import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  stepperDiv: { padding: 10 },
  stepper: {
    padding: "0!important",
    "& .Mui-disabled .MuiStepLabel-iconContainer": {
      opacity: 0.4,
    },
    "& .MuiStepConnector-line": {
      borderLeftStyle: "dotted",
      borderLeftWidth: "2px",
      borderColor: "#C6D2DC",
    },
    "& .Mui-completed .MuiStepConnector-line": {
      borderColor: "#00B5B1",
    },
    "& .MuiStepConnector-root": {
      marginLeft: "15px",
    },
    "& .MuiStepLabel-iconContainer": {
      paddingRight: "11px",
    },
  },
  steperLabel: {
    padding: "0!important",
    "& .MuiStepLabel-label": {
      color: "#0A2756",
      fontFamily: "Poppins-Regular",
    },
    "& .Mui-disabled .MuiStepLabel-iconContainer": {
      opacity: 0.4,
    },
  },
  stepIcon: {
    border: "2px solid #C6D2DC",
    borderRadius: "16px",
    fontFamily: "Poppins-SemiBold",
    fontSize: "14px",
    height: "30px",
    width: "30px",
    lineHeight: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  completed: {
    border: "1px solid #00B5B1",
    background: "#00B5B1",
    color: "#fff",
  },
  active: {
    border: "1px solid #00B5B1",
    color: "#00B5B1",
  },
});

export const customRequestStyles = makeStyles({
  customRequestTopDateDiv: {
    display: "flex",
  },
  customRequestDateDivflex: {
    display: "flex",
    alignItems: "center",
  },
  customRequestDateDivflexEnd: {
    display: "flex",
    alignItems: "center",
    marginLeft: "30px",
  },
  customRequestDateSpan: {
    color: "#0A2756",
    fontFamily: "Poppins-Medium",
    fontSize: "14px",
    marginRight: "8px",
  },
  calendarMonthIconStyles: {
    color: "#00b5b1",
  },
  calendarMonthIconStylesActive: { color: "#ffa800" },
  customTextDiv: {
    color: "#A7A7A7",
    fontFamily: "NotoSerif-Regular",
    fontSize: "14px",
  },
  datePickerDiv: {
    paddingTop: "16px",
  },
});


export const roleDefinitionStyles = makeStyles({
  topDiv: {
    width: "240px",
    height: "160px",
    border: "1px solid #A7A7A7",
    borderRadius: "4px",
    alignItems: "left",
  },
  containerDiv: {
    marginLeft: "11px",
    marginTop: "10px",
  },
  contentDiv: {
    fontFamily: "NotoSerif-Regular",
    fontSize: "13px",
    color: "#0A2756",
  },
  addRoleTile: {
    // width: '200px',
    height: "100%",
    minHeight: "144px",
    border: "1px dotted #A7A7A7",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#0A2756",
    fontSize: "13px",
    fontFamily: 'Poppins-Regular',
    letterSpacing: "0px",
    // padding: '16px',
    cursor: "pointer",
  },
  roleTile: {
    // width: '200px',
    // height: '160px',
    border: "1px solid #A7A7A7",
    borderRadius: "8px",
    display: "flex",
    padding: "16px",
    flexDirection: "column",
    // boxShadow: '1px 1px 3px black',
    // '&:hover': {
    //   boxShadow: '1px 1px 3px inset',
    // },
  },
  tileHeader: {
    textAlign: "left",
    font: "normal normal 13px/20px Poppins-Regular",
    letterSpacing: "0px",
    color: "#0A2756",
  },
  tileValue: {
    font: "normal normal normal 13px/18px NotoSerif-Regular",
    letterSpacing: "0px",
    color: "#0A2756",
    margin: "0px 0px 8px",
  },
  modalScopeFieldLabel: {
    font: "normal normal normal 14px/21px Poppins-Regular",
    letterSpacing: "0px",
    color: "#0A2756",
  },
  roleTileWrapper: {
    cursor: "pointer",
    "&:hover": {
      "&>div:last-child": {
        display: "block",
      },
    },
  },
  roleActionStrip: {
    display: "none",
    position: "relative",
    borderRadius: "0px 0px 8px 8px",
  },
  roleActionStripAbsolute: {
    position: "absolute",
    bottom: "0px",
    right: "0px",
    left: "0px",
    borderRadius: "0px 0px 8px 8px",
    border: "1px solid #A7A7A7",
    borderTop: "0px",
    display: "flex",
    padding: "4px 8px",
    justifyContent: "right",
  },
  iconWrapper: {
    display: "flex",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    "&:hover>svg>*>path": {
      fill: "#EF5B2B",
    },
  },
  checkBox: {
    // background: 'red',
    '& svg g': {
      stroke: '#00B5B1'
    }
  }
});

export const userDefinitionStyles = makeStyles({
  addUserTile: {
    // width: '200px',
    height: "100%",
    minHeight: "144px",
    border: "1px solid #A7A7A7",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#0A2756",
    font: "normal normal normal 13px/20px Poppins-Regular",
    letterSpacing: "0px",
    // padding: '16px',
    cursor: "pointer",
  },
  additionPendingDiv: {
    padding: "5px 0px 0px 5px",
    position: "absolute",
    left: "0",
    bottom: "0",
    // display: "none",
    background: '#FFEDCA',
    borderRadius: '4px',
    width: '129px',
    height: '32',
    alignItems: 'center',
    display: 'flex',
    // "&:hover>svg>*>path": {
    //   fill: "#EF5B2B",
    //   // strokeOpacity: '0.6',
    // },
  },
  tooltipSpan: {
    marginLeft: '8px 0px 0px 8px'
  },
  toolTipPosition: {
    paddingLeft: '8px!important', display: 'flex', alignItems: 'center'
  },
  userTileWrapper: {
    cursor: "pointer",
    "&:hover": {
      "&>div:last-child": {
        display: "block",
      },
    },
  },
  // addRoleTile: {
  //   // width: '200px',
  //   height: "100%",
  //   minHeight: "144px",
  //   border: "1px dotted #A7A7A7",
  //   borderRadius: "8px",
  //   display: "flex",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   color: "#0A2756",
  //   font: "normal normal normal 13px/20px Poppins-Regular",
  //   letterSpacing: "0px",
  //   // padding: '16px',
  //   cursor: "pointer",
  // },
  overlay: {
    width: ' 100 %',
    height: ' 100 %',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, .5)',
    // zIndex: 2,
    cursor: 'pointer',
    border: "1px solid #A7A7A7",
    borderRadius: "8px",
    display: "flex",
    padding: "12px",
    flexDirection: "column",
    position: 'relative',
    // opacity: '0.4'
    // boxShadow: 'inset 0 0 2000px rgba(255, 255, 255, .5)',
    // filter: 'blur(1px)',
  },
  userTileWrapperPendingAccount: {
    cursor: "pointer",
    "&:hover": {
      "&>div:last-child": {
        display: "block",
      },
    },
    opacity: '0.3',
  },
  userTile: {
    // width: '200px',
    // height: '160px',
    border: "1px solid #A7A7A7",
    borderRadius: "8px",
    display: "flex",
    padding: "12px",
    flexDirection: "column",
    position: 'relative'
  },
  userTileActionStrip: {
    display: "none",
    position: "relative",
    borderRadius: "0px 0px 8px 8px",
  },
  userActionStripAbsolute: {
    position: "absolute",
    bottom: "0px",
    right: "0px",
    left: "0px",
    borderRadius: "0px 0px 8px 8px",
    border: "1px solid #A7A7A7",
    borderTop: "0px",
    display: "flex",
    padding: "4px 8px",
    justifyContent: "right",
  },
  avatar: {
    width: "30px",
    height: "30px",
    marginRight: "8px",
  },
  userNameAvtarWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
  },
  userNameWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  userName: {
    textAlign: "left",
    font: "normal normal normal 13px/20px Poppins-Regular",
    letterSpacing: "0px",
    color: "#0A2756",
  },
  thirdPartyLabel: {
    textAlign: "left",
    font: "normal normal normal 11px/15px NotoSerif-Regular",
    letterSpacing: "0px",
    color: "#0A2756",
  },
  tileTitle: {
    textAlign: "left",
    font: "normal normal normal 13px/20px Poppins-Regular",
    letterSpacing: "0px",
    color: "#0A2756",
  },
  tileSubTitle: {
    textAlign: "left",
    font: "normal normal normal 13px/18px NotoSerif-Regular",
    letterSpacing: "0px",
    color: "#0A2756",
  },
  iconWrapper: {
    display: "flex",
    marginRight: "8px",
    padding: "2px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    "&:hover>svg>*>path": {
      fill: "#EF5B2B",
      // strokeOpacity: '0.6',
    },
  },
  checkboxLabel: {
    "&+span": {
      font: "normal normal normal normal 14px/21px Poppins-Regular",
    },
  },
  errorSpan: {
    textAlign: "left",
    font: "normal normal normal 10px/20px NotoSerif-Regular",
    letterSpacing: "0px",
    color: "#EF5B2B",
    marginTop: "0px",
    marginLeft: "4px",
  },
});

export const modalStyles = makeStyles({
  modalActionDiv: {
    display: "flex",
    justifyContent: "right",
  },
  modalContentDiv: {
    marginBottom: "12px",
    color: "#0A2756",
    font: "normal normal normal 14px/21px Poppins-Medium",
  },
  modalTextDiv: {
    // maxWidth: "52ch",
    color: "#0A2756",
    font: "normal normal normal 14px/22px NotoSerif-Regular",
  },
  spanText: {
    fontFamily: "Poppins-Medium",
    fontSize: "13px",
    color: "#0A2756",
  },
});

export const phoneInputStyles = makeStyles({
  container: {
    "& > .flag-dropdown": {
      "& > .selected-flag": {
        zIndex: "2",
      },
      "&:after": {
        // background: 'orange',
        borderRight: "1px solid #C6D2DC !important",
        content: '""',
        position: "absolute",
        top: "15%",
        right: "0",
        bottom: "0",
        left: "0",
        height: "70%",
        zIndex: "0",
      },
    },
  },
  inputField: {
    width: "100% !important",
    font: "normal normal normal 14px/19px Noto-Serif !important",
    letterSpacing: "0px",
    color: "#0A2756",
    paddingTop: "6.5px !important",
    paddingBottom: "6.5px !important",
  },
  dropdown: {
    marginTop: "0px !important",
    font: "normal normal normal 14px/16px NotoSerif",
    letterSpacing: "0px",
    color: "#0A2756",
    "& > .country:hover": {
      backgroundColor: "#EDF4F8 !important",
    },
  },
});

export const formikContainerStyles = makeStyles({
  topDiv: {
    padding: "16px",
  },
  stepperContainer: {
    padding: "32px 7px 16px",
  },
  modalTextDiv: {
    letterSpacing: "0px",
    // maxWidth: "52ch",
    color: "#0A2756",
    font: "normal normal normal 14px/22px NotoSerif-Regular",
  },
  stepperScoller: {
    height: "calc(100vh - 270px)",
    overflow: "scroll",
    paddingRight: "8px",
  },
  leftSection: {
    display: "flex",
    justifyContent: "space-between",
  },
  reasonLabel: {
    font: "normal normal 600 15px/23px Poppins-Regular",
    letterSpacing: "0px",
    color: "#0A2756",
    opacity: "1",
    marginBottom: "10px",
  },
  modalContentDiv: {
    marginBottom: "12px",
    color: "#0A2756",
    font: "normal normal normal 14px/21px Poppins-Medium",
  },
  modalTextDiv: {
    letterSpacing: "0px",
    // maxWidth: "52ch",
    color: "#0A2756",
    font: "normal normal normal 14px/22px NotoSerif-Regular",
  },
  spanText: {
    fontFamily: "NotoSerif-Bold",
  },
  modalActionDiv: {
    display: "flex",
    justifyContent: "right",
  },
  footerActions: {
    display: "inline-flex",
    "& > button": {
      margin: "0 8px !important",
      "&:first-child": {
        marginLeft: "0px !important",
      },
      "&:last-child": {
        marginRight: "0px !important",
      },
    },
  },
  footerActionsRight: {
    float: "right",
  },
});

export const delegationOwnerStyles = makeStyles({
  toolTipPosition: {
    paddingLeft: '8px!important', display: 'flex', alignItems: 'center'
  },
  tooltipSpan: {
    marginLeft: '8px 0px 0px 8px'
  },
  container: {
    border: "1px solid #D9D9D9",
    display: "inline-flex",
    borderRadius: "4px",
    paddingTop: "16px",
    paddingBottom: "17px",
    paddingLeft: "11px",
  },
  modalTextDiv: {
    letterSpacing: "0px",
    // maxWidth: "52ch",
    color: "#0A2756",
    font: "normal normal normal 14px/22px NotoSerif-Regular",
  },
  containerSignature: {
    border: "1px solid #D9D9D9",
    borderRadius: "4px",
    paddingBottom: "8px",
  },
  liBottomPadding: {
    color: "#0A2756",
    fontSize: "14px",
    fontFamily: "NotoSerif-Regular",
    paddingBottom: "16px",
  },
  calendarMonthIconStyles: {
    color: "#00b5b1",
  },
  calendarMonthIconStylesActive: {
    color: "#ffa800",
  },
  gridItemUserDetails: {
    paddingLeft: "16px",
  },
  gridItemUserDetailsSpan: {
    color: "#0A2756",
    fontSize: "14px",
    fontFamily: "Poppins-Medium",
    paddingLeft: "4px",
  },
  girdStyleAddSignature: {
    paddingLeft: "20px",
    paddingBottom: "8px",
  },
  signatureDiv: {
    border: "1px solid #D9D9D9",
    borderRadius: "4px",
    width: "183px",
    height: "93px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  signatureSpan: {
    color: " #A7A7A7",
    fontSize: "12px",
    fontFamily: "NotoSerif-Normal",
  },
  gridCalenderStyle: {
    paddingRight: "16px",
  },
  spanTextStyle: {
    color: " #0A2756",
    fontFamily: "NotoSerif-Regular",
    fontSize: "14px",
  },
  liHeading: {
    color: "#0A2756",
    fontSize: "14px",
    fontFamily: "NotoSerif-Regular",
    // paddingBottom: "16px",
    marginTop: "15px",
    marginLeft: "15px",
  },
});

export const implementationStyles = makeStyles({
  mainModelGrid: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #B9C3CE !important",
    },
    "& .modalHeader": {
      minHeight: "45px !important",
    },
    "& .MuiPaper-root.MuiPaper-elevation": {
      "&:first-child": {
        minHeight: "45px !important",
      },
    },
  },
  boldText: {
    fontFamily: 'Poppins-Bold',
    fontSize: '14px'
  },
  snippet: {
    color: '#EFB6A3',
    fontSize: '12px',
    fontFamily: 'NotoSerif-Regular',
  },
  gridContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "10px 0!important",
      color: "#FFFFFF !important",
    },
    "& .MuiAccordionSummary-content": {
      margin: "10px 0!important",
      color: "#0A2756",
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      // color: "#ffffff !important",
      backgroundColor: "#00B5B1 !important",
      color: 'red !important',
      "& .MuiSvgIcon-root": {
        color: "#FFF",
      },
      "& .MuiTypography-root": {
        color: "#FFF",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #B9C3CE !important",
    },
    // '& .MuiAccordionSummary-root.Mui-expanded .MuiSvgIcon-root': {
    //   color: '#0A2756',
    //   background: '#0A2756'
    // },  
  },
  gridTitle: {
    backgroundColor: "#DFEFFF",
    boxShadow: "none",
  },
  accTitle: {
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: "NotoSerif-Bold",
  },
  astrik: {
    color: "#EF5B2B",
    margin: "0 2px",
  },
  accordionSummary: {
    minHeight: "0px !important",
  },
  accordionDetails: {
    backgroundColor: "#FFFFFF",
    paddingTop: "0px",
  },
  title: {
    display: "flex",
    padding: "10px 10px 10px 0px",
    fontSize: "14px",
    color: "#0A2756",
    fontFamily: "Poppins-Medium",
  },
  subTitle: {
    marginLeft: "10px",
    color: "#0080FF",
  },
  markStep: {
    margin: "8px -2px",
  },
  checkboxLabel: {
    "&+span": {
      font: "normal normal normal normal 14px/21px Poppins-Regular",
      fontSize: "14px",
      color: "#0A2756",
    },
  },
  taskList: {
    fontFamily: "NotoSerif-Regular",
    fontSize: "14px",
    color: "#0A2756",
    padding: "10px 0 15px 0",
  },
  gridItem: {
    backgroundColor: "#fff",
    textAlign: "center",
    borderRadius: "4px",
    fontFamily: "NotoSerif-Regular",
    fontSize: "14px",
    color: "#0A2756",
    border: "1px solid #B9C3CE",
    //padding: "25px",
    position: "relative",
    //display: "inline-block",
    cursor: "pointer",
    width: "100%",
    height: "70px",
    alignItems: "center",
    display: "flex",
    "&:hover div": {
      display: "block",
    },
  },
  roleName: {
    width: "100%",
  },
  pencilIcon: {
    padding: "0px 5px 5px 0px",
    position: "absolute",
    right: "0",
    bottom: "0",
    display: "none",
    cursor: "pointer",
  },

  rightCheckIcon: {
    padding: "0px 5px 5px 0px",
    position: "absolute",
    right: "0",
    bottom: "0",
    display: "block",
    cursor: "pointer",
  },

  paddingT: {
    paddingTop: "15px",
  },
  roleGroupNameLabel: {
    maxWidth: "20%",
    "& .MuiTypography-root": {
      color: "#0A2756",
      font: "normal normal normal normal 14px/21px Poppins-Regular",
      fontSize: "14px",
      fontWeight: "bold",
    },
  },
  textAreaLabel: {
    "& .MuiTypography-root": {
      color: "#0A2756",
      font: "normal normal normal normal 14px/21px Poppins-Regular",
      fontSize: "14px",
      fontWeight: "normal",
    },
  },
  modelCheckboxLabel: {
    "&+span": {
      fontFamily: "NotoSerif-Regular",
      fontSize: "14px",
      color: "#0A2756",
    },
  },
  linkTag: {
    padding: "0 5px",
    color: "#0080FF",
  },
  fileUploaderMain: {
    border: "2px dashed #B9C3CE",
    borderRadius: "4px",
    width: "100%",
    padding: "8.5px 14px",
    display: 'flex',
    justifyContent: 'center',
  },
  filePasteDive: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  }
})
export const parStyles = makeStyles({
  tooltipSpan: {
    marginLeft: '8px 0px 0px 8px'
  },
  toolTipPosition: {
    paddingLeft: '8px!important', display: 'flex', alignItems: 'center'
  },
  input: {
    background: '#F4F7F8',
    padding: '10px',
    border: 'none',
    borderRadius: '4px',
    color: '#0A2756',
    fontFamily: 'NotoSerif-Regular',
    fontSize: '14px'
  },
  divider: {
    background: '#C6D2DC',
    opacity: '0,3',
  },
  divderDiv: {
    padding: '8px 16px 8px',
  },
  topDiv: {
    display: 'flex',
  },
  spanText: {
    textDecoration: 'underline',
    fontFamily: 'Poppins-Medium',
    fontSize: '12px',
    color: '#0A2756',
    paddingTop: '8px',
    cursor: 'pointer',
  },
  helperText: {
    fontFamily: 'NotoSerif-Italic',
    fontSize: '10px',
    marginTop: '0px'
  },
  helperTextError: {
    color: '#EF5B2B !important',
  },
  notchedOutline: {
    border: 'none',
  },
  comment: {
    cursor: 'pointer',
  },
  filterMenu: {
    margin: '0px 6px',
    display: 'inline-flex',
  },
  multilineheight: {
    height: '100px',
  },
  singleLine: {
    padding: '6px',
  },
  mainSectionDiv: {
    display: 'flex',
    justifyContent: 'right',
    flexDirection: 'row'
  },
  updatedByStyle: {
    color: '#A7A7A7',
    fontFamily: 'NotoSerif-Regular',
    fontSize: '10px',
    marginTop: '-10px',
    paddingBottom: '10px'
  },
  ViewHistory: {
    cursor: 'pointer',
    color: '#0A2756',
    textDecoration: 'underline',
    fontSize: '12px',
    fontFamily: 'Poppins-Medium',
    display: 'flex',
    alignItems: 'center'
  }
})

export const parImplementationStyles = makeStyles({
  gridContainer: {
    paddingBottom: "40px",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "10px 0!important",
      color: "#FFFFFF !important",
    },
    "& .MuiAccordionSummary-content": {
      margin: "10px 0!important",
      color: "#0A2756",
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      color: "#ffffff !important",
      backgroundColor: "#00B5B1 !important",
      "& .MuiSvgIcon-root": {
        color: "#FFF",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #B9C3CE !important",
    },
  },
  gridTitle: {
    backgroundColor: "#DFEFFF",
    boxShadow: "none",
  },
  containerBorder: {
    border: "1px solid #D9D9D9",
    borderRadius: "4px",
    paddingLeft: '8px'
  },
  accTitle: {
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: "NotoSerif-Bold",
  },
  fieldTopPadding: {
    paddingTop: '10px'
  },
  astrik: {
    color: "#EF5B2B",
    margin: "0 2px",
  },
  pamAstrik: {
    color: "#EF5B2B",
    margin: "0 2px",
    display: "contents",
  },
  paddingT: {
    paddingTop: "0px !important",
  },
  serviceNowText: {
    paddingLeft: "15px !important",
  },
  accordionSummary: {
    minHeight: "0px !important",
  },
  accordionDetails: {
    backgroundColor: "#FBFBFB",
    paddingTop: "0px",
    paddingBottom: "1px",
  },
  serviceTitle: {
    color: "#0A2756",
    fontFamily: "Poppins-Medium",
    fontSize: "14px",
  },
  paddingLeft: {
    paddingLeft: "30px"
  },
  tooltipSpan: {
    marginLeft: '8px',
  },
  title: {
    display: "flex",
    padding: "10px 10px 10px 0px",
    fontFamily: "NotoSerif-Regular",
    fontSize: "14px",
    color: "#0A2756",
  },
  titleQue: {
    display: "flex",
    padding: "10px 10px 0px 0px",
    fontFamily: "NotoSerif-Regular",
    fontSize: "14px",
    color: "#0A2756",
  },
  subTitleQue: {
    padding: "15px 0px",
    fontFamily: "NotoSerif-Regular",
    fontSize: "14px",
    color: "#0A2756",
  },
  subTitle: {
    fontSize: "12px",
    color: "#0A2756",
    fontFamily: "NotoSerif-Regular",
  },
  markStep: {
    margin: "8px -2px",
  },
  markStepQue: {
    // paddingLeft: "15px",
    paddingTop: '8px',
    marginTop: "2px",
  },

  checkboxLabel: {
    "&+span": {
      font: "normal normal normal normal 14px/21px Poppins-Regular",
      fontSize: "14px",
      paddingLeft: "0px !important",
      color: "#0A2756",
    },
  },
  checkboxLabelQue: {
    "&+span": {
      fontFamily: "NotoSerif-Regular",
      fontSize: "14px",
      color: "#0A2756",
    },
  },
  taskList: {
    fontFamily: "NotoSerif-Regular",
    fontSize: "14px",
    color: "#0A2756",
    padding: "10px 0 15px 0",
  },
  gridItem: {
    backgroundColor: "#fff",
    textAlign: "center",
    borderRadius: "4px",
    fontFamily: "NotoSerif-Regular",
    fontSize: "14px",
    color: "#0A2756",
    border: "1px solid #B9C3CE",
    //padding: "25px",
    position: "relative",
    //display: "inline-block",
    cursor: "pointer",
    width: "100%",
    height: "70px",
    alignItems: "center",
    display: "flex",
    "&:hover div": {
      display: "block",
    },
  },
  gridLeft: {
    padding: "0 0 0 30px !important",
  },
  strongTag: {
    padding: "0 5px",
  },
  linkTag: {
    padding: "0 5px",
    color: "#0080FF",
  },
  displayFlex: {
    display: "flex",
  },
  paddingR: {
    paddingRight: "2px",
  },
});

export const sarUseStyles = makeStyles({
  tooltipSpan: {
    marginLeft: '8px 0px 0px 8px'
  },
  toolTipPosition: {
    paddingLeft: '8px!important', display: 'flex', alignItems: 'center'
  },
  contentStyle: {
    color: '#A7A7A7',
    fontFamily: 'NotoSerif-Regular',
    fontSize: "14px",
    paddingLeft: '25px',
    marginTop: '-10px'
  },
  container: {
    border: "1px solid #D9D9D9",
    display: "inline-flex",
    borderRadius: "4px",
    paddingTop: "16px",
    paddingBottom: "17px",
    paddingLeft: "11px",
    width:'100%'
  }, spanTextStyle: {
    color: " #0A2756",
    fontFamily: "NotoSerif-Regular",
    fontSize: "14px",
  },
  headerTitle: {
    fontSize: '14px',
    fontFamily: "NotoSerif-Regular",
    color: ' #0A2756'
  },
  headerTitleForComplex: {
    fontSize: '14px',
    fontFamily: "NotoSerif-Regular",
    color: ' #0A2756',
    marginTop: '15px'
  },
  titleForheader: {
    fontSize: '14px',
    fontFamily: "NotoSerif-Regular",
    color: ' #979DA4',
    marginTop: '6px',
    marginBottom: ' 6px',
  },
  listing: {
    fontFamily: 'NotoSerif-Regular',
    margin: 0,
    fontSize: '14px',
    color: ' #A7A7A7',
    paddingLeft: '19px'
  },
  subTitle: {
    color: '#0A2756',
    marginTop: '20px',
    fontFamily: 'Poppins-Regular',
    fontSize: ' 14px',
    marginBottom: ' 7px',
  },
  marginTopForDropdown: {
    marginTop: '20px'
  },
  checkBox: {
    '& svg g': {
      stroke: '#00B5B1'
    },
    "& .Mui-disabled": {
      '& svg g': {
        stroke: '#d9d9d9'
      }
    }
  }
})
export default useStyles;
