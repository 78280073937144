import { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { format, isDate, isEqual, isWithinInterval } from "date-fns";
import TextField from "@mui/material/TextField";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import _, { includes } from "lodash";
import { useSnackbar } from "notistack";
import { ReactComponent as Sorting } from "../../assets/icons/sorting_asc_inactive.svg";
import { ReactComponent as SortingAscending } from "../../assets/icons/sorting_asc.svg";
import { ReactComponent as SortingDescending } from "../../assets/icons/sorting_desc.svg";
import useDaterangePicker from "../../components/date-range-picker/useDaterangePicker";
import CustomButton from "../../components/button";
import { getAllParrequests } from "../../api-services/par";
import StatusBadge from "./StatusBadge";
import {
    IFiltersObj,
    ITableParameters,
    initialTableParameters,
    createColumns,
    createRowCells,
    parColumnMapping,
    emptyFilters,
    IParSummaryData,
} from "./parUtils";
import parSummaryStyles from "./par-summary-styles";
import { fetchFilterEndPoints, fetchAllFilterRequests, updateStatusOfPar } from "../../api-services/par";
import { RootState } from "../../appStore/store";
import { loginUserData } from "../../appStore/reducer/loginReducer";

function useParHook() {
    const { loginuserdata, loginsyncupdata } = useSelector((state: RootState) => state.loginData)
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const styleClasses = parSummaryStyles();
    const [parSummaryData, setParSummaryData] = useState({
        count: 0,
        results: [],
        pageSize: 10,
    });
    const [parDataFromApi, setParDataFromApi] = useState([]);
    const [tempParDataFromApi, setTempParDataFromApi] = useState([]);
    const [filters, setFilters] = useState<IFiltersObj>(emptyFilters);
    const [tableParameters, setTableParameters] = useState<ITableParameters>(initialTableParameters)
    const [closeFilter, setCloseFilter] = useState(false);
    const [requestIdFilter, setRequestIdFilters] = useState([]);
    const [requestStatusFilter, setRequestStatusFilters] = useState<
        {
            id: number;
            value: string;
            label: string;
        }[]
    >([]);
    const [isLoading, setIsLoading] = useState({
        tableLoading: false,
        filters: {
            status: false,
            requestId: false,
            requestedOn: false,
        },
    });
    const [menuData, setMenuData] = useState({
        anchorEl: null,
        row: {
            status: null,
            id: null,
            delegation_id: null,
        },
    });
    const [modalData, setModalData] = useState<{
        data: any;
        type: string;
    }>({ data: null, type: "" });
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const { datepickerProps, setDatepickerProps, setDateRangeValue } =
        useDaterangePicker({ isSingleDate: true });

    const formateWord = (item: any) => {
        const capitalized = item.toLowerCase();
        let newText = capitalized.charAt(0).toUpperCase() + capitalized.slice(1);
        return newText;
    }

    const fetchFiltersValues = () => {
        setIsLoading((loadingState) => ({
            ...loadingState,
            filters: {
                ...loadingState.filters,
                status: true,
            },
        }));
        fetchAllFilterRequests(
            fetchFilterEndPoints.fetchDelegationStatusFilterEndPoint
        ).then(
            (successData: any) => {
                setRequestStatusFilters(successData?.data);
                setIsLoading((loadingState) => ({
                    ...loadingState,
                    filters: {
                        ...loadingState.filters,
                        status: false,
                    },
                }));
            },
            (error: any) => {
                setRequestStatusFilters([]);
                setIsLoading((loadingState) => ({
                    ...loadingState,
                    filters: {
                        ...loadingState.filters,
                        status: false,
                    },
                }));
                console.error(error);
            }
        );
    };

    const enablePar = (name: string) => {

        if (loginuserdata.name === name) return true;
        else return false;
    }

    const openDelegation = (id: any, row: any) => {
        setMenuData({
            anchorEl: null,
            row: {
                status: null,
                id: null,
                delegation_id: null,
            },
        });
        if (enablePar(row.assign_to)) return history.push(`/par/${row.id}`);
        return null;
    };

    const openMenu = (e: any, row: any) => {
        setMenuData({ anchorEl: e.target, row });
    };

    const formatDate = (dateValue: any) =>
        dateValue && isDate(new Date(dateValue))
            ? format(new Date(dateValue), "dd MMM yyyy")
            : "";
    useEffect(() => {
        const rowsArray = tempParDataFromApi.map((row: any) => {
            const cellArray = Object.entries(row)
                .map(([key, value]) => {
                    let cellData = null;
                    switch (key) {
                        case "request_number":
                            cellData = createRowCells(
                                parColumnMapping.request_number,
                                `${value}`,
                                `${row.id}-${value}`,
                                () => openDelegation(value, row),
                                2,
                                enablePar(row.assign_to) ? { textDecoration: "underline", fontFamily: 'NotoSerif-Bold !important', fontWeight: 'bolder !important', color: "#0080FF" } : { color: "#0080FF" }
                            );
                            break;
                        case 'status':
                            cellData = createRowCells(parColumnMapping.status, <StatusBadge isRequestToUpdateCount={row.request_to_update_count} requestType={row.request_type} status={value} requestPrupose={row.request_purpose} stage={row.stage} stageId={row.stage_priority ? row.stage_priority : null} remediationCount={row.remediation_count} isUpdateRequested={row.is_request_to_update} superScript={row.request_to_update_count} />, `${row.id}-${value}`, undefined, 5);
                            break;
                        case "assign_to":
                            cellData = createRowCells(
                                parColumnMapping.assignedTo,
                                `${value || ""}`,
                                `${row.id}-${value || ""}`,
                                undefined,
                                4
                            );
                            break;
                        case "request_date":
                            cellData = createRowCells(
                                parColumnMapping.requestedOn,
                                `${formatDate(value)}`,
                                `${row.id}-${value}`,
                                undefined,
                                6
                            );
                            break;
                        case "updated_at":
                            cellData = createRowCells(
                                parColumnMapping.lastUpdated,
                                `${formatDate(value)}`,
                                `${row.id}-${value}`,
                                undefined,
                                7
                            );
                            break;
                        case "completion_date":
                            cellData = createRowCells(
                                parColumnMapping.completionDate,
                                `${formatDate(value)}`,
                                `${row.id}-${value || "-"}`,
                                undefined,
                                8
                            );
                            break;
                        default:
                            break;
                    }
                    return cellData;
                })
                .filter((obj: any) => obj);
            // if (row.delegation_id === null) {
            //     if (row.status === 'Approved - Pending Implementation' ||
            //         row.status === 'Completed' ||
            //         row.status === 'Revoked' ||
            //         row.status === 'Draft'
            //     ) {
            //         cellArray.push(
            //             createRowCells(
            //                 parColumnMapping.actions,
            //                 '',
            //                 `more-actions-${row.id}`,
            //                 () => null,
            //                 9,

            //             )
            //         );
            //     } else {
            //         cellArray.push(
            //             createRowCells(
            //                 parColumnMapping.actions,
            //                 <MoreVertIcon
            //                     className={`${styleClasses.moreIcon} ${!!menuData.anchorEl && styleClasses.moreIconActive
            //                         }`}
            //                 />,
            //                 `more-actions-${row.id}`,
            //                 (e) => openMenu(e, row),
            //                 9,

            //             )
            //         );
            //     }

            // } else if (row.delegation_id) {
            //     // if (row.status === 'Approved - Pending Implementation' ||
            //     //     row.status === 'completed' ||
            //     //     row.status === 'Revoked' ||
            //     //     row.status === 'Draft' ||
            //     //     row.status === 'Pending Approval' ||
            //     //     row.status === 'Remediation Requested'
            //     // ) {
            //     cellArray.push(
            //         createRowCells(
            //             parColumnMapping.actions,
            //             '',
            //             `more-actions-${row.id}`,
            //             () => null,
            //             9,
            //             { textAlign: "center" }
            //         )
            //     );
            //     // }
            //     //else {
            //     //     cellArray.push(
            //     //         createRowCells(
            //     //             parColumnMapping.actions,
            //     //             <MoreVertIcon
            //     //                 className={`${styleClasses.moreIcon} ${!!menuData.anchorEl && styleClasses.moreIconActive
            //     //                     }`}
            //     //             />,
            //     //             `more-actions-${row.id}`,
            //     //             (e) => openMenu(e, row),
            //     //             9,
            //     //             { textAlign: "center" }
            //     //         )
            //     //     );
            //     // }
            // }


            return cellArray;
        });

        setParSummaryData((prevState: any) => ({ ...prevState, results: [...rowsArray] }));
    }, [tempParDataFromApi]);

    const handleStatusUpdatePar = () => {
        const postBody = {
            par_id: modalData.data.id,
            status: "10",
        }
        updateStatusOfPar(postBody).then((res) => {
            enqueueSnackbar('Request to revoke PAR has been submitted successfully', { variant: 'success' });
            fetchAllDelegationsSummaryData();
        }).catch((err) => err);
        handleModalClose();

    }
    // Set par Data onload once 
    const fetchAllDelegationsSummaryData = async () => {
        console.log('$$$$ inside fetchDelegation');
        // @ts-ignore */
        await getAllParrequests().then((res: any) => {


            setParDataFromApi(res.data.sort((a: any, b: any) => b.updated_at.localeCompare(a.updated_at)));
            setTempParDataFromApi(res.data.sort((a: any, b: any) => b.updated_at.localeCompare(a.updated_at)));
            setRequestIdFilters(res.data?.map((requestIdObj: { request_number: string }) => ({
                id: requestIdObj.request_number,
                value: requestIdObj.request_number,
            })))
            setIsLoading((loadingState) => ({ ...loadingState, tableLoading: false }));
        }).catch((error: string) => {
            enqueueSnackbar('Something went wrong! Please try again', { variant: 'error' });
            setIsLoading((loadingState) => ({ ...loadingState, tableLoading: false }));
        }
        )
    }

    useEffect(() => {
        fetchAllDelegationsSummaryData();
        fetchFiltersValues();
        setIsLoading((loadingState) => ({ ...loadingState, tableLoading: true }));
    }, []);

    const setDateRangeFilter = () => {
        setCloseFilter(true);

        let requestedOn = parDataFromApi.filter((obj: IParSummaryData) => {
            if (isWithinInterval(new Date(`${obj.request_date}`), {
                // @ts-ignore */
                start: datepickerProps.dateRange.startDate,
                // @ts-ignore */
                end: datepickerProps.dateRange.endDate,
            }) || formatDate(obj.request_date) === formatDate(datepickerProps.dateRange.endDate)) {
                console.log('done in par');
                return true;
            } else {

                return false;
            }
        }
        )
        setTempParDataFromApi([...requestedOn]);
        setTableParameters((prevParameters: any) => ({
            ...prevParameters,
            page: Math.ceil(requestedOn.length / parSummaryData.pageSize),
        }));
    };

    // clear value handler
    const clearValueHandler = () => {

        setIsLoading((loadingState) => ({ ...loadingState, tableLoading: false }));
        setDatepickerProps({
            isSingleDate: false,
            dateRange:
            {
                startDate: null,
                endDate: null,
            },
        })
        setFilters(emptyFilters)
        setTempParDataFromApi(parDataFromApi);
    }
    // For Sorting
    useEffect(() => {
        tableParameters.orderBy.column !== '' ? createAndSetParSummary() : setTempParDataFromApi(parDataFromApi);
    }, [tableParameters.orderBy.column, tableParameters.orderBy.order]);

    // For sorting Column Name
    const createAndSetParSummary = () => {
        console.log("tableParameters.orderBy.column", tableParameters.orderBy.column)
        setTempParDataFromApi((prevData) => [...prevData.sort(function (valueOne, valueTwo) {
            if (
                tableParameters.orderBy.column === 'request_date' ||
                tableParameters.orderBy.column === 'completion_date' ||
                tableParameters.orderBy.column === 'updated_at'
            ) {
                if (tableParameters.orderBy.order === 'asc')
                    return (
                        new Date(valueOne[tableParameters.orderBy.column]).getTime() -
                        new Date(valueTwo[tableParameters.orderBy.column]).getTime()
                    );
                if (tableParameters.orderBy.order === 'desc')
                    return (
                        new Date(valueTwo[tableParameters.orderBy.column]).getTime() -
                        new Date(valueOne[tableParameters.orderBy.column]).getTime()
                    );
                return 0;
            }
            else {
                if (
                    valueOne[tableParameters.orderBy.column] <
                    valueTwo[tableParameters.orderBy.column]
                )
                    return tableParameters.orderBy.order === 'asc' ? -1 : 1;
                if (
                    valueOne[tableParameters.orderBy.column] >
                    valueTwo[tableParameters.orderBy.column]
                )
                    return tableParameters.orderBy.order === 'asc' ? 1 : -1;
                return 0;
            }
        }),
        ]);
    };

    const handleSort = (e: any) => {
        setTableParameters((prevParameters) => {
            const newOrderBy = { ...prevParameters.orderBy };
            if (e.target.id !== newOrderBy.column) {
                newOrderBy.order = "asc";
                newOrderBy.column = e.target.id;
            } else if (prevParameters.orderBy.order === "desc") {
                newOrderBy.order = "asc";
                newOrderBy.column = "";
            } else {
                newOrderBy.order = "desc";
            }
            return { ...prevParameters, orderBy: { ...newOrderBy } };
        });
    };

    const getSortingIcon = (columnId: string) => {
        if (tableParameters.orderBy.column === columnId) {
            return tableParameters.orderBy.order === "desc" ? (
                <SortingDescending />
            ) : (
                <SortingAscending />
            );
        }
        return <Sorting />;
    };

    // modal handlers start
    const modalHeaderHandler = (type: string) => {
        switch (type) {
            case "revoke":
                return "Request to revoke";
            default:
                return "";
        }
    };

    const modalContentHandler = (type: string) => {
        switch (type) {
            case "revoke":
                return (
                    <div>
                        <div className={styleClasses.modalContentDiv}>
                            {`Are you sure you want to revoke this PAR  #${modalData.data.id}?`}
                        </div>
                    </div>
                );
            // case "update":
            //     return (
            //         <div>
            //             <div className={styleClasses.updateModalContent}>
            //                 {`Are you sure you want to update this delegation?
            //   On pressing 'Request to Update' an email will be triggered to the administrator.`}
            //             </div>
            //             <div className={styleClasses.updateModalNote}>
            //                 <span className={styleClasses.spanText}>{"Note: "}</span>
            //                 Administrator can allow or deny this request. Please provide your
            //                 justification below
            //             </div>
            //             <TextField
            //                 id="outlined-textarea"
            //                 label="Please provide reason for updation"
            //                 classes={{ root: styleClasses.textFieldRoot }}
            //                 InputProps={{ classes: { root: styleClasses.textFieldInput } }}
            //                 minRows={3}
            //                 maxRows={6}
            //                 onChange={(e) => setUpdateReason(e.target.value)}
            //                 value={updateReason}
            //                 multiline
            //                 InputLabelProps={{
            //                     classes: {
            //                         root: styleClasses.textFieldLabel,
            //                         shrink: styleClasses.textFieldLabelShrink,
            //                     },
            //                 }}
            //             />
            //         </div>
            //     );
            default:
                return null;
        }
    };
    const modalActionHandler = (type: string) => {
        switch (type) {
            case "revoke":
                return (
                    <div className={styleClasses.modalActionDiv}>
                        <CustomButton
                            // disabled={isLoading}
                            variant="contained"
                            actionType="secondary"
                            onClick={handleStatusUpdatePar}
                        >
                            Yes
                        </CustomButton>
                        <CustomButton
                            // disabled={isLoading}
                            variant="contained"
                            actionType="primary"
                            disableMargin
                            onClick={() => handleModalClose()}
                        >
                            No
                        </CustomButton>
                    </div>
                )

            default:
                return null;
        }
    }

    // modal handlers end
    // handle modal open 

    const handleModalOpen = (actionType: string) => {
        if (menuData) {
            setModalData({ data: { ...menuData.row }, type: actionType });
            setMenuData({
                anchorEl: null,
                row: {
                    status: null,
                    id: null,
                    delegation_id: null,
                },
            });
        } else {
            setModalData({ data: null, type: actionType });
        }
        setModalOpen(true);
    };

    const tableColumns = [
        createColumns(
            1,
            "PAR ID",
            () => null,
            parColumnMapping.id,
            tableParameters.orderBy.column === parColumnMapping.id,
            (e) => handleSort(e),
            { width: "115px" }
        ),
        createColumns(
            2,
            "Assigned To",
            getSortingIcon(parColumnMapping.assignedTo),
            parColumnMapping.assignedTo,
            tableParameters.orderBy.column === parColumnMapping.assignedTo,
            (e) => handleSort(e),
            { width: "115px" }
        ),
        createColumns(
            3,
            "Status",
            getSortingIcon(parColumnMapping.status),
            parColumnMapping.status,
            tableParameters.orderBy.column === parColumnMapping.status,
            (e) => handleSort(e),
            { width: "115px" }
        ),

        createColumns(
            4,
            "Requested On",
            getSortingIcon(parColumnMapping.requestedOn),
            parColumnMapping.requestedOn,
            tableParameters.orderBy.column === parColumnMapping.requestedOn,
            (e) => handleSort(e),
            { width: "100px" }
        ),
        createColumns(
            5,
            "Last Updated",
            getSortingIcon(parColumnMapping.lastUpdated),
            parColumnMapping.lastUpdated,
            tableParameters.orderBy.column === parColumnMapping.lastUpdated,
            (e) => handleSort(e),
            { width: "115px" }
        ),
        createColumns(
            6,
            "Completion Date",
            getSortingIcon(parColumnMapping.completionDate),
            parColumnMapping.completionDate,
            tableParameters.orderBy.column === parColumnMapping.completionDate,
            (e) => handleSort(e),
            { width: "115px" }
        ),
        // createColumns(
        //     7,
        //     "Actions",
        //     null,
        //     parColumnMapping.actions,
        //     false,
        //     undefined,
        //     { width: "40px" },

        // ),
    ];


    const fetchFilteredDataWithDebounce = (filtersObject: any) => {
        if (filtersObject?.requestId?.length > 0) {
            let requestIdPar = parDataFromApi.filter((obj: IParSummaryData) => (filtersObject?.requestId.find((item: string) => item === obj.request_number)))
            setTempParDataFromApi([...requestIdPar]);
            setTableParameters((prevParameters: any) => ({
                ...prevParameters,
                page: Math.ceil(requestIdPar.length / parSummaryData.pageSize),
            }));
        }
        else if (filtersObject?.status?.length > 0) {
            let statusPar = parDataFromApi.filter((obj: IParSummaryData) =>
                (filtersObject?.status.find((item: string) => item == obj.status_id)))
            setTempParDataFromApi([...statusPar]);
            setTableParameters((prevParameters: any) => ({
                ...prevParameters,
                page: Math.ceil(statusPar.length / parSummaryData.pageSize),
            }));
        }
        else {
            setTempParDataFromApi(parDataFromApi)
        }
    }

    useEffect(() => {
        fetchFilteredDataWithDebounce(filters);

    }, [filters]);


    useEffect(() => {
        if (!modalOpen) {
            setModalData({ data: null, type: "" });
        }
    }, [modalOpen]);


    const handleModalClose = () => {
        setModalOpen(false);
    };

    return {
        handleSort,
        getSortingIcon,
        requestStatusFilter,
        requestIdFilter,
        setCloseFilter,
        closeFilter,
        setDateRangeFilter,
        clearValueHandler,
        tableColumns,
        handleModalClose,
        datepickerProps,
        setDatepickerProps,
        setDateRangeValue,
        modalData,
        menuData,
        setMenuData,
        modalOpen,
        parSummaryData,
        isLoading,
        tableParameters,
        setTableParameters,
        filters,
        setFilters,
        modalActionHandler,
        modalContentHandler,
        modalHeaderHandler,
        emptyFilters,
        handleModalOpen,
    }
}
export default useParHook;