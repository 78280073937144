import { Popover, PopoverActions, PopoverProps, PopperProps } from '@mui/material';
import React, { useState } from 'react';
import reviewFilter from './useStyles';

interface IStyledReviewContainer extends PopoverProps {
    open: boolean;
    anchorEl: SVGElement | HTMLDivElement | null;
    handleClose: () => void;
}

const ReviewCommentsContainer = ({ anchorEl, open, handleClose, children }: IStyledReviewContainer) => {
    const styleClasses = reviewFilter();


    const id = open ? 'simple-popover' : undefined;


    return (
        <>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}

                className={styleClasses.popover}
                data-testid="filtermenu-popover"
            >
                {children}
            </Popover>
        </>
    )
}
export default ReviewCommentsContainer;