/* eslint-disable react/require-default-props */
import React, { useCallback, useEffect, useState } from 'react';
import InputBase from '@mui/material/InputBase';
import { Skeleton } from '@mui/material';
import { filterPopoverStyles } from './make-styles';
import Checkboxs from '../checkbox/index';
import { debounce } from 'lodash';

interface IOption {
  label?: string,
  id: string,
  value: string,
}

interface IFilterOptionsProps {
  disabled?: boolean;
  searchPlaceholder?: string,
  optionsList: IOption[],
  allowSearch?: boolean,
  handleOptionSelection: (_event: React.ChangeEvent<HTMLInputElement>, _checked: boolean) => void;
  selectedOptions: any[],
  isFilterDataLoading: boolean,
  allowAll?: boolean,
  selectAllHandler?: (_isSelected: boolean) => void,
}

function FilterOptions({
  disabled,
  searchPlaceholder,
  optionsList,
  allowSearch,
  handleOptionSelection,
  selectedOptions,
  isFilterDataLoading,
  allowAll,
  selectAllHandler,
}: IFilterOptionsProps) {
  console.log('ding ding', selectedOptions)
  const classes = filterPopoverStyles();
  const [selectAll, setSelectAll] = useState<boolean>(
    optionsList.length === selectedOptions.length,
  );
  const [filterOptionsData, setFilterOptionsData] = useState<IOption[]>([]);

  const skeletonArray = ['S1', 'S2', 'S3'];

  const debouncedSearch = useCallback(
    debounce((inputValue) => {
      searchBy(inputValue);
    }, 1500),
    [],
  );

  const searchBy = (inputValue: string) => {
    //console.log('optionsList', optionsList)
    // console.log("optionsList.filter((option) => String(option.value).toLowerCase().includes(inputValue.toLowerCase()))",)
    // let v = optionsList.filter((option) => String(option.value).toLowerCase().includes(inputValue.toLowerCase()));
    //console.log("v", v);
    //console.log("inputValue", inputValue);
    // const a = ;
    // console.log("a", a)
    if (inputValue) {
      setFilterOptionsData(optionsList.filter((option: any) => option.value.toString().toLowerCase() === inputValue.toString().toLowerCase()));
    } else {
      setFilterOptionsData(optionsList);
    }
  }

  useEffect(() => {
    setFilterOptionsData(optionsList);
  }, [optionsList]);

  const handleSelectAll = () => {
    if (selectAllHandler) {
      selectAllHandler(!selectAll);
    }
    setSelectAll((isAllSelected) => !isAllSelected);
  };

  useEffect(() => {
    if (allowAll) {
      console.log('aa')
      setSelectAll(optionsList.length === selectedOptions.length);
    }
  }, [selectedOptions]);

  return (
    <div data-testid="filter-options-wrapper">
      {!!optionsList.length && allowSearch && (
        <div className={classes.stickySearchContainer} data-testid="filter-search-wrapper">
          <InputBase
            fullWidth
            sx={{ ml: 1, flex: 1 }}
            placeholder={`${searchPlaceholder || 'Search here'}`}
            inputProps={{
              'aria-label': searchPlaceholder || 'search here',
              'data-testid': 'filter-search-input',
            }}
            classes={{ root: classes.stickySearch }}
            onChange={(e) => {
              debouncedSearch(String(e.target.value))
            }}
          />
        </div>
      )}
      <div className={classes.filterOptionsList}>
        {isFilterDataLoading && skeletonArray.map((item) => (
          <div
            className={classes.skeletonContainer}
            key={item}
            data-testid="loader-container"
          >
            <Skeleton animation="wave" className={classes.skeletonCheckbox} />
            <Skeleton animation="wave" className={classes.skeletonLabel} />
          </div>
        ))}
        {allowAll && !isFilterDataLoading && filterOptionsData.length > 0 && (
          <Checkboxs
            key="all-0"
            checked={selectAll}
            onChange={handleSelectAll}
            value={0}
            label="All"
            dataTestid="all-checkbox"
          />
        )}
        {!isFilterDataLoading && filterOptionsData.length > 0
          && filterOptionsData.map((option) => (
            <Checkboxs
              key={`${option.id}-${option.value}`}
              checked={(allowAll && selectAll) || !!(selectedOptions?.includes(option.id))}
              onChange={handleOptionSelection}
              // name={option.id}
              value={option.id}
              label={option.value}
              disabled={disabled}
            />
          ))}
        {!isFilterDataLoading
          && filterOptionsData.length <= 0
          && (
            <div className={classes.noRecordsLabel}>No values found for this filter</div>
          )}
      </div>
    </div>
  );
}

export default FilterOptions;
