import React from "react";

export const tableColumnMapping = {
  request_number: "request_number",
  requestId: "request_id",
  requestorName: "requestor_name",
  requestType: "request_type",
  myLastAction: "my_last_action",
  currentStatus: "current_status",
  requestedOn: "requested_on",
  actions: "actions",
};

export function createColumns(
  key: number,
  label: string,
  icon: any,
  id: string,
  isActive: boolean,
  handleClick?: (_e: any) => void,
  style?: React.CSSProperties
) {
  return {
    key,
    label,
    icon,
    handleClick,
    id,
    isActive,
    style,
  };
}
export function createRowCells(
  columnId: string,
  renderComponent: any,
  id: string,
  columnNumber: number,
  handleClick?: undefined | ((_e: any) => void),
  style?: React.CSSProperties,
  value?: any
) {
  return {
    columnId,
    renderComponent,
    id,
    handleClick,
    columnNumber,
    style,
    value,
  };
}

export const initialTableParameters = {
  page: 1,
  orderBy: {
    order: "asc",
    column: "",
  },
};

export interface ITableParameters {
  page: number;
  orderBy: {
    order: string;
    column: string;
  };
}
export interface IDateRangeObj {
  startDate: Date | null;
  endDate: Date | null;
}

export interface IFiltersObj {
  requestId: string[];
  requestorName: string[];
  requestType: string[];
  myLastAction: string[];
  currentStatus: string[];
  requestedOn: IDateRangeObj;
}

export interface ITableColumnData {
  request_id: number;
  requestor_name: string;
  request_type: string;
  my_last_action: string;
  current_status: string;
  requested_on: string;
  request_number: string;
}

export const emptyFilters = {
  requestId: [],
  requestorName: [],
  requestType: [],
  myLastAction: [],
  currentStatus: [],
  requestedOn: {
    startDate: null,
    endDate: null,
  },
};

export type LastAction = {
  id: string;
  value: string;
};

export type changeData = {
  id: number;
  name: string;
};
