import React, { useState, useEffect } from 'react'

const CountdownTimer = (props) => {
    const [minutes, setMinutes] = useState(2);
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval);
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(60);
                }
            }
        }, 1000);
        return () => {
            clearInterval(myInterval);
        };
    })
    return (
        <div>
            {minutes === 0 && seconds === 0 ? null : (
                <h1>
                    {" "}
                    {minutes}:{seconds < 120 ? `${seconds}` : seconds}
                </h1>
            )}
        </div>
    )
};

export default CountdownTimer;
