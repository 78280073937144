import React, { useEffect, useState } from 'react';
import { userHistoryColumnMapping, createColumns, createRowCells, initialTableParameters, IDateRangeObj, ITableParameters, IFiltersObj } from './utils';
import { format, isDate, isEqual, isWithinInterval } from "date-fns";
import { fetchHistoryTableDataPar } from '../../../api-services/par';
import DynamicTable from '../../../components/dynamic-table';
import CustomModal from '../../../components/custom-modal';
import { Button, Grid } from '@mui/material';
import reviewFilter from './useStyles';
import { ReactComponent as CrossIcon } from '../../../assets/icons/cancel_cross.svg';
import { Divider } from '@mui/material';
import FilterMenu from '../../../components/filter-menu';
import CustomToggleButton from '../../../components/button/CustomToggleButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import { parStyles } from '../../../components/formik-containers/useStyles';
import useDaterangePicker from '../../../components/date-range-picker/useDaterangePicker';
import CustomDatePicker from '../../../components/date-range-picker';
import CustomButton from '../../../components/button';
import { ReactComponent as EmptyResultIcon } from "../../../assets/icons/no_results_icon.svg";

interface IHistorytable {
    parId: number,
    section: string,
    subSection: string,
    data: {
        id: number | string,
        section: string,
        sub_section: string,
        update_type: string,
        updated_by: string,
        last_updated: string,
        value: string
    }[] | any,

}

const HistoryTable = ({ data }: IHistorytable) => {
    const styleClasses = reviewFilter();
    const Classes = parStyles();
    const [visible, setVisible] = useState(5);
    const [historyDataFromApi, setHistoryDataFromApi] = useState({
        results: [],
    });
    const {
        datepickerProps,
        setDatepickerProps,
        setDateRangeValue,
    } = useDaterangePicker({ isSingleDate: true });
    const [tempHistoryDataFromApi, setTempHistoryDataFromApi] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [tableParameters, setTableParameters] = useState<ITableParameters>(initialTableParameters)
    const [closeFilter, setCloseFilter] = useState(false);
    const formatDateInside = (dateValue: any) => (dateValue && isDate(new Date(dateValue)) ? format(new Date(dateValue), 'dd MMM yyyy') : '');

    useEffect(() => {
        const rowsArray = tempHistoryDataFromApi.map((row: any) => {
            const cellArray = Object.entries(row)
                .map(([key, value]) => {
                    let cellData = null;
                    switch (key) {
                        case "last_updated":
                            cellData = createRowCells(
                                userHistoryColumnMapping.lastUpdated,
                                `${formatDateInside(value) || ""}`,
                                `${row.id}-${value || ""}`,
                                undefined,
                                1
                            );
                            break;
                        case "section":
                            if (row.section == 'Role_definition') {
                                cellData = createRowCells(
                                    userHistoryColumnMapping.section,
                                    // @ts-ignore
                                    `${value?.replace(/_/g, " ") || ""}:${row.role_name_section}`,
                                    `${row.id}-${value || ""}`,
                                    undefined,
                                    4
                                );
                            } else if (row.section == 'User_details') {
                                cellData = createRowCells(
                                    userHistoryColumnMapping.section,
                                    // @ts-ignore
                                    `${value?.replace(/_/g, " ") || ""}:${row.user_details_section}`,
                                    `${row.id}-${value || ""}`,
                                    undefined,
                                    4
                                );
                            } else {
                                cellData = createRowCells(
                                    userHistoryColumnMapping.section,
                                    // @ts-ignore
                                    `${value?.replace(/_/g, " ") || ""}`,
                                    `${row.id}-${value || ""}`,
                                    undefined,
                                    4
                                );
                            }
                            break;
                        case "sub_section":
                            cellData = createRowCells(
                                userHistoryColumnMapping.subSection,
                                // @ts-ignore
                                `${value?.replace(/_/g, " ") || ""}`,
                                `${row.id}-${value || ""}`,
                                undefined,
                                5
                            );
                            break;
                        case "update_type":
                            cellData = createRowCells(
                                userHistoryColumnMapping.updateType,
                                `${value || ""}`,
                                `${row.id}-${value || ""}`,
                                undefined,
                                3
                            );
                            break;
                        case "updated_by":
                            cellData = createRowCells(
                                userHistoryColumnMapping.updatedBy,
                                `${value || ""}`,
                                `${row.id}-${value || ""}`,
                                undefined,
                                2
                            );
                            break;

                        case "value":
                            cellData = createRowCells(
                                userHistoryColumnMapping.value,
                                `${value || ""}`,
                                `${row.id}-${value || ""}`,
                                undefined,
                                6
                            );
                            break;
                        default:
                            break;
                    }
                    return cellData;
                })
                .filter((obj: any) => obj);

            return cellArray;
        });

        setHistoryDataFromApi((prevState: any) => ({ ...prevState, results: [...rowsArray] }));
    }, [tempHistoryDataFromApi]);



    const tableColumns = [
        createColumns(
            1,
            "Last Updated",
            '',
            userHistoryColumnMapping.lastUpdated,
            tableParameters.orderBy.column === userHistoryColumnMapping.lastUpdated,
            () => null,
            { width: "115px" }
        ),
        createColumns(
            2,
            "Updated By",
            '',
            userHistoryColumnMapping.updatedBy,
            tableParameters.orderBy.column === userHistoryColumnMapping.updatedBy,
            () => null,
            { width: "115px" }
        ),
        createColumns(
            3,
            "Updated Type",
            '',
            userHistoryColumnMapping.updateType,
            tableParameters.orderBy.column === userHistoryColumnMapping.updateType,
            () => null,
            { width: "115px" }
        ),

        createColumns(
            4,
            "Section",
            '',
            userHistoryColumnMapping.section,
            tableParameters.orderBy.column === userHistoryColumnMapping.section,
            () => null,
            { width: "100px" }
        ),
        createColumns(
            5,
            "Sub-Section",
            '',
            userHistoryColumnMapping.subSection,
            tableParameters.orderBy.column === userHistoryColumnMapping.subSection,
            () => null,
            { width: "115px" }
        ),
        createColumns(
            5,
            "Value",
            '',
            userHistoryColumnMapping.value,
            tableParameters.orderBy.column === userHistoryColumnMapping.value,
            () => null,
            { width: "115px" }
        ),

    ];

    const formatDate = (dateValue: any) =>
        dateValue && isDate(new Date(dateValue))
            ? format(new Date(dateValue), "dd MMM yyyy")
            : "";

    const setDateRangeFilter = () => {
        setCloseFilter(true);
        // @ts-ignore
        setTempHistoryDataFromApi([...data.filter((obj: any) => {
            if (isWithinInterval(new Date(`${obj.last_updated}`), {
                // @ts-ignore */
                start: datepickerProps.dateRange.startDate,
                // @ts-ignore */
                end: datepickerProps.dateRange.endDate,
            }) || formatDate(obj.last_updated) === formatDate(datepickerProps.dateRange.endDate))
                return true;
            return false;
        }
        )]
        );
    };

    // clear value handler
    const clearValueHandler = () => {
        setDatepickerProps({
            isSingleDate: false,
            dateRange:
            {
                startDate: null,
                endDate: null,
            },
        })
        setTempHistoryDataFromApi(data)
    }


    const emptyRequestHandler = () => {
        if (historyDataFromApi?.results.length === 0) {
            return (
                <Grid item xs={12} data-testid="empty-delegation-screen">
                    <div className={styleClasses.emptyResultContainer}>
                        <EmptyResultIcon />
                        <span>
                            There are no requests matching to the selected filters.
                        </span>
                    </div>
                </Grid>
            );
        }
        return null;
    };

    const modalContentHandler = () => {
        return (

            <>
                <Grid item xs={12} className={styleClasses.filtersContainer}>
                    <div className={styleClasses.filterPanel}>
                        <div className={styleClasses.filterIcon}>
                            <FilterListIcon />
                        </div>
                        <div className={styleClasses.filterMenu}>
                            <FilterMenu
                                filterLabel="Requested On"
                                handleFilterClose={() => {
                                    if (closeFilter) {
                                        setCloseFilter(false);
                                    }
                                    //console.log('handleClose');
                                }}
                                // badgeCount={filters.requestDate.endDate && filters.requestDate.startDate
                                //   ? differenceInCalendarDays(
                                //     filters.requestDate.endDate,
                                //     filters.requestDate.startDate,
                                //   ) + 1
                                //   : 0}
                                // badgeCount={filters.requestDate.endDate && filters.requestDate.startDate
                                //   ? ' '
                                //   : 0}
                                closeFilterExternally={closeFilter}
                            >
                                <div className={styleClasses.dateRangeToggleContainer}>
                                    <CustomToggleButton
                                        variant="contained"
                                        isSelected={!!datepickerProps.isSingleDate}
                                        onClick={() => {
                                            if (!datepickerProps.isSingleDate) {
                                                setDatepickerProps({
                                                    isSingleDate: true,
                                                    dateRange:
                                                    {
                                                        startDate: null,
                                                        endDate: null,
                                                    },
                                                });
                                            }
                                        }}
                                    >
                                        Single Date
                                    </CustomToggleButton>
                                    <CustomToggleButton
                                        variant="contained"
                                        isSelected={!datepickerProps.isSingleDate}
                                        onClick={() => {
                                            if (datepickerProps.isSingleDate) {
                                                setDatepickerProps({
                                                    isSingleDate: false,
                                                    dateRange:
                                                    {
                                                        startDate: null,
                                                        endDate: null,
                                                    },
                                                });
                                            }
                                        }}
                                    >
                                        Date Range
                                    </CustomToggleButton>
                                </div>
                                <div className={styleClasses.dateRangeContainer}>
                                    <CustomDatePicker
                                        isRangePicker={!datepickerProps.isSingleDate}
                                        setDateRangeValue={setDateRangeValue}
                                        dateRange={datepickerProps.dateRange}
                                        disableFuture
                                    />
                                </div>
                                <div className={styleClasses.dateRangeApplyCta}>
                                    <CustomButton
                                        variant="contained"
                                        actionType="secondary"
                                        disabled={!datepickerProps.dateRange.endDate}
                                        onClick={() => setDateRangeFilter()}
                                        isCompact
                                        disableMargin
                                    >
                                        Apply
                                    </CustomButton>
                                </div>

                            </FilterMenu>
                            <CustomButton
                                variant="contained"
                                actionType="tertiary"
                                onClick={() => {
                                    clearValueHandler()

                                }}
                                disableRipple
                                disableMargin
                                disablePadding
                            >
                                CLEAR
                            </CustomButton>
                        </div>
                    </div>
                </Grid>
                {historyDataFromApi.results.length > 0 ?
                    <div className={styleClasses.historyTable}>
                        <DynamicTable
                            rows={historyDataFromApi.results.slice(0, visible)}
                            isLoading={isLoading}
                            columns={tableColumns}
                            collapsible={false}
                            pageNumber={tableParameters.page}
                            pageSize={10}
                        >
                        </DynamicTable>
                    </div>
                    : emptyRequestHandler()}
            </>
        )
    }

    const fetchHistoryData = () => {
        setTempHistoryDataFromApi(data);
    }

    const loadMoreHandler = () => {
        setVisible(visible + 5);
    }

    useEffect(() => {
        fetchHistoryData();
    }, [])

    const modalHandler = () => {
        setModalOpen(true);
    }
    const handleModalClose = () => {
        setVisible(5);
        setModalOpen(false);
        setTempHistoryDataFromApi(data)
    }

    return (
        <>
            {(data.length > 0) &&
                (<>
                    <div onClick={modalHandler} className={Classes.ViewHistory}>
                        View history
                    </div>
                    <div className={Classes.divderDiv}>
                        <Divider
                            className={Classes.divider}
                            orientation='vertical'
                        />
                    </div>
                </>
                )
            }
            {
                modalOpen && <CustomModal
                    modalHeader='View history'
                    open={modalOpen}
                    onClose={handleModalClose}
                    modalAction={(historyDataFromApi.results.length > visible) && <Button onClick={loadMoreHandler} className={styleClasses.loadButton}>Load more</Button>}
                    modalContent={modalContentHandler()}
                    review
                    centerButton
                    modalHeaderPorps={<CrossIcon style={{ paddingRight: '16px', cursor: 'pointer' }} onClick={handleModalClose} />}
                />
            }
        </>
    )
}

export default HistoryTable;