import React, { useState, useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import FormikControl from '../../../formik-conrtols/FormikControl';
import FieldLabel from '../../../field-label';
import ReviewActions from '../../../review-actions';
import { useDispatch } from 'react-redux';
import { format, isDate } from 'date-fns';
import { RootState } from '../../../../appStore/store';
import { historyDate } from '../../../../constants/updatedField';
import { getAllCompany } from '../../../../api-services/delegation-module';
import { fetchAllCommentsReplyPart, getAllOffices } from '../../../../api-services/par';
import HelperTooltip from '../../../helper-tooltip';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import { ReactComponent as CommentSvg } from '../../../../assets/icons/comment.svg';
import ReviewCommentsContainer from '../../../../modules/par/comments/commentsContainer';
import CustomSvgText from '../../../cutomsvgtext/custtomSvg';
import { stringAvatar } from '../../../../constants/customAvatar';
import { postFieldCommentsPar } from '../../../../api-services/par';
import { ReactComponent as PostArrowButton } from '../../../../assets/icons/post_arrow_button.svg';
import HistoryTable from '../../../../modules/par/comments/historyTable';
import CommentSubmission from '../../../../modules/par/comments/commentSubmission';
import { chatHistoryData } from '../../../../appStore/reducer/chatHistoryReducer';
import { parStyles } from '../../useStyles';
import { Divider } from '@mui/material';
import { fetchfieldHistoryForLastUpdate } from '../../../../api-services/review-delegation';
import { fieldUpdationHistory } from '../../../../appStore/reducer/reviewActionReducer';

const managerDetails = "Please provide contact details for the manager of the account owner. As per the account owner, this should be an internal WPP named individual. If you are raising this request from a 3rd Party, you should provide the details of the manager of your WPP primary contact. In addition, you should also select 3rd Party in the company box below and then provide your own manager information."
const pointOfEscalationToolTip = 'This should be the email address of the account manager from your own company who manages the relationship with WPP'

function ManagerDetails({ isDisabled }: { isDisabled: boolean }) {
    const [anchorEl, setAnchorEl] = useState<SVGElement | HTMLDivElement | null>(
        null,
    );
    const styleClasses = parStyles();
    const dispatch = useDispatch();
    const formikCtx: any = useFormikContext();
    const formatDateInside = (dateValue: any) => (dateValue && isDate(new Date(dateValue)) ? format(new Date(dateValue), 'MMM dd, yyyy') : '');
    const { submitParCommentsData } = useSelector((state: RootState) => state);
    const { submitParRequestorCommentsData } = useSelector((state: RootState) => state);
    const [officeList, setOfficeList] = useState([]);
    const { updatedFieldHistoryData } = useSelector((state: RootState) => state);
    const remediationCount = formikCtx.values.remediation_count;
    const routeCheck = () => {
        return location.pathname.slice(1, 4).toLocaleLowerCase() === 'par'
    }
    const remediated_by = formikCtx.values.remediation_by
    const updatedArray: any = []
    const lastUpdatedFieldHistorValue = (section: string, upadtedFieldHistory: any) => {
        updatedArray.pop();
        upadtedFieldHistory.length > 0 && upadtedFieldHistory.map(function (comment: any) {
            if (comment.section === section) {
                updatedArray.push(comment.last_updated);
            }

        });
    }
    useEffect(() => {
        getAllOffices().then((res) => {
            setOfficeList(res.data);
            console.log(res, 'offices');
        }).catch((err) => {
            console.log(err);
        })
        fetchfieldHistoryForLastUpdate({
            request_id: parseInt(formikCtx.values.request_id),
            request_type: "PAR",
        })
            .then((response) => {

                dispatch(fieldUpdationHistory(response.data));
            })
            .catch((error) => {
                console.log(error);
            });

    }, []);


    const thirdPartyFieldHandler = () => {
        return (
            <>
                <Grid container paddingTop={'12px'} >
                    <Grid item xs={5}>
                        <div>
                            <FieldLabel Title="Please fill 3rd Party details below" required />
                        </div>
                        <div style={{ color: '#A7A7A7', fontFamily: 'NotoSerif-Regular', fontSize: '10px', marginTop: '-5px', paddingBottom: '12px' }}>
                            {/* @ts-ignore */}
                            {(updatedFieldHistoryData[4].Third_Manager_Party_details?.length > 0) && `Updated on ${formatDateInside(updatedFieldHistoryData[4].Third_Manager_Party_details[updatedFieldHistoryData[4].Third_Manager_Party_details?.length - 1].last_updated)}`}
                        </div>
                    </Grid>
                    <Grid item xs={5}>

                    </Grid>
                    <Grid item xs={2}>
                        {routeCheck() ? remediationCount > 0 ? (<div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="Third_Manager_Party_details"
                                subSection=''
                                data={updatedFieldHistoryData.length > 0 ? updatedFieldHistoryData[4].Third_Manager_Party_details : null}
                            />
                            <CommentSubmission
                                sectionName='Third_Manager_Party_details'
                                subSection=''
                                remediation_by={remediated_by}
                                updateType='text update'
                                data={submitParCommentsData.length > 0 ? submitParCommentsData[4].Third_Manager_Party_details?.length > 0 && submitParCommentsData[4].Third_Manager_Party_details : null}
                                requestorData={submitParRequestorCommentsData.length > 0 ? submitParRequestorCommentsData[4].Third_Manager_Party_details?.length > 0 && submitParRequestorCommentsData[4].Third_Manager_Party_details : null}
                            />
                        </div>) : null
                            : (
                                <div className={styleClasses.mainSectionDiv}>
                                    <HistoryTable
                                        parId={formikCtx.values.request_id}
                                        section="Third_Manager_Party_details"
                                        subSection=''
                                        data={updatedFieldHistoryData.length > 0 ? updatedFieldHistoryData[4].Third_Manager_Party_details : null}
                                    />

                                    <CommentSubmission
                                        sectionName='Third_Manager_Party_details'
                                        subSection=''
                                        remediation_by={remediated_by}
                                        updateType='text update'
                                        data={submitParCommentsData.length > 0 ? submitParCommentsData[4].Third_Manager_Party_details?.length > 0 && submitParCommentsData[4].Third_Manager_Party_details : null}
                                        requestorData={submitParRequestorCommentsData.length > 0 ? submitParRequestorCommentsData[4].Third_Manager_Party_details?.length > 0 && submitParRequestorCommentsData[4].Third_Manager_Party_details : null}
                                    />
                                </div>)}
                    </Grid>
                </Grid>
                <Grid container rowSpacing={3} columnSpacing={10}>
                    <Grid item sm={3}>
                        <FormikControl
                            control="textfield"
                            label="3rd Party manager first name"
                            name="par_third_party_manager_first_name"
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <FormikControl
                            control="textfield"
                            label="3rd Party manager last name"
                            name="par_third_party_manager_last_name"
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item sm={6}></Grid>
                    <Grid item sm={3}>
                        <FormikControl
                            control="phone"
                            label="3rd Party manager phone"
                            name="par_third_party_manager_phone"
                            defaultCountry="us"
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <FormikControl
                            control="textfield"
                            label="3rd Party manager email"
                            name="par_third_party_manager_email"
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item sm={6}></Grid>

                    <Grid item sm={3}>
                        <FormikControl
                            control="textfield"
                            label="Point of escalation"
                            name="par_point_of_escalation_in_third_party"
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item xs={1} className={styleClasses.toolTipPosition} >
                        <span className={styleClasses.tooltipSpan}>
                            <HelperTooltip tooltipMsg={pointOfEscalationToolTip}>
                                <HelpRoundedIcon style={{ color: '#0A2756' }} />
                            </HelperTooltip>
                        </span>
                    </Grid>

                </Grid>
            </>
        )
    }
    const managerFieldHandler = () => {
        return (
            <>
                <Grid item xs={3}>
                    <FormikControl
                        control="textfield"
                        label="Manager first name"
                        name="par_internal_manager_first_name"
                        disabled={isDisabled}
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormikControl
                        control="textfield"
                        label="Manager last name"
                        name="par_internal_manager_last_name"
                        disabled={isDisabled}
                    />
                </Grid>
                <Grid item xs={6}>

                </Grid>
                <Grid item xs={3}>
                    <FormikControl
                        control="textfield"
                        label="Manager email"
                        name="par_internal_manager_email"
                        disabled={isDisabled}
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormikControl
                        control="phone"
                        label=" Manager phone"
                        name="par_internal_manager_phone"
                        defaultCountry="us"
                        disabled={isDisabled}
                    />
                </Grid>
                <Grid item xs={6}>

                </Grid>
                <Grid item sm={3}>
                    <FormikControl
                        control="textfield"
                        label="Point of escalation"
                        name="par_point_of_escalation_in_third_party"
                        disabled={isDisabled}
                    />
                </Grid >
                <Grid item xs={1} className={styleClasses.toolTipPosition} >
                    <span className={styleClasses.tooltipSpan}>
                        <HelperTooltip tooltipMsg={pointOfEscalationToolTip}>
                            <HelpRoundedIcon style={{ color: '#0A2756' }} />
                        </HelperTooltip>
                    </span>
                </Grid>

            </>
        )
    }

    return (
        <>
            <Grid container style={{ color: 'red' }}>
                <Grid item xs={5}>
                    <div>
                        <FieldLabel Title="Manager details" tooltipMsg={managerDetails} required />
                    </div>
                    <div style={{ color: '#A7A7A7', fontFamily: 'NotoSerif-Regular', fontSize: '10px', marginTop: '-5px', paddingBottom: '12px' }}>
                        {/* @ts-ignore */}
                        {(updatedFieldHistoryData[3].Manager_details?.length > 0) && `Updated on ${formatDateInside(updatedFieldHistoryData[3].Manager_details[updatedFieldHistoryData[3].Manager_details?.length - 1].last_updated)}`}
                    </div>
                </Grid>
                <Grid item xs={5}>

                </Grid>
                <Grid item xs={2}>
                    {routeCheck() ? remediationCount > 0 ? (<div className={styleClasses.mainSectionDiv}>
                        <HistoryTable
                            parId={formikCtx.values.request_id}
                            section="Manager_details"
                            subSection=''
                            data={updatedFieldHistoryData.length > 0 ? updatedFieldHistoryData[3].Manager_details : null}
                        />

                        <CommentSubmission
                            sectionName='Manager_details'
                            subSection=''
                            remediation_by={remediated_by}
                            updateType='text update'
                            data={submitParCommentsData.length > 0 ? submitParCommentsData[3].Manager_details : null}
                            requestorData={submitParRequestorCommentsData.length > 0 ? submitParRequestorCommentsData[3].Manager_details : null}
                        />
                    </div>) : null
                        :
                        (<div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="Manager_details"
                                subSection=''
                                data={updatedFieldHistoryData.length > 0 ? updatedFieldHistoryData[3].Manager_details : null}
                            />

                            <CommentSubmission
                                sectionName='Manager_details'
                                subSection=''
                                remediation_by={remediated_by}
                                updateType='text update'
                                data={submitParCommentsData.length > 0 ? submitParCommentsData[3].Manager_details : null}
                                requestorData={submitParRequestorCommentsData.length > 0 ? submitParRequestorCommentsData[3].Manager_details : null}
                            />
                        </div>)}
                </Grid>
            </Grid>
            <Grid container rowSpacing={3} columnSpacing={10}>
                {
                    (
                        formikCtx.values.par_company !== ('3rd Party' || 'Third Party')
                    ) && (
                        managerFieldHandler()
                    )
                }
            </Grid>
            <Grid container columnSpacing={10} style={{ paddingTop: '20px' }}>
                <Grid item sm={3} >
                    <FormikControl
                        control="autocomplete"
                        label="Office"
                        name="par_is_third_party_office"
                        options={
                            officeList?.map((officeObj: any) => ({
                                label: officeObj.office,
                                value: officeObj.office,
                            }))
                        }
                        disabled={isDisabled}
                    />
                </Grid>
                <Grid item xs={1} className={styleClasses.toolTipPosition} >
                    <span className={styleClasses.tooltipSpan}>
                        <HelperTooltip tooltipMsg='If you are from a non-WPP <br /> company, please select <b>3rd Party</b>'>
                            <HelpRoundedIcon style={{ color: '#0A2756' }} />
                        </HelperTooltip>
                    </span>
                </Grid>
            </Grid>
            {
                (
                    formikCtx.values.par_company === ('3rd Party' || 'Third Party')
                ) && (
                    thirdPartyFieldHandler()
                )
            }
        </>
    )
}

export default ManagerDetails;