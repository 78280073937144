import { pink } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";

interface IStyleProps {
  color: string;
  background: string;
}

const statusBadgeStyles = makeStyles({
  statusBadge: {
    font: "normal normal normal 14px/15px NotoSerif-Regular",
    letterSpacing: "-0.06px",
    padding: "4px 8px",
    margin: "5.25px 0px",
    borderRadius: "2px",
    whiteSpace: "nowrap",
    color: (props: IStyleProps) => props.color,
    background: (props: IStyleProps) => props.background,
  },
  pendingApproval: {
    color: "#028886",
    paddingLeft: "0px",
    paddingTop: "0px",
    font: "normal normal normal 14px/14px NotoSerif-Regular",
    letterSpacing: "-0.1px",
    margin: "0 0 0 8px",
    whiteSpace: "nowrap",
  },
  pendingApprovalContainer: {
    display: "inline-flex",
    flexDirection: "column",
  },
  superScript: {
    fontSize: "10px",
    verticalAlign: "super",
  },
  remediationMain: {
    display: "flex",
    flexDirection: "column",
    width: "300px",
  },
  remediationStage: {
    padding: "4px 8px 0 8px",
    margin: 0,
  },
  remediationBy: {
    padding: "2px 8px 4px 8px",
    margin: 0,
    fontSize: "12px",
  },
  isUpdateRequested: {
    font: "normal normal normal 14px/15px NotoSerif-Regular",
    letterSpacing: "-0.06px",
    padding: "4px 8px",
    margin: "5.25px 0px",
    borderRadius: "2px",
    whiteSpace: "nowrap",
    color: "#9B5C46",
    background: "#FFEDCA",
  },
});

export default statusBadgeStyles;
