import format from 'date-fns/format/index';
import addDays from 'date-fns/addDays/index';

export const parBody = {
    del_request_id: '',
    status: '1',
    request_type: '2',
    request_purpose: '',
    event: '',
    next_priority: null,
    request_id: '',
    par_requestor_first_name: '',
    par_requestor_last_name: '',
    par_requestor_email: '',
    par_date_raised: '',
    par_first_name: '',
    par_last_name: '',
    par_department: '',
    par_company: '',
    par_city: '',
    par_email: '',
    par_phone: '',
    par_job_title: '',
    par_job_title_explanation: '',
    par_internal_manager_name: '',
    par_is_third_party_office: '',
    par_is_third_party_company: '',
    par_third_party_manager_first_name: '',
    par_third_party_manager_last_name: '',
    par_third_party_manager_phone: '',
    par_third_party_manager_email: '',
    par_point_of_escalation_in_third_party: '',
    par_servicenow_ticket_number: '',
    par_domain: '',
    par_account_justification: '',
    par_account_name: '',
    par_user_logon_name: '',
    par_pre_windows_2000_username: '',
    par_country: '',
    par_not_listed_third_party_company: '',
    par_internal_manager_first_name: '',
    par_internal_manager_last_name: '',
    par_internal_manager_email: '',
    par_internal_manager_phone: '',
    Job_description: '',
    Account_owner_details: '',
    Third_Manager_Party_details: '',
    Manager_details: '',
    ServiceNow_ticket_number: '',
    Domain: '',
    mark_step_aad_onPrem: false,
    review_account_requirements: '',
    is_step_one_completed_cloud: false,
    enter_user_logon_name: '',
    is_user_logon_name_entered: false,
    enter_proposed_user_name: '',
    is_proposed_user_name_entered: false,
    enter_display_name: '',
    is_display_name_entered: false,
    enter_first_name_and_last_name: false,
    copy_password_for_end_user: false,
    add_usage_location: false,
    entered_job_info_section: false,
    user_created_or_exist: false,
    is_user_added: false,
    assign_license: null,
    assign_admin_role_to_account: false,
    enroll_pim: false,
    shared_account_details_groupm: false,
    is_step_two_completed_cloud: false,
    login_to_adaxes: false,
    is_select_create_admin: false,
    enter_user_logon_name_in_adaxes: '',
    is_entered_user_logon_name: false,
    enter_correct_role_group_name: '',
    is_correct_role_group_entered: false,
    communicate_password_securely: false,
    is_step_complete_ad: false,
}