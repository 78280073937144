import React from 'react';
import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  appBarFooter: {
    background: '#F6F6F6 0% 0% no-repeat padding-box !important',
    boxShadow: 'none !important',
    bottom: 0,
    right: 0,
    position: 'fixed',
    left: 0,
    zIndex: 25,
    padding: 16,
  },
});

interface IStyledFooter {
  children: React.ReactNode,
}
function Footer({
  children,
}: IStyledFooter) {
  const classes = styles();
  return (
    <footer className={classes.appBarFooter}>
      {children}
    </footer>
  );
}
export default Footer;
