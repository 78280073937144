import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';

const useStyle = makeStyles({
  input: {
    textColor: '#0A2756',
    color: '#0A2756',
    WebkitTextFillColor: '#0a2756 !important',
    font: 'normal normal normal 14px/19px NotoSerif',
  },
});

interface IStyledIconTextField {
  iconStart?: any,
  iconEnd?: any,
  value?: any,
  onClick?: (_e: any) => void,
}
function IconTextField({
  iconEnd,
  iconStart,
  value,
  onClick,
}: IStyledIconTextField) {
  const classes = useStyle();
  return (
    <TextField
      data-testid="icon-text-field"
      disabled
      size="small"
      value={value}
      InputProps={{
        startAdornment: iconStart ? (
          <InputAdornment position="start" data-testid="adornment-start">{iconStart}</InputAdornment>
        ) : null,
        endAdornment: iconEnd ? (
          <InputAdornment position="end" data-testid="adornment-end">{iconEnd}</InputAdornment>
        ) : null,
        classes: { input: classes.input },
      }}
      onClick={onClick}
    />
  );
}
export default IconTextField;
