const calendarStyles = `
  .MuiPickerStaticWrapper-root {
    min-width: 250px;
  }
  .MuiPickerStaticWrapper-root > div {
    min-width: 250px;
  }
  .MuiPickerStaticWrapper-root > div > div, .MuiPickerStaticWrapper-root > div > div > div, .MuiCalendarPicker-root {
    width: 250px;
  }
  .MuiCalendarPicker-root > div:first-child {
    padding-left: 16px;
    padding-right: 8px;
    color: #A7A7A7;
    margin-top: 0px;
    margin-bottom: 0px;
  }
 .MuiCalendarPicker-root > div:first-child > div{
    font: normal normal normal 13px/13px 'Poppins-Regular';
    font-family: 'Poppins-Regular' !important;
    letter-spacing: 0px;
  }
  .MuiCalendarPicker-root > div:first-child > div:last-child > div {
    width: 4px;
  }
  .MuiCalendarPicker-viewTransitionContainer > div > div > span {
    margin: 0px;
    font: normal normal normal 12px/13px 'Poppins-Medium';
    letter-spacing: 0px;
    color: #0A2756;
    width: 32px;
    height: 32px;
  }
  .MuiCalendarPicker-viewTransitionContainer > div > div > span:nth-child(1)::after {
    content: 'u';
  }
  .MuiCalendarPicker-viewTransitionContainer > div > div > span:nth-child(2)::after {
    content: 'o';
  }
  .MuiCalendarPicker-viewTransitionContainer > div > div > span:nth-child(3)::after {
    content: 'u';
  }
  .MuiCalendarPicker-viewTransitionContainer > div > div > span:nth-child(4)::after {
    content: 'e';
  }
  .MuiCalendarPicker-viewTransitionContainer > div > div > span:nth-child(5)::after {
    content: 'h';
  }
  .MuiCalendarPicker-viewTransitionContainer > div > div > span:nth-child(6)::after {
    content: 'r';
  }
  .MuiCalendarPicker-viewTransitionContainer > div > div > span:nth-child(7)::after {
    content: 'a';
  }
  .PrivatePickersSlideTransition-root {
    min-height: 210px;
  }
  .MuiYearPicker-root {
    justify-content: center;
  }
  .PrivatePickersYear-yearButton {
    height: 40px;
    width: 40px;
    border-radius: 4px;
    margin: 2px;
    font-size: 13px;
    font-family: 'NotoSerif-Regular';
  }
  .PrivatePickersYear-yearButton:hover{
    background: #EEF2F3;
  }
  .PrivatePickersYear-yearButton.Mui-selected, .PrivatePickersYear-yearButton.Mui-selected:hover {
    background-color: #99FDD1;
    color: #0A2756;
    font-family: 'NotoSerif-Bold';
  }
  .MuiPickersDay-root.MuiPickersDay-dayOutsideMonth {
    color: #A7A7A7 !important;
  }
  .PrivatePickersFadeTransitionGroup-root.MuiCalendarPicker-viewTransitionContainer {
    max-height: 242px;
  }
  .MuiPickersDay-root.MuiPickersDay-today {
    border: none;
  }
  `;

export default calendarStyles;
