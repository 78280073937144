import * as Yup from 'yup';
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TextField from '@mui/material/TextField';
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { format, isDate, isWithinInterval } from "date-fns";
import CustomModal from '../../../components/custom-modal';
import _, { isEmpty } from "lodash";
import FieldLabel from '../../../components/field-label';
import adminSummaryStyles from "../administrative-summary-styles";
import {
  Formik,
  Form,
  ArrayHelpers,
  FieldArray,
  FormikProps,
  FormikValues,
} from 'formik';
import { Grid } from "@mui/material";
import FormikControl from '../../../components/formik-conrtols/FormikControl';
import {
  AdministrativeSummaryColumnMapping,
  createColumns,
  emptyFilters,
  IFiltersObj,
  initialTableParameters,
  ITableParameters,
  IAdministrativeSummaryData,
  changeData,
  filterDataType,
  columnArrayForHistoryTable,
} from "./administrativeSummaryUtils";
import {
  fetchAllAdministrativeSummaryData,
  postAllUpdatedRoles,
  fetchAllRequestorsAdmin,
  fetchAllApproversAdmin,
  fetchAllImplementorsAdmin,
  postUpdatedionEndPointsAdminstrativeSummary,
  fetchJustificationForDeny,
  postAdminJustifcationAllowOrDeny,
  fetchAllUpdateHistory,
  postAdminDeny,
  updateHistory,
  fetchAllAdminStatus,
} from "../../../api-services/administrative-summary";
import { ReactComponent as Sorting } from "../../../assets/icons/sorting_asc_inactive.svg";
import { ReactComponent as SortingAscending } from "../../../assets/icons/sorting_asc.svg";
import { ReactComponent as SortingDescending } from "../../../assets/icons/sorting_desc.svg";
import useDaterangePicker from "../../../components/date-range-picker/useDaterangePicker";
import { createRowCells } from "./administrativeSummaryUtils";
import StatusBadge from "./StatusBadge";
import { useSnackbar } from "notistack";

import CustomButton from "../../../components/button";

import DynamicTableHistory from '../../../components/review-actions/dynamicTableHistory';
import { getRequestData } from '../../../api-services/delegation-module';



const daysPending = [
  {
    id: 'More than 10 days',
    value: 'More than 10 days'
  },
  {
    id: 'More than 20 days',
    value: 'More than 20 days'
  },
  {
    id: 'More than 30 days',
    value: 'More than 30 days'
  },
]

function useAdministrativeSummaryHook() {

  const initialValues = {
    change_requestor: '',
  };
  const validationSchema = Yup.object({
    change_requestor: Yup.string().required('Required'),
  });
  const history = useHistory();
  const styleClasses = adminSummaryStyles();
  const initialRender = useRef(true);
  const { enqueueSnackbar } = useSnackbar();


  // AutoComplete States
  const [changeRequestorData, setchangeRequestorData] = useState<changeData[]>([])

  //FILTER START
  const [isLoadingPending, setIsLoadingPending] = useState({
    tableLoading: false,
    filters: {
      requestStatus: false,
      requestId: false,
      requestorName: false,
      daysPending: false,
      actionOwner: false,
      requestType: false,
    },
  });
  const [filters, setFilters] = useState<IFiltersObj>(emptyFilters);
  const [requestIdFilter, setRequestIdFilters] = useState([]);
  const [requestorNameFilter, setRequestorNameFilters] = useState<filterDataType[]>([{ id: '', value: '' }]);
  const [requestTypeFilter, setRequestTypeFilters] = useState([]);
  const [requestStatusFilter, setRequestStatusFilters] = useState([]);
  const [actionOwnerFilter, setActionOwnerFilters] = useState([]);
  const [daysPendingFilter, setDaysPendingFilters] = useState([]);
  const [closeFilter, setCloseFilter] = useState(false);
  const [denyModalOPen, setDenyModalOpen] = useState(false);
  const [justForDeny, setJustForDeny] = useState('')
  const [viewHistoryData, setViewHistoryData] = useState([]);
  const { datepickerProps, setDatepickerProps, setDateRangeValue } =
    useDaterangePicker({ isSingleDate: true });
  //FILTER END

  //LISTING START
  const [tableParametersPending, setTableParametersPending] =
    useState<ITableParameters>(initialTableParameters);

  const [administrativeSummaryData, setAdministrativeSummaryData] =
    useState<any>({
      results: [],
      pageSize: 10,
    });
  const [administrativeSummaryDataFromApi, setAdministrativeSummaryDataFromApi] = useState([]);
  const [tempAdministrativeSummaryDataFromApi, setTempAdministrativeSummaryDataFromApi] = useState([]);
  //LISTING END

  //ACTION MENU START
  const [menuData, setMenuData] = useState({
    anchorEl: null,
    row: {
      request_id: null,
      stage_id: null,
      request_type: null,
      request_status: null,
      is_request_to_update: null,
      linked_delegation: null,
      request_stage: null,
      request_to_update_count: 0,
      implementor_type_id: null,
      request_number: null,
      request_purpose: null,
      implementer_type: null,
    },
  });
  const [modalData, setModalData] = useState<{
    data: any;
    type: string;
  }>({ data: null, type: "" });
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [updateReason, setUpdateReason] = useState("");
  const [denyJustification, setDenyJustification] = useState('')
  const [initiateRequest, setInitiateRequest] = useState({
    purpose: "",
    requestType: "",
  });
  const [isAPILoading, setIsAPILoading] = useState(false);
  const formRef = useRef<FormikProps<FormikValues>>(null);
  const [shouldRefetchDelegations, setShouldRefetchDelegations] =
    useState(false);
  const [open, setOpen] = useState(false);
  const [rowValue, setRowValue] = useState<{
    request_Id: string;
    stage_Id: string;
  }>({ request_Id: "", stage_Id: "" });
  //ACTION MENU END


  // all filters api calling

  const denyOnChangeHandler = (e: any) => {
    setDenyJustification(e.target.value);
  }

  const fetchFiltersValues = () => {
    setIsLoadingPending((loadingState) => ({
      ...loadingState,
      filters: {
        ...loadingState.filters,
        requestStatus: true,
        requestId: true,
        requestorName: true,
        daysPending: true,
        actionOwner: true,
        requestType: true,
      },
    }));

    fetchAllAdminStatus().then(
      (successData: any) => {
        setRequestStatusFilters(successData?.data);
        setIsLoadingPending((loadingState) => ({
          ...loadingState,
          filters: {
            ...loadingState.filters,
            status: false,
          },
        }));
      },
      (error: any) => {
        setRequestStatusFilters([]);
        setIsLoadingPending((loadingState) => ({
          ...loadingState,
          filters: {
            ...loadingState.filters,
            status: false,
          },
        }));
        console.error(error);
      }
    )
  };


  const setDateRangeFilter = () => {

    setCloseFilter(true);
    let requestedOnData = administrativeSummaryDataFromApi.filter((obj: IAdministrativeSummaryData) => {
      if (isWithinInterval(new Date(`${obj.requested_on}`), {
        // @ts-ignore */
        start: datepickerProps.dateRange.startDate,
        // @ts-ignore */
        end: datepickerProps.dateRange.endDate,
      }) || formatDate(obj.requested_on) === formatDate(datepickerProps.dateRange.endDate))
        return true;
      return false;
    }
    )
    setTempAdministrativeSummaryDataFromApi([...requestedOnData]);
    setTableParametersPending((prevParameters: any) => ({
      ...prevParameters,
      page: Math.ceil(requestedOnData.length / administrativeSummaryData.pageSize),
    }));
  };

  // clear value handler
  const clearValueHandler = () => {
    setIsLoadingPending((loadingState) => ({ ...loadingState, tableLoading: false }));
    setDatepickerProps({
      isSingleDate: false,
      dateRange:
      {
        startDate: null,
        endDate: null,
      },
    })
    setFilters(emptyFilters)
    setTempAdministrativeSummaryDataFromApi(administrativeSummaryDataFromApi)
  }
  // FILTER FUNCTIONS END

  // for sorting
  useEffect(() => {
    tableParametersPending.orderBy.column !== '' ? createAdminSummary() : setTempAdministrativeSummaryDataFromApi(administrativeSummaryDataFromApi);
  }, [tableParametersPending.orderBy.column, tableParametersPending.orderBy.order]);


  // For sorting Column Name
  const createAdminSummary = () => {
    console.log(tableParametersPending.orderBy.column,)
    setTempAdministrativeSummaryDataFromApi((prevData) => [...prevData.sort(function (valueOne, valueTwo) {
      // if (
      //   tableParametersPending.orderBy.column === 'requested_on' ||
      //   tableParametersPending.orderBy.column === 'updated_at' ||
      //   tableParametersPending.orderBy.column === 'created_at'
      // ) {
      //   if (tableParametersPending.orderBy.order === 'asc')
      //     return (
      //       new Date(valueOne[tableParametersPending.orderBy.column]).getTime() -
      //       new Date(valueTwo[tableParametersPending.orderBy.column]).getTime()
      //     );
      //   if (tableParametersPending.orderBy.order === 'desc')
      //     return (
      //       new Date(valueTwo[tableParametersPending.orderBy.column]).getTime() -
      //       new Date(valueOne[tableParametersPending.orderBy.column]).getTime()
      //     );
      //   return 0;
      // }
      // else {
      if (
        valueOne[tableParametersPending.orderBy.column] <
        valueTwo[tableParametersPending.orderBy.column]
      )
        return tableParametersPending.orderBy.order === 'asc' ? -1 : 1;
      if (
        valueOne[tableParametersPending.orderBy.column] >
        valueTwo[tableParametersPending.orderBy.column]
      )
        return tableParametersPending.orderBy.order === 'asc' ? 1 : -1;
      return 0;
      // }
    }),
    ]);
  };

  const handleSort = (e: any) => {
    setTableParametersPending((prevParameters) => {
      const newOrderBy = { ...prevParameters.orderBy };
      if (e.target.id !== newOrderBy.column) {
        newOrderBy.order = "asc";
        newOrderBy.column = e.target.id;
      } else if (prevParameters.orderBy.order === "desc") {
        newOrderBy.order = "asc";
        newOrderBy.column = "";
      } else {
        newOrderBy.order = "desc";
      }
      return { ...prevParameters, orderBy: { ...newOrderBy } };
    });
  };


  const getSortingIcon = (columnId: string) => {
    if (tableParametersPending.orderBy.column === columnId) {
      return tableParametersPending.orderBy.order === "desc" ? (
        <SortingDescending />
      ) : (
        <SortingAscending />
      );
    }
    return <Sorting />;
  };
  // LISTING FUNCATIONS START

  // Listing api calling
  useEffect(() => {

    const rowsArray = tempAdministrativeSummaryDataFromApi.map((row: any) => {
      const cellArray = Object.entries(row)
        .map(([key, value]) => {
          let cellData: any = null;
          switch (key) {
            case "request_number":
              cellData = createRowCells(
                AdministrativeSummaryColumnMapping.request_number,
                `${value}`,
                `${row.request_id}-${value}`,
                1,
                () => openDelegation(row.request_id, row.request_type, row.implementer_type_id),
                { textDecoration: "underline", color: "#0080FF" }
              );
              cellData.value = value;
              break;
            case "requestor_name":
              cellData = createRowCells(
                AdministrativeSummaryColumnMapping.requestorName,
                `${value || ""}`,
                `${row.request_id}-${value || ""}`,
                2
              );
              break;
            case "request_type":
              cellData = createRowCells(
                AdministrativeSummaryColumnMapping.requestType,
                `${value || ""}`,
                `${row.request_id}-${value || ""}`,
                3
              );
              break;
            case "request_status":
              cellData = createRowCells(
                AdministrativeSummaryColumnMapping.requestStatus,
                <StatusBadge
                  status={value}
                  requestPurpose={row.request_purpose}
                  requestStage={row.request_stage}
                  remediationStage={row.remediation_stage}
                  remediationBy={row.remediation_by}
                  stageId={row.request_stage_priority}
                  remediationCount={row.remediation_count}
                  isUpdateRequested={row.is_request_to_update}
                  superScript={row.request_to_update_count}
                  isRequestToUpdateCount={row.request_to_update_count}
                  requestType={row.request_type}
                />,
                `${row.request_id}-${value}`,
                4
              );
              break;
            case "action_owner":
              cellData = createRowCells(
                AdministrativeSummaryColumnMapping.actionOwner,
                `${value || ""}`,
                ``,
                5
              );
              break;
            case "days_pending":
              cellData = createRowCells(
                AdministrativeSummaryColumnMapping.daysPending,
                `${value || ""}`,
                `${row.request_id}-${value || ""}`,
                6
              );
              break;
            case "requested_on":
              cellData = createRowCells(
                AdministrativeSummaryColumnMapping.requestedOn,
                `${formatDate(value)}`,
                `${row.request_id}-${value || ""}`,
                7
              );
              break;
            default:
              break;
          }
          return cellData;
        })
        .filter((obj: any) => obj);
      if (
        row.request_status === "Archived" ||
        row.request_status === "Rejected"
      ) {
        cellArray.push(
          createRowCells(
            AdministrativeSummaryColumnMapping.actions,
            "",
            `more-actions-${row.id}`,
            9,
            undefined,
            { textAlign: "center" }
          )
        );
      } else {
        cellArray.push(
          createRowCells(
            AdministrativeSummaryColumnMapping.actions,
            <MoreVertIcon
              className={`${styleClasses.moreIcon} ${!!menuData.anchorEl && styleClasses.moreIconActive
                }`}
            />,
            `more-actions-${row.id}`,
            9,
            (e) => openMenu(
              e,
              row.request_id,
              row.stage_id,
              row.request_type,
              row.request_status,
              row.is_request_to_update,
              row.linked_delegation,
              row.request_stage,
              row.request_to_update_count,
              row.implementer_type_id,
              row.request_number,
              row.request_purpose,
              row.implementer_type
            ),
            { textAlign: "center" }
          )
        );
      }

      return cellArray;
    });
    setAdministrativeSummaryData((prevState: any) => ({ ...prevState, results: [...rowsArray] }))
  }, [tempAdministrativeSummaryDataFromApi])

  // set adminstrativeSummary page  Data onload once

  const fetchAllAdministrativeSummaryDataS = async () => {
    // @ts-ignore
    await fetchAllAdministrativeSummaryData().then((res: any) => {
      setAdministrativeSummaryDataFromApi(res.data.sort((a: any, b: any) => b.updated_at.localeCompare(a.updated_at)));
      setTempAdministrativeSummaryDataFromApi(res.data.sort((a: any, b: any) => b.updated_at.localeCompare(a.updated_at)));
      // @ts-ignore
      //  get unique keys for filter values
      const requestorName = [...new Set(res.data?.map(({ requestor_name }) => requestor_name))].map(e => res.data?.find(({ requestor_name }) => requestor_name == e));
      //const requestorName = [...new Set(res.data?.map(({ requestor_name }) => requestor_name.toLowerCase()))];
      // @ts-ignore
      const actionOwner = [...new Set(res.data?.map(({ action_owner }) => action_owner))].map(e => res.data?.find(({ action_owner }) => action_owner == e));
      // @ts-ignore
      const requestType = [...new Set(res.data?.map(({ request_type }) => request_type))].map(e => res.data?.find(({ request_type }) => request_type == e));

      // @ts-ignore
      setRequestorNameFilters(requestorName?.map((requestTypeObj: { requestor_name: string }) => ({
        id: requestTypeObj.requestor_name,
        value: requestTypeObj.requestor_name === "" ? "(Blanks)" : requestTypeObj.requestor_name,
      })))
      // setRequestorNameFilters(requestorName?.map((item: string) => ({
      //   id: item,
      //   value: item,
      // })))
      // @ts-ignore */
      setRequestTypeFilters(requestType?.map((requestTypeObj: { request_type: string }) => ({
        id: requestTypeObj.request_type,
        value: requestTypeObj.request_type === "" ? "(Blanks)" : requestTypeObj.request_type,
      })))
      // @ts-ignore */
      setRequestIdFilters(res.data?.map((requestIdObj: { request_number: string }) => ({
        id: String(requestIdObj.request_number),
        value: String(requestIdObj.request_number),
      })))
      // @ts-ignore */
      setActionOwnerFilters(actionOwner?.map((actionOwnerObj: { action_owner: string }) => ({
        id: actionOwnerObj.action_owner,
        value: actionOwnerObj.action_owner === "" ? "(Blanks)" : actionOwnerObj.action_owner,
      })))
      // @ts-ignore */
      setDaysPendingFilters(daysPending?.map((daysPendingObj: { id: number, value: string }) => ({
        id: daysPendingObj.id,
        value: daysPendingObj.value === null ? null : daysPendingObj.value,
      })))

      setIsLoadingPending((loadingState) => ({
        ...loadingState,
        tableLoading: false, filters: {
          requestStatus: false,
          requestId: false,
          requestorName: false,
          daysPending: false,
          actionOwner: false,
          requestType: false,
        }
      }));

    }).catch((error: string) => {
      enqueueSnackbar('Something went wrong! Please try again', { variant: 'error' });
      setIsLoadingPending((loadingState) => ({
        ...loadingState, tableLoading: false, filters: {
          requestStatus: false,
          requestId: false,
          requestorName: false,
          daysPending: false,
          actionOwner: false,
          requestType: false,
        }
      }));
    })
  }

  useEffect(() => {
    fetchAllAdministrativeSummaryDataS();
    fetchFiltersValues();
    setIsLoadingPending((loadingState) => ({
      ...loadingState, tableLoading: true, filters: {
        requestStatus: true,
        requestId: true,
        requestorName: true,
        daysPending: true,
        actionOwner: true,
        requestType: true,
      },
    }));
  }, []);

  const tableColumnsForPending = [
    createColumns(
      1,
      "REQ ID",
      () => null,
      AdministrativeSummaryColumnMapping.requestId,
      tableParametersPending.orderBy.column ===
      AdministrativeSummaryColumnMapping.requestId,
      () => null,
      { width: "65px" }
    ),
    createColumns(
      2,
      "Requestor Name",
      getSortingIcon(AdministrativeSummaryColumnMapping.requestorName),
      AdministrativeSummaryColumnMapping.requestorName,
      tableParametersPending.orderBy.column ===
      AdministrativeSummaryColumnMapping.requestorName,
      (e) => handleSort(e),
      { width: "112px" }
    ),
    createColumns(
      3,
      "Request Type",
      getSortingIcon(AdministrativeSummaryColumnMapping.requestType),
      AdministrativeSummaryColumnMapping.requestType,
      tableParametersPending.orderBy.column ===
      AdministrativeSummaryColumnMapping.requestType,
      (e) => handleSort(e),
      { width: "112px" }
    ),
    createColumns(
      4,
      "Request Status",
      getSortingIcon(AdministrativeSummaryColumnMapping.requestStatus),
      AdministrativeSummaryColumnMapping.requestStatus,
      tableParametersPending.orderBy.column ===
      AdministrativeSummaryColumnMapping.requestStatus,
      (e) => handleSort(e),
      { width: "135px" }
    ),
    createColumns(
      5,
      "Action Owner",
      getSortingIcon(AdministrativeSummaryColumnMapping.actionOwner),
      AdministrativeSummaryColumnMapping.actionOwner,
      tableParametersPending.orderBy.column ===
      AdministrativeSummaryColumnMapping.actionOwner,
      (e) => handleSort(e),
      { width: "112px" }
    ),
    createColumns(
      6,
      "Days Pending",
      getSortingIcon(AdministrativeSummaryColumnMapping.daysPending),
      AdministrativeSummaryColumnMapping.daysPending,
      tableParametersPending.orderBy.column ===
      AdministrativeSummaryColumnMapping.daysPending,
      (e) => handleSort(e),
      { width: "112px" }
    ),
    createColumns(
      7,
      "Requested On",
      getSortingIcon(AdministrativeSummaryColumnMapping.requestedOn),
      AdministrativeSummaryColumnMapping.requestedOn,
      tableParametersPending.orderBy.column ===
      AdministrativeSummaryColumnMapping.requestedOn,
      (e) => handleSort(e),
      { width: "112px" }
    ),
    createColumns(
      8,
      "Actions",
      null,
      AdministrativeSummaryColumnMapping.actions,
      false,
      undefined,
      { width: "20px" }
    ),
  ];
  // LISTING FUNCATIONS END

  // filtering
  const fetchFilteredDataWithDebounce = (filtersObject: any) => {
    if (filtersObject?.requestorName?.length > 0) {
      let requestorNameData = administrativeSummaryDataFromApi.filter((obj: IAdministrativeSummaryData) => (filtersObject?.requestorName.find((item: string) => item === obj.requestor_name))
      )
      setTempAdministrativeSummaryDataFromApi([...requestorNameData]);
      setTableParametersPending((prevParameters: any) => ({
        ...prevParameters,
        page: Math.ceil(requestorNameData.length / administrativeSummaryData.pageSize),
      }));
    }
    else if (filtersObject?.requestId?.length > 0) {
      let requestIdData = administrativeSummaryDataFromApi.filter((obj: IAdministrativeSummaryData) => (filtersObject?.requestId.find((item: string) => item === String(obj.request_number))))
      setTempAdministrativeSummaryDataFromApi([...requestIdData]);
      setTableParametersPending((prevParameters: any) => ({
        ...prevParameters,
        page: Math.ceil(requestIdData.length / administrativeSummaryData.pageSize),
      }));
    }
    else if (filtersObject?.requestType?.length > 0) {
      let requestTypeData = administrativeSummaryDataFromApi.filter((obj: IAdministrativeSummaryData) => (filtersObject?.requestType.find((item: string) => item === obj.request_type)))
      setTempAdministrativeSummaryDataFromApi([...requestTypeData]);
      setTableParametersPending((prevParameters: any) => ({
        ...prevParameters,
        page: Math.ceil(requestTypeData.length / administrativeSummaryData.pageSize),
      }));
    }
    else if (filtersObject?.requestStatus?.length > 0) {
      let requestStatusData = administrativeSummaryDataFromApi.filter((obj: IAdministrativeSummaryData) =>
        (filtersObject?.requestStatus.find((item: string) => item == obj.status_id))
      )
      setTempAdministrativeSummaryDataFromApi([...requestStatusData]);
      setTableParametersPending((prevParameters: any) => ({
        ...prevParameters,
        page: Math.ceil(requestStatusData.length / administrativeSummaryData.pageSize),
      }));
    }
    else if (filtersObject?.actionOwner?.length > 0) {
      let actionOwnerData = administrativeSummaryDataFromApi.filter((obj: IAdministrativeSummaryData) =>
        (filtersObject?.actionOwner.find((item: string) => item == obj.action_owner))
      )
      setTempAdministrativeSummaryDataFromApi([...actionOwnerData]);
      setTableParametersPending((prevParameters: any) => ({
        ...prevParameters,
        page: Math.ceil(actionOwnerData.length / administrativeSummaryData.pageSize),
      }));
    }
    else if (filtersObject?.daysPending?.length > 0) {
      let daysPendingData = administrativeSummaryDataFromApi.filter((obj: IAdministrativeSummaryData) =>
        (filtersObject?.daysPending.find((item: number) => item == obj.days_pending))
      )
      setTempAdministrativeSummaryDataFromApi([...daysPendingData]);
      setTableParametersPending((prevParameters: any) => ({
        ...prevParameters,
        page: Math.ceil(daysPendingData.length / administrativeSummaryData.pageSize),
      }));
    }
    // else if (filtersObject. > 0) {
    //   setTempAdministrativeSummaryDataFromApi([...administrativeSummaryDataFromApi.filter((obj: IAdministrativeSummaryData) =>
    //     (filtersObject?.daysPending.find((item: number) => item == obj.days_pending))
    //   )]);
    // }
    else {
      setTempAdministrativeSummaryDataFromApi(administrativeSummaryDataFromApi);
    }
  }

  useEffect(() => {
    fetchFilteredDataWithDebounce(filters);

  }, [filters]);

  // ACTION MENU FUNCATIONS START
  useEffect(() => {
    if (!modalOpen) {
      setModalData({ data: null, type: "" });
    }
  }, [modalOpen]);


  const openDelegation = (id: any, requestType: string, implementerTypeId: any) => {
    setMenuData({
      anchorEl: null,
      row: {
        request_id: null,
        stage_id: null,
        request_type: null,
        request_status: null,
        is_request_to_update: null,
        linked_delegation: null,
        request_stage: null,
        request_to_update_count: 0,
        implementor_type_id: null,
        request_number: null,
        request_purpose: null,
        implementer_type: null
      },
    });

    if (requestType === 'Delegation') {
      const Admin = 'Administrator';
      getRequestData(id, Admin)
        .then((successData) => {
          if (successData.message == 'request already open with another administrator') {
            enqueueSnackbar("request already open with another administrator", {
              variant: "error",
            })
          }
          else if (successData.data[0].request_purpose && successData.data[0].request_purpose == "Connect to / manage a server or computer") {
            history.push(`/myadministrativeTasks/delegation/${id}`);
          } else {
            history.push(`/myadministrativeTasks/sar/${id}`);
          }
        })
    } else if (requestType === 'PAR') {
      history.push(`/myadministrativeTasks/par/${id}`);
    }
    return null;
  };

  const formatDate = (dateValue: any) =>
    dateValue && isDate(new Date(dateValue))
      ? format(new Date(dateValue), "dd MMM yyyy")
      : "";

  // action menu popup
  const openMenu = (e: any,
    rowId: any,
    stageId: any,
    requestType: any,
    request_status: any,
    is_request_to_update: any,
    linked_delegation: any,
    request_stage: any,
    request_to_update_count: any,
    implementor_type_id: any,
    request_number: any,
    request_purpose: any,
    implementer_type: any
  ) => {
    setMenuData({
      anchorEl: e.target,
      row:
      {
        request_id: rowId,
        stage_id: stageId,
        request_type: requestType,
        request_status: request_status,
        is_request_to_update: is_request_to_update,
        linked_delegation: linked_delegation,
        request_stage: request_stage,
        request_to_update_count,
        implementor_type_id,
        request_number,
        request_purpose,
        implementer_type
      },
    });
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setUpdateReason("");
    setInitiateRequest({ purpose: "", requestType: "" });
    setDenyModalOpen(false);
  };

  const handleModalOpen = (actionType: string) => {
    if (menuData) {
      setModalData({ data: { ...menuData.row }, type: actionType });
      if (actionType === "changeRequestor") {
        fetchAllRequestorsAdmin().then((response: any) => {
          setchangeRequestorData(response.data);
        }).catch(error => {
          enqueueSnackbar('Something went wrong! Please try again', { variant: 'error' });
          console.log(error)
        })
      } else if (actionType === "changeApprover") {
        fetchAllApproversAdmin(menuData.row.stage_id).then((response: any) => {
          const tempArray: changeData[] = [];
          !isEmpty(response.data?.primary) && tempArray.push({ id: response.data?.primary.id, name: `${response.data?.primary.name} (primary approver)`, stage: 'primary' })
          !isEmpty(response.data?.secondary1) && tempArray.push({ id: response.data?.secondary1.id, name: `${response.data?.secondary1.name} (backup1 approver)`, stage: 'secondary1' })
          !isEmpty(response.data?.secondary2) && tempArray.push({ id: response.data?.secondary2.id, name: `${response.data?.secondary2.name} (backup2 approver)`, stage: 'secondary2' })
          setchangeRequestorData(tempArray);
        }).catch(error => {
          enqueueSnackbar('Something went wrong! Please try again', { variant: 'error' });
          console.log(error)
        })
      } else if (actionType === "changeImplementor") {
        fetchAllImplementorsAdmin(menuData.row.implementor_type_id).then((response: any) => {
          const tempArray: changeData[] = [];
          !isEmpty(response.data?.primary) && tempArray.push({ id: response.data?.primary.id, name: `${response.data?.primary.name} (primary implementer)`, email: `${response.data?.primary.email}` })
          !isEmpty(response.data?.secondary) && tempArray.push({ id: response.data?.secondary.id, name: `${response.data?.secondary.name} (backup implementer)`, email: `${response.data?.secondary.email}` })
          setchangeRequestorData(tempArray);
        }).catch(error => {
          enqueueSnackbar('Something went wrong! Please try again', { variant: 'error' });
          console.log(error)
        })
      } else if (actionType === "approveDeny") {
        fetchJustificationForDeny(menuData.row.request_id).then((response: any) => {
          setJustForDeny(response.data.justification)
        })
      } else if (actionType === "viewHistory") {
        updateHistory(menuData.row.request_id).then((res: any) => {
          setViewHistoryData(res.data);
        })
      }
      setMenuData({
        anchorEl: null,
        row: {
          request_id: null,
          stage_id: null,
          request_type: null,
          request_status: null,
          is_request_to_update: null,
          linked_delegation: null,
          request_stage: null,
          request_to_update_count: 0,
          implementor_type_id: null,
          request_number: null,
          request_purpose: null,
          implementer_type: null
        },
      });
    } else {
      setModalData({ data: null, type: actionType });
    }

    setModalOpen(true);

  };


  const stageLableHandler = (stage: string) => {
    const stageTemp = stage !== null ? stage.split(" ") : ''
    if ('L1' === stageTemp[0]) return 'Stage 1';
    else if ('L2' === stageTemp[0]) return 'Stage 2';
    else if ('L3' === stageTemp[0]) return 'Stage 3';
    else if ('L4' === stageTemp[0]) return 'Stage 4';
    else if ('L5' === stageTemp[0]) return 'Stage 5';
    else if ('L6' === stageTemp[0]) return 'Stage 6';
  }



  const modalHeaderHandler = (type: string) => {
    switch (type) {
      case 'changeRequestor':
        return 'Change Requestor';
      case 'changeApprover':
        return `Change Approver ( ${stageLableHandler(modalData.data.request_stage)}-${modalData.data.request_stage.substring(2, modalData.data.request_stage.length)} )`;
      case `changeImplementor`:
        return `Change Implementor ( ${modalData.data.implementer_type} )`;
      case 'approveDeny':
        return denyModalOPen ? 'Justification For Denial' : 'Approve or Deny Request';
      case 'viewHistory':
        return `View history(#${modalData.data.request_id})`
      default: return '';
    }
  };

  const idValue = (label: string) => {
    const result = changeRequestorData.find((item: any) => item.name === label);
    return result;
  }

  // OnSubmit funtion for formik AutoComplete
  const onSubmit = (values: any, submitProps: any) => {
    setIsAPILoading(false);
    const requestBodyRequestor = {
      request_id: modalData.data.request_id,
      requestor_id: idValue(values.change_requestor)?.id,
      request_type: modalData.data.request_type,
    };

    const requestBodyApprover = {
      request_type: modalData.data.request_type,
      approver_id: idValue(values.change_requestor)?.id,
      request_id: modalData.data.request_id,
    };

    const requestBodyImplementor = {
      request_id: modalData.data.request_id,
      implementer_id: idValue(values.change_requestor)?.id,
      request_type: modalData.data.request_type,
      implementer_type: modalData.data.implementor_type_id
    };

    if (modalData.type === "changeRequestor") {
      postAllUpdatedRoles(postUpdatedionEndPointsAdminstrativeSummary.postUpdatedRequestor, requestBodyRequestor).then((success: any) => {
        enqueueSnackbar(`Requestor For Delegation request ${modalData.data.request_number}  has been updated successfully.`, { variant: 'success' });
        setIsLoadingPending((loadingState) => ({ ...loadingState, tableLoading: true }));
        fetchAllAdministrativeSummaryDataS();
      }).catch((error: any) => {
        enqueueSnackbar('Something went wrong! Please try again', { variant: 'error' });
        console.log(error)
      });
    } else if (modalData.type === "changeApprover") {
      postAllUpdatedRoles(postUpdatedionEndPointsAdminstrativeSummary.postUpdatedApprover, requestBodyApprover).then((success: any) => {
        enqueueSnackbar(`Approver For Delegation request ${modalData.data.request_number}  has been updated successfully.`, { variant: 'success' });
        setIsLoadingPending((loadingState) => ({ ...loadingState, tableLoading: true }));
        fetchAllAdministrativeSummaryDataS();
      }).catch((error: any) => {
        enqueueSnackbar('Something went wrong! Please try again', { variant: 'error' });
        console.log(error)
      });
    } else if (modalData.type === "changeImplementor") {
      postAllUpdatedRoles(postUpdatedionEndPointsAdminstrativeSummary.postUpdatedImplementor, requestBodyImplementor).then((success: any) => {
        enqueueSnackbar(`Implementor For Delegation request ${modalData.data.request_number}  has been updated successfully.`, { variant: 'success' });
        setIsLoadingPending((loadingState) => ({ ...loadingState, tableLoading: true }));
        fetchAllAdministrativeSummaryDataS();
      }).catch((error: any) => {
        enqueueSnackbar('Something went wrong! Please try again', { variant: 'error' });
        console.log(error)
      });
    }
    submitProps.setSubmitting(false);
    submitProps.resetForm();
    handleModalClose();
  };

  // end OnSubmit code

  const caseLabelHandler = (type: string) => {
    if (type === "changeRequestor") {
      return "changeRequestor";
    } else if (type === "changeApprover") {
      return "changeApprover";
    } else if (type === "changeImplementor") {
      return "changeImplementor";
    }
  }

  const autoLabelHandler = (type: string) => {
    if (type === "changeRequestor") {
      return "Requestor name";
    } else if (type === "changeApprover") {
      return "Approver name";
    } else if (type === "changeImplementor") {
      return "Implementor name";
    }
  }

  const modalContentHandler = (type: string) => {

    switch (type) {
      case caseLabelHandler(type):
        return (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            validateOnChange={false}
            validateOnBlur={false}
            innerRef={formRef}
          >
            <Form>
              {/* <Grid container spacing={2} >
                <Grid item xs={8}> */}
              <FormikControl
                control="autocomplete"
                label={autoLabelHandler(type)}
                name="change_requestor"
                options={
                  changeRequestorData.length > 0 && changeRequestorData.map((requestor: any) => (
                    { label: requestor.name, value: requestor.name }
                  ))
                }
                required
              />
              {/* </Grid>

              </Grid> */}

            </Form>
          </Formik>
        );
      case "approveDeny":
        if (denyModalOPen) {
          return (
            <div>
              <div className={styleClasses.modalContentDiv}>
                Please provide justification for denying this request<span style={{ color: '#EF5B2B' }}>*</span>.
              </div>
              <div>
                <TextField sx={{ width: '540px', color: '#0A275' }}
                  onChange={denyOnChangeHandler}
                  multiline
                  minRows={4}
                />
              </div>
            </div>
          )
        } else {
          return (
            <div>
              <div className={styleClasses.modalContentDiv}>
                Justification from Requestor for requesting to update delegation{modalData.data.id}
              </div>
              <div className={styleClasses.modalContentDiv}>
                {justForDeny}
              </div>
            </div>
          )
        }
      case "viewHistory":
        return (
          <DynamicTableHistory
            isLoading={isLoadingPending.tableLoading}
            viewHistoryData={viewHistoryData}
            filterName="LAST UPDATE REQUESTED"
            columnArray={columnArrayForHistoryTable}
          />
        );
      default:
        return null;
    }
  };
  const isCreateRequestDisabled = (isCreate: boolean) => {
    if (!isCreate && !isAPILoading) return false;
    if (
      isCreate &&
      !isAPILoading &&
      initiateRequest.requestType &&
      initiateRequest.purpose
    )
      return false;
    return true;
  };

  const handleSave = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const justificationOfAdmin = () => {
    const postBody = {
      request_id: modalData.data.request_id,
      comment: denyModalOPen ? denyJustification : "",
      action_taken: denyModalOPen ? 'deny' : 'allow',
    }
    if (denyModalOPen) {
      postAdminDeny(postBody).then((res: any) => {
        enqueueSnackbar(`Delegation request ${modalData.data.request_id}  has been denied successfully.`, { variant: 'success' });
        setIsLoadingPending((prevState) => ({ ...prevState, tableLoading: true }))
        fetchAllAdministrativeSummaryDataS();
      }).catch((error: any) => {
        enqueueSnackbar('Something went wrong! Please try again', { variant: 'error' });
      })
      setDenyJustification('');
      fetchAllAdministrativeSummaryDataS();
      handleModalClose();
    } else {
      postAdminDeny(postBody).then((res: any) => {
        enqueueSnackbar(`Delegation request ${modalData.data.request_id}  has been approved successfully.`, { variant: 'success' });
        setIsLoadingPending((prevState) => ({ ...prevState, tableLoading: true }))
        fetchAllAdministrativeSummaryDataS();
      }).catch((error: any) => {
        enqueueSnackbar('Something went wrong! Please try again', { variant: 'error' })
        console.log(error);
      })
      setDenyJustification('');
      fetchAllAdministrativeSummaryDataS();
      handleModalClose();
    }

  }

  const modalActionHandler = (type: string) => {
    switch (type) {
      case "changeRequestor":
        return (
          <div className={styleClasses.modalActionDiv}>
            <CustomButton
              disabled={isAPILoading}
              variant="contained"
              actionType="secondary"
              onClick={handleModalClose}
            >
              Cancel
            </CustomButton>
            <CustomButton
              disabled={isAPILoading}
              variant="contained"
              actionType="primary"
              disableMargin
              onClick={handleSave}
            >
              Update
            </CustomButton>
          </div>
        );
      case "changeApprover":
        return (
          <div className={styleClasses.modalActionDiv}>
            <CustomButton
              disabled={isAPILoading}
              variant="contained"
              actionType="secondary"
              onClick={handleModalClose}
            >
              Cancel
            </CustomButton>
            <CustomButton
              disabled={isAPILoading}
              variant="contained"
              actionType="primary"
              disableMargin
              onClick={handleSave}
            >
              Update
            </CustomButton>
          </div>
        );
      case "changeImplementor":
        return (
          <div className={styleClasses.modalActionDiv}>
            <CustomButton
              variant="contained"
              actionType="secondary"
              onClick={handleModalClose}
              disabled={isCreateRequestDisabled(false)}
            >
              Cancel
            </CustomButton>
            <CustomButton
              variant="contained"
              actionType="primary"
              disableMargin
              // disabled={isCreateRequestDisabled(true)}
              onClick={handleSave}
            >
              Update
            </CustomButton>
          </div>
        );
      case "approveDeny":
        if (denyModalOPen) {
          return (
            <div className={styleClasses.modalActionDiv}>
              <CustomButton
                variant="contained"
                actionType="secondary"
                disabled={isAPILoading}
                disableMargin
                onClick={justificationOfAdmin}
              >
                Submit
              </CustomButton>
            </div>
          )
        } else {
          return (
            <div className={styleClasses.modalActionDiv}>
              <CustomButton
                disabled={isAPILoading}
                variant="contained"
                actionType="secondary"
                onClick={handleClickDeny}
              >
                Deny
              </CustomButton>
              <CustomButton
                variant="contained"
                actionType="primary"
                disabled={isAPILoading}
                disableMargin
                onClick={justificationOfAdmin}
              >
                Approve
              </CustomButton>
            </div>
          )
        }
      default:
        return null;
    }
  }
  // ACTION MENU FUNCATIONS END
  // denyModal
  const handleClickDeny = () => {
    setDenyModalOpen(true);

  }

  return {
    open,
    styleClasses,
    tableColumnsForPending,
    administrativeSummaryData,
    isLoadingPending,
    filters,
    setFilters,
    tableParametersPending,
    setDateRangeFilter,
    setTableParametersPending,
    requestIdFilter,
    requestorNameFilter,
    requestTypeFilter,
    requestStatusFilter,
    actionOwnerFilter,
    daysPendingFilter,
    setCloseFilter,
    closeFilter,
    rowValue,
    datepickerProps,
    setDatepickerProps,
    setDateRangeValue,
    handleModalOpen,
    modalData,
    menuData,
    setMenuData,
    modalOpen,
    modalContentHandler,
    modalActionHandler,
    handleModalClose,
    modalHeaderHandler,
    clearValueHandler,
  };
}

export default useAdministrativeSummaryHook;
