import React from 'react';

export const userHistoryColumnMapping = {
    lastUpdated: 'lastUpdated',
    updatedBy: 'updatedBy',
    updateType: 'updateType',
    section: 'section',
    subSection: 'subSection',
    value: 'value',
};

export function createColumns(
    key: number,
    label: string,
    icon: any,
    id: string,
    isActive: boolean,
    handleClick?: (_e: any) => void,
    style?: React.CSSProperties,
) {
    return {
        key, label, icon, handleClick, id, isActive, style,
    };
}

export function createRowCells(
    columnId: string,
    renderComponent: any,
    id: string,
    handleClick: undefined | ((_e: any) => void),
    columnNumber: number,
    style?: React.CSSProperties,
) {
    return {
        columnId, renderComponent, id, handleClick, columnNumber, style,
    };
}

export const initialTableParameters = {
    page: 1,
    orderBy: {
        order: 'asc',
        column: '',
    },
};

export interface ITableParameters {
    page: number
    orderBy: {
        order: string,
        column: string,
    },
}
export interface IDateRangeObj {
    startDate: Date | null;
    endDate: Date | null;
}


export interface IFiltersObj {
    lastUpdated: number[],
    requestDate?: IDateRangeObj,

}
export const emptyFilters: IFiltersObj = {
    lastUpdated: [],
};
