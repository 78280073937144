import axios from '../common/axiosInstance';

export const fetchDelegationFieldHistory = async (getBody: any) => {
  const endPoint = '/get_delegation_fields_history/';
  const { data } = await axios.post(endPoint, getBody);
  return data;
};

export const fetchDelegationFieldComments = async (getBody: any) => {
  const endPoint = '/get_delegation_fields_comment/';
  const { data } = await axios.post(endPoint, getBody);
  return data;
};

export const postDelegationFieldComments = async (postBody: any) => {
  const endPoint = '/create_delegation_fields_comment/';
  const { data } = await axios.post(endPoint, postBody);
  return data;
};

export const postDelegationReply = async (postBody: any) => {
  const endPoint = '/create_delegation_fields_comment_reply/';
  const { data } = await axios.post(endPoint, postBody);
  return data;
};

export const fetchAllCommentsReply = async (postBody: any) => {
  const endPoint = '/all_fields_latest_comment_reply/';
  const { data } = await axios.post(endPoint, postBody);
  return data;
}

export const postDelegationFieldHistoryData = async (postBody: any) => {
  const endPoint = '/post_delegation_fields_history/';
  const { data } = await axios.post(endPoint, postBody);
  return data;
};

export const fetchfieldHistoryForLastUpdate = async (postBody: any) => {
  const endPoint = '/all_fields_latest_history/';
  const { data } = await axios.post(endPoint, postBody);
  return data;
}

