import { createSlice, current } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


interface ISarImplementation {
    //   for sar implementaion //
    sar_on_premise_id: number,
    is_step_one_completed: boolean,
    proposed_role_group_name: string,
    is_step_two_completed: boolean,
    proposed_delegation_role_group_name: string,
    proposed_target_group_name: string,
    proposed_gpo_name: string,
    is_delegation_gpo_target_completed: boolean,
    is_delegation_info_completed: boolean,
    complete_manage_by_section: boolean,
    complete_other_section: boolean,
    is_step_three_completed: boolean,
    is_service_account_require_access: string,
    review_delegation_form_to_determine_scope: string,
    is_step_four_completed: boolean,
    is_target_group_name_completed: boolean,
    is_target_group_delegation_info_completed: boolean,
    is_target_group_complete_other_section: boolean,
    is_target_group_complete_manage_by_section: true,
    is_step_five_completed: boolean,
    copy_target_group_name: boolean,
    add_members_to_target_group: boolean,
    is_step_six_completed: boolean,
    is_group_policy_gpo_name_completed: boolean,
    is_group_policy_role_group_completed: boolean,
    is_select_the_delegation: boolean,
    is_step_seven_completed: boolean,
    is_assignment_to_gpo_completed: boolean,
    is_step_eight_completed: boolean,
    is_implemented: boolean

}

export const aadSarImplementation = createSlice({
    name: 'aadSarTasklist',
    initialState: [{
        sar_on_premise_id: null,
        is_step_one_completed: false,
        proposed_role_group_name: '',
        is_step_two_completed: false,
        proposed_delegation_role_group_name: '',
        proposed_target_group_name: '',
        proposed_gpo_name: '',
        is_delegation_gpo_target_completed: false,
        is_delegation_info_completed: false,
        complete_manage_by_section: false,
        complete_other_section: false,
        is_step_three_completed: false,
        is_service_account_require_access: 'Yes',
        review_delegation_form_to_determine_scope: 'Individual device or devices (server/s and / or computer/s)',
        is_step_four_completed: false,
        is_target_group_name_completed: false,
        is_target_group_delegation_info_completed: false,
        is_target_group_complete_other_section: false,
        is_target_group_complete_manage_by_section: false,
        is_step_five_completed: false,
        copy_target_group_name: false,
        add_members_to_target_group: false,
        is_step_six_completed: false,
        is_group_policy_gpo_name_completed: false,
        is_group_policy_role_group_completed: false,
        is_select_the_delegation: false,
        is_step_seven_completed: false,
        is_assignment_to_gpo_completed: false,
        is_step_eight_completed: false,
        is_implemented: false
    }],
    reducers: {
        aadSarImplementationTasklist: (state, action: PayloadAction<ISarImplementation>) => {
            return [{ ...action.payload }]
        },
    }
});

export const { aadSarImplementationTasklist } = aadSarImplementation.actions;
export default aadSarImplementation.reducer;