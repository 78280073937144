/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import ShowMoreText from 'react-show-more-text';
import landingPageStyles from './landing-page-styles';
import { ReactComponent as Illustration } from '../../assets/images/landing-illustration.svg';
import LockIcon from '../../assets/images/login-lock-icon.svg';

function LandingPage() {
  const [Data, SetData] = useState("")

  const landingPageClasses = landingPageStyles();
  const showMoreProps = {
    lines: 3,
    more: 'See More',
    less: 'See Less',
    anchorClass: landingPageClasses.seeMoreAnchor,
    expanded: false,
    truncatedEndingComponent: '...',
  };

  useEffect(() => {
    SetData("done");
    // console.log("asdas")
  }, [])

  return (
    <div className={`${landingPageClasses.p16} ${landingPageClasses.pb0}`} data-testid="landing-page">
      <Grid container spacing={2}>
        <Grid item md={3}>
          <div className={landingPageClasses.illustrationContainer}>
            <Illustration />
          </div>
        </Grid>
        <Grid item md={9}>
          <Grid container style={{ marginBottom: "17px" }}>
            <Grid item md={12}>
              <div className={landingPageClasses.titleContainer}>
                <img src={LockIcon} alt="icon" className={landingPageClasses.titleIcon} />
                <div className={landingPageClasses.sectionHeader} data-testid="top-header"> About GPAG </div>
              </div>
            </Grid>
            <Grid item md={12}>
              <p className={landingPageClasses.paragraphStyling}>
                GroupM Privileged Access Governance is an application to oversee all aspects of privileged access within the GroupM IT estate. Privileged access should only be assigned to those people with specific needs and that access should be governed to ensure that the privileges granted are the minimum required. We call this least privilege access. This helps us protect our IT systems by preventing unrestricted access to sensitive information and systems and reducing opportunities for cyber-attacks.
              </p>
              <p className={landingPageClasses.paragraphStyling}>
                GPAG allows us to create requests for teams and individuals to be granted access specific to their role, function or application. Privileged accounts are then created, dedicated for this purpose and with the appropriate privileges assigned. Periodically, we require owners of these accounts and their managers to attest to the access they have, to ensure we keep the levels of privileged access to a minimum across all our systems. GPAG will work in tandem with the WPP Privileged Access Management solution, providing the governance and audit trail behind access provisions, their justification and approvals. Within this application, depending on your role, you will be able to submit a Delegation request or a Privileged Access Request or Attest to your existing access. You can track your requests through the process and liaise with the GroupM Security team with any queries you may have. You may also be able to access the Surveillance and Insights modules should your role require.
              </p>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={6}>
              <Grid container className={landingPageClasses.mb16}>
                <div className={landingPageClasses.sectionHeader}>
                  Our Process
                </div>
              </Grid>
              <div className={landingPageClasses.moduleSection}>
                <div className={landingPageClasses.moduleHeader}>
                  Delegation
                </div>
                <div className={landingPageClasses.paragraphStyling}>
                  <ShowMoreText {...showMoreProps}>
                    In this module, a team lead or manager will be able to request privileged access specific to the function of that team or application. The team members or application owners are named and the systems to which they need access are detailed. Once approved, a Delegation will allow for individual privileged access accounts, (admin, service, test) to be created for the named delegates. A Delegation must be kept up to date with changes in the team membership and access requirements as the need arises. The team manager or application owner is held responsible for the access granted and the actions taken by the team or application.
                  </ShowMoreText>
                </div>
              </div>

              <div className={landingPageClasses.moduleSection}>

                <div className={landingPageClasses.moduleHeader}>
                  Privileged Access Request
                </div>

                <div className={landingPageClasses.paragraphStyling}>
                  <ShowMoreText {...showMoreProps}>
                    This module is accessible to any individual named in a Delegation, for whom privileged access has been approved in principle. The individual will need to complete this form for their privileged access account to be reviewed, approved and then created.
                  </ShowMoreText>
                </div>
              </div>
              <div className={landingPageClasses.moduleSection}>
                <div className={landingPageClasses.moduleHeader}>
                  Attestation
                </div>
                <div className={landingPageClasses.paragraphStyling}>
                  <ShowMoreText {...showMoreProps}>
                    Privileged access is granted based on a documented requirement, in the Delegation and Privileged Access Request modules. Periodically we require you and your manager to confirm that the access is still both appropriate and necessary. You will receive an email prompting you to access this module and confirm the access required. Your manager will then need to confirm this as well. Failure to respond by either yourself or your manager will result in access being revoked.
                  </ShowMoreText>
                </div>
              </div>
            </Grid>
            <Grid item md={6}>
              <Grid container className={landingPageClasses.mb16}>
                <div className={landingPageClasses.sectionHeader}>
                  Our Governance
                </div>
              </Grid>
              <div className={landingPageClasses.moduleSection}>
                <div className={landingPageClasses.moduleHeader}>
                  Surveillance
                </div>
                <div className={landingPageClasses.paragraphStyling}>
                  <ShowMoreText {...showMoreProps}>
                    This module integrates the GPAG application with our current privileged access status across the GroupM IT estate. It allows review and monitoring of current state privileged access and identifies any unapproved access that has been granted, allowing proactive management of privileges and security.
                  </ShowMoreText >
                </div >
              </div >
              <div className={landingPageClasses.moduleSection}>
                <div className={landingPageClasses.moduleHeader}>
                  Insights
                </div>
                <div className={landingPageClasses.paragraphStyling}>
                  <ShowMoreText {...showMoreProps}>
                    This module provides insights into the number and type of access granted across GroupM, by the GPAG application. It allows for monitoring of progress, total volumes of Delegations, PARs, service, admin and test accounts, duration of request approvals and implementation and assignment of roles.
                  </ShowMoreText >
                </div >
              </div >
            </Grid >
          </Grid >
        </Grid >
      </Grid >
    </div >
  );
}
export default LandingPage;
