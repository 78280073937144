import {
    useEffect,
    useRef,
    useState,
    useCallback,
} from 'react';
import Grid from '@mui/material/Grid';
import {
    useFormikContext,
} from 'formik';
import FormikControl from '../../../components/formik-conrtols/FormikControl';
import adminSummaryStyles from "../administrative-summary-styles";
import { IImplementerData, IImplementerTypeData } from './implementerManagementUtils';

function EditApproverInfo({ data, implementerlist }: any) {
    console.log("formikCtx.values.primary_implementer", data, implementerlist)
    const [implementerList, setImplementerList] = useState<IImplementerData[]>([])
    const styleClasses = adminSummaryStyles();
    const formikCtx: any = useFormikContext();
    const isAutoCompleteSelected = useRef(false);

    useEffect(() => {
        if (data.data) {
            formikCtx.setFieldValue('primary_implementer', data.primary_implementer);
            formikCtx.setFieldValue('primary_implementer_email', data.primary_implementer_email);
            formikCtx.setFieldValue('backup_implementer', data.backup_implementer);
            formikCtx.setFieldValue('backup_implementer_email', data.backup_implementer_email);
        }
    }, []);


    useEffect(() => {
        setImplementerList(implementerlist && implementerlist.filter((item: any) => item.name !== formikCtx.values.primary_implementer));
    }, [formikCtx.values.primary_implementer])

    useEffect(() => {
        setImplementerList(implementerlist && implementerlist.filter((item: any) => item.name !== formikCtx.values.backup_implementer && item.name !== formikCtx.values.primary_implementer));
        // console.log("formikCtx.values.backup_implementer2", formikCtx.values.backup_implementer, implementerlist)
    }, [formikCtx.values.backup_implementer])

    return (
        <>
            <Grid container spacing={2} className={styleClasses.editModal}>
                <Grid item xs={6}>
                    <FormikControl
                        control="autocomplete"
                        label="Primary implementer"
                        name="primary_implementer"
                        freeSolo
                        options={
                            implementerlist?.length > 0 &&
                            implementerlist
                                .filter((approver: any) => approver.id !== 0)
                                .map((item: any) => ({
                                    label: item.name,
                                    value: item.id,
                                }))
                        }
                        handleSelectionChange={(selectedValue: {
                            value: string;
                            label: string;
                        }) => {
                            const selectedApprover = implementerlist?.find(
                                (obj: any) => obj.id == selectedValue.value
                            );
                            console.log("selectedApprover@@", selectedApprover)
                            isAutoCompleteSelected.current = true;
                            formikCtx.setFieldValue(
                                "primary_implementer",
                                selectedApprover ? selectedApprover.name : ""
                            );
                            formikCtx.setFieldValue(
                                "primary_implementer_email",
                                selectedApprover ? selectedApprover.email : ""
                            );
                        }}
                        required
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormikControl
                        control="textfield"
                        label="Email address"
                        name="primary_implementer_email"
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormikControl
                        control="autocomplete"
                        label="Backup implementer"
                        name="backup_implementer"
                        freeSolo
                        options={
                            implementerList?.length > 0 &&
                            implementerList.filter((approver: any) => approver.value !== data.backup_implementer) &&
                            implementerList.map((item: any) => ({
                                label: item.name,
                                value: item.id,
                            }))
                        }
                        handleSelectionChange={(selectedValue: {
                            value: string;
                            label: string;
                        }) => {
                            const selectedApprover = implementerList?.find(
                                (obj: any) => obj.id == selectedValue.value
                            );
                            isAutoCompleteSelected.current = true;
                            formikCtx.setFieldValue(
                                "backup_implementer",
                                selectedApprover ? selectedApprover?.name : ""
                            );
                            formikCtx.setFieldValue(
                                "backup_implementer_email",
                                selectedApprover ? selectedApprover?.email : ""
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormikControl
                        control="textfield"
                        label="Email address"
                        name="backup_implementer_email"
                        disabled={true}
                    />
                </Grid>
            </Grid>
        </>
    );
}
export default EditApproverInfo;
