import { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { format, isDate, isEqual, isWithinInterval } from "date-fns";
import TextField from "@mui/material/TextField";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import _, { includes } from "lodash";
import { useSnackbar } from "notistack";
import { ReactComponent as Sorting } from "../../assets/icons/sorting_asc_inactive.svg";
import { ReactComponent as SortingAscending } from "../../assets/icons/sorting_asc.svg";
import { ReactComponent as SortingDescending } from "../../assets/icons/sorting_desc.svg";
import delegationSummaryStyles from "./delegation-summary-styles";
import useDaterangePicker from "../../components/date-range-picker/useDaterangePicker";
import CustomButton from "../../components/button";
import {
  fetchAllDelegations,
  fetchFilterEndPoints,
  fetchAllFilterRequests,
  postNewRequest,
  cancelDelegation,
  submitRequestToUpdate,
  updateRequestStatus,
} from "../../api-services/delegation-module";
import StatusBadge from "./StatusBadge";
import delegationBody from "../../constants/delegationBody";
import {
  IFiltersObj,
  ITableParameters,
  initialTableParameters,
  createColumns,
  createRowCells,
  delegationColumnMapping,
  emptyFilters,
  IDelegationSummaryData,
} from "./delegationUtils";
import RadioButton from "../../components/radio-button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../appStore/store";
import { addRequestType } from "../../appStore/reducer/delegationSummaryReducer";
import { postNewRequestPar } from "../../api-services/par";
import { parBody } from "../../constants/parBody";

const createRequestOptions = [
  {
    label: "Connect to / manage a server or computer",
    additionalValue: "1",
    value: "Connect to / manage a server or computer",
  },
  {
    label: "Automate actions via application",
    additionalValue: "1",
    value: "Automate actions via application",
  },
  {
    label: "Send and receive an email via application",
    additionalValue: "1",
    value: "Send and receive an email via application",
  },
  {
    label: "Automate the sending of email notifications",
    additionalValue: "1",
    value: "Automate the sending of email notifications",
  },
  {
    label: "Test a service / product as a part of project",
    additionalValue: "2",
    value: "Test a service / product as a part of project",
  },
];

function useDelegationHook() {
  const { loginuserdata } = useSelector((state: RootState) => state.loginData)
  const dispatch = useDispatch()
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const styleClasses = delegationSummaryStyles();
  const [delegationSummaryData, setDelegationSummaryData] = useState({
    count: 0,
    results: [],
    pageSize: 10,
  });
  const [delegationDataFromApi, setDelegationsDataFromApi] = useState([]);
  const [tempdelegationDataFromApi, setTempDelegationsDataFromApi] = useState([]);
  const [requestIdFilter, setRequestIdFilters] = useState([]);
  const [requestStatusFilter, setRequestStatusFilters] = useState<
    {
      id: string;
      value: string;
      label: string;
    }[]
  >([]);
  const [teamNameFilter, setTeamNameFilters] = useState([]);
  const [filters, setFilters] = useState<IFiltersObj>(emptyFilters);
  const [tableParameters, setTableParameters] = useState<ITableParameters>(
    initialTableParameters
  );
  const [closeFilter, setCloseFilter] = useState(false);
  const [isLoading, setIsLoading] = useState({
    tableLoading: false,
    filters: {
      status: false,
      requestId: false,
      teamName: false,
    },
  });
  const [shouldRefetchDelegations, setShouldRefetchDelegations] =
    useState(false);
  const [updateReason, setUpdateReason] = useState("");
  const [initiateRequest, setInitiateRequest] = useState({
    purpose: "",
    requestType: "",
  });
  const [isAPILoading, setIsAPILoading] = useState(false);
  const [requestTypehandler, setRequestTypeHandler] = useState("");
  const [menuData, setMenuData] = useState({
    anchorEl: null,
    row: {
      status: null,
      id: null,
      del_user_detail_list: -1,
      is_request_to_update: false,
      status_id: null,
      admin_action_taken: {
        admin_deny_comment: null,
        is_deny: false
      },
      request_to_update_flag: false,
      request_number: null
    },
  });
  const [modalData, setModalData] = useState<{
    data: any;
    type: string;
  }>({ data: null, type: "" });

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { datepickerProps, setDatepickerProps, setDateRangeValue } =
    useDaterangePicker({ isSingleDate: true });

  const fetchFiltersValues = () => {
    setIsLoading((loadingState) => ({
      ...loadingState,
      filters: {
        ...loadingState.filters,
        status: true,
      },
    }));
    fetchAllFilterRequests(
      fetchFilterEndPoints.fetchDelegationStatusFilterEndPoint
    ).then(
      (successData: any) => {
        setRequestStatusFilters(successData?.data);
        setIsLoading((loadingState) => ({
          ...loadingState,
          filters: {
            ...loadingState.filters,
            status: true,
          },
        }));
      },
      (error: any) => {
        setRequestStatusFilters([]);
        setIsLoading((loadingState) => ({
          ...loadingState,
          filters: {
            ...loadingState.filters,
            status: true,
          },
        }));
        console.error(error);
      }
    );
  };

  const openDelegation = (id: any, row: any) => {
    setMenuData({
      anchorEl: null,
      row: {
        status: null,
        id: null,
        del_user_detail_list: -1,
        is_request_to_update: false,
        status_id: null,
        admin_action_taken: {
          admin_deny_comment: null,
          is_deny: false
        },
        request_to_update_flag: false,
        request_number: null,
        request_number: null
      },
    });
    if (row.request_purpose == "Connect to / manage a server or computer") {
      localStorage.setItem("event", '')
      history.push(`/delegation/${row.id}`)
    } else {
      localStorage.setItem("event", '');
      history.push(`/delegation/sar/${row.id}`)
    }

    return null;
  };

  const openMenu = (e: any, row: any) => {
    setMenuData({ anchorEl: e.target, row });
  };

  const formatDate = (dateValue: any) =>
    dateValue && isDate(new Date(dateValue))
      ? format(new Date(dateValue), "dd MMM yyyy")
      : "";

  useEffect(() => {
    const rowsArray = tempdelegationDataFromApi.map((row: any) => {
      const cellArray = Object.entries(row)
        .map(([key, value]) => {
          let cellData = null;
          switch (key) {
            case "request_number":
              cellData = createRowCells(
                delegationColumnMapping.request_number,
                `${value}`,
                `${row.id}-${value}`,
                () => openDelegation(value, row),
                2,
                { textDecoration: "underline", color: "#0080FF" }
              );
              break;
            case 'status':
              cellData = createRowCells(delegationColumnMapping.status,
                <StatusBadge isRequestToUpdateCount={row.request_to_update_count} status={value} requestPurpose={row.request_purpose} stage={row.stage} stageId={row.stage_priority ? row.stage_priority : null} remediationCount={row.remediation_count} isUpdateRequested={row.is_request_to_update} superScript={row.request_to_update_count} />, `${row.id}-${value}`, undefined, 4);
              break;
            case "team_name":
              cellData = createRowCells(
                delegationColumnMapping.teamName,
                `${value || ""}`,
                `${row.id}-${value || ""}`,
                undefined,
                5
              );
              break;
            case "request_date":
              cellData = createRowCells(
                delegationColumnMapping.requestDate,
                `${formatDate(value)}`,
                `${row.id}-${value}`,
                undefined,
                6
              );
              break;
            case "updated_at":
              cellData = createRowCells(
                delegationColumnMapping.lastUpdated,
                `${formatDate(value)}`,
                `${row.id}-${value}`,
                undefined,
                7
              );
              break;
            case "completion_date":
              cellData = createRowCells(
                delegationColumnMapping.completionDate,
                `${formatDate(value)}`,
                `${row.id}-${value || "-"}`,
                undefined,
                8
              );
              break;
            default:
              break;
          }
          return cellData;
        })
        .filter((obj: any) => obj);
      if (row.status === 'Approved - Pending Implementation') {
        cellArray.push(
          createRowCells(
            delegationColumnMapping.actions,
            '',
            `more-actions-${row.id}`,
            () => null,
            9,
            //{ textAlign: "center" }
          )
        );
      } else {
        cellArray.push(
          createRowCells(
            delegationColumnMapping.actions,
            <MoreVertIcon
              className={`${styleClasses.moreIcon} ${!!menuData.anchorEl && styleClasses.moreIconActive
                }`}
            />,
            `more-actions-${row.id}`,
            (e) => openMenu(e, row),
            9,
            // { textAlign: "center" }
          )
        );
      }

      return cellArray;
    });

    setDelegationSummaryData((prevState: any) => ({ ...prevState, results: [...rowsArray] }));
  }, [tempdelegationDataFromApi]);



  // Set delegation Data onload once 
  const fetchAllDelegationsSummaryData = async () => {
    setIsLoading((loadingState) => ({
      ...loadingState, tableLoading: true, filters: {
        status: true,
        requestId: true,
        teamName: true,
      },
    }));
    // @ts-ignore */
    await fetchAllDelegations().then((res: any) => {
      setDelegationsDataFromApi(res.data.sort((a: any, b: any) => b.updated_at.localeCompare(a.updated_at)));
      setTempDelegationsDataFromApi(res.data.sort((a: any, b: any) => b.updated_at.localeCompare(a.updated_at)));
      // @ts-ignore */
      const teamName = [...new Set(res.data?.map(({ team_name }) => team_name))].map(e => res.data?.find(({ team_name }) => team_name == e));
      // @ts-ignore */
      setTeamNameFilters(teamName?.map((teamNameObj: { team_name: string }) => ({
        id: teamNameObj.team_name,
        value: teamNameObj.team_name === "" ? "(Blanks)" : teamNameObj.team_name,
      }))
      )
      setRequestIdFilters(res.data?.map((requestIdObj: { request_number: string }) => ({
        id: requestIdObj.request_number,
        value: requestIdObj.request_number,
      })))
      setIsLoading((loadingState) => ({
        ...loadingState, tableLoading: false, filters: {
          ...loadingState.filters,
          status: false,
          requestId: false,
          teamName: false,
        },
      }));
    }).catch((error: string) => {
      enqueueSnackbar('Something went wrong! Please try again', { variant: 'error' });
      setIsLoading((loadingState) => ({
        ...loadingState, tableLoading: false,
        filters: {
          status: false,
          requestId: false,
          teamName: false,
        },
      }));
    }
    )
  }

  useEffect(() => {
    fetchAllDelegationsSummaryData();
    fetchFiltersValues();

  }, []);

  const setDateRangeFilter = () => {
    setCloseFilter(true);
    let requestedOnDelegation = delegationDataFromApi.filter((obj: IDelegationSummaryData) => {
      if (isWithinInterval(new Date(`${obj.request_date}`), {
        // @ts-ignore */
        start: datepickerProps.dateRange.startDate,
        // @ts-ignore */
        end: datepickerProps.dateRange.endDate,
      }) || formatDate(obj.request_date) === formatDate(datepickerProps.dateRange.endDate))
        return true;
      return false;
    }
    )
    setTempDelegationsDataFromApi([...requestedOnDelegation]);
    setTableParameters((prevParameters: any) => ({
      ...prevParameters,
      page: Math.ceil(requestedOnDelegation.length / delegationSummaryData.pageSize),
    }));

  };

  // clear value handler
  const clearValueHandler = () => {
    setIsLoading((loadingState) => ({ ...loadingState, tableLoading: false }));
    setDatepickerProps({
      isSingleDate: false,
      dateRange:
      {
        startDate: null,
        endDate: null,
      },
    })
    setFilters(emptyFilters)
    setTempDelegationsDataFromApi(delegationDataFromApi)
  }

  // For Sorting
  useEffect(() => {
    tableParameters.orderBy.column !== '' ? createAndSetDelegationSummary() : setTempDelegationsDataFromApi(delegationDataFromApi);
  }, [tableParameters.orderBy.column, tableParameters.orderBy.order]);

  // For sorting Column Name
  const createAndSetDelegationSummary = () => {
    setTempDelegationsDataFromApi((prevData) => [...prevData.sort(function (valueOne, valueTwo) {
      if (
        tableParameters.orderBy.column === 'request_date' ||
        tableParameters.orderBy.column === 'updated_at' ||
        tableParameters.orderBy.column === 'completion_date'
      ) {
        if (tableParameters.orderBy.order === 'asc')
          return (
            new Date(valueOne[tableParameters.orderBy.column]).getTime() -
            new Date(valueTwo[tableParameters.orderBy.column]).getTime()
          );
        if (tableParameters.orderBy.order === 'desc')
          return (
            new Date(valueTwo[tableParameters.orderBy.column]).getTime() -
            new Date(valueOne[tableParameters.orderBy.column]).getTime()
          );
        return 0;
      }
      else {
        if (
          valueOne[tableParameters.orderBy.column] <
          valueTwo[tableParameters.orderBy.column]
        )
          return tableParameters.orderBy.order === 'asc' ? -1 : 1;
        if (
          valueOne[tableParameters.orderBy.column] >
          valueTwo[tableParameters.orderBy.column]
        )
          return tableParameters.orderBy.order === 'asc' ? 1 : -1;
        return 0;
      }
    }),
    ]);
  };

  const handleSort = (e: any) => {
    setTableParameters((prevParameters) => {
      const newOrderBy = { ...prevParameters.orderBy };
      if (e.target.id !== newOrderBy.column) {
        newOrderBy.order = "asc";
        newOrderBy.column = e.target.id;
      } else if (prevParameters.orderBy.order === "desc") {
        newOrderBy.order = "asc";
        newOrderBy.column = "";
      } else {
        newOrderBy.order = "desc";
      }
      return { ...prevParameters, orderBy: { ...newOrderBy } };
    });
  };

  const getSortingIcon = (columnId: string) => {
    if (tableParameters.orderBy.column === columnId) {
      return tableParameters.orderBy.order === "desc" ? (
        <SortingDescending />
      ) : (
        <SortingAscending />
      );
    }
    return <Sorting />;
  };

  const tableColumns = [
    createColumns(
      2,
      "Delegation ID",
      () => null,
      delegationColumnMapping.request_number,
      tableParameters.orderBy.column === delegationColumnMapping.request_number,
      () => null,
      { width: "115px" }
    ),
    createColumns(
      4,
      "Status",
      getSortingIcon(delegationColumnMapping.status),
      delegationColumnMapping.status,
      tableParameters.orderBy.column === delegationColumnMapping.status,
      (e) => handleSort(e),
      { width: "115px" }
    ),
    createColumns(
      5,
      "Team Name",
      getSortingIcon(delegationColumnMapping.teamName),
      delegationColumnMapping.teamName,
      tableParameters.orderBy.column === delegationColumnMapping.teamName,
      (e) => handleSort(e),
      { width: "115px" }
    ),
    createColumns(
      6,
      "Requested On",
      getSortingIcon(delegationColumnMapping.requestDate),
      delegationColumnMapping.requestDate,
      tableParameters.orderBy.column === delegationColumnMapping.requestDate,
      (e) => handleSort(e),
      { width: "120px" }
    ),
    createColumns(
      7,
      "Last Updated",
      getSortingIcon(delegationColumnMapping.lastUpdated),
      delegationColumnMapping.lastUpdated,
      tableParameters.orderBy.column === delegationColumnMapping.lastUpdated,
      (e) => handleSort(e),
      { width: "115px" }
    ),
    createColumns(
      8,
      "Completion Date",
      getSortingIcon(delegationColumnMapping.completionDate),
      delegationColumnMapping.completionDate,
      tableParameters.orderBy.column === delegationColumnMapping.completionDate,
      (e) => handleSort(e),
      { width: "115px" }
    ),
    createColumns(
      9,
      "Actions",
      null,
      delegationColumnMapping.actions,
      false,
      undefined,
      { width: "55px" }
    ),
  ];

  const fetchFilteredDataWithDebounce = (filtersObject: any) => {

    if (filtersObject?.requestId?.length > 0) {

      let requestIdDelegation = delegationDataFromApi.filter((obj: IDelegationSummaryData) => (filtersObject?.requestId.find((item: string) => item == obj.request_number)))
      setTempDelegationsDataFromApi([...requestIdDelegation]);
      setTableParameters((prevParameters: any) => ({
        ...prevParameters,
        page: Math.ceil(requestIdDelegation.length / delegationSummaryData.pageSize),
      }));

    }
    else if (filtersObject?.teamName?.length > 0) {
      let teamNameDelegation = delegationDataFromApi.filter((obj: IDelegationSummaryData) => (filtersObject?.teamName.find((item: string) => item == obj.team_name)))
      setTempDelegationsDataFromApi([...teamNameDelegation]);
      setTableParameters((prevParameters: any) => ({
        ...prevParameters,
        page: Math.ceil(teamNameDelegation.length / delegationSummaryData.pageSize),
      }));
    }
    else if (filtersObject?.status?.length > 0) {
      let statusDelegation = delegationDataFromApi.filter((obj: IDelegationSummaryData) =>
        (filtersObject?.status.find((item: string) => item == obj.status_id))
      )
      setTempDelegationsDataFromApi([...statusDelegation]);
      setTableParameters((prevParameters: any) => ({
        ...prevParameters,
        page: Math.ceil(statusDelegation.length / delegationSummaryData.pageSize),
      }));
    }
    else {
      setTempDelegationsDataFromApi(delegationDataFromApi)
    }
  }

  useEffect(() => {
    fetchFilteredDataWithDebounce(filters);

  }, [filters]);


  useEffect(() => {
    if (!modalOpen) {
      setModalData({ data: null, type: "" });
    }
  }, [modalOpen]);


  const handleModalClose = () => {
    setModalOpen(false);
    setUpdateReason("");
    setInitiateRequest({ purpose: "", requestType: "" });
  };

  const handleModalOpen = (actionType: string) => {
    if (menuData) {
      setModalData({ data: { ...menuData.row }, type: actionType });
      setMenuData({
        anchorEl: null,
        row: {
          status: null,
          id: null,
          del_user_detail_list: -1,
          is_request_to_update: false,
          status_id: null,
          admin_action_taken: {
            admin_deny_comment: null,
            is_deny: false
          },
          request_to_update_flag: false,
          request_number: null
        },
      });
    } else {
      setModalData({ data: null, type: actionType });
    }
    setModalOpen(true);
  };

  const confirmCancellation = () => {
    setIsAPILoading(true);
    if (modalData.data.id) {
      cancelDelegation(modalData.data.id)
        .then((res) => {
          if (res.status) {
            enqueueSnackbar("Request cancelled successfully", {
              variant: "success",
            });
            setShouldRefetchDelegations(true);

          } else {
            enqueueSnackbar("Something went wrong. Please try again", {
              variant: "error",
            });
          }
          setIsLoading((loadingState) => ({ ...loadingState, tableLoading: false }));
          fetchAllDelegationsSummaryData();
        })
        .catch((err) => {
          enqueueSnackbar("Something went wrong. Please try again", {
            variant: "error",
          });
          console.error(err);
        })
        .finally(() => {
          handleModalClose();
          setIsAPILoading(false);

        });
    } else {
      handleModalClose();
    }
  };

  const confirmUpdate = () => {
    setIsAPILoading(true);
    submitRequestToUpdate({
      justification: updateReason,
      reqId: modalData.data.id,
    })
      .then((res) => {
        if (res.status) {
          enqueueSnackbar(
            `Request to update Delegation #${modalData.data.id} submitted succesfully.`,
            {
              variant: "success",
            }
          );
          setIsLoading((loadingState) => ({ ...loadingState, tableLoading: false }));
          fetchAllDelegationsSummaryData();
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong. Please try again.", {
          variant: "error",
        });
        console.error(err);
      })
      .finally(() => {
        handleModalClose();
        setIsAPILoading(false);
      });
  };

  const confirmArchive = () => {

    const archivedStatusObject = requestStatusFilter.find(
      (status) => status.value === "Archived"
    );
    if (archivedStatusObject && archivedStatusObject.id) {
      setIsAPILoading(true);
      updateRequestStatus({
        reqId: modalData.data.id,
        statusId: Number(archivedStatusObject.id),
      })
        .then((res) => {
          if (res.status) {
            enqueueSnackbar(
              `Delegation #${modalData.data.id} has been successfully archived.`,
              {
                variant: "success",
              }
            );
            fetchAllDelegationsSummaryData();
            setShouldRefetchDelegations(true);
          } else {
            enqueueSnackbar("Something went wrong. Please try again.", {
              variant: "error",
            });
          }
          fetchAllDelegationsSummaryData();

        })
        .catch((err) => {
          enqueueSnackbar("Something went wrong. Please try again.", {
            variant: "error",
          });
          console.error(err);
        })
        .finally(() => {
          setIsAPILoading(false);
          handleModalClose();
        });
    } else {
      console.error("Archived status not found");
      enqueueSnackbar("Something went wrong. Please try again.", {
        variant: "error",
      });
    }
  };

  const handleCreateRequest = (requestType: string, requestPurpose: string) => {
    setIsAPILoading(true);
    dispatch(addRequestType(Number(requestType)))
    const postBody = {
      ...delegationBody,
      status: "1",
      request_type: requestType,
      request_id: "",
      event: "create",
      stage: "1",
      request_purpose: requestPurpose,
      first_name: loginuserdata?.given_name,
      last_name: loginuserdata?.family_name,
      email: loginuserdata?.email,
      del_signed_by: `${loginuserdata?.given_name} ${loginuserdata?.family_name}`,
    };
    const postBodyPar = {
      ...parBody,
      status: "1",
      request_type: requestType,
      request_id: "",
      event: "create",
      stage: "1",
      request_purpose: requestPurpose,
      par_requestor_first_name: loginuserdata?.given_name,
      par_requestor_last_name: loginuserdata?.family_name,
      par_requestor_email: loginuserdata?.email,
      par_date_raised: format(new Date(), 'yyyy-MM-dd'),
    };

    // console.log('directParcreation', postBodyPar)
    if (initiateRequest.purpose && initiateRequest.purpose == "Test a service / product as a part of project") {
      postNewRequestPar(postBodyPar).then((successData: any) => {
        enqueueSnackbar("PAR created successfully", { variant: "success" });
        history.push(`/par/${successData.data.request_id}`);
      })
      setIsAPILoading(false);
    } else {
      postNewRequest(postBody).then(
        (successData: any) => {
          if (initiateRequest.purpose && initiateRequest.purpose == "Connect to / manage a server or computer") {
            enqueueSnackbar("Delegation created successfully", { variant: "success" });
            history.push(`/delegation/${successData.data.request_id}`);
          } else {
            enqueueSnackbar(`Delegation created successfully `, { variant: "success" });
            localStorage.setItem("event", 'create');
            history.push(`/delegation/sar/${successData.data.request_id}`);
          }
          setIsAPILoading(false);
          handleModalClose();
        },
        (error: any) => {
          enqueueSnackbar("Something went wrong! Please try again", {
            variant: "error",
          });
          console.error(error);
          setIsAPILoading(false);
          handleModalClose();
        }
      );
    }

  };


  const modalHeaderHandler = (type: string) => {
    switch (type) {
      case "cancel":
        return "Cancel Request";
      case "update":
        return "Request to Update";
      case "createRequest":
        return "Create new Delegation";
      case "archive":
      case "archiveError":
        return "Archive";
      case "viewJustification":
        return "Justification For Denial";
      default:
        return "";
    }
  };
  const modalContentHandler = (type: string) => {
    switch (type) {
      case "cancel":
        return (
          <div>
            <div className={styleClasses.modalContentDiv}>
              {`Are you sure you want to cancel Delegation ${modalData.data.request_number}?`}
            </div>
            <div className={styleClasses.modalTextDiv}>
              <span className={styleClasses.spanText}>{"Note: "}</span>
              A cancelled Delegation Request will be  permanently deleted and cannot retrieved.
            </div>
          </div>
        );
      case "update":
        return (
          <div>
            <div className={styleClasses.updateModalContent}>
              {`Are you sure you want to update this delegation?
              On pressing 'Request to Update' an email will be triggered to the administrator.`}
            </div>
            <div className={styleClasses.updateModalNote}>
              <span className={styleClasses.spanText}>{"Note: "}</span>
              Administrator can allow or deny this request. Please provide your
              justification below
            </div>
            <TextField
              id="outlined-textarea"
              label="Please provide reason for updation"
              classes={{ root: styleClasses.textFieldRoot }}
              InputProps={{ classes: { root: styleClasses.textFieldInput } }}
              minRows={3}
              maxRows={6}
              onChange={(e) => setUpdateReason(e.target.value)}
              value={updateReason}
              multiline
              InputLabelProps={{
                classes: {
                  root: styleClasses.textFieldLabel,
                  shrink: styleClasses.textFieldLabelShrink,
                },
              }}
            />
          </div>
        );
      case "createRequest":
        return (
          <div>
            <div className={styleClasses.modalContentDiv}>
              What is it you would like to do?
            </div>
            <div className={styleClasses.modalContentDiv}>
              Choose an option below:
            </div>
            <div>
              <RadioButton
                onChange={(e, value) => {
                  console.log({
                    purpose: value,
                    requestType: e.target.title ? e.target.title : "",
                  }, 'ding', e);
                  setInitiateRequest({
                    purpose: value,
                    requestType: e.target.title ? e.target.title : "",
                  });

                }}
                disabled={isAPILoading}
                radioGrouplabels={createRequestOptions}
              />
            </div>
          </div>
        );
      case "archive":
        return (
          <div>
            <div className={styleClasses.modalContentDiv}>
              Are you sure you want to archive Delegation #{modalData.data.id}?
            </div>
            <div className={styleClasses.modalTextDiv}>
              <span className={styleClasses.spanText}>{"Note : "}</span>
              Archiving a Delegation is a permanent action and it cannot be
              undone.
            </div>
          </div>
        );
      case "archiveError":
        return (
          <div>
            <div className={styleClasses.modalContentDiv}>
              {`You cannot archive Delegation #${modalData.data.id}
              as it has Privileged Access Users associated with it.
              To archive this Delegation please use the three dots to
              Request to Update and then remove all the Privileged
              Access Users from it. Once Delegation is empty, you may archive.`}
            </div>
          </div>
        );
      case "viewJustification":
        return (
          <div>
            <div className={styleClasses.modalContentDiv}>
              {modalData.data.admin_action_taken.admin_deny_comment}
            </div>
          </div>
        )
      default:
        return null;
    }
  };

  const isCreateRequestDisabled = (isCreate: boolean) => {
    if (!isCreate && !isAPILoading) return false;

    if (
      isCreate &&
      !isAPILoading &&
      initiateRequest.requestType &&
      initiateRequest.purpose
    )
      return false;
    return true;
  };
  const modalActionHandler = (type: string) => {
    switch (type) {
      case "cancel":
        return (
          <div className={styleClasses.modalActionDiv}>
            <CustomButton
              disabled={isAPILoading}
              variant="contained"
              actionType="secondary"
              onClick={handleModalClose}
            >
              No
            </CustomButton>
            <CustomButton
              disabled={isAPILoading}
              variant="contained"
              actionType="primary"
              disableMargin
              onClick={() => confirmCancellation()}
            >
              Yes, Cancel Request
            </CustomButton>
          </div>
        );
      case "update":
        return (
          <div className={styleClasses.modalActionDiv}>
            <CustomButton
              disabled={isAPILoading}
              variant="contained"
              actionType="secondary"
              onClick={handleModalClose}
            >
              Cancel
            </CustomButton>
            <CustomButton
              disabled={isAPILoading}
              variant="contained"
              actionType="primary"
              disableMargin
              onClick={() => confirmUpdate()}
            >
              Request to Update
            </CustomButton>
          </div>
        );
      case "createRequest":
        return (
          <div className={styleClasses.modalActionDiv}>
            <CustomButton
              variant="contained"
              actionType="secondary"
              onClick={handleModalClose}
              disabled={isCreateRequestDisabled(false)}
            >
              Cancel
            </CustomButton>
            <CustomButton
              variant="contained"
              actionType="primary"
              disableMargin
              disabled={isCreateRequestDisabled(true)}
              onClick={() => {
                handleCreateRequest(
                  initiateRequest.requestType,
                  initiateRequest.purpose
                );
              }}
            >
              Create
            </CustomButton>
          </div>
        );
      case "archive":
        return (
          <div className={styleClasses.modalActionDiv}>
            <CustomButton
              disabled={isAPILoading}
              variant="contained"
              actionType="secondary"
              onClick={handleModalClose}
            >
              Cancel
            </CustomButton>
            <CustomButton
              variant="contained"
              actionType="primary"
              disabled={isAPILoading}
              disableMargin
              onClick={() => confirmArchive()}
            >
              Yes, Archive
            </CustomButton>
          </div>
        );
      case "viewJustification":
        return (
          <div className={styleClasses.modalActionDiv}>
            <CustomButton
              disabled={isAPILoading}
              variant="contained"
              actionType="secondary"
              onClick={handleModalClose}
            >
              OK
            </CustomButton>
          </div>
        )
      default:
        return null;
    }
  };

  return {
    tableColumns,
    tableParameters,
    modalContentHandler,
    modalActionHandler,
    handleModalClose,
    modalHeaderHandler,
    setTableParameters,
    modalOpen,
    filters,
    setDateRangeFilter,
    handleModalOpen,
    setCloseFilter,
    delegationSummaryData,
    requestStatusFilter,
    datepickerProps,
    setDatepickerProps,
    setDateRangeValue,
    menuData,
    modalData,
    teamNameFilter,
    closeFilter,
    isLoading,
    requestIdFilter,
    setFilters,
    setMenuData,
    styleClasses,
    handleCreateRequest,
    requestTypehandler,
    clearValueHandler,
  };
}

export default useDelegationHook;
