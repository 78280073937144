/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef, useMemo } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SearchIcon from "@mui/icons-material/Search";
// import CustomAutocomplete from '../auto-complete';
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Popover from "@mui/material/Popover";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Toolbar,
  TextField,
} from "@mui/material";
import { ReactComponent as DropDown } from "../../assets/icons/DropDownArrow.svg";
import { styled } from "@mui/material/styles";
import { useOktaAuth } from "@okta/okta-react";
import { Link, useLocation, useHistory } from "react-router-dom";
import BrandLogoWhite from "../../assets/images/brandlogo_white.svg";
import useStyles from "./useStyles";
import { getAllCountries, getLoginSyncUpCall } from "../../api-services/common-api";
import CustomButton from "../button";
import CustomModal from "../custom-modal";
import modalStyles from "../custom-modal/modalStyles";
import {
  loginSyncUpData,
  loginUserData,
  initialLoading,
  dispatchCountryData,
  dispatchCompanyData,
  dispatchThirdCompanyData,
  dispatchOfficeData
} from "../../appStore/reducer/loginReducer";
import { useSelector, useDispatch } from "react-redux";
import type { RootState } from "../../appStore/store";
import { useSnackbar } from "notistack";
import Loader from "../loader";
import { attestationPermissionPopuup, delegationPermissionPopup, insightsPermissionPopup, parPermissionPopuup, surveillancePermissionPopup } from "./permission";
import { getAllCompany } from "../../api-services/delegation-module";
import { getAllOffices, getThirdPartyCompany } from "../../api-services/par";
import IdleTimer from "../idleTimer/idleTimer";
import IdleTimerContainer from "../idleTimer/IdleTimerContainer";

const Image = styled("img")({
  width: "auto",
  height: "30px",
});

const HeaderMain = styled("div")({
  display: "flex",
  flexDirection: "column",
  flex: 1,
});

const HeaderTopPart = styled("div")({
  display: "flex",
  justifyContent: "space-between",
});

const HeaderLeftPart = styled("div")({
  display: "flex",
});

const HeaderRightPart = styled("div")({
  display: "flex",
});

const MainNav = styled("div")({
  display: "flex",
  width: "100%",
  color: "#fff",
});

const ProfileIcon = styled("div")({
  display: "flex",
  alignItems: "center",
});

const TopLinks = styled("div")({
  display: "flex",
  alignItems: "center",
  "& Button": {
    display: "block",
    textTransform: "capitalize",
    font: "normal normal normal 15px/23px Poppins-Regular",
    color: "#f2f2f2",
    "&:hover": {
      color: "#00FFB4 !important",
    },
  },
});

const stringToColor = (nameString: string) => {
  let hash = 0;
  let i;
  for (i = 0; i < nameString.length; i += 1) {
    // eslint-disable-next-line no-bitwise
    hash = nameString.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = hash >= i * 8 && 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
};

const stringAvatar = (name: string) => ({
  sx: {
    bgcolor: stringToColor(name),
    fontSize: "16px",
    fontFamily: "Poppins-Regular",
  },
  children: `${name && name.split(" ").length > 1
    ? // eslint-disable-next-line no-unsafe-optional-chaining
    name?.split(" ")[0][0] + name?.split(" ")[1][0]
    : name[0]
    }`,
});

function Header() {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { loginuserdata, loginsyncupdata, loading } = useSelector(
    (state: RootState) => state.loginData
  );
  const modalClasses = modalStyles({ centerButton: false });
  const { oktaAuth, authState } = useOktaAuth();
  const location = useLocation();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState<IModalState>({
    show: false,
    modalHeader: "",
    modalContent: null,
    modalActions: null,
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElSearch, setAnchorElSearch] =
    useState<HTMLButtonElement | null>(null);
  const [userAuthenticated, setUserAuthenticated] = useState<boolean>(false);
  const open = Boolean(anchorEl);
  const openSearch = !!anchorElSearch;
  const [isSearchActive, setIsSearchActive] = useState(false);
  const searchRef = useRef<any>();
  const [permission, setpermission] = useState<string>('')
  // eslint-disable-next-line max-len
  const handleClick = (
    event:
      | React.MouseEvent<HTMLSpanElement>
      | React.KeyboardEvent<HTMLSpanElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const id = openSearch ? "simple-popover" : undefined;

  const handleLogout = async () => {
    oktaAuth.tokenManager.clear();
    await oktaAuth.signOut();
    //   {
    //   revokeAccessToken: true,
    //   revokeRefreshToken: true,
    //   clearTokensBeforeRedirect: true,
    //   postLogoutRedirectUri: "/",
    // });
  };

  const handleModalClose = () => {
    setModalOpen(false);
    // console.log('cuurent route', location.pathname);
    // history.push('/par');
  };

  useEffect(() => {
    if (isSearchActive && searchRef && searchRef.current) {
      searchRef.current?.focus();
    }
  }, [isSearchActive]);

  // useEffect(() => {
  //   if (authState?.isAuthenticated) {
  //     oktaAuth.getUser().then((res: any) => {
  //       dispatch(loginUserData(res));
  //       getLoginSyncUpCall()
  //         .then((response: any) => {
  //           dispatch(loginSyncUpData(response?.data));
  //         })
  //         .catch((err) => {
  //           enqueueSnackbar("Something went wrong! Please try again", {
  //             variant: "error",
  //           });
  //           //console.log(err);
  //         });
  //     });
  //     setUserAuthenticated(true);
  //   } else {
  //     setUserAuthenticated(false);
  //   }
  // }, [authState]);
  useEffect(() => {
    AuthChecking;

    getAllCountries().then((res) => {
      dispatch(dispatchCountryData(res.data));
    }).catch((err) => {
      // console.log(err);
    });

    getAllCompany().then((res) => {
      dispatch(dispatchCompanyData(res.data));
    }).catch((err) => {
      //console.log(err);
    });
    getThirdPartyCompany().then((res) => {
      dispatch(dispatchThirdCompanyData(res.data));
    }).catch((err) => {
      // console.log(err);
    })

    getAllOffices().then((res) => {
      dispatch(dispatchOfficeData(res.data));
    }).catch((err) => {
      console.log(err);
    })
  }, []);

  const AuthChecking = useMemo(() => {
    if (authState?.isAuthenticated) {
      dispatch(initialLoading(true))
      oktaAuth.getUser().then((res: any) => {
        dispatch(loginUserData(res));
        getLoginSyncUpCall()
          .then((response: any) => {
            if (response.message !== undefined && response.message == "ok" && response.status !== undefined && response.status == true) {
              dispatch(loginSyncUpData(response?.data));
              dispatch(initialLoading(false))
            } else if (response.message !== undefined && response.message == "Okta profile not exists") {
              enqueueSnackbar("Your Okta profile is Inactive", { variant: "error" });
              handleLogout()
            } else if (response.message !== undefined && response.message == "You don't have GPAG access") {
              enqueueSnackbar("You Dont have access to GPAG Application", { variant: "error" });
              handleLogout()
            }
          })
          .catch((err) => {
            console.log("err", err)
            enqueueSnackbar("Something went wrong! Please try again", {
              variant: "error",
            });
          });
      });
      setUserAuthenticated(true);
    } else {
      setUserAuthenticated(false);
    }
  }, [authState])

  const tempfunc = (allowedUserRoles: string[]) => {
    return (
      loginsyncupdata &&
      loginsyncupdata?.groups.filter((obj: any) =>
        allowedUserRoles.includes(obj.group_name.toLowerCase())
      ).length
    )
  };

  const urlAccess = (route: string) => {
    switch (route) {
      case "/delegation":
        return tempfunc(["requestor"]);
      case "/par":
        return tempfunc(["requestor", "user"]);
      case "/attestaion":
        return tempfunc(["administrator", "approver", "requestor", "user"]);
      case "/surveillance":
        return tempfunc(["reporter"]);
      case "/reporting":
        return tempfunc(["administrator", "reporter"]);
      case "/myadministrativeTasks":
        return tempfunc(["administrator"]);
      case "/myApprovalTasks":
        return tempfunc(["approver"]);
      case "/myImplementationTasks":
        return tempfunc(["implementer"]);
      default:
        return true;
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    handleLogout();
    dispatch(initialLoading(false))
  };

  const handleCLoseSearch = (e: any) => {
    setAnchorElSearch(null);
    console.log(e.target.innerText);
  };

  const routes = [
    { path: "/home", label: "Home" },
    { path: "/delegation", label: "Delegation" },
    { path: "/par", label: "PAR" },
    { path: "/attestaion", label: "Attestation" },
    { path: "/surveillance", label: "Surveillance" },
    { path: "/reporting", label: "Insights" },
  ];

  const modalContentHandler = useMemo(
    () => (
      <span className={modalClasses.modalTextDiv}>
        {permission == '/delegation' ? <div dangerouslySetInnerHTML={{ __html: delegationPermissionPopup }} /> : permission == '/par' ? <div dangerouslySetInnerHTML={{ __html: parPermissionPopuup }} /> : permission == '/attestaion' ? <div dangerouslySetInnerHTML={{ __html: attestationPermissionPopuup }} /> : permission == '/surveillance' ? <div dangerouslySetInnerHTML={{ __html: surveillancePermissionPopup }} /> : <div dangerouslySetInnerHTML={{ __html: insightsPermissionPopup }} />}
      </span>
    ),
    [permission]
  );

  // @ts-ignore */
  const [[, currentRoot]] = location.pathname.matchAll(/^(\/[^/]*)/g);

  if (!userAuthenticated) {
    return null;
  }

  if (loading) {
    return (
      <div style={{ padding: "0px 32px" }}>
        <Loader openModal={true} />
      </div>
    );
  }

  return (
    <>
      <AppBar
        position="fixed"
        className={classes.appBarHeader}
        data-testid="appbar"
      >
        <IdleTimerContainer />
        <Toolbar disableGutters>
          <HeaderMain className={classes.headermain}>
            <HeaderTopPart>
              <HeaderLeftPart>
                <Image src={BrandLogoWhite} />
                <div className={classes.divdivder}>
                  <Divider orientation="vertical" className={classes.divider} />
                </div>
                <div className={classes.pagdiv} data-testid="header-text">
                  Privileged Access Governance
                </div>
              </HeaderLeftPart>
              <HeaderRightPart>
                {/* <div className={classes.serachdiv}>
                <InputBase inputRef={searchRef} data-testid="search-input" onBlur={() => setIsSearchActive(false)} className={`${classes.inputsearch} ${isSearchActive ? classes.inputsearchActive : ''}`} placeholder="Search..." />
                <SearchIcon className={classes.search} data-testid="search-icon" onClick={() => setIsSearchActive(true)} />
              </div> */}
                <div className={classes.serachdiv}>
                  {/* <Button
                  className={`${classes.button}`}
                  // aria-describedby={id}
                  variant="contained"
                  onClick={handleClickSearch}
                  endIcon={<KeyboardArrowDownIcon />}
                  data-testid="filter-button"
                >
                  REQ ID
                </Button>
                <Menu
                  id={id}
                  aria-labelledby="demo-positioned-button"
                  anchorEl={anchorElSearch}
                  open={openSearch}
                  onClose={handleCLoseSearch}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <MenuItem onClick={handleCLoseSearch}>REQ ID</MenuItem>
                  <MenuItem onClick={handleCLoseSearch}>Requestor Name</MenuItem>
                </Menu> */}
                  {/* <Popover
                  id={id}
                  open={openSearch}
                  anchorEl={anchorElSearch}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  data-testid="filtermenu-popover"
                >
                  hii
                </Popover> */}
                  {/* <Autocomplete
                  className={classes.notchedOutline}
                  size="small"
                  popupIcon={<DropDown />}
                  value={value}
                  onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                      setValue({
                        title: newValue,
                      });
                    } else if (newValue && newValue.inputValue) {
                      // Create a new value from the user input
                      setValue({
                        title: newValue.inputValue,
                      });
                    } else {
                      setValue(newValue);
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option.title);
                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        inputValue,
                        title: `Add "${inputValue}"`,
                      });
                    }

                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  options={top100Films}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option.title;
                  }}
                  renderOption={(props, option) => <li {...props}>{option.title}</li>}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                    // InputProps={{
                    //   classes:
                    //   {
                    //     notchedOutline: classes.notchedOutline,
                    //     input: classes.input,
                    //     // focused: styleClasses.focused,
                    //     // error: styleClasses.error,
                    //     // disabled: styleClasses.disable,
                    //   },
                    // }}
                    />
                  )}
                /> */}
                  {/* <SearchIcon className={classes.search} data-testid="search-icon" onClick={() => setIsSearchActive(true)} /> */}
                </div>
                <div className={classes.toplinksdiv}>
                  <TopLinks>
                    {/* <a href="mailTo:nikhileshn@cybage.com" className={classes.link}>
                    <Button size="medium"> Help  </Button>
                  </a> */}
                    <Link to="/faq" className={classes.link}>
                      <Button size="medium"> FAQs </Button>
                    </Link>
                  </TopLinks>
                  {/* <IconButton
                  size="large"
                  aria-label="show 17 new notifications"
                  color="inherit"
                  className={classes.notificationIconButton}
                >
                  <Badge badgeContent={6} className={classes.notificationicon}>
                    <NotificationsOutlinedIcon style={{ color: "white" }} />
                  </Badge>
                </IconButton> */}

                  <ProfileIcon>
                    <Avatar
                      {...stringAvatar(
                        (loginuserdata && loginuserdata?.name) || ""
                      )}
                      className={classes.avatar}
                    />
                    <span className={classes.span} data-testid="username">
                      {" "}
                      {loginuserdata?.name || ""}
                    </span>
                    <span
                      tabIndex={0}
                      onKeyDown={handleClick}
                      onClick={handleClick}
                      role="button"
                      className={classes.arrowkeyspan}
                      data-testid="logout"
                    >
                      <KeyboardArrowDownIcon />
                    </span>
                  </ProfileIcon>
                </div>
              </HeaderRightPart>
            </HeaderTopPart>

            <MainNav>
              <Box sx={{ width: "100%" }}>
                <Tabs
                  value={
                    location.pathname !== "/" && location.pathname !== "/callback"
                      ? currentRoot
                      : routes[0].path
                  }
                  className={classes.tab}
                  variant="standard"
                  data-testid="header-tabs"
                  onChange={(e, value) => {
                    if (!urlAccess(value)) {
                      console.log("value", value);
                      setpermission(value)
                      setModalOpen(true);
                      e.preventDefault();
                    }
                  }}
                >
                  {routes.map((routeItem) => (
                    <Tab
                      key={routeItem.path}
                      value={routeItem.path}
                      label={routeItem.label}
                      component={Link}
                      to={routeItem.path}
                      disableFocusRipple
                      disableTouchRipple
                    />
                  ))}
                  {tempfunc(["approver"]) && (
                    <Tab
                      value="/myApprovalTasks"
                      label="My Approval Tasks"
                      component={Link}
                      to="/myApprovalTasks"
                      disableFocusRipple
                      disableTouchRipple
                    />
                  )}
                  {tempfunc(["administrator"]) && (
                    <Tab
                      value="/myadministrativeTasks"
                      label="My Administrative Tasks"
                      component={Link}
                      to="/myadministrativeTasks"
                      disableFocusRipple
                      disableTouchRipple
                    />
                  )}
                  {tempfunc(["implementer"]) && (
                    <Tab
                      value="/myImplementationTasks"
                      label="My Implementation Tasks"
                      component={Link}
                      to="/myImplementationTasks"
                      disableFocusRipple
                      disableTouchRipple
                    />
                  )}
                </Tabs>
              </Box>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => {
                  setAnchorEl(null);
                }}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                data-testid="menu"
              >
                <MenuItem
                  style={{
                    fontFamily: "NotoSerif-regular",
                    fontSize: "13px",
                    color: "#0A2756",
                  }}
                  role="button"
                >
                  {loginuserdata && loginuserdata?.email}
                </MenuItem>
                <MenuItem
                  onClick={handleClose}
                  style={{
                    fontFamily: "NotoSerif-regular",
                    fontSize: "13px",
                    color: "#0A2756",
                  }}
                  role="button"
                  data-testid="logout-menu"
                >
                  Sign Out
                </MenuItem>
              </Menu>
              <CustomModal
                modalHeader="Note"
                open={modalOpen}
                onClose={handleModalClose}
                modalAction={
                  <div className={modalClasses.modalActionDiv}>
                    <CustomButton
                      variant="contained"
                      actionType="primary"
                      disableMargin
                      onClick={handleModalClose}
                    >
                      OK
                    </CustomButton>
                  </div>
                }
                modalContent={modalContentHandler}
              />
            </MainNav>
          </HeaderMain>
        </Toolbar>
      </AppBar>

      <CustomModal
        open={modalData.show}
        modalHeader={modalData.modalHeader}
        modalContent={modalData.modalContent}
        modalAction={modalData.modalActions}
      />
    </>
  );
}

export default Header;
