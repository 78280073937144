import { Grid } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterOptions from "../../../components/filter-menu/FilterOptions";
import FilterMenu from "../../../components/filter-menu";
import approvalSummaryStyles from "./../administrative-summary-styles";
import CustomButton from "../../../components/button";
import DynamicTable from "../../../components/dynamic-table";
import { emptyFilters } from "./userManagementUtils";
import PaginationBlock from "../../../components/pagination-block";
import { ReactComponent as EmptyResultIcon } from "../../../assets/icons/no_results_icon.svg";
import { ReactComponent as UsersIcon } from '../../../assets/icons/users_icon.svg';
import { ReactComponent as ImplementerIcon } from "../../../assets/icons/Implementer_icon.svg";
import { ReactComponent as ReporterIcon } from '../../../assets/icons/Reporter_icon.svg';
import { ReactComponent as RequestorIcon } from "../../../assets/icons/Requestor_icon.svg";
import { ReactComponent as ApproverIcon } from "../../../assets/icons/Approver_icon.svg";
import { ReactComponent as AdministratorIcon } from "../../../assets/icons/Administrator_icon.svg";
import AddIcon from "@mui/icons-material/Add";
import CustomModal from "../../../components/custom-modal";
import { useHistory } from "react-router-dom";
import useUserManagementHook from "./useUserManagementHook";
import { isEqual } from "lodash";
import { Box } from "@mui/system";


function UserManagement() {
  const history = useHistory();
  const styleClasses = approvalSummaryStyles();
  const {
    open,
    filters,
    clearValueHandler,
    userManagementSummaryData,
    tableColumnsForPending,
    rowValue,
    isLoading,
    tableParametersPending,
    setTableParametersPending,
    setFilters,
    oktaAccountFilter,
    requestorNameFilter,
    accountTypeFilter,
    roleGroupFilter,
    menuData,
    setMenuData,
    handleModalOpen,
    //handlerCreatUser,
    modalOpen,
    modalContentHandler,
    modalHeaderHandler,
    handleModalClose,
    modalActionHandler,
    modalData,

    noteForEditOpen,
    noteModalOpen,
    noteForDeteteOpen
  } = useUserManagementHook();

  const emptyRequestHandler = () => {
    if (!isLoading.tableLoading && userManagementSummaryData?.results.length === 0) {
      return (
        <Grid item xs={12} data-testid="empty-delegation-screen">
          <div className={styleClasses.emptyResultContainer}>
            <EmptyResultIcon />
            <span>
              {isEqual(filters, emptyFilters)
                ? "There are no requests created by you."
                : "There are no requests matching to the selected filters."}
            </span>
          </div>
        </Grid>
      );
    }
    return null;
  };

  return (
    <>
      {/* filter started=> */}
      <Grid item xs={12} className={styleClasses.filtersContainer}>
        <div className={styleClasses.filterPanel}>
          <div className={styleClasses.filterIcon}>
            <FilterListIcon />
          </div>
          <div className={styleClasses.filterMenu}>
            <FilterMenu
              filterLabel="Name"
              handleFilterClose={() => console.log("handleClose")}
              badgeCount={filters.requestorName?.length}
            >
              <FilterOptions
                searchPlaceholder="Search"
                allowSearch
                optionsList={requestorNameFilter}
                selectedOptions={filters.requestorName}
                handleOptionSelection={(e, selection) => {
                  setFilters((prevFilters: any) => {
                    let newRequestNameArray = [...prevFilters.requestorName];
                    if (!selection) {
                      newRequestNameArray = newRequestNameArray.filter(
                        (option) => option !== e.target.value
                      );
                    } else newRequestNameArray.push(e.target.value);
                    return {
                      ...prevFilters,
                      requestorName: [...newRequestNameArray],
                      page: 1,
                    };
                  });
                }}
                isFilterDataLoading={isLoading.filters.requestorName}
                allowAll
                selectAllHandler={(isSelected: boolean) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    requestorName: isSelected
                      ? requestorNameFilter.map(
                        (obj: { id: string; value: string }) => obj.id
                      )
                      : [],
                  }));
                }}
              />
            </FilterMenu>
          </div>
          <div className={styleClasses.filterMenu}>
            <FilterMenu
              filterLabel="ACCOUNT STATUS"
              handleFilterClose={() => console.log("handleClose")}
              badgeCount={filters.accountStatus?.length}
            >
              <FilterOptions
                searchPlaceholder="Search"
                //allowSearch
                optionsList={accountTypeFilter}
                selectedOptions={filters.accountStatus}
                handleOptionSelection={(e, selection) => {
                  setFilters((prevFilters) => {
                    let newAccountStatusArray = [...prevFilters.accountStatus];
                    if (!selection) {
                      newAccountStatusArray = newAccountStatusArray.filter(
                        (option) => option !== e.target.value
                      );
                    } else {
                      newAccountStatusArray.push(e.target.value);
                    }
                    return {
                      ...prevFilters,
                      accountStatus: [...newAccountStatusArray],
                      page: 1,
                    };
                  });
                }}
                isFilterDataLoading={isLoading.filters.accountStatus}
                allowAll
                selectAllHandler={(isSelected: boolean) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    accountStatus: isSelected
                      ? accountTypeFilter.map(
                        (obj: { id: string; value: string }) => obj.id
                      )
                      : [],
                  }));
                }}
              />
            </FilterMenu>
          </div>
          <div className={styleClasses.filterMenu}>
            <FilterMenu
              filterLabel="OKTA ACCOUNT"
              handleFilterClose={() => console.log("handleClose")}
              badgeCount={filters.oktaAccount?.length}
            >
              <FilterOptions
                searchPlaceholder="Search"
                optionsList={oktaAccountFilter}
                selectedOptions={filters.oktaAccount}
                handleOptionSelection={(e, selection) => {
                  setFilters((prevFilters) => {
                    let newOktaAccountArray = [...prevFilters.oktaAccount];
                    if (!selection) {
                      newOktaAccountArray = newOktaAccountArray.filter(
                        (option) => option !== e.target.value
                      );
                    } else {
                      newOktaAccountArray.push(e.target.value);
                    }
                    return {
                      ...prevFilters,
                      oktaAccount: [...newOktaAccountArray],
                      page: 1,
                    };
                  });
                }}
                isFilterDataLoading={isLoading.filters.oktaAccount}
                allowAll
                selectAllHandler={(isSelected: boolean) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    oktaAccount: isSelected
                      ? oktaAccountFilter.map(
                        (obj: { id: string; value: string }) => obj.id
                      )
                      : [],
                  }));
                }}
              />
            </FilterMenu>
          </div>
          <div className={styleClasses.filterMenu}>
            <FilterMenu
              filterLabel="ROLE GROUP"
              handleFilterClose={() => console.log("handleClose")}
              badgeCount={filters.roleGroup?.length}
            >
              <FilterOptions
                searchPlaceholder="Search"
                optionsList={roleGroupFilter}
                selectedOptions={filters.roleGroup}
                handleOptionSelection={(e, selection) => {
                  setFilters((prevFilters) => {
                    let newRoleGroupArray = [...prevFilters.roleGroup];
                    if (!selection) {
                      newRoleGroupArray = newRoleGroupArray.filter(
                        (option) => option !== e.target.value
                      );
                    } else newRoleGroupArray.push(e.target.value);
                    return {
                      ...prevFilters,
                      roleGroup: [...newRoleGroupArray],
                      page: 1,
                    };
                  });
                }}
                isFilterDataLoading={isLoading.filters.roleGroup}
                allowAll
                selectAllHandler={(isSelected: boolean) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    roleGroup: isSelected
                      ? roleGroupFilter.map(
                        (obj: { id: string; value: string }) => obj.id
                      )
                      : [],
                  }));
                }}
              />
            </FilterMenu>
          </div>
          <CustomButton
            variant="contained"
            actionType="tertiary"
            onClick={() => clearValueHandler()}
            disableRipple
            disableMargin
            disablePadding
          >
            CLEAR
          </CustomButton>
        </div>
        <div>
          <CustomButton
            variant="contained"
            actionType="primary"
            disableMargin
            onClick={() => {
              handleModalOpen("createUser", null);
              // handlerCreatUser()
            }}
          >
            <AddIcon style={{ marginRight: "4px" }} />
            Add User
          </CustomButton>
        </div>
      </Grid>
      {/* filter end=> */}
      {/* listing table started=> */}
      <Grid item xs={12}>
        <DynamicTable
          rows={userManagementSummaryData.results}
          isLoading={isLoading.tableLoading}
          columns={tableColumnsForPending}
          pageNumber={tableParametersPending.page}
          // pageNumber={activePageNo ? tableParametersPending.page : Math.ceil(userManagementSummaryData.results.length / userManagementSummaryData.pageSize)}
          pageSize={userManagementSummaryData.pageSize}
        >
        </DynamicTable>
      </Grid>
      {/* listing table end=> */}
      {/* For listing Icon */}
      <Box className={styleClasses.iconListingMainDiv}>
        <Box className={styleClasses.iconListing}>
          <AdministratorIcon />
          <span className={styleClasses.iconListingName}>Administrator</span>
        </Box>
        <Box className={styleClasses.iconListing}>
          <ApproverIcon />
          <span className={styleClasses.iconListingName}>Approver</span>
        </Box>
        <Box className={styleClasses.iconListing}>
          <ImplementerIcon />
          <span className={styleClasses.iconListingName}>Implementer</span>
        </Box>
        <Box className={styleClasses.iconListing}>
          <ReporterIcon />
          <span className={styleClasses.iconListingName}>Reporter</span>
        </Box>
        <Box className={styleClasses.iconListing}>
          <RequestorIcon />
          <span className={styleClasses.iconListingName}>Requestor</span>
        </Box>
        <Box className={styleClasses.iconListing}>
          <UsersIcon />
          <span className={styleClasses.iconListingName}>User</span>
        </Box>
      </Box>
      {/* End listing Icon*/}
      {/* pagination started=> */}
      <Grid item xs={12} style={{ paddingTop: "21px" }}>
        <PaginationBlock
          totalRecords={userManagementSummaryData?.results.length || 0}
          currentRecordsCount={userManagementSummaryData?.results.length}
          activePage={tableParametersPending.page}
          // activePage={activePageNo ? tableParametersPending.page : Math.ceil(userManagementSummaryData.results.length / userManagementSummaryData.pageSize)}
          handlePageChange={(pageNumber) => {
            setTableParametersPending((prevParameters: any) => ({
              ...prevParameters,
              page: pageNumber,
            }));
            //setactivePage(pageNumber)
          }}
          isLoading={isLoading.tableLoading}
          pageSize={userManagementSummaryData.pageSize}
        />
      </Grid>
      {/* pagination end=> */}
      {emptyRequestHandler()}
      {/* modal code started=> */}
      {/* {menuData.anchorEl && menuData.row && (
        <Menu
          id="menu-popover"
          open={!!menuData.anchorEl}
          anchorEl={menuData.anchorEl}
          onClose={() =>
            setMenuData({
              anchorEl: null,
              row: {
                request_id: null,
                stage_id: null,
                request_type: null,
                request_status: null,
                is_request_to_update: null,
              },
            })
          }
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          classes={{ root: styleClasses.moreActionMenu }}
          disableAutoFocusItem
        >
          <MenuItem
            onClick={() => handleModalOpen("changeRequestor")}
            className={styleClasses.moreActionMenuItem}
          >
            <UsersIcon />
            <span className={styleClasses.menuLabel}>Change Requestor</span>
          </MenuItem>
          {menuData.row.request_status === "Completed" &&
            menuData.row.is_request_to_update && (
              <>
                <MenuItem
                  onClick={() => handleModalOpen("approveDeny")}
                  className={styleClasses.moreActionMenuItem}
                >
                  <RightTickIcon />
                  <span className={styleClasses.menuLabel}>Approve / Deny</span>
                </MenuItem>
              </>
            )}
          {menuData.row.request_status === "Pending Approval" && (
            <>
              <MenuItem
                onClick={() => handleModalOpen("changeApprover")}
                className={styleClasses.moreActionMenuItem}
              >
                <UserIcon />
                <span className={styleClasses.menuLabel}>Change Approver</span>
              </MenuItem>
            </>
          )}
          {menuData.row.request_status ===
            "Approved - Pending Implementation" && (
              <>
                <MenuItem
                  onClick={() => handleModalOpen("changeRequestor")}
                  className={styleClasses.moreActionMenuItem}
                >
                  <UserIcon />
                  <span className={styleClasses.menuLabel}>Change Requestor</span>
                </MenuItem>
                <MenuItem
                  onClick={() => handleModalOpen("changeImplementor")}
                  className={styleClasses.moreActionMenuItem}
                >
                  <UserIcon />
                  <span className={styleClasses.menuLabel}>
                    Change Implementer
                  </span>
                </MenuItem>
                <MenuItem
                  onClick={() => console.log('open Delegation')}
                  className={styleClasses.moreActionMenuItem}
                >
                  <EyeIcon />
                  <span className={styleClasses.menuLabel}>View Delegation</span>
                </MenuItem>
              </>
            )}
        </Menu>
      )} */}

      {
        modalOpen && (
          <CustomModal
            modalHeader={modalHeaderHandler(modalData.type)}
            open={modalOpen}
            centerButton={noteForEditOpen == 1 ? true : (noteForDeteteOpen == 1) ? true : (noteModalOpen ? true : false)}
            onClose={handleModalClose}
            modalAction={modalActionHandler(modalData.type)}
            modalContent={modalContentHandler(modalData.type)}
          />
        )
      }
      {/* modal code ended=> */}
    </>
  );
}
export default UserManagement;
