export const routeCheck = () => {
    return location.pathname.slice(1, 5).toLocaleLowerCase() === 'dele';
}
export const routeCheckForDelation = () => {
    return location.pathname.slice(1, 4).toLocaleLowerCase() === 'del';
}
export const routeCheckForPendingApproval = () => {
    return location.pathname.slice(1, 35).toLocaleLowerCase() === 'myapprovaltasks/pendingapprovalsar'
}

export const routeCheckForImplementationSummary = () => {
    return location.pathname.slice(1, 44).toLocaleLowerCase() === 'myimplementationtasks/implementationsummary'
}

export const routeCheckForAdminSummary = () => {
    return location.pathname.slice(1, 26).toLocaleLowerCase() === 'myadministrativetasks/sar'
}

export const routeCheckForApprovalSummary = () => {
    return location.pathname.slice(1, 35).toLocaleLowerCase() === 'myapprovaltasks/approvalsummarysar'
}

export const routeCheckForPendingImplementation = () => {
    return location.pathname.slice(1, 44).toLocaleLowerCase() === 'myimplementationtasks/pendingimplementation'
}
