/* eslint-disable react/jsx-props-no-spreading */
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Skeleton from '@mui/material/Skeleton';
import { useEffect, useState } from 'react';
import paginationStyles from './paginationStyles';
import Loader from '../loader';

interface IPaginationBlockProps {
  currentRecordsCount: number;
  totalRecords: number;
  activePage: number;
  pageSize: number;
  handlePageChange: (_pageNumber: number) => void;
  isLoading: boolean;
}

function PaginationBlock({
  // currentRecordsCount,
  totalRecords,
  activePage,
  pageSize,
  handlePageChange,
  isLoading,
}: IPaginationBlockProps) {
  const styleClasses = paginationStyles();
  if (isLoading) {
    return (
      <div className={styleClasses.skeletonContainer} data-testid="skeleton-container">
        {/* <Skeleton animation="wave" className={styleClasses.skeleton} /> */}
        {/* <Loader /> */}
      </div>
    );
  }
  if (!totalRecords) {
    return null;
  }

  return (
    <div data-testid="pagination-block">
      <div className={styleClasses.paginatedResultsText} data-testid="pagination-text">
        {/* {`${currentRecordsCount} of ${totalRecords} Results`} */}
        {`${Math.ceil(((totalRecords) / pageSize)) === activePage ? (totalRecords % pageSize || pageSize) : pageSize} of ${totalRecords} Results`}
      </div>
      <Pagination
        count={Math.ceil(((totalRecords) / pageSize))}
        shape="rounded"
        size="small"
        onChange={(_e, pageNumber) => {
          handlePageChange(pageNumber);
        }}
        classes={{ root: styleClasses.paginationRoot }}
        data-testid="pagination"
        renderItem={(item) => (
          <PaginationItem
            classes={{
              text: styleClasses.paginationText,
              selected: styleClasses.paginationSelected,
            }}
            {...item}
            data-testid="pagination-item"
          />
        )}
        page={activePage}
      />
    </div>
  );
}

export default PaginationBlock;
