/* eslint-disable camelcase */
import { useCallback, useEffect, useRef, useState } from "react";
import {
  ArrayHelpers,
  FieldArray,
  Formik,
  FormikProps,
  FormikValues,
  useFormikContext,
} from "formik";
import Grid from "@mui/material/Grid";
import { Add, HelpRounded } from "@mui/icons-material";
import * as Yup from "yup";
import CustomButton from "../../../button";
import FormikControl from "../../../formik-conrtols/FormikControl";
import FieldLabel from "../../../field-label";
import CustomModal from "../../../custom-modal";
import { roleDefinitionStyles, modalStyles, parStyles } from "../../useStyles";
import HelperTooltip from "../../../helper-tooltip";
import { ReactComponent as DeleteAction } from "../../../../assets/icons/DeleteAction.svg";
import { getScopeValuesByGeographicScope } from "../../../../api-services/delegation-module";
import { roleDefinition, roleNameTooltip, roleSlotsTooltip, scopeTooltip } from "../tooltip";
import { useSelector } from "react-redux";
import { RootState } from "../../../../appStore/store";
import HistoryTable from "../../../../modules/delegation/comments/historyTable";
import CommentSubmission from "../../../../modules/delegation/comments/commentSubmission";
import { postDelegationFieldHistoryData } from "../../../../api-services/review-delegation";
import { emptySchemaCheckForRoleDefinition } from "../formikContainerUtils";
import { debounce, isEqual } from "lodash";
import { useSnackbar } from "notistack";
import { routeCheckDelegation, routeCheckForAdminSummary, routeCheckForApprovalSummary, routeCheckForImplementationSummary, routeCheckForPendingApproval } from "./routingConditional";



export type RoleDefinitionType = {
  id: string | null;
  scope: string;
  role_name: string;
  justification: string;
  number_of_slots: string;
  justification_for_slots: string;
  regional_scope_label: string;
  regional_scope: number | null;
  market_scope_label: string;
  market_scope: number | null;
  city_scope_label: string;
  city_scope: number | null;
  country_scope_label: string;
  country_scope: number | null;
  is_role_for_thirdparty_user: boolean;
  does_servicenow_require: boolean;
  is_active: boolean;
  is_deleted: boolean;
};

const emptyRole = {
  id: null,
  scope: "Geographical",
  geographical_scope: "Regional",
  role_name: "",
  justification: "",
  number_of_slots: "",
  justification_for_slots: "",
  regional_scope: null,
  market_scope: null,
  city_scope: null,
  country_scope: null,
  regional_scope_label: "",
  market_scope_label: "",
  city_scope_label: "",
  country_scope_label: "",
  is_role_for_thirdparty_user: false,
  does_servicenow_require: false,
  is_active: true,
  is_deleted: false,
};

const geographicalScopeOptions = [
  {
    label: "Regional",
    value: "Regional",
  },
  {
    label: "Market",
    value: "Market",
  },
  {
    label: "Country",
    value: "Country",
  },
  {
    label: "City",
    value: "City",
  },
];

const generateAutoCompleteOptions = (data: { id: number; name: string }[]) => {
  return data.map((obj: any) => ({
    id: obj.id,
    value: obj.name,
    label: obj.name,
  }));
};

function RoleDefinition({ isDisabled }: { isDisabled: boolean }) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = roleDefinitionStyles();
  const modalClasses = modalStyles();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [roleGroupModalValues, setRoleGroupModalValues] =
    useState<RoleDefinitionType>({ ...emptyRole });
  const formikCtx: any = useFormikContext();
  const formRef = useRef<FormikProps<FormikValues>>(null);
  const [modalAction, setModalAction] = useState<{
    index: number;
    actionType: string;
  }>({
    index: -1,
    actionType: "",
  });
  const [totalSlotsUtilized, setTotalSlotsUtilized] = useState<number>(0);

  const [scopeValue, setScopeValue] = useState<{
    selection: string;
    regionOptions: { id: number; value: string; label: string }[];
    marketOptions: { id: number; value: string; label: string }[];
    countryOptions: { id: number; value: string; label: string }[];
    cityOptions: { id: number; value: string; label: string }[];
  }>({
    selection: "Regional",
    regionOptions: [],
    marketOptions: [],
    countryOptions: [],
    cityOptions: [],
  });
  const { fieldHistoryDelegationCommentsData } = useSelector((state: RootState) => state);
  const { submitDelegationCommentData } = useSelector((state: RootState) => state);
  const styleClasses = parStyles();
  const remediated_by = formikCtx.values.remediation_by
  const remediationCount = formikCtx.values.remediation_count;
  const checkForEditReviewFlow = () => {
    return routeCheckDelegation() || routeCheckForAdminSummary() || routeCheckForImplementationSummary() || routeCheckForApprovalSummary()
  }
  useEffect(() => {
    getScopeValuesByGeographicScope(scopeValue.selection)
      .then((res) => {
        const tempScopeValue = scopeValue;
        switch (scopeValue.selection) {
          case "Regional":
            tempScopeValue.regionOptions = [
              ...generateAutoCompleteOptions(res.data),
            ];
            break;
          case "Market":
            tempScopeValue.marketOptions = [
              ...generateAutoCompleteOptions(res.data),
            ];
            break;
          case "Country":
            tempScopeValue.countryOptions = [
              ...generateAutoCompleteOptions(res.data),
            ];
            break;
          case "City":
            tempScopeValue.cityOptions = [
              ...generateAutoCompleteOptions(res.data),
            ];
            break;
          default:
            break;
        }
        setScopeValue((prevValue) => ({ ...prevValue, ...tempScopeValue }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [scopeValue.selection]);

  useEffect(() => {
    let totalCount = 0;
    if (
      formikCtx.values.del_role_name_list &&
      formikCtx.values.del_role_name_list.length
    ) {
      formikCtx.values.del_role_name_list.forEach(
        (roleObj: RoleDefinitionType) => {
          if (roleObj.is_active)
            totalCount += parseInt(roleObj.number_of_slots, 10);
        }
      );
      setTotalSlotsUtilized(totalCount);
    }
  }, [formikCtx.values.del_role_name_list]);

  useEffect(() => {
    if (!modalOpen) {
      setModalAction({
        index: -1,
        actionType: "",
      });
      setRoleGroupModalValues(emptyRole);
    }
    if (modalOpen && modalAction.index >= 0) {
      const filteredRolesSlots = formikCtx.values.del_role_name_list.reduce(
        (slotSum: number, roleObj: RoleDefinitionType, index: number) => {
          if (index !== modalAction.index) {
            return slotSum * 1 + parseInt(roleObj.number_of_slots, 10);
          }
          return slotSum;
        },
        0
      );
      setTotalSlotsUtilized(filteredRolesSlots);
    }
  }, [modalOpen]);

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleSave = () => {
    const currentValues = formRef.current?.values
    if (formikCtx.values.remediation_count > 0) {
      // @ts-ignore
      const changedValues = Object.keys(currentValues !== undefined && currentValues).filter(key => formRef.current?.values[key] !== roleGroupModalValues[key])
      let finalChangedData: any = [];
      changedValues.length > 0 && changedValues.map((item) => {
        finalChangedData.push(
          {
            update_type: emptySchemaCheckForRoleDefinition.get(item),
            section: 'Role_definition',
            sub_section: item,
            // @ts-ignore
            value: roleGroupModalValues[item],
            role_name_section: roleGroupModalValues.role_name,
            user_details_section: ''
          }
        )
      })

      const historyPostBody = {
        request_id: formikCtx.values.request_id,
        is_submitted: false,
        data: finalChangedData
      }
      if (finalChangedData.length > 0) {
        postDelegationFieldHistoryData(historyPostBody).then((response) => {
          console.log(response)
        }).catch(error => error);
      }

    }
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleViewRole = (roleObject: RoleDefinitionType, index: number) => {
    setRoleGroupModalValues(roleObject);
    setModalAction({
      actionType: "view",
      index,
    });
    setModalOpen(true);
  };

  // const duplicatesCheckfunction = (values: any) => {
  //   const temp = formikCtx.values.del_role_name_list.filter((item: any) => item.is_active !== false)

  //   let booleanCheck = false;
  //   temp.length > 0 && temp.map((item: any) => {
  //     if (item.role_name === values.role_name) {
  //       booleanCheck = true;
  //       return
  //     }
  //   })
  //   return booleanCheck;
  // }
  const modalContentHandler = (
    arrayHelpers: ArrayHelpers,
    actionType: string
  ) => {
    switch (actionType) {
      case "view":
      case "edit":
      case "add":
        return (
          <Formik
            validationSchema={Yup.object({
              role_name: Yup.string().required("Please provide role name"),
              scope: Yup.string().required("Please select scope"),
              justification: Yup.string().when("scope", {
                is: "Geographical",
                then: Yup.string().required(
                  "Please provide justification for geographical scope"
                ),
                otherwise: Yup.string().required(
                  "Please provide justification for functional scope"
                ),
              }),
              number_of_slots: Yup.number()
                .min(1, "Number of slots should be between 1 to 50")
                .max(
                  50 - totalSlotsUtilized,
                  `You have already utilized ${totalSlotsUtilized} slots for previous roles. You can assign maximum ${50 - totalSlotsUtilized
                  } slots to this role.`
                )
                .required("Please provide the number of slots")
                .typeError("Slot can only be a number"),
              justification_for_slots: Yup.string().required(
                "Please provide the justification for slots"
              ),
              regional_scope_label: Yup.string().when(
                ["scope", "geographical_scope"],
                {
                  is: (scope: string, geographical_scope: string) =>
                    scope === "Geographical" &&
                    geographical_scope === "Regional",
                  then: Yup.string().required("Please provide regional scope"),
                  otherwise: Yup.string(),
                }
              ),
              market_scope_label: Yup.string().when(
                ["scope", "geographical_scope"],
                {
                  is: (scope: string, geographical_scope: string) =>
                    scope === "Geographical" && geographical_scope === "Market",
                  then: Yup.string().required("Please provide market scope"),
                  otherwise: Yup.string(),
                }
              ),
              city_scope_label: Yup.string().when(
                ["scope", "geographical_scope"],
                {
                  is: (scope: string, geographical_scope: string) =>
                    scope === "Geographical" && geographical_scope === "City",
                  then: Yup.string().required("Please provide city scope"),
                  otherwise: Yup.string(),
                }
              ),
              country_scope_label: Yup.string().when(
                ["scope", "geographical_scope"],
                {
                  is: (scope: string, geographical_scope: string) =>
                    scope === "Geographical" &&
                    geographical_scope === "Country",
                  then: Yup.string().required("Please provide country scope"),
                  otherwise: Yup.string(),
                }
              ),
              is_role_for_thirdparty_user: Yup.boolean(),
              does_servicenow_require: Yup.boolean(),
            })}
            onSubmit={(values: FormikValues) => {
              console.log('current index', modalAction.index)
              // if (duplicatesCheckfunction(values)) {
              //   enqueueSnackbar(`Duplicate role name can't be added`, { variant: 'warning' });
              //   return;
              // } else
              if (modalAction.index >= 0) {
                arrayHelpers.replace(modalAction.index, values);
              } else {
                arrayHelpers.push({ ...values });
              }
              handleClose();
            }}
            validateOnChange={false}
            initialValues={roleGroupModalValues}
            innerRef={formRef}
          >
            {({ values, setFieldValue, setFieldError }) => (
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={7}>
                    <FormikControl
                      disabled={isDisabled}
                      control="textfield"
                      label="Role name"
                      name="role_name"
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <span
                      style={{
                        display: "flex",
                        paddingTop: "8px",
                        height: "100%",
                      }}
                    >
                      <HelperTooltip tooltipMsg={roleNameTooltip}>
                        <HelpRounded />
                      </HelperTooltip>
                    </span>
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <div className={classes.modalScopeFieldLabel}>
                        Please select scope below:{" "}
                      </div>
                      <div style={{ paddingLeft: '5px' }}>
                        <HelperTooltip tooltipMsg={scopeTooltip}>
                          <HelpRounded style={{ color: '#0A2756' }} />
                        </HelperTooltip>
                      </div>
                    </div>


                    <FormikControl
                      disabled={isDisabled}
                      control="radio"
                      name="scope"
                      radioGrouplabels={[
                        {
                          label: "Geographical",
                          value: "Geographical",
                        },
                        {
                          label: "Functional",
                          value: "Functional",
                        },
                      ]}
                      isInline
                    />
                  </Grid>
                  {values.scope === "Geographical" && (
                    <>
                      <Grid item xs={6}>
                        <FormikControl
                          required
                          disabled={isDisabled}
                          control="autocomplete"
                          label="Geographical scope"
                          name="geographical_scope"
                          options={geographicalScopeOptions}
                          handleSelectionChange={(selection: {
                            value: string;
                            label: string;
                          }) => {
                            setScopeValue((prevValue) => ({
                              ...prevValue,
                              selection: selection.value,
                            }));
                            setFieldValue(
                              "geographical_scope",
                              selection.value
                            );
                          }}
                        />
                      </Grid>
                      {values.geographical_scope === "Regional" && (
                        <Grid item xs={6}>
                          <FormikControl
                            required
                            disabled={isDisabled}
                            control="autocomplete"
                            label="Regional scope"
                            name="regional_scope_label"
                            options={scopeValue.regionOptions}
                            handleSelectionChange={(selection: {
                              value: string;
                              label: string;
                              id: number;
                            }) => {
                              setFieldValue(
                                "regional_scope_label",
                                selection.value
                              );
                              setFieldValue("regional_scope", selection.id);
                            }}
                          />
                        </Grid>
                      )}
                      {values.geographical_scope === "Market" && (
                        <Grid item xs={6}>
                          <FormikControl
                            disabled={isDisabled}
                            control="autocomplete"
                            label="Market scope"
                            name="market_scope_label"
                            options={scopeValue.marketOptions}
                            handleSelectionChange={(selection: {
                              value: string;
                              label: string;
                              id: number;
                            }) => {
                              setFieldValue(
                                "market_scope_label",
                                selection.value
                              );
                              setFieldValue("market_scope", selection.id);
                            }}
                            required
                          />
                        </Grid>
                      )}
                      {values.geographical_scope === "City" && (
                        <Grid item xs={6}>
                          <FormikControl
                            disabled={isDisabled}
                            control="autocomplete"
                            label="City scope"
                            name="city_scope_label"
                            required
                            options={scopeValue.cityOptions}
                            handleSelectionChange={(selection: {
                              value: string;
                              label: string;
                              id: number;
                            }) => {
                              setFieldValue(
                                "city_scope_label",
                                selection.value
                              );
                              setFieldValue("city_scope", selection.id);
                            }}
                          />
                        </Grid>
                      )}
                      {values.geographical_scope === "Country" && (
                        <Grid item xs={6}>
                          <FormikControl
                            required
                            disabled={isDisabled}
                            control="autocomplete"
                            label="Country scope"
                            name="country_scope_label"
                            options={scopeValue.countryOptions}
                            handleSelectionChange={(selection: {
                              value: string;
                              label: string;
                              id: number;
                            }) => {
                              setFieldValue(
                                "country_scope_label",
                                selection.value
                              );
                              setFieldValue("country_scope", selection.id);
                            }}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <FormikControl
                          disabled={isDisabled}
                          control="textfield"
                          label="Justification for geographical scope"
                          name="justification"
                          multiline
                          minRows={3}
                          required
                        />
                      </Grid>
                    </>
                  )}
                  {values.scope === "Functional" && (
                    <Grid item xs={12}>
                      <FormikControl
                        required
                        disabled={isDisabled}
                        control="textfield"
                        label="Justification for functional scope"
                        name="justification"
                        multiline
                        minRows={3}
                      />
                    </Grid>
                  )}
                  <Grid item xs={7}>
                    <FormikControl
                      disabled={isDisabled}
                      control="textfield"
                      label="Slot/s required"
                      name="number_of_slots"
                      type="number"
                      required
                      onChange={(e: any) => {
                        setFieldValue("number_of_slots", e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <span
                      style={{
                        display: "flex",
                        paddingTop: "8px",
                        height: "100%",
                      }}
                    >
                      <HelperTooltip tooltipMsg={roleSlotsTooltip}>
                        <HelpRounded />
                      </HelperTooltip>
                    </span>
                  </Grid>
                  <Grid item xs={12}>
                    <FormikControl
                      required
                      disabled={isDisabled}
                      control="textfield"
                      label="Justification for slot/s required"
                      name="justification_for_slots"
                      multiline
                      minRows={3}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikControl
                      disabled={isDisabled}
                      control="checkbox"
                      label="Is this role for 3rd Party Users?"
                      name="is_role_for_thirdparty_user"
                      isCompact
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikControl
                      disabled={isDisabled}
                      control="checkbox"
                      label="Does this role require ServiceNow resolver group access?"
                      name="does_servicenow_require"
                      isCompact
                    />
                  </Grid>
                </Grid>
              </div>
            )}
          </Formik>
        );
      case "delete":
        return (
          <div>
            <div className={modalClasses.modalContentDiv}>
              Are you sure you want to delete this Role Definition and all its
              data?
            </div>
            <div className={modalClasses.modalTextDiv}>
              <span className={modalClasses.spanText}>{"Note: "}</span>A
              cancelled Role Definition will be permanently deleted and cannot
              be retrieved.
            </div>
          </div>
        );
      // case "slotlimitreached":
      //   return (
      //     <div className={modalClasses.modalContentDiv}>
      //       This user cannot be added. There are already 50 privileged access users associated with this Delegation.
      //       The number of users associated with a role should be no more than 50 and ideally, significantly less. You should keep the number of users in a given privilege role to the minimum possible. If you still require additional privileged access users, you will need to split the team into sub-teams and roles.
      //     </div>
      //   );
      case "usersPresent":
        return (
          <div className={modalClasses.modalContentDiv}>
            Role cannot be deleted as there are users present in this role.
          </div>
        );
      default:
        return null;
    }
  };

  const modalActionHandler = (
    arrayHelpers: ArrayHelpers,
    actionType: string
  ) => {
    switch (actionType) {
      case "view":
      case "edit":
      case "add":
        return (
          <div className={modalClasses.modalActionDiv}>
            <CustomButton
              variant="contained"
              actionType="secondary"
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </CustomButton>
            <CustomButton
              variant="contained"
              actionType="primary"
              disableMargin
              disabled={isDisabled}
              onClick={() => {
                handleSave();
              }}
            >
              Save
            </CustomButton>
          </div>
        );
      case "delete":
        return (
          <div className={modalClasses.modalActionDiv}>
            <CustomButton
              variant="contained"
              actionType="secondary"
              onClick={() => {
                const roleToDelete =
                  formikCtx.values.del_role_name_list[modalAction.index];
                arrayHelpers.replace(modalAction.index, {
                  ...roleToDelete,
                  is_active: false,
                });
                handleClose();
              }}
            >
              Delete
            </CustomButton>
            <CustomButton
              variant="contained"
              actionType="primary"
              disableMargin
              onClick={() => {
                handleClose();
              }}
            >
              Keep
            </CustomButton>
          </div>
        );
      // case "slotlimitreached":
      //   return (
      //     <div className={modalClasses.modalActionDiv}>
      //       <CustomButton
      //         variant="contained"
      //         actionType="primary"
      //         disableMargin
      //         onClick={() => {
      //           handleClose();
      //         }}
      //       >
      //         OK
      //       </CustomButton>
      //     </div>
      //   );
      case "usersPresent":
        return (
          <div className={modalClasses.modalActionDiv}>
            <CustomButton
              variant="contained"
              actionType="primary"
              disableMargin
              onClick={() => {
                handleClose();
              }}
            >
              OK
            </CustomButton>
          </div>
        );
      default:
        return null;
    }
  };

  const getModalHeader = (actionType: string) => {
    switch (actionType) {
      case "view":
      case "edit":
      case "add":
        return "Role Definition";
      case "delete":
        return "Delete Role Definition";
      // case "slotlimitreached":
      //   return "Warning";
      case "usersPresent":
        return "Note";
      default:
        return "";
    }
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={5}>
          <FieldLabel
            Title="Role definition"
            tooltipMsg={roleDefinition}
            required
          />
        </Grid>
        <Grid item xs={5}></Grid>
        <Grid item xs={2}>
          {(checkForEditReviewFlow() && remediationCount > 0) ? (
            <div className={styleClasses.mainSectionDiv}>
              <HistoryTable
                parId={formikCtx.values.request_id}
                section="Role_definition"
                subSection=''
                data={fieldHistoryDelegationCommentsData.length > 0 ? fieldHistoryDelegationCommentsData[6].Role_definition : null}
              />

              <CommentSubmission
                sectionName='Role_definition'
                subSection=''
                updateType='Select update'
                remediation_by={remediated_by}
                data={submitDelegationCommentData.length > 0 ? submitDelegationCommentData[6].Role_definition : null}
              />
            </div>
          ) : routeCheckForPendingApproval() && (
            <div className={styleClasses.mainSectionDiv}>
              <HistoryTable
                parId={formikCtx.values.request_id}
                section="Role_definition"
                subSection=''
                data={fieldHistoryDelegationCommentsData.length > 0 ? fieldHistoryDelegationCommentsData[6].Role_definition : null}
              />
              <CommentSubmission
                sectionName='Role_definition'
                subSection=''
                updateType='Select update'
                remediation_by={remediated_by}
                data={submitDelegationCommentData.length > 0 ? submitDelegationCommentData[6].Role_definition : null}
              />
            </div>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <FieldArray
          name="del_role_name_list"
          render={(arrayHelpers) => (
            <>
              {!isDisabled && (
                <Grid item xs={4} sm={3} md={3} lg={3}>
                  <div
                    className={classes.addRoleTile}
                    onClick={() => {
                      setModalAction({
                        actionType:
                          totalSlotsUtilized >= 50 ? "slotlimitreached" : "add",
                        index: -1,
                      });
                      setModalOpen(true);
                    }}
                    role="presentation"
                    onKeyDown={() => null}
                  >
                    <div style={{ display: "flex" }}>
                      <Add style={{ fontSize: "20px", lineHeight: "20px" }} />
                      Add
                    </div>
                  </div>
                </Grid>
              )}
              {formikCtx.values.del_role_name_list &&
                formikCtx.values.del_role_name_list.length
                ? formikCtx.values.del_role_name_list.map(
                  (del_role_obj: RoleDefinitionType, index: number) =>
                    del_role_obj.is_active ? (
                      <Grid
                        item
                        xs={4}
                        sm={3}
                        md={3}
                        lg={3}
                        className={classes.roleTileWrapper}
                      >
                        <div
                          className={classes.roleTile}
                          onClick={() => {
                            handleViewRole(del_role_obj, index);
                          }}
                          role="presentation"
                        >
                          <div className={classes.tileHeader}>
                            Role Name:
                          </div>
                          <div className={classes.tileValue}>
                            {del_role_obj.role_name || ""}
                          </div>
                          <div className={classes.tileHeader}>Scope: </div>
                          <div className={classes.tileValue}>
                            {del_role_obj.scope || ""}
                          </div>
                          <div className={classes.tileHeader}>
                            Slots:
                            <span className={classes.tileValue}>
                              {` ${del_role_obj.number_of_slots}`}
                            </span>
                          </div>
                        </div>
                        {!isDisabled && (
                          <div className={classes.roleActionStrip}>
                            <div className={classes.roleActionStripAbsolute}>
                              <span className={classes.iconWrapper}>
                                <DeleteAction
                                  onClick={() => {
                                    const usersAgainstRole = formikCtx.values.del_user_detail_list.filter((obj: any) => (isEqual(obj.role_name, del_role_obj.role_name) && obj.is_active)).length
                                    setModalAction({
                                      actionType: usersAgainstRole > 0 ? "usersPresent" : "delete",
                                      index,
                                    });
                                    setModalOpen(true);
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                        )}
                      </Grid>
                    ) : null
                )
                : null}
              <CustomModal
                modalHeader={getModalHeader(modalAction.actionType)}
                open={modalOpen}
                onClose={handleClose}
                modalAction={modalActionHandler(
                  arrayHelpers,
                  modalAction.actionType
                )}
                modalContent={modalContentHandler(
                  arrayHelpers,
                  modalAction.actionType
                )}
              />
            </>
          )}
        />
      </Grid>
    </div>
  );
}

export default RoleDefinition;
