import { useOktaAuth } from '@okta/okta-react';
import { useState } from 'react';
import LockIcon from '../../assets/images/login-lock-icon.svg';
import LoginBg from '../../assets/images/login_bg.jpeg';
import Brandlogo from '../../components/brandlogo';
import StyledSolidButton from '../../components/button/SolidButton';
import FullScreenBg from '../../components/fullscreen-bg';
import useStyles from './useStyle';

function LoginScreen() {
  const { oktaAuth } = useOktaAuth();
  const LoginStyles = useStyles()
  const [isLoginClicked, setIsLoginClicked] = useState(false);

  const login = async () => {
    setIsLoginClicked(true);
    await oktaAuth.signInWithRedirect();
  };

  return (
    <div data-testid="login-screen">
      <FullScreenBg bgimage={LoginBg}>
        <div className={LoginStyles.BrandLogoDiv}>
          <Brandlogo />
        </div>
        <div className={LoginStyles.LoginPanel}>
          <div className={LoginStyles.mainstyle}>
            <div className={LoginStyles.Image}>
              <img src={LockIcon} />
            </div>
            <div className={LoginStyles.headingStyles}>
              Privileged Access Governance
            </div>
          </div>
          <StyledSolidButton
            actionType="primary"
            disabled={isLoginClicked}
            onClick={login}
            data-testid="signin-button"
          >
            <span style={{
              fontFamily: 'Poppins-Medium',
            }}>
              Sign In </span>
          </StyledSolidButton>
        </div>
      </FullScreenBg>
    </div>
  );
}

export default LoginScreen;
