import { configureStore } from '@reduxjs/toolkit'
import delegationSummaryReducer from './reducer/delegationSummaryReducer'
import loginReducer from './reducer/loginReducer'
import chatHistoryReducer from './reducer/chatHistoryReducer';
import isCommented from './reducer/isCommented';
import submitParApproverComments from './reducer/submitParApproverComments';
import parUpdatedFieldHistorySlice from './reducer/fieldHistoryReducer';
import submitParRequestorComments from './reducer/submitParRequestorComments';
import delegationEditReviewReducer from './reducer/delegationEditReviewReducer';
import delegationFieldHistoryReducer from './reducer/delegationFieldHistoryReducer';
import aadImplementationOnprem from './reducer/aadImplementationOnprem';
import adImplementationOnprem from './reducer/adImplementationOnprem';
import cloudImplementation from './reducer/cloudImplementation';

import sarEditReviewReducer from './reducer/sarEditReviewReducer';
import sarFieldHistoryReducer from './reducer/sarFieldHistoryReducer';
import aadSarImplementation from './reducer/sarImplementertask';

export const store = configureStore({
        reducer: {
                loginData: loginReducer,
                delegationSummary: delegationSummaryReducer,
                updatedFieldHistoryData: parUpdatedFieldHistorySlice,
                chatFieldHistoryData: chatHistoryReducer,
                isCommentedState: isCommented,
                submitParCommentsData: submitParApproverComments,
                submitParRequestorCommentsData: submitParRequestorComments,
                submitDelegationCommentData: delegationEditReviewReducer,
                fieldHistoryDelegationCommentsData: delegationFieldHistoryReducer,
                aadImplementationOnpremList: aadImplementationOnprem,
                adImplementationOnpremList: adImplementationOnprem,
                cloudImplementationList: cloudImplementation,
                submitSarCommentData: sarEditReviewReducer,
                fieldHistorySarCommentsData: sarFieldHistoryReducer,
                aadSarImplementationList: aadSarImplementation,

        },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch