import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import Grid from '@mui/material/Grid';
import { useSnackbar } from 'notistack';
import { Skeleton } from '@mui/material';
import FormikControl from '../../../formik-conrtols/FormikControl';
import FieldLabel from '../../../field-label';
import { getAccountTypeValues } from '../../../../api-services/delegation-module';
import ReviewActions from '../../../review-actions';
import { format, isDate } from 'date-fns';
import { chooseAccountType } from '../tooltip';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../appStore/store';
import { parStyles } from '../../useStyles';
import HistoryTable from '../../../../modules/delegation/comments/historyTable';
import CommentSubmission from '../../../../modules/delegation/comments/commentSubmission';
import { routeCheck, routeCheckDelegation, routeCheckForAdminSummary, routeCheckForApprovalSummary, routeCheckForImplementationSummary, routeCheckForPendingApproval } from './routingConditional';

function AccountType({ isDisabled }: { isDisabled: boolean }) {
  const formikCtx: any = useFormikContext();
  const [isAPILoading, setIsAPILoading] = useState(true);
  const [accountTypeOptions, setAccountTypeOptions] = useState<{
    label: string,
    value: string,
    additionalValue: string,
  }[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const { fieldHistoryDelegationCommentsData } = useSelector((state: RootState) => state);
  const { submitDelegationCommentData } = useSelector((state: RootState) => state);
  const styleClasses = parStyles();
  const remediated_by = formikCtx.values.remediation_by
  const remediationCount = formikCtx.values.remediation_count;

  // const routeCheck = () => {
  //   return location.pathname.slice(1, 5).toLocaleLowerCase() === 'dele';
  // }
  // const routeCheckForPendingApproval = () => {
  //   return location.pathname.slice(1, 32).toLocaleLowerCase() === 'myapprovaltasks/pendingapproval'
  // }

  // const routeCheckForImplementationSummary = () => {
  //   return location.pathname.slice(1, 44).toLocaleLowerCase() === 'myimplementationtasks/implementationsummary'
  // }

  // const routeCheckForAdminSummary = () => {
  //   return location.pathname.slice(1, 32).toLocaleLowerCase() === 'myadministrativetasks/delegation'
  // }

  // const routeCheckForApprovalSummary = () => {
  //   return location.pathname.slice(1, 32).toLocaleLowerCase() === 'myapprovaltasks/approvalsummary'
  // }

  const checkForEditReviewFlow = () => {
    return routeCheckDelegation() || routeCheckForAdminSummary() || routeCheckForImplementationSummary() || routeCheckForApprovalSummary()
  }
  const formatDateInside = (dateValue: any) => (dateValue && isDate(new Date(dateValue)) ? format(new Date(dateValue), 'MMM dd, yyyy') : '');

  useEffect(() => {
    setIsAPILoading(true);
    if (formikCtx.values.request_type === 1) {
      getAccountTypeValues(formikCtx.values.request_type).then((res) => {
        if (res.status) {
          setAccountTypeOptions(res.data
            .map((obj: {
              id: string,
              value: string
            }) => ({
              label: obj.value,
              value: obj.id,
              additionalValue: obj.value,
            })));
        } else {
          enqueueSnackbar('Something went wrong! Please try again', { variant: 'error' });
        }
        setIsAPILoading(false);
      }).catch((err) => {
        console.error(err);
        enqueueSnackbar('Something went wrong! Please try again', { variant: 'error' });
        setIsAPILoading(false);
      });
    } else {
      setAccountTypeOptions([{
        label: 'Standard Service Account',
        value: 'standard_service_account',
        additionalValue: 'Standard Service Account',
      }, {
        label: 'Complex Service Account',
        value: 'complex_service_account',
        additionalValue: 'Complex Service Account',
      },
      ]);
      setIsAPILoading(false);
    }
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={5}>
          <div>
            <FieldLabel Title="Choose account type" tooltipMsg={chooseAccountType} required />
          </div>
          <div style={{ color: '#A7A7A7', fontFamily: 'NotoSerif-Regular', fontSize: '10px', marginTop: '-5px', paddingBottom: '10px' }}>
            {/* @ts-ignore */}
            {(fieldHistoryDelegationCommentsData[5].Choose_account_type?.length > 0) && `Updated on ${formatDateInside(fieldHistoryDelegationCommentsData[5].Choose_account_type[fieldHistoryDelegationCommentsData[5].Choose_account_type?.length - 1].last_updated)}`}
          </div>
        </Grid>
        <Grid item xs={5}>
        </Grid>
        <Grid item xs={2}>
          {(checkForEditReviewFlow() && remediationCount > 0) ? (
            <div className={styleClasses.mainSectionDiv}>
              <HistoryTable
                parId={formikCtx.values.request_id}
                section="Choose_account_type"
                subSection=''
                data={fieldHistoryDelegationCommentsData.length > 0 ? fieldHistoryDelegationCommentsData[5].Choose_account_type : null}
              />

              <CommentSubmission
                sectionName='Choose_account_type'
                subSection=''
                updateType='Select update'
                remediation_by={remediated_by}
                data={submitDelegationCommentData.length > 0 ? submitDelegationCommentData[5].Choose_account_type : null}
              />
            </div>
          ) : routeCheckForPendingApproval() && (
            <div className={styleClasses.mainSectionDiv}>
              <HistoryTable
                parId={formikCtx.values.request_id}
                section="Choose_account_type"
                subSection=''
                data={fieldHistoryDelegationCommentsData.length > 0 ? fieldHistoryDelegationCommentsData[5].Choose_account_type : null}
              />

              <CommentSubmission
                sectionName='Choose_account_type'
                subSection=''
                updateType='Select update'
                remediation_by={remediated_by}
                data={submitDelegationCommentData.length > 0 ? submitDelegationCommentData[5].Choose_account_type : null}
              />
            </div>
          )}
        </Grid>
      </Grid>
      <Grid container flexDirection="column">
        {isAPILoading
          ? (
            <Skeleton animation="wave" variant="rectangular" height="30px" />
          ) : (
            <FormikControl
              disabled={isDisabled}
              control="radio"
              name="del_delegation_account_type_id"
              radioGrouplabels={accountTypeOptions}

            />
          )}
      </Grid>
    </>
  );
}
export default AccountType;
