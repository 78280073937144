import { makeStyles } from "@mui/styles";

const approverManagementStyles = makeStyles({
  pageContainer: {
    padding: "16px 0px 20px",
  },
  gridContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    "& .MuiAccordionSummary-content.Mui-expanded": {
      //background:'red',
      margin:"16px 0!important"
    }
  },
  gridFirst: {
    padding: "0 10px 0 0",
  },
  gridSecond: {
    padding: "0 0 0 10px",
  },
  gridTitle: {
    padding: "10px 15px",
    backgroundColor: "#DFEFFF",
    color: "#0A2756",
    fontSize: "14px",
    fontWeight: "500",
    fontFamily:"Poppins-Medium"
  },
  accordion: {
    boxShadow: "none",
    borderBottom: "1px solid #C6D2DC",
    borderRadius: "0",
    position: "unset",
    borderBottomLeftRadius: "0 !important",
    borderBottomRightRadius: "0 !important",
    margin: "0 !important",
    //background: "red",
    '& .MuiOutlinedInput-notchedOutline': {
      WebkitTextFillColor: 'red !important',
      //background:'red',
      border:'1px solid #0080FF !important'
    }
  },
  accTitle: {
    color: "#0A2756",
    fontSize: "14px",
    fontWeight: "400",
    width: "90%",
    fontFamily:"NotoSerif-Regular"
  },
  editBtn: {
    color: "#00B5B1",
    fontSize: "14px",
    fontWeight: "400",
    margin: "0",
    top: "12px",
    right: "50px",
    position: "absolute",
    padding: "5px",
    zIndex: "99",
    cursor: "pointer",
    fontFamily:"Poppins-Regular"
  },
  accordionSummary: {
    minHeight: "0px !important",
    zIndex: "1",
    "&.MuiButtonBase-root.MuiAccordionSummary-root:hover:not(.Mui-disabled)": {
      background: '#F1FFFE',
    },
  },

  modalContentDiv: {
    marginBottom: "12px",
    color: "#0A2756",
    font: "normal normal normal 14px/21px NotoSerif-Regular",
   
  },
  modalTextDiv: {
    //maxWidth: "52ch",
    color: "#0A2756",
    font: "normal normal normal 14px/22px NotoSerif-Regular",
     marginTop:'10px'
  },
  spanText: {
    fontFamily: "NotoSerif-Bold",
    fontSize: '14px',
    color: '#0A2756',
  },
  updateModalContent: {
    marginBottom: "8px",
    // maxWidth: '85ch',
    color: "#0A2756",
    font: "normal normal normal 14px/18px Poppins-Medium",
  },
  updateModalNote: {
    marginBottom: "16px",
    maxWidth: "86ch",
    font: "normal normal normal 14px/22px NotoSerif-Regular",
    color: "#0A2756",
  },
  modalActionDiv: {
    display: "flex",
    justifyContent: "right",
  },
  textFieldRoot: {
    width: "100%",
    border: "1px solid #A7A7A7",
    color: "#A7A7A7",
  },
  textFieldInput: {
    padding: "12px !important",
    font: "normal normal normal 12px/17px NotoSerif-Regular !important",
    "&>fieldset>legend": {
      fontSize: "1em",
    },
    "&>textarea:first-child": {
      minHeight: "26px !important",
    },
    "&>textarea": {
      color: "#0A2756 !important",
      fontSize: "14px !important",
    },
    "&.Mui-focused > fieldset": {
      border: "1px solid #0080FF !important",
    },
  },
  textFieldLabel: {
    width: "100%",
    font: "normal normal normal 12px/17px NotoSerif-Regular !important",
    transform: "translate(12px, 8px) scale(1) !important",
  },
  textFieldLabelShrink: {
    transform: "translate(14px, -8px) scale(1) !important",
    color: "#0080FF !important",
  },
  actionDiv: {
    marginTop: "20px",
  },
 TableForDependency: {
   margin: "30px 0",
    "& .MuiTableRow-head": {
      font:'normal normal normal 14px/19px Poppins-Medium !important'
    },
    "& .MuiTableCell-head": {
      font:'normal normal normal 14px/19px Poppins-Medium !important'
    },
    "& .MuiTableCell-body": {
      height:'auto'
    }
  },
  modalActionDivNew: {
    "& button": {
      backgroundColor: "rgb(0, 181, 177) !important",
      border: "2px solid rgb(0, 181, 177) !important",
      color: " rgb(255, 255, 255) !important",
      borderRadius: '25px',
      "&:hover": {
        backgroundColor: "rgb(0, 165, 162) !important",
        borderColor: "rgb(0, 165, 162) !important",
      }
    }
  }, 
});

export default approverManagementStyles;
