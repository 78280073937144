import { styled } from '@mui/material/styles';
import BrandLogoWhite from '../../assets/images/brandlogo_white.svg';

const Image = styled('img')({
  width: 'auto',
  height: '35px',
  padding: '20px',
});

function Brandlogo() {
  return <Image src={BrandLogoWhite} />;
}

export default Brandlogo;
