/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-no-duplicate-props */
import { StandardTextFieldProps, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
interface IStyledTextFields extends StandardTextFieldProps {
  name?: string;
  value: string;
  isDisabled?: boolean;
  implementationFieldCheck?: boolean;
  //endAdornment?: ReactDOM
}
const styles = makeStyles({
  inputContainer: {
    // margin: '8px auto',
  },
  notchedOutline: {
    border: "1px solid #A7A7A7",
  },

  input: {
    // font: 'normal normal normal 12px/17px ',
    fontSize: "14px",
    fontFamily: "NotoSerif-Regular",
    // height: '32px',
    color: "#0A2756",
    // "& .MuiFilledInput-root": {
    //   background: "rgb(232, 241, 250)"
    // }
  },
  // inputImplementation: {
  //   // font: 'normal normal normal 12px/17px ',
  //   fontSize: "14px",
  //   fontFamily: "NotoSerif-Regular",
  //   // height: '32px',
  //   color: "#0A2756",
  //   // "& .MuiFilledInput-root": {
  //   //   background: "rgb(232, 241, 250)"
  //   // }
  // },
  focused: {
    font: "14px NotoSerif-Regular",
    border: "#0080FF",
  },
  label: {
    width: "100%",
    color: "#A7A7A7",
    fontSize: "12px",
    fontFamily: "NotoSerif-Regular",
    // font: 'normal normal normal 12px/17px NotoSerif-Regular ',
    // transform: 'translate(12px, 8px) scale(1) ',
    "&::last-letter": {
      color: "red",
    },
    "&.Mui-disabled": {
      color: "#0080FF !important",
    },
  },
  labelImplementation: {
    width: "100%",
    color: "#A7A7A7",
    fontSize: "12px",
    fontFamily: "NotoSerif-Regular",
    // font: 'normal normal normal 12px/17px NotoSerif-Regular ',
    // transform: 'translate(12px, 8px) scale(1) ',
    "&::last-letter": {
      color: "red",
    },
    "&.Mui-disabled": {
      color: "#D9D9D9 !important",
    },
  },
  shrink: {
    transform: "translate(12px, -8px) scale(0.9)",
    color: "#0080FF ",
  },
  error: {
    border: "#EF5B2B !important",
    letterSpacing: "0px",
    color: "#EF5B2B !importnant",
  },
  helperText: {
    font: "normal normal normal 10px/20px NotoSerif-Regular",
  },
  helperTextError: {
    color: "#EF5B2B !important",
  },
  disable: {
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: (props: { isDisabled?: boolean }) =>
        props.isDisabled ? "#A7A7A7" : "#0A2756",
    },
  },
  implementationDisable: {
    backgroundColor: '#F6F6F6',
  },
  asterisk: {
    color: "#EF5B2B",
  },
});

function TextFields({
  placeholder,
  label,
  onChange,
  onFocus,
  multiline,
  minRows,
  name,
  value,
  error,
  disabled,
  required,
  isDisabled,
  implementationFieldCheck,
}: IStyledTextFields) {
  const styleClasses = styles({ isDisabled });
  return (
    <div className={styleClasses.inputContainer}>
      <TextField
        placeholder={placeholder}
        label={label}
        onChange={onChange}
        onFocus={onFocus}
        multiline={multiline}
        minRows={minRows}
        variant="outlined"
        data-testid="text-field"
        name={name}
        error={error}
        value={value}
        helperText={error}
        disabled={disabled}
        fullWidth
        // sx={{
        //   "& .MuiInputBase-input.Mui-disabled": {
        //     WebkitTextFillColor: "#000000",
        //   },
        // }}
        InputProps={{
          classes: {
            // root: styleClasses.inputRoot,
            notchedOutline: styleClasses.notchedOutline,
            input: styleClasses.input,
            // focused: styleClasses.focused,
            error: styleClasses.error,
            disabled: implementationFieldCheck ? styleClasses.implementationDisable : styleClasses.disable,
          },
        }}
        inputProps={{
          "data-testid": "text-field-input",
        }}
        InputLabelProps={{
          classes: {
            root: implementationFieldCheck ? styleClasses.labelImplementation : styleClasses.label,
            shrink: styleClasses.shrink,
            asterisk: styleClasses.asterisk,
            //disabled: styleClasses.
          },
        }}
        FormHelperTextProps={{
          classes: {
            root: styleClasses.helperText,
            error: styleClasses.helperTextError,
          },
        }}
        size="small"
        required={required}
      />
    </div>
  );
}

export default TextFields;
