import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  radioGroupRoot: {
    marginLeft: '4px',
    marginBottom: '8px',
    flexDirection: (props: { isInline?: boolean }) => props.isInline ? 'row' : 'column',
  },
  checked: {
    color: '#00B5B1 !important',
    fontFamily: '13px NotoSerif-Regular',
    // '&.Mui-disabled': {
    //   color: '#0A2756 !important',
    // },
  },
  radioLabel: {
    font: 'normal normal 13px/13px NotoSerif-Regular !important',
    letterSpacing: '0px',
    color: '#0A2756',
    opacity: '1',
    '&.Mui-disabled': {
      color: '#0A2756 !important',
    },
  },
  radioRoot: {
    color: '#00B5B1 !important',
    padding: '4px',
    marginLeft: '4px',
    '&.Mui-disabled': {
      color: '#d9d9d9 !important',
    },
  },
  disabled: {
    color: '#d9d9d9',
  },
  errorSpan: {
    textAlign: 'left',
    font: 'normal normal normal 10px/20px NotoSerif-Regular',
    letterSpacing: '0px',
    color: '#EF5B2B',
  }
});

export default useStyles;
