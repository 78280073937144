import { createSlice, current } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export const delegationUpdatedFieldHistorySlice = createSlice({
    name: 'fieldHistoryDelegationCommentsData',
    initialState: [
        {
            Reason_for_Delegation_request: []
        },
        { Justification_for_access: [] },
        { Requestor_details: [] },
        { Team_details: [] },
        { Job_title_and_description: [] },
        { Choose_account_type: [] },
        { Role_definition: [] },
        { User_details: [] },
        { Provide_custom_request: [] },
        { Third_Party_Request_For_Service_in_place: [] },
        { Contract_number_or_Request_For_Service_reference: [] },
        { Request_For_Service_details: [] },
        { Implementation_plan: [] },
        { Access_required_for_Request_For_Service: [] },
        { Third_Party_Request_For_Service_timings: [] },
        { Privileged_access_Delegation_owner_sign_off: [] },
    ],
    reducers: {
        updatedFieldHistoryDelegation: (state, action: PayloadAction<any>) => {
            return [...action.payload]
        },
    }
});

export const { updatedFieldHistoryDelegation } = delegationUpdatedFieldHistorySlice.actions;
export default delegationUpdatedFieldHistorySlice.reducer;