import { useEffect, useState } from 'react';
import { Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import FormikContainerSar from '../../components/formik-containers/formikContainerSar/FormikContainerSar';
import BreadCrumbs from '../../components/breadcrumb';

// import { fetchAllCommentsReplyPart, fetchfieldHistoryForLastUpdate } from '../../api-services/review-delegation';
import { fetchAllCommentsReplyPart } from '../../api-services/par';
import { useDispatch, useSelector } from 'react-redux';
import { fieldUpdationHistory } from '../../appStore/reducer/reviewActionReducer';
import { chatHistoryData } from '../../appStore/reducer/chatHistoryReducer';
import { getParRequestData, getAllCountryByCityId } from '../../api-services/par';
import { RootState } from '../../appStore/store';
import { format, isDate } from 'date-fns';
import { searchUserByQueryDetails } from '../../api-services/common-api';
import { getRequestData, getSarImplementer } from '../../api-services/delegation-module';
import Loader from '../../components/loader';
import { submitSarComments } from '../../appStore/reducer/sarEditReviewReducer';
import { updatedFieldHistorySar } from '../../appStore/reducer/sarFieldHistoryReducer';
import { isEmpty } from 'lodash';
import { aadSarImplementationTasklist } from '../../appStore/reducer/sarImplementertask';
import { routeCheckForApprovalSummary } from '../../components/formik-containers/formikContainerSar/formSteps/routingConditional';

const styles = makeStyles({
    bodyMainDiv: {
        display: 'flex',
    },
    topDiv: {
        padding: '16px',
    },
});

function FormPageSar() {
    const { loginuserdata, loginsyncupdata } = useSelector((state: RootState) => state.loginData)
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [accountOwnerList, setaccountOwnerList] = useState<{
        first_name: string,
        last_name: string,
        email: string,
        country: string,
        city: string,
        phone: string | number,
        department: string,
        company: string,
        title: string,
    }[]>([]);

    const { requestId } = useParams<{ requestId: string }>();
    const styleClasses = styles();
    const { enqueueSnackbar } = useSnackbar();
    const [activeStep, setActiveStep] = useState(0);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [countryList, setCountryList] = useState('');
    const formatDate = (dateValue: any) => (dateValue && isDate(new Date(dateValue)) ? format(new Date(dateValue), 'dd MMM yyyy') : '');

    let okatValidationCheckObject = {
        oktaCompany: false,
        oktaPhone: false,
        oktaCity: false,
        oktaCountry: false,
        oktaJobTitle: false,
        oktaDepartment: false,
        oktahomeorg: false
    }

    const serachCountryByCity = (cityName: string) => {
        getAllCountryByCityId(cityName).then((res) => {
            setCountryList(res.data.country);
        }).catch((err) => {
            console.log(err);
        })
    }

    const getImplementationTasklistApis = (requestId: any) => {
        getSarImplementer(requestId).then((res) => {
            dispatch(aadSarImplementationTasklist(res.data))
        }).catch(error => error);
    }

    useEffect(() => {
        if (!requestId) {
            history.push('/delegation');
        }
        getRequestData(requestId)
            .then((successData) => {
                getImplementationTasklistApis(requestId)
                if (successData.data[0].dataverse[0].okta_data.value.length > 0 && successData.data[0].dataverse[0].okta_data.value[0] !== undefined) {
                    // okatValidationCheckObject.oktaCity = successData.data[0].dataverse[0].okta_data.value[0].new_city !== null ? true : false;
                    okatValidationCheckObject.oktahomeorg = successData.data[0].dataverse[0].okta_data.value[0].new_homeorg !== null ? true : false;
                    okatValidationCheckObject.oktaCompany = successData.data[0].dataverse[0].okta_data.value[0].new_company !== null ? true : false;
                    // okatValidationCheckObject.oktaCountry = successData.data[0].dataverse[0].okta_data.value[0].new_country !== null ? true : false;
                    okatValidationCheckObject.oktaDepartment = successData.data[0].dataverse[0].okta_data.value[0].new_department !== null ? true : false;
                    okatValidationCheckObject.oktaPhone = successData.data[0].dataverse[0].okta_data.value[0].new_primaryphone !== null ? true : false;
                    setData({
                        ...successData.data[0],
                        ...successData.data[1],
                        ...okatValidationCheckObject,
                        sar_department: okatValidationCheckObject.oktaDepartment ? successData.data[0].dataverse[0].okta_data.value[0].new_department : successData.data[1].sar_department,
                        // country: okatValidationCheckObject.oktaCountry ? successData.data[0].dataverse[0].okta_data.value[0].new_country : successData.data[0].country,
                        // city: okatValidationCheckObject.oktaCity ? successData.data[0].dataverse[0].okta_data.value[0].new_city : successData.data[0].city,
                        phone: okatValidationCheckObject.oktaPhone ? successData.data[0].dataverse[0].okta_data.value[0].new_primaryphone : successData.data[0].phone,
                    })
                } else {
                    setData({
                        ...successData.data[0],
                        ...successData.data[1],
                        company: successData.data[0].dataverse[1].db_data.company !== '' && successData.data[0].dataverse[1].db_data.company,
                        sar_third_party_company: successData.data[0].dataverse[1].db_data.third_party_company !== '' && successData.data[0].dataverse[1].db_data.third_party_company,
                        sar_not_listed_third_party_company: successData.data[0].dataverse[1].db_data.not_listed_third_party_company !== '' && successData.data[0].dataverse[1].db_data.not_listed_third_party_company,
                    })
                }
                dispatch(submitSarComments(successData.data[2].comments_history))
                dispatch(updatedFieldHistorySar(successData.data[3].fields_history))
                localStorage.setItem('historyDataOfMainSchemaSar', JSON.stringify({
                    ...successData.data[0],
                    ...successData.data[1],
                }))
                setIsLoading(false);
            }).catch((err) => {
                console.error(err);
                setIsLoading(false);
                enqueueSnackbar('Something went wrong while fetching request data.', { variant: 'error' });
                history.push('/delegation');
            });
    }, []);


    if (isLoading) {
        return (
            <div style={{ padding: '0px 32px' }}>
                <Loader openModal={true} />
            </div>
        );
    }

    const BreadCrumbHandler = (route: string) => {
        const newRoute = route.slice(1, 5).toLocaleLowerCase();
        switch (newRoute) {
            case 'dele':
                return (
                    <BreadCrumbs
                        breadcrumbData={[
                            {
                                key: 1,
                                title: 'Delegation',
                                href: '/delegation',
                            }, {
                                key: 2,
                                title: localStorage.getItem('event') === 'create' ? "Create New Delegation" : `Delegation ${data && data?.request_number}`,
                                href: '',
                            },
                        ]
                        }
                    />
                )
            case 'myap':
                return (
                    <BreadCrumbs

                        breadcrumbData={
                            [
                                {
                                    key: 1,
                                    title: 'My Approval Tasks',
                                    href: '/myApprovalTasks',
                                },
                                {
                                    key: 2,
                                    title: `${data && data?.request_number}`,
                                    href: '',
                                },

                            ]}
                    />
                )
            case 'myad':
                return (
                    <BreadCrumbs
                        breadcrumbData={[
                            {
                                key: 1,
                                title: 'My Administrative Task',
                                href: '/myadministrativeTasks',
                            }, {
                                key: 2,
                                title: `Delegation ${data && data?.request_number}`,
                                href: '',
                            },
                        ]}
                    />
                )
            case 'myim':
                return (
                    <BreadCrumbs
                        breadcrumbData={[
                            {
                                key: 1,
                                title: 'Pending Implementations',
                                href: '/myImplementationTasks',
                            }, {
                                key: 3,
                                title: `${data && data?.request_number}`,
                                href: '',
                            },
                        ]}
                    />
                )
            default: return null;
        }
    }

    return (
        <div className={styleClasses.topDiv}>
            <div style={{ paddingLeft: '7px' }}>
                {BreadCrumbHandler(location.pathname)}
            </div>
            <div className={styleClasses.bodyMainDiv}>
                <FormikContainerSar
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    data={data}
                    requestId={requestId}
                    path={location.pathname}
                />
            </div>
        </div>
    );
}
export default FormPageSar;
