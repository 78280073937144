/* eslint-disable react/require-default-props */
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { DialogProps } from '@mui/material';
import modalStyles from './modalStyles';

interface IStyledModalProps extends DialogProps {
  modalHeader: string;
  centerButton?: boolean;
  modalAction?: React.ReactNode;
  modalContent?: React.ReactNode;
  review?: boolean;
  modalHeaderPorps?: React.ReactNode;
}

export default function CustomModal({
  modalHeader,
  open,
  onClose,
  modalAction,
  modalContent,
  review,
  centerButton,
  modalHeaderPorps,
}: IStyledModalProps) {
  // @ts-ignore
  const styleClasses = modalStyles({ centerButton });
  return (
    <Dialog onClose={onClose}
      open={open} classes={{ paper: styleClasses.paperRoot }} data-testid="dialog" >
      <div className={styleClasses.modalHeader} data-testid="modalHeaderContainer">
        <div>
          {modalHeader}
        </div>
        <div>
          {modalHeaderPorps}
        </div>
      </div>
      <div className={styleClasses.modalContent}>
        <div className={styleClasses.contentDiv} data-testid="modalContentContainer">
          {modalContent}
        </div>
        <div className={review ? styleClasses.modalActionDivReview : styleClasses.actionDiv} data-testid="modalActionContainer">
          {modalAction}
        </div>
      </div>
    </Dialog>
  );
}
