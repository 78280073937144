import './index.css';
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import { useMemo } from "react";
import { Provider } from 'react-redux';
import { useHistory, } from "react-router-dom";
import { BrowserRouter as Router } from 'react-router-dom';
import UserConfirmation from './components/navigation-prompt';
import Layout from './layout/layout';
import { oktaAuthConfig } from "./config/env-constants";
import { store } from './appStore/store';


const App = () => {

  // for Okta connfiguration
  const oktaAuth = useMemo(() => new OktaAuth({ ...oktaAuthConfig }), []);
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any): Promise<void> => {
    // history.replace(toRelativeUrl(originalUri, window.location.origin));

    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };
  //End of Okta configuration

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Provider store={store}>
        <Router getUserConfirmation={(message, callback) => UserConfirmation({ message, callback })}>
          <Layout />
        </Router>
      </Provider>
    </Security>
  )
}
export default App;
