import { useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterOptions from '../../../components/filter-menu/FilterOptions';
import FilterMenu from '../../../components/filter-menu';
import useApprovalSummaryHook from './useApprovalSummaryHook';
import CustomButton from '../../../components/button';
import DynamicTable from '../../../components/dynamic-table';
import PaginationBlock from '../../../components/pagination-block';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ReactComponent as EmptyResultIcon } from "../../../assets/icons/no_results_icon.svg";
import { ReactComponent as VisibilityIcon } from '../../../assets/icons/eye_b.svg';
import CustomToggleButton from '../../../components/button/CustomToggleButton';
import CustomDatePicker from '../../../components/date-range-picker';
import { isEqual } from 'lodash';

function ApprovalSummaryPanel() {
  const history = useHistory();
  const {
    styleClasses,
    tableColumns,
    approvalSummaryData,
    isLoading,
    filters,
    setFilters,
    tableParameters,
    setTableParameters,
    requestIdFilter,
    requestorNameFilter,
    currentStatusFilter,
    requestTypeFilter,
    setCloseFilter,
    myLastActionFilter,
    menuData,
    setMenuData,
    clearValueHandler,
    closeFilter,
    datepickerProps,
    setDatepickerProps,
    setDateRangeValue,
    setDateRangeFilter,
    emptyFilters
  } = useApprovalSummaryHook();

  const openLinkedDelegation = (id: any) => {
    if (menuData.row.requestType.toLowerCase() === 'par') {
      if (menuData.row.request_purpose === "Connect to / manage a server or computer") {
        // console.log("123")
        history.push(`/myApprovalTasks/approvalSummary/${id}`);
      } else {
        history.push(`/myApprovalTasks/approvalSummarySar/${id}`);
      }
    }
  };

  const emptyRequestHandler = () => {
    if (!isLoading.tableLoading && approvalSummaryData?.results.length === 0) {
      return (
        <Grid item xs={12} data-testid="empty-delegation-screen">
          <div className={styleClasses.emptyResultContainer}>
            <EmptyResultIcon />
            <span>
              {isEqual(filters, emptyFilters)
                ? "There are no requests created by you."
                : "There are no requests matching to the selected filters."}
            </span>
          </div>
        </Grid>
      );
    }
    return null;
  };

  return (
    <>
      <Grid item xs={12} className={styleClasses.filtersContainer}>
        <div className={styleClasses.filterPanel}>
          <div className={styleClasses.filterIcon}>
            <FilterListIcon />
          </div>
          <div className={styleClasses.filterMenu}>
            <FilterMenu
              filterLabel="REQ ID"
              handleFilterClose={() => console.log('handleClose')}
              badgeCount={filters.requestId?.length}
            >
              <FilterOptions
                searchPlaceholder="Search"
                allowSearch
                optionsList={requestIdFilter}
                selectedOptions={filters.requestId}
                handleOptionSelection={(e, selection) => {
                  setFilters((prevFilters) => {
                    let newRequestIdArray = [...prevFilters.requestId];
                    if (!selection) {
                      newRequestIdArray = newRequestIdArray
                        .filter((option) => option !== (e.target.value));
                    } else newRequestIdArray.push(e.target.value);
                    return {
                      ...prevFilters,
                      requestId: [...newRequestIdArray],
                      page: 1,
                    };
                  });
                }}
                isFilterDataLoading={isLoading.filters.requestId}
                allowAll
                selectAllHandler={(isSelected: boolean) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    requestId: isSelected ? requestIdFilter
                      .map((obj: { id: string, value: string }) => obj.id) : [],
                  }));
                }}
              />
            </FilterMenu>
          </div>
          <div className={styleClasses.filterMenu}>
            <FilterMenu
              filterLabel="REQUESTOR NAME"
              handleFilterClose={() => console.log("handleClose")}
              badgeCount={filters.requestorName?.length}
            >
              <FilterOptions
                searchPlaceholder="Search"
                allowSearch
                optionsList={requestorNameFilter}
                selectedOptions={filters.requestorName}
                handleOptionSelection={(e, selection) => {
                  setFilters((prevFilters) => {
                    let newRequestorNameArray = [...prevFilters.requestorName];
                    console.log(selection, "name");
                    if (!selection) {
                      newRequestorNameArray = newRequestorNameArray.filter(
                        (option) => option !== e.target.value
                      );
                    } else newRequestorNameArray.push(e.target.value);
                    return {
                      ...prevFilters,
                      requestorName: [...newRequestorNameArray],
                      page: 1,
                    };
                  });
                }}
                isFilterDataLoading={isLoading.filters.requestorName}
                allowAll
                selectAllHandler={(isSelected: boolean) => {
                  // console.log(isSelected);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    requestorName: isSelected
                      ? requestorNameFilter.map(
                        (obj: { id: string; value: string }) => obj.id
                      )
                      : [],
                  }));
                }}
              />
            </FilterMenu>
          </div>
          <div className={styleClasses.filterMenu}>
            <FilterMenu
              filterLabel="REQUEST TYPE"
              handleFilterClose={() => console.log('handleClose')}
              badgeCount={filters.requestType?.length}
            >
              <FilterOptions
                searchPlaceholder="Search"
                allowSearch
                optionsList={requestTypeFilter}
                selectedOptions={filters.requestType}
                handleOptionSelection={(e, selection) => {
                  setFilters((prevFilters) => {
                    let newRequestStatusArray = [...prevFilters.requestType];
                    if (!selection) {
                      newRequestStatusArray = newRequestStatusArray
                        .filter((option: any) => option !== (e.target.value));
                    } else newRequestStatusArray.push(e.target.value);
                    return {
                      ...prevFilters,
                      requestType: [...newRequestStatusArray],
                      page: 1,
                    };
                  });
                }}
                isFilterDataLoading={isLoading.filters.requestId}
                allowAll
                selectAllHandler={(isSelected: boolean) => {
                  // console.log(isSelected);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    status: isSelected ? requestIdFilter
                      .map((obj: { id: number, value: string }) => obj.id) : [],
                  }));
                }}
              />
            </FilterMenu>
          </div>
          <div className={styleClasses.filterMenu}>
            <FilterMenu
              filterLabel="Current Status"
              handleFilterClose={() => console.log('handleClose')}
              badgeCount={filters.currentStatus?.length}
            >
              <FilterOptions
                searchPlaceholder="Search"
                allowSearch
                optionsList={currentStatusFilter}
                selectedOptions={filters.currentStatus}
                handleOptionSelection={(e, selection) => {
                  setFilters((prevFilters) => {
                    let newRequestStatusArray = [...prevFilters.currentStatus];
                    if (!selection) {
                      newRequestStatusArray = newRequestStatusArray
                        .filter((option) => option !== (e.target.value));
                    } else newRequestStatusArray.push(e.target.value);
                    return {
                      ...prevFilters,
                      currentStatus: [...newRequestStatusArray],
                      page: 1,
                    };
                  });
                }}
                isFilterDataLoading={isLoading.filters.currentStatus}
                allowAll
                selectAllHandler={(isSelected: boolean) => {
                  // console.log(isSelected);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    status: isSelected ? currentStatusFilter
                      .map((obj: { id: string, value: string }) => obj.id) : [],
                  }));
                }}
              />
            </FilterMenu>
          </div>
          <div className={styleClasses.filterMenu}>
            <FilterMenu
              filterLabel="MY LAST ACTION "
              handleFilterClose={() => console.log('handleClose')}
              badgeCount={filters.requestId?.length}
            >
              <FilterOptions
                searchPlaceholder="Search"
                allowSearch
                optionsList={myLastActionFilter}
                selectedOptions={filters.myLastAction}
                handleOptionSelection={(e, selection) => {
                  setFilters((prevFilters) => {
                    let newRequestTypeArray = [...prevFilters.myLastAction];
                    if (!selection) {
                      newRequestTypeArray = newRequestTypeArray
                        .filter((option) => option !== e.target.value);
                    } else newRequestTypeArray.push(e.target.value);
                    return {
                      ...prevFilters,
                      myLastAction: [...newRequestTypeArray],
                      page: 1,
                    };
                  });
                }}
                isFilterDataLoading={isLoading.filters.myLastAction}
                allowAll
                selectAllHandler={(isSelected: boolean) => {
                  // console.log(isSelected);
                  setFilters((prevFilters: any) => ({
                    ...prevFilters,
                    myLastAction: isSelected ? myLastActionFilter
                      .map((obj: { id: string, value: string }) => obj.id) : [],
                  }));
                }}
              />
            </FilterMenu>
          </div>
          <div className={styleClasses.filterMenu}>
            <FilterMenu
              filterLabel="Requested On"
              handleFilterClose={() => {
                if (closeFilter) {
                  setCloseFilter(false);
                }
                //console.log('handleClose');
              }}
              // badgeCount={filters.requestDate.endDate && filters.requestDate.startDate
              //   ? differenceInCalendarDays(
              //     filters.requestDate.endDate,
              //     filters.requestDate.startDate,
              //   ) + 1
              //   : 0}
              // badgeCount={filters.requestDate.endDate && filters.requestDate.startDate
              //   ? ' '
              //   : 0}
              closeFilterExternally={closeFilter}
            >
              <div className={styleClasses.dateRangeToggleContainer}>
                <CustomToggleButton
                  variant="contained"
                  isSelected={!!datepickerProps.isSingleDate}
                  onClick={() => {
                    if (!datepickerProps.isSingleDate) {
                      setDatepickerProps({
                        isSingleDate: true,
                        dateRange:
                        {
                          startDate: null,
                          endDate: null,
                        },
                      });
                    }
                  }}
                >
                  Single Date
                </CustomToggleButton>
                <CustomToggleButton
                  variant="contained"
                  isSelected={!datepickerProps.isSingleDate}
                  onClick={() => {
                    if (datepickerProps.isSingleDate) {
                      setDatepickerProps({
                        isSingleDate: false,
                        dateRange:
                        {
                          startDate: null,
                          endDate: null,
                        },
                      });
                    }
                  }}
                >
                  Date Range
                </CustomToggleButton>
              </div>
              <div className={styleClasses.dateRangeContainer}>
                <CustomDatePicker
                  isRangePicker={!datepickerProps.isSingleDate}
                  setDateRangeValue={setDateRangeValue}
                  dateRange={datepickerProps.dateRange}
                  disableFuture
                />
              </div>
              <div className={styleClasses.dateRangeApplyCta}>
                <CustomButton
                  variant="contained"
                  actionType="secondary"
                  disabled={!datepickerProps.dateRange.endDate}
                  onClick={() => setDateRangeFilter()}
                  isCompact
                  disableMargin
                >
                  Apply
                </CustomButton>
              </div>
            </FilterMenu>
          </div>
          <div className={styleClasses.filterMenu}>
            <FilterMenu
              filterLabel="Assigned On"
              handleFilterClose={() => {
                if (closeFilter) {
                  setCloseFilter(false);
                }
                console.log('handleClose');
              }}
              closeFilterExternally={closeFilter}
            >
              <div className={styleClasses.dateRangeToggleContainer}>
                <CustomToggleButton
                  variant="contained"
                  isSelected={!!datepickerProps.isSingleDate}
                  onClick={() => {
                    if (!datepickerProps.isSingleDate) {
                      setDatepickerProps({
                        isSingleDate: true,
                        dateRange:
                        {
                          startDate: null,
                          endDate: null,
                        },
                      });
                    }
                  }}
                >
                  Single Date
                </CustomToggleButton>
                <CustomToggleButton
                  variant="contained"
                  isSelected={!datepickerProps.isSingleDate}
                  onClick={() => {
                    if (datepickerProps.isSingleDate) {
                      setDatepickerProps({
                        isSingleDate: false,
                        dateRange:
                        {
                          startDate: null,
                          endDate: null,
                        },
                      });
                    }
                  }}
                >
                  Date Range
                </CustomToggleButton>
              </div>
              <div className={styleClasses.dateRangeContainer}>
                <CustomDatePicker
                  isRangePicker={!datepickerProps.isSingleDate}
                  setDateRangeValue={setDateRangeValue}
                  dateRange={datepickerProps.dateRange}
                  disableFuture
                />
              </div>
              <div className={styleClasses.dateRangeApplyCta}>
                <CustomButton
                  variant="contained"
                  actionType="secondary"
                  disabled={!datepickerProps.dateRange.endDate}
                  onClick={() => setDateRangeFilter()}
                  isCompact
                  disableMargin
                >
                  Apply
                </CustomButton>
              </div>
            </FilterMenu>
          </div>
          <CustomButton
            variant="contained"
            actionType="tertiary"
            onClick={() => {
              clearValueHandler()
            }}
            disableRipple
            disableMargin
            disablePadding
          >
            CLEAR
          </CustomButton>
        </div>
      </Grid>
      <Grid item xs={12}>
        <DynamicTable
          rows={approvalSummaryData.results}
          isLoading={isLoading.tableLoading}
          columns={tableColumns}
          pageNumber={tableParameters.page}
          pageSize={approvalSummaryData.pageSize}
        />
      </Grid>
      <Grid item xs={12} style={{ paddingTop: '21px' }}>
        <PaginationBlock
          totalRecords={approvalSummaryData?.results.length || 0}
          currentRecordsCount={approvalSummaryData?.results?.length}
          activePage={tableParameters.page}
          handlePageChange={(pageNumber) => {
            setTableParameters((prevParameters) => ({
              ...prevParameters,
              page: pageNumber,
            }));
          }}
          isLoading={isLoading.tableLoading}
          pageSize={approvalSummaryData.pageSize}
        />
      </Grid>
      {emptyRequestHandler()}
      {menuData.anchorEl && menuData.row
        && (
          <Menu
            id="menu-popover"
            open={!!menuData.anchorEl}
            anchorEl={menuData.anchorEl}
            onClose={() => setMenuData({
              anchorEl: null,
              row: {
                rowId: null,
                stageId: '',
                requestType: '',
                linkedDelegationId: null,
                delegateToSecondary: null,
                request_purpose: null,
              },
            })}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            classes={{ root: styleClasses.moreActionMenu }}
            disableAutoFocusItem
          >
            {
              menuData.row.linkedDelegationId && (
                <MenuItem
                  onClick={() =>
                    openLinkedDelegation(menuData.row.linkedDelegationId)}
                  className={styleClasses.moreActionMenuItem}
                >
                  <VisibilityIcon />
                  <span className={styleClasses.menuLabel}>
                    View Delegation
                  </span>
                </MenuItem>
              )}
          </Menu>)
      }
    </>
  );
}
export default ApprovalSummaryPanel;
