import React, { useEffect, useState } from 'react';
import Grid from "@mui/material/Grid";
import FormikControl from "../../../formik-conrtols/FormikControl";
import FieldLabel from "../../../field-label";
import { formikContainerStyles, parImplementationStyles } from "../../useStyles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelperTooltip from "../../../helper-tooltip";
import { HelpRounded } from "@mui/icons-material";
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../appStore/store';
import { autoGenerated, enterDisplayName, proposedDisplayName, proposedUserName } from '../tooltip';
import CustomModal from '../../../custom-modal';
import CustomButton from '../../../button';
import { isEmpty } from 'lodash';

const createRequestOptions = [
  {
    label: "Connect to / manage a server or computer",
    additionalValue: "1",
    value: "Connect to / manage a server or computer",
  },
  {
    label: "Automate actions via application",
    additionalValue: "1",
    value: "Automate actions via application",
  },
  {
    label: "Send and receive an email via application",
    additionalValue: "1",
    value: "Send and receive an email via application",
  },
  {
    label: "Automate the sending of email notifications",
    additionalValue: "1",
    value: "Automate the sending of email notifications",
  },
  {
    label: "Test a service / product as a part of project",
    additionalValue: "2",
    value: "Test a service / product as a part of project",
  },
];
function cloudImplementation({ isDisabled }: { isDisabled: boolean }) {
  const classes = parImplementationStyles();
  const [expanded, setExpanded] = useState<string | false>(false);
  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    if (!formikCtx.values.is_step_one_completed_cloud) {
      setModalOpen(true);
    }
    setExpanded(isExpanded ? panel : false);
  };
  const styleClasses = formikContainerStyles();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const formikCtx: any = useFormikContext();
  const { cloudImplementationList } = useSelector((state: RootState) => state);

  const userLogonname = () => {
    const fullName = `${formikCtx.values.par_first_name}.${formikCtx.values.par_last_name}-s`
    let firstName = formikCtx.values.par_first_name
    if (fullName.length >= 17) {
      const lengthDiff = fullName.length - 17;
      firstName = firstName.substring(0, formikCtx.values.par_first_name.length - lengthDiff);
    }
    if (formikCtx.values.del_request_id !== null) {

      const temp = `${firstName}.${formikCtx.values.par_last_name}-s@${formikCtx.values.par_domain}`
      return temp
    } else {
      return `t-${firstName}.${formikCtx.values.par_last_name}@${formikCtx.values.par_domain}`
    }
  }

  const enterDisplayNameHandler = () => {
    if (formikCtx.values.request_purpose === 'Connect to / manage a server or computer') {
      return '(Admin)';
    } else if (formikCtx.values.request_purpose === ('Automate actions via application' || 'Send and receive an email via application' || 'Automate the sending of email notifications')) {
      return '(Service Account)';
    } else if (formikCtx.values.request_purpose === 'Test a service / product as a part of project') {
      return '(Test)'
    }
  }
  const handleClose = () => {
    setExpanded('panel1');
    setModalOpen(false);
  };
  useEffect(() => {
    if (!isEmpty(cloudImplementationList[0])) {
      formikCtx.setFieldValue('review_account_requirements', cloudImplementationList[0].review_account_requirements)
      formikCtx.setFieldValue('is_step_one_completed_cloud', cloudImplementationList[0].is_step_one_completed)
      formikCtx.setFieldValue('is_user_logon_name_entered', cloudImplementationList[0].is_user_logon_name_entered)
      formikCtx.setFieldValue('enter_proposed_user_name', userLogonname())
      formikCtx.setFieldValue('is_proposed_user_name_entered', cloudImplementationList[0].is_proposed_user_name_entered)
      formikCtx.setFieldValue('enter_display_name', `${formikCtx.values.par_first_name} ${formikCtx.values.par_last_name}${enterDisplayNameHandler()}`)
      formikCtx.setFieldValue('is_display_name_entered', cloudImplementationList[0].is_display_name_entered)
      formikCtx.setFieldValue('enter_first_name_and_last_name', cloudImplementationList[0].enter_first_name_and_last_name)
      formikCtx.setFieldValue('copy_password_for_end_user', cloudImplementationList[0].copy_password_for_end_user)
      formikCtx.setFieldValue('add_usage_location', cloudImplementationList[0].add_usage_location)
      formikCtx.setFieldValue('entered_job_info_section', cloudImplementationList[0].entered_job_info_section)
      formikCtx.setFieldValue('user_created_or_exist', cloudImplementationList[0].user_created_or_exist)
      formikCtx.setFieldValue('is_user_added', cloudImplementationList[0].is_user_added)
      formikCtx.setFieldValue('assign_license', cloudImplementationList[0].assign_license)
      formikCtx.setFieldValue('assign_admin_role_to_account', cloudImplementationList[0].assign_admin_role_to_account)
      formikCtx.setFieldValue('enroll_pim', cloudImplementationList[0].enroll_pim)
      formikCtx.setFieldValue('shared_account_details_groupm', cloudImplementationList[0].shared_account_details_groupm)
      formikCtx.setFieldValue('is_step_two_completed_cloud', cloudImplementationList[0].is_step_two_completed_cloud)
    }
    formikCtx.setFieldValue('enter_proposed_user_name', userLogonname())
    formikCtx.setFieldValue('enter_user_logon_name', formikCtx.values.sar_user_logon_name_upn);
    formikCtx.setFieldValue('enter_display_name', `${formikCtx.values.par_first_name} ${formikCtx.values.par_last_name}${enterDisplayNameHandler()}`)
    setExpanded('panel1');
  }, []);

  return (
    <>
      <Grid container className={classes.gridContainer}>
        <Grid item xs={5}>
          <FieldLabel Title="Implementation task list for cloud account set up" />
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: "5px" }}>
          <Accordion
            expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className={classes.gridTitle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.accordionSummary}
            >
              <Typography className={classes.accTitle}>
                Review account requirements
                <span className={classes.astrik}>*</span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <Typography>
                <div>
                  <Grid item xs={12}>
                    <Typography className={classes.title}>
                      <div>
                        If you have reviewed from Step 1 to Step 4, then you may
                        add review comments below and mark this step complete.
                      </div>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormikControl
                      control="textfield"
                      name="review_account_requirements"
                      label="Enter comments"
                      multiline
                      minRows={4}
                      disabled={formikCtx.values.is_step_one_completed_cloud ? true : false}
                    />
                  </Grid>
                </div>

                <div className={classes.markStep}>
                  <Grid item xs={12}>
                    <FormikControl
                      control="checkbox"
                      label="Mark this step complete"
                      name="is_step_one_completed_cloud"
                      required
                      className={classes.checkboxLabel}
                    />
                  </Grid>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          <Accordion expanded={formikCtx.values.is_step_one_completed_cloud ? expanded === 'panel2' : false} onChange={handleChange(formikCtx.values.is_step_one_completed_cloud ? 'panel2' : '')} className={classes.gridTitle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
              className={classes.accordionSummary}
            >
              <Typography className={classes.accTitle}>
                Login to Azure portal
                <span className={classes.astrik}>*</span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>

              {
                <>

                  {(formikCtx.values.par_domain === 'Hybrid (AD/Azure)') &&
                    <>
                      <Grid item xs={12}>
                        <Typography className={classes.titleQue}>
                          <div>Find user</div>
                          <span className={classes.astrik}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <div className={classes.markStepQue}>
                          <FormikControl
                            control="textfield"
                            label=""
                            name="enter_user_logon_name"
                            required
                            className={classes.checkboxLabelQue}
                            disabled={formikCtx.values.is_user_logon_name_entered === true ? true : false}
                          />
                        </div>
                      </Grid>
                      <div className={classes.markStepQue}>
                        <FormikControl
                          control="checkbox"
                          label="Check if completed"
                          name="is_user_logon_name_entered"
                          required
                          className={classes.checkboxLabelQue}
                        />
                      </div>
                    </>
                  }
                  {(formikCtx.values.par_domain !== 'Hybrid (AD/Azure)') &&
                    <>
                      <Grid item xs={12}>
                        <Typography className={classes.titleQue}>
                          <div>Select new user</div>
                          <span className={classes.astrik}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className={classes.titleQue}>
                          <div>Enter the <strong>Proposed User Name</strong> below into the <strong>User Name</strong> attribute</div>
                          <span className={classes.astrik}>*</span>
                          <HelperTooltip tooltipMsg="eg. Qasim.saeed">
                            <HelpRounded />
                          </HelperTooltip>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>

                        <Typography className={classes.titleQue}>
                          <div>Proposed <strong> User Name</strong> </div>
                          <span className={classes.astrik}>*</span>
                          <HelperTooltip tooltipMsg={proposedUserName}>
                            <HelpRounded />
                          </HelperTooltip>
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <div className={classes.markStepQue}>
                          <FormikControl
                            control="textfield"
                            label="Enter display name"
                            name="enter_proposed_user_name"
                            required
                            className={classes.checkboxLabelQue}
                            disabled={formikCtx.values.is_proposed_user_name_entered === true ? true : false}
                          />
                        </div>
                      </Grid>
                      <div className={classes.markStepQue}>
                        <FormikControl
                          control="checkbox"
                          label="Check if completed"
                          name="is_proposed_user_name_entered"
                          required
                          className={classes.checkboxLabelQue}
                        />
                      </div>
                    </>}
                </>
              }

              {(formikCtx.values.par_domain !== 'Hybrid (AD/Azure)') && (formikCtx.values.enter_proposed_user_name !== '' || formikCtx.values.is_proposed_user_name_entered === true) &&
                <>
                  <Grid item xs={12}>
                    <Typography className={classes.titleQue}>
                      <div>
                        Enter display name in <strong>Name</strong> field and include appropriate account type in brackets, i.e. Admin, Service, Security Admin, Test, Global Admin or company name for a Third Party supplier <span className={classes.astrik}>*</span>
                      </div>

                      <HelperTooltip tooltipMsg={enterDisplayName}>
                        <HelpRounded />
                      </HelperTooltip>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.titleQue}>
                      <div>Proposed display <strong> Name</strong> </div>
                      <span className={classes.astrik}>*</span>
                      <HelperTooltip tooltipMsg={proposedDisplayName}>
                        <HelpRounded />
                      </HelperTooltip>
                    </Typography>

                  </Grid>
                  <Grid item xs={5}>
                    <div className={classes.markStepQue}>
                      <FormikControl
                        control="textfield"
                        label="Enter proposed user name"
                        name="enter_display_name"
                        required
                        className={classes.checkboxLabelQue}
                        disabled={formikCtx.values.is_display_name_entered === true ? true : false}
                      />
                    </div>
                  </Grid>
                  <div className={classes.markStepQue}>
                    <FormikControl
                      control="checkbox"
                      label="Check if completed"
                      name="is_display_name_entered"
                      required
                      className={classes.checkboxLabelQue}
                    />
                  </div>
                </>}

              {(formikCtx.values.par_domain !== 'Hybrid (AD/Azure)') && (formikCtx.values.enter_display_name !== '' || formikCtx.values.is_display_name_entered === true) &&
                <>
                  <Grid item xs={12}>
                    <Typography className={classes.titleQue}>
                      <div>
                        Enter <strong>First name</strong> and <strong>Last name</strong> into appropriate fields
                      </div>
                      <span className={classes.astrik}>*</span>
                      {/* <HelperTooltip tooltipMsg="eg. Qasim.saeed">
                        <HelpRounded />
                      </HelperTooltip> */}
                    </Typography>
                  </Grid>
                  <div className={classes.markStepQue}>
                    <FormikControl
                      control="checkbox"
                      label="Check if completed"
                      name="enter_first_name_and_last_name"
                      required
                      className={classes.checkboxLabelQue}
                    />
                  </div>
                </>
              }

              {(formikCtx.values.par_domain !== 'Hybrid (AD/Azure)') && (formikCtx.values.enter_first_name_and_last_name === true) &&
                <>
                  <Grid item xs={12}>
                    <Typography className={classes.title}>
                      <div>
                        Select <strong>auto- generate password</strong> radio
                        button. Copy the <strong>initial password</strong> and store securely for
                        sending to end user
                      </div>
                      <span className={classes.astrik}>*</span>
                      <HelperTooltip tooltipMsg={autoGenerated}>
                        <HelpRounded />
                      </HelperTooltip>
                    </Typography>
                    <div className={classes.markStepQue}>
                      <FormikControl
                        control="checkbox"
                        label="Check if completed"
                        name="copy_password_for_end_user"
                        required
                        className={classes.checkboxLabelQue}
                      />
                    </div>
                  </Grid>
                </>
              }

              {(formikCtx.values.par_domain !== 'Hybrid (AD/Azure)') && (formikCtx.values.copy_password_for_end_user === true) &&
                <Grid item xs={12}>
                  <Typography className={classes.titleQue}>
                    <div>
                      In Setting section select <strong>Usage location </strong>
                      from the drop down
                    </div>
                    <span className={classes.astrik}>*</span>
                  </Typography>
                  <div className={classes.markStepQue}>
                    <FormikControl
                      control="checkbox"
                      label="Check if completed"
                      name="add_usage_location"
                      required
                      className={classes.checkboxLabelQue}
                    />
                  </div>
                </Grid>}

              {(formikCtx.values.add_usage_location === true) &&
                <Grid item xs={12}>
                  <Typography className={classes.titleQue}>
                    <div>
                      In <strong>Job info</strong> section enter
                      <strong> Job title, Department </strong>
                      as mentioned in the PAR form
                    </div>
                    <span className={classes.astrik}>*</span>
                  </Typography>
                  <div className={classes.markStepQue}>
                    <FormikControl
                      control="radio"
                      name="entered_job_info_section"
                      radioGrouplabels={[
                        {
                          label: "Completed",
                          value: "Completed",
                        },
                        {
                          label: "Not Required",
                          value: "Not Required",
                        },
                      ]}
                      isInline
                    />
                  </div>
                </Grid>
              }
              {(formikCtx.values.par_domain !== 'Hybrid (AD/Azure)') && ((formikCtx.values.entered_job_info_section === 'Completed') || (0)) &&
                <>
                  <Grid item xs={12}>
                    <Typography className={classes.title}>
                      <div>
                        Enter <strong>Company name</strong> and
                        <strong> Manager</strong> details
                      </div>
                      <span className={classes.astrik}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.title}>
                      <div>
                        Press <strong>Create</strong> it will either say
                        <strong> User created</strong> or
                        <strong> User already exists</strong>
                      </div>
                      <span className={classes.astrik}>*</span>
                    </Typography>
                    <div className={classes.markStepQue}>
                      <FormikControl
                        control="checkbox"
                        label="Check if completed"
                        name="user_created_or_exist"
                        required
                        className={classes.checkboxLabelQue}
                      />
                    </div>
                  </Grid>
                </>
              }

              {(formikCtx.values.par_domain !== 'Hybrid (AD/Azure)') && (formikCtx.values.user_created_or_exist === true) &&
                <Grid item xs={12}>
                  <Typography className={classes.titleQue}>
                    <div>Have you added the user?</div>
                    <span className={classes.astrik}>*</span>
                  </Typography>
                  <div className={classes.markStepQue}>
                    <FormikControl
                      control="radio"
                      name="is_user_added"
                      radioGrouplabels={[
                        {
                          label: "Yes, added",
                          value: "Yes",
                        },
                        {
                          label: "user already exists",
                          value: "No",
                        },
                      ]}
                      isInline
                    />
                  </div>
                </Grid>}
              {(formikCtx.values.par_domain === 'Hybrid (AD/Azure)') && ((formikCtx.values.enter_user_logon_name !== '') || (formikCtx.values.is_user_logon_name_entered === true)) &&
                <Grid item xs={12}>
                  <Typography className={classes.titleQue}>
                    <div>
                      Assign <strong>license</strong> (if required) Azure /
                      SharePoint / O365 access
                    </div>
                    <span className={classes.astrik}>*</span>
                  </Typography>
                  <div className={classes.markStepQue}>
                    <FormikControl
                      control="radio"
                      name="assign_license"
                      radioGrouplabels={[
                        {
                          label: "Completed",
                          value: "Completed",
                        },
                        {
                          label: "Not Required",
                          value: "Not Required",
                        },
                      ]}
                      isInline
                    />
                  </div>
                </Grid>}
              {(formikCtx.values.par_domain === 'Hybrid (AD/Azure)') ? ((formikCtx.values.assign_license === "Completed") || (formikCtx.values.assign_license === "Not Required")) &&
                <Grid item xs={12}>
                  <Typography className={classes.titleQue}>
                    <div>
                      Assign <strong>admin role</strong> to account
                    </div>
                    <span className={classes.astrik}>*</span>
                  </Typography>
                  <div className={classes.markStepQue}>
                    <FormikControl
                      control="checkbox"
                      label="Check if completed"
                      name="assign_admin_role_to_account"
                      required
                      className={classes.checkboxLabelQue}
                    />
                  </div>
                </Grid> : (formikCtx.values.is_user_added === 'Yes' || 'No') &&
              <Grid item xs={12}>
                <Typography className={classes.titleQue}>
                  <div>
                    Assign <strong>admin role</strong> to account
                  </div>
                  <span className={classes.astrik}>*</span>
                </Typography>
                <div className={classes.markStepQue}>
                  <FormikControl
                    control="checkbox"
                    label="Check if completed"
                    name="assign_admin_role_to_account"
                    required
                    className={classes.checkboxLabelQue}
                  />
                </div>
              </Grid>
              }
              {(formikCtx.values.assign_admin_role_to_account === true) &&
                <Grid item xs={12}>
                  <Typography className={classes.title}>
                    <div>
                      Enroll account into <strong>PIM</strong> (Privileged
                      Identity Management)
                    </div>
                    <span className={classes.astrik}>*</span>
                  </Typography>
                  <div className={classes.markStepQue}>
                    <FormikControl
                      control="checkbox"
                      label="Check if completed"
                      name="enroll_pim"
                      required
                      className={classes.checkboxLabelQue}
                    />
                  </div>
                </Grid>
              }
              {(formikCtx.values.enroll_pim === true) &&
                <Grid item xs={12}>
                  <Typography className={classes.title}>
                    <div>
                      Have you <strong>shared account details</strong> with GroupM
                      owner?
                    </div>
                    <span className={classes.astrik}>*</span>
                  </Typography>
                  <div className={classes.markStepQue}>
                    <FormikControl
                      control="radio"
                      name="shared_account_details_groupm"
                      radioGrouplabels={[
                        {
                          label: "Yes",
                          value: "Yes",
                        },
                        {
                          label: "No",
                          value: "No",
                        },
                      ]}
                      isInline
                    />
                  </div>
                </Grid>
              }

              {((formikCtx.values.shared_account_details_groupm === 'Yes') || (formikCtx.values.shared_account_details_groupm === 'No')) &&
                <div className={classes.markStep}>
                  <Grid item xs={12}>
                    <FormikControl
                      control="checkbox"
                      label="Mark this step complete"
                      name="is_step_two_completed_cloud"
                      required
                      className={classes.checkboxLabel}
                    />
                  </Grid>
                </div>
              }
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      <CustomModal
        modalHeader='Note'
        open={modalOpen}
        onClose={handleClose}
        modalAction={
          <CustomButton
            variant="contained"
            actionType="primary"
            disableMargin
            onClick={handleClose}
          >
            Ok
          </CustomButton>
        }
        modalContent={
          <div>
            <div className={styleClasses.modalTextDiv}>
              This section cannot be completed until the Review and understand PAR requirements section is completed
            </div>
          </div>
        }
      />
    </>
  );
}

export default cloudImplementation;
