import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { debounce, isEmpty } from 'lodash';
import Grid from '@mui/material/Grid';
import FormikControl from '../../../formik-conrtols/FormikControl';
import FieldLabel from '../../../field-label';
import { format, isDate } from 'date-fns';
import { RootState } from '../../../../appStore/store';
import {
    getAllCountries,
    searchUserByQuery,
    getAllCitiesByCountryId,
    searchUserByQueryDetails,
} from '../../../../api-services/common-api';
import HelperTooltip from '../../../helper-tooltip';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import ReviewCommentsContainer from '../../../../modules/par/comments/commentsContainer';
import { parStyles, sarUseStyles } from '../../useStyles';
import { teamDetails, pointOfEscalationToolTip } from '../tooltips';
import HistoryTable from '../../../../modules/sar/comments/historyTable';
import CommentSubmission from '../../../../modules/sar/comments/commentSubmission';
import { routeCheck, routeCheckForAdminSummary, routeCheckForApprovalSummary, routeCheckForImplementationSummary, routeCheckForPendingApproval } from './routingConditional';
import { getAllOffices, getThirdPartyCompany } from '../../../../api-services/par';

function RequestorAndTeamDetails({ isDisabled }: { isDisabled: boolean }) {
    const classes = sarUseStyles();
    const formikCtx: any = useFormikContext();
    const [openState, setOpenState] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    // const { countryData, companydata, thirdPartyCompanyName, officeData } = useSelector((state: RootState) => state.loginData);
    const formatDateInside = (dateValue: any) => (dateValue && isDate(new Date(dateValue)) ? format(new Date(dateValue), 'MMM dd, yyyy') : '');
    const [countryList, setCountryList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [officeList, setOfficeList] = useState([]);
    const [thirdPartyCompany, setThirdPartyCompany] = useState([]);
    const [accountOwnerToSearch, setaccountOwnerToSearch] = useState('');
    const [anchorEl, setAnchorEl] = useState<SVGElement | HTMLDivElement | null>(
        null,
    );
    const [fieldNameObject, setFieldNameObject] = useState('')
    const [accountOwnerList, setaccountOwnerList] = useState<{
        first_name: string,
        last_name: string,
        email: string,
        country: string,
        city: string,
        phone: string | number,
        department: string,
        company: string,
        title: string,
    }[]>([]);
    const isAutoCompleteSelected = useRef(false);
    const styleClasses = parStyles();
    const remediated_by = formikCtx.values.remediation_by
    const remediationCount = formikCtx.values.remediation_count;
    const { fieldHistorySarCommentsData } = useSelector((state: RootState) => state);
    const { submitSarCommentData } = useSelector((state: RootState) => state);

    const searchUserByFirstName = (value: string) => {
        if (value && value !== '') {
            searchUserByQueryDetails(value, '').then((res) => {
                setOpenState(true)
                setaccountOwnerList([...res]);
            }).catch((err: any) => {
                //console.log(err);
                setaccountOwnerList([]);
            }).finally(() => {
                setSearchLoading(false);
            });
        }
    };

    const debouncedSearch = useCallback(
        debounce((inputValue) => {
            searchUserByFirstName(inputValue);
        }, 1500),
        [],
    );

    useEffect(() => {
        debouncedSearch(accountOwnerToSearch);
    }, [accountOwnerToSearch])

    useEffect(() => {
        if (formikCtx.values.country !== '' && countryList.length) {
            const selectedCountry: any = countryList
                .find((countryObj: any) => countryObj.country === formikCtx.values.country);
            getAllCitiesByCountryId(selectedCountry?.id).then((res) => {
                setCityList(res.data);
            }).catch((err) => {
                //console.log(err);
            })
        }
    }, [formikCtx.values.country])

    useEffect(() => {
        getAllCountries().then((res) => {
            setCountryList(res.data);
        }).catch((err) => {
            // console.log(err);
        });
        getThirdPartyCompany().then((res) => {
            setThirdPartyCompany(res.data);
        }).catch((err) => {
            // console.log(err);
        }),
            getAllOffices().then((res) => {
                setOfficeList(res.data);
                //console.log(res, 'offices');
            }).catch((err) => {
                console.log(err);
            })
    }, [])
    const open = !!anchorEl;
    const handleClose = () => {
        setAnchorEl(null);
    }

    useEffect(() => {
        if (formikCtx.values.oktahomeorg && formikCtx.values.oktaCompany) {
            if (formikCtx.values.dataverse[0].okta_data.value[0].new_homeorg == 'ssow008') {
                formikCtx.setFieldValue('company', '3rd Party');
                formikCtx.setFieldValue('sar_third_party_company', formikCtx.values.dataverse[0].okta_data.value[0].new_company)
            } else {
                formikCtx.setFieldValue('company', formikCtx.values.dataverse[0].okta_data.value[0].new_company);
            }
        } else {
            formikCtx.setFieldValue('company', formikCtx.values.dataverse[1].db_data.company !== '' && formikCtx.values.dataverse[1].db_data.company);
            formikCtx.setFieldValue('sar_third_party_company', formikCtx.values.dataverse[1].db_data.third_party_company !== '' && formikCtx.values.dataverse[1].db_data.third_party_company);
            formikCtx.setFieldValue('sar_not_listed_third_party_company', formikCtx.values.dataverse[1].db_data.not_listed_third_party_company !== '' && formikCtx.values.dataverse[1].db_data.not_listed_third_party_company);
        }
    }, [formikCtx.values])

    const IsCompanyThirdPartyAndNotlisted = () => {
        return (
            <>
                <Grid item sm={3}>
                    <FormikControl
                        control="autocomplete"
                        label="3rd party company"
                        name="sar_third_party_company"
                        options={
                            thirdPartyCompany?.map((companyObj: any) => ({
                                label: companyObj.third_party_company_name,
                                value: companyObj.third_party_company_name,
                            }))
                        }
                        disabled={true}
                    />
                </Grid>
                <Grid item sm={6}>
                </Grid>

                <Grid item sm={3}>
                    <FormikControl
                        control="textfield"
                        label="Company name"
                        name="sar_not_listed_third_party_company"
                        disabled={true}
                    />
                </Grid>
                <Grid item sm={3} >
                    <FormikControl
                        control="autocomplete"
                        label="Office"
                        name="sar_office"
                        options={
                            officeList?.map((officeObj: any) => ({
                                label: officeObj.office,
                                value: officeObj.office,
                            }))
                        }
                        disabled={isDisabled}
                    />
                </Grid>
                <Grid item sm={1} className={classes.toolTipPosition} >
                    <span className={classes.tooltipSpan}>
                        <HelperTooltip tooltipMsg='If you are from a non-WPP <br /> company, please select 3rd Party'>
                            <HelpRoundedIcon style={{ color: '#0A2756' }} />
                        </HelperTooltip>
                    </span>
                </Grid>
                <Grid item sm={5}>
                </Grid>
                <Grid item sm={3}>
                    <FormikControl
                        control="textfield"
                        label=""
                        name="sar_department"
                        disabled={formikCtx.values.oktaDepartment ? true : isDisabled}
                    />
                </Grid>
                <Grid item sm={3}>
                    <FormikControl
                        control="autocomplete"
                        label="Country"
                        name="country"
                        options={countryList?.map((countryObj: any) => ({
                            label: countryObj.country,
                            value: countryObj.country,
                        }))}
                        disabled={formikCtx.values.oktaCountry ? true : isDisabled}
                    />
                </Grid>
                <Grid item sm={6}>
                </Grid>
                <Grid item sm={3}>
                    <FormikControl
                        control="autocomplete"
                        label="City"
                        name="city"
                        options={cityList?.map((city: any) => ({ label: city.city, value: city.city }))}
                        disabled={formikCtx.values.oktaCity ? true : isDisabled}
                    />
                </Grid>
            </>
        )
    }

    const IsNormalCompany = () => {
        return (
            <>
                <Grid item sm={3} >
                    <FormikControl
                        control="autocomplete"
                        label="Office"
                        name="sar_office"
                        options={
                            officeList?.map((officeObj: any) => ({
                                label: officeObj.office,
                                value: officeObj.office,
                            }))
                        }
                        disabled={isDisabled}
                    />
                </Grid>
                <Grid item sm={1} className={classes.toolTipPosition} >
                    <span className={classes.tooltipSpan}>
                        <HelperTooltip tooltipMsg='If you are from a non-WPP <br /> company, please select 3rd Party'>
                            <HelpRoundedIcon style={{ color: '#0A2756' }} />
                        </HelperTooltip>
                    </span>
                </Grid>
                <Grid item sm={5}>
                </Grid>
                <Grid item sm={3}>
                    <FormikControl
                        control="textfield"
                        label="Department"
                        name="sar_department"
                        disabled={formikCtx.values.oktaDepartment ? true : isDisabled}
                    />
                </Grid>
                <Grid item sm={3}>
                    <FormikControl
                        control="autocomplete"
                        label="Country"
                        name="country"
                        options={countryList?.map((countryObj: any) => ({
                            label: countryObj.country,
                            value: countryObj.country,
                        }))}
                        disabled={formikCtx.values.oktaCountry ? true : isDisabled}
                    />
                </Grid>
                <Grid item sm={5}>
                </Grid>
                <Grid item sm={3}>
                    <FormikControl
                        control="autocomplete"
                        label="City"
                        name="city"
                        options={cityList?.map((city: any) => ({ label: city.city, value: city.city }))}
                        disabled={formikCtx.values.oktaCity ? true : isDisabled}
                    />
                </Grid>
            </>
        )
    }
    const IsCompanyThirdPartyAndListed = () => {
        return (
            <>
                <Grid item sm={3}>
                    <FormikControl
                        control="autocomplete"
                        label="3rd party company"
                        name="sar_third_party_company"
                        options={
                            thirdPartyCompany?.map((companyObj: any) => ({
                                label: companyObj.third_party_company_name,
                                value: companyObj.third_party_company_name,
                            }))
                        }
                        disabled={true}
                    />
                </Grid>
                <Grid item sm={6}>
                </Grid>
                <Grid item sm={3}>
                    <FormikControl
                        control="textfield"
                        label="Department"
                        name="sar_department"
                        disabled={formikCtx.values.oktaDepartment ? true : isDisabled}
                    />
                </Grid>
                <Grid item sm={3} >
                    <FormikControl
                        control="autocomplete"
                        label="Office"
                        name="sar_office"
                        options={
                            officeList?.map((officeObj: any) => ({
                                label: officeObj.office,
                                value: officeObj.office,
                            }))
                        }
                        disabled={isDisabled}
                    />
                </Grid>
                <Grid item sm={1} className={classes.toolTipPosition} >
                    <span className={classes.tooltipSpan}>
                        <HelperTooltip tooltipMsg='If you are from a non-WPP <br /> company, please select 3rd Party'>
                            <HelpRoundedIcon style={{ color: '#0A2756' }} />
                        </HelperTooltip>
                    </span>
                </Grid>
                <Grid item sm={5}>
                </Grid>

                <Grid item sm={3}>
                    <FormikControl
                        control="autocomplete"
                        label="Country"
                        name="country"
                        options={countryList?.map((countryObj: any) => ({
                            label: countryObj.country,
                            value: countryObj.country,
                        }))}
                        disabled={formikCtx.values.oktaCountry ? true : isDisabled}
                    />
                </Grid>
                {/* <Grid item sm={5}>
                </Grid> */}
                <Grid item sm={3}>
                    <FormikControl
                        control="autocomplete"
                        label="City"
                        name="city"
                        options={cityList?.map((city: any) => ({ label: city.city, value: city.city }))}
                        disabled={formikCtx.values.oktaCity ? true : isDisabled}
                    />
                </Grid>
            </>
        )
    }
    const managerThirdFieldHandler = () => {
        return (
            <>
                <Grid item xs={3}>
                    <FormikControl
                        control="textfield"
                        label="3rd Party manager first name"
                        name="sar_third_party_manager_first_name"
                        disabled={isDisabled}
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormikControl
                        control="textfield"
                        label="3rd Party manager last name"
                        name="sar_third_party_manager_last_name"
                        disabled={isDisabled}
                    />
                </Grid>
                <Grid item xs={6}>

                </Grid>
                <Grid item xs={3}>
                    <FormikControl
                        control="textfield"
                        label="3rd Party manager email"
                        name="sar_third_party_manager_email"
                        disabled={isDisabled}
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormikControl
                        control="phone"
                        label="3rd Party manager phone"
                        name="sar_third_party_manager_phone"
                        defaultCountry="us"
                        disabled={isDisabled}
                    />
                </Grid>
                <Grid item xs={6}>

                </Grid>
                <Grid item sm={3}>
                    <FormikControl
                        control="textfield"
                        label="Point of escalation"
                        name="sar_point_of_escalation"
                        disabled={isDisabled}
                    />
                </Grid>
                <Grid item xs={1} className={classes.toolTipPosition} >
                    <span className={classes.tooltipSpan}>
                        <HelperTooltip tooltipMsg={pointOfEscalationToolTip}>
                            <HelpRoundedIcon style={{ color: '#0A2756' }} />
                        </HelperTooltip>
                    </span>
                </Grid>
                <Grid item xs={6}>

                </Grid>
            </>
        )

    }

    const managerFieldHandler = () => {
        return (
            <>
                <Grid item xs={3}>
                    <FormikControl
                        control="textfield"
                        label="Manager first name"
                        name="manager_first_name"
                        disabled={isDisabled}
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormikControl
                        control="textfield"
                        label="Manager last name"
                        name="manager_last_name"
                        disabled={isDisabled}
                    />
                </Grid>
                <Grid item xs={6}>

                </Grid>
                <Grid item xs={3}>
                    <FormikControl
                        control="textfield"
                        label="Manager email"
                        name="manager_email"
                        disabled={isDisabled}
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormikControl
                        control="phone"
                        label="Manager phone"
                        name="manager_phone"
                        defaultCountry="us"
                        disabled={isDisabled}
                    />
                </Grid>
                <Grid item xs={6}>

                </Grid>
                <Grid item sm={3}>
                    <FormikControl
                        control="textfield"
                        label="Point of escalation"
                        name="sar_point_of_escalation"
                        disabled={isDisabled}
                    />
                </Grid>
                <Grid item xs={1} className={classes.toolTipPosition} >
                    <span className={classes.tooltipSpan}>
                        <HelperTooltip tooltipMsg={pointOfEscalationToolTip}>
                            <HelpRoundedIcon style={{ color: '#0A2756' }} />
                        </HelperTooltip>
                    </span>
                </Grid>
                <Grid item xs={6}>

                </Grid>

            </>
        )
    }


    const checkForEditReviewFlow = () => {
        return routeCheck() || routeCheckForAdminSummary() || routeCheckForImplementationSummary() || routeCheckForApprovalSummary()
    }
    return (
        <>
            <Grid container >
                <Grid item xs={10}>
                    <>
                        <FieldLabel Title="Requestor details" required />
                    </>
                    <>
                        <div className={styleClasses.updatedByStyle}>
                            {/* @ts-ignore */}
                            {(fieldHistorySarCommentsData[2].Requestor_details?.length > 0) && `Updated on ${formatDateInside(fieldHistorySarCommentsData[2].Requestor_details[fieldHistorySarCommentsData[2].Requestor_details?.length - 1].last_updated)}`}
                        </div>
                    </>
                </Grid>
                <Grid item xs={2}>
                    {(checkForEditReviewFlow() && remediationCount > 0) ? (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="Requestor_details"
                                subSection=''
                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[2].Requestor_details : null}
                            />
                            <CommentSubmission
                                sectionName='Requestor_details'
                                subSection=''
                                updateType='Select update'
                                remediation_by={remediated_by}
                                data={submitSarCommentData.length > 0 ? submitSarCommentData[2].Requestor_details : null}
                            />
                        </div>
                    ) : routeCheckForPendingApproval() && (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="Requestor_details"
                                subSection=''
                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[2].Requestor_details : null}
                            />
                            <CommentSubmission
                                sectionName='Requestor_details'
                                subSection=''
                                updateType='Select update'
                                remediation_by={remediated_by}
                                data={submitSarCommentData.length > 0 ? submitSarCommentData[2].Requestor_details : null}
                            />
                        </div>
                    )}
                </Grid>
            </Grid>
            <Grid container rowSpacing={3} columnSpacing={10}>
                <Grid item xs={3}>
                    <FormikControl
                        control="textfield"
                        label="First name"
                        name="first_name"
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormikControl
                        control="textfield"
                        label="Last name"
                        name="last_name"
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={6}>

                </Grid>
                <Grid item xs={3}>
                    <FormikControl
                        control="textfield"
                        label="Email address"
                        name="email"
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormikControl
                        control="phone"
                        label="Phone"
                        name="phone"
                        defaultCountry="us"
                        disabled={formikCtx.values.oktaPhone ? true : isDisabled}
                    />
                </Grid>
                <Grid item xs={6}>
                </Grid>
                <Grid item sm={3}>
                    <FormikControl
                        control="textfield"
                        label="Company"
                        name="company"
                        disabled={true}
                    // disabled={formikCtx.values.oktaCompany ? true : isDisabled}
                    />
                </Grid>
                {(formikCtx.values.company == ('3rd Party' || 'Third Party') && formikCtx.values.sar_third_party_company == 'Not listed') ?
                    IsCompanyThirdPartyAndNotlisted() : (formikCtx.values.company == ('3rd Party' || 'Third Party')) ? IsCompanyThirdPartyAndListed() : IsNormalCompany()
                }
                <Grid item xs={5}></Grid>
            </Grid>

            <Grid container paddingTop={'12px'} >
                <Grid item xs={10}>
                    <div>
                        <FieldLabel Title="Team details" tooltipMsg={teamDetails} required />
                    </div>
                    <div>
                        <div className={styleClasses.updatedByStyle}>
                            {/* @ts-ignore */}
                            {(fieldHistorySarCommentsData[3].Team_details?.length > 0) && `Updated on ${formatDateInside(fieldHistorySarCommentsData[3].Team_details[fieldHistorySarCommentsData[3].Team_details?.length - 1].last_updated)}`}
                        </div>
                    </div>
                </Grid>
                <Grid item xs={2}>
                    {(checkForEditReviewFlow() && remediationCount > 0) ? (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="Team_details"
                                subSection=''
                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[3].Team_details : null}
                            />
                            <CommentSubmission
                                sectionName='Team_details'
                                subSection=''
                                updateType='Select update'
                                remediation_by={remediated_by}
                                data={submitSarCommentData.length > 0 ? submitSarCommentData[3].Team_details : null}
                            />
                        </div>
                    ) : routeCheckForPendingApproval() && (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="Team_details"
                                subSection=''
                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[3].Team_details : null}
                            />
                            <CommentSubmission
                                sectionName='Team_details'
                                subSection=''
                                updateType='Select update'
                                remediation_by={remediated_by}
                                data={submitSarCommentData.length > 0 ? submitSarCommentData[3].Team_details : null}
                            />
                        </div>
                    )}
                </Grid>
            </Grid >
            <Grid container rowSpacing={3} columnSpacing={10}>
                <Grid item xs={3}>
                    <FormikControl
                        control="textfield"
                        label="Team name"
                        name="team_name"
                        disabled={isDisabled}
                    />
                </Grid>
                <Grid item xs={9}>
                </Grid>
                {(formikCtx.values.company == ('3rd Party' || 'Third Party')
                ) ? (
                    managerThirdFieldHandler()

                ) : (
                    managerFieldHandler()
                )
                }

            </Grid>
            <ReviewCommentsContainer
                open={open}
                anchorEl={anchorEl}
                handleClose={handleClose}
            >
                <FormikControl
                    control='reviewaction'
                    name={fieldNameObject}
                />
            </ReviewCommentsContainer>
        </>
    )
};

export default RequestorAndTeamDetails;