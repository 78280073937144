import React from 'react';
import format from 'date-fns/format/index';
import addDays from 'date-fns/addDays/index';

const delegationBody = {
  status: '1',
  request_id: null,
  event: '',
  next_priority: null,
  request_type: '',
  team_name: '',
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  company: '',
  not_listed_third_party_company: '',
  third_party_company: '',
  manager_first_name: '',
  manager_last_name: '',
  manager_phone: '',
  manager_email: '',
  city: '',
  country: '',
  access_review_period: '90',
  request_purpose: '',
  del_reason_for_raising_new_request: '',
  del_other: '',
  del_please_enter_role_name: '',
  del_job_title: '',
  del_job_explanation: '',
  del_justification_for_access: '',
  del_delegation_account_type_id: '',
  del_role_name_list: [],
  del_user_detail_list: [],
  del_custom_request: '',
  del_short_term_long_term_contract_number: '',
  del_short_term_long_term_contract_details: '',
  del_short_term_long_term_implementation_plan: '',
  del_short_term_long_term_access_request: '',
  del_start_date: format(addDays(new Date(), 7), 'yyyy-MM-dd'),
  del_end_date: format(addDays(new Date(), 7), 'yyyy-MM-dd'),
  del_date: format(new Date(), 'yyyy-MM-dd'),
  sar_function_of_sar: '',
  sar_justification_of_sar: '',
  sar_city_code: '',
  sar_company_code: '',
  sar_application_name: '',
  sar_user_logon_name_upn: '',
  sar_pre_windows_2000_username: '',
  sar_is_complex_service_account: false,
  sar_domain_location: [],
  sar_logon_as_service: false,
  sar_logon_as_batch_job: false,
  sar_function_other: '',
  sar_justification: '',
  sar_is_sar_servers_usage: true,
  sar_servers_hostname: '',
  sar_server_tier: [],
  sar_user_rights_and_privileges: '',
  sar_group_membership: '',
  sar_external_internal_ip_range: '',
  sar_external_internal_ip_details: '',
  sar_spn_and_or_delegation: 'SPNonly',
  sar_spn_list: '',
  sar_is_any_service_kerberos_only: false,
  sar_is_specified_service_kerberos_only: false,
  sar_is_specified_service_any_protocol: false,
  sar_delegation_reason: '',
  sar_delegation_list: [],
  sar_password_change_procedure: '',
  sar_security_of_password: '',
  sar_does_require_interactive_logon: false,
  sar_justification_for_interactive_logon: '',
  sar_password_reset_timing: '60days(default policy)',
  sar_is_default_password_expiry_policy: false,
  sar_reason_for_password_expiry_policy: '',
  sar_business_impact: '',
  sar_risk_exposure: 'High',
  sar_risk_of_sar_non_password_expiring: '',
  sar_risk_if_sar_compromised: '',
  sar_department: '',
  sar_office: '',
  sar_third_party_company: '',
  sar_not_listed_third_party_company: '',
  sar_third_party_manager_first_name: '',
  sar_third_party_manager_last_name: '',
  sar_third_party_manager_email: '',
  sar_third_party_manager_phone: '',
  sar_point_of_escalation: '',
  del_is_thirdparty_request_service: 'False',
  finalCheck: false,
  mark_step_firststep: false,
  review_comments_first_step: '',
  mark_step_secondstep: false,
  sar_delegation_details: '',
  // //   for sar implementaion //
  sar_on_premise_id: '',
  is_step_one_completed: false,
  proposed_role_group_name: '',
  is_step_two_completed: false,
  proposed_delegation_role_group_name: '',
  proposed_target_group_name: '',
  proposed_gpo_name: '',
  is_delegation_gpo_target_completed: true,
  is_delegation_info_completed: false,
  complete_manage_by_section: true,
  complete_other_section: true,
  is_step_three_completed: false,
  is_service_account_require_access: 'Yes',
  review_delegation_form_to_determine_scope: 'Individual device or devices (server/s and / or computer/s)',
  is_step_four_completed: false,
  is_target_group_delegation_info_completed: true,
  is_target_group_complete_other_section: false,
  is_target_group_complete_manage_by_section: true,
  is_step_five_completed: false,
  copy_target_group_name: false,
  add_members_to_target_group: false,
  is_step_six_completed: false,
  is_select_the_delegation: false,
  is_step_seven_completed: false,
  is_step_eight_completed: false,
  is_implemented: false

};
export default delegationBody;
