import { FormikValues } from "formik";

export interface IFormikContainerProps {
  activeStep: number;
  setActiveStep: (_step: any) => void;
  requestId: string;
  data: any;
  path: any;
}

export interface FormikStepProps
  extends Pick<FormikValues, "children" | "validationSchema"> { }

export interface IModalState {
  show: boolean;
  modalHeader: string;
  modalContent: React.ReactElement | null;
  modalActions: React.ReactElement | null;
  rejectStatus?: boolean;
  remediationStatus?: boolean;
  approveStatus?: boolean;
}

export const stepsDataTestAcountApprovedPending = [
  { label: "Requestor & Owner Details" },
  { label: "Manager Details" },
  { label: "Account Justification" },
  { label: "Account Set Up-Request" },
];
export const stepsDataAdminAcountApprovedPending = [
  { label: "Requestor & Owner Details" },
  { label: "Manager Details" },
  { label: "Account Justification" },
  { label: "Account Set Up-Request" },
];
// hybrid sar
export const stepsDataServiceAcountApprovedPending1 = [
  { label: "Requestor & Owner Details" },
  { label: "Manager Details" },
  { label: "Account Justification" },
  { label: "Account Set Up-On-Premise" },
];
export const stepsDataServiceAcountApprovedPending2 = [
  { label: "Requestor & Owner Details" },
  { label: "Manager Details" },
  { label: "Account Justification" },
  { label: "Account Set Up-On-Premise" },
  { label: "Account Set Up-Request" },
];

// onmicrsoftmedia.com
export const stepsDataServiceAcountApprovedPending3 = [
  { label: "Requestor & Owner Details" },
  { label: "Manager Details" },
  { label: "Account Justification" },
  { label: "Account Set Up-Request" },
];

export const stepsData = [
  { label: "Requestor & Owner Details" },
  { label: "Manager Details" },
  { label: "Account Justification" },
];
export const stepsDataAadOnprem = [
  { label: "Requestor & Owner Details" },
  { label: "Manager Details" },
  { label: "Account Justification" },
  { label: "Account Set Up-Request" },
];
export const stepsDataAadCloud = [
  { label: "Requestor & Owner Details" },
  { label: "Manager Details" },
  { label: "Account Justification" },
  { label: "Account Set Up-Request" },
  { label: "Account Set Up- Cloud" },
];
export const stepsDataAd = [
  { label: "Requestor & Owner Details" },
  { label: "Manager Details" },
  { label: "Account Justification" },
  { label: "Account Set Up-On-Premise" },
];
export const stepsDataHybrid1 = [
  { label: "Requestor & Owner Details" },
  { label: "Manager Details" },
  { label: "Account Justification" },
  { label: "Account Set Up-On-Premise" },
];
export const stepsDataHybrid2 = [
  { label: "Requestor & Owner Details" },
  { label: "Manager Details" },
  { label: "Account Justification" },
  { label: "Account Set Up-On-Premise" },
  { label: "Account Set Up-Request" },
];
export const stepsDataHybrid3 = [
  { label: "Requestor & Owner Details" },
  { label: "Manager Details" },
  { label: "Account Justification" },
  { label: "Account Set Up-On-Premise" },
  { label: "Account Set Up-Request" },
  { label: "Account Set Up- Cloud" },
];
export const stepsDataPam = [
  { label: "Requestor & Owner Details" },
  { label: "Manager Details" },
  { label: "Account Justification" },
  // { label: "PAM Implementation" },
];



export interface IFormikContextPar {
  del_request_id: string;
  status: string;
  request_type: string;
  request_purpose: string;
  event: string;
  next_priority: null;
  request_id: string;
  par_requestor_first_name: string;
  par_requestor_last_name: string;
  par_requestor_email: string;
  par_date_raised: string;
  par_first_name: string;
  par_last_name: string;
  par_department: string;
  par_company: string;
  par_city: string;
  par_email: string;
  par_telephone: string;
  par_job_title: string;
  par_job_title_explanation: string;
  par_internal_manager_name: string;
  par_is_third_party_office: string;
  par_is_third_party_company: string;
  par_third_party_manager_first_name: string;
  par_third_party_manager_last_name: string;
  par_third_party_manager_telephone: string;
  par_third_party_manager_email: string;
  par_point_of_escalation_in_third_party: string;
  par_servicenow_ticket_number: string;
  par_domain: string;
  par_account_justification: string;
  par_account_name: string;
  par_user_logon_name: string;
  par_pre_windows_2000_username: string;
  delegation_id: null;
}

export const emptySchemaCheck = new Map([
  ['par_first_name', 'Text update'],
  ['par_last_name', 'Text update'],
  ['par_department', 'Text update'],
  ['par_company', 'Selection'],
  ['par_city', 'Selection'],
  ['par_email', 'Text update'],
  ['par_phone', 'Selection'],
  ['par_job_title', 'Text update'],
  ['par_job_title_explanation', 'Text update'],
  ['par_internal_manager_name', 'Text update'],
  ['par_is_third_party_office', 'Selection'],
  ['par_is_third_party_company', 'Selection'],
  ['par_third_party_manager_first_name', 'Text update'],
  ['par_third_party_manager_last_name', 'Text update'],
  ['par_third_party_manager_phone', 'Selection'],
  ['par_third_party_manager_email', 'Text update'],
  ['par_point_of_escalation_in_third_party', 'Text update'],
  ['par_servicenow_ticket_number', 'Text update'],
  ['par_domain', 'Text update'],
  ['par_account_justification', 'Text update'],
  ['par_account_name', 'Text update'],
  ['par_user_logon_name', 'Text update'],
  ['par_pre_windows_2000_username', 'Text update'],
  ['par_country', 'Text update'],
  ['par_not_listed_third_party_company', 'Selection'],
  ['par_internal_manager_first_name', 'Text update'],
  ['par_internal_manager_last_name', 'Text update'],
  ['par_internal_manager_email', 'Text update'],
  ['par_internal_manager_phone', 'Selection'],
])
export const fieldsOfSection = new Map([
  ['par_first_name', 'Account_owner_details'],
  ['par_last_name', 'Account_owner_details'],
  ['par_department', 'Account_owner_details'],
  ['par_company', 'Account_owner_details'],
  ['par_city', 'Account_owner_details'],
  ['par_email', 'Account_owner_details'],
  ['par_phone', 'Account_owner_details'],
  ['par_job_title', 'Account_owner_details'],
  ['par_job_title_explanation', 'Job_description'],
  ['par_internal_manager_name', 'Manager_details'],
  ['par_is_third_party_office', 'Manager_details'],
  ['par_is_third_party_company', 'Account_owner_details'],
  ['par_third_party_manager_first_name', 'Third_Manager_Party_details'],
  ['par_third_party_manager_last_name', 'Third_Manager_Party_details'],
  ['par_third_party_manager_phone', 'Third_Manager_Party_details'],
  ['par_third_party_manager_email', 'Third_Manager_Party_details'],
  ['par_point_of_escalation_in_third_party', 'Third_Manager_Party_details'],
  ['par_servicenow_ticket_number', 'ServiceNow_ticket_number'],
  ['par_domain', 'Domain'],
  ['par_account_justification', 'Account_justification'],
  ['par_account_name', 'Text update'],
  ['par_user_logon_name', 'text update'],
  ['par_pre_windows_2000_username', 'text update'],
  ['par_country', 'text update'],
  ['par_not_listed_third_party_company', 'Selection'],
  ['par_internal_manager_first_name', 'Manager_details'],
  ['par_internal_manager_last_name', 'Manager_details'],
  ['par_internal_manager_email', 'Manager_details'],
  ['par_internal_manager_phone', 'Manager_details'],
])
export const staticDifferenceData = ['description',
  'Account_owner_details',
  'Third_Manager_Party_details',
  'Manager_details',
  'ServiceNow_ticket_number',
  'Domain',
  'dataverse',
  'oktaCompany',
  'oktaPhone',
  'oktaCity',
  'oktaCountry',
  'oktaJobTitle',
  'oktaDepartment',
  'request_type',
  'event',
  'request_id',
  'par_date_raised',
  'par_internal_manager_name',
  'Job_description',
  'mark_step_aad_onPrem',
  'review_account_requirements',
  'is_step_one_completed_cloud',
  'enter_user_logon_name',
  'is_user_logon_name_entered',
  'enter_proposed_user_name',
  'is_proposed_user_name_entered',
  'enter_display_name',
  'is_display_name_entered',
  'enter_first_name_and_last_name',
  'copy_password_for_end_user',
  'add_usage_location',
  'entered_job_info_section',
  'user_created_or_exist',
  'is_user_added',
  'assign_license',
  'assign_admin_role_to_account',
  'enroll_pim',
  'shared_account_details_groupm',
  'is_step_two_completed_cloud',
  'login_to_adaxes',
  'is_select_create_admin',
  'enter_user_logon_name_in_adaxes',
  'is_entered_user_logon_name',
  'enter_correct_role_group_name',
  'is_correct_role_group_entered',
  'communicate_password_securely',
  'is_step_complete_ad',
]
