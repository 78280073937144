import usePendingImplementationHook from "./usePendingImplementationHook";
import { useHistory } from "react-router-dom";
import { Grid } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterOptions from "../../../components/filter-menu/FilterOptions";
import FilterMenu from "../../../components/filter-menu";
import myImplementationTasksStyles from "../my-Implementation-tasks-styles";
import CustomButton from "../../../components/button";
import DynamicTable from "../../../components/dynamic-table";
import PaginationBlock from "../../../components/pagination-block";
import CustomModal from "../../../components/custom-modal";
import CustomDatePicker from "../../../components/date-range-picker";
import CustomToggleButton from "../../../components/button/CustomToggleButton";
import { ReactComponent as UserIcon } from "../../../assets/icons/user.svg";
import { ReactComponent as EyeIcon } from "../../../assets/icons/eye_b.svg";
import { ReactComponent as EmptyResultIcon } from "../../../assets/icons/no_results_icon.svg";
import { ReactComponent as InformationIcon } from "../../../assets/icons/info_9024.svg";

import { isEqual } from "lodash";

function PendingImplementation() {
  const history = useHistory();
  const styleClasses = myImplementationTasksStyles();
  const {
    filters,
    pendingImplementationData,
    tableColumnsForPending,
    isLoadingPending,
    tableParametersPending,
    setTableParametersPending,
    setFilters,
    requestIdFilter,
    requestorNameFilter,
    requestTypeFilter,
    requestNameFilter,
    setCloseFilter,
    modalHeaderHandler,
    modalContentHandler,
    modalActionHandler,
    closeFilter,
    datepickerProps,
    setDatepickerProps,
    setDateRangeValue,
    setDateRangeFilterRequestedOn,
    setDateRangeFilterAssignOn,
    menuData,
    setMenuData,
    modalData,
    modalOpen,
    handleModalOpen,
    handleModalClose,
    clearValueHandler,
    emptyFilters,
    assignOnBadge,
    requestOnBadge
  } = usePendingImplementationHook();

  const openLinkedDelegation = (id: any) => {
    if (menuData.row.request_type === 'PAR') {

      if (menuData.row.request_purpose === "Connect to / manage a server or computer") {
        // console.log("123")
        history.push(`/myImplementationTasks/pendingImplementation/${id}`);
      } else {
        history.push(`/myImplementationTasks/pendingImplementationSar/${id}`);
      }
    }
  };

  const emptyRequestHandler = () => {
    if (
      !isLoadingPending.tableLoading &&
      pendingImplementationData?.results.length === 0
    ) {
      return (
        <Grid item xs={12} data-testid="empty-delegation-screen">
          <div className={styleClasses.emptyResultContainer}>
            <EmptyResultIcon />
            <span>
              {isEqual(filters, emptyFilters)
                ? "There are no requests created by you."
                : "There are no requests matching to the selected filters."}
            </span>
          </div>
        </Grid>
      );
    }
    return null;
  };

  return (
    <>
      {/*  FILTERS START */}
      <Grid item xs={12} className={styleClasses.filtersContainer}>
        <div className={styleClasses.filterPanel}>
          <div className={styleClasses.filterIcon}>
            <FilterListIcon />
          </div>
          <div className={styleClasses.filterMenu}>
            <FilterMenu
              filterLabel="REQ ID"
              handleFilterClose={() => console.log("handleClose")}
              badgeCount={filters.requestId?.length}
            >
              <FilterOptions
                searchPlaceholder="Search"
                allowSearch
                optionsList={requestIdFilter}
                selectedOptions={filters.requestId}
                handleOptionSelection={(e, selection) => {
                  setFilters((prevFilters: any) => {
                    let newRequestIdArray = [...prevFilters.requestId];
                    console.log("newRequestIdArray", newRequestIdArray);
                    if (!selection) {
                      newRequestIdArray = newRequestIdArray.filter(
                        (option) => option !== e.target.value
                      );
                    } else newRequestIdArray.push(e.target.value);
                    return {
                      ...prevFilters,
                      requestId: [...newRequestIdArray],
                      page: 1,
                    };
                  });
                }}
                isFilterDataLoading={isLoadingPending.filters.requestId}
                allowAll
                selectAllHandler={(isSelected: boolean) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    requestId: isSelected
                      ? requestIdFilter.map(
                        (obj: { id: string; value: string }) => obj.id
                      )
                      : [],
                  }));
                }}
              />
            </FilterMenu>
          </div>
          <div className={styleClasses.filterMenu}>
            <FilterMenu
              filterLabel="REQUESTOR NAME"
              handleFilterClose={() => console.log("handleClose")}
              badgeCount={filters.requestorName?.length}
            >
              <FilterOptions
                searchPlaceholder="Search"
                allowSearch
                optionsList={requestorNameFilter}
                selectedOptions={filters.requestorName}
                handleOptionSelection={(e, selection) => {
                  setFilters((prevFilters) => {
                    let newRequestorNameArray = [...prevFilters.requestorName];
                    console.log(selection, "name");
                    if (!selection) {
                      newRequestorNameArray = newRequestorNameArray.filter(
                        (option) => option !== e.target.value
                      );
                    } else newRequestorNameArray.push(e.target.value);
                    return {
                      ...prevFilters,
                      requestorName: [...newRequestorNameArray],
                      page: 1,
                    };
                  });
                }}
                isFilterDataLoading={isLoadingPending.filters.requestorName}
                allowAll
                selectAllHandler={(isSelected: boolean) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    requestorName: isSelected
                      ? requestorNameFilter.map(
                        (obj: { id: string; value: string }) => obj.id
                      )
                      : [],
                  }));
                }}
              />
            </FilterMenu>
          </div>
          <div className={styleClasses.filterMenu}>
            <FilterMenu
              filterLabel="REQUEST TYPE"
              handleFilterClose={() => console.log("handleClose")}
              badgeCount={filters.requestType?.length}
            >
              <FilterOptions
                searchPlaceholder="Search"
                optionsList={requestTypeFilter}
                selectedOptions={filters.requestType}
                handleOptionSelection={(e, selection) => {
                  setFilters((prevFilters) => {
                    let newRequestorNameArray = [...prevFilters.requestType];

                    if (!selection) {
                      newRequestorNameArray = newRequestorNameArray.filter(
                        (option) => option !== e.target.value
                      );
                    } else newRequestorNameArray.push(e.target.value);
                    return {
                      ...prevFilters,
                      requestType: [...newRequestorNameArray],
                      page: 1,
                    };
                  });
                }}
                isFilterDataLoading={isLoadingPending.filters.requestType}
                allowAll
                selectAllHandler={(isSelected: boolean) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    requestType: isSelected
                      ? requestTypeFilter.map(
                        (obj: { id: string; value: string }) => obj.id
                      )
                      : [],
                  }));
                }}
              />
            </FilterMenu>
          </div>
          <div className={styleClasses.filterMenu}>
            <FilterMenu
              filterLabel="REQUEST NAME"
              handleFilterClose={() => console.log("handleClose")}
              badgeCount={filters.requestName?.length}
            >
              <FilterOptions
                searchPlaceholder="Search"
                optionsList={requestNameFilter}
                selectedOptions={filters.requestName}
                handleOptionSelection={(e, selection) => {
                  setFilters((prevFilters) => {
                    let newRequestNameArray = [...prevFilters.requestName];

                    if (!selection) {
                      newRequestNameArray = newRequestNameArray.filter(
                        (option) => option !== e.target.value
                      );
                    } else newRequestNameArray.push(e.target.value);
                    return {
                      ...prevFilters,
                      requestName: [...newRequestNameArray],
                      page: 1,
                    };
                  });
                }}
                isFilterDataLoading={isLoadingPending.filters.requestName}
                allowAll
                selectAllHandler={(isSelected: boolean) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    requestName: isSelected
                      ? requestNameFilter.map(
                        (obj: { id: string; value: string }) => obj.id
                      )
                      : [],
                  }));
                }}
              />
            </FilterMenu>
          </div>
          <div className={styleClasses.filterMenu}>
            <FilterMenu
              filterLabel="Requested On"
              handleFilterClose={() => {
                if (closeFilter) {
                  setCloseFilter(false);
                }
              }}
              badgeCount={requestOnBadge ? 1 : 0}
              closeFilterExternally={closeFilter}
            >
              <div className={styleClasses.dateRangeToggleContainer}>
                <CustomToggleButton
                  variant="contained"
                  isSelected={!!datepickerProps.isSingleDate}
                  onClick={() => {
                    if (!datepickerProps.isSingleDate) {
                      setDatepickerProps({
                        isSingleDate: true,
                        dateRange: {
                          startDate: null,
                          endDate: null,
                        },
                      });
                    }
                  }}
                >
                  Single Date
                </CustomToggleButton>
                <CustomToggleButton
                  variant="contained"
                  isSelected={!datepickerProps.isSingleDate}
                  onClick={() => {
                    if (datepickerProps.isSingleDate) {
                      setDatepickerProps({
                        isSingleDate: false,
                        dateRange: {
                          startDate: null,
                          endDate: null,
                        },
                      });
                    }
                  }}
                >
                  Date Range
                </CustomToggleButton>
              </div>
              <div className={styleClasses.dateRangeContainer}>
                <CustomDatePicker
                  isRangePicker={!datepickerProps.isSingleDate}
                  setDateRangeValue={setDateRangeValue}
                  dateRange={datepickerProps.dateRange}
                  disableFuture
                />
              </div>
              <div className={styleClasses.dateRangeApplyCta}>
                <CustomButton
                  variant="contained"
                  actionType="secondary"
                  disabled={!datepickerProps.dateRange.endDate}
                  onClick={() => setDateRangeFilterRequestedOn()}
                  isCompact
                  disableMargin
                >
                  Apply
                </CustomButton>
              </div>
            </FilterMenu>
          </div>

          <div className={styleClasses.filterMenu}>
            <FilterMenu
              filterLabel="Assigned On"
              handleFilterClose={() => {
                if (closeFilter) {
                  setCloseFilter(false);
                }
                console.log("handleClose");
              }}
              closeFilterExternally={closeFilter}
              badgeCount={assignOnBadge ? 1 : 0}
            >
              <div className={styleClasses.dateRangeToggleContainer}>
                <CustomToggleButton
                  variant="contained"
                  isSelected={!!datepickerProps.isSingleDate}
                  onClick={() => {
                    if (!datepickerProps.isSingleDate) {
                      setDatepickerProps({
                        isSingleDate: true,
                        dateRange: {
                          startDate: null,
                          endDate: null,
                        },
                      });
                    }
                  }}
                >
                  Single Date
                </CustomToggleButton>
                <CustomToggleButton
                  variant="contained"
                  isSelected={!datepickerProps.isSingleDate}
                  onClick={() => {
                    if (datepickerProps.isSingleDate) {
                      setDatepickerProps({
                        isSingleDate: false,
                        dateRange: {
                          startDate: null,
                          endDate: null,
                        },
                      });
                    }
                  }}
                >
                  Date Range
                </CustomToggleButton>
              </div>
              <div className={styleClasses.dateRangeContainer}>
                <CustomDatePicker
                  isRangePicker={!datepickerProps.isSingleDate}
                  setDateRangeValue={setDateRangeValue}
                  dateRange={datepickerProps.dateRange}
                  disableFuture
                />
              </div>
              <div className={styleClasses.dateRangeApplyCta}>
                <CustomButton
                  variant="contained"
                  actionType="secondary"
                  disabled={!datepickerProps.dateRange.endDate}
                  onClick={() => setDateRangeFilterAssignOn()}
                  isCompact
                  disableMargin
                >
                  Apply
                </CustomButton>
              </div>
            </FilterMenu>
          </div>
          <CustomButton
            variant="contained"
            actionType="tertiary"
            onClick={() => {
              clearValueHandler();
            }}
            disableRipple
            disableMargin
            disablePadding
          >
            CLEAR
          </CustomButton>
        </div>
      </Grid>
      {/*  FILTERS END */}
      {/*  LISTING TABLE START */}
      <Grid item xs={12}>
        <DynamicTable
          rows={pendingImplementationData.results}
          isLoading={isLoadingPending.tableLoading}
          columns={tableColumnsForPending}
          rowId={menuData.row.request_id}
          pageNumber={tableParametersPending.page}
          pageSize={pendingImplementationData.pageSize}
        ></DynamicTable>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: "21px" }}>
        <PaginationBlock
          totalRecords={pendingImplementationData?.results.length || 0}
          currentRecordsCount={pendingImplementationData?.results?.length}
          activePage={tableParametersPending.page}
          handlePageChange={(pageNumber) => {
            setTableParametersPending((prevParameters: any) => ({
              ...prevParameters,
              page: pageNumber,
            }));
          }}
          isLoading={isLoadingPending.tableLoading}
          pageSize={pendingImplementationData.pageSize}
        />
      </Grid>
      {/*  LISTING TABLE END */}
      {emptyRequestHandler()}
      {/*  ACTION MENU POPUP START */}
      {menuData.anchorEl && menuData.row && (
        <Menu
          id="menu-popover"
          open={!!menuData.anchorEl}
          anchorEl={menuData.anchorEl}
          onClose={() =>
            setMenuData({
              anchorEl: null,
              row: {
                request_id: null,
                request_stage_id: null,
                request_type: null,
                request_status: null,
                linked_delegation: null,
                request_stage: null,
                delegate_to_secondary: null,
                implementer_type_id: null,
                request_purpose: null
              },
            })
          }
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          classes={{ root: styleClasses.moreActionMenu }}
          disableAutoFocusItem
        >
          {menuData.row.linked_delegation && (
            <MenuItem
              onClick={() =>
                openLinkedDelegation(menuData.row.linked_delegation)
              }
              className={styleClasses.moreActionMenuItem}
            >
              <EyeIcon />
              <span className={styleClasses.menuLabel}>View Delegation</span>
            </MenuItem>
          )}
          {menuData.row.delegate_to_secondary && (
            <MenuItem
              onClick={() => handleModalOpen("changeImplementor")}
              className={styleClasses.moreActionMenuItem}
            >
              <UserIcon />
              <span className={styleClasses.menuLabel}>Change implementer</span>
            </MenuItem>
          )}
          {!menuData.row.delegate_to_secondary && (
            <MenuItem
              onClick={() => handleModalOpen("info")}
              className={styleClasses.moreActionMenuItem}
            >
              <InformationIcon />
              <span className={styleClasses.menuLabel}>
                Delegation information
              </span>
            </MenuItem>
          )}
        </Menu>
      )}
      {modalOpen && (
        <CustomModal
          modalHeader={modalHeaderHandler(modalData.type)}
          open={modalOpen}
          onClose={handleModalClose}
          modalAction={modalActionHandler(modalData.type)}
          modalContent={modalContentHandler(modalData.type)}
        />
      )}
      {/*  ACTION MENU POPUP END */}
    </>
  );
}
export default PendingImplementation;
