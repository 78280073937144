import { createSlice, current } from '@reduxjs/toolkit'
import { IData } from './ISarSubmitData';
import type { PayloadAction } from '@reduxjs/toolkit'
import { IFreshPostedData } from './ISarSubmitData';
import { IpostData } from './ISarSubmitData';

export const sarCommentSlice = createSlice({
    name: 'submitDelegationCommentData',
    initialState: [
      { Function: []},
        { Justification: [] },
        { Requestor_details: [] },
        { Team_details: [] },
        { Service_account_details:[] },
        { Select_domain:[] },
        { Service_account_name:[] },
        { Choose_account_type:[] },
        { Choose_service_account_function:[] },
        { Account_function_justification:[] },
        { Require_interactive_logon:[] },
        { Justification_for_interactive_logon: [] },
        { Additional_function: [] },
        { Is_single_server:[] },
        { Server_hostnames:[] },
        { Choose_server_tier: [] },
        { User_rights_and_privileges:[] },
        { Group_membership:[] },
        { External_And_Internal_IP_Details:[] },
        { External_internal_ip_ranges: [] },
        { Service_account_request_spn_dele: [] },
        { SPN_list:[]},
        { required_delegtion: [] },
        { Delegation_list: [] },
        { Delegation_reason:[]},
        { Password_change_procedures: [] },
        { Security_of_passwords: [] },
        { Password_will_be_changed: [] },
        { Default_password_expiry_policy: [] },
        { Business_impact: [] },
        { Risk_exposure: [] },
        { Risk_of_the_service_account: [] },
        { Account_was_compromised: [] },
        { Attachments: [] }
    ],
    reducers: {
        submitSarComments: (state, action: PayloadAction<any>) => {
            return [...action.payload]
        },
        postDelegationFreshComments: (state, action: PayloadAction<IpostData>) => {
            state.map((row) => {
                Object.entries(row).map(([key, value]) => {
                    if (key === action.payload.section) {
                        value.push(action.payload)
                    }
                })
            })
        },
        deleteDelegationComments: (state, action: PayloadAction<IpostData>) => {
            state.map((row) => {
                Object.entries(row).map(([key, value]) => {
                    if (key === action.payload.section) {
                        value.map((item: any, index: number) => {
                            if (item.id === action.payload.id) {
                                value.splice(index, 1)
                            }
                        })
                    }
                })
            })
        },
        editDelegationComments: (state, action: PayloadAction<IpostData>) => {
            state.map((row) => {
                Object.entries(row).map(([key, value]) => {
                    if (key === action.payload.section) {
                        value.map((item: any, index: number) => {
                            if (item.id === action.payload.id) {
                                value.splice(index, 1)
                                value.push(action.payload)
                            }
                        })
                    }
                })
            })
        },
        requestorDelegationApproverFreshComments: (state, action: PayloadAction<IpostData>) => {
            state.map((row) => {
                Object.entries(row).map(([key, value]) => {
                    if (key === action.payload.section) {

                        value.map((item: any, index: number) => {

                            if (item.id === action.payload.comment_id) {
                                item.requestor_reply = [...item.requestor_reply, action.payload]
                            }
                        })
                    }
                })
            })
        },
        requestorDelegationEditReply: (state, action: PayloadAction<IpostData>) => {
            state.map((row) => {
                Object.entries(row).map(([key, value]) => {
                    if (key === action.payload.section) {

                        value.map((item: any, index: number) => {

                            if (item.id === action.payload.comment_id) {
                                item.requestor_reply = [action.payload]
                            }
                        })
                    }
                })
            })
        },
        requestorDelegationDeleteReply: (state, action: PayloadAction<IpostData>) => {

            state.map((row) => {
                Object.entries(row).map(([key, value]) => {
                    if (key === action.payload.section) {

                        value.map((item: any, index: number) => {

                            if (item.id === action.payload.comment_id) {
                                item.requestor_reply = []
                            }
                        })
                    }
                })
            })
        }


    }
});

export const { submitSarComments, postDelegationFreshComments, deleteDelegationComments, editDelegationComments, requestorDelegationApproverFreshComments, requestorDelegationEditReply, requestorDelegationDeleteReply } = sarCommentSlice.actions;
export default sarCommentSlice.reducer;