/* eslint-disable react/require-default-props */
import statusBadgeStyles from "./status-badge-styles";
import StatusProgress from "../../../components/status-progress";
import HelperTooltip from "../../../components/helper-tooltip";

const getStatusBackground = (status: string) => {
  switch (status) {
    case "Pending Creation":
      return "#F9F7B7";
    case "Active":
      return "#99FDD1";
    case "Inactive":
      return "#EF5B2B";
    default:
      return "default";
  }
};

const getStatusFontColor = (status: string) => {
  switch (status) {
    case "Pending Creation":
      return "#914B32";
    case "Active":
      return "#014645";
    case "Inactive":
      return "#FFE2D8";
    default:
      return "default";
  }
};


function StatusBadge({ status }: { status: any }) {
  const statusClasses = statusBadgeStyles({
    background: getStatusBackground(status),
    color: getStatusFontColor(status),
  });

  switch (status) {
    case "Pending Creation":
      return (
        <div >
          <span
            className={statusClasses.pendingCreation}
            data-testid="status-badge"
          >
            {`${status}`}
          </span>
        </div>
      );
    case "Active":
      return (
        <div>
          <span
            className={statusClasses.active}
            data-testid="status-badge"
          >
            {`${status}`}
          </span>
        </div>
      );
    case "Inactive":
      return (
        <div >
          <span
            className={statusClasses.inActive}
            data-testid="status-badge"
          >
            {`${status}`}
          </span>
        </div>
      );
    default:
      return (
        <div>
          <span
            className={statusClasses.pendingCreation}
            data-testid="status-badge"
          >
            {`${status}`}
          </span>
        </div>
      );
  }
}

export default StatusBadge;
