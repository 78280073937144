/* eslint-disable camelcase */
import { useEffect, useRef, useState } from "react";
import {
  ArrayHelpers,
  FieldArray,
  Formik,
  FormikProps,
  FormikValues,
  useFormikContext,
} from "formik";
import Grid from "@mui/material/Grid";
import CheckIcon from '@mui/icons-material/Check';
import { Add, HelpRounded } from "@mui/icons-material";
import * as Yup from "yup";
import CustomButton from "../../../button";
import FormikControl from "../../../formik-conrtols/FormikControl";
import FieldLabel from "../../../field-label";
import CustomModal from "../../../custom-modal";
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import AddIcon from '@mui/icons-material/Add';
import {
  roleDefinitionStyles,
  modalStyles,
  implementationStyles,
  delegationOwnerStyles,
} from "../../useStyles";
import HelperTooltip from "../../../helper-tooltip";
import { ReactComponent as DeleteAction } from "../../../../assets/icons/DeleteAction.svg";
import { getScopeValuesByGeographicScope } from "../../../../api-services/delegation-module";
import ReviewActions from "../../../review-actions";
import { format, isDate } from "date-fns";

import Uploady, {
  useItemStartListener,
  useItemFinalizeListener,
} from "@rpldy/uploady";
import { getMockSenderEnhancer } from "@rpldy/mock-sender";
import UploadDropZone from "@rpldy/upload-drop-zone";
import withPasteUpload from "@rpldy/upload-paste";
import UploadPreview from "@rpldy/upload-preview";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ReactComponent as PencilIconBlack } from "../../../../assets/icons/pencil_b.svg";
import { ReactComponent as DragFile } from "../../../../assets/icons/pasteSnippet.svg";
import UploadStatus from "../../../file-uploader/fileSelection";
import { accordian1, accordian2, createRoleGroup1, createRoleGroup2, createRoleGroup3, createRoleGroup4, createRoleGroup5, createRoleGroup6, proposedRoleGroupName } from "../implementationTooltip";
import UploadSnippet from "../../../file-uploader/fileSelection";
import FileUploader from "../../../file-uploader/fileUploader";
import { getImplementationStepData, postStepOneData, postStepTwoData } from "../../../../api-services/pending-implementation";
import { initial, isEqual } from "lodash";
import { useSnackbar } from "notistack";

export type stepTwo = {
  assign_gpo_file_upload: string,
  create_role_group_in_ad: string,
  group_policy_object_name: string,
  group_policy_object_name_script: string,
  id: number,
  is_assign_gpo_to_role_and_target_group: boolean,
  is_assign_permission_and_create_acl: boolean,
  is_assign_resources_to_target_group: string,
  is_create_group_policy_object_in_ad: boolean,
  is_create_target_group_in_ad: boolean,
  is_implemented: boolean,
  is_login_to_adaxes_and_select_ad_admin: boolean,
  login_to_adaxes_file_upload: boolean,
  proposed_role_group_name: string,
  proposed_role_group_name_id: number,
  target_group_name: string,
  target_group_name_script: string,
}

export type RoleTileData = {
  step_one: {
    enter_review_comments: string,
    id: number,
    review_delegation_file: any,
    is_step_one_completed: boolean,
  },
  step_two: {
    assign_gpo_file_upload: string,
    create_role_group_in_ad: string,
    group_policy_object_name: string,
    group_policy_object_name_script: string,
    id: number,
    is_assign_gpo_to_role_and_target_group: boolean,
    is_assign_permission_and_create_acl: boolean,
    is_assign_resources_to_target_group: string,
    is_create_group_policy_object_in_ad: boolean,
    is_create_target_group_in_ad: boolean,
    is_implemented: boolean,
    is_login_to_adaxes_and_select_ad_admin: boolean,
    login_to_adaxes_file_upload: boolean,
    proposed_role_group_name: string,
    proposed_role_group_name_id: number,
    target_group_name: string,
    target_group_name_script: string,
  }[],
}

export const emptyRoleTileData = {
  step_one: {
    enter_review_comments: '',
    id: -1,
    review_delegation_file: [],
    is_step_one_completed: false
  },
  step_two: [{
    assign_gpo_file_upload: '',
    create_role_group_in_ad: '',
    group_policy_object_name: '',
    group_policy_object_name_script: '',
    id: -1,
    is_assign_gpo_to_role_and_target_group: false,
    is_assign_permission_and_create_acl: false,
    is_assign_resources_to_target_group: '',
    is_create_group_policy_object_in_ad: false,
    is_create_target_group_in_ad: false,
    is_implemented: false,
    is_login_to_adaxes_and_select_ad_admin: false,
    login_to_adaxes_file_upload: false,
    proposed_role_group_name: '',
    proposed_role_group_name_id: -1,
    target_group_name: '',
    target_group_name_script: '',
  }]
}
export type RoleDefinitionType = {
  id: string | null;
  scope: string;
  role_name: string;
  justification: string;
  number_of_slots: string;
  justification_for_slots: string;
  regional_scope_label: string;
  regional_scope: number | null;
  market_scope_label: string;
  market_scope: number | null;
  city_scope_label: string;
  city_scope: number | null;
  country_scope_label: string;
  country_scope: number | null;
  is_role_for_thirdparty_user: boolean;
  does_servicenow_require: boolean;
  is_active: boolean;
  is_deleted: boolean;
};

const emptyRole = {
  id: null,
  scope: "Geographical",
  geographical_scope: "Regional",
  role_name: "",
  justification: "",
  number_of_slots: "",
  justification_for_slots: "",
  regional_scope: null,
  market_scope: null,
  city_scope: null,
  country_scope: null,
  regional_scope_label: "",
  market_scope_label: "",
  city_scope_label: "",
  country_scope_label: "",
  is_role_for_thirdparty_user: false,
  does_servicenow_require: false,
  is_active: true,
  is_deleted: false,
};


function Implementation({ isDisabled }: { isDisabled: boolean }) {

  const classes = implementationStyles();
  const [expanded, setExpanded] = useState<string | false>(false);
  const { enqueueSnackbar } = useSnackbar();
  const styleClasses = delegationOwnerStyles();
  const [roleNameListFromData, setRoleNameListFromData] = useState<RoleDefinitionType>({ ...emptyRole });
  const [accordianModal, setAccordianModal] = useState(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    if (!formikCtx.values.mark_step_firststep) {
      setAccordianModal(true);
    }
    setExpanded(isExpanded ? panel : false);
  };

  const formikCtx: any = useFormikContext();
  const [getImplementationData, setGetImplementationData] = useState<RoleTileData>({ ...emptyRoleTileData });
  const modalClasses = modalStyles();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const formRef = useRef<FormikProps<FormikValues>>(null);
  const [modalAction, setModalAction] = useState<{
    index: number;
    actionType: string;
  }>({
    index: -1,
    actionType: "",
  });

  let keysArray: any = [];
  const initialValues = {
    role_group_name: '',
    mark_step_firststep: false,
    // mark_step_secondstep: false,
    createRole_comments_step_two: '',
    not_required_target_group: false,
    target_group_name: '',
    target_group_name_script: '',
    not_required_group_policy: false,
    group_policy_object_name: '',
    script_group_policy_object: '',
    assign_resources: '',
    not_required_assign_group_policy_object: false,
    mark_step_completed_rolegroup: false,
    not_required_acl: false,
    not_required_assign_resources: false,
    mark_step_secondStep_is_implemented: false,
  };
  const validationSchema = Yup.object({
    // role_name: Yup.string().required("Please provide role name"),
    // justification_for_slots: Yup.string().required(
    //   "Please provide the justification for slots"
    // ),
    // is_role_for_thirdparty_user: Yup.boolean(),
    // does_servicenow_require: Yup.boolean(),
    // mark_step_firststep: Yup.boolean().oneOf(
    //   [true],
    //   "Please mark this step complete"
    // ),
    // // mark_step_secondstep: Yup.boolean().oneOf(
    // //   [true],
    // //   "Please mark this step complete"
    // // ),
    // createRole_comments_step_two: Yup.string().required("Please provide role name"),
    // not_required_target_group: Yup.boolean().oneOf(
    //   [true],
    //   "Please mark this step complete"
    // ),
    // target_group_name: Yup.string().required("Please provide role name"),
    // target_group_name_script: Yup.string().required("Please provide role name"),
    // not_required_group_policy: Yup.boolean().oneOf(
    //   [true],
    //   "Please mark this step complete"
    // ),
    // group_policy_object_name: Yup.string().required("Please provide role name"),
    // script_group_policy_object: Yup.string().required("Please provide role name"),
    // assign_resources: Yup.string().required("Please provide role name"),
    // not_required_assign_group_policy_object: Yup.boolean().oneOf(
    //   [true],
    //   "Please mark this step complete"
    // ),
    // mark_step_completed_rolegroup: Yup.boolean().oneOf(
    //   [true],
    //   "Please mark this step complete"
    // ),
    // not_required_acl: Yup.boolean().oneOf(
    //   [true],
    //   "Please mark this step complete"
    // ),
  });


  const getrequestData = () => {
    getImplementationStepData(formikCtx.values.request_id).then((res) => {
      formikCtx.setFieldValue('review_comments_first_step', res.data[0].step_one.enter_review_comments);
      if (res.data[0].step_one.is_step_one_completed) {
        console.log('2')
        const firstStepCheck = res.data[0].step_one.is_step_one_completed;
        formikCtx.setFieldValue('mark_step_firststep', firstStepCheck);
      }
      // if (res.data[0].step_two.is_implemented) {
      //   const secondStepCheck = res.data[0].step_two.is_implemented;
      //   formikCtx.setFieldValue('mark_step_firststep', secondStepCheck);
      // }
      setGetImplementationData(res.data[0]);
    }).catch((err) => err);
  }


  useEffect(() => {
    getrequestData();
    if (formikCtx.values.is_implemented) {
      formikCtx.setFieldValue('mark_step_secondstep', true);
    };
  }, []);

  const letterCapitalization = () => {

    const arr = roleNameListFromData.role_name.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str2 = arr.join(" ");
    return str2;
  }

  const getModalHeader = (actionType: string) => {
    switch (actionType) {
      case "add":
        return letterCapitalization();
      default:
        return "";
    }
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleCloseAccordianModal = () => {
    setExpanded('panel1');
    setAccordianModal(false);
  }

  const handleSave = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const modalActionHandler = (actionType: string) => {
    switch (actionType) {
      case "add":
        return (
          <div className={modalClasses.modalActionDiv}>
            <CustomButton
              variant="contained"
              actionType="secondary"
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </CustomButton>
            {!isDisabled && <CustomButton
              variant="contained"
              actionType="primary"
              disableMargin
              disabled={formRef.current?.values.mark_step_secondStep_is_implemented === false ? true : false}
              onClick={() => {
                handleSave();
              }}
            >
              Save
            </CustomButton>}
          </div>
        );
      default:
        return null;
    }
  };

  const handleViewRole = (roleNameList: RoleDefinitionType, index: number) => {
    setRoleNameListFromData(roleNameList);
    setModalAction({
      actionType: "add",
      index,
    });
    setModalOpen(true);
  };

  const onSubmitHandlerForRole = (values: any) => {

    // const firstStepdata = JSON.parse(localStorage.getItem('firstStepData') || '');

    const filteredArray = getImplementationData.step_two.filter(
      (item: any) => item.proposed_role_group_name_id === roleNameListFromData.id
    );
    const submitData = {
      // @ts-ignore
      step_one_id: getImplementationData.step_one.id ? getImplementationData?.step_one.id : null,
      step_two_id: filteredArray.length > 0 ? filteredArray[0].id : null,
      proposed_role_group_name: roleNameListFromData.id,
      create_role_group_in_ad: values.createRole_comments_step_two,
      is_create_target_group_in_ad: values.not_required_target_group,
      target_group_name: values.not_required_target_group ? '' : values.target_group_name,
      target_group_name_script: values.not_required_target_group ? '' : values.target_group_name_script,
      is_create_group_policy_object_in_ad: values.not_required_group_policy,
      group_policy_object_name: values.not_required_group_policy ? '' : values.group_policy_object_name,
      group_policy_object_name_script: values.not_required_group_policy ? '' : values.script_group_policy_object,
      is_assign_resources_to_target_group: values.not_required_assign_resources ? '' : values.assign_resources,
      is_assign_gpo_to_role_and_target_group: values.not_required_assign_group_policy_object,
      is_login_to_adaxes_and_select_ad_admin: values.mark_step_completed_rolegroup,
      is_assign_permission_and_create_acl: values.not_required_acl,
      assign_resources_to_target_group: values.not_required_assign_resources ? true : false,
      is_implemented: values.mark_step_secondStep_is_implemented ? true : false,
    }

    postStepTwoData(submitData).then(() => {
      getrequestData();
    }).catch((err) => err);

    handleClose();

  };


  const modalContentHandler = (actionType: string, value: any) => {
    // formikCtx.setFieldValue('role_group_name', roleNameListFromData.role_name);

    const filteredArray = getImplementationData.step_two.filter(
      (item: any) => item.proposed_role_group_name_id === value.id
    );

    const fetchedDataValues = {
      role_group_name: filteredArray.length > 0 ? filteredArray[0].proposed_role_group_name : '',
      createRole_comments_step_two: filteredArray.length > 0 ? filteredArray[0].create_role_group_in_ad : '',
      not_required_target_group: filteredArray.length > 0 ? filteredArray[0].is_create_target_group_in_ad : false,
      target_group_name: filteredArray.length > 0 ? filteredArray[0].target_group_name : '',
      target_group_name_script: filteredArray.length > 0 ? filteredArray[0].target_group_name_script : '',
      not_required_group_policy: filteredArray.length > 0 ? filteredArray[0].is_create_group_policy_object_in_ad : false,
      group_policy_object_name: filteredArray.length > 0 ? filteredArray[0].group_policy_object_name : '',
      script_group_policy_object: filteredArray.length > 0 ? filteredArray[0].group_policy_object_name_script : '',
      assign_resources: filteredArray.length > 0 ? filteredArray[0].is_assign_resources_to_target_group : '',
      not_required_assign_group_policy_object: filteredArray.length > 0 ? filteredArray[0].is_assign_gpo_to_role_and_target_group : false,
      mark_step_completed_rolegroup: filteredArray.length > 0 ? filteredArray[0].is_login_to_adaxes_and_select_ad_admin : false,
      not_required_acl: filteredArray.length > 0 ? filteredArray[0].is_assign_permission_and_create_acl : false,
      not_required_assign_resources: filteredArray.length > 0 ? filteredArray[0]?.assign_resources_to_target_group : false,
      mark_step_secondStep_is_implemented: filteredArray.length > 0 ? filteredArray[0].is_implemented : false
    }


    const currentStepId = (values: any) => {
      const filteredArray = getImplementationData.step_two.filter(
        (item: any) => item.proposed_role_group_name_id === roleNameListFromData.id
      );
      return filteredArray.length > 0 ? filteredArray[0].id : null;
    }

    const currentGetFiles = (values: any) => {
      const filteredArray = getImplementationData.step_two.filter(
        (item: any) => item.proposed_role_group_name_id === roleNameListFromData.id
      );
      return filteredArray.length > 0 ? filteredArray[0].assign_gpo_file_upload : null;
    }

    const currentGetFilesAcl = (values: any) => {
      const filteredArray = getImplementationData.step_two.filter(
        (item: any) => item.proposed_role_group_name_id === roleNameListFromData.id
      );
      return filteredArray.length > 0 ? filteredArray[0].login_to_adaxes_file_upload : null;
    }

    switch (actionType) {
      case "add":
        return (
          <>
            <Formik
              onSubmit={(values: FormikValues) => {
                onSubmitHandlerForRole(values)
              }}
              initialValues={
                {
                  ...initialValues,
                  ...fetchedDataValues,
                  role_group_name: roleNameListFromData.role_name,
                }
                // {
                //   role_group_name: '',
                //   mark_step_firststep: false,
                //   createRole_comments_step_two: '',
                //   not_required_target_group: false,
                //   target_group_name: '',
                //   target_group_name_script: '',
                //   not_required_group_policy: false,
                //   group_policy_object_name: '',
                //   script_group_policy_object: '',
                //   assign_resources: '',
                //   not_required_assign_group_policy_object: false,
                //   mark_step_completed_rolegroup: false,
                //   not_required_acl: false,
                // }
              }
              validationSchema={validationSchema}
              //enableReinitialize
              // validateOnChange={false}
              // validateOnBlur={false}
              innerRef={formRef}
            >
              {({ values, setFieldValue }) => (
                <Grid container spacing={2} className={classes.mainModelGrid}>
                  <Grid item xs={3} className={classes.roleGroupNameLabel}>
                    <FieldLabel Title="Role group name" />
                  </Grid>

                  <Grid item xs={4} style={{ paddingLeft: "0" }}>
                    <FormikControl
                      control="textfield"
                      name="role_group_name"
                      required
                    />
                  </Grid>
                  <Grid item xs={1} className={styleClasses.toolTipPosition} >
                    <span className={styleClasses.tooltipSpan}>
                      <HelperTooltip tooltipMsg={proposedRoleGroupName}>
                        <HelpRoundedIcon style={{ color: '#0A2756' }} />
                      </HelperTooltip>
                    </span>
                  </Grid>

                  <Grid item xs={12} className={classes.textAreaLabel}>
                    <Typography className={classes.title}>
                      <div>
                        Create <span className={classes.boldText}>role group</span> in Active Directory using
                        PowerShell script
                      </div>
                      <span className={classes.astrik}>*</span>
                      <span>
                        <HelperTooltip tooltipMsg={createRoleGroup1}>
                          <HelpRoundedIcon style={{ color: '#0A2756' }} />
                        </HelperTooltip>
                      </span>
                    </Typography>
                    <FormikControl
                      control="textfield"
                      name="createRole_comments_step_two"
                      multiline
                      minRows={4}
                      disabled={isDisabled}
                    />
                  </Grid>

                  {values.createRole_comments_step_two !== '' && <Grid item xs={12} className={classes.textAreaLabel}>
                    <Typography className={classes.title}>
                      <div>
                        Create <span className={classes.boldText}>target group</span> in Active Directory
                        using PowerShell script.
                      </div>
                      <span className={classes.astrik}>*</span>
                      <span>
                        <HelperTooltip tooltipMsg={createRoleGroup2}>
                          <HelpRoundedIcon style={{ color: '#0A2756' }} />
                        </HelperTooltip>
                      </span>
                    </Typography>
                    <Grid xs={6}>
                      <FormikControl
                        control="checkbox"
                        label="Not required"
                        name="not_required_target_group"
                        // onChange={(e: any) => {
                        //   if (values.not_required_target_group === true)
                        //     setFieldValue('target_group_name', '');
                        // }}
                        required
                        className={classes.modelCheckboxLabel}
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid xs={6} className={classes.paddingT}>
                      <FormikControl
                        control="textfield"
                        name="target_group_name"
                        label="Target group name"


                        disabled={isDisabled || values.not_required_target_group ? true : false}
                        implementationFieldCheck={values.not_required_target_group ? true : false}
                      />
                    </Grid>
                    <Grid xs={12} className={classes.paddingT}>
                      <FormikControl
                        control="textfield"
                        name="target_group_name_script"
                        label="Script"
                        multiline
                        minRows={4}
                        disabled={isDisabled || values.not_required_target_group ? true : false}
                        implementationFieldCheck={values.not_required_target_group ? true : false}
                      />
                    </Grid>
                  </Grid>}

                  {(values.not_required_target_group === true || (values.target_group_name !== '' && values.target_group_name_script !== '')) && <Grid item xs={12} className={classes.textAreaLabel}>
                    <Typography className={classes.title}>
                      <div>
                        Create <span className={classes.boldText}>group policy object</span> in Active
                        Directory using PowerShell script.
                      </div>
                      <span className={classes.astrik}>*</span>
                      <span>
                        <HelperTooltip tooltipMsg={createRoleGroup3}>
                          <HelpRoundedIcon style={{ color: '#0A2756' }} />
                        </HelperTooltip>
                      </span>
                    </Typography>

                    <FormikControl
                      control="checkbox"
                      label="Not required"
                      name="not_required_group_policy"
                      required
                      disabled={isDisabled}
                      className={classes.modelCheckboxLabel}
                    />
                    <Grid xs={6} className={classes.paddingT}>
                      <FormikControl
                        control="textfield"
                        name="group_policy_object_name"
                        label="Group policy object name"
                        disabled={isDisabled || values.not_required_group_policy ? true : false}
                        implementationFieldCheck={values.not_required_group_policy ? true : false}
                      />
                    </Grid>
                    <Grid xs={12} className={classes.paddingT}>
                      <FormikControl
                        control="textfield"
                        name="script_group_policy_object"
                        label="Script"
                        multiline
                        minRows={4}
                        disabled={isDisabled || values.not_required_group_policy ? true : false}
                        implementationFieldCheck={values.not_required_group_policy ? true : false}
                      />
                    </Grid>
                  </Grid>}

                  {(values.not_required_group_policy === true || (values.group_policy_object_name !== '' && values.script_group_policy_object !== '')) && <Grid item xs={12} className={classes.textAreaLabel}>
                    <Typography className={classes.title}>
                      <div>
                        Assign <span className={classes.boldText}>resources</span>  (server/ application objects) to target
                        group.
                      </div>
                      <span className={classes.astrik}>*</span>
                      <span>
                        <HelperTooltip tooltipMsg={createRoleGroup4}>
                          <HelpRoundedIcon style={{ color: '#0A2756' }} />
                        </HelperTooltip>
                      </span>
                    </Typography>
                    <FormikControl
                      control="checkbox"
                      label="Not Required"
                      name="not_required_assign_resources"
                      required
                      className={classes.modelCheckboxLabel}
                      disabled={isDisabled}
                    />
                    <Grid xs={12} className={classes.paddingT}>
                      <FormikControl
                        control="textfield"
                        name="assign_resources"
                        label="Script"
                        multiline
                        minRows={4}
                        disabled={isDisabled || values.not_required_assign_resources === true ? true : false}
                        implementationFieldCheck={values.not_required_assign_resources ? true : false}
                      />
                    </Grid>
                  </Grid>

                  }

                  {(values.not_required_assign_resources === true || (values.assign_resources !== '')) && <Grid item xs={12} className={classes.textAreaLabel}>
                    <Typography className={classes.title}>
                      <div>
                        Assign <span className={classes.boldText}>Group Policy Object</span> to  <span className={classes.boldText}>role group</span> and <span className={classes.boldText}>target group</span> using Group Policy Editor
                      </div>
                      <span className={classes.astrik}>*</span>
                      <span>
                        <HelperTooltip tooltipMsg={createRoleGroup5}>
                          <HelpRoundedIcon style={{ color: '#0A2756' }} />
                        </HelperTooltip>
                      </span>
                    </Typography>

                    <FormikControl
                      control="checkbox"
                      label="Not required"
                      name="not_required_assign_group_policy_object"
                      required
                      className={classes.modelCheckboxLabel}
                      disabled={isDisabled}
                    />
                    <Grid item xs={5}>
                      <Typography className={classes.title}>
                        <div>Upload snips/file (optional)</div>
                        {/* <button className={classes.subTitle}>+Attach File</button> */}
                      </Typography>
                    </Grid>
                    {/* {!isDisabled && */}
                    <>
                      <Grid item xs={12} className={classes.fileUploaderMain}>
                        <FileUploader
                          disabled={values.not_required_assign_group_policy_object ? true : false}
                          fileAction={(
                            <CustomButton
                              variant="contained"
                              actionType="primary"
                              disableMargin
                              disabled={isDisabled || values.not_required_assign_group_policy_object ? true : false}
                            >
                              <AddIcon />
                              <span>Attach File</span>
                            </CustomButton>
                          )}
                          step_one_id={getImplementationData.step_one.id ? getImplementationData?.step_one.id : null}
                          file_section={'assign_gpo_file_upload'}
                          role_id={roleNameListFromData.id}
                          step_id={currentStepId(values)}
                          getFiles={currentGetFiles(values)}
                          requestId={formikCtx.values.request_id}
                        />
                      </Grid>
                      {/* <Grid item xs={12}>
                          <UploadSnippet
                            step_id={currentStepId(values)}
                            getFiles={currentGetFiles(values)}
                            requestId={formikCtx.values.request_id}
                            step_one_id={getImplementationData.step_one.id ? getImplementationData?.step_one.id : null}
                            file_section={'assign_gpo_file_upload'}
                            role_id={roleNameListFromData.id}
                          />
                        </Grid> */}
                    </>
                    {/* } */}
                    {/* <Grid item xs={12}>
                  <FormikControl
                    control="textfield"
                    name="review_comments"
                    multiline
                    minRows={4}
                  />
                </Grid> */}
                  </Grid>}

                  <Grid item xs={12} className={classes.textAreaLabel}>
                    {/* {(values.not_required_assign_group_policy_object === true) && */}
                    <>
                      <Typography className={classes.title}>
                        <div>
                          Login to Adaxes
                          <a
                            className={classes.linkTag}
                            href=" https://id.insidemedia.net/Adaxes#/SignIn"
                          >
                            https://id.insidemedia.net/Adaxes#/SignIn
                          </a>
                          and select <span className={classes.boldText}> GroupM AD Admins</span> from the dropdown list <span className={classes.astrik}>*</span>
                        </div>
                      </Typography>

                      <FormikControl
                        control="checkbox"
                        label="Mark this step complete"
                        name="mark_step_completed_rolegroup"
                        required
                        className={classes.modelCheckboxLabel}
                        disabled={isDisabled}
                      />
                    </>
                    {/* } */}
                    {values.mark_step_completed_rolegroup === true &&
                      <>
                        <Typography className={classes.title}>
                          <div>
                            Assign required permissions and create  <span className={classes.boldText}>Access Control List</span>(ACL) in Adaxes to provide access to Active Directory objects, such as users, computers, groups <span className={classes.astrik}>*</span>
                            <span>
                              <HelperTooltip tooltipMsg={createRoleGroup6}>
                                <HelpRoundedIcon style={{ color: '#0A2756' }} />
                              </HelperTooltip>
                            </span>
                          </div>

                        </Typography>
                        <FormikControl
                          control="checkbox"
                          label="Not required"
                          name="not_required_acl"
                          required
                          disabled={isDisabled}
                          className={classes.modelCheckboxLabel}
                        />
                        {/* {!isDisabled && */}
                        <>
                          <Grid item xs={5}>
                            <Typography className={classes.title}>
                              <div>Upload snips/file (optional)</div>
                              {/* <span className={classes.subTitle}>+Attach File</span> */}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} className={classes.fileUploaderMain}>
                            <FileUploader
                              disabled={isDisabled || values.not_required_acl ? true : false}
                              fileAction={(
                                <CustomButton
                                  variant="contained"
                                  actionType="primary"
                                  disableMargin
                                  disabled={isDisabled || values.not_required_acl ? true : false}
                                >
                                  <AddIcon />
                                  <span>Attach File</span>
                                </CustomButton>
                              )}
                              requestId={formikCtx.values.request_id}
                              step_one_id={getImplementationData.step_one.id ? getImplementationData?.step_one.id : null}
                              file_section={'login_to_adaxes_file_upload'}
                              role_id={roleNameListFromData.id}
                              step_id={currentStepId(values)}
                              getFiles={currentGetFilesAcl(values)}
                            />
                          </Grid>
                          {/* <Grid item xs={12}>
                              <UploadSnippet
                                requestId={formikCtx.values.request_id}
                                step_one_id={getImplementationData.step_one.id ? getImplementationData?.step_one.id : null}
                                file_section={'login_to_adaxes_file_upload'}
                                role_id={roleNameListFromData.id}
                                step_id={currentStepId(values)}
                                getFiles={currentGetFilesAcl(values)}
                              />
                            </Grid> */}
                        </>
                        {/* } */}
                        {/* Please attach files count logic to it  */}
                        {/* {!isDisabled && values.not_required_acl === true && ( */}
                        <>
                          <FormikControl
                            control="checkbox"
                            label="Mark this step complete"
                            name="mark_step_secondStep_is_implemented"
                            required
                            // disabled={isDisabled || values.mark_step_secondStep_is_implemented ? true : false}
                            className={classes.modelCheckboxLabel}
                          />
                        </>
                        {/* ) */}
                        {/* } */}
                        {/* <Grid item xs={12}>
                        <FormikControl
                          control="textfield"
                          name="review_comments"
                          multiline
                          minRows={4}
                        />
                      </Grid> */}
                      </>
                    }
                  </Grid>

                </Grid>
              )
              }
            </Formik >
          </>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    setExpanded('panel1');
  }, [])

  const getIsImplementedTrueForRoleTile = () => {

    const filteredArray = getImplementationData.step_two.filter(
      (item: any) => {
        if (item.is_implemented === true) {
          keysArray.push(item.proposed_role_group_name_id);
        }
      }
    );
  };

  getIsImplementedTrueForRoleTile();

  const handleSaveFirstStep = () => {
    const postbody = {
      request_id: formikCtx.values.request_id,
      step_one_id: null,
      enter_review_comments: formikCtx.values.review_comments_first_step,
      is_task_list_completed: false,
      is_step_one_completed: formikCtx.values.mark_step_firststep ? true : false,
    }
    postStepOneData(postbody).then((res) => {
      localStorage.setItem("firstStepData", JSON.stringify(res));
      enqueueSnackbar(`Step data submitted successfully`, {
        variant: "success",
      });
      getrequestData();
    }).catch((err) => err);
  };


  return (
    <>
      <Grid container className={classes.gridContainer}>
        <Grid item xs={5}>
          <FieldLabel Title="Implementation task list" />
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: "5px" }}>
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className={classes.gridTitle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.accordionSummary}
            >
              <FieldLabel Title="Review and understand Delegation requirements" implementation={expanded === 'panel1'} tooltipMsg={accordian1} required />
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <Typography>
                <div>
                  <Grid item xs={5}>
                    <FieldLabel Title="Enter review comments (optional)" />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikControl
                      control="textfield"
                      name="review_comments_first_step"
                      multiline
                      minRows={4}
                      disabled={getImplementationData.step_one.is_step_one_completed ? true : false}
                    // onChange={() => {
                    //   formikCtx.setFieldValue('review_comments_first_step', 'abc');
                    // }}
                    />
                  </Grid>
                </div>
                {/* {!isDisabled && */}
                <div>
                  <Grid item xs={5}>
                    <Typography className={classes.title}>
                      <div>Upload snips/file (optional)</div>
                      {/* <span className={classes.subTitle}>+Attach File</span> */}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.fileUploaderMain}>
                    <FileUploader
                      disabled={getImplementationData.step_one.is_step_one_completed ? true : false}
                      fileAction={(
                        <CustomButton
                          variant="contained"
                          actionType="primary"
                          disableMargin
                          disabled={getImplementationData.step_one.is_step_one_completed ? true : false}
                        >
                          <AddIcon />
                          <span>Attach File</span>
                        </CustomButton>
                      )}
                      step_one_id={null}
                      file_section={'review_delegation_file'}
                      role_id={null}
                      step_id={getImplementationData.step_one.id ? getImplementationData?.step_one.id : null}
                      requestId={formikCtx.values.request_id}
                      // getFiles={formikCtx.values.attachment.length > 0 && formikCtx.values.attachment.filter((obj: any) => obj.file_section !== 'signature')}
                      getFiles={getImplementationData.step_one.review_delegation_file}

                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                    <UploadSnippet
                      step_one_id={null}
                      file_section={'review_delegation_file'}
                      role_id={null}
                      step_id={getImplementationData.step_one.id ? getImplementationData?.step_one.id : null}
                      requestId={formikCtx.values.request_id}
                      // getFiles={formikCtx.values.attachment.length > 0 && formikCtx.values.attachment.filter((obj: any) => obj.file_section !== 'signature')}
                      getFiles={getImplementationData.step_one.review_delegation_file}
                    />
                  </Grid> */}
                </div>
                {/* } */}

                {!isDisabled && <div className={classes.markStep}>
                  <Grid item xs={12}>
                    <FormikControl
                      control="checkbox"
                      label="Mark this step complete"
                      name="mark_step_firststep"
                      required
                      disabled={isDisabled ? true : getImplementationData.step_one.is_step_one_completed ? true : false}
                      className={classes.modelCheckboxLabel}
                    // onChange={() => {
                    //   if (getImplementationData.step_one.is_step_one_completed) {
                    //     formikCtx.setFieldValue('mark_step_firststep', true)
                    //   }
                    // }}
                    />
                    <CustomButton
                      variant="contained"
                      actionType="primary"
                      disableMargin
                      // disabled={isDisabled}
                      onClick={() => {
                        handleSaveFirstStep();
                      }}
                      disabled={formikCtx.values.mark_step_firststep ? false : getImplementationData.step_one.is_step_one_completed ? false : true}
                    >
                      Save
                    </CustomButton>
                  </Grid>
                </div>}
              </Typography>

            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          <Accordion expanded={formikCtx.values.mark_step_firststep ? expanded === 'panel2' : false} onChange={handleChange(formikCtx.values.mark_step_firststep ? 'panel2' : '')} className={classes.gridTitle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
              className={classes.accordionSummary}
            >
              {/* <Typography className={classes.accTitle}>
                Role Group Implementation
                <span className={classes.astrik}>*</span>
              </Typography> */}
              <FieldLabel Title="Role Group Implementation" implementation={expanded === 'panel2'} tooltipMsg={accordian2} required />
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <Typography className={classes.taskList}>
                <span>
                  Click on each role group and complete the task list.
                </span>
              </Typography>

              <Grid container rowSpacing={3} columnSpacing={3}>
                {formikCtx.values.del_role_name_list.length > 0 && formikCtx.values.del_role_name_list.map((roleNameList: any, index: number) => (
                  <Grid
                    item
                    xs={2}
                    // key={index}
                    onClick={() => {
                      handleViewRole(roleNameList, index);
                    }}
                  >
                    <div className={classes.gridItem}>
                      <span className={classes.roleName}>
                        {roleNameList.role_name}
                      </span>
                      <div className={classes.pencilIcon}>
                        <PencilIconBlack style={{ background: "#FFEDCA", padding: '1px', borderRadius: '1px' }} />
                      </div>

                      {keysArray.includes(roleNameList.id) && <div className={classes.rightCheckIcon}>
                        <CheckIcon style={{ background: "#FFEDCA", padding: '1px', borderRadius: '1px' }} />
                      </div>}
                    </div>
                  </Grid>
                )
                  // return <div>
                  // </div>
                )}
              </Grid>
              {!isDisabled && keysArray.length === formikCtx.values.del_role_name_list.length && <div className={classes.markStep}>
                <Grid item xs={12}>
                  <FormikControl
                    control="checkbox"
                    label="Mark this step complete"
                    name="mark_step_secondstep"
                    required
                    disabled={formikCtx.values.is_implemented}
                    className={classes.modelCheckboxLabel}
                  />
                </Grid>
              </div>}
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid >
      <CustomModal
        centerButton={true}
        modalHeader={getModalHeader(modalAction.actionType)}
        open={modalOpen}
        onClose={handleClose}
        modalAction={modalActionHandler(modalAction.actionType)}
        modalContent={modalContentHandler(modalAction.actionType, roleNameListFromData)}
      />
      <CustomModal
        modalHeader='Note'
        open={accordianModal}
        onClose={handleCloseAccordianModal}
        modalAction={
          <CustomButton
            variant="contained"
            actionType="primary"
            disableMargin
            onClick={handleCloseAccordianModal}
          >
            Ok
          </CustomButton>
        }
        modalContent={
          <div>
            <div className={styleClasses.modalTextDiv}>
              This section cannot be completed until the Review and understand Delegation requirements section is completed
            </div>
          </div>
        }
      />
    </>
  );
}

export default Implementation;
