import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import {
    Formik,
    FormikConfig,
    FormikValues,
    FormikHelpers,
    FormikErrors,
    useFormikContext,

} from 'formik';
import {
    useHistory,
    Prompt,
    useLocation,
} from 'react-router-dom';
import Divider from '@mui/material/Divider';
import { useSnackbar } from 'notistack';
import { isEmpty, isEqual } from 'lodash';
import Footer from '../../footer';
import CustomButton from '../../button';
import CustomModal from '../../custom-modal';
import VerticalLinearStepper from '../../stepper';
import { FormikStepProps, IModalState, staticDifferenceData, stepsDataAdminAcountApprovedPending, stepsDataHybrid1, stepsDataHybrid2, stepsDataHybrid3, stepsDataServiceAcountApprovedPending1, stepsDataServiceAcountApprovedPending2, stepsDataServiceAcountApprovedPending3, stepsDataTestAcountApprovedPending } from './formikContainerUtils';
import { formikContainerStyles } from '../useStyles';
import { cancelPar, postNewRequestPar, updateStatusOfPar, postParFieldHistoryData, postCloudAad, postOnpremAd } from '../../../api-services/par';
import {
    stepsData,
    stepsDataAadOnprem,
    stepsDataAadCloud,
    stepsDataAd,
    stepsDataPam
} from './formikContainerUtils';
import { format, isDate } from 'date-fns';
import { request } from 'http';
import { useSelector } from 'react-redux';
import { RootState } from '../../../appStore/store';
import { postFieldCommentsPar } from '../../../api-services/par';
import { loginSyncUpData } from '../../../appStore/reducer/loginReducer';
import { emptySchemaCheck, fieldsOfSection } from './formikContainerUtils';
import { postOnpremAad, getPostOnpremAad } from '../../../api-services/par';
import { updateImplementerStatus } from '../../../api-services/pending-implementation';
import { routeCheck, routeCheckForAdminSummary, routeCheckForImplementationSummary, routCheckApprovalSummary, routeCheckForPar, routeCheckForImplementationSummarypar } from './formSteps/routingConditional';
import { routeCheckForApprovalSummary } from '../formikContainerDelegation/formsSteps/routingConditional';




export function FormikStepper({
    children,
    requestId,
    requestData,
    ...props
}: FormikConfig<FormikValues> & {
    requestId: string,
    requestData: any,
}) {
    const { submitParCommentsData } = useSelector((state: RootState) => state);
    const { aadImplementationOnpremList } = useSelector((state: RootState) => state);
    const { cloudImplementationList } = useSelector((state: RootState) => state);
    const { adImplementationOnpremList } = useSelector((state: RootState) => state);
    const { chathistorydata } = useSelector((state: RootState) => state.chatFieldHistoryData);
    const { loginuserdata, loginsyncupdata } = useSelector((state: RootState) => state.loginData);

    const [schemaCheck, setSchemaCheck] = useState(false);
    const epmtyObjectRemediation = {
        Account_requestor_details: '',
        Job_description: '',
        Account_owner_details: '',
        third_party_comment: '',
        manager_details_comment: '',
        ServiceNow_ticket_number: '',
        Domain_comment: '',
        Account_justification_comment: '',
    }
    const formikCtx: any = useFormikContext();
    let modalClickCheck = {
        rejectStatus: false,
        remediationStatus: false,
        approveStatus: false,
    }

    const formatDate = (dateValue: any) => (dateValue && isDate(new Date(dateValue)) ? format(new Date(dateValue), 'yyyy-MM-dd') : '');
    const history = useHistory();
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();

    const [modalData, setModalData] = useState<IModalState>({
        show: false,
        modalHeader: '',
        modalContent: null,
        modalActions: null,
    });
    const styleClasses = formikContainerStyles();
    const childrenArray = React.Children.toArray(children) as React.ReactElement<FormikStepProps>[];
    const [activeStep, setActiveStep] = useState(0);
    const [getImplementationData, setGetImplementationData] = useState({});
    const currentChild = childrenArray[activeStep];
    const [isAPIProgress, setIsAPIProgress] = useState({
        saving: false,
        canceling: false,
    });
    function isLastStep() {
        return activeStep === childrenArray.length - 1;
    }

    const checkIsDisabledImplementation = () => {
        if (routeCheckForImplementationSummarypar() || routeCheckForAdminSummary() || routCheckApprovalSummary()) {
            return true;
        } else return false;
    };
    const implementationCheckForParStepData = () => {

        if (checkIsDisabledImplementation() && requestData.status_label == 'Approved - Pending Implementation' && requestData.par_domain === 'insidemedia.onmicrosoft.com') {
            return stepsDataServiceAcountApprovedPending3;
        }
        else if (checkIsDisabledImplementation() && requestData.status_label == 'Approved - Pending Implementation' && requestData.par_domain === 'Hybrid (AD/Azure)' && requestData.implementer_type === 'Cloud implementation engineer') {
            return stepsDataServiceAcountApprovedPending2;
        }
        else if (checkIsDisabledImplementation() && requestData.status_label == 'Approved - Pending Implementation' && requestData.par_domain === 'Hybrid (AD/Azure)' && requestData.is_ad_task_list_completed) {
            return stepsDataServiceAcountApprovedPending1;
        }
        else if (checkIsDisabledImplementation() && requestData.status_label == 'Approved - Pending Implementation' && requestData.request_purpose == 'Connect to / manage a server or computer') {
            return stepsDataAdminAcountApprovedPending;
        }
        else if (checkIsDisabledImplementation() && requestData.status_label == 'Approved - Pending Implementation' && requestData.request_purpose == 'Test a service / product as a part of project') {
            return stepsDataTestAcountApprovedPending;
        }
        else if (checkIsDisabledImplementation() && requestData.par_domain == 'Hybrid (AD/Azure)') {
            return stepsDataHybrid3;
        } else if (requestData.par_domain == 'Hybrid (AD/Azure)' && requestData.implementer_type === 'On-premise implementation engineer' && !requestData.is_ad_task_list_completed) {
            return stepsDataHybrid1;
        } else if (requestData.par_domain == 'Hybrid (AD/Azure)' && requestData.implementer_type === 'On-premise implementation engineer' && requestData.is_ad_task_list_completed) {
            return stepsDataHybrid2;
        } else if ((requestData.implementer_type === 'Cloud implementation engineer') && (requestData.par_domain === 'Hybrid (AD/Azure)')) {
            return stepsDataHybrid3;
        }
        else if (requestData.status_label === 'Completed' && (requestData.par_domain === 'insidemedia.onmicrosoft.com')) {
            return stepsDataAadCloud;
        }
        else if (requestData.status_label === 'Completed' && (requestData.par_domain !== 'insidemedia.onmicrosoft.com')) {
            return stepsDataAd;
        } else if ((requestData.par_domain === 'insidemedia.onmicrosoft.com') && requestData.implementer_type === 'On-premise implementation engineer') {
            return stepsDataAadOnprem;
        } else if (((requestData.par_domain !== 'insidemedia.onmicrosoft.com') && requestData.implementer_type === 'On-premise implementation engineer')) {
            return stepsDataAd;
        } else if ((requestData.implementer_type === 'Cloud implementation engineer') && (requestData.par_domain === 'insidemedia.onmicrosoft.com')) {
            return stepsDataAadCloud;
        }
        // else if ((requestData.del_request_id !== null) && (requestData.implementer_type === 'PAM implementation engineer') && (requestData.par_domain !== 'insidemedia.onmicrosoft.com')) {
        //     return stepsDataPam;
        // }
        else return stepsData;
    }


    const historyDataOfForm = JSON.parse(localStorage.getItem('historyDataOfMainSchema') || '');

    useEffect(() => {
        if (routeCheckForImplementationSummary() && requestData.par_domain === 'insidemedia.onmicrosoft.com' && requestData.implementer_type === 'On-premise implementation engineer') {

            setModalData({
                show: true,
                modalHeader: 'Note',
                modalContent:
                    (
                        <div>
                            <div className={styleClasses.modalTextDiv}>
                                Please review this Privileged Access Request from beginning to end and raise the ServiceNow ticket to request implementation by Cloud Implementation Engineer.
                            </div>
                        </div>
                    ),
                modalActions: (
                    <div className={styleClasses.modalActionDiv}>
                        <CustomButton
                            variant="contained"
                            actionType="primary"
                            disableMargin
                            onClick={handleModalClose}
                        >
                            OK
                        </CustomButton>
                    </div>
                )
            })
        } else if (routeCheckForImplementationSummary() && requestData.par_domain !== 'insidemedia.onmicrosoft.com' && requestData.implementer_type === 'On-premise implementation engineer') {

            setModalData({
                show: true,
                modalHeader: 'Note',
                modalContent:
                    (
                        <div>
                            <div className={styleClasses.modalTextDiv}>
                                Please review this Privileged Access Request from beginning to end. This will enable full understanding of the requirements for implementation.
                            </div>
                            <div className={styleClasses.modalTextDiv}>
                                Comments, screenshots and scripts can be added during the implementation steps that follow.
                            </div>
                        </div>
                    ),
                modalActions: (
                    <div className={styleClasses.modalActionDiv}>
                        <CustomButton
                            variant="contained"
                            actionType="primary"
                            disableMargin
                            onClick={handleModalClose}
                        >
                            OK
                        </CustomButton>
                    </div>
                )
            })
        } else if (routeCheckForImplementationSummary() && (requestData.implementer_type === 'Cloud implementation engineer') && (requestData.par_domain === 'insidemedia.onmicrosoft.com')) {
            setModalData({
                show: true,
                modalHeader: 'Note',
                modalContent:
                    (
                        <div>
                            <div className={styleClasses.modalTextDiv}>
                                Please review this Privileged Access Request from beginning to end. This will enable full understanding of the requirements for implementation.
                            </div>
                        </div>
                    ),
                modalActions: (
                    <div className={styleClasses.modalActionDiv}>
                        <CustomButton
                            variant="contained"
                            actionType="primary"
                            disableMargin
                            onClick={handleModalClose}
                        >
                            OK
                        </CustomButton>
                    </div>
                )
            })
        }
        //  else if ((requestData.del_request_id !== null) && (requestData.implementer_type === 'PAM implementation engineer') && (requestData.par_domain !== 'insidemedia.onmicrosoft.com')) {
        //     setModalData({
        //         show: true,
        //         modalHeader: 'Note',
        //         modalContent:
        //             (
        //                 <div>
        //                     <div className={styleClasses.modalTextDiv}>
        //                         Please review this PAR from Step 1 to Step 4 and kindly complete PAM implementation task list in Step 5.
        //                     </div>
        //                 </div>
        //             ),
        //         modalActions: (
        //             <div className={styleClasses.modalActionDiv}>
        //                 <CustomButton
        //                     variant="contained"
        //                     actionType="primary"
        //                     disableMargin
        //                     onClick={handleModalClose}
        //                 >
        //                     OK
        //                 </CustomButton>
        //             </div>
        //         )
        //     })
        // }
    }, [])
    const postBodyData = [
        // @ts-ignore
        ...submitParCommentsData[0].Account_requestor_details,
        // @ts-ignore
        ...submitParCommentsData[1].Account_owner_details,
        // @ts-ignore
        ...submitParCommentsData[2].Job_description,
        // @ts-ignore
        ...submitParCommentsData[3].Manager_details,
        // @ts-ignore
        ...submitParCommentsData[4].Third_Manager_Party_details,
        // @ts-ignore
        ...submitParCommentsData[5].ServiceNow_ticket_number,
        // @ts-ignore
        ...submitParCommentsData[6].Account_justification,
        // @ts-ignore
        ...submitParCommentsData[7].Domain,
    ]

    const aadOnpremiseClose = () => {
        // enqueueSnackbar(`PAR ${props.initialValues.request_number} Implemented successfully`, { variant: 'success' });
        history.push("/myImplementationTasks");
        handleModalClose();
    }
    // const adOnpremiseClose = () => {
    //     history.push("/myImplementationTasks");
    //     handleModalClose();
    // }
    const checkIsDisabled = () => !(props.initialValues.status_label === 'Draft' || props.initialValues.status_label !== 'Pending Approval') || routCheckApprovalSummary() || routeCheckForAdminSummary() || props.initialValues.status_label === 'Completed' || props.initialValues.status_label === "Approved - Pending Implementation" || props.initialValues.status_label === 'Rejected';

    // const checkIsDisabled = () => !(props.initialValues.status_label === 'Draft'
    //     || props.initialValues.status_label === 'Remediation Requested');

    // save logic start
    const handleSave = (
        values: FormikValues,
        errors: FormikErrors<FormikValues>,
        setFieldValue: (
            _fieldName: string,
            _value: unknown) => void,
        validateForm: (_values: FormikValues) => any,
        resetForm: (_values: FormikValues) => void,
        options: { shouldExit: boolean, switchStep: number },
    ) => {

        if (routeCheckForImplementationSummary()) {
            if ((requestData.implementer_type === 'Cloud implementation engineer') && (requestData.par_domain === 'Hybrid (AD/Azure)')) {
                const postBody = {
                    par_id: values.request_id,
                    cloud_engineer_id: cloudImplementationList[0].cloud_engineer_id ? cloudImplementationList[0].cloud_engineer_id : null,
                    review_account_requirements: values.review_account_requirements,
                    is_step_one_completed: values.is_step_one_completed_cloud,
                    enter_user_logon_name: values.enter_user_logon_name,
                    is_user_logon_name_entered: values.is_user_logon_name_entered,
                    enter_proposed_user_name: values.enter_proposed_user_name,
                    is_proposed_user_name_entered: values.is_proposed_user_name_entered,
                    enter_display_name: values.enter_display_name,
                    is_display_name_entered: values.is_display_name_entered,
                    enter_first_name_and_last_name: values.enter_first_name_and_last_name,
                    copy_password_for_end_user: values.copy_password_for_end_user,
                    add_usage_location: values.add_usage_location,
                    entered_job_info_section: values.entered_job_info_section,
                    user_created_or_exist: values.user_created_or_exist,
                    is_user_added: values.is_user_added,
                    assign_license: values.assign_license,
                    assign_admin_role_to_account: values.assign_admin_role_to_account,
                    enroll_pim: values.enroll_pim,
                    shared_account_details_groupm: values.shared_account_details_groupm,
                    is_step_two_completed: false,
                    is_implemented: false
                }

                postCloudAad(postBody).then((res) => {
                    enqueueSnackbar(`PAR ${props.initialValues.request_number} has been saved successfully`, {
                        variant: "success",
                    });
                }).catch((error => error))

            } else if ((requestData.par_domain === 'insidemedia.onmicrosoft.com') && requestData.implementer_type === 'On-premise implementation engineer') {
                const postBody = {
                    par_id: values.request_id,
                    on_premise_id: aadImplementationOnpremList[0].on_premise_id ? aadImplementationOnpremList[0].on_premise_id : null,
                    provide_service_number: values.provide_service_number_aad_onPrem,
                    is_step_complete: values.mark_step_aad_onPrem,
                    is_implemented: false
                }
                postOnpremAad(postBody).then((Res) => {
                    enqueueSnackbar(`PAR ${props.initialValues.request_number} has been saved successfully`, {
                        variant: "success",
                    });
                }).catch(error => error);
            } else if (((requestData.par_domain !== 'insidemedia.onmicrosoft.com') && requestData.implementer_type === 'On-premise implementation engineer')) {

                const postBody = {
                    par_id: values.request_id,
                    on_premise_ad_id: adImplementationOnpremList[0].on_premise_ad_id ? adImplementationOnpremList[0].on_premise_ad_id : null,
                    login_to_adaxes: values.login_to_adaxes,
                    is_select_create_admin: values.is_select_create_admin,
                    enter_user_logon_name_in_adaxes: values.enter_user_logon_name_in_adaxes,
                    is_entered_user_logon_name: values.is_entered_user_logon_name,
                    enter_correct_role_group_name: values.enter_correct_role_group_name,
                    is_correct_role_group_entered: values.is_correct_role_group_entered,
                    communicate_password_securely: values.communicate_password_securely,
                    is_step_complete: values.is_step_complete_ad,
                    is_implemented: false
                }
                postOnpremAd(postBody).then((res) => {
                    // enqueueSnackbar(`PAR ${props.initialValues.request_number} has been saved successfully`, {
                    //     variant: "success",
                    // });
                }).catch((error => error))
            } else if ((requestData.implementer_type === 'Cloud implementation engineer') && (requestData.par_domain === 'insidemedia.onmicrosoft.com')) {
                const postBody = {
                    par_id: values.request_id,
                    cloud_engineer_id: cloudImplementationList[0].cloud_engineer_id ? cloudImplementationList[0].cloud_engineer_id : null,
                    review_account_requirements: values.review_account_requirements,
                    is_step_one_completed: values.is_step_one_completed_cloud,
                    enter_user_logon_name: values.enter_user_logon_name,
                    is_user_logon_name_entered: values.is_user_logon_name_entered,
                    enter_proposed_user_name: values.enter_proposed_user_name,
                    is_proposed_user_name_entered: values.is_proposed_user_name_entered,
                    enter_display_name: values.enter_display_name,
                    is_display_name_entered: values.is_display_name_entered,
                    enter_first_name_and_last_name: values.enter_first_name_and_last_name,
                    copy_password_for_end_user: values.copy_password_for_end_user,
                    add_usage_location: values.add_usage_location,
                    entered_job_info_section: values.entered_job_info_section,
                    user_created_or_exist: values.user_created_or_exist,
                    is_user_added: values.is_user_added,
                    assign_license: values.assign_license,
                    assign_admin_role_to_account: values.assign_admin_role_to_account,
                    enroll_pim: values.enroll_pim,
                    shared_account_details_groupm: values.shared_account_details_groupm,
                    is_step_two_completed: false,
                    is_implemented: false
                }

                postCloudAad(postBody).then((res) => {
                    enqueueSnackbar(`PAR ${props.initialValues.request_number} has been saved successfully`, {
                        variant: "success",
                    });
                }).catch((error => error))
            } else if ((requestData.del_request_id !== null) && (requestData.implementer_type === 'PAM implementation engineer') && (requestData.par_domain !== 'insidemedia.onmicrosoft.com')) {
                return stepsDataPam;
            } else return stepsData;
            return;
        }

        if (props.initialValues.remediation_count > 0) {
            const changedValues = Object.keys(values).filter(key => values[key] !== historyDataOfForm[key])

            const newArray = changedValues.filter(v => !staticDifferenceData.includes(v))

            let finalChangedData: any = [];
            newArray.length > 0 && newArray.map((item) => {
                finalChangedData.push(
                    {
                        update_type: emptySchemaCheck.get(item),
                        section: fieldsOfSection.get(item),
                        sub_section: item,
                        value: historyDataOfForm[item],
                    }
                )

            })

            const historyPostBody = {
                par_id: requestId,
                is_submitted: false,
                data: finalChangedData

            }
            if (finalChangedData.length > 0) {
                postParFieldHistoryData(historyPostBody).then((response) => {
                    console.log(response)
                }).catch(error => error);
            }

        }

        if (isEqual(historyDataOfForm, values))

            setFieldValue('onSave', true);
        async () => {
            await Promise.resolve();
        };
        // if (!isEmpty(errors)) {
        //     console.log('eeeee', errors);
        //     setFieldValue("onSave", false);
        //     return;
        // } else {
        //     !routeCheckForImplementationSummary() && !routeCheck() && validateForm(values).then((errors: FormikErrors<FormikValues>) => {
        //         postNewRequestPar({ ...values, status: '1', request_id: requestId, event: requestId !== '' ? 'update' : 'create', next_priority: null, request_type: 2, par_date_raised: formatDate(values.par_date_raised) }).then((res) => {
        //             setIsAPIProgress((prevState) => ({ ...prevState, saving: true }));
        //             // if (options.switchStep && activeStep !== options.switchStep && isEmpty(errors)) {
        //             if (res.status) {

        //                 // enqueueSnackbar(`PAR #${requestId} is saved successfully`, { variant: 'success' })

        //                 if (options.shouldExit) {
        //                     resetForm({ values: res.data });
        //                     history.push('/par');
        //                 } else if (options.switchStep && activeStep !== options.switchStep && isEmpty(errors)) {
        //                     setActiveStep(options.switchStep);
        //                 }
        //                 const formValues = {
        //                     ...values,
        //                 };
        //                 resetForm({ values: formValues });
        //             } else {
        //                 enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        //             }
        //             // } else {
        //             //     enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        //             // }


        //             setIsAPIProgress((prevState) => ({ ...prevState, saving: false }));
        //         }).catch((err) => {
        //             console.error(err);
        //             enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        //             setIsAPIProgress((prevState) => ({ ...prevState, saving: false }));
        //         });
        //         setFieldValue('onSave', false);
        //     });
        // }
        validateForm(values).then((errors: FormikErrors<FormikValues>) => {
            if (!isEmpty(errors)) {
                setFieldValue("onSave", false);
                return;
            } else {
                postNewRequestPar({
                    ...values,
                    request_id: requestId,
                    event: requestId !== '' ? 'update' : 'create',
                    next_priority: null,
                    request_type: 2,
                    par_date_raised: formatDate(values.par_date_raised),
                    status: values.remediation_count === 0 ? "1" : "7",
                    del_signed_by: ''
                }).then((res) => {
                    setIsAPIProgress((prevState) => ({ ...prevState, saving: true }));
                    if (res.status) {
                        if (options.shouldExit) {
                            resetForm({ values: res.data });
                            history.push('/par');
                        }
                        else if (isEmpty(errors)) {
                            enqueueSnackbar(`PAR ${requestData.request_number} has been saved successfully`, { variant: 'success' })
                        }
                        const formValues = {
                            ...values,
                        };

                        resetForm({ values: formValues });
                    } else {
                        enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
                    }
                    setIsAPIProgress((prevState) => ({ ...prevState, saving: false }));
                }).catch((err) => {
                    console.error(err);
                    enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
                    setIsAPIProgress((prevState) => ({ ...prevState, saving: false }));
                });
                setFieldValue('onSave', false);
            }
        });
    };
    // save logic end  End

    // start of next button logic
    const handleSaveOnNext = (
        // dirty: boolean,
        values: FormikValues,
        errors: FormikErrors<FormikValues>,
        setFieldValue: (
            _fieldName: string,
            _value: unknown) => void,
        validateForm: (_values: FormikValues) => any,
        resetForm: (_values: FormikValues) => void,
        options: { shouldExit: boolean, switchStep: number },
    ) => {

        if (routeCheckForImplementationSummary()) {
            if ((requestData.par_domain === 'insidemedia.onmicrosoft.com') && requestData.implementer_type === 'On-premise implementation engineer') {
                const postBody = {
                    par_id: values.request_id,
                    on_premise_id: aadImplementationOnpremList[0].on_premise_id ? aadImplementationOnpremList[0].on_premise_id : null,
                    provide_service_number: values.provide_service_number_aad_onPrem,
                    is_step_complete: values.mark_step_aad_onPrem,
                    is_implemented: false
                }
                postOnpremAad(postBody).then((Res) => {
                    enqueueSnackbar(`PAR ${props.initialValues.request_number} has been saved successfully`, {
                        variant: "success",
                    });
                }).catch(error => error);
            } else if (((requestData.par_domain !== 'insidemedia.onmicrosoft.com') && requestData.implementer_type === 'On-premise implementation engineer')) {

                const postBody = {
                    par_id: values.request_id,
                    on_premise_ad_id: adImplementationOnpremList[0].on_premise_ad_id ? adImplementationOnpremList[0].on_premise_ad_id : null,
                    login_to_adaxes: values.login_to_adaxes,
                    is_select_create_admin: values.is_select_create_admin,
                    enter_user_logon_name_in_adaxes: values.enter_user_logon_name_in_adaxes,
                    is_entered_user_logon_name: values.is_entered_user_logon_name,
                    enter_correct_role_group_name: values.enter_correct_role_group_name,
                    is_correct_role_group_entered: values.is_correct_role_group_entered,
                    communicate_password_securely: values.communicate_password_securely,
                    is_step_complete: values.is_step_complete_ad,
                    is_implemented: false
                }
                postOnpremAd(postBody).then((res) => {
                    enqueueSnackbar(`PAR ${props.initialValues.request_number} has been saved successfully`, {
                        variant: "success",
                    });
                }).catch((error => error))
            } else if ((requestData.implementer_type === 'Cloud implementation engineer') && (requestData.par_domain === 'insidemedia.onmicrosoft.com')) {
                const postBody = {
                    par_id: values.request_id,
                    cloud_engineer_id: cloudImplementationList[0].cloud_engineer_id ? cloudImplementationList[0].cloud_engineer_id : null,
                    review_account_requirements: values.review_account_requirements,
                    is_step_one_completed: values.is_step_one_completed_cloud,
                    enter_user_logon_name: values.enter_user_logon_name,
                    is_user_logon_name_entered: values.is_user_logon_name_entered,
                    enter_proposed_user_name: values.enter_proposed_user_name,
                    is_proposed_user_name_entered: values.is_proposed_user_name_entered,
                    enter_display_name: values.enter_display_name,
                    is_display_name_entered: values.is_display_name_entered,
                    enter_first_name_and_last_name: values.enter_first_name_and_last_name,
                    copy_password_for_end_user: values.copy_password_for_end_user,
                    add_usage_location: values.add_usage_location,
                    entered_job_info_section: values.entered_job_info_section,
                    user_created_or_exist: values.user_created_or_exist,
                    is_user_added: values.is_user_added,
                    assign_license: values.assign_license,
                    assign_admin_role_to_account: values.assign_admin_role_to_account,
                    enroll_pim: values.enroll_pim,
                    shared_account_details_groupm: values.shared_account_details_groupm,
                    is_step_two_completed: false,
                    is_implemented: false
                }
                postCloudAad(postBody).then((res) => {
                    enqueueSnackbar(`PAR ${props.initialValues.request_number} has been saved successfully`, {
                        variant: "success",
                    });
                }).catch((error => error))
            } else if ((requestData.del_request_id !== null) && (requestData.implementer_type === 'PAM implementation engineer') && (requestData.par_domain === 'insidemedia.onmicrosoft.com')) {
                return stepsDataPam;
            } else return stepsData;
        }

        if (props.initialValues.remediation_count > 0) {
            const changedValues = Object.keys(values).filter(key => values[key] !== historyDataOfForm[key])

            const newArray = changedValues.filter(v => !staticDifferenceData.includes(v))

            let finalChangedData: any = [];
            newArray.length > 0 && newArray.map((item) => {
                finalChangedData.push(
                    {
                        update_type: emptySchemaCheck.get(item),
                        section: fieldsOfSection.get(item),
                        sub_section: item,
                        value: historyDataOfForm[item],
                    }
                )

            })

            const historyPostBody = {
                par_id: requestId,
                is_submitted: false,
                data: finalChangedData

            }
            if (finalChangedData.length > 0) {
                postParFieldHistoryData(historyPostBody).then((response) => {
                    console.log(response)
                }).catch(error => error);
            }

        }

        if (isEqual(historyDataOfForm, values))

            setFieldValue('onSave', true);
        async () => {
            await Promise.resolve();
        };
        validateForm(values).then((errors: FormikErrors<FormikValues>) => {
            if (!isEmpty(errors)) {
                setFieldValue("onSave", false);
                return;
            } else {

                postNewRequestPar({
                    ...values,
                    request_id: requestId,
                    event: requestId !== '' ? 'update' : 'create',
                    next_priority: null,
                    request_type: 2,
                    par_date_raised: formatDate(values.par_date_raised),
                    status: values.remediation_count === 0 ? "1" : "7",
                    del_signed_by: ''
                }).then((res) => {
                    setIsAPIProgress((prevState) => ({ ...prevState, saving: true }));
                    if (res.status) {
                        if (options.shouldExit) {
                            resetForm({ values: res.data });
                            history.push('/par');
                        } else if (options.switchStep && activeStep !== options.switchStep && isEmpty(errors)) {
                            setActiveStep(options.switchStep);
                        }
                        const formValues = {
                            ...values,
                        };
                        resetForm({ values: formValues });
                    } else {
                        enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
                    }
                    setIsAPIProgress((prevState) => ({ ...prevState, saving: false }));
                }).catch((err) => {
                    console.error(err);
                    enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
                    setIsAPIProgress((prevState) => ({ ...prevState, saving: false }));
                });
                setFieldValue('onSave', false);
            }
        });
    };
    // end of next button logic

    // start of implementer submit api
    const ConfirmImplementationHandler = (values: any, erros: any) => {
        if (requestData.par_domain == 'Hybrid (AD/Azure)' && requestData.implementer_type === 'On-premise implementation engineer' && requestData.is_ad_task_list_completed) {
            const postBody = {
                par_id: values.request_id,
                on_premise_id: aadImplementationOnpremList[0].on_premise_id ? aadImplementationOnpremList[0].on_premise_id : null,
                provide_service_number: values.provide_service_number_aad_onPrem,
                is_step_complete: values.mark_step_aad_onPrem,
                is_implemented: true
            }
            statusUpdateOfImplementationTaskList()
            postOnpremAad(postBody).then((Res) => {
                setModalData({
                    show: true,
                    modalHeader: 'Success',
                    modalContent:
                        (
                            <div>
                                <div className={styleClasses.modalTextDiv}>
                                    This step for PAR {props.initialValues.request_number} has been completed successfully. This request will be now be sent to the Cloud Implementation Engineer for implementation.
                                </div>
                            </div>
                        ),
                    modalActions: (
                        <div className={styleClasses.modalActionDiv}>
                            <CustomButton
                                variant="contained"
                                actionType="primary"
                                disableMargin
                                onClick={aadOnpremiseClose}
                            >
                                OK
                            </CustomButton>
                        </div>
                    )
                })
            }).catch(error => error);

        } else if (requestData.par_domain == 'Hybrid (AD/Azure)' && requestData.implementer_type === 'On-premise implementation engineer' && !requestData.is_ad_task_list_completed) {
            const postBody = {
                par_id: values.request_id,
                on_premise_ad_id: adImplementationOnpremList[0].on_premise_ad_id ? adImplementationOnpremList[0].on_premise_ad_id : null,
                login_to_adaxes: values.login_to_adaxes,
                is_select_create_admin: values.is_select_create_admin,
                enter_user_logon_name_in_adaxes: values.enter_user_logon_name_in_adaxes,
                is_entered_user_logon_name: values.is_entered_user_logon_name,
                enter_correct_role_group_name: values.enter_correct_role_group_name,
                is_correct_role_group_entered: values.is_correct_role_group_entered,
                communicate_password_securely: values.communicate_password_securely,
                is_step_complete: values.is_step_complete_ad,
                is_implemented: true
            }
            statusUpdateOfImplementationTaskList()
            postOnpremAd(postBody).then((res) => {
                setModalData({
                    show: true,
                    modalHeader: 'Success',
                    modalContent:
                        (
                            <div>
                                <div className={styleClasses.modalTextDiv}>
                                    Implementation task list for PAR {props.initialValues.request_number} has been completed successfully.
                                </div>
                            </div>
                        ),
                    modalActions: (
                        <div className={styleClasses.modalActionDiv}>
                            <CustomButton
                                variant="contained"
                                actionType="primary"
                                disableMargin
                                onClick={aadOnpremiseClose}
                            >
                                OK
                            </CustomButton>
                        </div>
                    )
                })
            }).catch(error => error);

        } else if (requestData.par_domain == 'Hybrid (AD/Azure)' && requestData.implementer_type === 'Cloud implementation engineer') {
            const postBody = {
                par_id: values.request_id,
                cloud_engineer_id: cloudImplementationList[0].cloud_engineer_id ? cloudImplementationList[0].cloud_engineer_id : null,
                review_account_requirements: values.review_account_requirements,
                is_step_one_completed: values.is_step_one_completed_cloud,
                enter_user_logon_name: values.enter_user_logon_name,
                is_user_logon_name_entered: values.is_user_logon_name_entered,
                enter_proposed_user_name: values.enter_proposed_user_name,
                is_proposed_user_name_entered: values.is_proposed_user_name_entered,
                enter_display_name: values.enter_display_name,
                is_display_name_entered: values.is_display_name_entered,
                enter_first_name_and_last_name: values.enter_first_name_and_last_name,
                copy_password_for_end_user: values.copy_password_for_end_user,
                add_usage_location: values.add_usage_location,
                entered_job_info_section: values.entered_job_info_section,
                user_created_or_exist: values.user_created_or_exist,
                is_user_added: values.is_user_added,
                assign_license: values.assign_license,
                assign_admin_role_to_account: values.assign_admin_role_to_account,
                enroll_pim: values.enroll_pim,
                shared_account_details_groupm: values.shared_account_details_groupm,
                is_step_two_completed: true,
                is_implemented: true
            }
            statusUpdateOfImplementationTaskList()
            postCloudAad(postBody).then((Res) => {
                // enqueueSnackbar(`PAR ${props.initialValues.request_number} has been completed successfully`, {
                //     variant: "success",
                // });
                history.push("/myImplementationTasks");
            }).catch(error => error);

        } else if ((requestData.par_domain === 'insidemedia.onmicrosoft.com') && requestData.implementer_type === 'On-premise implementation engineer') {

            const postBody = {
                par_id: values.request_id,
                on_premise_id: aadImplementationOnpremList[0].on_premise_id ? aadImplementationOnpremList[0].on_premise_id : null,
                provide_service_number: values.provide_service_number_aad_onPrem,
                is_step_complete: values.mark_step_aad_onPrem,
                is_implemented: true
            }
            statusUpdateOfImplementationTaskList()
            postOnpremAad(postBody).then((Res) => {
                setModalData({
                    show: true,
                    modalHeader: 'Success',
                    modalContent:
                        (
                            <div>
                                <div className={styleClasses.modalTextDiv}>
                                    This step for PAR {props.initialValues.request_number} has been completed successfully. This request will be now be sent to the Cloud Implementation Engineer for implementation.
                                </div>
                            </div>
                        ),
                    modalActions: (
                        <div className={styleClasses.modalActionDiv}>
                            <CustomButton
                                variant="contained"
                                actionType="primary"
                                disableMargin
                                onClick={aadOnpremiseClose}
                            >
                                OK
                            </CustomButton>
                        </div>
                    )
                })
            }).catch(error => error);
        } else if (((requestData.par_domain !== 'insidemedia.onmicrosoft.com') && requestData.implementer_type === 'On-premise implementation engineer')) {

            const postBody = {
                par_id: values.request_id,
                on_premise_ad_id: adImplementationOnpremList[0].on_premise_ad_id ? adImplementationOnpremList[0].on_premise_ad_id : null,
                login_to_adaxes: values.login_to_adaxes,
                is_select_create_admin: values.is_select_create_admin,
                enter_user_logon_name_in_adaxes: values.enter_user_logon_name_in_adaxes,
                is_entered_user_logon_name: values.is_entered_user_logon_name,
                enter_correct_role_group_name: values.enter_correct_role_group_name,
                is_correct_role_group_entered: values.is_correct_role_group_entered,
                communicate_password_securely: values.communicate_password_securely,
                is_step_complete: values.is_step_complete_ad,
                is_implemented: true
            }
            statusUpdateOfImplementationTaskList()
            postOnpremAd(postBody).then((res) => {
                setModalData({
                    show: true,
                    modalHeader: 'Success',
                    modalContent:
                        (
                            <div>
                                <div className={styleClasses.modalTextDiv}>
                                    Implementation task list for PAR {props.initialValues.request_number} has been completed successfully.
                                </div>
                            </div>
                        ),
                    modalActions: (
                        <div className={styleClasses.modalActionDiv}>
                            <CustomButton
                                variant="contained"
                                actionType="primary"
                                disableMargin
                                onClick={aadOnpremiseClose}
                            >
                                OK
                            </CustomButton>
                        </div>
                    )
                })
            }).catch(error => error);
        } else if ((requestData.implementer_type === 'Cloud implementation engineer') && (requestData.par_domain === 'insidemedia.onmicrosoft.com')) {

            const postBody = {
                par_id: values.request_id,
                cloud_engineer_id: cloudImplementationList[0].cloud_engineer_id ? cloudImplementationList[0].cloud_engineer_id : null,
                review_account_requirements: values.review_account_requirements,
                is_step_one_completed: values.is_step_one_completed_cloud,
                enter_user_logon_name: values.enter_user_logon_name,
                is_user_logon_name_entered: values.is_user_logon_name_entered,
                enter_proposed_user_name: values.enter_proposed_user_name,
                is_proposed_user_name_entered: values.is_proposed_user_name_entered,
                enter_display_name: values.enter_display_name,
                is_display_name_entered: values.is_display_name_entered,
                enter_first_name_and_last_name: values.enter_first_name_and_last_name,
                copy_password_for_end_user: values.copy_password_for_end_user,
                add_usage_location: values.add_usage_location,
                entered_job_info_section: values.entered_job_info_section,
                user_created_or_exist: values.user_created_or_exist,
                is_user_added: values.is_user_added,
                assign_license: values.assign_license,
                assign_admin_role_to_account: values.assign_admin_role_to_account,
                enroll_pim: values.enroll_pim,
                shared_account_details_groupm: values.shared_account_details_groupm,
                is_step_two_completed: true,
                is_implemented: true
            }
            statusUpdateOfImplementationTaskList()
            postCloudAad(postBody).then((Res) => {
                // enqueueSnackbar(`PAR ${props.initialValues.request_number} has been completed successfully`, {
                //     variant: "success",
                // });
                history.push("/myImplementationTasks");
            }).catch(error => error);
        }
        else if ((requestData.del_request_id !== null) && (requestData.implementer_type === 'PAM implementation engineer') && (requestData.par_domain !== 'insidemedia.onmicrosoft.com')) {
            return stepsDataPam;
        } else return stepsData;
    }
    // end of implementer submit api

    // start of implementation status update
    const statusUpdateOfImplementationTaskList = () => {
        if ((requestData.request_purpose !== 'Connect to / manage a server or computer' || 'Test a service / product as a part of project') && requestData.par_domain === 'ad.insidemedia.net' && requestData.implementer_type === 'On-premise implementation engineer') {
            const postBodyStatusUpdate = {
                request_id: requestId,
                status: '8',
                request_type: 'PAR'
            }
            updateImplementerStatus(postBodyStatusUpdate).then((res) => {
                enqueueSnackbar(`Implementation request ${props.initialValues.request_number} completed successfully`, {
                    variant: "success",
                });
                // history.push("/myImplementationTasks");
            }).catch((err) => err);
        } else if (requestData.par_domain === 'Hybrid (AD/Azure)' && requestData.implementer_type === 'On-premise implementation engineer' && requestData.is_ad_task_list_completed) {
            const postBodyStatusUpdate = {
                request_id: requestId,
                status: '3',
                request_type: 'PAR'
            }
            updateImplementerStatus(postBodyStatusUpdate).then((res) => {
                enqueueSnackbar(`Implementation request ${props.initialValues.request_number} completed successfully`, {
                    variant: "success",
                });
                // history.push("/myImplementationTasks");
            }).catch((err) => err);

        } else if (requestData.par_domain === 'Hybrid (AD/Azure)' && requestData.implementer_type === 'On-premise implementation engineer' && !requestData.is_ad_task_list_completed) {
            const postBodyStatusUpdate = {
                request_id: requestId,
                status: '3',
                request_type: 'PAR'
            }
            updateImplementerStatus(postBodyStatusUpdate).then((res) => {
                enqueueSnackbar(`Implementation request ${props.initialValues.request_number} completed successfully`, {
                    variant: "success",
                });
                // history.push("/myImplementationTasks");
            }).catch((err) => err);
        } else if (requestData.par_domain === 'Hybrid (AD/Azure)' && requestData.implementer_type === 'Cloud implementation engineer') {
            const postBodyStatusUpdate = {
                request_id: requestId,
                status: '8',
                request_type: 'PAR'
            }
            updateImplementerStatus(postBodyStatusUpdate).then((res) => {
                enqueueSnackbar(`Implementation request ${props.initialValues.request_number} completed successfully`, {
                    variant: "success",
                });
                // history.push("/myImplementationTasks");
            }).catch((err) => err);
        }
        else if ((requestData.par_domain !== 'insidemedia.onmicrosoft.com') && requestData.implementer_type === 'On-premise implementation engineer' && requestData.is_ad_task_list_completed) {
            const postBodyStatusUpdate = {
                request_id: requestId,
                status: '8',
                request_type: 'PAR'
            }
            updateImplementerStatus(postBodyStatusUpdate).then((res) => {
                enqueueSnackbar(`Implementation request ${props.initialValues.request_number} completed successfully`, {
                    variant: "success",
                });
                // history.push("/myImplementationTasks");
            }).catch((err) => err);
        }
        else if ((requestData.par_domain === 'insidemedia.onmicrosoft.com') && requestData.implementer_type === 'On-premise implementation engineer') {
            const postBodyStatusUpdate = {
                request_id: requestId,
                status: '3',
                request_type: 'PAR'
            }
            updateImplementerStatus(postBodyStatusUpdate).then((res) => {
                enqueueSnackbar(`Implementation request ${props.initialValues.request_number} completed successfully`, {
                    variant: "success",
                });
                // history.push("/myImplementationTasks");
            }).catch((err) => err);
        } else if (((requestData.par_domain !== 'insidemedia.onmicrosoft.com') && requestData.implementer_type === 'On-premise implementation engineer')) {
            const postBodyStatusUpdate = {
                request_id: requestId,
                status: requestData.del_request_id !== null ? '3' : '8',
                request_type: 'PAR'
            }
            updateImplementerStatus(postBodyStatusUpdate).then((res) => {
                enqueueSnackbar(`PAR ${props.initialValues.request_number} Implemented  successfully`, { variant: 'success' });
                // history.push("/myImplementationTasks");
            }).catch((err) => err)
        } else if ((requestData.implementer_type === 'Cloud implementation engineer') && (requestData.par_domain === 'insidemedia.onmicrosoft.com')) {
            const postBodyStatusUpdate = {
                request_id: requestId,
                status: '8',
                request_type: 'PAR'
            }
            updateImplementerStatus(postBodyStatusUpdate).then((res) => {
                enqueueSnackbar(`PAR ${props.initialValues.request_number} Implemented  successfully`, { variant: 'success' });
                history.push("/myImplementationTasks");
            }).catch((err) => err)
        }
        // else if ((requestData.del_request_id !== null) && (requestData.implementer_type === 'PAM implementation engineer') && (requestData.par_domain !== 'insidemedia.onmicrosoft.com')) {
        //     const postBodyStatusUpdate = {
        //         par_id: requestId,
        //         status: '8',
        //     }
        //     updateImplementerStatus(postBodyStatusUpdate).then((res) => {
        //         enqueueSnackbar(`PAR ${props.initialValues.request_number} Implemented  successfully`, { variant: 'success' });
        //         // history.push("/myImplementationTasks");
        //     }).catch((err) => err)
        // }
    }
    // end of implementation status update
    const statusUpdatePar = () => {
        if (modalClickCheck.approveStatus) {
            if (requestData.next_priority !== null) {
                const postBodyStatusUpdate = {
                    par_id: requestId,
                    status: '4',
                }

                updateStatusOfPar(postBodyStatusUpdate).then((res) => {
                    // enqueueSnackbar(`PAR ${props.initialValues.request_number} Approved successfully`, { variant: 'success' });
                    history.push("/myApprovalTasks");
                }).catch((err) => err)
            } else {
                const postBodyStatusUpdate = {
                    par_id: requestId,
                    status: '3',
                }

                updateStatusOfPar(postBodyStatusUpdate).then((res) => {
                    // enqueueSnackbar(`PAR ${props.initialValues.request_number} Approved successfully`, { variant: 'success' });
                    history.push("/myApprovalTasks");
                }).catch((err) => err)
            }
        } else if (modalClickCheck.rejectStatus) {

            const postBodyStatusUpdate = {
                par_id: requestId,
                status: '2',
            }
            updateStatusOfPar(postBodyStatusUpdate).then((res) => {
                // enqueueSnackbar(`PAR ${props.initialValues.request_number} Rejected successfully`, { variant: 'success' });
                history.push("/myApprovalTasks");
            }).catch((err) => err)
        } else if (modalClickCheck.remediationStatus) {
            const postBodyStatusUpdate = {
                par_id: requestId,
                status: '7',
            }
            updateStatusOfPar(postBodyStatusUpdate).then((res) => {
                // enqueueSnackbar(`PAR ${props.initialValues.request_number} Remediated successfully`, { variant: 'success' });
                history.push("/myApprovalTasks");
            }).catch((err) => err)
        }
    }
    const handleModalClose = () => {
        setModalData({
            show: false,
            modalContent: null,
            modalActions: null,
            modalHeader: '',
            rejectStatus: false,
            remediationStatus: false,
            approveStatus: false,
        });
        modalClickCheck = {
            rejectStatus: false,
            approveStatus: false,
            remediationStatus: false,
        }
    };
    // cancel request start

    const handleCancelRequest = (reqId: string) => {
        setIsAPIProgress((prevState) => ({ ...prevState, canceling: true }));
        cancelPar(reqId).then((res) => {
            if (res.status) {
                enqueueSnackbar('PAR cancelled successfully', { variant: 'success' });
                history.push('/par');
            } else {
                enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
            }
            setIsAPIProgress((prevState) => ({ ...prevState, canceling: false }));
        }).catch((err) => {
            console.error(err);
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
            setIsAPIProgress((prevState) => ({ ...prevState, canceling: false }));
        }).finally(() => {
            setModalData({
                show: false,
                modalContent: null,
                modalActions: null,
                modalHeader: '',
            });
        });

    };
    // cancel request end

    // start of cancel of myApprovalTask

    const handleCancelApprover = () => {
        history.push('/myApprovalTasks');
    }
    // start of cancel of myApprovalTask

    // cancelation logic start 
    const confirmCancel = (
        values: FormikValues,
        errors: FormikErrors<FormikValues>,
        setFieldValue: (_fieldName: string, _value: unknown) => void,
        validateForm: (_values: FormikValues) => any,
        resetForm: (_values: FormikValues) => void,
    ) => {
        setModalData({
            show: true,
            modalHeader: 'Cancel PAR',
            modalContent:
                (
                    <div>
                        <div className={styleClasses.modalContentDiv}>
                            Are you sure you want to cancel PAR&nbsp;
                            {props.initialValues.request_number}
                            ?
                        </div>
                        <div className={styleClasses.modalTextDiv}>
                            <span className={styleClasses.spanText}>
                                {'Note: '}
                            </span>
                            A cancelled PAR will be permanently deleted and cannot be retrieved.
                        </div>
                    </div>
                ),
            modalActions: (
                <div className={styleClasses.modalActionDiv}>
                    <CustomButton
                        variant="contained"
                        actionType="secondary"
                        onClick={() => { handleCancelRequest(requestId); }}
                        disabled={isAPIProgress.canceling || isAPIProgress.saving}
                    >
                        {"Don't Save"}
                    </CustomButton>
                    <CustomButton
                        variant="contained"
                        actionType="primary"
                        disableMargin
                        onClick={() => {
                            handleSave(
                                values,
                                errors,
                                setFieldValue,
                                validateForm,
                                resetForm,
                                {
                                    shouldExit: true,
                                    switchStep: -1,
                                }
                            );
                        }}
                        disabled={isAPIProgress.canceling || isAPIProgress.saving}
                    >
                        Save as Draft
                    </CustomButton>
                </div>
            )
        })

    }

    const confirmCancelReview = (
        values: FormikValues,
        errors: FormikErrors<FormikValues>,
        setFieldValue: (_fieldName: string, _value: unknown) => void,
        validateForm: (_values: FormikValues) => any,
        resetForm: (_values: FormikValues) => void,
    ) => {
        setModalData({
            show: true,
            modalHeader: 'Cancel review',
            modalContent:
                (
                    <div>
                        <div className={styleClasses.modalContentDiv}>
                            Are you sure you want to cancel your review of PAR&nbsp;
                            {props.initialValues.request_number}
                            ?
                        </div>
                        <div className={styleClasses.modalTextDiv}>
                            <span className={styleClasses.spanText}>
                                {'Note: '}
                            </span>
                            If you cancel your review now, this PAR will revert to the Pending Approval stage. If you are unable to review at this time, you can delegate to another approver, via your Pending Approvals page. <br /> Should you continue to review this PAR, you will be able to either approve the PAR or request updates to the PAR using the Request Remediation button.
                        </div>
                    </div>
                ),
            modalActions: (
                <div className={styleClasses.modalActionDiv}>
                    <CustomButton
                        variant="contained"
                        actionType="secondary"
                        onClick={() => { handleCancelApprover() }}
                    >
                        Cancel Review
                    </CustomButton>
                    <div style={{ paddingLeft: '5px' }}>
                        <CustomButton
                            variant="contained"
                            actionType="primary"
                            disableMargin
                            onClick={handleModalClose}
                            disabled={isAPIProgress.canceling || isAPIProgress.saving}
                        >
                            Continue Review
                        </CustomButton>
                    </div>

                </div>
            )
        })

    }
    // cancelation logic end

    // Reject PAR in approval stage
    const ConfirmRejectReview = () => {
        modalClickCheck = {
            rejectStatus: true,
            remediationStatus: false,
            approveStatus: false,
        }
        setModalData({
            show: true,
            modalHeader: 'Reject PAR',
            rejectStatus: true,
            modalContent:
                (
                    <div>
                        <div className={styleClasses.modalContentDiv}>
                            Are you sure you want to reject PAR&nbsp;
                            {props.initialValues.request_number}
                            ?
                        </div>
                        <div className={styleClasses.modalTextDiv}>
                            <span className={styleClasses.spanText}>
                                {'Note: '}
                            </span>
                            Rejecting a PAR is a permanent action and cannot be undone. If you require the user to update the PAR with further information, please request this using the <b>Request Remediation</b> button at the bottom of the screen.
                        </div>
                    </div>
                ),
            modalActions: (
                <div className={styleClasses.modalActionDiv}>
                    <CustomButton
                        variant="contained"
                        actionType="secondary"
                        onClick={() => statusUpdatePar()}
                        disabled={isAPIProgress.canceling || isAPIProgress.saving}
                    >
                        Reject
                    </CustomButton>
                    <CustomButton
                        variant="contained"
                        actionType="primary"
                        disableMargin
                        onClick={handleModalClose}
                        disabled={isAPIProgress.canceling || isAPIProgress.saving}
                    >
                        No
                    </CustomButton>
                </div>
            )
        })
    }

    // End Reject PAR


    // Start of Remediation modal pop-up
    const ConfirmRemediationReview = (
        values: FormikValues,
        errors: FormikErrors<FormikValues>,
        validateForm: (_values: FormikValues) => any,
        resetForm: (_values: FormikValues) => void,
        options: { shouldExit: boolean, switchStep: number },
    ) => {
        modalClickCheck = {
            rejectStatus: false,
            remediationStatus: true,
            approveStatus: false,
        }

        if (isEmpty(postBodyData)) {

            setModalData({
                show: true,
                modalHeader: 'Note',
                modalContent:
                    (
                        <div className={styleClasses.modalTextDiv}>
                            No comments have been added to this PAR to explain why remediation is required. <br /> Please return to the PAR and add comments using the speech bubble icon on the top right of the PAR view. Comments can be added in each section to explain what additional information is required.
                        </div>

                    ),
                modalActions: (
                    <div className={styleClasses.modalActionDiv}>

                        <CustomButton
                            variant="contained"
                            actionType="primary"
                            disableMargin
                            onClick={handleModalClose}
                        >
                            Ok
                        </CustomButton>
                    </div>
                )
            })
        } else {
            const appCheckingLastComments = postBodyData.map((item) => {
                // return (item.updated_by === loginsyncupdata.email) && (item.current_remediation === props.initialValues.remediation_count) && (item.request_to_update === props.initialValues.request_to_update_count);
                return (item.updated_by === loginsyncupdata.email) && (item.current_remediation === props.initialValues.remediation_count)
            })
            if (appCheckingLastComments.includes(true)) {
                statusUpdatePar();
                return;
            } else {
                setModalData({
                    show: true,
                    modalHeader: 'Note',
                    modalContent:
                        (
                            <div className={styleClasses.modalTextDiv}>
                                No comments have been added to this Delegation to explain why remediation is required. <br /> Please return to the Delegation and add comments using the speech bubble icon on the top right of the Delegation view. Comments can be added in each section to explain what additional information is required.
                            </div>
                        ),
                    modalActions: (
                        <div className={styleClasses.modalActionDiv}>

                            <CustomButton
                                variant="contained"
                                actionType="primary"
                                disableMargin
                                onClick={() => handleModalClose()}
                            >
                                Ok
                            </CustomButton>
                        </div>
                    )
                })

            }

        }
    }
    // End of Remediation modal pop-up

    // Start of Approve modal pop-up
    const ConfirmApproveHandler = () => {

        modalClickCheck = {
            rejectStatus: false,
            approveStatus: true,
            remediationStatus: false,
        }
        setModalData({
            show: true,
            modalHeader: 'Approve PAR',
            modalContent:
                (
                    <div>
                        <div className={styleClasses.modalContentDiv}>
                            Are you sure you want to approve PAR &nbsp;{props.initialValues.request_number} and move it to next approval stage without any update?
                        </div>
                        <div className={styleClasses.modalTextDiv}>
                            <span className={styleClasses.spanText}>
                                {'Note: '}
                            </span>
                            If you require the User to update the PAR with further information, please request this using the <b>Request Remediation</b> button at the bottom of the screen.
                        </div>
                    </div>

                ),
            modalActions: (
                <div className={styleClasses.modalActionDiv}>
                    <CustomButton
                        variant="contained"
                        actionType="secondary"
                        onClick={() => handleModalClose()}
                        disabled={isAPIProgress.canceling || isAPIProgress.saving}
                    >
                        No
                    </CustomButton>
                    <CustomButton
                        variant="contained"
                        actionType="primary"
                        disableMargin
                        onClick={statusUpdatePar}
                        disabled={isAPIProgress.canceling || isAPIProgress.saving}
                    >
                        Approve
                    </CustomButton>
                </div>
            )
        })
    }
    // End of Approve modal pop-up



    const getButtonLabel = (isSubmitting: boolean) => {
        if (isSubmitting) return 'Submitting';
        if (isLastStep()) return 'Submit';
        return 'Next';
    };

    const isImplementDisabled = (values: any) => {
        if (requestData.par_domain === 'Hybrid (AD/Azure)' && requestData.implementer_type === 'Cloud implementation engineer') {
            return values.is_step_two_completed_cloud;
        }
        else if (requestData.par_domain === 'Hybrid (AD/Azure)' && requestData.implementer_type === 'On-premise implementation engineer' && requestData.is_ad_task_list_completed) {
            return values.mark_step_aad_onPrem;
        } else if (requestData.par_domain === 'Hybrid (AD/Azure)' && requestData.implementer_type === 'On-premise implementation engineer' && !requestData.is_ad_task_list_completed) {
            return values.is_step_complete_ad;
        } else if ((requestData.par_domain === 'insidemedia.onmicrosoft.com') && requestData.implementer_type === 'On-premise implementation engineer') {
            return values.mark_step_aad_onPrem;
        } else if (((requestData.par_domain !== 'insidemedia.onmicrosoft.com') && requestData.implementer_type === 'On-premise implementation engineer')) {
            return values.is_step_complete_ad;
        } else if ((requestData.implementer_type === 'Cloud implementation engineer') && (requestData.par_domain === 'insidemedia.onmicrosoft.com')) {
            return values.is_step_two_completed_cloud;
        } else if ((requestData.del_request_id !== null) && (requestData.implementer_type === 'PAM implementation engineer') && (requestData.par_domain !== 'insidemedia.onmicrosoft.com')) {
            return stepsDataPam;
        } else return stepsData;
    }


    return (
        <>
            <Formik
                {...props}
                validationSchema={currentChild.props.validationSchema}
                onSubmit={async (values: FormikValues, helpers: FormikHelpers<FormikValues>) => {
                    helpers.setSubmitting(true);
                    await props.onSubmit(values, helpers);
                    helpers.setSubmitting(false);
                }}
                validateOnChange={false}
            >
                {
                    (
                        {
                            isSubmitting,
                            values,
                            setFieldValue,
                            errors,
                            validateForm,
                            submitForm,
                            dirty,
                            resetForm,
                        }
                    ) => (
                        <>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={3}>
                                    <div className={styleClasses.leftSection}>
                                        <div className={styleClasses.stepperContainer}>
                                            <div className={styleClasses.stepperScoller}>
                                                <VerticalLinearStepper
                                                    activeStep={activeStep}
                                                    // stepsData={((props.initialValues.is_implemented && checkIsDisabledImplementation()) || routeCheckForImplementationSummary()) ? implementationCheckForParStepData() : stepsData}
                                                    stepsData={((checkIsDisabledImplementation()) || routeCheckForImplementationSummary()) ? implementationCheckForParStepData() : stepsData}

                                                    setActiveStep={setActiveStep}
                                                    requestStatus={requestData.status_label}
                                                />
                                            </div>
                                        </div>
                                        <Divider orientation="vertical" variant="middle" flexItem style={{ margin: '0  20px' }} />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={9}>
                                    <div style={{
                                        // maxHeight: 'calc(100vh - 108px - 68.5px - 60px)',
                                        // overflowX: 'scroll',
                                        paddingBottom: '30px',
                                        paddingLeft: '10px',
                                        paddingRight: '10px',
                                    }}
                                    >
                                        <div className={styleClasses.reasonLabel}>
                                            {`${props.initialValues.request_number} : ${requestData?.request_purpose || ''}`}
                                        </div>
                                        {currentChild}
                                    </div>
                                </Grid>
                            </Grid>
                            <Footer>
                                <div style={{ paddingRight: '15px' }}>
                                    <div className={styleClasses.footerActions}>
                                        {activeStep > 0 && (<CustomButton
                                            variant="contained"
                                            actionType="secondary"
                                            disableMargin
                                            onClick={() => setActiveStep((s) => s - 1)}
                                        >
                                            Prev
                                        </CustomButton>)}
                                        {routeCheck() && <CustomButton
                                            variant="contained"
                                            actionType="secondary"
                                            disableMargin
                                            // disabled={approver ? true : (checkIsDisabled() || isSubmitting || activeStep === 0)}
                                            // disabled={!roleGroupArray.includes('Approver')}
                                            onClick={ConfirmRejectReview}
                                        >
                                            Reject
                                        </CustomButton>}
                                    </div>

                                    <div className={`${styleClasses.footerActionsRight} ${styleClasses.footerActions}`}>
                                        {
                                            routeCheck() && (<>
                                                <CustomButton
                                                    variant="contained"
                                                    actionType="secondary"
                                                    disableMargin
                                                    // disabled={isAPIProgress.saving || checkIsDisabled()}
                                                    onClick={() => {
                                                        confirmCancelReview(values, errors, setFieldValue, validateForm, resetForm)
                                                    }}
                                                >
                                                    Cancel
                                                </CustomButton>
                                            </>
                                            )
                                            // ) : (
                                            // (routeCheck() && requestData.del_request_id === null) ?
                                            //     (
                                            //         <CustomButton
                                            //             variant="contained"
                                            //             actionType="secondary"
                                            //             disableMargin
                                            //             // disabled={isAPIProgress.saving || checkIsDisabled()}
                                            //             onClick={() => {
                                            //                 confirmCancel(values, errors, setFieldValue, validateForm, resetForm);
                                            //             }}
                                            //         >
                                            //             Cancel
                                            //         </CustomButton>
                                            //     ) : (
                                            //         () => null
                                            //     )
                                            // )
                                        }
                                        {
                                            routeCheckForPar() && <CustomButton
                                                variant="contained"
                                                actionType="secondary"
                                                disableMargin
                                                // disabled={isAPIProgress.saving || checkIsDisabled()}
                                                onClick={() => {
                                                    confirmCancel(values, errors, setFieldValue, validateForm, resetForm);
                                                }}
                                            >
                                                Cancel
                                            </CustomButton>

                                        }
                                        {!routCheckApprovalSummary() && !routeCheckForAdminSummary() && ((requestData.status_label == 'Draft') || (requestData.status_label == 'Remediation Requested')) &&
                                            <CustomButton
                                                variant="contained"
                                                actionType="secondary"
                                                disableMargin
                                                disabled={isAPIProgress.saving}
                                                name="onSave"
                                                onClick={() => {
                                                    handleSave(
                                                        values,
                                                        errors,
                                                        // dirty
                                                        setFieldValue,
                                                        validateForm,
                                                        resetForm,
                                                        {
                                                            shouldExit: false,
                                                            switchStep: activeStep,
                                                        },
                                                    );
                                                }}
                                            >
                                                {isAPIProgress.saving ? 'Saving..' : 'Save'}

                                            </CustomButton>
                                        }
                                        {routeCheck() && <CustomButton
                                            variant="contained"
                                            actionType="secondary"
                                            disableMargin
                                            onClick={() => ConfirmRemediationReview(
                                                values,
                                                errors,
                                                validateForm,
                                                resetForm,
                                                {
                                                    shouldExit: false,
                                                    switchStep: activeStep,
                                                },
                                            )}
                                        >
                                            Request Remediation
                                        </CustomButton>}

                                        {

                                            checkIsDisabled() ?
                                                (!routeCheckForImplementationSummary() && <CustomButton
                                                    variant="contained"
                                                    actionType="primary"
                                                    disableMargin
                                                    disabled={routeCheck() ? false : isLastStep()}
                                                    onClick={isLastStep() ? ConfirmApproveHandler : () => setActiveStep((s) => s + 1)}
                                                >
                                                    {(routeCheck() && isLastStep()) ? `Approve` : ` Next`}
                                                </CustomButton>
                                                ) : (
                                                    <CustomButton
                                                        variant="contained"
                                                        actionType="primary"
                                                        disableMargin
                                                        disabled={checkIsDisabled()
                                                            || (isSubmitting || isAPIProgress.saving)
                                                            // || (isLastStep())
                                                        }
                                                        onClick={() => {
                                                            if (!isLastStep()) {
                                                                handleSaveOnNext(
                                                                    values,
                                                                    errors,
                                                                    setFieldValue,
                                                                    validateForm,
                                                                    resetForm,
                                                                    {
                                                                        shouldExit: false,
                                                                        switchStep: activeStep + 1,
                                                                    },
                                                                );
                                                            } else {
                                                                submitForm();
                                                            }
                                                        }}
                                                    >
                                                        {getButtonLabel(isSubmitting)}
                                                    </CustomButton>
                                                )
                                        }
                                        {/* {checkIsDisabled() &&
                                            (
                                                <CustomButton
                                                    variant="contained"
                                                    actionType="primary"
                                                    disableMargin
                                                    disabled={checkIsDisabled()
                                                        || (isSubmitting || isAPIProgress.saving)
                                                        // || (isLastStep())
                                                    }
                                                    onClick={() => {
                                                        if (!isLastStep()) {
                                                            handleSave(
                                                                values,
                                                                errors,
                                                                setFieldValue,
                                                                validateForm,
                                                                resetForm,
                                                                {
                                                                    shouldExit: false,
                                                                    switchStep: activeStep + 1,
                                                                },
                                                            );
                                                        } else {
                                                            submitForm();
                                                        }
                                                    }}
                                                >
                                                    {getButtonLabel(isSubmitting)}
                                                </CustomButton>
                                            )
                                        } */}
                                        {
                                            routeCheckForImplementationSummary() ? (
                                                <>
                                                    {isLastStep() && <CustomButton
                                                        variant="contained"
                                                        actionType="secondary"
                                                        disableMargin
                                                        disabled={isAPIProgress.saving}
                                                        name="onSave"
                                                        onClick={() => {
                                                            handleSave(
                                                                values,
                                                                errors,
                                                                setFieldValue,
                                                                validateForm,
                                                                resetForm,
                                                                {
                                                                    shouldExit: false,
                                                                    switchStep: activeStep,
                                                                },
                                                            );
                                                        }}
                                                    >
                                                        {isAPIProgress.saving ? 'Saving..' : 'Save'}

                                                    </CustomButton>}
                                                    <CustomButton
                                                        variant="contained"
                                                        actionType="primary"
                                                        disableMargin
                                                        disabled={isLastStep() && (isImplementDisabled(values) ? false : true)}
                                                        onClick={isLastStep() ? () => ConfirmImplementationHandler(values, errors) : () => setActiveStep((s) => s + 1)}
                                                    >
                                                        {(routeCheckForImplementationSummary() && isLastStep()) ? `Implement` : ` Next`}
                                                    </CustomButton>
                                                </>
                                            )
                                                : routeCheckForImplementationSummary() && (
                                                    <CustomButton
                                                        variant="contained"
                                                        actionType="primary"
                                                        disableMargin
                                                        disabled={
                                                            checkIsDisabled() ||
                                                            isSubmitting ||
                                                            isAPIProgress.saving ||
                                                            (isLastStep() && !values.finalCheck)
                                                        }
                                                        onClick={() => {
                                                            if (!isLastStep()) {
                                                                handleSave(
                                                                    values,
                                                                    errors,
                                                                    setFieldValue,
                                                                    validateForm,
                                                                    resetForm,
                                                                    {
                                                                        shouldExit: false,
                                                                        switchStep: activeStep + 1,
                                                                    }
                                                                );
                                                            } else {
                                                                submitForm();
                                                            }
                                                        }}
                                                    >
                                                        {getButtonLabel(isSubmitting)}
                                                    </CustomButton>
                                                )}
                                    </div>
                                </div>
                            </Footer>
                            <Prompt
                                when={dirty && !checkIsDisabled()}
                                message={(location) => `Are you sure you want to go to ${location.pathname}`}
                            />
                            {/* {routeCheck() ? <Prompt
                                when={!(postBodyData.length > 0)}
                                message={(location) => `Are you sure you want to go to ${location.pathname}`}
                            /> : null} */}
                        </>
                    )
                }
            </Formik >
            {
                modalData.show && (
                    <CustomModal
                        modalHeader={modalData.modalHeader}
                        open={modalData.show}
                        onClose={handleModalClose}
                        modalAction={modalData.modalActions}
                        modalContent={modalData.modalContent}
                    />
                )
            }
        </>
    )
}