import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { format, isDate, isWithinInterval } from "date-fns";
import _, { values } from "lodash";
import myImplementationTasksStyles from "../my-Implementation-tasks-styles";
import { ReactComponent as Sorting } from "../../../assets/icons/sorting_asc_inactive.svg";
import { ReactComponent as SortingAscending } from "../../../assets/icons/sorting_asc.svg";
import { ReactComponent as SortingDescending } from "../../../assets/icons/sorting_desc.svg";
import useDaterangePicker from "../../../components/date-range-picker/useDaterangePicker";
import StatusBadge from "./StatusBadge";

import {
  tableColumnMapping,
  createColumns,
  emptyFilters,
  IFiltersObj,
  initialTableParameters,
  ITableParameters,
  ITableColumnData,
  LastAction,
  createRowCells,
} from "./implementationSummaryUtils";
import { fetchMyimplementationSummaryData } from "../../../api-services/implementation-summary";

function useImplementationSummaryHook() {
  const history = useHistory();
  const styleClasses = myImplementationTasksStyles();

  const [implementationSummaryData, setImplementationSummaryData] = useState({
    count: 0,
    results: [],
    pageSize: 10,
  });

  const [implementationSummaryDataFromApi, setImplementationSummaryDataFromApi] = useState([]);
  const [tempImplementationSummaryDataFromApi, setTempImplementationSummaryDataFromApi] = useState([]);

  const [filters, setFilters] = useState<IFiltersObj>(emptyFilters);
  const [closeFilter, setCloseFilter] = useState(false);
  const { datepickerProps, setDatepickerProps, setDateRangeValue } =
    useDaterangePicker({ isSingleDate: true });

  const [requestOnBadge, setRequestOnBadge] = useState(false);

  const [tableParametersPending, setTableParametersPending] =
    useState<ITableParameters>(initialTableParameters);
  const [requestIdFilter, setRequestIdFilters] = useState([]);
  const [requestorNameFilter, setRequestorNameFilters] = useState<LastAction[]>(
    [{ id: "", value: "" }]
  );
  const [requestTypeFilter, setRequestTypeFilters] = useState<LastAction[]>([
    { id: "", value: "" },
  ]);
  const [myLastActionFilter, setMyLastActionFilters] = useState<LastAction[]>([
    { id: "", value: "" },
  ]);
  const [currentStatusFilter, setCurrentStatusFilters] = useState<LastAction[]>(
    [{ id: "", value: "" }]
  );

  const [menuData, setMenuData] = useState({
    anchorEl: null,
    row: {
      request_id: null,
      request_stage_id: null,
      request_type: null,
      request_status: null,
      linked_delegation: null,
      request_stage: null,
      delegate_to_secondary: null,
      implementer_type_id: null,
      request_purpose: null
    },
  });

  const [isLoadingPending, setIsLoadingPending] = useState({
    tableLoading: false,
    filters: {
      requestId: false,
      requestorName: false,
      requestType: false,
      myLastAction: false,
      currentStatus: false,
      requestedOn: false,
    },
  });

  const fetchFiltersValues = () => {
    setIsLoadingPending((loadingState) => ({
      ...loadingState,
      filters: {
        ...loadingState.filters,
      },
    }));
  };

  const openDelegation = (id: any, row: any) => {
    setMenuData({
      anchorEl: null,
      row: {
        request_id: null,
        request_stage_id: null,
        request_type: null,
        request_status: null,
        linked_delegation: null,
        request_stage: null,
        delegate_to_secondary: null,
        implementer_type_id: null,
        request_purpose: null
      },
    });

    if (row.request_type === 'Delegation') {
      if (row.request_purpose === "Connect to / manage a server or computer") {
        history.push(`/myImplementationTasks/implementationSummary/${row.request_id}`);
      } else {
        history.push(`/myImplementationTasks/implementationSummarySar/${row.request_id}`);
      }
    } else {
      history.push(`/myImplementationTasks/implementationSummarypar/${row.request_id}`);
    }
    return null;
  };

  useEffect(() => {
    fetchAllImplementationSummary();
    fetchFiltersValues();
    setIsLoadingPending((loadingState) => ({
      ...loadingState,
      tableLoading: true,
    }));
  }, []);

  // FILTERS START

  const fetchFilteredDataWithDebounce = (filtersObject: any) => {
    if (filtersObject?.requestId?.length > 0) {
      setTempImplementationSummaryDataFromApi([
        ...implementationSummaryDataFromApi.filter((obj: ITableColumnData) =>
          filtersObject?.requestId.find(
            (item: string) => item === obj.request_number
          )
        ),
      ]);
      setTableParametersPending((prevParameters: any) => ({
        ...prevParameters,
        page: Math.ceil(
          filtersObject?.requestId?.length / implementationSummaryData.pageSize
        ),
      }));
    } else if (filtersObject?.requestorName?.length > 0) {
      setTempImplementationSummaryDataFromApi([
        ...implementationSummaryDataFromApi.filter((obj: ITableColumnData) =>
          filtersObject?.requestorName.find(
            (item: string) => item === obj.requestor_name
          )
        ),
      ]);
      setTableParametersPending((prevParameters: any) => ({
        ...prevParameters,
        page: Math.ceil(
          filtersObject?.requestorName?.length / implementationSummaryData.pageSize
        ),
      }));
    } else if (filtersObject?.requestType?.length > 0) {
      setTempImplementationSummaryDataFromApi([
        ...implementationSummaryDataFromApi.filter((obj: ITableColumnData) =>
          filtersObject?.requestType.find(
            (item: string) => item == obj.request_type
          )
        ),
      ]);

      setTableParametersPending((prevParameters: any) => ({
        ...prevParameters,
        page: Math.ceil(
          filtersObject?.requestType?.length / implementationSummaryData.pageSize
        ),
      }));

    } else if (filtersObject?.myLastAction?.length > 0) {
      setTempImplementationSummaryDataFromApi([
        ...implementationSummaryDataFromApi.filter((obj: ITableColumnData) =>
          filtersObject?.myLastAction.find(
            (item: string) => item == obj.my_last_action
          )
        ),
      ]);
      setTableParametersPending((prevParameters: any) => ({
        ...prevParameters,
        page: Math.ceil(
          filtersObject?.myLastAction?.length / implementationSummaryData.pageSize
        ),
      }));
    } else if (filtersObject?.currentStatus?.length > 0) {
      setTempImplementationSummaryDataFromApi([
        ...implementationSummaryDataFromApi.filter((obj: ITableColumnData) =>
          filtersObject?.currentStatus.find(
            (item: string) => item == obj.current_status
          )
        ),
      ]);
      setTableParametersPending((prevParameters: any) => ({
        ...prevParameters,
        page: Math.ceil(
          filtersObject?.currentStatus?.length / implementationSummaryData.pageSize
        ),
      }));
    } else {
      setTempImplementationSummaryDataFromApi(implementationSummaryDataFromApi);
    }
  };

  useEffect(() => {
    fetchFilteredDataWithDebounce(filters);
  }, [filters]);

  const formatDate = (dateValue: any) =>
    dateValue && isDate(new Date(dateValue))
      ? format(new Date(dateValue), "dd MMM yyyy")
      : "";

  const setDateRangeFilter = () => {
    setCloseFilter(true);
    setRequestOnBadge(true);
    let requestedOnData = implementationSummaryDataFromApi.filter((obj: ITableColumnData) => {
      if (
        isWithinInterval(new Date(`${obj.requested_on}`), {
          // @ts-ignore */
          start: datepickerProps.dateRange.startDate,
          // @ts-ignore */
          end: datepickerProps.dateRange.endDate,
        }) ||
        formatDate(obj.requested_on) ===
        formatDate(datepickerProps.dateRange.endDate)
      )
        return true;
      return false;
    })
    setTempImplementationSummaryDataFromApi([...requestedOnData]);
    setTableParametersPending((prevParameters: any) => ({
      ...prevParameters,
      page: Math.ceil(requestedOnData.length / implementationSummaryData.pageSize),
    }));
  };

  // clear value handler
  const clearValueHandler = () => {
    setRequestOnBadge(false);
    setIsLoadingPending((loadingState) => ({
      ...loadingState,
      tableLoading: false,
    }));
    setDatepickerProps({
      isSingleDate: false,
      dateRange: {
        startDate: null,
        endDate: null,
      },
    });
    setFilters(emptyFilters);
    setTempImplementationSummaryDataFromApi(implementationSummaryDataFromApi);
  };

  // FILTERS END

  // LISTING TABLE START

  useEffect(() => {
    const rowsArray = tempImplementationSummaryDataFromApi.map((row: any) => {
      const cellArray = Object.entries(row)
        .map(([key, value]) => {
          let cellData = null;
          // row.request_type  == 'PAR' && row.delegation_status != 'Completed'
          switch (key) {
            case "request_number":
              cellData = createRowCells(
                tableColumnMapping.request_number,
                `${value}`,
                `${row.request_id}-${value}`,
                1,
                () =>
                  openDelegation(
                    row.linked_delegation ? row.linked_delegation : value, row
                  ),
                { textDecoration: "underline", color: "#0080FF" }
              );
              break;
            case "requestor_name":
              cellData = createRowCells(
                tableColumnMapping.requestorName,
                `${value || ""}`,
                `${row.request_id}-${value || ""}`,
                2
              );
              break;
            case "request_type":
              cellData = createRowCells(
                tableColumnMapping.requestType,
                `${value || ""}`,
                `${row.request_id}-${value || ""}`,
                3
              );
              break;
            case "my_last_action":
              cellData = createRowCells(
                tableColumnMapping.myLastAction,
                `${value == "Completed" ? "Implemented" : value || ""
                } on ${formatDate(row.actioned_on)}`,
                `${row.request_id}-${value || ""}`,
                4
              );
              break;
            case "current_status":
              cellData = createRowCells(
                tableColumnMapping.currentStatus,
                <StatusBadge
                  status={value}
                  requestType={row.request_type}
                  requestStage={row.request_stage}
                  remediationStage={row.remediation_stage}
                  remediationBy={row.remediation_by}
                  stageId={row.stage_id}
                  remediationCount={row.remediation_count}
                  isUpdateRequested={row.is_request_to_update}
                  superScript={row.request_to_update_count}
                  isRequestToUpdateCount={row.request_to_update_count}
                  requestStagePriority={row.request_stage_priority}
                  requestPurpose={row.request_purpose}
                />,
                `${row.request_id}-${value || ""}`,
                5
              );
              break;

            case "requested_on":
              cellData = createRowCells(
                tableColumnMapping.requestedOn,
                `${formatDate(value)}`,
                `${row.request_id}-${value || ""}`,
                6
              );
              break;

            default:
              break;
          }
          return cellData;
        })
        .filter((obj: any) => obj);

      if (row.request_type == "PAR" && row.linked_delegation != null) {
        cellArray.push(
          createRowCells(
            tableColumnMapping.actions,
            <MoreVertIcon
              className={`${styleClasses.moreIcon} ${!!menuData.anchorEl && styleClasses.moreIconActive
                }`}
            />,
            `more-actions-${row.id}`,
            7,
            (e) =>
              openMenu(
                e,
                row.request_id,
                row.request_stage_id,
                row.request_type,
                row.request_status,
                row.linked_delegation,
                row.request_stage,
                row.delegate_to_secondary,
                row.implementer_type_id,
                row.request_purpose
              ),
            { paddingLeft: "35px" }
          )
        );
      } else {
        cellArray.push(
          createRowCells(
            tableColumnMapping.actions,
            "",
            `more-actions-${row.id}`,
            7,
            undefined,
            { paddingLeft: "35px" }
          )
        );
      }

      return cellArray;
    });
    setImplementationSummaryData((prevState: any) => ({
      ...prevState,
      results: [...rowsArray],
    }));
  }, [tempImplementationSummaryDataFromApi]);

  const fetchAllImplementationSummary = async () => {
    // @ts-ignore */
    await fetchMyimplementationSummaryData()
      .then((res: any) => {
        setImplementationSummaryDataFromApi(res.data);
        setTempImplementationSummaryDataFromApi(res.data);

        // setImplementationSummaryDataFromApi(
        //   res.data.sort((a: any, b: any) =>
        //     b.requested_on.localeCompare(a.requested_on)
        //   )
        // );
        // setTempImplementationSummaryDataFromApi(
        //   res.data.sort((a: any, b: any) =>
        //     b.requested_on.localeCompare(a.requested_on)
        //   )
        // );

        // @ts-ignore */
        setRequestIdFilters(
          res.data?.map((requestIdObj: { request_number: string }) => ({
            id: requestIdObj.request_number,
            value: requestIdObj.request_number,
          }))
        );

        // @ts-ignore */
        //For requestorName
        const requestorName = [
          ...new Set(
            res.data?.map(
              ({ requestor_name }: { requestor_name: any }) => requestor_name
            )
          ),
        ].map((e) =>
          res.data?.find(
            ({ requestor_name }: { requestor_name: any }) => requestor_name == e
          )
        );
        // @ts-ignore */
        setRequestorNameFilters(
          requestorName?.map(
            (requestorNameObj: { requestor_name: string }) => ({
              id: requestorNameObj.requestor_name,
              value:
                requestorNameObj.requestor_name === ""
                  ? "(Blanks)"
                  : requestorNameObj.requestor_name,
            })
          )
        );

        // @ts-ignore */
        //For requestType
        const requestType = [
          ...new Set(
            res.data?.map(
              ({ request_type }: { request_type: any }) => request_type
            )
          ),
        ].map((e) =>
          res.data?.find(
            ({ request_type }: { request_type: any }) => request_type == e
          )
        );
        // @ts-ignore */
        setRequestTypeFilters(
          requestType?.map((requestTypeObj: { request_type: string }) => ({
            id: requestTypeObj.request_type,
            value:
              requestTypeObj.request_type === ""
                ? "(Blanks)"
                : requestTypeObj.request_type == "PAR"
                  ? requestTypeObj.request_type + " (Privileged Access Request)"
                  : requestTypeObj.request_type,
          }))
        );

        // @ts-ignore */
        const myLastAction = [
          ...new Set(
            res.data?.map(
              ({ my_last_action }: { my_last_action: any }) => my_last_action
            )
          ),
        ].map((e) =>
          res.data?.find(
            ({ my_last_action }: { my_last_action: any }) => my_last_action == e
          )
        );
        // @ts-ignore */
        setMyLastActionFilters(
          myLastAction?.map((myLastActionObj: { my_last_action: string }) => ({
            id: myLastActionObj.my_last_action,
            value:
              myLastActionObj.my_last_action === ""
                ? "(Blanks)"
                : myLastActionObj.my_last_action,
          }))
        );

        // @ts-ignore */
        const currentStatus = [
          ...new Set(
            res.data?.map(
              ({ current_status }: { current_status: any }) => current_status
            )
          ),
        ].map((e) =>
          res.data?.find(
            ({ current_status }: { current_status: any }) => current_status == e
          )
        );
        // @ts-ignore */
        setCurrentStatusFilters(
          currentStatus?.map(
            (currentStatusObj: { current_status: string }) => ({
              id: currentStatusObj.current_status,
              value:
                currentStatusObj.current_status === ""
                  ? "(Blanks)"
                  : currentStatusObj.current_status,
            })
          )
        );

        setIsLoadingPending((loadingState) => ({
          ...loadingState,
          tableLoading: false,
        }));
      })
      .catch((error: string) => {
        setIsLoadingPending((loadingState) => ({
          ...loadingState,
          tableLoading: false,
        }));
      });
  };

  // for sorting
  useEffect(() => {
    tableParametersPending.orderBy.column !== ""
      ? createPendingImplementation()
      : setTempImplementationSummaryDataFromApi(
        implementationSummaryDataFromApi
      );
  }, [
    tableParametersPending.orderBy.column,
    tableParametersPending.orderBy.order,
  ]);

  // For sorting Column Name
  const createPendingImplementation = () => {
    setTempImplementationSummaryDataFromApi((prevData) => [
      ...prevData.sort(function (valueOne, valueTwo) {
        if (
          valueOne[tableParametersPending.orderBy.column] <
          valueTwo[tableParametersPending.orderBy.column]
        )
          return tableParametersPending.orderBy.order === "asc" ? -1 : 1;
        if (
          valueOne[tableParametersPending.orderBy.column] >
          valueTwo[tableParametersPending.orderBy.column]
        )
          return tableParametersPending.orderBy.order === "asc" ? 1 : -1;
        return 0;
      }),
    ]);
  };

  const handleSort = (e: any) => {
    setTableParametersPending((prevParameters) => {
      const newOrderBy = { ...prevParameters.orderBy };
      if (e.target.id !== newOrderBy.column) {
        newOrderBy.order = "asc";
        newOrderBy.column = e.target.id;
      } else if (prevParameters.orderBy.order === "desc") {
        newOrderBy.order = "asc";
        newOrderBy.column = "";
      } else {
        newOrderBy.order = "desc";
      }
      return { ...prevParameters, orderBy: { ...newOrderBy } };
    });
  };

  const getSortingIcon = (columnId: string) => {
    if (tableParametersPending.orderBy.column === columnId) {
      return tableParametersPending.orderBy.order === "desc" ? (
        <SortingDescending />
      ) : (
        <SortingAscending />
      );
    }
    return <Sorting />;
  };

  const tableColumnsForPending = [
    createColumns(
      1,
      "REQ ID",
      () => null,
      tableColumnMapping.requestId,
      tableParametersPending.orderBy.column === tableColumnMapping.requestId,
      (e) => handleSort(e),
      { width: "75px" }
    ),
    createColumns(
      2,
      "Requestor Name",
      getSortingIcon(tableColumnMapping.requestorName),
      tableColumnMapping.requestorName,
      tableParametersPending.orderBy.column ===
      tableColumnMapping.requestorName,
      (e) => handleSort(e),
      { width: "130px" }
    ),
    createColumns(
      3,
      "Request Type",
      getSortingIcon(tableColumnMapping.requestType),
      tableColumnMapping.requestType,
      tableParametersPending.orderBy.column === tableColumnMapping.requestType,
      (e) => handleSort(e),
      { width: "115px" }
    ),
    createColumns(
      4,
      "My Last Action",
      getSortingIcon(tableColumnMapping.myLastAction),
      tableColumnMapping.myLastAction,
      tableParametersPending.orderBy.column === tableColumnMapping.myLastAction,
      (e) => handleSort(e),
      { width: "115px" }
    ),

    createColumns(
      5,
      "Current Status",
      getSortingIcon(tableColumnMapping.currentStatus),
      tableColumnMapping.currentStatus,
      tableParametersPending.orderBy.column ===
      tableColumnMapping.currentStatus,
      (e) => handleSort(e),
      { width: "115px" }
    ),

    createColumns(
      6,
      "Requested On",
      getSortingIcon(tableColumnMapping.requestedOn),
      tableColumnMapping.requestedOn,
      tableParametersPending.orderBy.column === tableColumnMapping.requestedOn,
      (e) => handleSort(e),
      { width: "115px" }
    ),

    createColumns(
      7,
      "Actions",
      null,
      tableColumnMapping.actions,
      false,
      undefined,
      { width: "15px" }
    ),
  ];

  // LISTING TABLE END

  const openMenu = (
    e: any,
    rowId: any,
    stageId: any,
    requestType: any,
    request_status: any,
    linked_delegation: any,
    request_stage: any,
    delegate_to_secondary: any,
    implementer_type_id: any,
    request_purpose: any
  ) => {
    setMenuData({
      anchorEl: e.target,
      row: {
        request_id: rowId,
        request_stage_id: stageId,
        request_type: requestType,
        request_status: request_status,
        linked_delegation: linked_delegation,
        request_stage: request_stage,
        delegate_to_secondary: delegate_to_secondary,
        implementer_type_id: implementer_type_id,
        request_purpose
      },
    });
  };

  return {
    open,
    styleClasses,
    tableColumnsForPending,
    implementationSummaryData,
    isLoadingPending,
    filters,
    setFilters,
    tableParametersPending,
    setDateRangeFilter,
    setTableParametersPending,
    requestIdFilter,
    requestorNameFilter,
    requestTypeFilter,
    myLastActionFilter,
    currentStatusFilter,
    setCloseFilter,
    closeFilter,
    datepickerProps,
    setDatepickerProps,
    setDateRangeValue,
    menuData,
    setMenuData,
    clearValueHandler,
    emptyFilters,
    requestOnBadge
  };
}

export default useImplementationSummaryHook;
