/* eslint-disable react/require-default-props */
import statusBadgeStyles from './status-badge-styles';
import StatusProgress from '../../components/status-progress';

const getStatusBackground = (status: string) => {
  switch (status.toLowerCase()) {
    case 'draft':
      return '#DBEDFF';
    case 'rejected':
      return '#FFE2D8';
    case 'approved - pending implementation':
      return '#D4FFEC';
    case 'remediation requested':
      return '#F9F7B7';
    case 'archived':
      return '#EFE8FC';
    case 'completed':
      return '#9AFFFB';
    case 'pending approval':
    default:
      return '#FFF';
  }
};

const getStatusFontColor = (status: string) => {
  switch (status.toLowerCase()) {
    case 'draft':
      return '#0080FF';
    case 'rejected':
      return '#EF5B2B';
    case 'approved - pending implementation':
      return '#00B5B1';
    case 'remediation requested':
      return '#8B7B1E';
    case 'pending approval':
      return '#028886';
    case 'archived':
      return '#79698E';
    case 'completed':
      return '#007573';
    default:
      return 'default';
  }
};

const delegationStages = [
  { label: 'L1', index: 1 },
  { label: 'L2', index: 2 },
  { label: 'L3', index: 3 },
  { label: 'L4', index: 4 },
];
const sarStages = [
  { label: 'L1', index: 1 },
  { label: 'L2', index: 2 },
  { label: 'L3', index: 3 },
  { label: 'L4', index: 4 },
  { label: 'L5', index: 5 },
  { label: 'L6', index: 6 },
];

function StatusBadge(
  {
    status,
    requestType,
    stage,
    remediationCount,
    isUpdateRequested,
    stageId,
    requestPurpose,
  }:
    {
      status: any,
      requestType: string,
      stage?: string,
      remediationCount?: string,
      isUpdateRequested?: boolean,
      stageId: string | number,
      requestPurpose: string,
    },
) {
  const statusClasses = statusBadgeStyles(
    {
      background: getStatusBackground(status),
      color: getStatusFontColor(status),
    },
  );
  const getStagesArray = () => {

    if (requestType?.toLowerCase() === 'delegation' && requestPurpose === 'Connect to / manage a server or computer') {
      return delegationStages;
    } else {
      return sarStages;
    }
  };



  switch (status.toLowerCase()) {
    case 'draft':
    case 'approved - pending implementation':
    case 'rejected':
    case 'archived':
    case 'completed':
      return (
        <span className={statusClasses.statusBadge} data-testid="status-badge">
          {`${status} ${isUpdateRequested ? '(Update Requested)' : ''}`}
        </span>
      );
    case 'remediation requested':
      return (
        <span className={statusClasses.statusBadge} data-testid="status-badge">
          {`${status} (${remediationCount})`}
        </span>
      );
    case 'pending approval':
      return (
        <div data-testid="status-badge">
          <div
            className={`${statusClasses.statusBadge} ${statusClasses.pendingApproval}`}
          >
            {`${status} (${stage || ''})`}
            {/* {`${status}`} */}
          </div>
          <StatusProgress
            stagesArray={getStagesArray()}
            currentStage={stageId}
          />
        </div>
      );
    default:
      return <span data-testid="status-badge">{status}</span>;
  }
}

export default StatusBadge;
