import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { format, isDate, isWithinInterval } from 'date-fns';
import _, { isEqual } from 'lodash';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import approvalSummaryStyles from '../approval-summary-styles';
import {
  approvalSummaryColumnMapping,
  createColumns,
  emptyFilters,
  IFiltersObj,
  initialTableParameters,
  ITableParameters,
  createRowCells,
  IPropsFilter,
  IFilterApprovalSummaryData,
} from './approvalSummaryUtils';
import { fetchAllApprovalSummaryData } from '../../../api-services/approval-summary';
import StatusBadge from '../StatusBadge';
import { ReactComponent as Sorting } from '../../../assets/icons/sorting_asc_inactive.svg';
import { ReactComponent as SortingAscending } from '../../../assets/icons/sorting_asc.svg';
import { ReactComponent as SortingDescending } from '../../../assets/icons/sorting_desc.svg';
import useDaterangePicker from '../../../components/date-range-picker/useDaterangePicker';
import { IPendingApprovalSummaryData } from '../pendingAprroverSummary/pendingSummaryUtils';
import { useSnackbar } from 'notistack';
// import { ReactComponent as VisibilityIcon } from '../../assets/icons/eye_b.svg';

function useApprovalSummaryHook() {
  const lastActions = [{ id: "Approved", value: 'Approved' }, { id: 'Remediation Requested', value: 'Remediation Requested' }, { id: 'Rejected', value: 'Rejected' }];
  const currentStatusActions = [{ id: 'Draft', value: 'Draft' }, { id: 'Pending Approval', value: 'Pending Approval' }, { id: 'Remediation Requested', value: 'Remediation Requested' }, { id: 'Rejected', value: 'Rejected' }, { id: 'Approved', value: 'Approved' }, { id: 'Completed', value: 'Completed' }];
  const enqueueSnackbar = useSnackbar();
  const history = useHistory();
  const styleClasses = approvalSummaryStyles();
  const [approvalSummaryData, setApprovalummaryData] = useState({
    count: 0,
    results: [],
    pageSize: 10
  });
  const [approvalSummaryDataFromApi, setApprovalSummaryDataFromApi] = useState([]);
  const [tempApprovalSummaryDataFromApi, setTempApprovalSummaryDataFromApi] = useState([]);
  const [isLoading, setIsLoading] = useState({
    tableLoading: false,
    filters: {
      requestId: false,
      requestorName: false,
      currentStatus: false,
      myLastAction: false,
      requestType: false,
      requestedOn: false,
      assignedOn: false,
    },
  });
  const [filters, setFilters] = useState<IFiltersObj>(emptyFilters);
  const [tableParameters, setTableParameters] = useState<ITableParameters>(initialTableParameters);
  const [requestIdFilter, setRequestIdFilters] = useState([]);
  const [requestorNameFilter, setRequestorNameFilters] = useState<IPropsFilter[]>([{ id: '', value: '' }]);
  const [currentStatusFilter, setCurrentStatusFilters] = useState<IPropsFilter[]>([{ id: '', value: '' }]);
  const [myLastActionFilter, setMyLastActionFilters] = useState<IPropsFilter[]>([{ id: '', value: '' }]);
  const [requestTypeFilter, setRequestTypeFilters] = useState([]);
  const [requestedOnFilter, setRequestOnFilters] = useState([]);
  const [assignedOnFilter, setAssignedOnFilters] = useState([]);
  const [closeFilter, setCloseFilter] = useState(false);
  const [menuData, setMenuData] = useState(
    {
      anchorEl: null,
      row: {
        rowId: null,
        stageId: '',
        requestType: '',
        linkedDelegationId: null,
        delegateToSecondary: null,
        request_purpose: null
      },
    },
  );

  const {
    datepickerProps,
    setDatepickerProps,
    setDateRangeValue,
  } = useDaterangePicker({ isSingleDate: true });

  const fetchFiltersValues = () => {
    setIsLoading((loadingState) => ({
      ...loadingState,
      filters: {
        ...loadingState.filters,
      },
    }));
  }


  const requestStagehandler = (stage: string) => {
    if (stage === 'L1') {
      return 1;
    } else if (stage === 'L2') {
      return 2;
    } else if (stage === 'L3') {
      return 3;
    } else if (stage === 'L4') {
      return 4;
    }
    return '';
  };

  const openDelegation = (row: any) => {

    if (row.request_type === 'Delegation') {
      if (row.request_purpose && row.request_purpose == "Connect to / manage a server or computer") {
        history.push(`/myApprovalTasks/approvalSummary/${row.request_id}`);
      } else {
        history.push(`/myApprovalTasks/approvalSummarySar/${row.request_id}`);
      }
    } else {
      history.push(`/myApprovalTasks/approvalSummaryPar/${row.request_id}`);
    }
  };
  const openMenu = (
    e: any,
    rowId: any,
    stageId: any,
    requestType: any,
    linkedDelegationId: any,
    delegateToSecondary: any,
    request_purpose: any
  ) => {
    setMenuData({
      anchorEl: e.target,
      row:
      {
        rowId,
        stageId,
        requestType,
        linkedDelegationId,
        delegateToSecondary,
        request_purpose,
      },
    });
  };
  const formatDate = (dateValue: any) => (dateValue && isDate(new Date(dateValue)) ? format(new Date(dateValue), 'dd MMM yyyy') : '');

  useEffect(() => {

    const rowsArray = tempApprovalSummaryDataFromApi.map((row: any) => {

      const cellArray = Object.entries(row)
        .map(([key, value]) => {
          let cellData = null;
          switch (key) {
            case 'request_number':
              cellData = createRowCells(approvalSummaryColumnMapping.request_number, `${value}`, `${row.id}-${value}`, 1, () => openDelegation(row), { textDecoration: 'underline', color: '#0080FF' },);
              break;
            case 'requestor_name':
              cellData = createRowCells(approvalSummaryColumnMapping.requestorName, `${value || ''}`, `${row.id}-${value || ''}`, 2);
              break;
            case 'request_type':
              cellData = createRowCells(approvalSummaryColumnMapping.requestType, `${value || ''}`, `${row.id}-${value || ''}`, 3);
              break;
            case 'requested_on':
              cellData = createRowCells(approvalSummaryColumnMapping.requestedOn, value === null ? '' : `${formatDate(value)}`, `${row.id}-${value || ''}`, 4);
              break;
            case 'assigned_on':
              cellData = createRowCells(approvalSummaryColumnMapping.assignedOn, value === null ? '' : `${formatDate(value)}`, `${row.id}-${value || ''}`, 5);
              break;
            case 'last_request_stage':
              cellData = createRowCells(approvalSummaryColumnMapping.last_request_stage, value === null ? '' : `${value}`, `${row.id}-${value || ''}`, 6);
              break;
            case 'my_last_action':
              cellData = createRowCells(approvalSummaryColumnMapping.myLastAction, row.actioned_on ? `${value}` === 'Remediation' ? `${value} requested on ${formatDate(row.actioned_on)}` : `${value}  on ${formatDate(row.actioned_on)}` : '', `${row.id}-${value || ''}`, 7);
              break;
            case 'current_status':
              cellData = createRowCells(approvalSummaryColumnMapping.currentStatus, <StatusBadge requestPurpose={row.request_purpose} status={value} remediationCount={row.remediation_count} requestType={row.request_type} stage={row.request_stage} stageId={row.request_stage_priority} />, `${row.id}-${value || ''}`, 8);
              break;
            default: break;
          }
          return cellData;
        }).filter((obj: any) => obj);
      if (row.linked_delegation) {
        cellArray.push(
          createRowCells(
            approvalSummaryColumnMapping.actions,
            <MoreVertIcon
              className={`${styleClasses.moreIcon} ${!!menuData.anchorEl && styleClasses.moreIconActive}`}
            />,
            `more-actions-${row.id}`,
            9,
            (e) => openMenu(
              e,
              row.request_id,
              row.request_stage_id,
              row.request_type.slice(0, 3).toLowerCase(),
              row.linked_delegation,
              row.delegate_to_secondary,
              row.request_purpose,
            ),
            { textAlign: 'center' },
          ),
        );
      } else {
        cellArray.push(
          createRowCells(
            approvalSummaryColumnMapping.actions,
            '',
            `more-actions-${row.id}`,
            9,
            () => null,
            { textAlign: 'center' },
          ),
        );
      }
      return cellArray;
    })
    setApprovalummaryData((prevState: any) => ({ ...prevState, results: [...rowsArray] }));
  }, [tempApprovalSummaryDataFromApi]);


  // Set delegation Data onload once 
  const fetchAllApprovalSummary = async () => {
    // @ts-ignore */
    await fetchAllApprovalSummaryData().then((res: any) => {
      setApprovalSummaryDataFromApi(res.data);
      setTempApprovalSummaryDataFromApi(res.data);
      // setApprovalSummaryDataFromApi(res.data.sort((a: any, b: any) => b.requested_on.localeCompare(a.requested_on)));
      // setTempApprovalSummaryDataFromApi(res.data.sort((a: any, b: any) => b.requested_on.localeCompare(a.requested_on)));

      // @ts-ignore */
      const requestorName = [...new Set(res.data?.map(({ requestor_name }) => requestor_name))].map(e => res.data?.find(({ requestor_name }) => requestor_name == e));
      setRequestorNameFilters(requestorName?.map((requestorNameObj: { requestor_name: string }) => ({
        id: requestorNameObj.requestor_name,
        value: requestorNameObj.requestor_name === "" ? "(Blanks)" : requestorNameObj.requestor_name,
      })))

      // @ts-ignore */
      const requestType = [...new Set(res.data?.map(({ request_type }) => request_type))].map(e => res.data?.find(({ request_type }) => request_type == e));
      // @ts-ignore */
      setRequestTypeFilters(requestType?.map((requestTypeObj: { request_type: string }) => ({
        id: requestTypeObj.request_type,
        value: requestTypeObj.request_type === "" ? "(Blanks)" : requestTypeObj.request_type,
      })))

      // @ts-ignore */
      setMyLastActionFilters(lastActions);
      setCurrentStatusFilters(currentStatusActions);
      setRequestIdFilters(res.data?.map((requestIdObj: { request_number: string }) => ({
        id: requestIdObj.request_number,
        value: requestIdObj.request_number,
      })));

      setIsLoading((loadingState) => ({ ...loadingState, tableLoading: false }));
    }).catch((error: string) => {
      // enqueueSnackbar('Something went wrong! Please try again', { variant: 'error' });
      setIsLoading((loadingState) => ({ ...loadingState, tableLoading: false }));
    })
  }

  useEffect(() => {
    console.log('inside hook')
    fetchAllApprovalSummary();
    fetchFiltersValues();
    setIsLoading((loadingState) => ({ ...loadingState, tableLoading: true }));
  }, []);

  const setDateRangeFilter = () => {
    setCloseFilter(true);
    let dateFilterData = approvalSummaryDataFromApi.filter((obj: IPendingApprovalSummaryData) => {
      console.log("datepickerProps.dateRange.startDate", datepickerProps.dateRange)
      if (isWithinInterval(new Date(`${obj.assigned_on}`), {
        // @ts-ignore */
        start: datepickerProps.dateRange.startDate,
        // @ts-ignore */
        end: datepickerProps.dateRange.endDate,
      }) || formatDate(obj.assigned_on) == formatDate(datepickerProps.dateRange.endDate))
        if (isWithinInterval(new Date(`${obj.requested_on}`), {
          // @ts-ignore */
          start: datepickerProps.dateRange.startDate,
          // @ts-ignore */
          end: datepickerProps.dateRange.endDate,
        }) || formatDate(obj.requested_on) === formatDate(datepickerProps.dateRange.endDate))
          return true;
      return false;
    }
    )
    setTempApprovalSummaryDataFromApi([...dateFilterData]);
    setTableParameters((prevParameters: any) => ({
      ...prevParameters,
      page: Math.ceil(dateFilterData.length / approvalSummaryData.pageSize),
    }));
  };

  // clear value handler
  const clearValueHandler = () => {
    setIsLoading((loadingState) => ({ ...loadingState, tableLoading: false }));
    setDatepickerProps({
      isSingleDate: false,
      dateRange:
      {
        startDate: null,
        endDate: null,
      },
    })
    setFilters(emptyFilters)
    setTempApprovalSummaryDataFromApi(approvalSummaryDataFromApi)
  }

  // for sorting
  useEffect(() => {
    tableParameters.orderBy.column !== '' ? createUserManagementSummary() : setTempApprovalSummaryDataFromApi(approvalSummaryDataFromApi);
  }, [tableParameters.orderBy.column, tableParameters.orderBy.order]);

  // For sorting Column Name
  const createUserManagementSummary = () => {
    console.log("s", tableParameters.orderBy.column)
    setTempApprovalSummaryDataFromApi((prevData) => [...prevData.sort(function (valueOne: any, valueTwo: any) {
      if (valueOne[tableParameters.orderBy.column]?.toLowerCase() < valueTwo[tableParameters.orderBy.column]?.toLowerCase())
        return tableParameters.orderBy.order === 'asc' ? -1 : 1;
      if (valueOne[tableParameters.orderBy.column]?.toLowerCase() > valueTwo[tableParameters.orderBy.column]?.toLowerCase())
        return tableParameters.orderBy.order === 'desc' ? -1 : 1;
      return 0;
    }
    ),
    ]);
  };

  const handleSort = (e: any) => {
    setTableParameters((prevParameters) => {
      const newOrderBy = { ...prevParameters.orderBy };
      if (e.target.id !== newOrderBy.column) {
        newOrderBy.order = "asc";
        newOrderBy.column = e.target.id;
      } else if (prevParameters.orderBy.order === "desc") {
        newOrderBy.order = "asc";
        newOrderBy.column = "";
      } else {
        newOrderBy.order = "desc";
      }
      return { ...prevParameters, orderBy: { ...newOrderBy } };
    });
  };
  const getSortingIcon = (columnId: string) => {
    if (tableParameters.orderBy.column === columnId) {
      return tableParameters.orderBy.order === 'desc' ? <SortingDescending /> : <SortingAscending />;
    }
    return <Sorting />;
  };
  const tableColumns = [
    createColumns(1, 'REQ ID', () => null, approvalSummaryColumnMapping.requestId, tableParameters.orderBy.column === approvalSummaryColumnMapping.requestId, () => null, { width: '75px' }),
    createColumns(2, 'Requestor Name', getSortingIcon(approvalSummaryColumnMapping.requestorName), approvalSummaryColumnMapping.requestorName, tableParameters.orderBy.column === approvalSummaryColumnMapping.requestorName, (e) => handleSort(e), { width: '130px' }),
    createColumns(3, 'Request Type', getSortingIcon(approvalSummaryColumnMapping.requestType), approvalSummaryColumnMapping.requestType, tableParameters.orderBy.column === approvalSummaryColumnMapping.requestType, (e) => handleSort(e), { width: '100px' }),
    createColumns(4, 'Requested On', getSortingIcon(approvalSummaryColumnMapping.requestedOn), approvalSummaryColumnMapping.requestedOn, tableParameters.orderBy.column === approvalSummaryColumnMapping.requestedOn, (e) => handleSort(e), { width: '115px' }),
    createColumns(5, 'Assigned On', getSortingIcon(approvalSummaryColumnMapping.assignedOn), approvalSummaryColumnMapping.assignedOn, tableParameters.orderBy.column === approvalSummaryColumnMapping.assignedOn, (e) => handleSort(e), { width: '115px' }),
    createColumns(6, 'Stage', getSortingIcon(approvalSummaryColumnMapping.last_request_stage), approvalSummaryColumnMapping.last_request_stage, tableParameters.orderBy.column === approvalSummaryColumnMapping.last_request_stage, (e) => handleSort(e), { width: '115px' }),
    createColumns(7, 'My Last Action', getSortingIcon(approvalSummaryColumnMapping.myLastAction), approvalSummaryColumnMapping.myLastAction, tableParameters.orderBy.column === approvalSummaryColumnMapping.myLastAction, (e) => handleSort(e), { width: '115px' }),
    createColumns(8, 'Current Status', getSortingIcon(approvalSummaryColumnMapping.currentStatus), approvalSummaryColumnMapping.currentStatus, tableParameters.orderBy.column === approvalSummaryColumnMapping.currentStatus, (e) => handleSort(e), { width: '115px' }),
    createColumns(9, 'Actions', null, approvalSummaryColumnMapping.actions, false, undefined, { width: '20px' }),
  ];


  const fetchFilteredDataWithDebounce = (filtersObject: any) => {
    //console.log("filtersObject@@@", filtersObject)
    if (filtersObject?.requestId?.length > 0) {
      let requestIdData = approvalSummaryDataFromApi.filter((obj: IFilterApprovalSummaryData) => (filtersObject?.requestId.find((item: string) => item === obj.request_number))
      )
      setTempApprovalSummaryDataFromApi([...requestIdData]);
      setTableParameters((prevParameters: any) => ({
        ...prevParameters,
        page: Math.ceil(requestIdData.length / approvalSummaryData.pageSize),
      }));
    }
    else if (filtersObject?.requestorName?.length > 0) {
      let requestorNameData = approvalSummaryDataFromApi.filter((obj: IFilterApprovalSummaryData) => (filtersObject?.requestorName.find((item: string) => item === obj.requestor_name)))
      setTempApprovalSummaryDataFromApi([...requestorNameData]);
      setTableParameters((prevParameters: any) => ({
        ...prevParameters,
        page: Math.ceil(requestorNameData.length / approvalSummaryData.pageSize),
      }));
    }
    else if (filtersObject?.requestType?.length > 0) {
      let requestTypeData = approvalSummaryDataFromApi.filter((obj: IFilterApprovalSummaryData) =>
        (filtersObject?.requestType.find((item: string) => item == obj.request_type))
      )
      setTempApprovalSummaryDataFromApi([...requestTypeData]);
      setTableParameters((prevParameters: any) => ({
        ...prevParameters,
        page: Math.ceil(requestTypeData.length / approvalSummaryData.pageSize),
      }));
    }
    else if (filtersObject?.currentStatus?.length > 0) {
      let currentStatusData = approvalSummaryDataFromApi.filter((obj: IFilterApprovalSummaryData) =>
        (filtersObject?.currentStatus.find((item: string) => item == obj.current_status))
      )
      setTempApprovalSummaryDataFromApi([...currentStatusData]);
      setTableParameters((prevParameters: any) => ({
        ...prevParameters,
        page: Math.ceil(currentStatusData.length / approvalSummaryData.pageSize),
      }));
    }
    else if (filtersObject?.myLastAction?.length > 0) {
      let myLastActionData = approvalSummaryDataFromApi.filter((obj: IFilterApprovalSummaryData) =>
        (filtersObject?.myLastAction.find((item: string) => item == obj.my_last_action))
      )
      setTempApprovalSummaryDataFromApi([...myLastActionData]);
      setTableParameters((prevParameters: any) => ({
        ...prevParameters,
        page: Math.ceil(myLastActionData.length / approvalSummaryData.pageSize),
      }));
    }
    else {
      setTempApprovalSummaryDataFromApi(approvalSummaryDataFromApi)
    }
  }

  useEffect(() => {
    fetchFilteredDataWithDebounce(filters);
  }, [filters]);


  return {
    styleClasses,
    tableColumns,
    approvalSummaryData,
    isLoading,
    filters,
    setFilters,
    tableParameters,
    setTableParameters,
    requestIdFilter,
    requestorNameFilter,
    currentStatusFilter,
    myLastActionFilter,
    requestTypeFilter,
    requestedOnFilter,
    assignedOnFilter,
    setCloseFilter,
    closeFilter,
    menuData,
    setMenuData,
    setDateRangeFilter,
    clearValueHandler,
    datepickerProps,
    setDatepickerProps,
    setDateRangeValue,
    emptyFilters,
  };
}

export default useApprovalSummaryHook;
