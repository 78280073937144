import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    FormikValues,
    FormikHelpers,
} from 'formik';

import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { IFormikContainerProps, emptySchemaCheck, fieldsOfSection, fieldsOfSectionForSubsection, staticDiffernceData } from './formikContainerUtils';
import { parBody } from '../../../constants/parBody';
import { RootState } from '../../../appStore/store';
import { IModalState } from './formikContainerUtils';
import modalStyles from '../../custom-modal/modalStyles';
import { FormikStepper } from './formikStepper';
import CustomButton from '../../button';
import { postNewRequestPar } from '../../../api-services/par';
import { FormikStep } from './formikStep';;
import { format, isDate } from 'date-fns';
import CustomModal from '../../custom-modal';
import {
    useHistory,
    useLocation,
} from 'react-router-dom';
import RequestorAndTeamDetails from './formSteps/requestorAndTeamDetails';
import { postNewRequest } from '../../../api-services/delegation-module';
import AccountType from './formSteps/accountType';
import ServerInformation from './formSteps/serverInformation';
import SARReason from './formSteps/sarReason';
import ServiceAccountDetails from './formSteps/serviceAccountDetails';
import ServiceAccountPremissions from './formSteps/serviceAccountPremissions';
import SpnAndDelegationDetails from './formSteps/spnAndDelegationDetailss';
import PasswordSecurityAndPolicy from './formSteps/passwordSecurityAndPolicy';
import delegationBody from '../../../constants/delegationBody';
import { postDelegationFieldHistoryData } from '../../../api-services/review-delegation';
import { routeCheckForAdminSummary, routeCheckForApprovalSummary, routeCheckForPendingImplementation } from './formSteps/routingConditional';
import ImplementationSar from './formSteps/implementation';

const initialValuesData = {
    ...delegationBody,
}

function FormikContainerSar({
    ...props
}: IFormikContainerProps) {
    const history = useHistory();
    const location = useLocation();
    const formatDate = (dateValue: any) => (dateValue && isDate(new Date(dateValue)) ? format(new Date(dateValue), 'yyyy-MM-dd') : '');
    const { enqueueSnackbar } = useSnackbar();
    const { submitSarCommentData } = useSelector((state: RootState) => state);

    const [modalData, setModalData] = useState<IModalState>({
        show: false,
        modalHeader: '',
        modalContent: null,
        modalActions: null,
    });
    const centerButton = false;
    const modalClasses = modalStyles({ centerButton });

    const checkIsDisabled = () => !(props.data.status_label === 'Draft' || props.data.status_label !== 'Pending Approval') || routeCheckForApprovalSummary() || props.data.status_label === 'Rejected' || props.data.status_label === 'Completed' || props.data.status_label === 'Rejected' || props.data.status_label === "Approved - Pending Implementation" || routeCheckForAdminSummary();

    const historyDataOfForm = JSON.parse(localStorage.getItem('historyDataOfMainSchemaSar') || '');
    return (
        <>
            <FormikStepper
                initialValues={{ ...initialValuesData, ...props.data, request_id: props.requestId }}
                onSubmit={async (values: FormikValues, formikHelpers: FormikHelpers<FormikValues>) => {
                    if (props.data.remediation_count > 0) {
                        const changedValues = Object.keys(values).filter(key => values[key] !== historyDataOfForm[key])
                        const newArray = changedValues.filter(v => !staticDiffernceData.includes(v));
                        let finalChangedData: any = [];
                        newArray.length > 0 && newArray.map((item) => {
                            finalChangedData.push({
                                update_type: emptySchemaCheck.get(item),
                                section: fieldsOfSection.get(item),
                                sub_section: fieldsOfSectionForSubsection.get(item),
                                value: historyDataOfForm[item],
                                role_name_section: '',
                                user_details_section: ''
                            })
                        })
                        const historyPostBody = {
                            request_id: props.requestId,
                            is_submitted: false,
                            data: finalChangedData.filter((item: any) => item.section !== undefined && item.sub_section !== undefined)
                        }
                        if (finalChangedData.length > 0) {
                            postDelegationFieldHistoryData(historyPostBody).then((response) => {
                                console.log(response)
                            }).catch(error => error);
                        }
                    }
                    formikHelpers.setSubmitting(true);
                    await postNewRequest({
                        ...values, event: props.requestId !== '' ? 'update' : 'create',
                        status: '4',
                        request_id: props.requestId,
                        request_type: 1,
                        del_signed_by: ''
                    }).then((res) => {
                        if (res.status) {
                            formikHelpers.resetForm({ values: res.data });
                            setModalData({
                                show: true,
                                modalHeader: 'Success',
                                modalContent: (
                                    <div>
                                        <div className={modalClasses.modalContentDiv}>
                                            <div>
                                                {values.remediation_count === 0 && values.request_to_update_count === 0 ? `Your Delegation request has been created successfully` : `Your Delegation request has been updated and re-submitted successfully`}
                                            </div>
                                            <div>
                                                Your Delegation is {res.data.request_number}
                                            </div>
                                        </div>
                                        <div className={modalClasses.modalTextDiv}>
                                            <span className={modalClasses.spanText}>
                                                {'Note: '}
                                            </span>
                                            You will shortly receive an email confirmation with a link to your Delegation. You can use this link to return to the GPAG application to review the progress of your request. Please note that while in the review and approvals stages you will not be able to edit your request.<br /> <br />  You may be contacted for further information by the approvers during their review. Your prompt response to any queries will allow your request to be dealt with as quickly as possible.
                                        </div>
                                    </div>
                                ),
                                modalActions: (
                                    <div className={modalClasses.modalActionDiv}>
                                        <CustomButton
                                            variant="contained"
                                            actionType="primary"
                                            disableMargin
                                            onClick={() => history.push('/delegation')}
                                        >
                                            Ok
                                        </CustomButton>
                                    </div>
                                ),
                            });
                        } else {
                            console.error(res);
                        }
                    }).catch((err) => {
                        console.log(err);
                        enqueueSnackbar('Something went wrong, please try again', { variant: 'error' });
                    }).finally(() => {
                        formikHelpers.setSubmitting(false);
                    });

                }}
                requestId={props.requestId}
                requestData={props.data}
            >
                <FormikStep
                    validationSchema={Yup.object({
                        onSave: Yup.boolean(),
                        sar_function_of_sar: Yup.string().required('Please specify function'),
                        sar_justification_of_sar: Yup.string().required('Please specify justification'),
                    })}
                >
                    <SARReason isDisabled={checkIsDisabled()} />
                </FormikStep>
                <FormikStep
                    validationSchema={Yup.object({
                        onSave: Yup.boolean(),
                        first_name: Yup.string().required('Please specify first Name'),
                        last_name: Yup.string().required('Please specify last name'),
                        email: Yup.string().required('Please specify email'),
                        phone: Yup.string()
                            .required('Please specify phone')
                            .test('len', 'Invalid phone', (val) => {
                                if (val && val.length >= 7) return true;
                                return false;
                            }),
                        company: Yup.string().required('Please specify company'),
                        sar_third_party_company: Yup.string()
                            .when('company', {
                                is: "3rd Party",
                                then: Yup.string().required('Please specify 3rd party company'),
                                otherwise: Yup.string().nullable(),
                            }),
                        sar_not_listed_third_party_company: Yup.string()
                            .when('sar_third_party_company', {
                                is: "Not listed",
                                then: Yup.string().required('Please specify not listed company name'),
                                otherwise: Yup.string().nullable(),
                            }),
                        sar_office: Yup.string().required('Please specify office'),
                        sar_department: Yup.string().required('Please specify department'),
                        team_name: Yup.string().required('Please specify team name'),
                        city: Yup.string().required('Please specify city'),
                        country: Yup.string().required('Please specify country'),

                        manager_first_name: Yup.string()
                            .when('company', {
                                is: (val: string) => val !== '3rd Party',
                                then: Yup.string().required(`Please specify manager's first name`),
                                otherwise: Yup.string(),
                            }),
                        manager_last_name: Yup.string()
                            .when('company', {
                                is: (val: string) => val !== '3rd Party',
                                then: Yup.string().required(`Please specify manager's last name`),
                                otherwise: Yup.string(),
                            }),
                        manager_phone: Yup.string()
                            .when('company', {
                                is: (val: string) => val !== '3rd Party',
                                then: Yup.string().test("len", "Please specify manager's phone", (val) => {
                                    if (val && val.length >= 7) return true;
                                    return false;
                                }),
                                otherwise: Yup.string(),
                            }),
                        manager_email: Yup.string()
                            .when('company', {
                                is: (val: string) => val !== '3rd Party',
                                then: Yup.string().required(`Please specify manager's email`),
                                otherwise: Yup.string(),
                            }),
                        sar_third_party_manager_first_name: Yup.string().when('company', {
                            is: "3rd Party",
                            then: Yup.string().required(`Please specify 3rd party manager's first name`),
                            otherwise: Yup.string(),
                        }),
                        sar_third_party_manager_last_name: Yup.string().when('company', {
                            is: "3rd Party",
                            then: Yup.string().required(`Please specify 3rd party manager's last name`),
                            otherwise: Yup.string(),
                        }),
                        sar_third_party_manager_phone: Yup.string().when('company', {
                            is: "3rd Party",
                            then: Yup.string().test("len", "Please specify valid phone number", (val) => {
                                if (val && val.length >= 7) return true;
                                return false;
                            }),
                            otherwise: Yup.string(),
                        }),
                        sar_third_party_manager_email: Yup.string()
                            .when('company', {
                                is: "3rd Party",
                                then: Yup.string().required(`Please specify 3rd party manager's email`),
                                otherwise: Yup.string(),
                            }),
                        sar_point_of_escalation: Yup.string().required(`Please specify email for point of escalation`)
                    })
                    }
                >
                    <RequestorAndTeamDetails isDisabled={checkIsDisabled()} />
                </FormikStep>

                <FormikStep
                    validationSchema={Yup.object({
                        onSave: Yup.boolean(),
                        sar_city_code: Yup.string().required('Please specify city code'),
                        sar_company_code: Yup.string().required('Please specify company code'),
                        sar_application_name: Yup.string().required('Please specify application name'),
                        sar_domain_location: Yup.string().required('Please specify domain'),
                        sar_user_logon_name_upn: Yup.string(),
                        sar_pre_windows_2000_username: Yup.string(),
                    })}
                >
                    <ServiceAccountDetails isDisabled={checkIsDisabled()} />
                </FormikStep>
                <FormikStep
                    validationSchema={Yup.object({
                        onSave: Yup.boolean(),
                        sar_is_complex_service_account: Yup.boolean().required('Please specify account type'),
                        sar_function_other: Yup.string(),
                        sar_does_require_interactive_logon: Yup.boolean().when('sar_is_complex_service_account', {
                            is: true,
                            then: Yup.boolean().required('Please specify option for interactive logon'),
                            otherwise: Yup.boolean(),
                        }),
                        sar_justification_for_interactive_logon: Yup.string().when('sar_does_require_interactive_logon', {
                            is: true,
                            then: Yup.string().required('Please provide justification for interactive logon'),
                            otherwise: Yup.string(),
                        }),
                        sar_justification: Yup.string().required('Please specify account function justification'),
                        sar_logon_as_batch_job: Yup.boolean(),
                        sar_logon_as_service: Yup.boolean(),
                    })}
                >
                    <AccountType isDisabled={checkIsDisabled()} />
                </FormikStep>
                <FormikStep
                    validationSchema={Yup.object({
                        onSave: Yup.boolean(),
                        sar_server_tier: Yup.array()
                            .required("Please choose server tier")
                            .nullable(),
                        sar_is_sar_servers_usage: Yup.boolean().required('Please specify service account single server'),
                        sar_servers_hostname: Yup.string().required('Please specify server hostnames'),
                    })}
                >
                    <ServerInformation isDisabled={checkIsDisabled()} />
                </FormikStep>
                <FormikStep
                    validationSchema={Yup.object({
                        onSave: Yup.boolean(),
                        sar_user_rights_and_privileges: Yup.string().required('Please specify user rights and privileges'),
                        sar_group_membership: Yup.string().required('Please specify group membership'),
                        sar_external_internal_ip_range: Yup.string().required('Please specify external and internal IP details'),
                        sar_external_internal_ip_details: Yup.string().when('sar_domain_location', {
                            is: (val: string) => val !== 'ad.insidemedia.net',
                            then: Yup.string().required('Please specify IP details'),
                            otherwise: Yup.string(),
                        }),
                    })}
                >
                    <ServiceAccountPremissions isDisabled={checkIsDisabled()} />
                </FormikStep>
                <FormikStep
                    validationSchema={Yup.object({
                        onSave: Yup.boolean(),
                        sar_spn_and_or_delegation: Yup.string(),
                        sar_spn_list: Yup.string().when('sar_spn_and_or_delegation', {
                            is: (val: string) => val !== 'Delegationonly',
                            then: Yup.string().required('Please specify SPN list'),
                            otherwise: Yup.string(),
                        }),
                        sar_delegation_details: Yup.string().when('sar_spn_and_or_delegation', {
                            is: (val: string) => val !== 'SPNonly',
                            then: Yup.string().required('Please specify delegation details'),
                            otherwise: Yup.string(),
                        }),
                        sar_delegation_reason: Yup.string(),
                        sar_is_any_service_kerberos_only: Yup.boolean(),
                        sar_is_specified_service_kerberos_only: Yup.boolean(),
                        sar_is_specified_service_any_protocol: Yup.boolean(),
                        // sar_delegation_list: Yup.array()
                        //     .min(1, "You can't leave this blank.")
                        //     // .required("You can't leave this blank.")
                        //     .nullable(),
                    })}
                >
                    <SpnAndDelegationDetails isDisabled={checkIsDisabled()} />
                </FormikStep>
                <FormikStep
                    validationSchema={Yup.object({
                        onSave: Yup.boolean(),
                        sar_password_change_procedure: Yup.string().required('Please specify password change procedure'),
                        sar_security_of_password: Yup.string().required('Please specify security of passsword'),
                        sar_password_reset_timing: Yup.string(),
                        sar_reason_for_password_expiry_policy: Yup.string().when('sar_password_reset_timing', {
                            is: (val: string) => val !== '60days(default policy)',
                            then: Yup.string().required('Please specify reason for exception'),
                            otherwise: Yup.string(),
                        }),
                        sar_business_impact: Yup.string().when('sar_password_reset_timing', {
                            is: (val: string) => val !== '60days(default policy)',
                            then: Yup.string().required('Please specify business impact'),
                            otherwise: Yup.string(),
                        }),
                        sar_risk_exposure: Yup.string().when('sar_password_reset_timing', {
                            is: (val: string) => val !== '60days(default policy)',
                            then: Yup.string().required('Please specify risk of exposure'),
                            otherwise: Yup.string(),
                        }),
                        sar_risk_of_sar_non_password_expiring: Yup.string().when('sar_password_reset_timing', {
                            is: (val: string) => val !== '60days(default policy)',
                            then: Yup.string().required('Please specify risk of sar password expiry'),
                            otherwise: Yup.string(),
                        }),
                        sar_risk_if_sar_compromised: Yup.string().when('sar_password_reset_timing', {
                            is: (val: string) => val !== '60days(default policy)',
                            then: Yup.string().required('Please specify risk if service account compromised '),
                            otherwise: Yup.string(),
                        }),
                    })}
                >
                    <PasswordSecurityAndPolicy isDisabled={checkIsDisabled()} />
                </FormikStep>
                {/* for impelmentaion task list */}
                {(props.data.status_label == "Approved - Pending Implementation") && routeCheckForPendingImplementation() && (
                    < FormikStep
                        validationSchema={Yup.object({
                            onSave: Yup.boolean(),
                            sar_password_change_procedure: Yup.string().required('Please specify password change procedure'),
                            sar_security_of_password: Yup.string().required('Please specify security of passsword'),
                            sar_password_reset_timing: Yup.string(),
                            sar_reason_for_password_expiry_policy: Yup.string().when('sar_password_reset_timing', {
                                is: ('Quarterly' || 'Semi-annually' || 'Annually'),
                                then: Yup.string().required('Please specify reason for exception'),
                                otherwise: Yup.string(),
                            }),
                            sar_business_impact: Yup.string().when('sar_password_reset_timing', {
                                is: ('Quarterly' || 'Semi-annually' || 'Annually'),
                                then: Yup.string().required('Please specify business impact'),
                                otherwise: Yup.string(),
                            }),
                            sar_risk_exposure: Yup.string().when('sar_password_reset_timing', {
                                is: ('Quarterly' || 'Semi-annually' || 'Annually'),
                                then: Yup.string().required('Please specify risk of exposure'),
                                otherwise: Yup.string(),
                            }),
                            sar_risk_of_sar_non_password_expiring: Yup.string().when('sar_password_reset_timing', {
                                is: ('Quarterly' || 'Semi-annually' || 'Annually'),
                                then: Yup.string().required('Please specify risk of sar password expiry'),
                                otherwise: Yup.string(),
                            }),
                            sar_risk_if_sar_compromised: Yup.string().when('sar_password_reset_timing', {
                                is: ('Quarterly' || 'Semi-annually' || 'Annually'),
                                then: Yup.string().required('Please specify risk if service account compromised '),
                                otherwise: Yup.string(),
                            }),
                        })}
                    >
                        <ImplementationSar />
                    </FormikStep>
                )}

            </FormikStepper >
            <CustomModal
                open={modalData.show}
                modalHeader={modalData.modalHeader}
                modalContent={modalData.modalContent}
                modalAction={modalData.modalActions}
            />
        </>
    )
}

export default FormikContainerSar;







