import { makeStyles } from '@mui/styles';

interface IStyleProps {
  color: string,
  background: string,
}

const statusBadgeStyles = makeStyles({
  statusBadge: {
    font: 'normal normal normal 14px/15px NotoSerif-Regular',
    letterSpacing: '-0.06px',
    padding: '4px 8px',
    margin: '5.25px 0px',
    borderRadius: '2px',
    whiteSpace: 'nowrap',
    color: (props: IStyleProps) => props.color,
    background: (props: IStyleProps) => props.background,
  },
  pendingApproval: {
    color: '#028886',
    paddingLeft: '0px',
    paddingTop: '0px',
    font: 'normal normal normal 14px/14px NotoSerif-Regular',
    letterSpacing: '-0.1px',
    margin: '0 0 0 8px',
    whiteSpace: 'nowrap',
  },
});

export default statusBadgeStyles;
