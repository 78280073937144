import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    LoginPanel: {
        background: 'white',
        borderRadius: '8px',
        display: 'flex',
        margin: 'auto',
        padding: '32px',
        minWidth: '230px',
        width: '18.75vw',
        maxWidth: '300px',
        flexDirection: 'column',
    },
    BrandLogoDiv: {
        position: 'absolute',
    },
    Image: {
        width: 'auto',
        marginRight: '8px',
        display: 'inline-flex',
        alignSelf: 'center',
    },
    headingStyles:{
              fontFamily: 'Poppins-Bold',
              fontSize: '1.25rem',
              display: 'inline-flex',
              textAlign: 'center',
              color: '#0A2756',
    },
    mainstyle:{
            display: 'flex',
            marginBottom: '32px' }

});

export default useStyles;