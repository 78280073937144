import { Grid } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import approverManagementStyles from "./implementer-management-styles";
import useApproverManagementHook from "./useImplementerManagementHook";
import CustomModal from "../../../components/custom-modal";

function ImplementerManagement() {
  const styleClasses = approverManagementStyles();
  const {
    OnClickStageName,
    handleEditModalOpen,
    modalOpen,
    modalHeaderHandler,
    modalContentHandler,
    handleModalClose,
    modalActionHandler,
    modalData,
    allImplementerTypes,
    expandedPanel,
    handleAccordionChange,
  } = useApproverManagementHook();

  return (
    <>
      <div
        className={styleClasses.pageContainer}
        data-testid="approvermanagement-screen"
      >
        <Grid spacing={2} container className={styleClasses.gridContainer}>
          <Grid item xs={12} className={styleClasses.gridFirst}>
            <div className={styleClasses.gridTitle}>Implementer Type</div>
            {allImplementerTypes.length > 0 &&
              allImplementerTypes
                .map((item: any, index) => (
                  <div style={{ position: "relative" }}>
                    {expandedPanel === "panel" + item.id && (
                      <span
                        className={styleClasses.editBtn}
                        onClick={() => handleEditModalOpen(item, "Delegation")}
                      >
                        Edit
                      </span>
                    )}
                    <Accordion
                      className={styleClasses.accordion}
                      expanded={expandedPanel === "panel" + item.id}
                      onChange={(e) => handleAccordionChange(item.id)}
                      key={item.id}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={item.id}
                        id={item.id}
                        className={styleClasses.accordionSummary}
                      >
                        <Typography className={styleClasses.accTitle}>
                          {item.value}
                        </Typography>
                      </AccordionSummary>

                      <AccordionDetails>
                        {expandedPanel === "panel" + item.id && (
                          <OnClickStageName />
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                ))}
          </Grid>
        </Grid>
      </div>

      {modalOpen && (
        <CustomModal
          modalHeader={modalHeaderHandler(modalData.type)}
          open={modalOpen}
          onClose={handleModalClose}
          modalAction={modalActionHandler(modalData, modalData.type)}
          modalContent={modalContentHandler(modalData, modalData.type)}
        />
      )}
    </>
  );
}
export default ImplementerManagement;
