import { makeStyles } from '@mui/styles';

const landingPageStyles = makeStyles({
  seeMoreAnchor: {
    textDecoration: 'none',
    fontFamily: 'Noto Serif',
    color: '#0080ff',
    '&:hover': {
      color: '#ffa800',
    },
  },
  p16: {
    // padding: '16px',
    padding: '16px 53px 0 10px'
  },
  pb0: {
    paddingBottom: '0',
  },
  illustrationContainer: {
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    position: 'sticky',
    top: '150px',
  },
  titleContainer: {
    display: 'flex', alignItems: 'center', marginBottom: '10px',
  },
  titleIcon: {
    marginRight: '16px',
    marginTop: '16px',
    height: '32px',
  },
  mb16: {
    marginBottom: '16px',
  },
  sectionHeader: {
    display: 'inline-flex',
    fontSize: '15px',
    fontFamily: 'Poppins-Bold',
    position: 'relative',
    color: '#0A2756',
    '&:after': {
      border: '1px solid #FFA800;',
      width: '4ch !important',
      content: '""',
      height: '0px',
      position: 'absolute',
      bottom: '-8px',
    },
  },
  moduleHeader: {
    marginBottom: '5px',
    fontSize: '14px',
    fontFamily: 'Poppins-SemiBold',
    // font: 'normal normal 600 0.875rem/21px Poppins-SemiBold',
    color: '#0A2756',
  },
  moduleSection: {
    display: 'flex',
    padding: '8px 24px 8px 0px',
    flexDirection: 'column',
  },
  paragraphStyling: {
    fontSize: '14px',
    fontFamily: 'NotoSerif-Regular',
    //font: 'normal normal 0.875rem/19px Noto Serif',
    color: '#0A2756',
    letterSpacing: '0px',
    marginTop: '0px',
  },
  footerLeftDiv: {
    justifyContent: 'left',
  },
  footerRightDiv: {
    justifyContent: 'right',
  },
});
export default landingPageStyles;
