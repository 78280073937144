import { makeStyles } from "@mui/styles";

export const formikContainerStyles = makeStyles({
    topDiv: {
      padding: "16px",
    },
    stepperContainer: {
      padding: "32px 7px 16px",
    },
    stepperScoller: {
      height: "calc(100vh - 270px)",
      overflow: "scroll",
      paddingRight: "8px",
    },
    leftSection: {
      display: "flex",
      justifyContent: "space-between",
    },
    reasonLabel: {
      font: "normal normal 600 15px/23px Poppins-SemiBold",
      letterSpacing: "0px",
      color: "#0A2756",
      opacity: "1",
      marginBottom: "10px",
    },
    modalContentDiv: {
      marginBottom: "12px",
      color: "#0A2756",
      font: "normal normal normal 14px/21px Poppins-Medium",
    },
    modalTextDiv: {
      letterSpacing: "0px",
      // maxWidth: "52ch",
      color: "#0A2756",
      font: "normal normal normal 14px/22px NotoSerif-Regular",
    },
    spanText: {
      fontFamily: "NotoSerif-Bold",
    },
    modalActionDiv: {
      display: "flex",
      justifyContent: "right",
    },
    footerActions: {
      display: "inline-flex",
      "& > button": {
        margin: "0 8px !important",
        "&:first-child": {
          marginLeft: "0px !important",
        },
        "&:last-child": {
          marginRight: "0px !important",
        },
      },
    },
    footerActionsRight: {
      float: "right",
    },
    btnMargin:{
      marginLeft:"10px",
    }
  });