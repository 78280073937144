import axios from "../common/axiosInstance";

export const fetchMyPendingImplementationData = async (
  queryStringValue: string
) => {
  const endPoint = `/my_pending_implementation/`;
  const { data } = await axios.get(endPoint);
  return data;
};

export const fetchAllImplementorsAdmin = async () => {
  const endPoint = "get_all_implementer";
  const { data } = await axios.get(endPoint);
  return data;
};

export const fetchTypeWiseImplementer = async (implementer_type: any) => {
  const endPoint = `/get_type_wise_implementer/?implementer_type=${implementer_type}`;
  const { data } = await axios.get(endPoint);
  return data;
};

export const postDelegateToSecondaryImplementerEndPoints = {
  postUpdatedImplementor: "delegate_to_secondary_implementer/",
};

export const submitUpdateImpelementor = async (
  endPoint: string,
  postBody: any
) => {
  const { data } = await axios.post(endPoint, postBody);
  return data;
};

export const fetchImplementerRequestDelegateData = async ({
  implementer_type_id,
  request_type,
  request_id,
}: {
  implementer_type_id?: any;
  request_type?: any;
  request_id?: any;
}) => {
  let requestTypeId;
  if (request_type === "Delegation") {
    requestTypeId = "1";
  } else if (request_type === "PAR") {
    requestTypeId = "2";
  }
  const endPoint = `/implementer_request_delegate_from/?implementer_type=${implementer_type_id}&request_type=${requestTypeId}&request_id=${request_id}`;
  const { data } = await axios.get(endPoint);
  return data;
};

export const updateImplementerStatus = async (postBody: any) => {
  const endPoint = '/implementer_update_status/';
  const { data } = await axios.post(endPoint, postBody);
  return data;
};

export const postStepOneData = async (postBody: any) => {
  const endPoint = '/del_on_premise_implementation_step_one/';
  const { data } = await axios.post(endPoint, postBody);
  return data;
};

export const postStepTwoData = async (postBody: any) => {
  const endPoint = '/del_on_premise_implementation_step_two/';
  const { data } = await axios.post(endPoint, postBody);
  return data;
};

export const getImplementationStepData = async (requestId: any) => {
  const endPoint = `/get_delegation_implementer_list/?request_id=${requestId}`;
  const { data } = await axios.get(endPoint);
  return data;
}