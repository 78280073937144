import {
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import Grid from '@mui/material/Grid';
import {
  useFormikContext,
} from 'formik';
import {
  debounce,
} from 'lodash';
import FormikControl from '../../../formik-conrtols/FormikControl';
import FieldLabel from '../../../field-label';
import {
  getAllCountries,
  searchUserByQuery,
  getAllCitiesByCountryId,
} from '../../../../api-services/common-api';
import {
  getAllCompany,
} from '../../../../api-services/delegation-module';
import { format, isDate } from 'date-fns';
import { teamDetails } from '../tooltip';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../appStore/store';
import { parStyles } from '../../useStyles';
import HistoryTable from '../../../../modules/delegation/comments/historyTable';
import CommentSubmission from '../../../../modules/delegation/comments/commentSubmission';
import { routeCheckDelegation, routeCheckForAdminSummary, routeCheckForApprovalSummary, routeCheckForImplementationSummary, routeCheckForPendingApproval } from './routingConditional';

function RequestorAndManagerDetails({ isDisabled }: { isDisabled: boolean }) {
  const formikCtx: any = useFormikContext();
  const [searchLoading, setSearchLoading] = useState(false);
  const [managerNameToSearch, setManagerNameToSearch] = useState('');
  const [countryList, setCountryList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [openState, setOpenState] = useState(false);
  const [managerNameList, setManagerNameList] = useState<{
    first_name: string,
    last_name: string,
    email: string,
  }[]>([]);
  const isAutoCompleteSelected = useRef(false);
  const formatDateInside = (dateValue: any) => (dateValue && isDate(new Date(dateValue)) ? format(new Date(dateValue), 'MMM dd, yyyy') : '');
  const { fieldHistoryDelegationCommentsData } = useSelector((state: RootState) => state);
  const { submitDelegationCommentData } = useSelector((state: RootState) => state);
  const styleClasses = parStyles();
  const remediated_by = formikCtx.values.remediation_by
  const remediationCount = formikCtx.values.remediation_count;

  const checkForEditReviewFlow = () => {
    return routeCheckDelegation() || routeCheckForAdminSummary() || routeCheckForImplementationSummary() || routeCheckForApprovalSummary()
  }

  const searchUserByFirstName = (value: string) => {
    if (value && value !== '') {
      searchUserByQuery(value, '').then((res) => {
        setOpenState(true)
        setManagerNameList([...res]);
      }).catch((err: any) => {
        console.log(err);
        setManagerNameList([]);
      }).finally(() => {
        setSearchLoading(false);
      });
    }
  };

  const debouncedSearch = useCallback(
    debounce((inputValue) => {
      searchUserByFirstName(inputValue);
    }, 1500),
    [],
  );

  useEffect(() => {
    debouncedSearch(managerNameToSearch);
  }, [managerNameToSearch]);

  useEffect(() => {
    if (formikCtx.values.country !== '' && countryList.length) {
      const selectedCountry: any = countryList
        .find((countryObj: any) => countryObj.country === formikCtx.values.country);
      getAllCitiesByCountryId(selectedCountry?.id).then((res) => {
        setCityList(res.data);
      }).catch((err) => {
        console.log(err);
      });
    }
  }, [formikCtx.values.country]);

  useEffect(() => {
    getAllCountries().then((res) => {
      setCountryList(res.data);
    }).catch((err) => {
      console.log(err);
    });
    getAllCompany().then((res) => {
      setCompanyList(res.data);
    }).catch((err) => {
      console.log(err);
    });
  }, []);

  useEffect(() => {
    if (formikCtx.values.oktahomeorg && formikCtx.values.oktaCompany) {
      if (formikCtx.values.dataverse[0].okta_data.value[0].new_homeorg == 'ssow008') {
        formikCtx.setFieldValue('company', '3rd Party');
        formikCtx.setFieldValue('third_party_company', formikCtx.values.dataverse[0].okta_data.value[0].new_company)
      } else {
        formikCtx.setFieldValue('company', formikCtx.values.dataverse[0].okta_data.value[0].new_company);
      }
    } else {
      formikCtx.setFieldValue('company', formikCtx.values.dataverse[1].db_data.company !== '' && formikCtx.values.dataverse[1].db_data.company);
      formikCtx.setFieldValue('third_party_company', formikCtx.values.dataverse[1].db_data.third_party_company !== '' && formikCtx.values.dataverse[1].db_data.third_party_company);
      formikCtx.setFieldValue('not_listed_third_party_company', formikCtx.values.dataverse[1].db_data.not_listed_third_party_company !== '' && formikCtx.values.dataverse[1].db_data.not_listed_third_party_company);
    }
  }, [formikCtx.values])

  return (
    <>
      <Grid container>
        <Grid item xs={5}>
          <div>
            <FieldLabel Title="Requestor details" required />
          </div>
          <div style={{ color: '#A7A7A7', fontFamily: 'NotoSerif-Regular', fontSize: '10px', marginTop: '-5px', paddingBottom: '10px' }}>
            {/* @ts-ignore */}
            {(fieldHistoryDelegationCommentsData[2].Requestor_details?.length > 0) && `Updated on ${formatDateInside(fieldHistoryDelegationCommentsData[2].Requestor_details[fieldHistoryDelegationCommentsData[2].Requestor_details?.length - 1].last_updated)}`}
          </div>
        </Grid>
        <Grid item xs={5}>
        </Grid>
        <Grid item xs={2}>
          {(checkForEditReviewFlow() && remediationCount > 0) ? (
            <div className={styleClasses.mainSectionDiv}>
              <HistoryTable
                parId={formikCtx.values.request_id}
                section="Requestor_details"
                subSection=''
                data={fieldHistoryDelegationCommentsData.length > 0 ? fieldHistoryDelegationCommentsData[2].Requestor_details : null}
              />

              <CommentSubmission
                sectionName='Requestor_details'
                subSection=''
                updateType='text update'
                remediation_by={remediated_by}
                data={submitDelegationCommentData.length > 0 ? submitDelegationCommentData[2].Requestor_details : null}
              />
            </div>
          ) : routeCheckForPendingApproval() && (
            <div className={styleClasses.mainSectionDiv}>
              <HistoryTable
                parId={formikCtx.values.request_id}
                section="Requestor_details"
                subSection=''
                data={fieldHistoryDelegationCommentsData.length > 0 ? fieldHistoryDelegationCommentsData[2].Requestor_details : null}
              />

              <CommentSubmission
                sectionName='Requestor_details'
                subSection=''
                updateType='text update'
                remediation_by={remediated_by}
                data={submitDelegationCommentData.length > 0 ? submitDelegationCommentData[2].Requestor_details : null}
              />
            </div>
          )}
        </Grid>
      </Grid>
      <Grid container rowSpacing={3} columnSpacing={10}>
        <Grid item sm={3}>
          <FormikControl
            control="textfield"
            label="First name"
            name="first_name"
            disabled
          />
        </Grid>
        <Grid item sm={3}>
          <FormikControl
            control="textfield"
            label="Last name"
            name="last_name"
            disabled
          />
        </Grid>
        <Grid item sm={6}>
        </Grid>
        <Grid item sm={3}>
          <FormikControl
            control="textfield"
            label="Email"
            name="email"
            disabled
          />
        </Grid>
        <Grid item sm={3}>
          <FormikControl
            control="phone"
            label="Phone"
            name="phone"
            defaultCountry="us"
            disabled={isDisabled}
          />
        </Grid>
        <Grid item sm={6}>
        </Grid>
        <Grid item sm={3}>
          <FormikControl
            control="textfield"
            label="Company"
            name="company"
            disabled={true}
          />
        </Grid>
        {formikCtx.values.company === ('3rd Party') &&
          <>
            < Grid item sm={3}>
              <FormikControl
                control="textfield"
                label="3rd party company"
                name="third_party_company"
                disabled={true}
              />
            </Grid>
            <Grid item sm={6}>
            </Grid>
          </>
        }
        {formikCtx.values.third_party_company === 'Not Listed' &&
          <Grid item sm={3}>
            <FormikControl
              control="textfield"
              label="Company name"
              name="not_listed_third_party_company"
              disabled={true}
            />
          </Grid>}
      </Grid >
      <Grid container>
        <Grid item xs={5}>
          <FieldLabel Title="Team details" tooltipMsg={teamDetails} required />
        </Grid>
        <Grid item xs={5}>
        </Grid>
        <Grid item xs={2}>
          {(checkForEditReviewFlow() && remediationCount > 0) ? (
            <div className={styleClasses.mainSectionDiv}>
              <HistoryTable
                parId={formikCtx.values.request_id}
                section="Team_details"
                subSection=''
                data={fieldHistoryDelegationCommentsData.length > 0 ? fieldHistoryDelegationCommentsData[3].Team_details : null}
              />

              <CommentSubmission
                sectionName='Team_details'
                subSection=''
                updateType='text update'
                remediation_by={remediated_by}
                data={submitDelegationCommentData.length > 0 ? submitDelegationCommentData[3].Team_details : null}
              />
            </div>
          ) : routeCheckForPendingApproval() && (
            <div className={styleClasses.mainSectionDiv}>
              <HistoryTable
                parId={formikCtx.values.request_id}
                section="Team_details"
                subSection=''
                data={fieldHistoryDelegationCommentsData.length > 0 ? fieldHistoryDelegationCommentsData[3].Team_details : null}
              />

              <CommentSubmission
                sectionName='Team_details'
                subSection=''
                updateType='text update'
                remediation_by={remediated_by}
                data={submitDelegationCommentData.length > 0 ? submitDelegationCommentData[3].Team_details : null}
              />
            </div>
          )}
        </Grid>
      </Grid>

      <Grid container rowSpacing={3} columnSpacing={10}>
        <Grid item sm={3}>
          <FormikControl
            control="textfield"
            label="Team name"
            name="team_name"
            disabled={isDisabled}
          />
        </Grid>
        <Grid item sm={9} />
        <Grid item sm={3}>
          <FormikControl
            disabled={isDisabled}
            control="autocomplete"
            label="Manager first name"
            name="manager_first_name"
            open={openState}
            options={managerNameList?.map((userObj: any) => ({
              value: `${userObj.email}`,
              label: `${userObj.first_name} ${userObj.last_name}`,
            }))}
            handleInputChange={(inputValue: string, reason: string) => {
              if (reason === 'input') {
                setManagerNameToSearch(inputValue);
                setSearchLoading(true);
                setOpenState(false);
              }
              if (reason === 'reset') {
                formikCtx
                  .setFieldValue('manager_first_name', inputValue.split(' ')[0]);
              }
            }}
            freeSolo
            loading={searchLoading}
            onBlur={() => {
              if (!isAutoCompleteSelected.current) {
                formikCtx.setFieldValue('manager_first_name', managerNameToSearch);
              }
            }}
            handleSelectionChange={(selectedValue: {
              value: string,
              label: string,
            }) => {

              const selectedManager = managerNameList?.find(
                (obj: any) => obj.email === selectedValue.value,
              );
              setOpenState(false)
              isAutoCompleteSelected.current = true;
              formikCtx
                .setFieldValue('manager_first_name', selectedManager ? selectedManager.first_name : '');
              formikCtx
                .setFieldValue('manager_last_name', selectedManager ? selectedManager.last_name : '');
              formikCtx
                .setFieldValue('manager_email', selectedManager ? selectedManager.email : '');
            }}
          />
        </Grid>
        <Grid item sm={3}>
          <FormikControl
            disabled={isDisabled}
            control="textfield"
            label="Manager last name"
            name="manager_last_name"
          />
        </Grid>
        <Grid item sm={6}>
        </Grid>
        <Grid item sm={3}>
          <FormikControl
            control="textfield"
            label="Manager email"
            name="manager_email"
            disabled={isDisabled}
          />
        </Grid>
        <Grid item sm={3}>
          <FormikControl
            control="phone"
            label="Manager phone"
            name="manager_phone"
            defaultCountry="us"
            disabled={isDisabled}
          />
        </Grid>
        <Grid item sm={6}>
        </Grid>
        <Grid item sm={3}>
          <FormikControl
            control="autocomplete"
            label="Manager country"
            name="country"
            options={countryList?.map((countryObj: any) => ({
              label: countryObj.country,
              value: countryObj.country,
            }))}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item sm={3}>
          <FormikControl
            control="autocomplete"
            label="Manager city"
            name="city"
            options={cityList?.map((city: any) => ({ label: city.city, value: city.city }))}
            disabled={isDisabled}
          />
        </Grid>
      </Grid>
    </>
  );
}
export default RequestorAndManagerDetails;
