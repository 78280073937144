import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import FormikControl from '../../../formik-conrtols/FormikControl';
import FieldLabel from '../../../field-label';
import ReviewActions from '../../../review-actions';
import { useDispatch } from 'react-redux';
import { chatHistoryData } from '../../../../appStore/reducer/chatHistoryReducer';
import { format, isDate } from 'date-fns';
import { RootState } from '../../../../appStore/store';
import { historyDate } from '../../../../constants/updatedField';
import { getAllDomains } from '../../../../api-services/delegation-module';
import { ReactComponent as CommentSvg } from '../../../../assets/icons/comment.svg';
import ReviewCommentsContainer from '../../../../modules/par/comments/commentsContainer';
import HistoryTable from '../../../../modules/par/comments/historyTable';
import CommentSubmission from '../../../../modules/par/comments/commentSubmission';
import { Divider } from '@mui/material';
import { parStyles } from '../../useStyles';
import { fetchAllCommentsReplyPart } from '../../../../api-services/par';
import { fetchfieldHistoryForLastUpdate } from '../../../../api-services/review-delegation';
import { fieldUpdationHistory } from '../../../../appStore/reducer/reviewActionReducer';



function AccountJustification({ isDisabled }: { isDisabled: boolean }) {
    const styleClasses = parStyles();
    const dispatch = useDispatch();
    const [domainOptions, setDomainOptions] = useState([]);
    const formikCtx: any = useFormikContext();
    const { submitParCommentsData } = useSelector((state: RootState) => state);
    const { submitParRequestorCommentsData } = useSelector((state: RootState) => state);
    const [anchorEl, setAnchorEl] = useState<SVGElement | HTMLDivElement | null>(
        null,
    );

    const { updatedFieldHistoryData } = useSelector((state: RootState) => state);
    const { chathistorydata } = useSelector((state: RootState) => state.chatFieldHistoryData);
    const [fieldNameObject, setFieldNameObject] = useState('')
    const formatDateInside = (dateValue: any) => (dateValue && isDate(new Date(dateValue)) ? format(new Date(dateValue), 'MMM dd, yyyy') : '');
    const remediationCount = formikCtx.values.remediation_count;
    const routeCheck = () => {
        return location.pathname.slice(1, 4).toLocaleLowerCase() === 'par'
    }

    const updatedArray: any = []
    const lastUpdatedFieldHistorValue = (section: string, upadtedFieldHistory: any) => {
        updatedArray.pop();
        upadtedFieldHistory.length > 0 && upadtedFieldHistory.map(function (comment: any) {
            if (comment.section === section) {
                updatedArray.push(comment.last_updated);
            }

        });
    }
    const remediated_by = formikCtx.values.remediation_by
    useEffect(() => {
        getAllDomains().then((res) => {
            setDomainOptions(res.data.map((domain: { id: number, value: string }) => ({
                label: domain.value,
                value: domain.value,
            })));
        }).catch((err) => {
            console.error(err);
        });
        fetchfieldHistoryForLastUpdate({
            request_id: parseInt(formikCtx.values.request_id),
            request_type: "PAR",
        })
            .then((response) => {

                dispatch(fieldUpdationHistory(response.data));
            })
            .catch((error) => {
                console.log(error);
            });

    }, []);
    return (
        <>
            <Grid container >
                <Grid item xs={5}>
                    <div>
                        <FieldLabel Title="ServiceNow ticket number (optional)" tooltipMsg="If you have ServiceNow ticket, <br /> please enter it here" />
                    </div>
                    <div style={{ color: '#A7A7A7', fontFamily: 'NotoSerif-Regular', fontSize: '10px', marginTop: '-5px', paddingBottom: '12px' }}>
                        {/* @ts-ignore */}
                        {(updatedFieldHistoryData[5].ServiceNow_ticket_number?.length > 0) && `Updated on ${formatDateInside(updatedFieldHistoryData[5].ServiceNow_ticket_number[updatedFieldHistoryData[5].ServiceNow_ticket_number?.length - 1].last_updated)}`}
                    </div>
                </Grid>
                <Grid item xs={5}>

                </Grid>
                <Grid item xs={2}>
                    {routeCheck() ? remediationCount > 0 ? (<div className={styleClasses.mainSectionDiv}>
                        <HistoryTable
                            parId={formikCtx.values.request_id}
                            section="ServiceNow_ticket_number"
                            subSection=''
                            data={updatedFieldHistoryData.length > 0 ? updatedFieldHistoryData[5].ServiceNow_ticket_number : null}
                        />
                        <CommentSubmission
                            sectionName='ServiceNow_ticket_number'
                            subSection=''
                            remediation_by={remediated_by}
                            updateType='text update'
                            data={submitParCommentsData.length > 0 ? submitParCommentsData[5].ServiceNow_ticket_number : null}
                            requestorData={submitParRequestorCommentsData.length > 0 ? submitParRequestorCommentsData[5].ServiceNow_ticket_number : null}
                        />
                    </div>) : null
                        : (
                            <div className={styleClasses.mainSectionDiv}>
                                <HistoryTable
                                    parId={formikCtx.values.request_id}
                                    section="ServiceNow_ticket_number"
                                    subSection=''
                                    data={updatedFieldHistoryData.length > 0 ? updatedFieldHistoryData[5].ServiceNow_ticket_number : null}
                                />

                                <CommentSubmission
                                    sectionName='ServiceNow_ticket_number'
                                    subSection=''
                                    remediation_by={remediated_by}
                                    updateType='text update'
                                    data={submitParCommentsData.length > 0 ? submitParCommentsData[5].ServiceNow_ticket_number : null}
                                    requestorData={submitParRequestorCommentsData.length > 0 ? submitParRequestorCommentsData[5].ServiceNow_ticket_number : null}
                                />
                            </div>)}
                </Grid>
                <Grid item xs={3}>
                    <FormikControl
                        control="textfield"
                        label="ServiceNow ticket number"
                        name="par_servicenow_ticket_number"
                        disabled={isDisabled}
                    />
                </Grid>
                <Grid item xs={5}>

                </Grid>
                <Grid item xs={2}>

                </Grid>
            </Grid>
            <Grid container paddingTop={'12px'} >
                <Grid item xs={5}>
                    <div>
                        <FieldLabel Title="Domain" tooltipMsg="Please select the domain in which access is required" required />
                    </div>
                    <div style={{ color: '#A7A7A7', fontFamily: 'NotoSerif-Regular', fontSize: '10px', marginTop: '-5px', paddingBottom: '12px' }}>
                        {/* @ts-ignore */}
                        {(updatedFieldHistoryData[7].Domain?.length > 0) && `Updated on ${formatDateInside(updatedFieldHistoryData[7].Domain[updatedFieldHistoryData[7].Domain?.length - 1].last_updated)}`}
                    </div>
                </Grid>
                <Grid item xs={5}>

                </Grid>
                <Grid item xs={2}>
                    {routeCheck() ? remediationCount > 0 ? (<div className={styleClasses.mainSectionDiv}>
                        <HistoryTable
                            parId={formikCtx.values.request_id}
                            section="Domain"
                            subSection=''
                            data={updatedFieldHistoryData.length > 0 ? updatedFieldHistoryData[7].Domain : null}
                        />

                        <CommentSubmission
                            sectionName='Domain'
                            subSection=''
                            remediation_by={remediated_by}
                            updateType='text update'
                            data={submitParCommentsData.length > 0 ? submitParCommentsData[7].Domain : null}
                            requestorData={submitParRequestorCommentsData.length > 0 ? submitParRequestorCommentsData[7].Domain : null}
                        />
                    </div>) : null
                        : (
                            <div className={styleClasses.mainSectionDiv}>
                                <HistoryTable
                                    parId={formikCtx.values.request_id}
                                    section="Domain"
                                    subSection=''
                                    data={updatedFieldHistoryData.length > 0 ? updatedFieldHistoryData[7].Domain : null}
                                />

                                <CommentSubmission
                                    sectionName='Domain'
                                    subSection=''
                                    remediation_by={remediated_by}
                                    updateType='text update'
                                    data={submitParCommentsData.length > 0 ? submitParCommentsData[7].Domain : null}
                                    requestorData={submitParRequestorCommentsData.length > 0 ? submitParRequestorCommentsData[7].Account_justification : null}
                                />
                            </div>)}
                </Grid>
                <Grid item xs={3}>
                    <FormikControl
                        // disabled={isDisabled}
                        control="autocomplete"
                        label="Domain"
                        name="par_domain"
                        options={domainOptions}
                        // required
                        disabled={formikCtx.values.del_request_id !== null ? true : isDisabled}
                    />
                </Grid>
                <Grid item xs={5}>

                </Grid>
                <Grid item xs={2}>

                </Grid>
            </Grid>
            <Grid container paddingTop={'12px'} >
                <Grid item xs={5}>
                    <div>
                        <FieldLabel Title="Account justification" required tooltipMsg="Please explain what activities you need to complete that require privileged access" />
                    </div>
                    <div style={{ color: '#A7A7A7', fontFamily: 'NotoSerif-Regular', fontSize: '10px', marginTop: '-5px', paddingBottom: '12px' }}>
                        {/* @ts-ignore */}
                        {(updatedFieldHistoryData[6].Account_justification?.length > 0) && `Updated on ${formatDateInside(updatedFieldHistoryData[6].Account_justification[updatedFieldHistoryData[6].Account_justification?.length - 1].last_updated)}`}
                    </div>
                </Grid>
                <Grid item xs={5}>

                </Grid>
                <Grid item xs={2}>
                    {routeCheck() ? remediationCount > 0 ? (<div className={styleClasses.mainSectionDiv}>

                        <HistoryTable
                            parId={formikCtx.values.request_id}
                            section="Account_justification"
                            subSection=''
                            data={updatedFieldHistoryData.length > 0 ? updatedFieldHistoryData[6].Account_justification : null}
                        />
                        <CommentSubmission
                            sectionName='Account_justification'
                            subSection=''
                            remediation_by={remediated_by}
                            updateType='text update'
                            data={submitParCommentsData.length > 0 ? submitParCommentsData[6].Account_justification : null}
                            requestorData={submitParRequestorCommentsData.length > 0 ? submitParRequestorCommentsData[6].Account_justification : null}
                        />
                    </div>) : null
                        : (
                            <div className={styleClasses.mainSectionDiv}>
                                <HistoryTable
                                    parId={formikCtx.values.request_id}
                                    section="Account_justification"
                                    subSection=''
                                    data={updatedFieldHistoryData.length > 0 ? updatedFieldHistoryData[6].Account_justification : null}
                                />

                                <CommentSubmission
                                    sectionName='Account_justification'
                                    subSection=''
                                    remediation_by={remediated_by}
                                    updateType='text update'
                                    data={submitParCommentsData.length > 0 ? submitParCommentsData[6].Account_justification : null}
                                    requestorData={submitParRequestorCommentsData.length > 0 ? submitParRequestorCommentsData[6].Account_justification : null}
                                />
                            </div>)}
                </Grid>
                <Grid item sm={12}>
                    <FormikControl
                        // disabled={isDisabled}
                        control="textfield"
                        label="Account justification"
                        name="par_account_justification"
                        multiline
                        minRows={5}
                        disabled={isDisabled}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default AccountJustification;