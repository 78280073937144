const envConstants = {
  title: process.env.REACT_APP_TITLE,
  baseUrl: process.env.REACT_APP_API_BASE_URL,
};
const oktaAuthConfig = {
  issuer: `${process.env.REACT_APP_OKTA_BASE_URL}/oauth2/default`,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: `${window.location.origin}/callback`,
  pkce: true,
  disableHttpsCheck: false,
};

export { oktaAuthConfig, envConstants };
