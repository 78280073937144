import * as React from 'react';
import { useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Grid } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FilterMenu from '../filter-menu';
import FilterListIcon from '@mui/icons-material/FilterList';
import CustomToggleButton from '../button/CustomToggleButton';
import CustomDatePicker from '../date-range-picker';
import { format, isDate } from 'date-fns';
import CustomButton from '../button';
import reviewFilter from './useStyles';
import isEqual from 'lodash/isEqual';
import useDaterangePicker from '../date-range-picker/useDaterangePicker';
import { IFiltersObj, emptyFilters } from './userHistoryUtils';
import { ReactComponent as EmptyResultIcon } from '../../assets/icons/no_results_icon.svg';
import { tableRowStyle, tableHeadStyle, tableLoaderStyle } from './useStyles';


type viewHistorydate = {
    last_updated: string,
    requestor_name: string,
    justification_by_requestor: string,
    action_taken: string,
    justification_by_admin: string,
}

interface ITableProps {
    isLoading: boolean,
    viewHistoryData: viewHistorydate[],
    filterName: string,
    columnArray: string[],
}

const DynamicTableHistory = ({ isLoading, viewHistoryData, filterName, columnArray }: ITableProps) => {
    const styleClasses = reviewFilter();
    const tableHeadClasses = tableHeadStyle();
    const tableRowClasses = tableRowStyle();
    const tableLoaderClasses = tableLoaderStyle();
    const [closeFilter, setCloseFilter] = useState(false);
    const [filters, setFilters] = useState<IFiltersObj>(emptyFilters);
    const {
        datepickerProps,
        setDatepickerProps,
        setDateRangeValue,
    } = useDaterangePicker({ isSingleDate: true });
    const formatDateInside = (dateValue: any) => (dateValue && isDate(new Date(dateValue)) ? format(new Date(dateValue), 'dd MMM yyyy') : '');
    const setDateRangeFilter = () => {
        setCloseFilter(true);
        setFilters((prevFilters) => ({
            ...prevFilters,
            requestDate: { ...datepickerProps.dateRange },
        }));
    };

    const emptyRequestHandler = () => {
        if (!isLoading && viewHistoryData.length === 0) {
            return (
                <Grid item xs={12}>
                    <div className={styleClasses.emptyResultContainer}>
                        <EmptyResultIcon />
                        <span>
                            {
                                isEqual(filters, emptyFilters)
                                    ? 'There are no records present'
                                    : 'There are no records present'
                            }
                        </span>
                    </div>
                </Grid>
            );
        }
        return null;
    };
    return (
        <Grid container>
            <Grid item xs={12}>
                <div className={styleClasses.filterPanel}>
                    <div className={styleClasses.filterIcon}>
                        <FilterListIcon />
                    </div>
                    <div className={styleClasses.filterMenu}>
                        <FilterMenu
                            filterLabel={filterName}
                            handleFilterClose={() => {
                                if (closeFilter) {
                                    setCloseFilter(false);
                                }
                            }}
                            closeFilterExternally={closeFilter}
                        >
                            <div className={styleClasses.dateRangeToggleContainer}>
                                <CustomToggleButton
                                    variant="contained"
                                    isSelected={!!datepickerProps.isSingleDate}
                                    onClick={() => {
                                        if (!datepickerProps.isSingleDate) {
                                            setDatepickerProps({
                                                isSingleDate: true,
                                                dateRange:
                                                {
                                                    startDate: null,
                                                    endDate: null,
                                                },
                                            });
                                        }
                                    }}
                                >
                                    Single Date
                                </CustomToggleButton>
                                <CustomToggleButton
                                    variant="contained"
                                    isSelected={!datepickerProps.isSingleDate}
                                    onClick={() => {
                                        if (datepickerProps.isSingleDate) {
                                            setDatepickerProps({
                                                isSingleDate: false,
                                                dateRange:
                                                {
                                                    startDate: null,
                                                    endDate: null,
                                                },
                                            });
                                        }
                                    }}
                                >
                                    Date Range
                                </CustomToggleButton>
                            </div>
                            <div className={styleClasses.dateRangeContainer}>
                                <CustomDatePicker
                                    isRangePicker={!datepickerProps.isSingleDate}
                                    setDateRangeValue={setDateRangeValue}
                                    dateRange={datepickerProps.dateRange}
                                    disableFuture
                                />
                            </div>
                            <div className={styleClasses.dateRangeApplyCta}>
                                <CustomButton
                                    variant="contained"
                                    actionType="secondary"
                                    disabled={!datepickerProps.dateRange.endDate}
                                    onClick={() => setDateRangeFilter()}
                                    isCompact
                                    disableMargin
                                >
                                    Apply
                                </CustomButton>
                            </div>
                        </FilterMenu>
                    </div>
                </div>
            </Grid>
            <Grid item xs={12}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead classes={{ root: tableHeadClasses.head }}>
                        <TableRow sx={{ '&:first-of-type(th)': { border: 0 } }}>
                            {/* rotate map on clumn array */}
                            {
                                columnArray.map((item: any) => (
                                    <TableCell classes={{ root: tableHeadClasses.cell }}>{item}</TableCell>
                                ))
                            }

                        </TableRow>
                    </TableHead>
                    {
                        viewHistoryData.length || isLoading ? (
                            <TableBody>

                                {isLoading
                                    && (
                                        <TableRow data-testid="tableRow">
                                            <TableCell colSpan={6} sx={{ padding: '6px 0px' }}>
                                                <div className={tableLoaderClasses.container}>
                                                    <Skeleton
                                                        animation="wave"
                                                        width="100%"
                                                        className={tableLoaderClasses.skeleton}
                                                    />
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                {!isLoading && viewHistoryData.map((row: viewHistorydate) => (
                                    <TableRow
                                        key={row.last_updated}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell className={tableRowClasses.cell} component="th" scope="row">
                                            {formatDateInside(row.last_updated)}
                                        </TableCell>
                                        <TableCell className={tableRowClasses.cell}>{row.requestor_name}</TableCell>
                                        <TableCell className={tableRowClasses.cell}>{row.justification_by_requestor}</TableCell>
                                        <TableCell className={tableRowClasses.cell}>{row.action_taken}</TableCell>
                                        <TableCell className={tableRowClasses.cell}>{row.justification_by_admin}</TableCell>
                                    </TableRow>
                                ))
                                }
                            </TableBody>
                        ) : null
                    }

                </Table>
            </Grid>
            {emptyRequestHandler()}
        </Grid>
    );
}

export default DynamicTableHistory;