import { makeStyles } from "@mui/styles";

const modalStyles = makeStyles({
  paperRoot: {
    boxShadow: "none !important",
    maxWidth: (props: { centerButton?: boolean }) =>
      props.centerButton ? "1172px !important" : "",
    //width: "100%",
    // maxWidth: (props: { centerButton?: boolean }) => props.centerButton ? '600px!important' : '',
    //noteForDeteteOpen == 1 ? 'Note' : (noteForDeteteOpen == 2 ? 'Role Group Permissions' : 'Remove User');
    //width: '100%',
  },
  modalHeader: {
    minHeight: " 45px",
    background: "#0A2756",
    borderRadius: "4px 4px 0px 0px",
    color: "#FFFF",
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "24px",
    font: "normal normal normal 16px/25px Poppins-Semibold",
    letterSpacing: "0px",
  },
  modalContent: {
    padding: "24px 24px 24px",
  },
  spanStyle: {
    padding: "25px !important",
  },
  contentDiv: {},
  actionDiv: {
    marginTop: "20px",
  },
  modalActionDiv: {
    display: "flex",
    justifyContent: "right",
  },
  modalActionDivReview: {
    display: "flex",
    justifyContent: (props: { centerButton: boolean }) =>
      props.centerButton ? "center" : "",
  },
  modalContentDiv: {
    marginBottom: "12px",
    color: "#0A2756",
    font: "normal normal normal 14px/21px Poppins-Medium",
  },
  modalTextDiv: {
    // maxWidth: "52ch",
    color: "#0A2756",
    font: "normal normal normal 14px/22px NotoSerif-Regular",
  },
  spanText: {
    fontFamily: "NotoSerif-Bold",
    fontSize: "14px",
    color: "#0A2756",
  },
});

export default modalStyles;
