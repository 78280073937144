import { makeStyles } from "@mui/styles";

const useStylesDelegation = makeStyles({
    container: {
        border: '1px solid #D9D9D9',
        borderRadius: '4px',
        marginLeft: '28px',
    },
    topDivfileContainer: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
    },
    divFileIcon: {
        font: 'normal normal normal 14px/16px Noto Serif',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        marginLeft: '10px',
        marginTop: '8px',
        marginBottom: '8px',
    },
    divFileName: {
        font: 'normal normal normal 14px/16px Noto Serif',
        marginLeft: '8px',
        overflow: 'hidden',
        maxWidth: '10ch',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    closeIconStyle: {
        position: 'absolute',
        right: '5px',
        top: '5px',
        color: 'red',
        zIndex: 4,
        cursor: 'pointer',
        "& svg": {
            // stroke: '#00B5B1',
            color: "#00B5B1",
            width: '10px',
            height: '10px'
        }
    },
    implementationFile: {
        width: '400px',
        height: '200px',
        border: '1px solid #f1f1f1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '10px',
    },
    signatureImageContainer: {
        display: 'flex',
        minWidth: 0,
        overflow: '',
        position: 'relative',
    },
    InnerThumbnail: {
        display: 'block',
        height: '100%',
        width: '100%',
        position: 'relative'
        // maxWidth: '200px',
        // maxHeight: '95px',
    }
});
export default useStylesDelegation;
