import { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import FormikContainer from "../../components/formik-containers/formikContainerDelegation/FormikContainer";
import BreadCrumbs from "../../components/breadcrumb";
import { getRequestData } from "../../api-services/delegation-module";
import {
  fetchAllCommentsReply,
  fetchfieldHistoryForLastUpdate,
} from "../../api-services/review-delegation";
import { useDispatch } from "react-redux";
import { fieldUpdationHistory } from "../../appStore/reducer/reviewActionReducer";
import { chatHistoryData } from "../../appStore/reducer/chatHistoryReducer";
import { submitDelegationComments } from "../../appStore/reducer/delegationEditReviewReducer";
import { updatedFieldHistoryDelegation } from "../../appStore/reducer/delegationFieldHistoryReducer";
import Loader from "../../components/loader";

const styles = makeStyles({
  bodyMainDiv: {
    display: "flex",
  },
  topDiv: {
    padding: "16px",
  },
});

function FormPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { requestId, Admin } = useParams<{ requestId: string, Admin: string }>();
  const styleClasses = styles();
  const { enqueueSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  let okatValidationCheckObject = {
    oktaCompany: false,
    oktaPhone: false,
    oktaCity: false,
    oktaCountry: false,
    oktaTitle: false,
    oktaDepartment: false,
    oktahomeorg: false
  }

  useEffect(() => {
    if (!requestId) {
      history.push("/delegation");
    }

    // if (Admin == 'Administrator') {
    //   getRequestData(requestId, Admin)
    //     .then((successData) => {

    //       if (successData.message == 'request already open with another administrator') {
    //         enqueueSnackbar("request already open with another administrator", {
    //           variant: "error",
    //         })
    //         history.push("/myadministrativeTasks");
    //       } else {

    //         setData({
    //           ...successData.data[0],
    //           ...successData.data[1],

    //           //   if(formikCtx.values.dataverse[0].okta_data.value.length > 0) {
    //           //   formikCtx.setFieldValue('company', formikCtx.values.dataverse[0].okta_data.value[0].new_company)
    //           // } else {
    //           //   if (formikCtx.values.dataverse[1].db_data.company !== '' && formikCtx.values.dataverse[1].db_data.company !== undefined) {
    //           //     formikCtx.setFieldValue('company', formikCtx.values.dataverse[1].db_data.company)
    //           //   } else if (formikCtx.values.dataverse[1].db_data.third_party_company !== '' && formikCtx.values.dataverse[1].db_data.third_party_company !== undefined) {
    //           //     formikCtx.setFieldValue('third_party_company', formikCtx.values.dataverse[1].db_data.third_party_company)
    //           //   } else if (formikCtx.values.dataverse[1].db_data.not_listed_third_party_company !== '' && formikCtx.values.dataverse[1].db_data.not_listed_third_party_company !== undefined) {
    //           //     formikCtx.setFieldValue('not_listed_third_party_company', formikCtx.values.dataverse[1].db_data.not_listed_third_party_company)
    //           //   }
    //           //   //  for dev only testing
    //           //   formikCtx.setFieldValue('company', 'Cybage')
    //           // }


    //           company: successData.data[0].dataverse[1].db_data.company !== '' && successData.data[0].dataverse[1].db_data.company,
    //           sar_third_party_company: successData.data[0].dataverse[1].db_data.third_party_company !== '' && successData.data[0].dataverse[1].db_data.third_party_company,
    //           sar_not_listed_third_party_company: successData.data[0].dataverse[1].db_data.not_listed_third_party_company !== '' && successData.data[0].dataverse[1].db_data.not_listed_third_party_company,

    //         });
    //         localStorage.setItem('historyDataOfMainSchemaDelegation', JSON.stringify(successData.data[1]))
    //         dispatch(submitDelegationComments(successData.data[2].comments_history))
    //         dispatch(updatedFieldHistoryDelegation(successData.data[3].fields_history))
    //         setIsLoading(false);
    //       }
    //     })
    //     .catch((err) => {
    //       console.error(err);
    //       setIsLoading(false);
    //       enqueueSnackbar("Something went wrong while fetching request data.", {
    //         variant: "error",
    //       });
    //       setTimeout(() => history.push("/myadministrativeTasks"));
    //     });
    // } else {

    getRequestData(requestId)
      .then((successData) => {
        if (successData.data[0].dataverse[0].okta_data.value.length > 0 && successData.data[0].dataverse[0].okta_data.value[0] !== undefined) {
          okatValidationCheckObject.oktahomeorg = successData.data[0].dataverse[0].okta_data.value[0].new_homeorg !== null ? true : false;
          okatValidationCheckObject.oktaCompany = successData.data[0].dataverse[0].okta_data.value[0].new_company !== null ? true : false;
          okatValidationCheckObject.oktaPhone = successData.data[0].dataverse[0].okta_data.value[0].new_primaryphone !== null ? true : false;
          setData({
            ...successData.data[0],
            ...successData.data[1],
            ...okatValidationCheckObject,
            phone: okatValidationCheckObject.oktaPhone ? successData.data[0].dataverse[0].okta_data.value[0].new_primaryphone : successData.data[0].phone,
          });
        } else {
          setData({
            ...successData.data[0],
            ...successData.data[1],
            company: successData.data[0].dataverse[1].db_data.company !== '' && successData.data[0].dataverse[1].db_data.company,
            third_party_company: successData.data[0].dataverse[1].db_data.third_party_company !== '' && successData.data[0].dataverse[1].db_data.third_party_company,
            not_listed_third_party_company: successData.data[0].dataverse[1].db_data.not_listed_third_party_company !== '' && successData.data[0].dataverse[1].db_data.not_listed_third_party_company,
          });
        }

        localStorage.setItem('historyDataOfMainSchemaDelegation', JSON.stringify({
          ...successData.data[0],
          ...successData.data[1],
        }))
        dispatch(submitDelegationComments(successData.data[2].comments_history))
        dispatch(updatedFieldHistoryDelegation(successData.data[3].fields_history))
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
        enqueueSnackbar("Something went wrong while fetching request data.", {
          variant: "error",
        });
        history.push('/delegation');
      });


    // }

    // getImplementationStepData(requestId).then((res) => {
    //   console.log('res', res.data[0].step_one.enter_review_comments);

    //   setGetImplementationData(res.data[0]);
    // }).catch((err) => err);

    // fetchAllCommentsReply({
    //   request_id: parseInt(requestId),
    //   request_type: "Delegation",
    //   request_state:
    //     location.pathname.slice(1, 4).toLocaleLowerCase() === "del"
    //       ? "Requestor"
    //       : "Approver",
    // })
    //   .then((response) => {
    //     dispatch(chatHistoryData(response.data));
    //     localStorage.setItem("commentReply", JSON.stringify(response.data));
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    // fetchfieldHistoryForLastUpdate({
    //   request_id: parseInt(requestId),
    //   request_type: "Delegation",
    // })
    //   .then((response) => {
    //     dispatch(fieldUpdationHistory(response.data));
    //     localStorage.setItem(
    //       "latestUpdatedFeildHistory",
    //       JSON.stringify(response.data)
    //     );
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  }, []);

  if (isLoading) {
    return (
      <div style={{ padding: "0px 32px" }}>
        <Loader openModal={true} />
      </div>
    );
  }



  const BreadCrumbHandler = (route: string) => {
    // console.log('admin route', route);
    const newRoute = route.slice(1, 5).toLocaleLowerCase();
    switch (newRoute) {
      case "dele":
        return (
          <BreadCrumbs
            breadcrumbData={[
              {
                key: 1,
                title: "Delegation",
                href: "/delegation",
              },
              {
                key: 2,
                title: localStorage.getItem('event') === 'create' ? "Create New Delegation" : `Delegation ${data && data?.request_number}`,
                href: '',
              },
            ]}
          />
        );
      case "myap":
        return (
          <BreadCrumbs
            breadcrumbData={[
              {
                key: 1,
                title: "My Approval Task",
                href: "/myApprovalTasks",
              },
              {
                key: 2,
                title: `Delegation ${data?.request_number}`,
                href: '',
              },
            ]}
          />
        )
      case 'myad':
        return (
          <BreadCrumbs
            breadcrumbData={[
              {
                key: 1,
                title: 'My Administrative Task',
                href: '/myadministrativeTasks',
              }, {
                key: 2,
                title: `Delegation ${data?.request_number}`,
                href: '',
              },
            ]}
          />
        );
      case "myim":
        return (
          <BreadCrumbs
            breadcrumbData={[
              {
                key: 1,
                title: "My Implementation Task",
                href: "/myImplementationTasks",
              },
              {
                key: 2,
                title: `${data?.request_number}`,
                href: "",
              },
            ]}
          />
        );
      default:
        return null;
    }
  }

  return (
    <div className={styleClasses.topDiv}>
      {BreadCrumbHandler(location.pathname)}
      <div className={styleClasses.bodyMainDiv}>
        <FormikContainer
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          data={data}
          requestId={requestId}
          path={location.pathname}
        />
      </div>
    </div>
  );
}
export default FormPage;
