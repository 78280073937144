/* eslint-disable react/require-default-props */
import { Typography } from "@mui/material";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import useStyles from "./useStyles";
import HelperTooltip from "../helper-tooltip";

interface IFieldLabelProps {
  Title: string;
  required?: boolean;
  tooltipMsg?: string;
  lastUpdated?: string;
  implementation?: boolean;
}

export default function FieldLabel({
  Title,
  required,
  tooltipMsg,
  implementation,
}: IFieldLabelProps) {
  const classes = useStyles();
  return (
    <>
      <Typography sx={{ paddingBottom: "0px" }} className={classes.title}>
        <span className={implementation ? classes.implementationTitle : null}>{Title}</span>
        {required && <span className={classes.astrik}>*</span>}
        {tooltipMsg && (
          <span className={classes.tooltipSpan}>
            <HelperTooltip tooltipMsg={tooltipMsg}>
              <HelpRoundedIcon />
            </HelperTooltip>
          </span>
        )}
      </Typography>
    </>
  );
}
