import { createSlice, current } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


interface IAadImplementation {
    is_implemented: boolean,
    is_step_complete: boolean,
    on_premise_id: number,
    provide_service_numbe: string
}

export const aadImplementationOnprem = createSlice({
    name: 'aadOnpremTasklist',
    initialState: [{}],
    reducers: {
        aadImplementationOnpremTasklist: (state, action: PayloadAction<IAadImplementation>) => {
            console.log('payload', action.payload)
            return [{ ...action.payload }]
        },
    }
});

export const { aadImplementationOnpremTasklist } = aadImplementationOnprem.actions;
export default aadImplementationOnprem.reducer;