import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import FormikControl from '../../../formik-conrtols/FormikControl';
import FieldLabel from '../../../field-label';
import { format, isDate } from 'date-fns';
import { RootState } from '../../../../appStore/store';
import { functionTooltip, justificationTooltip } from '../tooltips';
import { parStyles } from '../../useStyles';
import HistoryTable from '../../../../modules/sar/comments/historyTable';
import CommentSubmission from '../../../../modules/sar/comments/commentSubmission';
import { routeCheck, routeCheckForAdminSummary, routeCheckForApprovalSummary, routeCheckForImplementationSummary, routeCheckForPendingApproval } from './routingConditional';

function SARReason({ isDisabled }: { isDisabled: boolean }) {
    const formikCtx: any = useFormikContext();
    const { fieldHistorySarCommentsData } = useSelector((state: RootState) => state);
    const formatDateInside = (dateValue: any) => (dateValue && isDate(new Date(dateValue)) ? format(new Date(dateValue), 'MMM dd, yyyy') : '');
    const { submitSarCommentData } = useSelector((state: RootState) => state);
    const styleClasses = parStyles();
    const remediated_by = formikCtx.values.remediation_by
    const remediationCount = formikCtx.values.remediation_count;

    const checkForEditReviewFlow = () => {
        return routeCheck() || routeCheckForAdminSummary() || routeCheckForImplementationSummary() || routeCheckForApprovalSummary()
    }

    return (
        <>
            <Grid container >
                <Grid item xs={10}>
                    <div>
                        <FieldLabel Title="Function" tooltipMsg={functionTooltip} required />
                    </div>
                    <div>
                        <div className={styleClasses.updatedByStyle}>
                            {/* @ts-ignore */}
                            {(fieldHistorySarCommentsData[0].Function?.length > 0) && `Updated on ${formatDateInside(fieldHistorySarCommentsData[0].Function[fieldHistorySarCommentsData[0].Function?.length - 1].last_updated)}`}
                        </div>
                    </div>
                </Grid>

                <Grid item xs={2}>
                    {(checkForEditReviewFlow() && remediationCount > 0) ? (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="Function"
                                subSection=''
                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[0].Function : null}
                            />

                            <CommentSubmission
                                sectionName='Function'
                                subSection=''
                                updateType='Select update'
                                remediation_by={remediated_by}
                                data={submitSarCommentData.length > 0 ? submitSarCommentData[0].Function : null}
                            />
                        </div>
                    ) : routeCheckForPendingApproval() && (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="Function"
                                subSection=''
                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[0].Function : null}
                            />

                            <CommentSubmission
                                sectionName='Function'
                                subSection=''
                                updateType='Select update'
                                remediation_by={remediated_by}
                                data={submitSarCommentData.length > 0 ? submitSarCommentData[0].Function : null}
                            />
                        </div>
                    )}
                </Grid>
            </Grid>
            <Grid container rowSpacing={3} >
                <Grid item xs={12}>
                    <FormikControl
                        control="textfield"
                        placeholder="Provide details of the automation you need to set up and what it will be used for"
                        name="sar_function_of_sar"
                        multiline
                        minRows={7}
                        disabled={isDisabled}
                    />
                </Grid>
            </Grid>
            <Grid container paddingTop={'12px'} >
                <Grid item xs={10}>
                    <>
                        <FieldLabel Title="Justification" tooltipMsg={justificationTooltip} required />
                    </>
                    <>
                        <div className={styleClasses.updatedByStyle}>
                            {/* @ts-ignore */}
                            {(fieldHistorySarCommentsData[1].Justification?.length > 0) && `Updated on ${formatDateInside(fieldHistorySarCommentsData[1].Justification[fieldHistorySarCommentsData[1].Justification?.length - 1].last_updated)}`}
                        </div>
                    </>
                </Grid>

                <Grid item xs={2}>
                    {(checkForEditReviewFlow() && remediationCount > 0) ? (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="Justification"
                                subSection=''
                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[1].Justification : null}
                            />
                            <CommentSubmission
                                sectionName='Justification'
                                subSection=''
                                updateType='Select update'
                                remediation_by={remediated_by}
                                data={submitSarCommentData.length > 0 ? submitSarCommentData[1].Justification : null}
                            />
                        </div>
                    ) : routeCheckForPendingApproval() && (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="Justification"
                                subSection=''
                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[1].Justification : null}
                            />
                            <CommentSubmission
                                sectionName='Justification'
                                subSection=''
                                updateType='Select update'
                                remediation_by={remediated_by}
                                data={submitSarCommentData.length > 0 ? submitSarCommentData[1].Justification : null}
                            />
                        </div>
                    )}
                </Grid>
            </Grid>
            <Grid container rowSpacing={3} >
                <Grid item xs={12}>
                    <FormikControl
                        control="textfield"
                        placeholder="Provide justification for this request"
                        name="sar_justification_of_sar"
                        multiline
                        minRows={7}
                        disabled={isDisabled}
                    />
                </Grid>
            </Grid>
        </>
    )
};

export default SARReason;