import { StandardTextFieldProps } from '@mui/material';
import { useState } from 'react';
import { textFieldStyles } from './useStyles';
import { TextField } from '@mui/material';

interface IStyledTextFields extends StandardTextFieldProps {
    name?: string,
    value?: string,
    isDisabled?: boolean,
}
const CommentSection = ({
    placeholder,
    label,
    onChange,
    name,
    value,
    error,
    disabled,
}: IStyledTextFields) => {
    const [multilineElement, setMultilineElement] = useState(false);
    const classes = textFieldStyles();
    const onFocusHandler = () => {
        setMultilineElement(true);
    }
    return (
        <div>
            <TextField
                placeholder={placeholder}
                label={label}
                onChange={onChange}
                onFocus={onFocusHandler}
                multiline={multilineElement}
                minRows={2}
                variant="outlined"
                data-testid="text-field"
                name={name}
                error={error}
                value={value}
                helperText={error}
                disabled={disabled}
                fullWidth
                InputProps={{
                    classes:
                    {
                        notchedOutline: classes.notchedOutline,
                        input: classes.input,
                    },
                }}
                FormHelperTextProps={{
                    classes: {
                        root: classes.helperText,
                        error: classes.helperTextError,
                    },
                }}
            />
        </div>
    )
}

export default CommentSection;