import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Formik,
  Form,
  FormikProps,
  FormikValues,
} from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../../components/formik-conrtols/FormikControl';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { format, isDate, isWithinInterval } from 'date-fns';
import _, { values } from 'lodash';
import approvalSummaryStyles from '../approval-summary-styles';
import { useSnackbar } from 'notistack';
import {
  pendingApprovalSummaryColumnMapping,
  createColumns,
  emptyFilters,
  IFiltersObj,
  initialTableParameters,
  ITableParameters,
  IPendingApprovalSummaryData,
  LastAction,
} from './pendingSummaryUtils';
import {
  // fetchAllFilterRequests,
  fetchAllPendingApprovalsData,
  // fetchFilterEndPointsPendingSummary,
  fetchCollapsibleFormData,
  postDelegatedToSecondaryPar,
} from '../../../api-services/approval-summary';
import CustomButton from '../../../components/button';
import { postDelegatedToSecondary } from '../../../api-services/approval-summary';
import { ReactComponent as Sorting } from '../../../assets/icons/sorting_asc_inactive.svg';
import { ReactComponent as SortingAscending } from '../../../assets/icons/sorting_asc.svg';
import { ReactComponent as SortingDescending } from '../../../assets/icons/sorting_desc.svg';
import useDaterangePicker from '../../../components/date-range-picker/useDaterangePicker';
import { createRowCells } from '../approvalSummary/approvalSummaryUtils';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

const User = {
  comment: '',
  delegated_by: '',
  delegated_on: '',
}
const useStyles = makeStyles({
  approverText: {
    font: "normal 13px/20px Poppins-Regular",
    color: "#0A2756",
  },
  approverTextResults: {
    font: "normal 14px/19px NotoSerif-Regular",
    color: "#0A2756",
    paddingLeft: "17px",
  },
  astrik: {
    color: "#EF5B2B",
    margin: "0 2px",
  },
  linkText: { textDecoration: "underline", color: "#0080FF" },
});

function usePendingApprovalSummaryHook() {
  const { enqueueSnackbar } = useSnackbar();
  const [isAPILoading, setIsAPILoading] = useState(false);
  const [activateSubmit, setActivateSubmit] = useState(true);
  const formRef = useRef<FormikProps<FormikValues>>(null);
  const [subApproverList, setSubApproverList] = useState(
    { possibleDelegates: Array(), delegated: User });
  // const styleClasses = approvalSummaryStyles();
  const isTouchedhandler = () => {
    setActivateSubmit(false);
  };
  const onBlurHandler = () => {
    setActivateSubmit(true);
  };
  const lastActions = [{ id: "Approved", value: 'Approved' }, { id: "Remediation Requested", value: 'Remediation Requested' }, { id: "Rejected", value: 'Rejected' }]
  const initialRender = useRef(true);
  const history = useHistory();
  const classes = useStyles();
  const styleClasses = approvalSummaryStyles();
  const [open, setOpen] = useState(false);
  const [pendingSummaryData, setPendingSummaryData] = useState({
    count: 0,
    results: [],
    pageSize: 10
  });

  const [pendingSummaryDataFromApi, setPendingSummaryDataFromApi] = useState([]);
  const [tempPendingSummaryDataFromApi, setTempPendingSummaryDataFromApi] = useState([]);
  const [menuData, setMenuData] = useState<{ anchorEl: any, row: any, completeRow: any }>(
    {
      anchorEl: null,
      row: {
        rowId: '',
        stageId: '',
        requestType: '',
        linkedDelegationId: null,
        delegateToSecondary: null,
      },
      completeRow: null
    },
  );

  const [modalData, setModalData] = useState<{
    data: any, type: string
  }>({ data: null, type: '' });
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isLoadingPending, setIsLoadingPending] = useState({
    tableLoading: false,
    filters: {
      requestId: false,
      requestorName: false,
      currentStatus: false,
      myLastAction: false,
      requestType: false,
    },
  });
  const [filters, setFilters] = useState<IFiltersObj>(emptyFilters);
  const [tableParametersPending, setTableParametersPending] = useState<ITableParameters>(initialTableParameters);
  const [requestIdFilter, setRequestIdFilters] = useState([]);
  const [requestorNameFilter, setRequestorNameFilters] = useState<LastAction[]>([{ id: '', value: '' }]);
  const [myLastActionFilter, setMyLastActionFilters] = useState<LastAction[]>([{ id: '', value: '' }]);
  const [requestTypeFilter, setRequestTypeFilters] = useState<LastAction[]>([{ id: '', value: '' }]);;
  const [closeFilter, setCloseFilter] = useState(false);
  const [delegatedApprover, setDelegatedApprover] = useState<{
    selected_Approver: string;
    add_reason: string;
  }>();
  const {
    datepickerProps,
    setDatepickerProps,
    setDateRangeValue,
  } = useDaterangePicker({ isSingleDate: true });

  const fetchFiltersValues = () => {
    setIsLoadingPending((loadingState) => ({
      ...loadingState,
      filters: {
        ...loadingState.filters,
        // requestId: true,
        // requestorName: true,
        // currentStatus: true,
        // myLastAction: true,
        // requestType: true,
        // requestedOn: true,
        // assignedOn: true,
      },
    }));
  }
  const openDelegation = (row: any) => {
    setMenuData({
      anchorEl: null,
      row: {
        rowId: '',
        stageId: '',
        requestType: '',
        linkedDelegationId: null,
        delegateToSecondary: null,
      },
      completeRow: { ...row }
    });

    if (row.request_type === 'Delegation') {
      // console.log("123")
      if (row.request_purpose === "Connect to / manage a server or computer") {
        history.push(`/myApprovalTasks/pendingApproval/${row.request_id}`);
      } else {
        history.push(`/myApprovalTasks/pendingApprovalSar/${row.request_id}`);
      }
    } else {
      history.push(`/myApprovalTasks/pendingApprovalPar/${row.request_id}`);
    }
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const openMenu = (
    e: any,
    rowId: any,
    stageId: any,
    requestType: any,
    linkedDelegationId: any,
    delegateToSecondary: any,
    row: any,
  ) => {
    setMenuData({
      anchorEl: e.target,
      row:
      {
        rowId,
        stageId,
        requestType,
        linkedDelegationId,
        delegateToSecondary,
      },
      completeRow: { ...row }
    });

  };

  const formatDate = (dateValue: any) => (dateValue && isDate(new Date(dateValue)) ? format(new Date(dateValue), 'dd MMM yyyy') : '');

  useEffect(() => {
    const rowsArray = tempPendingSummaryDataFromApi.map((row: any) => {
      const cellArray = Object.entries(row)
        .map(([key, value]) => {
          // console.log(row);
          let cellData = null;
          switch (key) {
            case 'request_number':
              cellData = createRowCells(pendingApprovalSummaryColumnMapping.request_number, `${value}`, `${row.request_id}-${value}`, 1, () => openDelegation(row), { textDecoration: 'underline', color: '#0080FF' });
              // cellData.value = value;
              break;
            case 'requestor_name':
              cellData = createRowCells(pendingApprovalSummaryColumnMapping.requestor_name, `${value || ''}`, `${row.request_id}-${value || ''}`, 2);
              break;
            case 'request_type':
              cellData = createRowCells(pendingApprovalSummaryColumnMapping.request_type, `${value || ''}`, `${row.request_id}-${value || ''}`, 3);
              break;
            case 'request_stage':
              cellData = createRowCells(pendingApprovalSummaryColumnMapping.request_stage, `${value || ''}`, `${row.request_id}-${value || ''}`, 4);
              break;
            case 'last_action':
              cellData = createRowCells(pendingApprovalSummaryColumnMapping.last_action, row.actioned_on ? `${value} on ${formatDate(row.actioned_on)}` : '', `${row.request_id}-${value || ''}`, 5);
              break;
            case 'requested_on':
              cellData = createRowCells(pendingApprovalSummaryColumnMapping.requested_on, `${formatDate(value)}`, `${row.request_id}-${value || ''}`, 6);
              break;
            case 'assigned_on':
              cellData = createRowCells(pendingApprovalSummaryColumnMapping.assigned_on, `${formatDate(value)}`, `${row.request_id}-${value || ''}`, 7);
              break;
            default: break;
          }
          return cellData;
        }).filter((obj: any) => obj);
      cellArray.push(
        createRowCells(
          pendingApprovalSummaryColumnMapping.actions,
          <MoreVertIcon
            className={`${styleClasses.moreIcon} ${!!menuData.anchorEl && styleClasses.moreIconActive}`}
          />,
          `more-actions-${row.id}`,
          9,
          (e) => openMenu(
            e,
            row.request_id,
            row.request_stage_id,
            row.request_type.slice(0, 3).toLowerCase(),
            row.linked_delegation,
            row.delegate_to_secondary,
            row
          ),
          { textAlign: 'center' },
        ),
      );
      return cellArray;
    })
    setPendingSummaryData((prevState: any) => ({ ...prevState, results: [...rowsArray] }));
  }, [tempPendingSummaryDataFromApi]);


  // Set delegation Data onload once 
  const fetchAllPendingApprovalSummary = async () => {
    // @ts-ignore */
    await fetchAllPendingApprovalsData().then((res: any) => {
      setPendingSummaryDataFromApi(res.data.sort((a: any, b: any) => b.requested_on.localeCompare(a.requested_on)));
      setTempPendingSummaryDataFromApi(res.data.sort((a: any, b: any) => b.requested_on.localeCompare(a.requested_on)));

      // @ts-ignore */
      //For requestorName
      const requestorName = [...new Set(res.data?.map(({ requestor_name }) => requestor_name))].map(e => res.data?.find(({ requestor_name }) => requestor_name == e));
      // @ts-ignore */
      setRequestorNameFilters(requestorName?.map((requestorNameObj: { requestor_name: string }) => ({
        id: requestorNameObj.requestor_name,
        value: requestorNameObj.requestor_name === "" ? "(Blanks)" : requestorNameObj.requestor_name,
      })))

      // @ts-ignore */
      //For requestType
      const requestType = [...new Set(res.data?.map(({ request_type }) => request_type))].map(e => res.data?.find(({ request_type }) => request_type == e));
      // @ts-ignore */
      setRequestTypeFilters(requestType?.map((requestTypeObj: { request_type: string }) => ({
        id: requestTypeObj.request_type,
        value: requestTypeObj.request_type === "" ? "(Blanks)" : requestTypeObj.request_type,
      })))

      // @ts-ignore */
      //For mylastAction
      setMyLastActionFilters(lastActions);
      //For requestId
      setRequestIdFilters(res.data?.map((requestIdObj: { request_number: string }) => ({
        id: requestIdObj.request_number,
        value: requestIdObj.request_number,
      })))
      setIsLoadingPending((loadingState) => ({ ...loadingState, tableLoading: false }));
    }).catch((error: string) => {
      // enqueueSnackbar('Something went wrong! Please try again', { variant: 'error' });
      setIsLoadingPending((loadingState) => ({ ...loadingState, tableLoading: false }));
    }
    )
  }

  useEffect(() => {
    fetchAllPendingApprovalSummary();
    fetchFiltersValues();
    setIsLoadingPending((loadingState) => ({ ...loadingState, tableLoading: true }));
  }, []);


  const setDateRangeFilter = () => {

    setCloseFilter(true);
    let requestedOnData = pendingSummaryDataFromApi.filter(
      (obj: IPendingApprovalSummaryData) => {
        if (
          isWithinInterval(new Date(`${obj.assigned_on}`), {
            // @ts-ignore */
            start: datepickerProps.dateRange.startDate,
            // @ts-ignore */
            end: datepickerProps.dateRange.endDate,
          }) ||
          formatDate(obj.assigned_on) ===
          formatDate(datepickerProps.dateRange.endDate)
        )
          return true;
        if (
          isWithinInterval(new Date(`${obj.requested_on}`), {
            // @ts-ignore */
            start: datepickerProps.dateRange.startDate,
            // @ts-ignore */
            end: datepickerProps.dateRange.endDate,
          }) ||
          formatDate(obj.requested_on) ===
          formatDate(datepickerProps.dateRange.endDate)
        )
          return true;
        return false;
      }
    )
    setPendingSummaryDataFromApi([...requestedOnData]);
    setTableParametersPending((prevParameters: any) => ({
      ...prevParameters,
      page: Math.ceil(requestedOnData.length / pendingSummaryData.pageSize),
    }));
  };


  // const setDateRangeFilter = () => {
  //   setCloseFilter(true);
  //   setTempPendingSummaryDataFromApi([...pendingSummaryDataFromApi.filter((obj: IPendingApprovalSummaryData) => {
  //     // console.log("datepickerProps.dateRange.startDate", datepickerProps.dateRange)
  //     if (isWithinInterval(new Date(`${obj.assigned_on}`), {
  //       // @ts-ignore */
  //       start: datepickerProps.dateRange.startDate,
  //       // @ts-ignore */
  //       end: datepickerProps.dateRange.endDate,
  //     }) || formatDate(obj.assigned_on) === formatDate(datepickerProps.dateRange.endDate))
  //       if (isWithinInterval(new Date(`${obj.requested_on}`), {
  //         // @ts-ignore */
  //         start: datepickerProps.dateRange.startDate,
  //         // @ts-ignore */
  //         end: datepickerProps.dateRange.endDate,
  //       }) || formatDate(obj.requested_on) === formatDate(datepickerProps.dateRange.endDate))
  //         return true;
  //     return false;
  //   }
  //   )]
  //   );
  // };

  // clear value handler
  const clearValueHandler = () => {
    setIsLoadingPending((loadingState) => ({ ...loadingState, tableLoading: false }));
    setDatepickerProps({
      isSingleDate: false,
      dateRange:
      {
        startDate: null,
        endDate: null,
      },
    })
    setFilters(emptyFilters)
    setTempPendingSummaryDataFromApi(pendingSummaryDataFromApi)

  }


  // for sorting
  useEffect(() => {
    tableParametersPending.orderBy.column !== '' ? createUserManagementSummary() : setTempPendingSummaryDataFromApi(pendingSummaryDataFromApi);
  }, [tableParametersPending.orderBy.column, tableParametersPending.orderBy.order]);

  // For sorting Column Name
  const createUserManagementSummary = () => {
    setTempPendingSummaryDataFromApi((prevData) => [...prevData.sort(function (valueOne: any, valueTwo: any) {
      if (valueOne[tableParametersPending.orderBy.column]?.toLowerCase() < valueTwo[tableParametersPending.orderBy.column]?.toLowerCase())
        return tableParametersPending.orderBy.order === 'asc' ? -1 : 1;
      if (valueOne[tableParametersPending.orderBy.column]?.toLowerCase() > valueTwo[tableParametersPending.orderBy.column]?.toLowerCase())
        return tableParametersPending.orderBy.order === 'desc' ? -1 : 1;
      return 0;
    }
    ),
    ]);
  };

  const handleSort = (e: any) => {
    setTableParametersPending((prevParameters) => {
      const newOrderBy = { ...prevParameters.orderBy };
      if (e.target.id !== newOrderBy.column) {
        newOrderBy.order = "asc";
        newOrderBy.column = e.target.id;
      } else if (prevParameters.orderBy.order === "desc") {
        newOrderBy.order = "asc";
        newOrderBy.column = "";
      } else {
        newOrderBy.order = "desc";
      }
      return { ...prevParameters, orderBy: { ...newOrderBy } };
    });
  };
  const getSortingIcon = (columnId: string) => {
    if (tableParametersPending.orderBy.column === columnId) {
      return tableParametersPending.orderBy.order === 'desc' ? <SortingDescending /> : <SortingAscending />;
    }
    return <Sorting />;
  };
  const tableColumnsForPending = [
    createColumns(1, 'REQ ID', () => null, pendingApprovalSummaryColumnMapping.requestId, tableParametersPending.orderBy.column === pendingApprovalSummaryColumnMapping.requestId, () => null, { width: '75px' }),
    createColumns(2, 'Requestor Name', getSortingIcon(pendingApprovalSummaryColumnMapping.requestor_name), pendingApprovalSummaryColumnMapping.requestor_name, tableParametersPending.orderBy.column === pendingApprovalSummaryColumnMapping.requestor_name, (e) => handleSort(e), { width: '130px' }),
    createColumns(3, 'Request Type', getSortingIcon(pendingApprovalSummaryColumnMapping.request_type), pendingApprovalSummaryColumnMapping.request_type, tableParametersPending.orderBy.column === pendingApprovalSummaryColumnMapping.request_type, (e) => handleSort(e), { width: '115px' }),
    createColumns(4, 'Stage', getSortingIcon(pendingApprovalSummaryColumnMapping.request_stage), pendingApprovalSummaryColumnMapping.request_stage, tableParametersPending.orderBy.column === pendingApprovalSummaryColumnMapping.request_stage, (e) => handleSort(e), { width: '115px' }),
    createColumns(5, 'My Last Action', getSortingIcon(pendingApprovalSummaryColumnMapping.last_action), pendingApprovalSummaryColumnMapping.last_action, tableParametersPending.orderBy.column === pendingApprovalSummaryColumnMapping.last_action, (e) => handleSort(e), { width: '115px' }),
    createColumns(6, 'Requested On', getSortingIcon(pendingApprovalSummaryColumnMapping.requested_on), pendingApprovalSummaryColumnMapping.requested_on, tableParametersPending.orderBy.column === pendingApprovalSummaryColumnMapping.requested_on, (e) => handleSort(e), { width: '115px' }),
    createColumns(7, 'Assigned On', getSortingIcon(pendingApprovalSummaryColumnMapping.assigned_on), pendingApprovalSummaryColumnMapping.assigned_on, tableParametersPending.orderBy.column === pendingApprovalSummaryColumnMapping.assigned_on, (e) => handleSort(e), { width: '115px' }),
    createColumns(8, 'Actions', null, pendingApprovalSummaryColumnMapping.actions, false, undefined, { width: '15px' }),
  ];


  const fetchFilteredDataWithDebounce = (filtersObject: any) => {

    if (filtersObject?.requestId?.length > 0) {
      let requestIdData = pendingSummaryDataFromApi.filter((obj: IPendingApprovalSummaryData) => (filtersObject?.requestId.find((item: string) => item === obj.request_number))
      )
      setTempPendingSummaryDataFromApi([...requestIdData]);
      setTableParametersPending((prevParameters: any) => ({
        ...prevParameters,
        page: Math.ceil(requestIdData.length / pendingSummaryData.pageSize),
      }));
    }
    else if (filtersObject?.requestorName?.length > 0) {
      let requestorNameData = pendingSummaryDataFromApi.filter((obj: IPendingApprovalSummaryData) => (filtersObject?.requestorName.find((item: string) => item === obj.requestor_name)))
      setTempPendingSummaryDataFromApi([...requestorNameData]);
      setTableParametersPending((prevParameters: any) => ({
        ...prevParameters,
        page: Math.ceil(requestorNameData.length / pendingSummaryData.pageSize),
      }));
    }
    else if (filtersObject?.requestType?.length > 0) {
      let requestTypeData = pendingSummaryDataFromApi.filter((obj: IPendingApprovalSummaryData) =>
        (filtersObject?.requestType.find((item: string) => item == obj.request_type))
      )
      setTempPendingSummaryDataFromApi([...requestTypeData]);
      setTableParametersPending((prevParameters: any) => ({
        ...prevParameters,
        page: Math.ceil(requestTypeData.length / pendingSummaryData.pageSize),
      }));
    }
    else if (filtersObject?.myLastAction?.length > 0) {
      let myLastActionData = pendingSummaryDataFromApi.filter((obj: IPendingApprovalSummaryData) =>
        (filtersObject?.myLastAction.find((item: string) => item == obj.my_last_action))
      )
      setTempPendingSummaryDataFromApi([...myLastActionData]);
      setTableParametersPending((prevParameters: any) => ({
        ...prevParameters,
        page: Math.ceil(myLastActionData.length / pendingSummaryData.pageSize),
      }));
    }
    else {
      setTempPendingSummaryDataFromApi(pendingSummaryDataFromApi)
    }
  }

  useEffect(() => {
    fetchFilteredDataWithDebounce(filters);
  }, [filters]);

  const handleModalOpen = (actionType: string) => {
    if (menuData) {
      setModalData({ data: { ...menuData.completeRow }, type: actionType });
      setMenuData({
        anchorEl: null,
        row: {
          rowId: '',
          stageId: '',
          requestType: '',
          linkedDelegationId: null,
          delegateToSecondary: null,
        },
        completeRow: null
      });
    } else {
      setModalData({ data: null, type: actionType });
    }
    // fetchCollapsibleFormData(
    //   { requestId: menuData.row.rowId, stageId: menuData.row.stageId, requestType: menuData.row.requestType },
    // ).then((res) => {
    //   setSubApproverList({
    //     delegated: { ...res.data.delegated },
    //     possibleDelegates: [...res.data.possible_delegates],
    //   });
    // }).catch((err) => {
    //   enqueueSnackbar('Something went wrong! Please try again', { variant: 'error' });
    // });
    setModalOpen(true);
  };



  const initialValues = {
    select_approver: "",
    add_reason_delegation: "",
  };
  const validationSchema = Yup.object({
    select_approver: Yup.string().required("Required"),
    add_reason_delegation: Yup.string().required("Required"),
  });

  const idValue = (label: string) => {
    const result = modalData.data.delegate_to_secondary.possible_delegates.find(
      (item: any) => item.name === label
    );
    return result.id;
  };

  const onSubmit = (values: any, submitProps: any) => {

    if (modalData.data.request_type === "Delegation") {
      setIsAPILoading(false);
      const postBody = {
        request_id: modalData.data.request_id,
        delegate_user: idValue(values.select_approver),
        comment: values.add_reason_delegation,
      };

      postDelegatedToSecondary(postBody).then(
        () => {
          enqueueSnackbar(
            `Delegation request ${modalData.data.request_number}  has been delegated to ${values.select_approver} successfully.`,
            { variant: "success" }
          );
          history.push("/myApprovalTasks");
          fetchAllPendingApprovalSummary()
          setIsAPILoading(false);
        },
        (error: any) => {
          enqueueSnackbar("Something went wrong! Please try again", {
            variant: "error",
          });
          console.error(error);
          setIsAPILoading(false);
        }
      );
      submitProps.setSubmitting(false);
      submitProps.resetForm();
      handleModalClose();
    } else {
      setIsAPILoading(false);
      const postBody = {
        par_id: modalData.data.request_id,
        delegate_user: idValue(values.select_approver),
        comment: values.add_reason_delegation,
      };
      postDelegatedToSecondaryPar(postBody).then(
        () => {
          enqueueSnackbar(
            `PAR request ${modalData.data.request_number}  has been delegated to ${values.select_approver} successfully.`,
            { variant: "success" }
          );
          history.push("/myApprovalTasks");
          fetchAllPendingApprovalSummary()
          setIsAPILoading(false);
        },
        (error: any) => {
          enqueueSnackbar("Something went wrong! Please try again", {
            variant: "error",
          });
          console.error(error);
          setIsAPILoading(false);
        }
      );

      setDelegatedApprover({
        selected_Approver: values.select_approver,
        add_reason: values.add_reason_delegation,
      });
      submitProps.setSubmitting(false);
      submitProps.resetForm();
      handleModalClose();
    }

  };

  const handleSave = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const delegationHandler = () => {
    return (
      <>
        <Grid item xs={8} style={{ paddingTop: "11px" }}>
          <span className={classes.approverText}>Delegated by:</span>
          <span className={classes.approverTextResults}>
            {subApproverList.delegated.delegated_by}
          </span>
        </Grid>
        <Grid item xs={8} style={{ paddingTop: "11px" }}>
          <span className={classes.approverText}> Delegated on:</span>
          <span className={classes.approverTextResults}>
            {formatDate(subApproverList.delegated.delegated_on)}
          </span>
        </Grid>
        <Grid item xs={8} style={{ paddingTop: "11px", paddingBottom: "11px" }}>
          <span className={classes.approverText}>Comments: </span>
          <span className={classes.approverTextResults}>
            {subApproverList.delegated.comment}
          </span>
        </Grid>
      </>
    );
  };
  function isEmpty(obj: any) {
    return Object.keys(obj).length === 0;
  }

  const collapsibleElementHandler = () => {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnChange={false}
        validateOnBlur={false}
        innerRef={formRef}
      >
        <Form>
          <Grid container>
            {subApproverList.possibleDelegates.length > 0 && (
              <>
                {!isEmpty(subApproverList.delegated) && delegationHandler()}
                {/* {console.log(isEmpty(subApproverList.delegated), 'ssss')} */}
                <Grid item xs={10}>
                  <FormikControl
                    control="autocomplete"
                    label="Select approver"
                    name="select_approver"
                    options={subApproverList.possibleDelegates.map(
                      (approver: any) => ({
                        label: approver.name,
                        value: approver.name,
                      })
                    )}
                    required
                  />
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12} style={{ paddingTop: "15px" }}>
                  <FormikControl
                    control="textfield"
                    label="Add reason for delegation"
                    name="add_reason_delegation"
                    multiline
                    minRows={4}
                    required
                  />
                </Grid>
                <Grid item xs={12}></Grid>
              </>
            )}

            {!isEmpty(subApproverList.delegated) && subApproverList.possibleDelegates.length === 0 && delegationHandler()}
            {isEmpty(subApproverList.delegated) && isEmpty(subApproverList.possibleDelegates) &&
              <>
                <Grid item xs={12} style={{ paddingTop: "15px" }}>
                  <span className={classes.approverTextResults}>
                    {'This request cannot be delegated since the Administrator has not assigned any backup approvers for this stage.'}
                  </span>
                </Grid>
              </>}
          </Grid>
        </Form>
      </Formik>
    );
  };
  // const modalContentHandler = (type: string) => {
  //   switch (type) {
  //     case '':
  //       return (
  //         <>
  //         </>
  //       );
  //     default return;
  //   }
  // }



  const modalChangeButton = () => {
    return (
      (!isEmpty(subApproverList.delegated) || (subApproverList.possibleDelegates.length > 0)) ? <div className={styleClasses.modalActionDiv}>
        <CustomButton
          disabled={isAPILoading}
          variant="contained"
          actionType="secondary"
          onClick={handleModalClose}
        >
          Cancel
        </CustomButton>
        <CustomButton
          disabled={isAPILoading}
          variant="contained"
          actionType="primary"
          disableMargin
          onClick={() => handleSave()}
        >
          Submit
        </CustomButton>
      </div> : isEmpty(subApproverList.delegated) && isEmpty(subApproverList.possibleDelegates) &&
      <div className={styleClasses.modalActionDiv}>
        <CustomButton
          disabled={isAPILoading}
          variant="contained"
          actionType="primary"
          disableMargin
          onClick={() => handleModalClose()}
        >
          Ok
        </CustomButton>
      </div>
    );

  }
  const modalContentHandler = (type: string) => {
    switch (type) {
      case 'change':
        return (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            validateOnChange={false}
            validateOnBlur={false}
            innerRef={formRef}
          >
            <Form>
              <Grid container>
                {!(isEmpty(modalData.data.delegate_to_secondary.delegated)) &&
                  <>
                    <Grid item xs={8} style={{ paddingTop: "11px" }}>
                      <span className={classes.approverText}>Delegated by:</span>
                      <span className={classes.approverTextResults}>
                        {modalData.data.delegate_to_secondary.delegated.delegated_by}
                      </span>
                    </Grid>
                    <Grid item xs={8} style={{ paddingTop: "11px" }}>
                      <span className={classes.approverText}> Delegated on:</span>
                      <span className={classes.approverTextResults}>
                        {formatDate(modalData.data.delegate_to_secondary.delegated.delegated_on)}
                      </span>
                    </Grid>
                    <Grid item xs={8} style={{ paddingTop: "11px", paddingBottom: "11px" }}>
                      <span className={classes.approverText}>Comments: </span>
                      <span className={classes.approverTextResults}>
                        {modalData.data.delegate_to_secondary.delegated.comment}
                      </span>
                    </Grid>
                  </>
                }
                <Grid item xs={10}>
                  <FormikControl
                    control="autocomplete"
                    label="Select approver"
                    name="select_approver"
                    options={modalData.data.delegate_to_secondary.possible_delegates.map(
                      (approver: any) => ({
                        label: approver.name,
                        value: approver.name,
                      })
                    )}
                    required
                  />
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12} style={{ paddingTop: "15px" }}>
                  <FormikControl
                    control="textfield"
                    label="Add reason for delegation"
                    name="add_reason_delegation"
                    multiline
                    minRows={4}
                    required
                  />
                </Grid>
                <Grid item xs={12}></Grid>
              </Grid>
            </Form>
          </Formik>
        );
      case 'info':
        return (
          <>
            {(isEmpty(modalData.data.delegate_to_secondary.delegated) && !(modalData.data.delegate_to_secondary.possible_delegates.length > 0)) && <>
              <Grid item xs={12} style={{ paddingTop: "15px" }}>
                <span className={classes.approverTextResults}>
                  {'This request cannot be delegated since the Administrator has not assigned any backup approvers for this stage.'}
                </span>
              </Grid>
            </>}
            {!(isEmpty(modalData.data.delegate_to_secondary.delegated) && !(modalData.data.delegate_to_secondary.possible_delegates.length > 0)) &&
              <>
                <Grid item xs={12} style={{ paddingTop: "11px" }}>
                  <span className={classes.approverText}>Delegated by:</span>
                  <span className={classes.approverTextResults}>
                    {modalData.data.delegate_to_secondary.delegated.delegated_by}
                  </span>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: "11px" }}>
                  <span className={classes.approverText}> Delegated on:</span>
                  <span className={classes.approverTextResults}>
                    {formatDate(modalData.data.delegate_to_secondary.delegated.delegated_on)}
                  </span>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: "11px", paddingBottom: "11px" }}>
                  <span className={classes.approverText}>Comments: </span>
                  <span className={classes.approverTextResults}>
                    {modalData.data.delegate_to_secondary.delegated.comment}
                  </span>
                </Grid>
              </>

            }
          </>
        )
      default: return null;
    }
  }

  const modalActionHandler = (type: string) => {
    switch (type) {
      case "change":
        return (
          modalChangeButton()
          // <div className={styleClasses.modalActionDiv}>
          //   <CustomButton
          //     disabled={isAPILoading}
          //     variant="contained"
          //     actionType="secondary"
          //     onClick={handleModalClose}
          //   >
          //     Cancel
          //   </CustomButton>
          //   <CustomButton
          //     disabled={isAPILoading}
          //     variant="contained"
          //     actionType="primary"
          //     disableMargin
          //     onClick={() => handleSave()}
          //   >
          //     Submit
          //   </CustomButton>
          // </div>
        );
      case "info":
        return (
          <div className={styleClasses.modalActionDiv}>
            <CustomButton
              disabled={isAPILoading}
              variant="contained"
              actionType="primary"
              disableMargin
              onClick={() => handleModalClose()}
            >
              Ok
            </CustomButton>
          </div>
        );
      default:
        return (
          <div className={styleClasses.modalActionDiv}>
            <CustomButton
              disabled={isAPILoading}
              variant="contained"
              actionType="primary"
              disableMargin
              onClick={() => handleModalClose()}
            >
              Ok
            </CustomButton>
          </div>
        );
    }
  };
  const modalHeaderHandler = (type: string) => {
    switch (type) {
      case 'change':
        return 'Change Approver';
      case 'info':
        return 'Delegation Info';
      default: return '';
    }
  };
  return {
    open,
    styleClasses,
    tableColumnsForPending,
    pendingSummaryData,
    isLoadingPending,
    filters,
    setFilters,
    tableParametersPending,
    setDateRangeFilter,
    setTableParametersPending,
    requestIdFilter,
    requestorNameFilter,
    myLastActionFilter,
    requestTypeFilter,
    setCloseFilter,
    closeFilter,
    datepickerProps,
    setDatepickerProps,
    setDateRangeValue,
    handleModalOpen,
    modalData,
    menuData,
    setMenuData,
    modalOpen,
    modalHeaderHandler,
    handleModalClose,
    modalContentHandler,
    modalActionHandler,
    subApproverList,
    clearValueHandler,
    collapsibleElementHandler,
  };
}
export default usePendingApprovalSummaryHook;
