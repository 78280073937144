import React from 'react';
import { Tooltip } from '@mui/material';
import useStyles from './useStyles';

function HelperTooltip({
  tooltipMsg,
  children,
}: {
  tooltipMsg: string,
  children: React.ReactElement
}) {
  const classes = useStyles();
  return (
    <span className={classes.tooltipIcon}>
      <Tooltip

        classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
        title={<div dangerouslySetInnerHTML={{ __html: tooltipMsg }} />}
        arrow
        placement="right-start"
        data-testid="fieldComponent"
      >
        {children}
      </Tooltip>
    </span>
  );
}
export default HelperTooltip;
