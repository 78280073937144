import { makeStyles } from "@mui/styles";

export const implementationStyles = makeStyles({
  mainModelGrid: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #B9C3CE !important",
    },
    "& .modalHeader": {
      minHeight: "45px !important",
    },
    "& .MuiPaper-root.MuiPaper-elevation": {
      "&:first-child": {
        minHeight: "45px !important",
      },
    },
  },
  boldText: {
    fontFamily: "Poppins-Bold",
    fontSize: "14px",
  },
  snippet: {
    color: "#EFB6A3",
    fontSize: "12px",
    fontFamily: "NotoSerif-Regular",
  },
  gridContainer: {
    padding:'0 20px 5px 20px',
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "10px 0!important",
      color: "#FFFFFF !important",
    },
    "& .MuiAccordionSummary-content": {
      margin: "10px 0!important",
      color: "#0A2756",
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      // color: "#ffffff !important",
      backgroundColor: "#DFEFFF !important",
      color: "red !important",
      // "& .MuiSvgIcon-root": {
      //   color: "#FFF",
      // },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #B9C3CE !important",
    },
    // '& .MuiAccordionSummary-root.Mui-expanded .MuiSvgIcon-root': {
    //   color: '#0A2756',
    //   background: '#0A2756'
    // },
  },
  gridTitle: {
    backgroundColor: "#DFEFFF",
    boxShadow: "none",
    font:"poppins",
   
    
  },
  
 
  accordionSummary: {
    minHeight: "0px !important",
    fontFamily:"Poppins-Regular",

    "& .MuiTypography-root": {
      color: "#0A2756",
      fontFamily: "Poppins-Regular",
      fontSize: "14px",
      
    },
    
   
  },

  accordionDetails: {
    backgroundColor: "#FFFFFF",
    paddingTop: "7px",
    fontFamily: "NotoSerif-Regular",

    "& .MuiTypography-root": {
      color: "#0A2756",
      fontFamily: "NotoSerif-Regular",
      fontSize: "14px",
      marginTop:"7px",
      
      
    },

    

  },
 
  subTitle: {
    marginLeft: "10px",
    color: "#0080FF",
  },
  markStep: {
    margin: "8px -2px",
  },
  checkboxLabel: {
    "&+span": {
      font: "normal normal normal normal 14px/21px Poppins-Regular",
      fontSize: "14px",
      color: "#0A2756",
    },
  },
  taskList: {
    fontFamily: "NotoSerif-Regular",
    fontSize: "14px",
    color: "#0A2756",
    padding: "10px 0 15px 0",
  },
  gridItem: {
    backgroundColor: "#fff",
    textAlign: "center",
    borderRadius: "4px",
    fontFamily: "NotoSerif-Regular",
    fontSize: "14px",
    color: "#0A2756",
    border: "1px solid #B9C3CE",
    //padding: "25px",
    position: "relative",
    //display: "inline-block",
    cursor: "pointer",
    width: "100%",
    height: "70px",
    alignItems: "center",
    display: "flex",
    "&:hover div": {
      display: "block",
    },
  },
  roleName: {
    width: "100%",
  },
  pencilIcon: {
    padding: "0px 5px 5px 0px",
    position: "absolute",
    right: "0",
    bottom: "0",
    display: "none",
    cursor: "pointer",
  },

  rightCheckIcon: {
    padding: "0px 5px 5px 0px",
    position: "absolute",
    right: "0",
    bottom: "0",
    display: "block",
    cursor: "pointer",
  },


  modelCheckboxLabel: {
    "&+span": {
      fontFamily: "NotoSerif-Regular",
      fontSize: "14px",
      color: "#0A2756",
    },
  },
  linkTag: {
    padding: "0 5px",
    color: "#0080FF",
  },
  fileUploaderMain: {
    border: "2px dashed #B9C3CE",
    borderRadius: "4px",
    width: "100%",
    padding: "8.5px 14px",
    display: "flex",
    justifyContent: "center",
  },
  filePasteDive: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },

  sectionHeader: {
    display: "inline-flex",
    fontSize: "15px",
    fontFamily: "Poppins-Bold",
    position: "relative",
    color: "#0A2756",
    marginTop: "15px",
  },

  titleContainer: {
    display: "flex",
    alignItems: "center",
    // marginBottom: "10px",
    marginTop: "15px",
    
  },

  moduleHeader: {
    marginBottom: "5px",
    fontSize: "14px",
    fontFamily: "Poppins-SemiBold",
    // font: 'normal normal 600 0.875rem/21px Poppins-SemiBold',
    color: "#FFFFFF",
    marginTop: "15px",
    // marginLeft: "14px",
    backgroundColor: "#00b5b1",
    padding: "12px"
  },

  aTitle: {
    marginRight: "20px",
    marginLeft: "20px",
    marginTop: "7px",
    marginBottom: "7px",
    fontSize: "14px",
    fontFamily: "Poppins-Regular",
  },

  accordionData:{
    lineHeight:"2.5",
    marginTop: "7px",
  }
});
