import { StyledEngineProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import "../App.css";
import Header from "../components/header";
import { ReactComponent as SuccessIcon } from "../assets/icons/toaster_success.svg";
import { ReactComponent as ErrorIcon } from "../assets/icons/toaster_error.svg";
import { ReactComponent as InfoIcon } from "../assets/icons/toaster_info.svg";
import { ReactComponent as WarningIcon } from "../assets/icons/toaster_warning.svg";
import Routes from "./routes";
import useStyles from './useStyles';


function Layout() {
  const snackbarClass = useStyles();
  return (
    <StyledEngineProvider injectFirst>
      <SnackbarProvider
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          root: snackbarClass.root,
          variantSuccess: snackbarClass.success,
          variantError: snackbarClass.error,
          variantWarning: snackbarClass.warning,
          variantInfo: snackbarClass.info,
        }}
        iconVariant={{
          success: <SuccessIcon style={{ marginRight: "8px" }} />,
          error: <ErrorIcon style={{ marginRight: "8px" }} />,
          warning: <WarningIcon style={{ marginRight: "8px" }} />,
          info: <InfoIcon style={{ marginRight: "8px" }} />,
        }}
        autoHideDuration={2500}
      >
        <Header />
        <Routes />
      </SnackbarProvider>
    </StyledEngineProvider>
  );
}

export default Layout;
