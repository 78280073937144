import React, { useEffect, useState } from 'react';
import Grid from "@mui/material/Grid";
import FormikControl from "../../../formik-conrtols/FormikControl";
import FieldLabel from "../../../field-label";
import { parImplementationStyles } from "../../useStyles";
import Typography from "@mui/material/Typography";
import HelperTooltip from "../../../helper-tooltip";
import { HelpRounded } from "@mui/icons-material";
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../appStore/store';
import { adTooltip2, serviceAccountAd, userLogonName } from '../tooltip';
import { isEmpty } from 'lodash';

function AccountCreationAD({ isDisabled }: { isDisabled: boolean }) {
  const classes = parImplementationStyles();
  const formikCtx: any = useFormikContext();
  const { adImplementationOnpremList } = useSelector((state: RootState) => state);

  const userLogonname = () => {
    const fullName = `${formikCtx.values.par_first_name}${formikCtx.values.par_first_name}`
    let firstName = formikCtx.values.par_first_name
    if (fullName.length > 17) {
      firstName = firstName.substring(0, fullName.length - 17)
    }
    const temp = `${firstName}.${formikCtx.values.par_last_name}-s@${formikCtx.values.par_domain}`
    return temp
  }
  useEffect(() => {

    if (formikCtx.values.del_request_id !== null) {
      formikCtx.setFieldValue('enter_correct_role_group_name', adImplementationOnpremList[0].enter_correct_role_group_name)
      if (formikCtx.values.request_purpose === 'Connect to / manage a server or computer') {
        formikCtx.setFieldValue('enter_user_logon_name_in_adaxes', userLogonname())
      } else {
        formikCtx.setFieldValue('enter_user_logon_name_in_adaxes', formikCtx.values.sar_user_logon_name_upn);
      }
    }
    if (!isEmpty(adImplementationOnpremList[0])) {
      formikCtx.setFieldValue('login_to_adaxes', adImplementationOnpremList[0].login_to_adaxes)
      formikCtx.setFieldValue('is_select_create_admin', adImplementationOnpremList[0].is_select_create_admin)
      formikCtx.setFieldValue('is_entered_user_logon_name', adImplementationOnpremList[0].is_entered_user_logon_name)
      formikCtx.setFieldValue('communicate_password_securely', adImplementationOnpremList[0].communicate_password_securely)
    }

  }, [])



  return (
    <>
      <Grid container spacing={2} className={classes.gridContainer}>
        <Grid item xs={12}>
          <FieldLabel Title="Implementation task list for on-premise account creation" />
        </Grid>
        <Grid item xs={12} className={classes.gridLeft}>
          <Typography className={classes.titleQue}>
            <div>
              Perform following steps in <strong>Adaxes</strong>
            </div>
            <span className={classes.astrik}>:</span>
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.gridLeft}>
          <Typography className={classes.titleQue}>
            <div>
              Login to
              <a
                className={classes.linkTag}
                href="https://id.insidemedia.net/Adaxes#/SignIn"
              >
                https://id.insidemedia.net/Adaxes#/SignIn
              </a>
              and select <strong>GroupM AD Admins</strong> for an Admin / Test account or <strong> GroupM SA Admins</strong> for a Service Account from the dropdown list <span className={classes.astrik}>*</span>
            </div>
            <HelperTooltip tooltipMsg={serviceAccountAd}>
              <HelpRounded />
            </HelperTooltip>

          </Typography>
          <div className={classes.markStepQue}>
            <FormikControl
              control="checkbox"
              label="Check if completed"
              name="login_to_adaxes"
              required
              className={classes.checkboxLabelQue}
              disabled={isDisabled}
            />
          </div>
        </Grid>
        {formikCtx.values.login_to_adaxes === true &&
          <Grid item xs={12} className={classes.gridLeft}>
            <Typography className={classes.titleQue}>
              <div>
                Select <strong>Create Admin Account / Create Service Account</strong> form the left hand tool bar.
                In <strong>Create</strong> window, drill down to the OU in which
                you wish to create the user and select <strong>Next</strong><span className={classes.astrik}>*</span>
              </div>

            </Typography>
            <div className={classes.markStepQue}>
              <FormikControl
                control="checkbox"
                label="Check if completed"
                name="is_select_create_admin"
                required
                disabled={isDisabled}
                className={classes.checkboxLabelQue}
              />
            </div>
          </Grid>}
        {((formikCtx.values.del_request_id !== null) && (formikCtx.values.is_select_create_admin === true)) &&
          <>

            <Grid item xs={12} className={classes.gridLeft}>
              <Typography className={classes.titleQue}>
                <div>
                  Populate the Adaxes form with details from the PAR request, using the below <strong>Proposed User Logon Name / samaccountname</strong>
                </div>
              </Typography>
              <Typography className={classes.titleQue}>
                <div>
                  Proposed <strong>User Logon Name</strong> (samaccountname)
                </div>
                <span className={classes.astrik}>*</span>
                <HelperTooltip tooltipMsg={userLogonName}>
                  <HelpRounded />
                </HelperTooltip>
              </Typography>
              <Grid item xs={5}>
                <div className={classes.markStepQue}>
                  <FormikControl
                    control="textfield"
                    label=""
                    name="enter_user_logon_name_in_adaxes"
                    required
                    className={classes.checkboxLabelQue}
                    disabled={isDisabled || formikCtx.values.is_entered_user_logon_name === true ? true : false}
                  />
                </div>
              </Grid>
              <div className={classes.markStepQue}>
                <FormikControl
                  control="checkbox"
                  label="Check if completed"
                  name="is_entered_user_logon_name"
                  required
                  disabled={isDisabled}
                  className={classes.checkboxLabelQue}
                />
              </div>
            </Grid>
          </>
        }

        {(formikCtx.values.del_request_id !== null) && (formikCtx.values.is_select_create_admin === true) &&
          <Grid item xs={12} className={classes.gridLeft}>
            <Typography className={classes.title}>
              <div>
                After creating the account, make sure you add the correct <strong>role group</strong>  to the account using the <strong>Role Group name</strong> below
              </div>
              <span className={classes.astrik}>*</span>
            </Typography>
            <Typography className={classes.title}>
              <div>
                Search for the account you have just created in Adaxes Scroll down to the <strong>Member</strong> of section and select <strong>ADD</strong> Find the below listed role group, select it and click <strong>OK</strong>
              </div>
            </Typography>
            <Typography className={classes.title}>
              <div>
                Role group name
              </div>
              <span className={classes.astrik}>*</span>
              <HelperTooltip tooltipMsg={adTooltip2}>
                <HelpRounded />
              </HelperTooltip>
            </Typography>
            <>
              <Grid item xs={5}>
                {/* <div className={classes.markStepQue}> */}
                <FormikControl
                  control="textfield"
                  label=""
                  name="enter_correct_role_group_name"
                  required
                  className={classes.checkboxLabelQue}
                  disabled={true}
                />
                {/* </div> */}
              </Grid>
              <div className={classes.markStepQue}>
                <FormikControl
                  control="checkbox"
                  label="Check if completed"
                  name="is_correct_role_group_entered"
                  required
                  disabled={isDisabled}
                  className={classes.checkboxLabelQue}
                />
              </div>
            </>
          </Grid>}
        {(formikCtx.values.del_request_id === null || formikCtx.values.request_purpose !== 'Connect to / manage a server or computer') ? (formikCtx.values.is_select_create_admin === true) &&
          <Grid item xs={12} className={classes.gridLeft}>
            <Typography className={classes.titleQue}>
              <div>
                Communicate password securely to account owner
              </div>
            </Typography>
            <Grid item xs={5}>
              {/* <div className={classes.markStepQue}> */}
              <FormikControl
                control="checkbox"
                label="Check if completed"
                name="communicate_password_securely"
                required
                disabled={isDisabled}
                className={classes.checkboxLabelQue}
              />
              {/* </div> */}
            </Grid>
          </Grid> : (formikCtx.values.is_correct_role_group_entered === true) && <Grid item xs={12} className={classes.gridLeft}>
            <Typography className={classes.titleQue}>
              <div>
                Communicate password securely to account owner
              </div>
            </Typography>
            <Grid item xs={5}>
              {/* <div className={classes.markStepQue}> */}
              <FormikControl
                control="checkbox"
                label="Check if completed"
                name="communicate_password_securely"
                required
                disabled={isDisabled}
                className={classes.checkboxLabelQue}
              />
              {/* </div> */}
            </Grid>
          </Grid>}

        {(formikCtx.values.del_request_id !== null) ? (
          (formikCtx.values.is_correct_role_group_entered === true)
          &&
          <Grid item xs={12} className={classes.markStep}>
            <div className={classes.markStep}>
              <FormikControl
                control="checkbox"
                label="Mark this step complete"
                name="is_step_complete_ad"
                required
                disabled={isDisabled}
                className={classes.checkboxLabel}
              />
            </div>
            {/* <span className={classes.astrik}>*</span> */}
          </Grid>
        ) : (
          (formikCtx.values.communicate_password_securely === true) &&
          <Grid item xs={12} className={classes.markStep}>
            <div className={classes.markStep}>
              <FormikControl
                control="checkbox"
                label="Mark this step complete"
                name="is_step_complete_ad"
                required
                disabled={isDisabled}
                className={classes.checkboxLabel}
              />
            </div>
            {/* <span className={classes.astrik}>*</span> */}
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default AccountCreationAD;
