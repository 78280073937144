import { makeStyles } from '@mui/styles';

export const phoneInputStyles = makeStyles({
  container: {
    '& > .special-label': {
      fontSize: '12px',
      // font: 'normal normal normal 12px/17px NotoSerif-Regular',
      fontFamily: 'NotoSerif-Regular',
      transform: 'scale(0.9)',
      color: '#0080FF',
      top: '-9px',
      left: '8px',
    },
    '& > .flag-dropdown': {
      '& > .country-list': {
        zIndex: '5',
      },
      '& > .selected-flag': {
        zIndex: '1',
      },
      '&:after': {
        // background: 'orange',
        borderRight: '1px solid #C6D2DC !important',
        content: '""',
        position: 'absolute',
        top: '15%',
        right: '0',
        bottom: '0',
        left: '0',
        height: '70%',
        zIndex: '0',
      },
    },
  },
  inputField: {
    width: '100% !important',
    font: 'normal normal normal 14px/19px Noto-Serif !important',
    letterSpacing: '0px',
    color: '#0A2756 !important',
    paddingTop: '6.5px !important',
    paddingBottom: '6.5px !important',
    '&:disabled': {
      cursor: 'initial !important',
      color: '#0A2756',
    },
    '&:disabled:hover': {
      borderColor: '#CACACA',
    },
  },
  dropdown: {
    marginTop: '0px !important',
    font: 'normal normal normal 14px/16px NotoSerif',
    letterSpacing: '0px',
    color: '#0A2756',
    '& > .country:hover': {
      backgroundColor: '#EDF4F8 !important',
    },
  },
  inputError: {
    borderColor: '#EF5B2B !important',
  },
  errorSpan: {
    textAlign: 'left',
    font: 'normal normal normal 10px/20px NotoSerif-Regular',
    letterSpacing: '0px',
    color: '#EF5B2B',
    marginTop: '0px',
    marginLeft: '4px',
  },
});

export default phoneInputStyles;
