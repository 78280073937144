import { useEffect, useRef, useState } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    FormControlLabel,
    Grid,
    Typography,
} from "@mui/material";
import React from "react";
import FieldLabel from "../../../field-label";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { implementationStyles } from "./implementationStyle";
import FormikControl from "../../../formik-conrtols/FormikControl";
import { useFormikContext } from 'formik';
import { useSelector } from "react-redux";
import { RootState } from "../../../../appStore/store";
import { isEmpty } from "lodash";

const ImplementationSar = () => {
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const formikCtx: any = useFormikContext();
    const { aadSarImplementationList } = useSelector((state: RootState) => state);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const classes = implementationStyles();

    useEffect(() => {
        setExpanded('panel1');
    }, [])



    const proposedRoleGroupName = () => `${formikCtx.values.sar_city_code.substr(0, 3)}-${formikCtx.values.sar_application_name}-SAAcs-gs`
    const proposedDelegationRoleName = () => `Service Account - _${formikCtx.values.sar_city_code.substr(0, 3)}${formikCtx.values.sar_company_code.substr(0, 3)}.${formikCtx.values.sar_application_name}`
    const proposedTargetGroupName = () => `${formikCtx.values.sar_city_code.substr(0, 3)}-${formikCtx.values.sar_application_name}-SATgt-gs`
    const proposedGpoName = () => `Access-${formikCtx.values.sar_city_code.substr(0, 3)}-${formikCtx.values.sar_application_name}-SAAcs`

    useEffect(() => {
        if (!isEmpty(aadSarImplementationList[0])) {
            formikCtx.setFieldValue('sar_on_premise_id', aadSarImplementationList[0].sar_on_premise_id)
            formikCtx.setFieldValue('is_step_one_completed', aadSarImplementationList[0].is_step_one_completed)
            formikCtx.setFieldValue('proposed_role_group_name', proposedRoleGroupName())
            formikCtx.setFieldValue('is_step_two_completed', aadSarImplementationList[0].is_step_two_completed)
            formikCtx.setFieldValue('proposed_delegation_role_group_name', proposedDelegationRoleName())
            formikCtx.setFieldValue('proposed_target_group_name', proposedTargetGroupName())
            formikCtx.setFieldValue('proposed_gpo_name', proposedGpoName())
            formikCtx.setFieldValue('is_delegation_gpo_target_completed', aadSarImplementationList[0].is_delegation_gpo_target_completed)
            formikCtx.setFieldValue('is_delegation_info_completed', aadSarImplementationList[0].is_delegation_info_completed)
            formikCtx.setFieldValue('complete_manage_by_section', aadSarImplementationList[0].complete_manage_by_section)
            formikCtx.setFieldValue('complete_other_section', aadSarImplementationList[0].complete_other_section)
            formikCtx.setFieldValue('is_step_three_completed', aadSarImplementationList[0].is_step_three_completed)
            formikCtx.setFieldValue('is_service_account_require_access', aadSarImplementationList[0].is_service_account_require_access)
            formikCtx.setFieldValue('review_delegation_form_to_determine_scope', aadSarImplementationList[0].review_delegation_form_to_determine_scope)
            formikCtx.setFieldValue('is_step_four_completed', aadSarImplementationList[0].is_step_four_completed)
            formikCtx.setFieldValue('is_target_group_name_completed', aadSarImplementationList[0].is_target_group_name_completed)
            formikCtx.setFieldValue('is_target_group_delegation_info_completed', aadSarImplementationList[0].is_target_group_delegation_info_completed)
            formikCtx.setFieldValue('is_target_group_complete_manage_by_section', aadSarImplementationList[0].is_target_group_complete_manage_by_section)
            formikCtx.setFieldValue('is_target_group_complete_other_section', aadSarImplementationList[0].is_target_group_complete_other_section)
            formikCtx.setFieldValue('is_step_five_completed', aadSarImplementationList[0].is_step_five_completed)
            formikCtx.setFieldValue('copy_target_group_name', aadSarImplementationList[0].copy_target_group_name)
            formikCtx.setFieldValue('add_members_to_target_group', aadSarImplementationList[0].add_members_to_target_group)
            formikCtx.setFieldValue('is_step_six_completed', aadSarImplementationList[0].is_step_six_completed)
            formikCtx.setFieldValue('is_group_policy_gpo_name_completed', aadSarImplementationList[0].is_group_policy_gpo_name_completed)
            formikCtx.setFieldValue('is_group_policy_role_group_completed', aadSarImplementationList[0].is_group_policy_role_group_completed)
            formikCtx.setFieldValue('is_select_the_delegation', aadSarImplementationList[0].is_select_the_delegation)
            formikCtx.setFieldValue('is_step_seven_completed', aadSarImplementationList[0].is_step_seven_completed)
            formikCtx.setFieldValue('is_assignment_to_gpo_completed', aadSarImplementationList[0].is_assignment_to_gpo_completed)
            formikCtx.setFieldValue('is_step_eight_completed', aadSarImplementationList[0].is_step_eight_completed)
            formikCtx.setFieldValue('is_implemented', aadSarImplementationList[0].is_implemented)
        }
        setExpanded('panel1');
    }, []);

    return (
        <>
            <Grid container className={classes.gridContainer}>
                <Grid item xs={5}>
                    <FieldLabel Title="Implementation task list" />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: '5px' }}>
                    <Accordion
                        expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className={classes.gridTitle}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className={classes.accordionSummary}
                        >
                            <Typography>
                                Review and understand Delegation requirements
                                <span className={classes.astrik}>*</span>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                            <Typography>
                                <FormikControl
                                    control="checkbox"
                                    label="Mark this step complete"
                                    name="is_step_one_completed"
                                    required
                                    className={classes.modelCheckboxLabel}
                                // disabled={isDisabled}
                                />
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: '5px' }}>
                    <Accordion expanded={formikCtx.values.is_step_one_completed ? expanded === 'panel2' : false} onChange={handleChange(formikCtx.values.is_step_one_completed ? 'panel2' : '')} className={classes.gridTitle}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                            className={classes.accordionSummary}
                        >
                            <Typography>
                                Role group creation
                                <span className={classes.astrik}>*</span>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                            <Typography>
                                Login to Adaxes:&nbsp;<a href="https://id.insidemedia.net/Adaxes#/SignIn" target="_blank">
                                    https://id.insidemedia.net/Adaxes#/SignIn
                                    <span className={classes.astrik}>*</span>
                                </a>
                                <br />
                                <span className={classes.shade}>
                                    Select <b> GroupM AD Admins</b> from the dropdown list and use your
                                    administrative credentials to login
                                </span>
                                <p>
                                    Scroll down to <b>Group Creation</b> and select <b>Create a Delegation Role Group</b>
                                    <span className={classes.astrik}>*</span>
                                </p>
                                <p>
                                    Choose <b>SA</b> as the location, click <b>Next</b>
                                    <span className={classes.astrik}>*</span>
                                </p>
                                <p>
                                    Complete <b>General</b> section as per Adaxes form
                                    <span className={classes.astrik}>*</span>
                                </p>
                                <p className={classes.shade}>
                                    <b>Group Name:</b> Copy and paste the Proposed Role Group name below into Adaxes <b>Group Name</b>
                                    <br />
                                    <b>Group Type:</b> This should remain with <b>Global</b> and <b>Security</b> checked <br />
                                    <b>Description</b>: This should describe what the role group is for{' '}
                                    <br />
                                    e.g. Service Account Role Group for &lt;service account name&gt;.{' '}
                                    <br />
                                    Service Account Name can be found on the <b>Service Account Details</b> section of the Delegation form, underneath the  <b>Domain</b> section. <br />
                                    Note, a Service Account name always starts with _ e.g. _GLOGRM.Adaxes
                                </p>
                                <p>
                                    Proposed <b>Role Group</b> name
                                    <span className={classes.astrik}>*</span>
                                </p>
                                <p className={classes.shade}>
                                    This is the proposed name for the Role Group. This should be validated
                                    to ensure it does not already exist. If it does not, check the box
                                    below to continue.
                                    <p>
                                        If the <b>Role Group</b> name does already exist, the{' '}
                                        <b>Proposed Role Group name</b> should be manually edited and this
                                        will then be saved when the box below is checked.
                                    </p>
                                </p>
                                <p></p>
                                <Grid item sm={5}>
                                    <FormikControl
                                        control="textfield"
                                        label="Proposed role group name"
                                        name="proposed_role_group_name"
                                        required
                                    />
                                </Grid>
                                <FormikControl
                                    control="checkbox"
                                    label="Mark this step complete"
                                    name="is_step_two_completed"
                                    required
                                    className={classes.modelCheckboxLabel}
                                />
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: '5px' }}>
                    <Accordion expanded={formikCtx.values.is_step_two_completed ? expanded === 'panel3' : false} onChange={handleChange(formikCtx.values.is_step_two_completed ? 'panel3' : '')} className={classes.gridTitle}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3a-content"
                            id="panel3a-header"
                            className={classes.accordionSummary}
                        >
                            <Typography>
                                Delegation role information
                                <span className={classes.astrik}>*</span>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                            <Typography>
                                <p>
                                    Complete <b>Delegation Role Information </b>section, click
                                    <b> Next</b>
                                    <span className={classes.astrik}>*</span>
                                    <br />
                                    <p className={classes.shade}>
                                        <b>Delegation ID</b>: Use the Delegation ID of this Delegation{' '}
                                        <br />
                                        <b>Delegation Role Name</b>: This should be completed with the{' '}
                                        <b>Proposed Delegation Role</b> name as below. <br />
                                        <b>Delegation Role – Justification</b>: Use the justification
                                        information from <b>Account Type</b> section of the Delegation form{' '}
                                        <br />
                                        <b>Delegation Role – Slots</b>: Not applicable for Service Accounts,
                                        leave blank <br />
                                        <b>Privilege Access Group Type</b>: Select{' '}
                                        <b>PAG – SA Delegation Role Group</b> <br />
                                        <b>Delegation Role – Type</b>: Choose the correct option based on
                                        the requirements in the Delegation <br />
                                        <b>Delegation Role – Scope</b>: Choose the correct option <br />
                                        <b>Delegation Role / Target Group Link </b>: This is only applicable
                                        if the Service Account requires permissions to access server/s. If
                                        no server access is required, leave blank. If server access is
                                        required, a Target Group will be required and this section should be
                                        completed with the Proposed Target Group name as below. <br />
                                        <b>Delegation Role – GPO Name</b>: This is only applicable if the
                                        Service Account requires permissions to access server/s. If no
                                        server access is required, leave blank. If server access is
                                        required, a GPO will be required and this section should be
                                        completed with the Proposed GPO name as below. <br />
                                        <b>ServiceNow Ticket Number</b>: Enter ticket number if there is one
                                    </p>
                                    <p>
                                        Proposed <b>Delegation Role</b> name <span className={classes.astrik}>*</span>
                                    </p>
                                    <p className={classes.shade}>
                                        This is the proposed name for the <b>Delegation Role</b>. This
                                        should be validated to ensure it does not already exist. If it does
                                        not, check the box below to continue.
                                    </p>
                                    <p className={classes.shade}>
                                        If the <b>Delegation Role name</b> does already exist, the <b>Proposed Delegation Role name</b> should be manually edited and
                                        this will then be saved when the box below is checked.
                                    </p>
                                </p>
                                <Grid item sm={6}>
                                    <FormikControl
                                        control="textfield"
                                        label="Proposed role group name"
                                        name="proposed_delegation_role_group_name"
                                        // disabled
                                        required
                                    />
                                </Grid>
                                {formikCtx.values.proposed_delegation_role_group_name &&
                                    <>
                                        <p>
                                            Proposed <b>Target Group</b> name
                                            <span className={classes.astrik}>*</span> <br />
                                            <p className={classes.shade}>
                                                This is the proposed name for the <b>Target Group</b>. This should
                                                be validated to ensure it does not already exist. If it does not,
                                                check the box below to continue.
                                            </p>
                                            <p className={classes.shade}>
                                                If the <b>Target Group name</b> does already exist, the{' '}
                                                <b>Proposed Target Group name</b> should be manually edited and this
                                                will then be saved when the box below is checked.
                                            </p>
                                        </p>
                                        <Grid item sm={6}>
                                            <FormikControl
                                                control="textfield"
                                                label="Proposed target group name"
                                                name="proposed_target_group_name"
                                                // disabled
                                                required
                                            />
                                        </Grid>
                                    </>
                                }
                                {formikCtx.values.proposed_target_group_name &&
                                    <>
                                        <p>
                                            Proposed <b>GPO</b> name
                                            <span className={classes.astrik}>*</span>
                                            <p className={classes.shade}>
                                                This is the proposed name for the <b>GPO</b> (Group Policy Object).
                                                This should be validated to ensure it does not already exist. If it
                                                does not, check the box below to continue.
                                            </p>
                                            <p className={classes.shade}>
                                                If the <b>GPO</b> name does already exist, the{' '}
                                                <b>Proposed GPO name</b> should be manually edited and this will
                                                then be saved when the box below is checked.
                                            </p>
                                        </p>
                                        <Grid item sm={6}>
                                            <FormikControl
                                                control="textfield"
                                                label="Proposed GPO name"
                                                name="proposed_gpo_name"
                                                required
                                            />
                                        </Grid>
                                        <FormikControl
                                            control="checkbox"
                                            label="Check if completed"
                                            name="is_delegation_gpo_target_completed"
                                            required
                                            className={classes.modelCheckboxLabel}
                                        />
                                    </>
                                }
                                {formikCtx.values.is_delegation_gpo_target_completed &&
                                    <>
                                        <p>
                                            Complete <b>Delegation Team Information Details</b> section
                                            <span className={classes.astrik}>*</span>
                                            <p className={classes.shade}>
                                                <b>Company</b>: Select the appropriate company value from the
                                                drop-down list{' '}
                                                <b>
                                                    {' '}
                                                    <br />
                                                    Organisation
                                                </b>
                                                : Use the <b>Company / 3rd Party Company</b> value from the <b>Requestor & Team Details</b> section of the
                                                Delegation <br />
                                                <b>Team</b>: This can be found in the{' '}
                                                <b>Requestor & Team Details</b> section of the Delegation
                                            </p>
                                            <FormikControl
                                                control="checkbox"
                                                label="Check if completed"
                                                name="is_delegation_info_completed"
                                                required
                                                className={classes.modelCheckboxLabel}
                                            />
                                        </p>
                                    </>}
                                {formikCtx.values.is_delegation_info_completed &&
                                    <>
                                        <p>
                                            On the <b>Just in Time Group Details</b> section, click <b>Next</b>
                                            <span className={classes.astrik}>*</span> <br />
                                            <span className={classes.shade}>
                                                This section does not need to be completed
                                            </span>
                                        </p>

                                        <p>
                                            Create Service Account Role Group – JIT Empty
                                            <span className={classes.astrik}>*</span>
                                        </p>
                                        <p>
                                            Complete <b>Managed By</b> section, click <b>Next</b>
                                            <span className={classes.astrik}>*</span>
                                            <br />
                                            <p className={classes.shade}>
                                                Use the <b>Requestor details</b> to populate the <b>Primary</b>{' '}
                                                field - this can be found on the <b>Requestor & Team Details</b>{' '}
                                                section of the Delegation <br />
                                                The <b>Co-Managed By</b> field does not need to be completed <br />
                                                <b>Do not</b> select <b>Can update membership using native tools</b>
                                            </p>
                                        </p>
                                        <FormikControl
                                            control="checkbox"
                                            label="Check if completed"
                                            name="complete_manage_by_section"
                                            required
                                            className={classes.modelCheckboxLabel}
                                        // disabled={isDisabled}
                                        />
                                    </>}
                                {formikCtx.values.complete_manage_by_section &&
                                    <>
                                        <p>
                                            Complete <b>Other</b> section, click <b>Finish</b>
                                            <span className={classes.astrik}>*</span> <br />
                                            <p className={classes.shade}>
                                                <b>GRM Group Type</b>: Choose <b>Group - SA Server Role Group</b>{' '}
                                                <br />
                                                <b>Company</b>: Choose the company from the drop-down list
                                                <br />
                                                <b>Group - GroupM Company</b>: Choose the <b>GroupM Company</b> it
                                                is from in the drop-down list <br />
                                                <b>Notes</b>: This will be auto-populate with information you
                                                provided on the form above <br />
                                                <b>Protect from accidental deletion</b>: select this if this service
                                                account will be business critical
                                            </p>
                                        </p>
                                        <FormikControl
                                            control="checkbox"
                                            label="Check if completed"
                                            name="complete_other_section"
                                            required
                                            className={classes.modelCheckboxLabel}
                                        />
                                    </>}
                                <FormikControl
                                    control="checkbox"
                                    label="Mark this step completed"
                                    name="is_step_three_completed"
                                    required
                                    className={classes.modelCheckboxLabel}
                                />
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: '5px' }}>
                    <Accordion expanded={formikCtx.values.is_step_three_completed ? expanded === 'panel4' : false} onChange={handleChange(formikCtx.values.is_step_three_completed ? 'panel4' : '')} className={classes.gridTitle}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4a-content"
                            id="panel4a-header"
                            className={classes.accordionSummary}
                        >
                            <Typography>
                                Service account access requirements
                                <span className={classes.astrik}>*</span>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                            <Typography>
                                <p>
                                    Does the Service Account require privileges on any devices, i.e.
                                    servers or computers?
                                    <span className={classes.astrik}>*</span>
                                </p>
                                {/* <p> */}
                                <p className={classes.shade}>
                                    A Service Account requiring access to any server/s or computer/s
                                    will require both a Role Group and a Group Policy Object (GPO).
                                </p>
                                <p className={classes.shade}>
                                    Some requests will also require a Target Group. This is only needed
                                    when there is a specific set of individual computers and/or servers
                                    that the Service Account needs to access.
                                </p>
                                <p className={classes.shade}>
                                    If the Service Account will require access to all the servers /
                                    computers in a City, Market, Region or Global geographic area, then
                                    a Group Policy Object (GPO) can be configured for this and no Target
                                    Group will be required.
                                </p>

                                <FormikControl
                                    control="radio"
                                    name="is_service_account_require_access"
                                    radioGrouplabels={[
                                        {
                                            label: 'Yes',
                                            value: "Yes",
                                        },
                                        {
                                            label: 'No',
                                            value: "No",
                                        },
                                    ]}
                                    isInline
                                />
                                {formikCtx.values.is_service_account_require_access !== "No" &&
                                    <>
                                        <p>
                                            Review Delegation form to determine scope of access and select from
                                            the below options:<span className={classes.astrik}>*</span>
                                        </p>

                                        <FormikControl
                                            control="radio"
                                            name="review_delegation_form_to_determine_scope"
                                            radioGrouplabels={[
                                                {
                                                    label: 'Individual device or devices (server/s and / or computer/s)',
                                                    value: 'Individual device or devices (server/s and / or computer/s)',
                                                },
                                                {
                                                    label: 'All devices in a city (server and / or computer)',
                                                    value: 'All devices in a city (server and / or computer)',
                                                },
                                                {
                                                    label: 'All devices in a country (server and / or computer)',
                                                    value: 'All devices in a country (server and / or computer)',
                                                },
                                                {
                                                    label: 'All devices in a region (server and / or computer)',
                                                    value: 'All devices in a region (server and / or computer)',
                                                },
                                                {
                                                    label: 'All devices globally (server and / or computer)',
                                                    value: 'All devices globally (server and / or computer)',
                                                },
                                            ]}
                                        />
                                    </>}

                                <FormikControl
                                    control="checkbox"
                                    label="Mark this step completed"
                                    name="is_step_four_completed"
                                    required
                                    className={classes.modelCheckboxLabel}
                                // disabled={isDisabled}
                                />
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                {(formikCtx.values.is_service_account_require_access === 'Yes') &&
                    <>
                        {(formikCtx.values.review_delegation_form_to_determine_scope === 'Individual device or devices (server/s and / or computer/s)') &&
                            <>
                                <Grid item xs={12} style={{ marginBottom: '5px' }}>
                                    <Accordion expanded={formikCtx.values.is_step_four_completed ? expanded === 'panel5' : false} onChange={handleChange(formikCtx.values.is_step_four_completed ? 'panel5' : '')} className={classes.gridTitle}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel5a-content"
                                            id="panel5a-header"
                                            className={classes.accordionSummary}
                                        >
                                            <Typography>
                                                Target group creation
                                                <span className={classes.astrik}>*</span>
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className={classes.accordionDetails}>
                                            <Typography>
                                                <p>
                                                    On the Adaxes home page, scroll down to <b>Group Creation</b> and
                                                    select <b>Create a Target Group</b>
                                                    <span className={classes.astrik}>*</span>
                                                </p>
                                                <p>
                                                    Choose <b>SA</b> as the location, click <b>Next</b>
                                                    <span className={classes.astrik}>*</span>
                                                </p>
                                                <p>
                                                    Complete <b>General</b> section, click <b>Next</b>
                                                    <span className={classes.astrik}>*</span>
                                                    <br />
                                                    <p className={classes.shade}>
                                                        <b>Group Name: </b>This should be completed with the <b>Proposed Target Group
                                                            name</b> as below. <br />
                                                        As you type in the  <b>Target Group Name</b>, the  <b>Group Name (pre-Windows
                                                            2000)</b> field will duplicate what you type <br />
                                                        <b>Group Type:</b> This should remain<b> Global</b> and <b>Security</b> <br />
                                                        <b>Description:</b> This should describe what the group is for <br />
                                                        e.g. Service Account Target Group for _GLOGRM.AdaxesDev <br />
                                                        <b>Email:</b> Use the <b>Requestor </b>email - this can be found on the <b>Requestor
                                                            & Team Details </b>section of the Delegation{' '}
                                                    </p>
                                                </p>
                                                <Grid item sm={5}>
                                                    <FormikControl
                                                        control="textfield"
                                                        label="Target group name"
                                                        name="proposed_gpo_name"
                                                        disabled={true}
                                                        required
                                                    />
                                                </Grid>
                                                <FormikControl
                                                    control="checkbox"
                                                    label="Check if completed"
                                                    name="is_target_group_name_completed"
                                                    required
                                                    className={classes.modelCheckboxLabel}

                                                />
                                                {formikCtx.values.is_target_group_name_completed &&
                                                    <>
                                                        <p>
                                                            Complete <b>Target Group Information</b> section
                                                            <span className={classes.astrik}>*</span> <br />
                                                            <p className={classes.shade}>
                                                                <b>Delegation ID</b>: Use the <b>Delegation ID</b> of this
                                                                Delegation <br />
                                                                <b>Privileged Access Group Type</b>: Choose{' '}
                                                                <b>PAG – SA Target Group</b>
                                                                <br />
                                                                <b>Target Group Description</b>: This should describe what the
                                                                groups is for <br />
                                                                e.g. Service Account Target Group for _GLOGRM.AdaxesDev <br />
                                                                <b>Delegation Role/Target Group Link</b>: This should be completed
                                                                with the <b>Delegation Role</b> name as below <br />
                                                                <b>Delegation Role – GPO Name</b>: This should be completed with the{' '}
                                                                <b>GPO name</b> as below <br />
                                                                <b>ServiceNow Ticket Number</b>: Enter ticket number if there is one{' '}
                                                            </p>
                                                        </p>
                                                        <Grid item sm={5}>
                                                            <FormikControl
                                                                control="textfield"
                                                                label="Delegation role name"
                                                                name="proposed_delegation_role_group_name"
                                                                disabled={true}
                                                                required
                                                            />
                                                        </Grid>
                                                        <br />
                                                        <Grid item sm={5}>
                                                            <FormikControl
                                                                control="textfield"
                                                                label="GPO name"
                                                                name="proposed_gpo_name"
                                                                disabled={true}
                                                                required
                                                            />
                                                        </Grid>
                                                        <p>
                                                            Complete <b>Delegation Team Information Details</b> section
                                                            <span className={classes.astrik}>*</span>
                                                        </p>
                                                        <p className={classes.shade}>
                                                            <b>Company</b>: Select the appropriate company value from the
                                                            drop-down list <br />
                                                            <b>Organisation</b>: Use the <b>Company / 3rd Party Company</b> value from the <b>Requestor & Team Details</b> section of the Delegation{' '}
                                                            <br />
                                                            <b>Team</b>: This can be found in the <b>Requestor & Team Details</b>{' '}
                                                            section of the Delegation
                                                        </p>
                                                        <FormikControl
                                                            control="checkbox"
                                                            label="Check if completed"
                                                            name="is_target_group_delegation_info_completed"
                                                            required
                                                            className={classes.modelCheckboxLabel}
                                                        />
                                                    </>}
                                                {formikCtx.values.is_target_group_delegation_info_completed &&
                                                    <>
                                                        <p>
                                                            On the <b>Just in Time Group Details</b> section, leave <b>blank</b>
                                                            <span className={classes.astrik}>*</span>
                                                            <br />
                                                            <span className={classes.shade}>
                                                                This section does not need to be completed
                                                            </span>
                                                        </p>
                                                        <p>
                                                            Complete <b>Managed By</b> section, click <b>Next</b>
                                                            <span className={classes.astrik}>*</span> <br />
                                                            <p className={classes.shade}>
                                                                Use the <b>Requestor details</b> to populate the <b>Managed By </b>field - this
                                                                can be found on the <b>Requestor & Team Details section</b> of the
                                                                Delegation <br />
                                                                The <b>Co-Managed</b> By field does not need to be completed <br />
                                                                <b>Do not</b> select<b> Can update membership using native tools</b>
                                                            </p>
                                                        </p>

                                                        <FormikControl
                                                            control="checkbox"
                                                            label="Check if completed"
                                                            name="is_target_group_complete_manage_by_section"
                                                            required
                                                            className={classes.modelCheckboxLabel}
                                                        />
                                                    </>}
                                                {formikCtx.values.is_target_group_complete_manage_by_section &&
                                                    <>
                                                        <p>
                                                            <p>
                                                                Complete <b>Other</b> section, click <b>Finish</b>
                                                                <span className={classes.astrik}>*</span>
                                                            </p>
                                                            <p className={classes.shade}>
                                                                <b>GRM Group Type</b>: Choose <b>Group - SA Server Target Group</b>
                                                                <br />
                                                                <b>Company</b>: Choose the company from the drop-down list <br />
                                                                <b>Group- GroupMCompany</b>: Choose which GroupMCompany it is from
                                                                the drop-down list - this should be the company for which the
                                                                Service Account will be used, i.e. this will never be a third party
                                                                company and will always be an internal company name. <br />
                                                                <b>Notes</b>: This will populate with information from the form you
                                                                filled in above <br />
                                                                <b>Do not</b> select the <b>Protect from accidental deletion box</b>{' '}
                                                                - this is managed by policy
                                                            </p>
                                                        </p>
                                                        <FormikControl
                                                            control="checkbox"
                                                            label="Check if completed"
                                                            name="is_target_group_complete_other_section"
                                                            required
                                                            className={classes.modelCheckboxLabel}
                                                        />
                                                    </>}
                                                <FormikControl
                                                    control="checkbox"
                                                    label="Mark this step completed"
                                                    name="is_step_five_completed"
                                                    required
                                                    className={classes.modelCheckboxLabel}
                                                />
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid >
                                <Grid item xs={12} style={{ marginBottom: '5px' }} >
                                    <Accordion expanded={formikCtx.values.is_step_five_completed ? expanded === 'panel6' : false} onChange={handleChange(formikCtx.values.is_step_five_completed ? 'panel6' : '')} className={classes.gridTitle}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel6a-content"
                                            id="panel6a-header"
                                            className={classes.accordionSummary}
                                        >
                                            <Typography>
                                                Addition of members to target group
                                                <span className={classes.astrik}>*</span>
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className={classes.accordionDetails}>
                                            <Typography>
                                                Login to Adaxes: <a href="https://id.insidemedia.net/Adaxes#/SignIn " target="_blank">
                                                    https://id.insidemedia.net/Adaxes#/SignIn
                                                </a>
                                                <p className={classes.shade}>
                                                    Select <b> GRM Help Desk</b> from the dropdown list and use your
                                                    administrative credentials to login
                                                </p>
                                                <p>
                                                    Copy <b>Target Group Name</b> from above and paste this into the
                                                    search window in Adaxes<span className={classes.astrik}>*</span>{' '}
                                                    <br />
                                                    <span className={classes.shade}>
                                                        Note: It may take several seconds to find the Target Group. <br />
                                                        Once found, click on the name and the Target Group will load in the
                                                        window. <br />
                                                        Use the navigation menu to select <b>Members</b> and click on <b>Add</b> below the <b>Members</b> box. <br />
                                                        Enter each device name individually, from the list of devices in the
                                                        Server Information section of the Delegation form. As the name is
                                                        typed, matching devices will appear below for selection. Once
                                                        selected, the devices will appear in the top bar in grey.
                                                        <br />
                                                        When all required devices have been selected, click <b>OK</b>.<br /> Once all devices have been successfully added, click <b>Close</b> and the devices will be dispayed in the <b>Members</b> window of the Target Group.
                                                    </span>
                                                </p>
                                                <FormikControl
                                                    control="checkbox"
                                                    label="Check if completed"
                                                    name="copy_target_group_name"
                                                    required
                                                    className={classes.modelCheckboxLabel}
                                                />
                                                {formikCtx.values.copy_target_group_name &&
                                                    <>
                                                        <p>
                                                            Add <b>Members</b> to the <b>Target Group</b>, click <b>OK</b>
                                                            <span className={classes.astrik}>*</span> <br />
                                                            <span className={classes.shade}>
                                                                Once the Group is created it will take you the page for the new
                                                                Group <br /> Scroll down to <b>Members</b> and select <b>Add</b>
                                                                <br />
                                                                Enter each device name individually, from the list of devices in the
                                                                Server Information section of the Delegation form - as the name is
                                                                typed, matching devices will appear below for selection and once
                                                                selected, the devices will appear in the top bar in grey <br />
                                                                When all required devices have been selected, click <b>OK</b> <br />Once all devices have been successfully added, click <b>Close</b> and the devices will be dispayed in the <b>Members</b> window of the Target Group
                                                            </span>
                                                        </p>
                                                        <FormikControl
                                                            control="checkbox"
                                                            label="Check if completed"
                                                            name="add_members_to_target_group"
                                                            required
                                                            className={classes.modelCheckboxLabel}
                                                        />
                                                    </>}
                                                <FormikControl
                                                    control="checkbox"
                                                    label="Mark this step completed"
                                                    name="is_step_six_completed"
                                                    required
                                                    className={classes.modelCheckboxLabel}
                                                />
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </>
                        }
                        <Grid item xs={12} style={{ marginBottom: '5px' }}>
                            {/* a ? b : (c ? d : e) */}
                            <Accordion expanded={
                                formikCtx.values.is_step_six_completed ? expanded === 'panel7' : ((formikCtx.values.is_service_account_require_access === 'Yes') && !(formikCtx.values.review_delegation_form_to_determine_scope === 'Individual device or devices (server/s and / or computer/s)') && (formikCtx.values.is_step_four_completed)) ? expanded === 'panel7' : false

                            } onChange={handleChange(formikCtx.values.is_step_six_completed ? 'panel7' : ((formikCtx.values.is_service_account_require_access === 'Yes') && !(formikCtx.values.review_delegation_form_to_determine_scope === 'Individual device or devices (server/s and / or computer/s)') && formikCtx.values.is_step_four_completed) ? 'panel7' : '')} className={classes.gridTitle}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel7a-content"
                                    id="panel7a-header"
                                    className={classes.accordionSummary}
                                >
                                    <Typography>
                                        Group policy object creation
                                        <span className={classes.astrik}>*</span>
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails className={classes.accordionDetails}>
                                    <Typography>
                                        <p>
                                            Login to <b> Group Policy Management Console </b>using admin credentials and <b>Create New GPO</b>
                                            <span className={classes.astrik}>*</span>
                                            <br />
                                            <p className={classes.shade}>
                                                If logging in on a computer you will need to pin <b>GPMC</b> to your
                                                Task Bar, then right-click, and while holding down <b>Shift</b>,
                                                again right-click on <br />
                                                <b>Group Policy Management</b> and select{' '}
                                                <b>Run as different user</b> Navigate through the <b>Forest</b> and <b>Domains</b> and in the
                                                domain required find <b>Group Policy Objects</b>, right click and
                                                select <b>New</b> Enter the name of the GPO chosen earlier,
                                                displayed below, click <b>OK</b>
                                            </p>
                                        </p>
                                        <Grid item sm={5}>
                                            <FormikControl
                                                control="textfield"
                                                label="GPO name"
                                                name="proposed_gpo_name"
                                                disabled={true}
                                                required
                                            />
                                        </Grid>
                                        <FormikControl
                                            control="checkbox"
                                            label="Check if completed"
                                            name="is_group_policy_gpo_name_completed"
                                            required
                                            className={classes.modelCheckboxLabel}
                                        />
                                        {formikCtx.values.is_group_policy_gpo_name_completed &&
                                            <>
                                                <p>
                                                    Find the created GPO in the tree, right click and select <b>Edit</b>
                                                    <span className={classes.astrik}>*</span> <br />
                                                    <p className={classes.shade}>
                                                        Navigate to <b>Policies &gt; Windows Settings &gt; Security Settings &gt;
                                                            Restricted Groups
                                                        </b>
                                                        <br />
                                                        Right-click and select <b>Add Group…</b> <br />
                                                        Select <b>Browse</b> and enter the name of the{' '}
                                                        <b>Service Account Role Group</b> chosen earlier, below, click{' '}
                                                        <b>OK</b>
                                                        <br />
                                                        In the <b>Properties</b> window, go to <b>Add</b>, next to{' '}
                                                        <b>This group is a member of</b>
                                                        <br />
                                                        In the <b>Group Membership</b> window, enter{' '}
                                                        <b>BUILTIN\Administrators</b>, click <b>OK</b>
                                                        <br />
                                                        This will then show in the <b>Properties</b> window, click <b>OK</b>
                                                    </p>
                                                </p>
                                                <Grid item sm={5}>
                                                    <FormikControl
                                                        control="textfield"
                                                        label="Role group name"
                                                        name="proposed_role_group_name"
                                                        disabled={true}
                                                        required
                                                    />
                                                </Grid>
                                                <FormikControl
                                                    control="checkbox"
                                                    label="Check if completed"
                                                    name="is_group_policy_role_group_completed"
                                                    required
                                                    className={classes.modelCheckboxLabel}
                                                />
                                            </>}
                                        {formikCtx.values.is_group_policy_role_group_completed &&
                                            <>
                                                <p>
                                                    Select the <b>Delegation</b> tab, click on <b>Advanced</b>
                                                    <span className={classes.astrik}>*</span>
                                                </p>
                                                <p className={classes.shade}>
                                                    Select <b>Authenticated Users</b> in <b>Group or user names</b> window{' '}
                                                    <br />
                                                    De-select <b>Apply Group Policy</b> in{' '}
                                                    <b>Permissions for Authenticated Users</b> window <br />
                                                    Click <b>OK</b> <br />
                                                    <b>Authenticated Users</b> will now be displayed with <b>Read</b>{' '}
                                                    permissions <br />
                                                    Note: DO NOT remove the Authenticated Users ACL from the GPO, this
                                                    causes read issues
                                                </p>
                                                <FormikControl
                                                    control="checkbox"
                                                    label="Check if completed"
                                                    name="is_select_the_delegation"
                                                    required
                                                    className={classes.modelCheckboxLabel}
                                                />
                                            </>}
                                        {formikCtx.values.is_select_the_delegation &&
                                            <>
                                                <p>
                                                    Select the <b>Scope</b> tab, click <b>Add</b>
                                                    <span className={classes.astrik}>*</span>
                                                </p>
                                                <p className={classes.shade}>
                                                    Enter the <b>Target Group</b> name as created previously and click on <b>Check Names</b> to the right <br />
                                                    Once checked the name will then be underlined, identifying an existing
                                                    object <br />
                                                    Click <b>OK</b> and the <b>Target Group</b> will now appear in the <b>Security Filtering</b>{' '}
                                                    window
                                                </p>
                                                <Grid item sm={5}>
                                                    <FormikControl
                                                        control="textfield"
                                                        label="Target group name"
                                                        name="proposed_target_group_name"
                                                        disabled={true}
                                                        required
                                                    />
                                                </Grid>
                                            </>}
                                        <br />
                                        <FormikControl
                                            control="checkbox"
                                            label="Mark this step complete"
                                            name="is_step_seven_completed"
                                            required
                                            className={classes.modelCheckboxLabel}
                                        />
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: '5px' }}>
                            <Accordion expanded={formikCtx.values.is_step_seven_completed ? expanded === 'panel8' : false} onChange={handleChange(formikCtx.values.is_step_seven_completed ? 'panel8' : '')} className={classes.gridTitle}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel8a-content"
                                    id="panel8a-header"
                                    className={classes.accordionSummary}
                                >
                                    <Typography>
                                        Assignment of GPO to devices
                                        <span className={classes.astrik}>*</span>
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails className={classes.accordionDetails}>
                                    <Typography>
                                        <p>
                                            Open <b>Active Directory Users and Computers</b>, and click on{' '}
                                            <b>Search</b> icon<span className={classes.astrik}>*</span>
                                        </p>
                                        <p className={classes.shade}>
                                            Select Computers in the Find drop-down and select the appropriate
                                            domain <br />
                                            In the <b>Computer name</b> field, enter each device name
                                            individually, from the list of devices in the{' '}
                                            <b>Server Information</b> section of the Delegation form <br />
                                            Use the <b>View</b> menu to <b>Choose Columns</b> and select <b>Published At</b> - this will
                                            enable visibility of the OU in which the devices exist
                                        </p>
                                        <p>
                                            Return to <b>Group Policy Management Console</b>
                                            <span className={classes.astrik}>*</span>
                                        </p>
                                        <p className={classes.shade}>
                                            Search for the OU of the devices in the Active Directory tree in GPMC{' '}
                                            <br />
                                            Right-click the OU and select <b>Link an Existing GPO…</b> <br />
                                            Select the GPO from the list, as below <br />
                                            Click <b>OK</b> <br />
                                            If the devices exist in more than one OU, repeat the above activities
                                            until all the OUs have been linked <br />
                                            Check that all the OUs selected are visible in the Scope tab of the
                                            GPO <br />
                                            Click <b>OK</b>
                                        </p>
                                        <Grid item sm={5}>
                                            <FormikControl
                                                control="textfield"
                                                label="GPO name"
                                                name="proposed_gpo_name"
                                                disabled={true}
                                                required
                                            />
                                        </Grid>
                                        <FormikControl
                                            control="checkbox"
                                            label="Check if completed"
                                            name="is_assignment_to_gpo_completed"
                                            required
                                            className={classes.modelCheckboxLabel}
                                        />
                                        <FormikControl
                                            control="checkbox"
                                            label="Mark this step complete"
                                            name="is_step_eight_completed"
                                            required
                                            className={classes.modelCheckboxLabel}
                                        />
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </>
                }
            </Grid >
        </>
    );
};

export default ImplementationSar;
