import { createSlice, current } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


interface ICloudImplementation {
    is_implemented: boolean,
    is_step_complete: boolean,
    on_premise_id: number,
    provide_service_numbe: string
}

export const cloudImplementationOnprem = createSlice({
    name: 'cloudOnpremTasklist',
    initialState: [{}],
    reducers: {
        cloudImplementationOnpremTasklist: (state, action: PayloadAction<ICloudImplementation>) => {
            console.log('payload', action.payload)
            return [{ ...action.payload }]
        },
    }
});

export const { cloudImplementationOnpremTasklist } = cloudImplementationOnprem.actions;
export default cloudImplementationOnprem.reducer;