import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  title: {fontFamily: 'Poppins-Regular', fontSize: '13px', color: '#949494'},
  subtitle: { cursor: 'pointer', fontFamily: 'Poppins-Regular', fontSize: '13px', color: '#0080FF' },
  breadcrumb: {
    '& .MuiBreadcrumbs-separator': { margin: 0 },
      '& .MuiBreadcrumbs-ol': {
        alignItems: 'end',
        paddingBottom: '5px',
      }
  },
});
export default useStyles;
