import * as Yup from "yup";
import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Formik,
  Form,
  ArrayHelpers,
  FieldArray,
  FormikProps,
  FormikValues,
  useFormikContext,
  useFormik,
  FormikProvider,
} from "formik";
import { Avatar, Box, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { tableHeadStyle, tableRowStyle } from '../../../components/review-actions/useStyles';
import FormikControl from "../../../components/formik-conrtols/FormikControl";
import CustomButton from "../../../components/button";
import approverManagementStyles from "./implementer-management-styles";
import {
  fetchAllStages,
  fetchStageWiseApprover,
  fetchAllApprover,
  postApproverData,
} from "../../../api-services/approver-management";
import EditApproverInfo from "./EditimplementerInfo";
import { useSnackbar } from "notistack";
import { IImplementerData, IImplementerTypeData } from "./implementerManagementUtils";
import { fetchAllImplementer, fetchAllImplementerTypes, fetchTypeWiseImplementer, postImplementerData } from "../../../api-services/implementer-management";

function useApproverManagementHook() {
  const styleClasses = approverManagementStyles();
  const { enqueueSnackbar } = useSnackbar();
  const tableHeadClasses = tableHeadStyle();
  const tableRowClasses = tableRowStyle()
  const formRef = useRef<FormikProps<FormikValues>>(null);
  const isAutoCompleteSelected = useRef(false);
  const [allImplementerTypes, setAllImplementerTypes] = useState([]);
  const [allImplementerData, setAllImplementerData] = useState<{ id: number; name: string; email: string }[]
  >([]);

  useEffect(() => {
    fetchAllStagesOnLoad()
  }, []);

  const [expandedPanel, setExpandedPanel] = useState("");
  const handleAccordionChange = (panel: any) => {
    if (expandedPanel !== "panel" + panel) {
      setExpandedPanel("panel" + panel);
    } else {
      setExpandedPanel("");
    }
  };

  const initialValuesModel = {
    stage_name: "",
    primary_implementer: "",
    primary_implementer_email: "",
    backup_implementer: "",
    backup_implementer_email: "",
  };
  const validationSchemaModel = Yup.object({
    stage_name: Yup.string().required("Required"),
    primary_implementer: Yup.string().required("Required"),
    backup_implementer: Yup.string(),
    backup_implementer_email: Yup.string().required("Required"),
  });

  const [postformValues, setPostFormValues] = useState<IImplementerTypeData>({
    stage_name: '',
    primary_implementer: '',
    backup_implementer: '',
    backup_implementer_email: '',
  });
  const [modelFormValues, setModelFormValues] = useState(initialValuesModel);
  const [titleForPopup, setTitleForPopup] = useState<{ data: any }>({ data: null });
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<{
    data: any;
    type: string;
  }>({ data: null, type: "" });
  const [dependencyData, setDependencyData] = useState([{ secondary_implementer: '', secondary_approver2: '' }])
  const [updateReason, setUpdateReason] = useState("");

  useEffect(() => {
    setModalOpen(modalOpen);
  }, [modalOpen])

  const handleModalClose = () => {
    setModalOpen(false);
    setUpdateReason('')
  };

  const fetchAllStagesOnLoad = () => {
    fetchAllImplementerTypes()
      .then((response: any) => {
        setAllImplementerTypes(response.data);
      })
      .catch((error) => console.log(error));
    fetchAllImplementer()
      .then((response: any) => {
        response.data.push({ id: 0, name: "None", email: "NA" });
        setAllImplementerData(response.data);
      })
      .catch((error) => console.log(error));
  }

  const OnClickStageName = React.useCallback(() => {
    const initialValues = {
      primary_implementer: "",
      primary_implementer_email: "",
      backup_implementer: "",
      backup_implementer_email: "",
      backup_approver_2: "",
      backup_approver_2_email: "",
    };
    const [formValues, setFormValues] = useState(initialValues);
    let id = expandedPanel.replace("panel", "");
    useEffect(() => {
      fetchTypeWiseImplementer({
        type_id: id,
      })
        .then((res) => {
          let obj: any = {
            primary_implementer: res.data.primary.name,
            primary_implementer_email: res.data.primary.email,
            backup_implementer: res.data.secondary.name == undefined ? 'None' : res.data.secondary.name,
            backup_implementer_email: res.data.secondary.email == undefined ? 'NA' : res.data.secondary.email,
          };
          setFormValues({ ...obj });
        })
        .catch((err) => {
          console.log(err);
        });
    }, []);

    return (
      <Formik
        initialValues={formValues}
        onSubmit={() => { console.log("submit!"); }}
        enableReinitialize={true}
      >
        <Form >
          <Grid container xs={7} spacing={2}>
            <Grid item xs={5}>
              <FormikControl
                control="textfield"
                label="Primary implementer"
                name="primary_implementer"
                disabled={true}
              />
            </Grid>
            <Grid item xs={5}>
              <FormikControl
                control="textfield"
                label="Email address"
                name="primary_implementer_email"
                disabled={true}
              />
            </Grid>
            <Grid item xs={5}>
              <FormikControl
                control="textfield"
                label="Backup implementer"
                name="backup_implementer"
                disabled={true}
              />
            </Grid>
            <Grid item xs={5}>
              <FormikControl
                control="textfield"
                label="Email address"
                name="backup_implementer_email"
                disabled={true}
              />
            </Grid>
          </Grid>
        </Form>
      </Formik>
    );
  }, [expandedPanel]);

  const handleEditModalOpen = (item: any, Title: string) => {
    setModalOpen(false);
    handleModalOpen("EditApprovar", item, Title)
  }

  const handleModalOpen = (actionType: string, item: any, Title: any) => {
    if (actionType == 'EditApprovar') {
      setTitleForPopup({ data: item })
      fetchTypeWiseImplementer({
        type_id: item.id,
      })
        .then((res) => {
          let obj: any = {
            stage_name: item.value,
            primary_implementer: res.data.primary.name,
            primary_implementer_email: res.data.primary.email,
            backup_implementer: res.data.secondary.name == undefined ? 'None' : res.data.secondary.name,
            backup_implementer_email: res.data.secondary.email == undefined ? 'NA' : res.data.secondary.email,
          };
          setModelFormValues({ ...obj });
          setModalData({ data: obj, type: actionType });
          setModalOpen(true);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setModalData({ data: item, type: actionType });
      setModalOpen(true);
    }

  };

  const modalHeaderHandler = (type: string) => {
    switch (type) {
      case 'EditApprovar':
        return `Implementer Type - ${titleForPopup.data.value}`;
      case 'Role_assignment':
        return 'Role assignment';
      default: return '';
    }
  };

  const handleSave = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const idValue = (label: string) => {
    const result = allImplementerData.find((item: any) => item.name === label);
    return result?.id;
  }

  const onFormSubmit = (values: any, submitProps: any) => {
    setPostFormValues(values)
    setModalOpen(false);
    const postBody = {
      primary_implementer: idValue(values.primary_implementer),
      secondary_implementer: idValue(values.backup_implementer) == 0 ? null : idValue(values.backup_implementer),
      implementer_type: titleForPopup.data.id,
    };

    postImplementerData(postBody).then((res) => {
      //console.log("postBody@#$", res)
      if (res.data && res.data.length > 0) {
        handleModalOpen("Role_assignment", res.data, null);
        let updateddependecy = Object.assign({}, ...res.data);
        setDependencyData([updateddependecy]);
        if (res.data.length == 1 && res.data[0].secondary_implementer !== 0) {
          setUpdateReason(`You are trying to remove  ${modelFormValues.backup_implementer} from their role. Currently ${modelFormValues.backup_implementer.split(" ")[0]} is an action owner for the following ${Number(res.data[0].secondary_implementer) == 1 ? 'request' : 'requests'}:`)
        }
        else {
          enqueueSnackbar(`${titleForPopup.data.value} has been updated successfully`, { variant: 'success' });
          setModalOpen(false);
        }
        fetchAllStagesOnLoad();
      } else {
        enqueueSnackbar(`${titleForPopup.data.value} has been updated successfully`, { variant: 'success' });
        fetchAllStagesOnLoad();
        setModalOpen(false);
      }
      setExpandedPanel('')
    }).catch((error) => {
      console.log("error", error)
    })
    setModalOpen(false);
  };

  const modalContentHandler = (modalData: any, type: any) => {
    //console.log("123@@@", modelFormValues)
    switch (type) {
      case "EditApprovar":
        return (
          <Formik
            initialValues={modelFormValues}
            validationSchema={validationSchemaModel}
            onSubmit={onFormSubmit}
            enableReinitialize={true}
            innerRef={formRef}
          >
            <Form>
              <EditApproverInfo data={modelFormValues} implementerlist={allImplementerData} />
            </Form>
          </Formik>
        )
      case "Role_assignment":
        return (
          <div>
            <div className={styleClasses.modalContentDiv}>
              {updateReason}
            </div>
            <Table className={styleClasses.TableForDependency}>
              <TableHead classes={{ root: tableHeadClasses.head }}>
                <TableRow sx={{ '&:first-of-type(th)': { border: 0 } }}>
                  <TableCell classes={{ root: tableHeadClasses.cell }}>Role </TableCell>
                  <TableCell classes={{ root: tableHeadClasses.cell }}>Delegation / PAR request count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className={tableRowClasses.cell}>Implementer </TableCell>
                  <TableCell className={tableRowClasses.cell}>{Number(dependencyData[0]?.secondary_approver2 == undefined ? 0 : dependencyData[0]?.secondary_approver2) + Number(dependencyData[0]?.secondary_implementer == undefined ? 0 : dependencyData[0].secondary_implementer)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <div className={styleClasses.modalTextDiv}>
              Before {modelFormValues.backup_implementer.split(" ")[0]} can be removed from the Backup implementer, {Number(dependencyData[0]?.secondary_implementer) == 1 ? 'this request' : 'these requests'} will need to be assigned to an another individual.
              <br /> <br />
              • Go to the <b>Administrator Summary</b> page.<br />
              • Search for the user in the <b>Action Owner</b> column and reassign. <br /> <br />
              Once complete, you will be able to remove {modelFormValues.backup_implementer.split(" ")[0]} from their role.
            </div>
          </div>
        );
      default:
        return null;
    };
  }


  const modalActionHandler = (modalData: any, type: any) => {
    switch (type) {
      case "EditApprovar":
        return (
          <div className={styleClasses.modalActionDiv}>
            <CustomButton
              variant="contained"
              actionType="secondary"
              onClick={handleModalClose}
            >
              Cancel
            </CustomButton>
            <CustomButton
              variant="contained"
              actionType="primary"
              disableMargin
              onClick={handleSave}
            >
              Save
            </CustomButton>
          </div>
        )
      case "Role_assignment":
        return (
          <div className={styleClasses.modalActionDiv}>
            <Box className={styleClasses.modalActionDivNew}>
              <CustomButton
                variant="contained"
                actionType="primary"
                disableMargin
                onClick={handleModalClose}
              >
                Ok
              </CustomButton>
            </Box>
          </div>
        );
      default:
        return null;
    };
  };


  return {
    OnClickStageName,
    handleEditModalOpen,
    modalOpen,
    modalHeaderHandler,
    modalContentHandler,
    handleModalClose,
    modalActionHandler,
    modalData,
    allImplementerTypes,
    expandedPanel,
    handleAccordionChange,
  };
}
export default useApproverManagementHook;
