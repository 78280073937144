import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import FormikControl from '../../../formik-conrtols/FormikControl';
import FieldLabel from '../../../field-label';
import CustomButton from '../../../button';
import FileUploader from '../../../file-uploader/fileUploader';
import AddIcon from '@mui/icons-material/Add';
import { parStyles, sarUseStyles } from '../../useStyles';
import { accountCompromised, businessImpact, defaultPasswordExpiryPolicy, expiryPolicy, passChangeProcedures, passwordChanaged, riskExposureTooltip, securityOfPassword } from '../tooltips';
import { RootState } from '../../../../appStore/store';
import HistoryTable from '../../../../modules/sar/comments/historyTable';
import CommentSubmission from '../../../../modules/sar/comments/commentSubmission';
import { format, isDate } from 'date-fns';
import { routeCheck, routeCheckForAdminSummary, routeCheckForApprovalSummary, routeCheckForImplementationSummary, routeCheckForPendingApproval } from './routingConditional';



function PasswordSecurityAndPolicy({ isDisabled }: { isDisabled: boolean }) {
    const classes = sarUseStyles();
    const styleClasses = parStyles();
    const formikCtx: any = useFormikContext();
    const remediated_by = formikCtx.values.remediation_by
    const remediationCount = formikCtx.values.remediation_count;
    const { fieldHistorySarCommentsData } = useSelector((state: RootState) => state);
    const { submitSarCommentData } = useSelector((state: RootState) => state);
    const formatDateInside = (dateValue: any) => (dateValue && isDate(new Date(dateValue)) ? format(new Date(dateValue), 'MMM dd, yyyy') : '');

    const checkForEditReviewFlow = () => {
        return routeCheck() || routeCheckForAdminSummary() || routeCheckForImplementationSummary() || routeCheckForApprovalSummary()
    }

    const [passwordWillChanged] = useState([{ label: '60 days (default policy)', value: '60days(default policy)' }, { label: 'Quarterly', value: 'Quarterly', }, { label: 'Semi-annually', value: 'Semi-annually' }, { label: 'Annually', value: 'Annually' }]);
    const [riskExposure] = useState([{ label: 'High - Service account has domain admin rights or interactive logon enabled', value: 'High' }, { label: 'Medium - Service account has local admin rights OR exists on >10 devices and is a domain account', value: 'Medium' }, { label: 'Low - Service account has limited rights OR exists on <10 devices and is a domain account', value: 'Low' }, { label: 'Extremely low - Service account has limited rights and or exist on only a single device', value: 'Extremelylow' }]);


    return (
        <>
            <Grid container paddingTop={'12px'} >
                <Grid item xs={10}>
                    <div>
                        <FieldLabel Title="Password change procedures" tooltipMsg={passChangeProcedures} required />
                    </div>
                    <div>
                        <div className={styleClasses.updatedByStyle}>
                            {/* @ts-ignore */}
                            {(fieldHistorySarCommentsData[22].Password_change_procedures?.length > 0) && `Updated on ${formatDateInside(fieldHistorySarCommentsData[22].Password_change_procedures[fieldHistorySarCommentsData[22].Password_change_procedures?.length - 1].last_updated)}`}
                        </div>
                    </div>
                </Grid>

                <Grid item xs={2}>
                    {(checkForEditReviewFlow() && remediationCount > 0) ? (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="Password_change_procedures"
                                subSection=''
                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[22].Password_change_procedures : null}
                            />
                            <CommentSubmission
                                sectionName='Password_change_procedures'
                                subSection=''
                                updateType='Select update'
                                remediation_by={remediated_by}
                                data={submitSarCommentData.length > 0 ? submitSarCommentData[22].Password_change_procedures : null}
                            />
                        </div>
                    ) : routeCheckForPendingApproval() && (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="Password_change_procedures"
                                subSection=''
                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[22].Password_change_procedures : null}
                            />
                            <CommentSubmission
                                sectionName='Password_change_procedures'
                                subSection=''
                                updateType='Select update'
                                remediation_by={remediated_by}
                                data={submitSarCommentData.length > 0 ? submitSarCommentData[22].Password_change_procedures : null}
                            />
                        </div>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <FormikControl
                        control="textfield"
                        name="sar_password_change_procedure"
                        // label="Please server hostnames"
                        disabled={isDisabled}
                        placeholder="Provide password change procedures"
                        multiline
                        minRows={5}
                    />
                </Grid>
            </Grid >
            <Grid container paddingTop={'12px'} >
                <Grid item xs={10}>
                    <div>
                        <FieldLabel Title="Security of passwords" tooltipMsg={securityOfPassword} required />
                    </div>
                    <div>
                        <div className={styleClasses.updatedByStyle}>
                            {/* @ts-ignore */}
                            {(fieldHistorySarCommentsData[15].Security_of_passwords?.length > 0) && `Updated on ${formatDateInside(fieldHistorySarCommentsData[15].Security_of_passwords[fieldHistorySarCommentsData[15].Security_of_passwords?.length - 1].last_updated)}`}
                        </div>
                    </div>
                </Grid>

                <Grid item xs={2}>
                    {(checkForEditReviewFlow() && remediationCount > 0) ? (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="Security_of_passwords"
                                subSection=''
                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[15].Security_of_passwords : null}
                            />
                            <CommentSubmission
                                sectionName='Security_of_passwords'
                                subSection=''
                                updateType='Select update'
                                remediation_by={remediated_by}
                                data={submitSarCommentData.length > 0 ? submitSarCommentData[15].Security_of_passwords : null}
                            />
                        </div>
                    ) : routeCheckForPendingApproval() && (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="Security_of_passwords"
                                subSection=''
                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[15].Security_of_passwords : null}
                            />
                            <CommentSubmission
                                sectionName='Security_of_passwords'
                                subSection=''
                                updateType='Select update'
                                remediation_by={remediated_by}
                                data={submitSarCommentData.length > 0 ? submitSarCommentData[15].Security_of_passwords : null}
                            />
                        </div>
                    )}
                </Grid>
                <Grid item xs={12} >
                    <FormikControl
                        control="textfield"
                        name="sar_security_of_password"
                        // label="Please server hostnames"
                        disabled={isDisabled}
                        placeholder="Provide security of password"
                        multiline
                        minRows={5}
                    />
                </Grid>
            </Grid >
            {
                formikCtx.values.sar_is_complex_service_account &&
                <>
                    <Grid container paddingTop={'12px'} >
                        <Grid item xs={10}>
                            <div>
                                <FieldLabel Title="How often the password will be changed?" tooltipMsg={passwordChanaged} required />
                            </div>
                            <div>
                                <div className={styleClasses.updatedByStyle}>
                                    {/* @ts-ignore */}
                                    {(fieldHistorySarCommentsData[23].Password_will_be_changed?.length > 0) && `Updated on ${formatDateInside(fieldHistorySarCommentsData[23].Password_will_be_changed[fieldHistorySarCommentsData[23].Password_will_be_changed?.length - 1].last_updated)}`}
                                </div>
                            </div>
                        </Grid>

                        <Grid item xs={2}>
                            {(checkForEditReviewFlow() && remediationCount > 0) ? (
                                <div className={styleClasses.mainSectionDiv}>
                                    <HistoryTable
                                        parId={formikCtx.values.request_id}
                                        section="Password_will_be_changed"
                                        subSection=''
                                        data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[23].Password_will_be_changed : null}
                                    />
                                    <CommentSubmission
                                        sectionName='Password_will_be_changed'
                                        subSection=''
                                        updateType='Select update'
                                        remediation_by={remediated_by}
                                        data={submitSarCommentData.length > 0 ? submitSarCommentData[23].Password_will_be_changed : null}
                                    />
                                </div>
                            ) : routeCheckForPendingApproval() && (
                                <div className={styleClasses.mainSectionDiv}>
                                    <HistoryTable
                                        parId={formikCtx.values.request_id}
                                        section="Password_will_be_changed"
                                        subSection=''
                                        data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[23].Password_will_be_changed : null}
                                    />
                                    <CommentSubmission
                                        sectionName='Password_will_be_changed'
                                        subSection=''
                                        updateType='Select update'
                                        remediation_by={remediated_by}
                                        data={submitSarCommentData.length > 0 ? submitSarCommentData[23].Password_will_be_changed : null}
                                    />
                                </div>
                            )}
                        </Grid>
                        <Grid className={classes.checkBox} item xs={5} >
                            <FormikControl
                                //checked
                                disabled={isDisabled}
                                control="radio"
                                name="sar_password_reset_timing"
                                radioGrouplabels={passwordWillChanged}
                            //isInline
                            />
                        </Grid>
                    </Grid>

                    {formikCtx.values.sar_password_reset_timing !== '60days(default policy)' &&
                        <>
                            <Grid container paddingTop={'12px'} >
                                <Grid item xs={10}>
                                    <div>
                                        <FieldLabel Title="Reason for exception from default password expiry policy" tooltipMsg={expiryPolicy} required />
                                    </div>
                                    <div>
                                        <div className={styleClasses.updatedByStyle}>
                                            {/* @ts-ignore */}
                                            {(fieldHistorySarCommentsData[24].Default_password_expiry_policy?.length > 0) && `Updated on ${formatDateInside(fieldHistorySarCommentsData[24].Default_password_expiry_policy[fieldHistorySarCommentsData[24].Default_password_expiry_policy?.length - 1].last_updated)}`}
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={2}>
                                    {(checkForEditReviewFlow() && remediationCount > 0) ? (
                                        <div className={styleClasses.mainSectionDiv}>
                                            <HistoryTable
                                                parId={formikCtx.values.request_id}
                                                section="Default_password_expiry_policy"
                                                subSection=''
                                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[24].Default_password_expiry_policy : null}
                                            />
                                            <CommentSubmission
                                                sectionName='Default_password_expiry_policy'
                                                subSection=''
                                                updateType='Select update'
                                                remediation_by={remediated_by}
                                                data={submitSarCommentData.length > 0 ? submitSarCommentData[24].Default_password_expiry_policy : null}
                                            />
                                        </div>
                                    ) : routeCheckForPendingApproval() && (
                                        <div className={styleClasses.mainSectionDiv}>
                                            <HistoryTable
                                                parId={formikCtx.values.request_id}
                                                section="Default_password_expiry_policy"
                                                subSection=''
                                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[24].Default_password_expiry_policy : null}
                                            />
                                            <CommentSubmission
                                                sectionName='Default_password_expiry_policy'
                                                subSection=''
                                                updateType='Select update'
                                                remediation_by={remediated_by}
                                                data={submitSarCommentData.length > 0 ? submitSarCommentData[24].Default_password_expiry_policy : null}
                                            />
                                        </div>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <FormikControl
                                        control="textfield"
                                        name="sar_reason_for_password_expiry_policy"
                                        // label="Please server hostnames"
                                        disabled={isDisabled}
                                        placeholder="Provide reason for exception"
                                        multiline
                                        minRows={5}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container paddingTop={'12px'} >
                                <Grid item xs={10}>
                                    <div>
                                        <FieldLabel Title="Business impact if service account is forced to expire every 60 days" tooltipMsg={businessImpact} required />
                                    </div>
                                    <div>
                                        <div className={styleClasses.updatedByStyle}>
                                            {/* @ts-ignore */}
                                            {(fieldHistorySarCommentsData[25].Business_impact?.length > 0) && `Updated on ${formatDateInside(fieldHistorySarCommentsData[25].Business_impact[fieldHistorySarCommentsData[25].Business_impact?.length - 1].last_updated)}`}
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={2}>
                                    {(checkForEditReviewFlow() && remediationCount > 0) ? (
                                        <div className={styleClasses.mainSectionDiv}>
                                            <HistoryTable
                                                parId={formikCtx.values.request_id}
                                                section="Business_impact"
                                                subSection=''
                                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[25].Business_impact : null}
                                            />
                                            <CommentSubmission
                                                sectionName='Business_impact'
                                                subSection=''
                                                updateType='Select update'
                                                remediation_by={remediated_by}
                                                data={submitSarCommentData.length > 0 ? submitSarCommentData[25].Business_impact : null}
                                            />
                                        </div>
                                    ) : routeCheckForPendingApproval() && (
                                        <div className={styleClasses.mainSectionDiv}>
                                            <HistoryTable
                                                parId={formikCtx.values.request_id}
                                                section="Business_impact"
                                                subSection=''
                                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[25].Business_impact : null}
                                            />
                                            <CommentSubmission
                                                sectionName='Business_impact'
                                                subSection=''
                                                updateType='Select update'
                                                remediation_by={remediated_by}
                                                data={submitSarCommentData.length > 0 ? submitSarCommentData[25].Business_impact : null}
                                            />
                                        </div>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <FormikControl
                                        control="textfield"
                                        name="sar_business_impact"
                                        disabled={isDisabled}
                                        placeholder="Provide business impact"
                                        multiline
                                        minRows={5}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container paddingTop={'12px'} >
                                <Grid item xs={10}>
                                    <div>
                                        <FieldLabel Title="Risk exposure" tooltipMsg={riskExposureTooltip} required />
                                    </div>
                                    <div>
                                        <div className={styleClasses.updatedByStyle}>
                                            {/* @ts-ignore */}
                                            {(fieldHistorySarCommentsData[26].Risk_exposure?.length > 0) && `Updated on ${formatDateInside(fieldHistorySarCommentsData[26].Risk_exposure[fieldHistorySarCommentsData[26].Risk_exposure?.length - 1].last_updated)}`}
                                        </div>
                                    </div>
                                </Grid>

                                <Grid item xs={2}>
                                    {(checkForEditReviewFlow() && remediationCount > 0) ? (
                                        <div className={styleClasses.mainSectionDiv}>
                                            <HistoryTable
                                                parId={formikCtx.values.request_id}
                                                section="Risk_exposure"
                                                subSection=''
                                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[26].Risk_exposure : null}
                                            />
                                            <CommentSubmission
                                                sectionName='Risk_exposure'
                                                subSection=''
                                                updateType='Select update'
                                                remediation_by={remediated_by}
                                                data={submitSarCommentData.length > 0 ? submitSarCommentData[26].Risk_exposure : null}
                                            />
                                        </div>
                                    ) : routeCheckForPendingApproval() && (
                                        <div className={styleClasses.mainSectionDiv}>
                                            <HistoryTable
                                                parId={formikCtx.values.request_id}
                                                section="Risk_exposure"
                                                subSection=''
                                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[26].Risk_exposure : null}
                                            />
                                            <CommentSubmission
                                                sectionName='Risk_exposure'
                                                subSection=''
                                                updateType='Select update'
                                                remediation_by={remediated_by}
                                                data={submitSarCommentData.length > 0 ? submitSarCommentData[26].Risk_exposure : null}
                                            />
                                        </div>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <FormikControl
                                        disabled={isDisabled}
                                        control="radio"
                                        name="sar_risk_exposure"
                                        radioGrouplabels={riskExposure}
                                    //isInline
                                    />
                                </Grid>
                            </Grid>
                            <Grid container paddingTop={'12px'} >
                                <Grid item xs={10}>
                                    <div>
                                        <FieldLabel Title="Risk of the service account having an exception from default password expiry policy" tooltipMsg={defaultPasswordExpiryPolicy} required />
                                    </div>
                                    <div>
                                        <div className={styleClasses.updatedByStyle}>
                                            {/* @ts-ignore */}
                                            {(fieldHistorySarCommentsData[27].Risk_of_the_service_account?.length > 0) && `Updated on ${formatDateInside(fieldHistorySarCommentsData[27].Risk_of_the_service_account[fieldHistorySarCommentsData[27].Risk_of_the_service_account?.length - 1].last_updated)}`}
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={2}>
                                    {(checkForEditReviewFlow() && remediationCount > 0) ? (
                                        <div className={styleClasses.mainSectionDiv}>
                                            <HistoryTable
                                                parId={formikCtx.values.request_id}
                                                section="Risk_of_the_service_account"
                                                subSection=''
                                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[27].Risk_of_the_service_account : null}
                                            />
                                            <CommentSubmission
                                                sectionName='Risk_of_the_service_account'
                                                subSection=''
                                                updateType='Select update'
                                                remediation_by={remediated_by}
                                                data={submitSarCommentData.length > 0 ? submitSarCommentData[27].Risk_of_the_service_account : null}
                                            />
                                        </div>
                                    ) : routeCheckForPendingApproval() && (
                                        <div className={styleClasses.mainSectionDiv}>
                                            <HistoryTable
                                                parId={formikCtx.values.request_id}
                                                section="Risk_of_the_service_account"
                                                subSection=''
                                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[27].Risk_of_the_service_account : null}
                                            />
                                            <CommentSubmission
                                                sectionName='Risk_of_the_service_account'
                                                subSection=''
                                                updateType='Select update'
                                                remediation_by={remediated_by}
                                                data={submitSarCommentData.length > 0 ? submitSarCommentData[27].Risk_of_the_service_account : null}
                                            />
                                        </div>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <FormikControl
                                        control="textfield"
                                        name="sar_risk_of_sar_non_password_expiring"
                                        //label="Please server hostnames"
                                        disabled={isDisabled}
                                        placeholder="Risk of the service account having an exception from default password expiry policy"
                                        multiline
                                        minRows={5}
                                    />
                                </Grid>
                            </Grid >

                            <Grid container paddingTop={'12px'} >
                                <Grid item xs={5}>
                                    <div>
                                        <FieldLabel Title="Risk if service account was compromised" tooltipMsg={accountCompromised} required />
                                    </div>
                                    <div>
                                        <div className={styleClasses.updatedByStyle}>
                                            {/* @ts-ignore */}
                                            {(fieldHistorySarCommentsData[28].Account_was_compromised?.length > 0) && `Updated on ${formatDateInside(fieldHistorySarCommentsData[28].Account_was_compromised[fieldHistorySarCommentsData[28].Account_was_compromised?.length - 1].last_updated)}`}
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={2}>
                                    {(checkForEditReviewFlow() && remediationCount > 0) ? (
                                        <div className={styleClasses.mainSectionDiv}>
                                            <HistoryTable
                                                parId={formikCtx.values.request_id}
                                                section="Account_was_compromised"
                                                subSection=''
                                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[28].Account_was_compromised : null}
                                            />
                                            <CommentSubmission
                                                sectionName='Account_was_compromised'
                                                subSection=''
                                                updateType='Select update'
                                                remediation_by={remediated_by}
                                                data={submitSarCommentData.length > 0 ? submitSarCommentData[28].Account_was_compromised : null}
                                            />
                                        </div>
                                    ) : routeCheckForPendingApproval() && (
                                        <div className={styleClasses.mainSectionDiv}>
                                            <HistoryTable
                                                parId={formikCtx.values.request_id}
                                                section="Account_was_compromised"
                                                subSection=''
                                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[28].Account_was_compromised : null}
                                            />
                                            <CommentSubmission
                                                sectionName='Account_was_compromised'
                                                subSection=''
                                                updateType='Select update'
                                                remediation_by={remediated_by}
                                                data={submitSarCommentData.length > 0 ? submitSarCommentData[28].Account_was_compromised : null}
                                            />
                                        </div>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <FormikControl
                                        control="textfield"
                                        name="sar_risk_if_sar_compromised"
                                        // label="Please server hostnames"
                                        disabled={isDisabled}
                                        placeholder="Risk if service account was compromised"
                                        multiline
                                        minRows={5}
                                    />
                                </Grid>
                            </Grid >
                        </>
                    }
                </>
            }
            <Grid item xs={12} >
                <FieldLabel Title="Attachments (optional)" />
            </Grid >
            <Grid item xs={12} style={{ paddingBottom: '16px', width: '100%' }}>
                <span className={classes.spanTextStyle}>
                    Kindly attach files in either doc, docx, pdf , img or jpg format
                </span>
            </Grid>
            <Grid item xs={12} className={classes.container} style={{ width: '100%' }}>
                <FileUploader
                    disabled={isDisabled}
                    fileAction={(
                        <CustomButton
                            variant="contained"
                            actionType="primary"
                            disableMargin
                            disabled={isDisabled}
                        >
                            <AddIcon />
                            <span>Attach File</span>
                        </CustomButton>)}
                    requestId={formikCtx.values.request_id}
                    getFiles={formikCtx.values.attachment.length > 0 && formikCtx.values.attachment.filter((obj: any) => obj.file_section !== 'signature')}
                />
            </Grid>


        </>
    )
}

export default PasswordSecurityAndPolicy;