import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  container: {
    border: '1px solid #D9D9D9',
    borderRadius: '4px',
    marginLeft: '28px',
    marginBottom: '10px',
  },
  topDivfileContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  divFileIcon: {
    font: 'normal normal normal 14px/16px Noto Serif',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    marginLeft: '10px',
    marginTop: '8px',
    marginBottom: '8px',
  },
  divFileName: {
    font: 'normal normal normal 14px/16px Noto Serif',
    marginLeft: '8px',
    overflow: 'hidden',
    maxWidth: '30ch',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  closeIconStyle: {
    position: 'absolute',
    right: '5px',
    top: '5px',
    cursor:'pointer'
  },
  // fileUpload at implementation
  implementationFile: {
    width: '400px',
    height: '200px',
    border: '1px solid #f1f1f1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '10px',
  }

});
export default useStyles;
