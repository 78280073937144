import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IChatHistoryData } from "./IChatHistoryProps";


interface IChatHistory {
    chathistorydata: IChatHistoryData[],
};

const initialState: IChatHistory = {
    chathistorydata: [],
}

export const chatHistorySlice = createSlice({
    name: 'chatHistory',
    initialState,
    reducers: {
        chatHistoryData: (state, action: PayloadAction<IChatHistoryData[]>) => {
            state.chathistorydata = action.payload
        },
        // state mutation logic
    }
});

export const { chatHistoryData } = chatHistorySlice.actions;
export default chatHistorySlice.reducer;
