import { makeStyles } from '@mui/styles';

const paginationStyles = makeStyles({
  paginatedResultsText: {
    font: 'normal normal normal 13px/20px Poppins-Regular',
    letterSpacing: '0px',
    color: '#0A2756',
    display: 'inline-block',
    lineHeight: '26px',
  },
  paginationRoot: {
    display: 'inline-block',
    float: 'right',
  },
  paginationText: {
    color: '#0A2756 !important',
    font: 'normal normal normal 13px/20px Poppins-Regular !important',
    borderRadius: '3px !important',
    margin: '0px 2px !important',
    '&:hover': {
      background: '#EEF2F3 !important',
    },
    "& svg": {
      width: '1.6rem',
      height:'1.6rem'
    }
  },
  paginationSelected: {
    background: '#fff !important',
    border: '2px solid #FFA800 !important',
    fontFamily: 'Poppins-SemiBold !important',
  },
  skeletonContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: '0 0 100%',
    height: 'calc(100vh - 20vh) !important',
  },
  skeleton: {
    width: '100%',
    transform: 'none !important',
    backgroundColor: '#EDF4F8 !important',
  },
});

export default paginationStyles;
