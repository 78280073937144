import { useFormikContext, Formik } from 'formik';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import FormikControl from '../../../formik-conrtols/FormikControl';
import FieldLabel from '../../../field-label';
import { format, isDate } from 'date-fns';
import { RootState } from '../../../../appStore/store';
import { justificationForAccess, reasonForDelegationRequest } from '../tooltip';
import HistoryTable from '../../../../modules/delegation/comments/historyTable';
import { parStyles } from '../../useStyles';
import CommentSubmission from '../../../../modules/delegation/comments/commentSubmission';
import { routeCheckDelegation, routeCheckForAdminSummary, routeCheckForApprovalSummary, routeCheckForImplementationSummary, routeCheckForPendingApproval } from './routingConditional';

function DelegationReason({ isDisabled }: { isDisabled: boolean }) {
  const formikCtx: any = useFormikContext();
  const formatDateInside = (dateValue: any) => (dateValue && isDate(new Date(dateValue)) ? format(new Date(dateValue), 'MMM dd, yyyy') : '');
  const { fieldHistoryDelegationCommentsData } = useSelector((state: RootState) => state);
  const { submitDelegationCommentData } = useSelector((state: RootState) => state);
  const styleClasses = parStyles();
  const remediated_by = formikCtx.values.remediation_by
  const remediationCount = formikCtx.values.remediation_count;

  const checkForEditReviewFlow = () => {
    return routeCheckDelegation() || routeCheckForAdminSummary() || routeCheckForImplementationSummary() || routeCheckForApprovalSummary()
  }

  // checkForEditReviewFlow
  return (
    <Grid container spacing={1}>
      <Grid item xs={5}>
        <div>
          <FieldLabel Title="Reason for Delegation request" tooltipMsg={reasonForDelegationRequest} required />
        </div>
        <div style={{ color: '#A7A7A7', fontFamily: 'NotoSerif-Regular', fontSize: '10px', marginTop: '-5px', paddingBottom: '10px' }}>
          {/* @ts-ignore */}
          {(fieldHistoryDelegationCommentsData[0].Reason_for_Delegation_request?.length > 0) && `Updated on ${formatDateInside(fieldHistoryDelegationCommentsData[0].Reason_for_Delegation_request[fieldHistoryDelegationCommentsData[0].Reason_for_Delegation_request?.length - 1].last_updated)}`}
        </div>
      </Grid>
      <Grid item xs={5}>

      </Grid>
      <Grid item xs={2}>
        {(checkForEditReviewFlow() && remediationCount > 0) ? (
          <div className={styleClasses.mainSectionDiv}>
            <HistoryTable
              parId={formikCtx.values.request_id}
              section="Reason_for_Delegation_request"
              subSection=''
              data={fieldHistoryDelegationCommentsData.length > 0 ? fieldHistoryDelegationCommentsData[0].Reason_for_Delegation_request : null}
            />

            <CommentSubmission
              sectionName='Reason_for_Delegation_request'
              subSection=''
              updateType='Select update'
              remediation_by={remediated_by}
              data={submitDelegationCommentData.length > 0 ? submitDelegationCommentData[0].Reason_for_Delegation_request : null}
            />
          </div>
        ) : routeCheckForPendingApproval() && (
          <div className={styleClasses.mainSectionDiv}>
            <HistoryTable
              parId={formikCtx.values.request_id}
              section="Reason_for_Delegation_request"
              subSection=''
              data={fieldHistoryDelegationCommentsData.length > 0 ? fieldHistoryDelegationCommentsData[0].Reason_for_Delegation_request : null}
            />

            <CommentSubmission
              sectionName='Reason_for_Delegation_request'
              subSection=''
              updateType='Select update'
              remediation_by={remediated_by}
              data={submitDelegationCommentData.length > 0 ? submitDelegationCommentData[0].Reason_for_Delegation_request : null}
            />
          </div>
        )}
      </Grid>
      <Grid item xs={12}>
        <FormikControl
          disabled={isDisabled}
          control="radio"
          name="del_reason_for_raising_new_request"
          radioGrouplabels={[
            {
              label: 'Role doesn\'t exist',
              value: `role doesn't exist`,
            },
            {
              label: 'Request for service',
              value: 'Request for service',
            },
            {
              label: 'Short-term contract',
              value: 'Short-term contract',
            },
            {
              label: 'Update existing Delegation',
              value: 'Update existing Delegation',
            },
            {
              label: 'Other',
              value: 'Other',
            },
          ]}
        />
        {
          formikCtx.values.del_reason_for_raising_new_request === 'Other' && (
            <FormikControl
              disabled={isDisabled}
              control="textfield"
              label="Please specify other"
              name="del_other"
              multiline
              minRows={4}
            />
          )
        }
      </Grid>
      <Grid item xs={5}>
        <div>
          <FieldLabel Title="Justification for access" tooltipMsg={justificationForAccess} required />
        </div>
        <div style={{ color: '#A7A7A7', fontFamily: 'NotoSerif-Regular', fontSize: '10px', marginTop: '-5px', paddingBottom: '10px' }}>
          {/* @ts-ignore */}
          {(fieldHistoryDelegationCommentsData[1].Justification_for_access?.length > 0) && `Updated on ${formatDateInside(fieldHistoryDelegationCommentsData[1].Justification_for_access[fieldHistoryDelegationCommentsData[1].Justification_for_access?.length - 1].last_updated)}`}
        </div>
      </Grid>
      <Grid item xs={5}>
      </Grid>
      <Grid item xs={2}>
        {(checkForEditReviewFlow() && remediationCount > 0) ? (
          <div className={styleClasses.mainSectionDiv}>
            <HistoryTable
              parId={formikCtx.values.request_id}
              section="Justification_for_access"
              subSection=''
              data={fieldHistoryDelegationCommentsData.length > 0 ? fieldHistoryDelegationCommentsData[1].Justification_for_access : null}
            />

            <CommentSubmission
              sectionName='Justification_for_access'
              subSection=''
              updateType='text update'
              remediation_by={remediated_by}
              data={submitDelegationCommentData.length > 0 ? submitDelegationCommentData[1].Justification_for_access : null}
            />
          </div>
        ) : routeCheckForPendingApproval() && (
          <div className={styleClasses.mainSectionDiv}>
            <HistoryTable
              parId={formikCtx.values.request_id}
              section="Justification_for_access"
              subSection=''
              data={fieldHistoryDelegationCommentsData.length > 0 ? fieldHistoryDelegationCommentsData[1].Justification_for_access : null}
            />

            <CommentSubmission
              sectionName='Justification_for_access'
              subSection=''
              updateType='text update'
              remediation_by={remediated_by}
              data={submitDelegationCommentData.length > 0 ? submitDelegationCommentData[1].Justification_for_access : null}
            />
          </div>
        )}

      </Grid>

      <Grid item xs={12}>
        <FormikControl
          disabled={isDisabled}
          control="textfield"
          label="Provide justification for raising access request"
          name="del_justification_for_access"
          multiline
          minRows={4}
        />
      </Grid >
    </Grid >

  );
}
export default DelegationReason;
