import React, { useState } from "react";

import useAccordionDataHook from "./useAccordionDataHook";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import { implementationStyles } from "./useStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


const mainAccordion = () => {
  const classes = implementationStyles();
  const {
    delegationQuestion,
    attestationQuestion,
    privilegedAccessquestions,
  } = useAccordionDataHook();
  // const [delegationList, setDelegationList] = useState(delegationQuestion);
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <>
      <Grid container className={classes.gridContainer}>
        <Grid item md={12}>
          <div className={classes.titleContainer}>
            <div className={classes.sectionHeader}>
              FAQs (Frequently Asked Questions)
            </div>
          </div>
        </Grid>

        <Grid item xs={12} className={classes.moduleHeader}>
          Delegation
        </Grid>
      </Grid>

      {delegationQuestion.map((item: any, index: any) => {
        return (
          <Grid container className={classes.gridContainer}>
            <Grid item xs={12}>
              <Accordion
                expanded={expanded === `panel1${index}`}
                onChange={handleChange(`panel1${index}`)}
                className={classes.gridTitle}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  className={classes.accordionSummary}
                >
                  <Typography >
                    <div dangerouslySetInnerHTML={{ __html: item.question }} />
                  </Typography>
                </AccordionSummary>

                <AccordionDetails className={classes.accordionDetails}>
                  <Typography>
                    <div dangerouslySetInnerHTML={{ __html: item.answer }} />
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>

        );
      })}

      {/* Privileged Access Start */}

      <Grid container className={classes.gridContainer}>
        <Grid item xs={12} className={classes.moduleHeader}>
          Privileged Access
        </Grid>
      </Grid>

      {privilegedAccessquestions.map((item: any, index: any) => {
        return (
          <Grid container className={classes.gridContainer}>
            <Grid item xs={12}>
              <Accordion
                expanded={expanded === `panel2${index}`}
                onChange={handleChange(`panel2${index}`)}
                className={classes.gridTitle}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                  className={classes.accordionSummary}
                >
                  <Typography>
                    <div dangerouslySetInnerHTML={{ __html: item.question }} />
                  </Typography>
                </AccordionSummary>

                <AccordionDetails className={classes.accordionDetails}>
                  <Typography>
                    <div dangerouslySetInnerHTML={{ __html: item.answer }} />
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        );
      })}

      {/* Attestation Start */}
      <Grid container className={classes.gridContainer}>
        <Grid item xs={12} className={classes.moduleHeader}>
          Attestation
        </Grid>
      </Grid>

      {attestationQuestion.map((item: any, index: any) => {
        return (
          <Grid container className={classes.gridContainer}>
            <Grid item xs={12}>
              <Accordion
                expanded={expanded === `panel3${index}`}
                onChange={handleChange(`panel3${index}`)}
                className={classes.gridTitle}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                  className={classes.accordionSummary}
                >
                  <Typography>
                    <div dangerouslySetInnerHTML={{ __html: item.question }} />
                  </Typography>
                </AccordionSummary>

                <AccordionDetails className={classes.accordionDetails}>
                  <Typography>
                    <div dangerouslySetInnerHTML={{ __html: item.answer }} />
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};

export default mainAccordion;
