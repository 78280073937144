import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import {
  Formik,
  FormikConfig,
  FormikValues,
  FormikHelpers,
  FormikErrors,
  useFormikContext,
  FieldProps
} from "formik";
import { useHistory, Prompt, useLocation } from "react-router-dom";
import Divider from "@mui/material/Divider";
import { useSnackbar } from "notistack";
import { isEmpty, isEqual, union, uniqWith } from "lodash";
import Footer from "../../footer";
import CustomButton from "../../button";
import CustomModal from "../../custom-modal";
import VerticalLinearStepper from "../../stepper";
import {
  cancelDelegation,
  postNewRequest,
} from "../../../api-services/delegation-module";
import { postRemediationStatus } from "../../../api-services/approval-summary";
import { RootState } from "../../../appStore/store";
import { useSelector } from "react-redux";
import { formikContainerStyles, modalStyles } from "../useStyles";
import {
  FormikStepProps,
  IModalState,
  emptySchemaCheck,
  fieldsOfSection,
  stepsData,
  staticDiffernceData,
  fieldsOfSectionForSubsection
} from "./formikContainerUtils";
import { updateImplementerStatus } from "../../../api-services/pending-implementation";
import { postDelegationFieldHistoryData } from "../../../api-services/review-delegation";
import { routeCheck, routeCheckForAdminSummary, routeCheckForApprovalSummary, routeCheckForImplementationSummary, routeCheckForPendingImplementation, routeCheckForImplementation } from "./formsSteps/routingConditional";


export const stepsDataImplementation = [
  { label: "Delegation Reason " },
  { label: "Requestor & Team Details" },
  { label: "Requestor Job Details" },
  { label: "Account Type" },
  { label: "Role Definition" },
  { label: "User Details" },
  { label: "Custom & 3rd Party Request" },
  { label: "Delegation Owner Sign-off" },
  { label: "Implementation" },
];

export function FormikStepper({
  children,
  requestId,
  requestData,
  ...props
}: FormikConfig<FormikValues> & {
  requestId: string;
  requestData: any;
}) {


  const history = useHistory();
  const location = useLocation();
  const [approver, setApprover] = useState(true);
  const [openModalAp, setOpenModalAp] = useState(false);
  const [openModalReject, setOpenModalReject] = useState(false);
  const { loginuserdata, loginsyncupdata } = useSelector(
    (state: RootState) => state.loginData
  );
  const { submitDelegationCommentData } = useSelector((state: RootState) => state);
  // window.addEventListener("beforeunload", (ev) => {
  //   console.log("sdsdsdd")
  //   ev.preventDefault();
  //   // return ev.returnValue = 'Are you sure you want to close?';
  // });routeCheckForPendingImplementation

  const formikCtx: any = useFormikContext();
  // const routeCheckForImplementation = () => {
  //   return location.pathname.slice(1, 44).toLocaleLowerCase() === 'myimplementationtasks/pendingimplementation'
  // }
  const roleGroupArray = loginsyncupdata.groups.map(
    ({ group_name }: { group_name: string }) => group_name
  );

  const checkIsDisabledImplementation = () => {
    if (routeCheckForImplementationSummary() || routeCheckForAdminSummary() || routeCheckForApprovalSummary()) {
      return true;
    } else return false;
  };

  const { enqueueSnackbar } = useSnackbar();
  const [modalData, setModalData] = useState<IModalState>({
    show: false,
    modalHeader: "",
    modalContent: null,
    modalActions: null,
  });

  const styleClasses = formikContainerStyles();
  const childrenArray = React.Children.toArray(
    children
  ) as React.ReactElement<FormikStepProps>[];
  const [activeStep, setActiveStep] = useState(0);
  const currentChild = childrenArray[activeStep];
  const [schemaCheck, setSchemaCheck] = useState(false);
  const [isAPIProgress, setIsAPIProgress] = useState({
    saving: false,
    canceling: false,
  });

  let modalClickCheck = {
    rejectStatus: false,
    remediationStatus: false,
    approveStatus: false,
  }
  const historyDataOfForm = JSON.parse(localStorage.getItem('historyDataOfMainSchemaDelegation') || '');

  const postBodyData = [
    // @ts-ignore
    ...submitDelegationCommentData[0].Reason_for_Delegation_request,
    // @ts-ignore
    ...submitDelegationCommentData[1].Justification_for_access,
    // @ts-ignore
    ...submitDelegationCommentData[2].Requestor_details,
    // @ts-ignore
    ...submitDelegationCommentData[3].Team_details,
    // @ts-ignore
    ...submitDelegationCommentData[4].Job_title_and_description,
    // @ts-ignore
    ...submitDelegationCommentData[5].Choose_account_type,
    // @ts-ignore
    ...submitDelegationCommentData[6].Role_definition,
    // @ts-ignore
    ...submitDelegationCommentData[7].User_details,
    // @ts-ignore
    ...submitDelegationCommentData[8].Provide_custom_request,
    // @ts-ignore
    ...submitDelegationCommentData[9].Third_Party_Request_For_Service_in_place,
    // @ts-ignore
    ...submitDelegationCommentData[10].Contract_number_or_Request_For_Service_reference,
    // @ts-ignore
    ...submitDelegationCommentData[11].Request_For_Service_details,
    // @ts-ignore
    ...submitDelegationCommentData[12].Implementation_plan,
    // @ts-ignore
    ...submitDelegationCommentData[13].Access_required_for_Request_For_Service,
    // @ts-ignore
    ...submitDelegationCommentData[14].Third_Party_Request_For_Service_timings,
    // @ts-ignore
    ...submitDelegationCommentData[15].Privileged_access_Delegation_owner_sign_off,
  ]

  function isLastStep() {
    return activeStep === childrenArray.length - 1;
  }
  // const checkIsDisabled = () =>
  //   !(
  //     props.initialValues.status_label === "Draft" ||
  //     props.initialValues.status_label === "Remediation Requested"
  //   );
  const checkIsDisabled = () => !(props.initialValues.status_label === 'Draft' || props.initialValues.status_label !== 'Pending Approval') || routeCheckForApprovalSummary() || routeCheckForAdminSummary() || props.initialValues.status_label === 'Completed' || props.initialValues.status_label === "Approved - Pending Implementation" || props.initialValues.status_label === 'Rejected';

  const pendingApprovalCheck = () => props.initialValues.status_label === 'Pending Approval'
  const centerButton = false;
  const modalClasses = modalStyles({ centerButton });

  const handleSave = (
    // dirty: boolean,
    values: FormikValues,
    errors: FormikErrors<FormikValues>,
    setFieldValue: (
      _fieldName: string,
      _value: unknown) => void,
    validateForm: (_values: FormikValues) => any,
    resetForm: (_values: FormikValues) => void,
    options: { shouldExit: boolean, switchStep: number },
  ) => {
    const duplicatesCheckfunction = () => {
      const temp = values.del_role_name_list.filter((item: any) => item.is_active !== false)
      const valueArr = temp.map(function (item: any) { return item.role_name });
      const isDuplicate = valueArr.some(function (item: any, idx: any) {
        return valueArr.indexOf(item) != idx
      });
      return isDuplicate;
    }
    const duplicatesUserCheckfunction = () => {
      const temp = values.del_user_detail_list.filter((item: any) => item.is_active !== false)
      let booleanCheck = false;
      for (let i = 0; i < temp.length - 1; i++) {
        if (temp[i].role_name === temp[i + 1].role_name && temp[i].email === temp[i + 1].email && temp[i].domain === temp[i + 1].domain) {
          return booleanCheck = true
        }
      }
      return booleanCheck;
    }
    if (activeStep === 4 && duplicatesCheckfunction()) {
      duplicateRoleNameCheck()
      return
    }
    if (activeStep === 5 && duplicatesUserCheckfunction()) {
      duplicateUserCheck()
      return
    }
    if (activeStep === 4 && !(values.del_role_name_list.filter((item: any) => item.is_active !== false).length > 0)) {
      enqueueSnackbar(`Please complete the current step`, { variant: 'warning' })
      return
    }
    if (activeStep === 5 && !(values.del_user_detail_list.filter((item: any) => item.is_active !== false).length > 0)) {
      enqueueSnackbar(`Please complete the current step`, { variant: 'warning' })
      return
    }

    if (props.initialValues.remediation_count > 0) {
      const changedValues = Object.keys(values).filter(key => values[key] !== historyDataOfForm[key])
      const newArray = changedValues.filter(v => !staticDiffernceData.includes(v));
      let finalChangedData: any = [];
      newArray.length > 0 && newArray.map((item) => {
        finalChangedData.push(
          {
            update_type: emptySchemaCheck.get(item),
            section: fieldsOfSection.get(item),
            sub_section: fieldsOfSectionForSubsection.get(item),
            value: historyDataOfForm[item],
            role_name_section: '',
            user_details_section: ''
          }
        )
      })

      const historyPostBody = {
        request_id: requestId,
        is_submitted: false,
        data: finalChangedData.filter((item) => item.section !== undefined && item.sub_section !== undefined)
      }
      if (finalChangedData.length > 0) {
        postDelegationFieldHistoryData(historyPostBody).then((response) => {
          console.log(response)
        }).catch(error => error);
      }
    }
    setFieldValue("onSave", true);
    async () => {
      await Promise.resolve();
    };
    validateForm(values).then((errors: FormikErrors<FormikValues>) => {
      if (errors.del_user_detail_list || errors.del_role_name_list) {
        setFieldValue("onSave", false);
        return;
      }
      if (!isEmpty(errors)) {
        setFieldValue("onSave", false);
        return;
      }
      else if (activeStep === 3 && values.del_delegation_account_type_id === '') {
        enqueueSnackbar('Please select atleast one option from choose account type', { variant: 'warning' })
      } else {
        postNewRequest({
          ...values,
          next_priority: null,
          request_type: 1,
          request_id: requestId,
          event: requestId !== '' ? 'update' : 'create',
          status: values.remediation_count === 0 ? "1" : "7",
        }).then((res) => {
          setIsAPIProgress((prevState) => ({ ...prevState, saving: true }));
          if (res.status) {
            if (options.shouldExit) {
              resetForm({ values: res.data });
              history.push('/delegation');
            }
            else if (isEmpty(errors)) {
              enqueueSnackbar(`Delegation ${requestData.request_number} has been saved successfully`, { variant: 'success' })
            }
            const formValues = {
              ...values,
              del_role_name_list: [...res.data.del_role_name_list],
              del_user_detail_list: [...res.data.del_user_detail_list],
            };

            resetForm({ values: formValues });
          } else {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
          }
          setIsAPIProgress((prevState) => ({ ...prevState, saving: false }));
        }).catch((err) => {
          console.error(err);
          enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
          setIsAPIProgress((prevState) => ({ ...prevState, saving: false }));
        });
        setFieldValue('onSave', false);
      }
    });
  };

  const handleSaveOnNext = (
    // dirty: boolean,
    values: FormikValues,
    errors: FormikErrors<FormikValues>,
    setFieldValue: (
      _fieldName: string,
      _value: unknown) => void,
    validateForm: (_values: FormikValues) => any,
    resetForm: (_values: FormikValues) => void,
    options: { shouldExit: boolean, switchStep: number },
  ) => {
    const duplicatesCheckfunction = () => {
      const temp = values.del_role_name_list.filter((item: any) => item.is_active !== false)
      const valueArr = temp.map(function (item: any) { return item.role_name });
      const isDuplicate = valueArr.some(function (item: any, idx: any) {
        return valueArr.indexOf(item) != idx
      });
      return isDuplicate;
    }

    const duplicatesUserCheckfunction = () => {
      const temp = values.del_user_detail_list.filter((item: any) => item.is_active !== false)
      let booleanCheck = false;
      for (let i = 0; i < temp.length - 1; i++) {
        if (temp[i].role_name === temp[i + 1].role_name && temp[i].email === temp[i + 1].email && temp[i].domain === temp[i + 1].domain) {
          return booleanCheck = true
        }
      }
      return booleanCheck;
    }

    if (activeStep === 4 && duplicatesCheckfunction()) {
      duplicateRoleNameCheck()
      return
    }
    if (activeStep === 5 && duplicatesUserCheckfunction()) {
      duplicateUserCheck()
      return
    }
    if (activeStep === 4 && !(values.del_role_name_list.filter((item: any) => item.is_active !== false).length > 0)) {
      enqueueSnackbar(`Please complete the current step`, { variant: 'warning' })
      return;
    }
    if (activeStep === 5 && !(values.del_user_detail_list.filter((item: any) => item.is_active !== false).length > 0)) {
      enqueueSnackbar(`Please complete the current step`, { variant: 'warning' })
      return;
    }
    if (values.status_label === "Remediation Requested" && props.initialValues.remediation_count > 0) {
      const changedValues = Object.keys(values).filter(key => values[key] !== historyDataOfForm[key])
      const newArray = changedValues.filter(v => !staticDiffernceData.includes(v));
      let finalChangedData: any = [];
      newArray.length > 0 && newArray.map((item) => {
        finalChangedData.push(
          {
            update_type: emptySchemaCheck.get(item),
            section: fieldsOfSection.get(item),
            sub_section: fieldsOfSectionForSubsection.get(item),
            value: historyDataOfForm[item],
            role_name_section: '',
            user_details_section: ''
          }
        )
      })

      const historyPostBody = {
        request_id: requestId,
        is_submitted: false,
        data: finalChangedData.filter((item: any) => item.section !== undefined && item.sub_section !== undefined)
      }

      if (finalChangedData.length > 0) {
        postDelegationFieldHistoryData(historyPostBody).then((response) => {
          console.log(response)
        }).catch(error => error);
      }
    }
    setFieldValue("onSave", true);
    async () => {
      await Promise.resolve();
    };
    validateForm(values).then((errors: FormikErrors<FormikValues>) => {
      if (errors.del_user_detail_list || errors.del_role_name_list) {
        setFieldValue("onSave", false);
        return;
      }
      if (!isEmpty(errors)) {
        setFieldValue("onSave", false);
        return;
      } else if (activeStep === 3 && values.del_delegation_account_type_id === '') {
        enqueueSnackbar('Please select atleast one option from choose account type', { variant: 'warning' })
      } else {
        postNewRequest({
          ...values,
          next_priority: null,
          request_type: 1,
          request_id: requestId,
          event: requestId !== '' ? 'update' : 'create',
          status: values.remediation_count === 0 ? "1" : "7",
        }).then((res) => {
          setIsAPIProgress((prevState) => ({ ...prevState, saving: true }));
          if (res.status) {
            if (options.shouldExit) {
              resetForm({ values: res.data });
              history.push('/delegation');
            } else if (options.switchStep && activeStep !== options.switchStep && isEmpty(errors)) {
              setActiveStep(options.switchStep);
            }
            const formValues = {
              ...values,
              del_role_name_list: [...res.data.del_role_name_list],
              del_user_detail_list: [...res.data.del_user_detail_list],
            };
            resetForm({ values: formValues });
          } else {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
          }
          setIsAPIProgress((prevState) => ({ ...prevState, saving: false }));
        }).catch((err) => {
          console.error(err);
          enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
          setIsAPIProgress((prevState) => ({ ...prevState, saving: false }));
        });
        setFieldValue('onSave', false);
      }
    });
  };


  const handleModalClose = () => {
    setModalData({
      show: false,
      modalContent: null,
      modalActions: null,
      modalHeader: "",
    });
    modalClickCheck = {
      rejectStatus: false,
      approveStatus: false,
      remediationStatus: false,
    }
  };


  // start of status-update api function
  const statusUpdateDelegation = () => {
    if (modalClickCheck.approveStatus) {
      if (requestData.next_priority !== null) {
        const postBodyStatusUpdate = {
          request_id: requestId,
          status: '4',
        }

        postRemediationStatus(postBodyStatusUpdate).then((res) => {
          enqueueSnackbar(`Delegation ${props.initialValues.request_number} Approved successfully`, { variant: 'success' });
          history.push("/myApprovalTasks");
        }).catch((err) => err)
      } else {
        const postBodyStatusUpdate = {
          request_id: requestId,
          status: '3',
        }

        postRemediationStatus(postBodyStatusUpdate).then((res) => {
          enqueueSnackbar(`Delegation ${props.initialValues.request_number} Approved successfully`, { variant: 'success' });
          history.push("/myApprovalTasks");
        }).catch((err) => err)
      }
    } else if (modalClickCheck.rejectStatus) {

      const postBodyStatusUpdate = {
        request_id: requestId,
        status: '2',
      }
      postRemediationStatus(postBodyStatusUpdate).then((res) => {
        enqueueSnackbar(`Delegation ${props.initialValues.request_number} Rejected successfully`, { variant: 'success' });
        history.push("/myApprovalTasks");
      }).catch((err) => err)
    } else if (modalClickCheck.remediationStatus) {
      const postBodyStatusUpdate = {
        request_id: requestId,
        status: '7',
      }
      postRemediationStatus(postBodyStatusUpdate).then((res) => {
        enqueueSnackbar(`Your remediation has been requested successfully for Delegation ${props.initialValues.request_number}`, { variant: 'success' });
        history.push("/myApprovalTasks");
      }).catch((err) => err)
    }
  }
  // end of status-update api function



  const handleCancelRequest = (reqId: string) => {
    setIsAPIProgress((prevState) => ({ ...prevState, canceling: true }));
    cancelDelegation(reqId)
      .then((res) => {
        if (res.status) {
          enqueueSnackbar("Delegation cancelled successfully", {
            variant: "success",
          });
          history.push("/delegation");
        } else {
          enqueueSnackbar("Something went wrong. Please try again.", {
            variant: "error",
          });
        }
        setIsAPIProgress((prevState) => ({ ...prevState, canceling: false }));
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar("Something went wrong. Please try again.", {
          variant: "error",
        });
        setIsAPIProgress((prevState) => ({ ...prevState, canceling: false }));
      });
  };


  const confirmCancel = (
    values: FormikValues,
    errors: FormikErrors<FormikValues>,
    setFieldValue: (_fieldName: string, _value: unknown) => void,
    validateForm: (_values: FormikValues) => any,
    resetForm: (_values: FormikValues) => void
  ) => {
    setModalData({
      show: true,
      modalHeader: "Cancel Delegation",
      modalContent: (
        <div>
          <div className={styleClasses.modalContentDiv}>
            Are you sure you want to cancel Delegation {props.initialValues.request_number}?
          </div>
          <div className={styleClasses.modalTextDiv}>
            <span className={styleClasses.spanText}>{"Note: "}</span>A cancelled
            Delegation will be permanently deleted and cannot be retrieved.
          </div>
        </div>
      ),
      modalActions: (
        <div className={styleClasses.modalActionDiv}>
          <CustomButton
            variant="contained"
            actionType="secondary"
            onClick={() => {
              handleCancelRequest(requestId);
            }}
            disabled={isAPIProgress.canceling || isAPIProgress.saving}
          >
            {"Don't Save"}
          </CustomButton>
          <CustomButton
            variant="contained"
            actionType="primary"
            disableMargin
            onClick={() => {
              handleSave(
                values,
                errors,
                setFieldValue,
                validateForm,
                resetForm,
                {
                  shouldExit: true,
                  switchStep: -1,
                }
              );
            }}
            disabled={isAPIProgress.canceling || isAPIProgress.saving}
          >
            Save as Draft
          </CustomButton>
        </div>
      ),
    });
  };

  //Start of  role Name duplication pop-up
  const duplicateRoleNameCheck = () => {
    setModalData({
      show: true,
      modalHeader: 'Note',
      modalContent:
        (
          <div>
            <div className={styleClasses.modalContentDiv}>
              There are two identical records for the same role. Either delete the duplicate record or update its values
            </div>
          </div>

        ),
      modalActions: (
        <div className={styleClasses.modalActionDiv}>
          <CustomButton
            variant="contained"
            actionType="secondary"
            onClick={() => handleModalClose()}
            disabled={isAPIProgress.canceling || isAPIProgress.saving}
          >
            Ok
          </CustomButton>
        </div>
      )
    })
  }
  //End of role Name duplication pop-up

  //Start of  user duplication pop-up
  const duplicateUserCheck = () => {
    setModalData({
      show: true,
      modalHeader: 'Note',
      modalContent:
        (
          <div>
            <div className={styleClasses.modalContentDiv}>
              There are two identical records for the same user. Either delete the duplicate record or update its values
            </div>
          </div>

        ),
      modalActions: (
        <div className={styleClasses.modalActionDiv}>
          <CustomButton
            variant="contained"
            actionType="secondary"
            onClick={() => handleModalClose()}
            disabled={isAPIProgress.canceling || isAPIProgress.saving}
          >
            Ok
          </CustomButton>
        </div>
      )
    })
  }
  //End of user duplication pop-up
  const ConfirmApproveHandler = () => {

    modalClickCheck = {
      rejectStatus: false,
      approveStatus: true,
      remediationStatus: false,
    }
    setModalData({
      show: true,
      modalHeader: 'Approve Delegation',
      modalContent:
        (
          <div>
            <div className={styleClasses.modalContentDiv}>
              Are you sure you want to approve Delegation &nbsp;{props.initialValues.request_number} and move it to next approval stage without any update?
            </div>
            <div className={styleClasses.modalTextDiv}>
              <span className={styleClasses.spanText}>
                {'Note: '}
              </span>
              If you require the User to update the Delegation with further information, please request this using the <b>Request Remediation</b> button at the bottom of the screen.
            </div>
          </div>

        ),
      modalActions: (
        <div className={styleClasses.modalActionDiv}>
          <CustomButton
            variant="contained"
            actionType="secondary"
            onClick={() => handleModalClose()}
            disabled={isAPIProgress.canceling || isAPIProgress.saving}
          >
            No
          </CustomButton>
          <CustomButton
            variant="contained"
            actionType="primary"
            disableMargin
            onClick={statusUpdateDelegation}
            disabled={isAPIProgress.canceling || isAPIProgress.saving}
          >
            Approve
          </CustomButton>
        </div>
      )
    })
  }
  // End of Approve modal pop-up
  const rejectHandler = () => {
    const rejectPostBody = {
      request_id: requestId,
      status: 2,
    };

    postRemediationStatus(rejectPostBody)
      .then((success: any) => {
        enqueueSnackbar(
          `Delegation request ${props.initialValues.request_number} rejected successfully!.`,
          { variant: "success" }
        );
        history.push("/myApprovalTasks");
      })
      .catch((error: any) => error);
  };

  const remdiationHandler = () => {
    const remediationPostBody = {
      request_id: requestId,
      status: 7,
    };

    postRemediationStatus(remediationPostBody)
      .then((success: any) => {
        enqueueSnackbar(
          `Remediation Requested successfully for Delegation request ${props.initialValues.request_number}.`,
          { variant: "success" }
        );
        history.push("/myApprovalTasks");
      })
      .catch((error: any) => error);
  };
  const getButtonLabel = (isSubmitting: boolean) => {
    if (isSubmitting) return "Submitting";
    if (isLastStep()) return "Submit";
    return "Next";
  };
  // const () => {
  //   history.push('/myApprovalTasks')
  // } = () => {
  //   setOpenModalAp(false);
  // };
  const handleCloseReject = () => {
    setOpenModalReject(false);
  };
  // Reject Delegation in approval stage
  const ConfirmRejectReview = () => {
    modalClickCheck = {
      rejectStatus: true,
      remediationStatus: false,
      approveStatus: false,

    }
    setModalData({
      show: true,
      modalHeader: 'Reject Delegation',
      modalContent:
        (
          <div>
            <div className={styleClasses.modalContentDiv}>
              Are you sure you want to reject Delegation&nbsp;
              {props.initialValues.request_number}
              ?
            </div>
            <div className={styleClasses.modalTextDiv}>
              <span className={styleClasses.spanText}>
                {'Note: '}
              </span>
              Rejecting a Delegation is a permanent action and cannot be undone. If you require the user to update the Delegation with further information, please request this using the <b>Request Remediation</b> button at the bottom of the screen.
            </div>
          </div>
        ),
      modalActions: (
        <div className={styleClasses.modalActionDiv}>
          <CustomButton
            variant="contained"
            actionType="secondary"
            onClick={() => statusUpdateDelegation()}
            disabled={isAPIProgress.canceling || isAPIProgress.saving}
          >
            Reject
          </CustomButton>
          <CustomButton
            variant="contained"
            actionType="primary"
            disableMargin
            onClick={handleModalClose}
            disabled={isAPIProgress.canceling || isAPIProgress.saving}
          >
            No
          </CustomButton>
        </div>
      )
    })
  }

  // End Reject Delegation

  const updateStatusImplementation = (
    values: FormikValues,
    erros: FormikErrors<FormikValues>,) => {
    const postBody = {
      request_id: requestId,
      status: 8,
      request_type: "Delegation",
    }
    // postStepOneData(postbodystep1).then((res) => res).catch((err) => err);

    updateImplementerStatus(postBody).then((res) => {
      enqueueSnackbar(`Implementation request ${props.initialValues.request_number} completed successfully`, {
        variant: "success",
      });
      history.push("/myImplementationTasks");
    }).catch((err) => err);
    handleModalClose();

  }

  useEffect(() => {
    if (!checkIsDisabledImplementation() && routeCheckForPendingImplementation()) {
      setModalData({
        show: true,
        modalHeader: 'Note',
        modalContent:
          (
            <div className={styleClasses.modalContentDiv}>
              <div className={styleClasses.modalTextDiv}>

                Please review this Delegation from beginning to end. This will enable full understanding of the requirements for implementation. It is possible to navigate to any part of the Delegation using the menu on the left-hand side.
              </div>
              <div className={styleClasses.modalTextDiv}>
                Comments, screenshots and scripts can be added during the implementation steps that follow.
              </div>
            </div>
          ),
        modalActions: (
          <div className={styleClasses.modalActionDiv}>
            {/* <CustomButton
            variant="contained"
            actionType="secondary"
            onClick={() => handleModalClose()}
            disabled={isAPIProgress.canceling || isAPIProgress.saving}
          >
            Cancel
          </CustomButton> */}
            <CustomButton
              variant="contained"
              actionType="primary"
              disableMargin
              onClick={handleModalClose}
              disabled={isAPIProgress.canceling || isAPIProgress.saving}
            >
              Ok
            </CustomButton>
          </div>
        )
      })
    }
  }, []);


  const ConfirmRemediationReview = (
    values: FormikValues,
    errors: FormikErrors<FormikValues>,
    validateForm: (_values: FormikValues) => any,
    resetForm: (_values: FormikValues) => void,
    options: { shouldExit: boolean, switchStep: number },
  ) => {
    modalClickCheck = {
      rejectStatus: false,
      remediationStatus: true,
      approveStatus: false,
    }
    if (isEmpty(postBodyData)) {
      setModalData({
        show: true,
        modalHeader: 'Note',
        modalContent:
          (
            <div className={styleClasses.modalTextDiv}>
              No comments have been added to this Delegation to explain why remediation is required. <br /> Please return to the Delegation and add comments using the speech bubble icon on the top right of the Delegation view. Comments can be added in each section to explain what additional information is required.
            </div>

          ),
        modalActions: (
          <div className={styleClasses.modalActionDiv}>

            <CustomButton
              variant="contained"
              actionType="primary"
              disableMargin
              onClick={() => handleModalClose()}
            >
              Ok
            </CustomButton>
          </div>
        )
      })
    } else {
      const appCheckingLastComments = postBodyData.map((item) => {
        // console.log("item@@@@@1234", item)
        return (item.updated_by === loginsyncupdata.email) && (item.current_remediation === props.initialValues.remediation_count) && (item.request_to_update === props.initialValues.request_to_update_count);
      })

      if (appCheckingLastComments.includes(true)) {
        statusUpdateDelegation();
        return;
      } else {
        setModalData({
          show: true,
          modalHeader: 'Note',
          modalContent:
            (
              <div className={styleClasses.modalTextDiv}>
                No comments have been added to this Delegation to explain why remediation is required. <br /> Please return to the Delegation and add comments using the speech bubble icon on the top right of the Delegation view. Comments can be added in each section to explain what additional information is required.
              </div>
            ),
          modalActions: (
            <div className={styleClasses.modalActionDiv}>

              <CustomButton
                variant="contained"
                actionType="primary"
                disableMargin
                onClick={() => handleModalClose()}
              >
                Ok
              </CustomButton>
            </div>
          )
        })
      }
    }
  }

  const confirmCancelReview = (
    values: FormikValues,
    errors: FormikErrors<FormikValues>,
    setFieldValue: (_fieldName: string, _value: unknown) => void,
    validateForm: (_values: FormikValues) => any,
    resetForm: (_values: FormikValues) => void,
  ) => {
    setModalData({
      show: true,
      modalHeader: 'Cancel review',
      modalContent: (
        < div >
          <div className={styleClasses.modalContentDiv}>
            Are you sure you want to cancel reviewing
            Delegation request {props.initialValues.request_number}?
          </div>
          <div className={styleClasses.modalTextDiv}>
            <span className={styleClasses.spanText}>
              {"Note: "}
            </span>
            The request will revert to the Pending Approval
            stage. If you are unable to review at this time,
            you can delegate to another approver, via the
            Approver Summary page. Should you continue to
            review this Delegation, you will be able to either
            approve the request or request remediation of the
            Delegation.
          </div>
        </div >
      ),
      modalActions: (
        < div className={styleClasses.modalActionDiv} >
          <div style={{ paddingRight: '5px' }}>
            <CustomButton
              variant="outlined"
              actionType="secondary"
              disableMargin
              onClick={() => {
                history.push('/myApprovalTasks')
              }}
              size="small"
            >
              Cancel Review
            </CustomButton>
          </div>

          <CustomButton
            variant="contained"
            actionType="primary"
            disableMargin
            onClick={() => {
              handleModalClose()
            }}
            size="small"
          >
            Continue Review
          </CustomButton>
        </div >
      )
    })

  }
  // const implementationModalHandler = () => {
  //   setModalData({
  //     show: true,
  //     modalHeader: 'Complete Delegation Request',
  //     modalContent:
  //       (
  //         <div>
  //           <div className={styleClasses.modalContentDiv}>
  //             Delegation request  #{requestId} is implemented successfully! Please click on 'Complete Delegation' to mark its status as completed.
  //           </div>
  //           {/* <div className={styleClasses.modalTextDiv}>
  //             <span className={styleClasses.spanText}>
  //               {'Note: '}
  //             </span>
  //             If you require the User to update the PAR with further information, please request this using the <b>Request Remediation</b> button at the bottom of the screen.
  //           </div> */}
  //         </div>

  //       ),
  //     modalActions: (
  //       <div className={styleClasses.modalActionDiv}>
  //         <CustomButton
  //           variant="contained"
  //           actionType="secondary"
  //           onClick={() => handleModalClose()}
  //           disabled={isAPIProgress.canceling || isAPIProgress.saving}
  //         >
  //           Cancel
  //         </CustomButton>
  //         <CustomButton
  //           variant="contained"
  //           actionType="primary"
  //           disableMargin
  //           onClick={() => null}
  //           disabled={isAPIProgress.canceling || isAPIProgress.saving}
  //         >
  //           Complete Delegation
  //         </CustomButton>
  //       </div>
  //     )
  //   })
  // }
  const ConfirmImplementationHandler = (values: any, erros: any) => {
    setModalData({
      show: true,
      modalHeader: 'Implement Delegation',
      modalContent:
        (
          <div>
            <div className={modalClasses.modalContentDiv}>
              <div>
                {`Implementation task list for Delegation ${values.request_number} has been completed successfully.`}
              </div>
            </div>
            <div className={modalClasses.modalTextDiv}>
              Privileged Access Requests have been triggered by the application and sent to those listed in the Delegation form for completion.  Once these Privileged Access Requests are approved, further tasks will be assigned to the Implementation team.
            </div>
          </div>
        ),
      modalActions: (
        <div className={styleClasses.modalActionDiv}>
          {/* <CustomButton
            variant="contained"
            actionType="secondary"
            onClick={() => handleModalClose()}
            disabled={isAPIProgress.canceling || isAPIProgress.saving}
          >
            Cancel
          </CustomButton> */}
          <CustomButton
            variant="contained"
            actionType="primary"
            disableMargin
            onClick={() => updateStatusImplementation(values, erros)}
            disabled={isAPIProgress.canceling || isAPIProgress.saving}
          >
            Ok
          </CustomButton>
        </div>
      )
    })
  }

  const modalPoper = () => {
    return (
      <CustomModal
        modalHeader="Reject Delegation Request"
        modalContent={
          <div>
            <div className={styleClasses.modalContentDiv}>
              Are you sure you want to reject the Delegation request
              {props.initialValues.request_number}?
            </div>
            <div className={styleClasses.modalTextDiv}>
              <span className={styleClasses.spanText}>{"Note: "}</span>
              Rejecting a Delegation request is a permanent action. If any
              update is required then request remediation.
            </div>
          </div>
        }
        modalAction={
          <div className={styleClasses.modalActionDiv}>
            <CustomButton
              variant="outlined"
              actionType="secondary"
              disableMargin
              onClick={rejectHandler}
              size="small"
            >
              Reject
            </CustomButton>
            <CustomButton
              variant="contained"
              actionType="primary"
              disableMargin
              // onClick={editPostHandler}
              size="small"
              onClick={handleCloseReject}
            >
              Cancel
            </CustomButton>
          </div>
        }
        open={openModalReject}
        onClose={handleCloseReject}
      />
    );
  };
  const confirmCancelImplementation = () => {
    setModalData({
      show: true,
      modalHeader: 'Cancel implementation',
      modalContent:
        (
          <div>
            <div className={styleClasses.modalContentDiv}>
              Are you sure you want to cancel implementing the Delegation request {props.initialValues.request_number} ?
            </div>
            <div className={styleClasses.modalTextDiv}>
              <span className={styleClasses.spanText}>
                {'Note: '}
              </span>
              The request will still appear in pending implementation
            </div>
          </div>

        ),
      modalActions: (
        <div className={styleClasses.modalActionDiv}>
          <CustomButton
            variant="contained"
            actionType="secondary"
            onClick={() => {
              history.push("/myImplementationTasks");
            }}
            disabled={isAPIProgress.canceling || isAPIProgress.saving}
          >
            Cancel Review
          </CustomButton>
          <CustomButton
            variant="contained"
            actionType="primary"
            disableMargin
            onClick={() => handleModalClose()}
            disabled={isAPIProgress.canceling || isAPIProgress.saving}
          >
            Continue Review
          </CustomButton>
        </div>
      )
    })
  }
  return (
    <>
      <Formik
        {...props}
        validationSchema={currentChild.props.validationSchema}
        onSubmit={async (
          values: FormikValues,
          helpers: FormikHelpers<FormikValues>
        ) => {
          helpers.setSubmitting(true);
          await props.onSubmit(values, helpers);
          helpers.setSubmitting(false);
        }}
        validateOnChange={false}
      >
        {({
          isSubmitting,
          values,
          setFieldValue,
          errors,
          validateForm,
          submitForm,
          dirty,
          resetForm,
        }) => (
          <>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={3}>
                <div className={styleClasses.leftSection}>
                  <div className={styleClasses.stepperContainer}>
                    <div className={styleClasses.stepperScoller}>
                      <VerticalLinearStepper
                        activeStep={activeStep}
                        stepsData={((props.initialValues.is_implemented && checkIsDisabledImplementation()) || routeCheckForPendingImplementation()) ? stepsDataImplementation : stepsData}
                        setActiveStep={setActiveStep}
                        requestStatus={requestData.status_label}
                      />
                    </div>
                  </div>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    style={{ margin: "0  20px" }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={9}>
                <div
                  style={{
                    maxHeight: 'calc(100vh - 108px - 68.5px - 60px)',
                    overflowX: 'scroll',
                    paddingBottom: "30px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                >
                  <div className={styleClasses.reasonLabel}>
                    {`${props.initialValues.request_number} : ${requestData?.request_purpose || ""}`}
                  </div>
                  {currentChild}
                </div>
              </Grid>
            </Grid>
            <Footer>
              <div>
                <div className={styleClasses.footerActions}>
                  {activeStep > 0 && (
                    <CustomButton
                      variant="contained"
                      actionType="secondary"
                      disableMargin
                      // disabled={approver ? true : (checkIsDisabled() || isSubmitting || activeStep === 0)}
                      onClick={() => setActiveStep((s) => s - 1)}
                    >
                      Prev
                    </CustomButton>
                  )}
                  {
                    pendingApprovalCheck() && routeCheck() &&
                    <CustomButton
                      variant="contained"
                      actionType="secondary"
                      disableMargin
                      // disabled={approver ? true : (checkIsDisabled() || isSubmitting || activeStep === 0)}
                      onClick={ConfirmRejectReview}
                    >
                      Reject
                    </CustomButton>}
                  {/* {location.pathname.slice(1, 4).toLocaleLowerCase() ===
                    "myd" &&
                    requestData.status_label === "Pending Approval" && (
                      <>
                        <CustomButton
                          variant="contained"
                          actionType="secondary"
                          disableMargin
                          // disabled={approver ? true : (checkIsDisabled() || isSubmitting || activeStep === 0)}
                          onClick={rejectModalHandler}
                        >
                          Reject
                        </CustomButton>
                        {modalPoper()}
                      </>
                    )} */}
                  {/* {location.pathname.slice(1, 4).toLocaleLowerCase() ===
                    "mya" && (
                      <>
                        <CustomButton
                          variant="contained"
                          actionType="secondary"
                          disableMargin
                          // disabled={approver ? true : (checkIsDisabled() || isSubmitting || activeStep === 0)}
                          onClick={rejectModalHandler}
                        >
                          Reject
                        </CustomButton>
                        {modalPoper()}
                      </>
                    )} */}
                </div>
                <div className={`${styleClasses.footerActionsRight} ${styleClasses.footerActions}`} >
                  {routeCheck() ? (
                    <>
                      <CustomButton
                        variant="contained"
                        actionType="secondary"
                        disableMargin
                        // disabled={isAPIProgress.saving || checkIsDisabled()}
                        onClick={() => {
                          confirmCancelReview(values, errors, setFieldValue, validateForm, resetForm)
                        }}
                      >
                        Cancel
                      </CustomButton>
                    </>
                  ) : (requestData.del_request_id !== null && requestData.status_label !== 'Draft') ?
                    (
                      () => null
                    )
                    //: (
                    //   <CustomButton
                    //     variant="contained"
                    //     actionType="secondary"
                    //     disableMargin
                    //     // disabled={isAPIProgress.saving || checkIsDisabled()}
                    //     onClick={() => {
                    //       confirmCancelImplementation();
                    //       console.log(location, "location");
                    //     }}
                    //   >
                    //     Cancel
                    //   </CustomButton>
                    // ) 
                    : (
                      !routeCheckForApprovalSummary() && <CustomButton
                        variant="contained"
                        actionType="secondary"
                        disableMargin
                        // disabled={isAPIProgress.saving || checkIsDisabled()}
                        onClick={() => {
                          confirmCancel(
                            values,
                            errors,
                            setFieldValue,
                            validateForm,
                            resetForm
                          );
                        }}
                      >
                        Cancel
                      </CustomButton>
                    )
                  }

                  {!routeCheckForApprovalSummary() && ((requestData.status_label == 'Draft') || (requestData.status_label == 'Remediation Requested')) &&
                    <CustomButton
                      variant="contained"
                      actionType="secondary"
                      disableMargin
                      disabled={isAPIProgress.saving}
                      name="onSave"
                      onClick={() => {
                        handleSave(
                          values,
                          errors,
                          setFieldValue,
                          validateForm,
                          resetForm,
                          {
                            shouldExit: false,
                            switchStep: activeStep,
                          },
                        );
                      }}
                    >
                      {isAPIProgress.saving ? 'Saving..' : 'Save'}

                    </CustomButton>
                  }
                  {pendingApprovalCheck() && routeCheck() && <CustomButton
                    variant="contained"
                    actionType="secondary"
                    disableMargin
                    // disabled={approver ? true : (checkIsDisabled() || isSubmitting || activeStep === 0)}
                    // disabled={!roleGroupArray.includes('Approver')}

                    onClick={() => ConfirmRemediationReview(
                      values,
                      errors,
                      validateForm,
                      resetForm,
                      {
                        shouldExit: false,
                        switchStep: activeStep,
                      },
                    )}
                  >
                    Request Remediation
                  </CustomButton>}
                  {location.pathname.slice(1, 4).toLocaleLowerCase() ===
                    "myd" &&
                    requestData.status_label === "Pending Approval" && (
                      <CustomButton
                        variant="contained"
                        actionType="secondary"
                        disableMargin
                        // disabled={approver ? true : (checkIsDisabled() || isSubmitting || activeStep === 0)}
                        disabled={!roleGroupArray.includes("Administrator")}
                        onClick={() => ConfirmRemediationReview(
                          values,
                          errors,
                          validateForm,
                          resetForm,
                          {
                            shouldExit: false,
                            switchStep: activeStep,
                          },
                        )}
                      >
                        Request Remediation
                      </CustomButton>
                    )}
                  {
                    checkIsDisabled() ?
                      (!routeCheckForPendingImplementation() &&
                        <CustomButton
                          variant="contained"
                          actionType="primary"
                          disableMargin
                          disabled={(pendingApprovalCheck() && routeCheck()) ? false : isLastStep()}
                          onClick={isLastStep() ? ConfirmApproveHandler : () => setActiveStep((s) => s + 1)}
                        >
                          {(pendingApprovalCheck() && routeCheck() && isLastStep()) ? `Approve` : ` Next`}
                        </CustomButton>
                      ) : (
                        <CustomButton
                          variant="contained"
                          actionType="primary"
                          disableMargin
                          disabled={checkIsDisabled()
                            || (isSubmitting || isAPIProgress.saving)
                            || (isLastStep() && (values.finalCheck ? false : true))
                          }
                          onClick={() => {
                            if (!isLastStep()) {
                              handleSaveOnNext(
                                values,
                                errors,
                                setFieldValue,
                                validateForm,
                                resetForm,
                                {
                                  shouldExit: false,
                                  switchStep: activeStep + 1,
                                },
                              );
                            } else {
                              submitForm();
                            }
                          }}
                        >
                          {getButtonLabel(isSubmitting)}
                        </CustomButton>
                      )
                  }
                  {
                    routeCheckForPendingImplementation() ? (
                      <CustomButton
                        variant="contained"
                        actionType="primary"
                        disableMargin
                        disabled={isLastStep() && (values.mark_step_secondstep ? false : true)}
                        onClick={isLastStep() ? () => ConfirmImplementationHandler(values, errors) : () => setActiveStep((s) => s + 1)}
                      >
                        {(routeCheckForPendingImplementation() && isLastStep()) ? `Implement Delegation` : ` Next`}
                      </CustomButton>
                    )
                      : routeCheckForPendingImplementation() && (
                        <CustomButton
                          variant="contained"
                          actionType="primary"
                          disableMargin
                          disabled={
                            checkIsDisabled() ||
                            isSubmitting ||
                            isAPIProgress.saving ||
                            (isLastStep() && !values.finalCheck)
                          }
                          onClick={() => {
                            if (!isLastStep()) {
                              handleSave(
                                values,
                                errors,
                                setFieldValue,
                                validateForm,
                                resetForm,
                                {
                                  shouldExit: false,
                                  switchStep: activeStep + 1,
                                }
                              );
                            } else {
                              submitForm();
                            }
                          }}
                        >
                          {getButtonLabel(isSubmitting)}
                        </CustomButton>
                      )}
                </div>
              </div>
            </Footer>
            <Prompt
              when={dirty && !checkIsDisabled()}
              message={(location) =>
                `Are you sure you want to go to ${location.pathname}`
              }
            />
          </>
        )}
      </Formik >
      {
        modalData.show && (
          <CustomModal
            modalHeader={modalData.modalHeader}
            open={modalData.show}
            onClose={handleModalClose}
            modalAction={modalData.modalActions}
            modalContent={modalData.modalContent}
          />
        )
      }
    </>
  );
}
