import { createSlice, current } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


interface IAdImplementation {
    is_implemented: boolean,
    is_step_complete: boolean,
    on_premise_id: number,
    provide_service_numbe: string
}

export const adImplementationOnprem = createSlice({
    name: 'adOnpremTasklist',
    initialState: [{}],
    reducers: {
        adImplementationOnpremTasklist: (state, action: PayloadAction<any>) => {
            return [{ ...action.payload }]
        },
    }
});

export const { adImplementationOnpremTasklist } = adImplementationOnprem.actions;
export default adImplementationOnprem.reducer;