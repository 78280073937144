import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import FormikControl from '../../../formik-conrtols/FormikControl';
import FieldLabel from '../../../field-label';
import { format, isDate } from 'date-fns';
import { jobTitleDescription } from '../tooltip';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../appStore/store';
import { parStyles } from '../../useStyles';
import HistoryTable from '../../../../modules/delegation/comments/historyTable';
import CommentSubmission from '../../../../modules/delegation/comments/commentSubmission';
import { routeCheck, routeCheckDelegation, routeCheckForAdminSummary, routeCheckForApprovalSummary, routeCheckForImplementationSummary, routeCheckForPendingApproval } from './routingConditional';

function JobTitleExplanation({ isDisabled }: { isDisabled: boolean }) {
  const formikCtx: any = useFormikContext();
  const { fieldHistoryDelegationCommentsData } = useSelector((state: RootState) => state);
  const { submitDelegationCommentData } = useSelector((state: RootState) => state);
  const styleClasses = parStyles();
  const remediated_by = formikCtx.values.remediation_by
  const remediationCount = formikCtx.values.remediation_count;
  const checkForEditReviewFlow = () => {
    return routeCheckDelegation() || routeCheckForAdminSummary() || routeCheckForImplementationSummary() || routeCheckForApprovalSummary()
  }
  const formatDateInside = (dateValue: any) => (dateValue && isDate(new Date(dateValue)) ? format(new Date(dateValue), 'MMM dd, yyyy') : '');

  return (
    <>
      <Grid container>
        <Grid item xs={5}>
          <div>
            <FieldLabel Title="Job title and description" tooltipMsg={jobTitleDescription} required />
          </div>
          <div style={{ color: '#A7A7A7', fontFamily: 'NotoSerif-Regular', fontSize: '10px', marginTop: '-5px', paddingBottom: '10px' }}>
            {/* @ts-ignore */}
            {(fieldHistoryDelegationCommentsData[4].Job_title_and_description?.length > 0) && `Updated on ${formatDateInside(fieldHistoryDelegationCommentsData[4].Job_title_and_description[fieldHistoryDelegationCommentsData[4].Job_title_and_description?.length - 1].last_updated)}`}
          </div>
        </Grid>
        <Grid item xs={5}>
        </Grid>
        <Grid item xs={2}>
          {(checkForEditReviewFlow() && remediationCount > 0) ? (
            <div className={styleClasses.mainSectionDiv}>
              <HistoryTable
                parId={formikCtx.values.request_id}
                section="Job_title_and_description"
                subSection=''
                data={fieldHistoryDelegationCommentsData.length > 0 ? fieldHistoryDelegationCommentsData[4].Job_title_and_description : null}
              />

              <CommentSubmission
                sectionName='Job_title_and_description'
                subSection=''
                updateType='Select update'
                remediation_by={remediated_by}
                data={submitDelegationCommentData.length > 0 ? submitDelegationCommentData[4].Job_title_and_description : null}
              />
            </div>
          ) : routeCheckForPendingApproval() && (
            <div className={styleClasses.mainSectionDiv}>
              <HistoryTable
                parId={formikCtx.values.request_id}
                section="Job_title_and_description"
                subSection=''
                data={fieldHistoryDelegationCommentsData.length > 0 ? fieldHistoryDelegationCommentsData[4].Job_title_and_description : null}
              />

              <CommentSubmission
                sectionName='Job_title_and_description'
                subSection=''
                updateType='Select update'
                remediation_by={remediated_by}
                data={submitDelegationCommentData.length > 0 ? submitDelegationCommentData[4].Job_title_and_description : null}
              />
            </div>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item sm={5}>
          <FormikControl
            disabled={isDisabled}
            control="textfield"
            label="Job title"
            name="del_job_title"
            error={formikCtx.errors.del_job_title}
            required
          />
        </Grid>
        <Grid item sm={12}>
          <FormikControl
            disabled={isDisabled}
            control="textfield"
            label="Job description"
            name="del_job_explanation"
            multiline
            minRows={5}
            error={formikCtx.errors.del_job_explanation}
            required
          />
        </Grid>
      </Grid>
    </>
  );
}
export default JobTitleExplanation;
