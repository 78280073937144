import React from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import FormikControl from '../../../formik-conrtols/FormikControl';
import FieldLabel from '../../../field-label';
import { RootState } from '../../../../appStore/store';
import { Box, Typography } from '@mui/material';
import { ChooseServiceAccountFunction, accountjustificationTooltip, chooseAccountType, interactiveLogin, justificaionForInteractiveDesign } from '../tooltips';
import { parStyles, sarUseStyles } from '../../useStyles';
import { format, isDate } from 'date-fns';
import HistoryTable from '../../../../modules/sar/comments/historyTable';
import CommentSubmission from '../../../../modules/sar/comments/commentSubmission';
import { routeCheck, routeCheckForAdminSummary, routeCheckForApprovalSummary, routeCheckForImplementationSummary, routeCheckForPendingApproval } from './routingConditional';

function AccountType({ isDisabled }: { isDisabled: boolean }) {
    const classes = sarUseStyles();
    const styleClasses = parStyles();
    const formikCtx: any = useFormikContext();
    const formatDateInside = (dateValue: any) => (dateValue && isDate(new Date(dateValue)) ? format(new Date(dateValue), 'MMM dd, yyyy') : '');
    const remediated_by = formikCtx.values.remediation_by
    const remediationCount = formikCtx.values.remediation_count;
    const { fieldHistorySarCommentsData } = useSelector((state: RootState) => state);
    const { submitSarCommentData } = useSelector((state: RootState) => state);

    const checkForEditReviewFlow = () => {
        return routeCheck() || routeCheckForAdminSummary() || routeCheckForImplementationSummary() || routeCheckForApprovalSummary()
    }
    return (
        <>

            <Grid container paddingTop={'12px'} >
                <Grid item xs={10}>
                    <>
                        <FieldLabel Title="Choose account type" tooltipMsg={chooseAccountType} required />
                    </>
                    <>
                        <div className={styleClasses.updatedByStyle}>
                            {/* @ts-ignore */}
                            {(fieldHistorySarCommentsData[7].Choose_account_type?.length > 0) && `Updated on ${formatDateInside(fieldHistorySarCommentsData[7].Choose_account_type[fieldHistorySarCommentsData[7].Choose_account_type?.length - 1].last_updated)}`}
                        </div>
                    </>
                </Grid>
                <Grid item xs={2}>
                    {(checkForEditReviewFlow() && remediationCount > 0) ? (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="Choose_account_type"
                                subSection=''
                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[7].Choose_account_type : null}
                            />
                            <CommentSubmission
                                sectionName="Choose_account_type"
                                subSection=''
                                updateType='Select update'
                                remediation_by={remediated_by}
                                data={submitSarCommentData.length > 0 ? submitSarCommentData[7].Choose_account_type : null}
                            />
                        </div>
                    ) : routeCheckForPendingApproval() && (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="Choose_account_type"
                                subSection=''
                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[7].Choose_account_type : null}
                            />
                            <CommentSubmission
                                sectionName='Choose_account_type'
                                subSection=''
                                updateType='Select update'
                                remediation_by={remediated_by}
                                data={submitSarCommentData.length > 0 ? submitSarCommentData[7].Choose_account_type : null}
                            />
                        </div>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Box style={{ marginBottom: '-10px' }}>
                        <Box className={classes.headerTitle}> Standard service account</Box>
                        <Typography className={classes.titleForheader} >This should be used for accounts requiring :</Typography>
                        <ul className={classes.listing}>
                            <li>logon as batch </li>
                            <li>logon as service </li>
                            <li>password resets every 60 days </li>
                            <li>no access permissions </li>
                        </ul>
                        <Box className={classes.headerTitleForComplex}> Complex service account</Box>
                        <Typography className={classes.titleForheader}>This should be used for accounts requiring :</Typography>
                        <ul className={classes.listing}>
                            <li>password to expire more than 60 days</li>
                            <li>non-expiring password </li>
                            <li>local admin permissions</li>
                            <li>extra security</li>
                            <li>permissions logon interactively</li>
                            <li>Delegation and SPNs</li>
                        </ul>
                        <Typography className={classes.subTitle}>Please select which service account type is required: </Typography>
                        <Grid item xs={3}>
                            <FormikControl
                                disabled={isDisabled}
                                control="radio"
                                name="sar_is_complex_service_account"
                                radioGrouplabels={[{
                                    label: 'Standard service account',
                                    value: false,
                                }, {
                                    label: 'Complex service account',
                                    value: true,
                                }]}
                                isInline
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    formikCtx.setFieldValue('sar_is_complex_service_account', e.target.value === 'true');
                                }}
                            />
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={9}>

                </Grid>
            </Grid >
            {
                formikCtx.values.sar_is_complex_service_account &&
                <>
                    <Grid container paddingTop={'12px'} >
                        <Grid item xs={10}>
                            <>
                                <FieldLabel Title="Does this service account require interactive logon?" tooltipMsg={interactiveLogin} required />
                            </>
                            <>
                                <div className={styleClasses.updatedByStyle}>
                                    {/* @ts-ignore */}
                                    {(fieldHistorySarCommentsData[10].Require_interactive_logon?.length > 0) && `Updated on ${formatDateInside(fieldHistorySarCommentsData[10].Require_interactive_logon[fieldHistorySarCommentsData[10].Require_interactive_logon?.length - 1].last_updated)}`}
                                </div>
                            </>
                        </Grid>

                        <Grid item xs={2}>
                            {(checkForEditReviewFlow() && remediationCount > 0) ? (
                                <div className={styleClasses.mainSectionDiv}>
                                    <HistoryTable
                                        parId={formikCtx.values.request_id}
                                        section="Require_interactive_logon"
                                        subSection=''
                                        data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[10].Require_interactive_logon : null}
                                    />
                                    <CommentSubmission
                                        sectionName='Require_interactive_logon'
                                        subSection=''
                                        updateType='Select update'
                                        remediation_by={remediated_by}
                                        data={submitSarCommentData.length > 0 ? submitSarCommentData[10].Require_interactive_logon : null}
                                    />
                                </div>
                            ) : routeCheckForPendingApproval() && (
                                <div className={styleClasses.mainSectionDiv}>
                                    <HistoryTable
                                        parId={formikCtx.values.request_id}
                                        section="Require_interactive_logon"
                                        subSection=''
                                        data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[10].Require_interactive_logon : null}
                                    />
                                    <CommentSubmission
                                        sectionName='Require_interactive_logon'
                                        subSection=''
                                        updateType='Select update'
                                        remediation_by={remediated_by}
                                        data={submitSarCommentData.length > 0 ? submitSarCommentData[10].Require_interactive_logon : null}
                                    />
                                </div>
                            )}
                        </Grid>
                    </Grid>

                    <Grid item xs={3}>
                        <FormikControl
                            disabled={isDisabled}
                            control="radio"
                            name="sar_does_require_interactive_logon"
                            radioGrouplabels={[{
                                label: 'Yes',
                                value: true,
                            }, {
                                label: 'No',
                                value: false,
                            }]}
                            isInline
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                formikCtx.setFieldValue('sar_does_require_interactive_logon', e.target.value === 'true');
                            }}
                        />
                    </Grid>
                    <Grid item xs={9}>

                    </Grid>
                    {formikCtx.values.sar_does_require_interactive_logon &&
                        <>
                            <Grid container paddingTop={'12px'} >
                                <Grid item xs={10}>
                                    <FieldLabel Title="Justification for interactive logon" tooltipMsg={justificaionForInteractiveDesign} required />
                                </Grid>

                                <Grid item xs={2}>
                                    {/* {(checkForEditReviewFlow() && remediationCount > 0) ? (
                                        <div className={styleClasses.mainSectionDiv}>
                                            <HistoryTable
                                                parId={formikCtx.values.request_id}
                                                section="Team_details"
                                                subSection=''
                                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[10].Require_interactive_logon : null}
                                            />
                                            <CommentSubmission
                                                sectionName='Team_details'
                                                subSection=''
                                                updateType='Select update'
                                                remediation_by={remediated_by}
                                                data={submitSarCommentData.length > 0 ? submitSarCommentData[10].Require_interactive_logon : null}
                                            />
                                        </div>
                                    ) : routeCheckForPendingApproval() && (
                                        <div className={styleClasses.mainSectionDiv}>
                                            <HistoryTable
                                                parId={formikCtx.values.request_id}
                                                section="Team_details"
                                                subSection=''
                                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[10].Require_interactive_logon : null}
                                            />
                                            <CommentSubmission
                                                sectionName='Team_details'
                                                subSection=''
                                                updateType='Select update'
                                                remediation_by={remediated_by}
                                                data={submitSarCommentData.length > 0 ? submitSarCommentData[10].Require_interactive_logon : null}
                                            />
                                        </div>
                                    )} */}
                                </Grid>
                            </Grid>
                            <Grid container rowSpacing={3} columnSpacing={10}>
                                <Grid item xs={12}>
                                    <FormikControl
                                        control="textfield"
                                        name="sar_justification_for_interactive_logon"
                                        placeholder="Provide justification for interactive logon"
                                        multiline
                                        minRows={5}
                                        disabled={isDisabled}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    }
                </>
            }
            < Grid container paddingTop={'12px'} >
                <Grid item xs={10}>
                    <>
                        <FieldLabel Title="Choose service account function" tooltipMsg={ChooseServiceAccountFunction} required />
                    </>
                    <>
                        <div className={styleClasses.updatedByStyle}>
                            {/* @ts-ignore */}
                            {(fieldHistorySarCommentsData[8].Choose_service_account_function?.length > 0) && `Updated on ${formatDateInside(fieldHistorySarCommentsData[8].Choose_service_account_function[fieldHistorySarCommentsData[8].Choose_service_account_function?.length - 1].last_updated)}`}
                        </div>
                    </>
                </Grid>
                <Grid item xs={2}>
                    {(checkForEditReviewFlow() && remediationCount > 0) ? (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="Choose_service_account_function"
                                subSection=''
                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[8].Choose_service_account_function : null}
                            />
                            <CommentSubmission
                                sectionName='Choose_service_account_function'
                                subSection=''
                                updateType='Select update'
                                remediation_by={remediated_by}
                                data={submitSarCommentData.length > 0 ? submitSarCommentData[8].Choose_service_account_function : null}
                            />
                        </div>
                    ) : routeCheckForPendingApproval() && (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="Choose_service_account_function"
                                subSection=''
                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[8].Choose_service_account_function : null}
                            />
                            <CommentSubmission
                                sectionName='Choose_service_account_function'
                                subSection=''
                                updateType='Select update'
                                remediation_by={remediated_by}
                                data={submitSarCommentData.length > 0 ? submitSarCommentData[8].Choose_service_account_function : null}
                            />
                        </div>
                    )}
                </Grid>
            </Grid >
            <Grid container rowSpacing={3} columnSpacing={10}>
                <Grid item xs={12} className={classes.checkBox}>
                    <FormikControl
                        control="checkbox"
                        label="Logon as a service"
                        name="sar_logon_as_service"
                        required
                        disabled={isDisabled}
                    //className={classes.modelCheckboxLabel}
                    />
                    <FormikControl
                        control="checkbox"
                        label="Logon as a batch Job"
                        name="sar_logon_as_batch_job"
                        required
                        disabled={isDisabled}
                    //className={classes.modelCheckboxLabel}
                    />
                </Grid>
            </Grid>
            <Grid container paddingTop={'12px'} >
                <Grid item xs={10}>
                    <FieldLabel Title="Additional function" />
                </Grid>
                <Grid item xs={2}>
                    {/* {(checkForEditReviewFlow() && remediationCount > 0) ? (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="Additional_function"
                                subSection=''
                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[31].Additional_function : null}
                            />
                            <CommentSubmission
                                sectionName='Additional_function'
                                subSection=''
                                updateType='Select update'
                                remediation_by={remediated_by}
                                data={submitSarCommentData.length > 0 ? submitSarCommentData[31].Additional_function : null}
                            />
                        </div>
                    ) : routeCheckForPendingApproval() && (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="Additional_function"
                                subSection=''
                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[31].Additional_function : null}
                            />
                            <CommentSubmission
                                sectionName='Additional_function'
                                subSection=''
                                updateType='Select update'
                                remediation_by={remediated_by}
                                data={submitSarCommentData.length > 0 ? submitSarCommentData[31].Additional_function : null}
                            />
                        </div>
                    )} */}
                </Grid>
            </Grid>
            <Grid container rowSpacing={3} columnSpacing={10}>
                <Grid item xs={12}>
                    <FormikControl
                        control="textfield"
                        name="sar_function_other"
                        disabled={isDisabled}
                        placeholder="Provide advise for other function"
                        multiline
                        minRows={5}
                    />
                </Grid>
            </Grid>
            <Grid container paddingTop={'12px'} >
                <Grid item xs={10}>
                    <>
                        <FieldLabel Title="Account function justification" tooltipMsg={accountjustificationTooltip} required />
                    </>
                    <>
                        <div className={styleClasses.updatedByStyle}>
                            {/* @ts-ignore */}
                            {(fieldHistorySarCommentsData[9].Account_function_justification?.length > 0) && `Updated on ${formatDateInside(fieldHistorySarCommentsData[9].Account_function_justification[fieldHistorySarCommentsData[9].Account_function_justification?.length - 1].last_updated)}`}
                        </div>
                    </>
                </Grid>
                <Grid item xs={2}>
                    {(checkForEditReviewFlow() && remediationCount > 0) ? (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="Account_function_justification"
                                subSection=''
                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[9].Account_function_justification : null}
                            />
                            <CommentSubmission
                                sectionName='Account_function_justification'
                                subSection=''
                                updateType='Select update'
                                remediation_by={remediated_by}
                                data={submitSarCommentData.length > 0 ? submitSarCommentData[9].Account_function_justification : null}
                            />
                        </div>
                    ) : routeCheckForPendingApproval() && (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="Account_function_justification"
                                subSection=''
                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[9].Account_function_justification : null}
                            />
                            <CommentSubmission
                                sectionName='Account_function_justification'
                                subSection=''
                                updateType='Select update'
                                remediation_by={remediated_by}
                                data={submitSarCommentData.length > 0 ? submitSarCommentData[9].Account_function_justification : null}
                            />
                        </div>
                    )}
                </Grid>
            </Grid >
            <Grid container rowSpacing={3} columnSpacing={10}>
                <Grid item xs={12}>
                    <FormikControl
                        control="textfield"
                        name="sar_justification"
                        placeholder="Provide account function justification"
                        disabled={isDisabled}
                        multiline
                        minRows={5}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default AccountType;