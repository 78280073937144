import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import {
    Formik,
    FormikConfig,
    FormikValues,
    FormikHelpers,
    FormikErrors,
    useFormikContext,

} from 'formik';
import {
    useHistory,
    Prompt,
    useLocation,
} from 'react-router-dom';
import Divider from '@mui/material/Divider';
import { useSnackbar } from 'notistack';
import { isEmpty } from 'lodash';
import Footer from '../../footer';
import CustomButton from '../../button';
import CustomModal from '../../custom-modal';
import VerticalLinearStepper from '../../stepper';
import { FormikStepProps, IModalState, emptySchemaCheck, fieldsOfSection, fieldsOfSectionForSubsection, staticDiffernceData, stepsImplemtaionDataSar } from './formikContainerUtils';
import { formikContainerStyles } from '../useStyles';
import { stepsData } from './formikContainerUtils';
import { format, isDate } from 'date-fns';
import { cancelDelegation, postNewRequest, postSarImplementer } from '../../../api-services/delegation-module';
import { postRemediationStatus } from '../../../api-services/approval-summary';
import { postDelegationFieldHistoryData } from '../../../api-services/review-delegation';
import { RootState } from '../../../appStore/store';
import { useDispatch, useSelector } from 'react-redux';
import { routeCheckForAdminSummary, routeCheckForApprovalSummary, routeCheckForDelation, routeCheckForPendingApproval, routeCheckForPendingImplementation } from './formSteps/routingConditional';
import { updateImplementerStatus } from '../../../api-services/pending-implementation';
import { aadSarImplementationTasklist } from '../../../appStore/reducer/sarImplementertask';

export function FormikStepper({
    children,
    requestId,
    requestData,
    ...props
}: FormikConfig<FormikValues> & {
    requestId: string,
    requestData: any,
}) {
    const formikCtx: any = useFormikContext();
    let modalClickCheck = {
        rejectStatus: false,
        remediationStatus: false,
        approveStatus: false,
    }
    const formatDate = (dateValue: any) => (dateValue && isDate(new Date(dateValue)) ? format(new Date(dateValue), 'yyyy-MM-dd') : '');
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const { submitSarCommentData } = useSelector((state: RootState) => state);
    const { aadSarImplementationList } = useSelector((state: RootState) => state)
    const { loginuserdata, loginsyncupdata } = useSelector((state: RootState) => state.loginData);
    const [schemaCheck, setSchemaCheck] = useState(false);
    const [modalData, setModalData] = useState<IModalState>({
        show: false,
        modalHeader: '',
        modalContent: null,
        modalActions: null,
    });
    const styleClasses = formikContainerStyles();
    const childrenArray = React.Children.toArray(children) as React.ReactElement<FormikStepProps>[];
    const [activeStep, setActiveStep] = useState(0);
    const currentChild = childrenArray[activeStep];
    const [isAPIProgress, setIsAPIProgress] = useState({
        saving: false,
        canceling: false,
    });

    function isLastStep() {
        return activeStep === childrenArray.length - 1;
    }

    const routeCheck = () => {
        return location.pathname.slice(1, 35).toLocaleLowerCase() === 'myapprovaltasks/pendingapprovalsar'
    }

    const checkIsDisabled = () => !(props.initialValues.status_label === 'Draft' || props.initialValues.status_label !== 'Pending Approval') || routeCheckForApprovalSummary() || routeCheckForAdminSummary() || props.initialValues.status_label === 'Completed' || props.initialValues.status_label === "Approved - Pending Implementation" || props.initialValues.status_label === 'Rejected';

    const historyDataOfForm = JSON.parse(localStorage.getItem('historyDataOfMainSchemaSar') || '');

    const postBodyData = [
        // @ts-ignore
        ...submitSarCommentData[0].Function,
        // @ts-ignore
        ...submitSarCommentData[1].Justification,
        // @ts-ignore
        ...submitSarCommentData[2].Requestor_details,
        // @ts-ignore
        ...submitSarCommentData[3].Team_details,
        // @ts-ignore
        ...submitSarCommentData[4].Service_account_details,
        // @ts-ignore
        ...submitSarCommentData[5].Select_domain,
        // @ts-ignore
        ...submitSarCommentData[6].Service_account_name,
        // @ts-ignore
        ...submitSarCommentData[7].Choose_account_type,
        // @ts-ignore
        ...submitSarCommentData[8].Choose_service_account_function,
        // @ts-ignore
        ...submitSarCommentData[9].Account_function_justification,
        // @ts-ignore
        ...submitSarCommentData[10].Require_interactive_logon,
        // @ts-ignore
        ...submitSarCommentData[11].Is_single_server,
        // @ts-ignore
        ...submitSarCommentData[12].Server_hostnames,
        // @ts-ignore
        ...submitSarCommentData[13].Choose_server_tier,
        // @ts-ignore
        ...submitSarCommentData[14].User_rights_and_privileges,
        // @ts-ignore
        ...submitSarCommentData[15].Security_of_passwords,
        // @ts-ignore
        ...submitSarCommentData[16].External_And_Internal_IP_Details,
        // @ts-ignore
        ...submitSarCommentData[17].Service_account_request_spn_dele,
        // @ts-ignore
        ...submitSarCommentData[18].SPN_list,
        // @ts-ignore
        ...submitSarCommentData[19].Provide_delegation_details,
        // @ts-ignore
        ...submitSarCommentData[20].Delegation_reason,
        // @ts-ignore
        ...submitSarCommentData[21].Delegation_list,
        // @ts-ignore
        ...submitSarCommentData[22].Password_change_procedures,
        // @ts-ignore
        ...submitSarCommentData[23].Password_will_be_changed,
        // @ts-ignore
        ...submitSarCommentData[24].Default_password_expiry_policy,
        // @ts-ignore
        ...submitSarCommentData[25].Business_impact,
        // @ts-ignore
        ...submitSarCommentData[26].Risk_exposure,
        // @ts-ignore
        ...submitSarCommentData[27].Risk_of_the_service_account,
        // @ts-ignore
        ...submitSarCommentData[28].Account_was_compromised,
        // @ts-ignore
        ...submitSarCommentData[30].Justification_for_interactive_logon,
        // @ts-ignore
        ...submitSarCommentData[31].Additional_function,
        // @ts-ignore
        ...submitSarCommentData[32].Group_membership,
        // @ts-ignore
        ...submitSarCommentData[33].External_internal_ip_ranges,
        // @ts-ignore
        ...submitSarCommentData[34].required_delegtion,
    ]

    // save logic start
    const handleSave = (
        // dirty: boolean,
        values: FormikValues,
        errors: FormikErrors<FormikValues>,
        setFieldValue: (
            _fieldName: string,
            _value: unknown) => void,
        validateForm: (_values: FormikValues) => any,
        resetForm: (_values: FormikValues) => void,
        options: { shouldExit: boolean, switchStep: number },
    ) => {
        //  for Saving implemnent task list data//
        if (routeCheckForPendingImplementation()) {
            const postBody = {
                request_id: values.request_id,
                sar_on_premise_id: aadSarImplementationList[0].sar_on_premise_id ? aadSarImplementationList[0].sar_on_premise_id : null,
                is_step_one_completed: values.is_step_one_completed,
                proposed_role_group_name: values.proposed_role_group_name,
                is_step_two_completed: values.is_step_two_completed,
                proposed_delegation_role_group_name: values.proposed_delegation_role_group_name,
                proposed_target_group_name: values.proposed_target_group_name,
                proposed_gpo_name: values.proposed_gpo_name,
                is_delegation_gpo_target_completed: values.is_delegation_gpo_target_completed,
                is_delegation_info_completed: values.is_delegation_info_completed,
                complete_manage_by_section: values.complete_manage_by_section,
                complete_other_section: values.complete_other_section,
                is_step_three_completed: values.is_step_three_completed,
                is_service_account_require_access: values.is_service_account_require_access,
                review_delegation_form_to_determine_scope: values.review_delegation_form_to_determine_scope,
                is_step_four_completed: values.is_step_four_completed,
                is_target_group_name_completed: values.is_target_group_name_completed,
                is_target_group_delegation_info_completed: values.is_target_group_delegation_info_completed,
                is_target_group_complete_other_section: values.is_target_group_complete_other_section,
                is_target_group_complete_manage_by_section: values.is_target_group_complete_manage_by_section,
                is_step_five_completed: values.is_step_five_completed,
                copy_target_group_name: values.copy_target_group_name,
                add_members_to_target_group: values.add_members_to_target_group,
                is_step_six_completed: values.is_step_six_completed,
                is_group_policy_gpo_name_completed: values.is_group_policy_gpo_name_completed,
                is_group_policy_role_group_completed: values.is_group_policy_role_group_completed,
                is_select_the_delegation: values.is_select_the_delegation,
                is_step_seven_completed: values.is_step_seven_completed,
                is_assignment_to_gpo_completed: values.is_assignment_to_gpo_completed,
                is_step_eight_completed: values.is_step_eight_completed,
                is_implemented: false
            }
            postSarImplementer(postBody).then((res) => {
                dispatch(aadSarImplementationTasklist(res.body_data))
                enqueueSnackbar(`Delegation ${props.initialValues.request_number} has been saved successfully`, { variant: 'success' })
            }).catch(error => error);
            return false;
        }
        // end 
        if (props.initialValues.remediation_count > 0) {
            const changedValues = Object.keys(values).filter(key => values[key] !== historyDataOfForm[key])
            const newArray = changedValues.filter(v => !staticDiffernceData.includes(v));
            let finalChangedData: any = [];
            newArray.length > 0 && newArray.map((item) => {
                finalChangedData.push({
                    update_type: emptySchemaCheck.get(item),
                    section: fieldsOfSection.get(item),
                    sub_section: fieldsOfSectionForSubsection.get(item),
                    value: historyDataOfForm[item],
                    role_name_section: '',
                    user_details_section: ''
                })
            })
            const historyPostBody = {
                request_id: requestId,
                is_submitted: false,
                data: finalChangedData.filter((item) => item.section !== undefined && item.sub_section !== undefined)
            }
            if (finalChangedData.length > 0) {
                postDelegationFieldHistoryData(historyPostBody).then((response) => {
                    console.log(response)
                }).catch(error => error);
            }
        }
        setFieldValue('onSave', true);
        async () => {
            await Promise.resolve();
        };
        console.log("!routeCheckForPendingImplementation()", !routeCheckForPendingImplementation())
        !routeCheckForPendingImplementation() && validateForm(values).then((errors: FormikErrors<FormikValues>) => {
            if (!isEmpty(errors)) {
                console.log("errors", errors)
                setFieldValue("onSave", false);
                return;
            } else if (activeStep == 3 && ![values.sar_logon_as_service, values.sar_logon_as_batch_job].includes(true)) {
                enqueueSnackbar('Please select atleast one option from service account function.', { variant: 'warning' });
            } else if (activeStep == 4 && values.sar_server_tier.length == 0) {
                enqueueSnackbar('Please select atleast one option from server tier.', { variant: 'warning' });
            } else if (activeStep == 6 && values.sar_spn_and_or_delegation !== 'SPNonly' && ![values.sar_is_any_service_kerberos_only, values.sar_is_specified_service_any_protocol, values.sar_is_specified_service_kerberos_only].includes(true)) {
                enqueueSnackbar('Please select atleast one option from required delegations.', { variant: 'warning' });
            } else {
                postNewRequest({
                    ...values,
                    request_id: requestId,
                    event: requestId !== '' ? 'update' : 'create',
                    next_priority: null,
                    status: values.remediation_count === 0 ? "1" : "7",
                    request_type: 1,
                    del_signed_by: ''
                }).then((res) => {
                    setIsAPIProgress((prevState) => ({ ...prevState, saving: true }));
                    if (res.status) {
                        if (options.shouldExit) {
                            resetForm({ values: res.data });
                            history.push('/delegation');
                        }
                        else if (isEmpty(errors)) {
                            enqueueSnackbar(`Delegation ${requestData.request_number} has been saved successfully`, { variant: 'success' })
                        }
                        const formValues = {
                            ...values,
                        };

                        resetForm({ values: formValues });
                    } else {
                        enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
                    }
                    setIsAPIProgress((prevState) => ({ ...prevState, saving: false }));
                }).catch((err) => {
                    console.error(err);
                    enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
                    setIsAPIProgress((prevState) => ({ ...prevState, saving: false }));
                });
                setFieldValue('onSave', false);
            }
        });
    };
    // save logic end End

    // save logic NextButton
    const handleSaveOnNext = (
        // dirty: boolean,
        values: FormikValues,
        errors: FormikErrors<FormikValues>,
        setFieldValue: (
            _fieldName: string,
            _value: unknown) => void,
        validateForm: (_values: FormikValues) => any,
        resetForm: (_values: FormikValues) => void,
        options: { shouldExit: boolean, switchStep: number },
    ) => {
        if (props.initialValues.remediation_count > 0) {
            const changedValues = Object.keys(values).filter(key => values[key] !== historyDataOfForm[key])
            const newArray = changedValues.filter(v => !staticDiffernceData.includes(v));
            let finalChangedData: any = [];
            newArray.length > 0 && newArray.map((item) => {
                finalChangedData.push({
                    update_type: emptySchemaCheck.get(item),
                    section: fieldsOfSection.get(item),
                    sub_section: fieldsOfSectionForSubsection.get(item),
                    value: historyDataOfForm[item],
                    role_name_section: '',
                    user_details_section: ''
                })
            })
            const historyPostBody = {
                request_id: requestId,
                is_submitted: false,
                data: finalChangedData.filter((item) => item.section !== undefined && item.sub_section !== undefined)
            }
            if (finalChangedData.length > 0) {
                postDelegationFieldHistoryData(historyPostBody).then((response) => {
                    console.log(response)
                }).catch(error => error);
            }
        }
        setFieldValue('onSave', true);
        async () => {
            await Promise.resolve();
        };
        !routeCheckForPendingImplementation() && validateForm(values).then((errors: FormikErrors<FormikValues>) => {
            if (!isEmpty(errors)) {
                console.log("errors", errors)
                setFieldValue("onSave", false);
                return;
            } else if (activeStep == 3 && ![values.sar_logon_as_service, values.sar_logon_as_batch_job].includes(true)) {
                enqueueSnackbar('Please select atleast one option from service account function.', { variant: 'warning' });
            } else if (activeStep == 4 && values.sar_server_tier.length == 0) {
                enqueueSnackbar('Please select atleast one option from server tier.', { variant: 'warning' });
            } else if (activeStep == 6 && values.sar_spn_and_or_delegation !== 'SPNonly' && ![values.sar_is_any_service_kerberos_only, values.sar_is_specified_service_any_protocol, values.sar_is_specified_service_kerberos_only].includes(true)) {
                enqueueSnackbar('Please select atleast one option from required delegations.', { variant: 'warning' });
            } else {
                postNewRequest({
                    ...values,
                    request_id: requestId,
                    event: requestId !== '' ? 'update' : 'create',
                    next_priority: null,
                    status: values.remediation_count === 0 ? "1" : "7",
                    request_type: 1,
                    del_signed_by: ''
                }).then((res) => {
                    setIsAPIProgress((prevState) => ({ ...prevState, saving: true }));
                    if (res.status) {
                        if (options.shouldExit) {
                            resetForm({ values: res.data });
                            history.push('/delegation');
                        } else if (options.switchStep && activeStep !== options.switchStep && isEmpty(errors)) {
                            setActiveStep(options.switchStep);
                        }
                        const formValues = {
                            ...values,
                        };
                        resetForm({ values: formValues });
                    } else {
                        enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
                    }
                    setIsAPIProgress((prevState) => ({ ...prevState, saving: false }));
                }).catch((err) => {
                    console.error(err);
                    enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
                    setIsAPIProgress((prevState) => ({ ...prevState, saving: false }));
                });
                setFieldValue('onSave', false);
            }
        });
    };
    // NextButton logic end  End

    // cancel request start
    const handleCancelRequest = (reqId: string) => {
        setIsAPIProgress((prevState) => ({ ...prevState, canceling: true }));
        if (routeCheckForPendingApproval()) {
            history.push("/myApprovalTasks");
        } else {
            cancelDelegation(reqId)
                .then((res) => {
                    if (res.status) {
                        enqueueSnackbar("Delegation cancelled successfully", {
                            variant: "success",
                        });
                        if (routeCheckForDelation()) {
                            history.push("/delegation");
                        } else {
                            history.push("/myApprovalTasks");
                        }
                    } else {
                        enqueueSnackbar("Something went wrong. Please try again.", {
                            variant: "error",
                        });
                    }
                    setIsAPIProgress((prevState) => ({ ...prevState, canceling: false }));
                })
                .catch((err) => {
                    console.error(err);
                    enqueueSnackbar("Something went wrong. Please try again.", {
                        variant: "error",
                    });
                    setIsAPIProgress((prevState) => ({ ...prevState, canceling: false }));
                });
        }
    };

    const confirmCancel = (
        values: FormikValues,
        errors: FormikErrors<FormikValues>,
        setFieldValue: (_fieldName: string, _value: unknown) => void,
        validateForm: (_values: FormikValues) => any,
        resetForm: (_values: FormikValues) => void
    ) => {
        setModalData({
            show: true,
            modalHeader: "Cancel Delegation",
            modalContent: (
                <div>
                    <div className={styleClasses.modalContentDiv}>
                        Are you sure you want to cancel Delegation {props.initialValues.request_number}?
                    </div>
                    <div className={styleClasses.modalTextDiv}>
                        <span className={styleClasses.spanText}>{"Note: "}</span>A cancelled
                        Delegation will be permanently deleted and cannot be retrieved.
                    </div>
                </div>
            ),
            modalActions: (
                <div className={styleClasses.modalActionDiv}>
                    <CustomButton
                        variant="contained"
                        actionType="secondary"
                        onClick={() => {
                            handleCancelRequest(requestId);
                        }}
                        disabled={isAPIProgress.canceling || isAPIProgress.saving}
                    >
                        {"Don't Save"}
                    </CustomButton>
                    <CustomButton
                        variant="contained"
                        actionType="primary"
                        disableMargin
                        onClick={() => {
                            handleSave(
                                values,
                                errors,
                                setFieldValue,
                                validateForm,
                                resetForm,
                                {
                                    shouldExit: true,
                                    switchStep: -1,
                                }
                            );
                        }}
                        disabled={isAPIProgress.canceling || isAPIProgress.saving}
                    >
                        Save as Draft
                    </CustomButton>
                </div>
            ),
        });
    };

    const handleModalClose = () => {
        setModalData({
            show: false,
            modalContent: null,
            modalActions: null,
            modalHeader: '',
            // rejectStatus: false,
            // remediationStatus: false,
            // approveStatus: false,
        });
        modalClickCheck = {
            rejectStatus: false,
            approveStatus: false,
            remediationStatus: false,
        }
    };

    // start of status-update api function
    const statusUpdateDelegation = () => {
        console.log("enter update")
        if (modalClickCheck.approveStatus) {
            if (requestData.next_priority !== null) {
                const postBodyStatusUpdate = { request_id: requestId, status: '4' }
                postRemediationStatus(postBodyStatusUpdate).then((res) => {
                    enqueueSnackbar(`Delegation ${props.initialValues.request_number} has been Approved successfully`, { variant: 'success' });
                    history.push("/myApprovalTasks");
                }).catch((err) => err)
            } else {
                const postBodyStatusUpdate = { request_id: requestId, status: '3' }
                postRemediationStatus(postBodyStatusUpdate).then((res) => {
                    enqueueSnackbar(`Delegation ${props.initialValues.request_number} has been Approved successfully`, { variant: 'success' });
                    history.push("/myApprovalTasks");
                }).catch((err) => err)
            }
        } else if (modalClickCheck.rejectStatus) {
            const postBodyStatusUpdate = { request_id: requestId, status: '2' }
            postRemediationStatus(postBodyStatusUpdate).then((res) => {
                enqueueSnackbar(`Delegation ${props.initialValues.request_number} has been Rejected successfully`, { variant: 'success' });
                history.push("/myApprovalTasks");
            }).catch((err) => err)
        } else if (modalClickCheck.remediationStatus) {
            const postBodyStatusUpdate = { request_id: requestId, status: '7' }
            postRemediationStatus(postBodyStatusUpdate).then((res) => {
                enqueueSnackbar(`Your remediation has been requested successfully for Delegation ${props.initialValues.request_number}`, { variant: 'success' });
                history.push("/myApprovalTasks");
            }).catch((err) => err)
        }
    }
    // end of status-update api function

    const confirmCancelReview = (
        values: FormikValues,
        errors: FormikErrors<FormikValues>,
        setFieldValue: (_fieldName: string, _value: unknown) => void,
        validateForm: (_values: FormikValues) => any,
        resetForm: (_values: FormikValues) => void,
    ) => {
        setModalData({
            show: true,
            modalHeader: 'Cancel review',
            modalContent:
                (
                    <div>
                        <div className={styleClasses.modalContentDiv}>
                            Are you sure you want to cancel your review of Delegation {requestData.requestId}
                            ?
                        </div>
                        <div className={styleClasses.modalTextDiv}>
                            <span className={styleClasses.spanText}>
                                {'Note: '}
                            </span>
                            If you cancel your review now, this Delegation will revert to the Pending Approval stage. If you are unable to review at this time, you can delegate to another approver, via your Pending Approvals page. <br /> Should you continue to review this Delegation, you will be able to either approve the Delegation or request updates to the Delegation using the <b>Request Remediation</b> button.
                        </div>
                    </div>
                ),
            modalActions: (
                <div className={styleClasses.modalActionDiv}>
                    <CustomButton
                        variant="contained"
                        actionType="secondary"
                        onClick={() => { handleCancelRequest(requestId); }}
                        disabled={isAPIProgress.canceling || isAPIProgress.saving}
                    >
                        Cancel Review
                    </CustomButton>
                    <CustomButton
                        variant="contained"
                        actionType="primary"
                        disableMargin
                        onClick={() => handleModalClose()}
                        disabled={isAPIProgress.canceling || isAPIProgress.saving}
                    >
                        Continue Review
                    </CustomButton>
                </div>
            )
        })

    }
    // cancelation logic end

    // Reject Delegation in approval stage
    const ConfirmRejectReview = () => {
        modalClickCheck = {
            rejectStatus: true,
            remediationStatus: false,
            approveStatus: false,

        }
        setModalData({
            show: true,
            modalHeader: 'Reject Delegation',
            rejectStatus: true,
            modalContent:
                (
                    <div>
                        <div className={styleClasses.modalContentDiv}>
                            Are you sure you want to reject Delegation {requestData.request_number}
                            ?
                        </div>
                        <div className={styleClasses.modalTextDiv}>
                            <span className={styleClasses.spanText}>
                                {'Note: '}
                            </span>
                            Rejecting a Delegation is a permanent action and cannot be undone. If you require the user to update the Delegation with further information, please request this using the <b>Request Remediation</b> button at the bottom of the screen.
                        </div>
                    </div>
                ),
            modalActions: (
                <div className={styleClasses.modalActionDiv}>
                    <CustomButton
                        variant="contained"
                        actionType="secondary"
                        onClick={() => statusUpdateDelegation()}
                        disabled={isAPIProgress.canceling || isAPIProgress.saving}
                    >
                        Reject
                    </CustomButton>
                    <CustomButton
                        variant="contained"
                        actionType="primary"
                        disableMargin
                        onClick={() => handleModalClose()}
                        disabled={isAPIProgress.canceling || isAPIProgress.saving}
                    >
                        No
                    </CustomButton>
                </div>
            )
        })
    }

    // Start of Remediation modal pop-up
    const ConfirmRemediationReview = (
        values: FormikValues,
        errors: FormikErrors<FormikValues>,
        validateForm: (_values: FormikValues) => any,
        resetForm: (_values: FormikValues) => void,
        options: { shouldExit: boolean, switchStep: number },
    ) => {
        modalClickCheck = {
            rejectStatus: false,
            remediationStatus: true,
            approveStatus: false,
        }
        if (isEmpty(postBodyData)) {
            setModalData({
                show: true,
                modalHeader: 'Note',
                modalContent:
                    (
                        <div className={styleClasses.modalTextDiv}>
                            No comments have been added to this Delegation to explain why remediation is required. <br /> Please return to the Delegation and add comments using the speech bubble icon on the top right of the Delegation view. Comments can be added in each section to explain what additional information is required.
                        </div>

                    ),
                modalActions: (
                    <div className={styleClasses.modalActionDiv}>

                        <CustomButton
                            variant="contained"
                            actionType="primary"
                            disableMargin
                            onClick={() => handleModalClose()}
                        >
                            Ok
                        </CustomButton>
                    </div>
                )
            })
        } else {
            const appCheckingLastComments = postBodyData.map((item) => {
                return (item.updated_by === loginsyncupdata.email) && (item.current_remediation === props.initialValues.remediation_count) && (item.request_to_update === props.initialValues.request_to_update_count);
            })
            if (appCheckingLastComments.includes(true)) {
                statusUpdateDelegation();
                return;
            } else {
                setModalData({
                    show: true,
                    modalHeader: 'Note',
                    modalContent:
                        (
                            <div className={styleClasses.modalTextDiv}>
                                No comments have been added to this Delegation to explain why remediation is required. <br /> Please return to the Delegation and add comments using the speech bubble icon on the top right of the Delegation view. Comments can be added in each section to explain what additional information is required.
                            </div>
                        ),
                    modalActions: (
                        <div className={styleClasses.modalActionDiv}>

                            <CustomButton
                                variant="contained"
                                actionType="primary"
                                disableMargin
                                onClick={() => handleModalClose()}
                            >
                                Ok
                            </CustomButton>
                        </div>
                    )
                })
            }
        }
    }
    // End of Remediation modal pop-up

    // Start of Approve modal pop-up
    const ConfirmApproveHandler = () => {

        modalClickCheck = {
            rejectStatus: false,
            approveStatus: true,
            remediationStatus: false,
        }
        setModalData({
            show: true,
            modalHeader: 'Approve Delegation',
            modalContent:
                (
                    <div>
                        <div className={styleClasses.modalContentDiv}>
                            Are you sure you want to approve Delegation {requestData.request_number} and move it to next approval stage without any update?
                        </div>
                        <div className={styleClasses.modalTextDiv}>
                            <span className={styleClasses.spanText}>
                                {'Note: '}
                            </span>
                            If you require the User to update the Delegation with further information, please request this using the <b>Request Remediation</b> button at the bottom of the screen.
                        </div>
                    </div>

                ),
            modalActions: (
                <div className={styleClasses.modalActionDiv}>
                    <CustomButton
                        variant="contained"
                        actionType="secondary"
                        onClick={() => handleModalClose()}
                        disabled={isAPIProgress.canceling || isAPIProgress.saving}
                    >
                        No
                    </CustomButton>
                    <CustomButton
                        variant="contained"
                        actionType="primary"
                        disableMargin
                        onClick={statusUpdateDelegation}
                        disabled={isAPIProgress.canceling || isAPIProgress.saving}
                    >
                        Approve
                    </CustomButton>
                </div>
            )
        })
    }
    // End of Approve modal pop-up



    const getButtonLabel = (isSubmitting: boolean) => {
        if (isSubmitting) return 'Submitting';
        if (isLastStep()) return 'Submit';
        return 'Next';
    };

    useEffect(() => {
        if (routeCheckForPendingImplementation()) {
            setModalData({
                show: true,
                modalHeader: 'Note',
                modalContent:
                    (
                        <div className={styleClasses.modalContentDiv}>
                            <div className={styleClasses.modalTextDiv}>
                                Please review this Delegation from beginning to end. This will <br /> enable full understanding of the requirements for <br></br>implementation.
                            </div>
                        </div>
                    ),
                modalActions: (
                    <div className={styleClasses.modalActionDiv}>
                        <CustomButton
                            variant="contained"
                            actionType="primary"
                            disableMargin
                            onClick={handleModalClose}
                            disabled={isAPIProgress.canceling || isAPIProgress.saving}
                        >
                            Ok
                        </CustomButton>
                    </div>
                )
            })
        }
    }, []);

    // for final confirm //
    const finalConfirm = () => {
        enqueueSnackbar(`Implementation request ${props.initialValues.request_number} completed successfully`, {
            variant: "success",
        });
        history.push("/myImplementationTasks");
        handleModalClose();
    }

    //  For update status of request to implement //
    const updateStatusImplementation = (
        values: FormikValues,
        erros: FormikErrors<FormikValues>,) => {
        const postBody = {
            request_id: requestId,
            status: 8,
            request_type: "Delegation",
        }
        updateImplementerStatus(postBody).then((res) => {
        }).catch((err) => err);
        handleModalClose();
    }

    //  for Implentation confirmation
    const ConfirmImplementationHandler = (values: any, erros: any) => {
        const postBody = {
            request_id: values.request_id,
            sar_on_premise_id: aadSarImplementationList[0].sar_on_premise_id ? aadSarImplementationList[0].sar_on_premise_id : null,
            is_step_one_completed: values.is_step_one_completed,
            proposed_role_group_name: values.proposed_role_group_name,
            is_step_two_completed: values.is_step_two_completed,
            proposed_delegation_role_group_name: values.proposed_delegation_role_group_name,
            proposed_target_group_name: values.proposed_target_group_name,
            proposed_gpo_name: values.proposed_gpo_name,
            is_delegation_gpo_target_completed: values.is_delegation_gpo_target_completed,
            is_delegation_info_completed: values.is_delegation_info_completed,
            complete_manage_by_section: values.complete_manage_by_section,
            complete_other_section: values.complete_other_section,
            is_step_three_completed: values.is_step_three_completed,
            is_service_account_require_access: values.is_service_account_require_access,
            review_delegation_form_to_determine_scope: values.review_delegation_form_to_determine_scope,
            is_step_four_completed: values.is_step_four_completed,
            is_target_group_name_completed: values.is_target_group_name_completed,
            is_target_group_delegation_info_completed: values.is_target_group_delegation_info_completed,
            is_target_group_complete_other_section: values.is_target_group_complete_other_section,
            is_target_group_complete_manage_by_section: values.is_target_group_complete_manage_by_section,
            is_step_five_completed: values.is_step_five_completed,
            copy_target_group_name: values.copy_target_group_name,
            add_members_to_target_group: values.add_members_to_target_group,
            is_step_six_completed: values.is_step_six_completed,
            is_group_policy_gpo_name_completed: values.is_group_policy_gpo_name_completed,
            is_group_policy_role_group_completed: values.is_group_policy_role_group_completed,
            is_select_the_delegation: values.is_select_the_delegation,
            is_step_seven_completed: values.is_step_seven_completed,
            is_assignment_to_gpo_completed: values.is_assignment_to_gpo_completed,
            is_step_eight_completed: values.is_step_eight_completed,
            is_implemented: false
        }
        postSarImplementer(postBody).then((res) => {
            updateStatusImplementation(values, erros)
            setModalData({
                show: true,
                modalHeader: 'Implement',
                modalContent:
                    (
                        <div>
                            <div className={styleClasses.modalContentDiv}>
                                Implementation task list for Delegation {props.initialValues.request_number} has been <br />completed successfully.  <br /> A Privileged Access Request has been triggered by the <br /> application for the account listed in the Delegation form for <br /> completion. <br />
                                A further task will be sent to the Implementation team to <br /> complete.
                            </div>
                        </div>
                    ),
                modalActions: (
                    <div className={styleClasses.modalActionDiv}>
                        <CustomButton
                            variant="contained"
                            actionType="primary"
                            disableMargin
                            onClick={finalConfirm}
                            disabled={isAPIProgress.canceling || isAPIProgress.saving}
                        >
                            Ok
                        </CustomButton>
                    </div>
                )
            })
        }).catch(error => error);
    }

    const isImplementDisabled = (values: any) => {
        if (values.is_service_account_require_access === 'Yes' && values.review_delegation_form_to_determine_scope === 'Individual device or devices (server/s and / or computer/s)') {
            return values.is_step_eight_completed;
        } else if (values.is_service_account_require_access === 'No') {
            return values.is_step_four_completed;
        }
        else {
            return values.is_step_eight_completed
        }
    }

    return (
        <>
            <Formik
                {...props}
                validationSchema={currentChild.props.validationSchema}
                onSubmit={async (values: FormikValues, helpers: FormikHelpers<FormikValues>) => {
                    helpers.setSubmitting(true);
                    await props.onSubmit(values, helpers);
                    helpers.setSubmitting(false);
                }}
                validateOnChange={false}
            >
                {
                    (
                        {
                            isSubmitting,
                            values,
                            setFieldValue,
                            errors,
                            validateForm,
                            submitForm,
                            dirty,
                            resetForm,
                        }
                    ) => (
                        <>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={3}>
                                    <div className={styleClasses.leftSection}>
                                        <div className={styleClasses.stepperContainer}>
                                            <div className={styleClasses.stepperScoller}>
                                                <VerticalLinearStepper
                                                    activeStep={activeStep}
                                                    // stepsData={stepsData}
                                                    stepsData={((props.initialValues.status_label == "Approved - Pending Implementation") && routeCheckForPendingImplementation()) ? stepsImplemtaionDataSar : stepsData}
                                                    setActiveStep={setActiveStep}
                                                    requestStatus={requestData.status_label}
                                                />
                                            </div>
                                        </div>
                                        <Divider orientation="vertical" variant="middle" flexItem style={{ margin: '0  20px' }} />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={9}>
                                    <div style={{
                                        maxHeight: 'calc(100vh - 108px - 68.5px - 60px)',
                                        overflowX: 'scroll',
                                        paddingBottom: '30px',
                                        // paddingLeft: '10px',
                                        paddingRight: '10px',
                                    }}
                                    >
                                        <div className={styleClasses.reasonLabel}>
                                            {`${requestData.request_number} : ${requestData?.request_purpose || ''}`}
                                        </div>
                                        {currentChild}
                                    </div>
                                </Grid>
                            </Grid>
                            <Footer>
                                <div style={{ paddingRight: '15px' }}>
                                    <div className={styleClasses.footerActions}>
                                        {activeStep > 0 && (<CustomButton
                                            variant="contained"
                                            actionType="secondary"
                                            disableMargin
                                            onClick={() => setActiveStep((s) => s - 1)}
                                        >
                                            Prev
                                        </CustomButton>)}
                                        {routeCheck() && <CustomButton
                                            variant="contained"
                                            actionType="secondary"
                                            disableMargin
                                            // disabled={approver ? true : (checkIsDisabled() || isSubmitting || activeStep === 0)}
                                            // disabled={!roleGroupArray.includes('Approver')}
                                            onClick={ConfirmRejectReview}
                                        >
                                            Reject
                                        </CustomButton>}
                                    </div>

                                    <div className={`${styleClasses.footerActionsRight} ${styleClasses.footerActions}`}>
                                        {
                                            routeCheck() ? (
                                                <CustomButton
                                                    variant="contained"
                                                    actionType="secondary"
                                                    disableMargin
                                                    // disabled={isAPIProgress.saving || checkIsDisabled()}
                                                    onClick={() => {
                                                        confirmCancelReview(values, errors, setFieldValue, validateForm, resetForm)
                                                    }}
                                                >
                                                    Cancel
                                                </CustomButton>
                                            ) : (
                                                (!routeCheckForApprovalSummary() && requestData.del_request_id !== null && requestData.status_label !== 'Draft') ?
                                                    (
                                                        () => null
                                                    ) : (
                                                        !routeCheckForApprovalSummary() && <CustomButton
                                                            variant="contained"
                                                            actionType="secondary"
                                                            disableMargin
                                                            // disabled={isAPIProgress.saving || checkIsDisabled()}
                                                            onClick={() => {
                                                                confirmCancel(values, errors, setFieldValue, validateForm, resetForm);
                                                            }}
                                                        >
                                                            Cancels
                                                        </CustomButton>
                                                    )
                                            )
                                        }
                                        {!routeCheckForAdminSummary() && ((requestData.status_label == 'Draft') || (requestData.status_label == 'Remediation Requested')) &&
                                            <CustomButton
                                                variant="contained"
                                                actionType="secondary"
                                                disableMargin
                                                disabled={isAPIProgress.saving}
                                                name="onSave"
                                                onClick={() => {
                                                    handleSave(
                                                        values,
                                                        errors,
                                                        // dirty,
                                                        setFieldValue,
                                                        validateForm,
                                                        resetForm,
                                                        {
                                                            shouldExit: false,
                                                            switchStep: activeStep,
                                                        },
                                                    );
                                                }}
                                            >
                                                {isAPIProgress.saving ? 'Saving..' : 'Save'}

                                            </CustomButton>
                                        }
                                        {routeCheck() && <CustomButton
                                            variant="contained"
                                            actionType="secondary"
                                            disableMargin
                                            onClick={() => ConfirmRemediationReview(
                                                values,
                                                errors,
                                                validateForm,
                                                resetForm,
                                                {
                                                    shouldExit: false,
                                                    switchStep: activeStep,
                                                },
                                            )}
                                        >
                                            Request Remediation
                                        </CustomButton>}

                                        {
                                            checkIsDisabled() ?
                                                (
                                                    <CustomButton
                                                        variant="contained"
                                                        actionType="primary"
                                                        disableMargin
                                                        disabled={routeCheck() ? false : isLastStep()}
                                                        onClick={isLastStep() ? ConfirmApproveHandler : () => setActiveStep((s) => s + 1)}
                                                    >
                                                        {(routeCheck() && isLastStep()) ? `Approve` : `Next`}
                                                    </CustomButton>
                                                ) : (
                                                    <CustomButton
                                                        variant="contained"
                                                        actionType="primary"
                                                        disableMargin
                                                        disabled={checkIsDisabled()
                                                            || (isSubmitting || isAPIProgress.saving)
                                                            // || (isLastStep())
                                                        }
                                                        onClick={() => {
                                                            if (!isLastStep()) {
                                                                handleSaveOnNext(
                                                                    values,
                                                                    errors,
                                                                    setFieldValue,
                                                                    validateForm,
                                                                    resetForm,
                                                                    {
                                                                        shouldExit: false,
                                                                        switchStep: activeStep + 1,
                                                                    },
                                                                );
                                                            } else {
                                                                submitForm();
                                                            }
                                                        }}
                                                    >
                                                        {getButtonLabel(isSubmitting)}
                                                    </CustomButton>
                                                )}

                                        {/* for Implenter button */}
                                        {
                                            routeCheckForPendingImplementation() ? (
                                                <>
                                                    {isLastStep() && <CustomButton
                                                        variant="contained"
                                                        actionType="secondary"
                                                        disableMargin
                                                        disabled={isAPIProgress.saving}
                                                        name="onSave"
                                                        onClick={() => {
                                                            handleSave(
                                                                values,
                                                                errors,
                                                                setFieldValue,
                                                                validateForm,
                                                                resetForm,
                                                                {
                                                                    shouldExit: false,
                                                                    switchStep: activeStep,
                                                                },
                                                            );
                                                        }}
                                                    >
                                                        {isAPIProgress.saving ? 'Saving..' : 'Save'}

                                                    </CustomButton>}
                                                    {activeStep == 8 && <CustomButton
                                                        variant="contained"
                                                        actionType="primary"
                                                        disableMargin
                                                        disabled={isLastStep() && (isImplementDisabled(values) ? false : true)}
                                                        onClick={isLastStep() ? () => ConfirmImplementationHandler(values, errors) : () => setActiveStep((s) => s + 1)}
                                                    >
                                                        {(routeCheckForPendingImplementation() && isLastStep()) ? `Implement` : ` Next`}
                                                    </CustomButton>}
                                                </>
                                            )
                                                : routeCheckForPendingImplementation() && (
                                                    <CustomButton
                                                        variant="contained"
                                                        actionType="primary"
                                                        disableMargin
                                                        disabled={
                                                            checkIsDisabled() ||
                                                            isSubmitting ||
                                                            isAPIProgress.saving ||
                                                            (isLastStep() && !values.finalCheck)
                                                        }
                                                        onClick={() => {
                                                            if (!isLastStep()) {
                                                                handleSave(
                                                                    values,
                                                                    errors,
                                                                    setFieldValue,
                                                                    validateForm,
                                                                    resetForm,
                                                                    {
                                                                        shouldExit: false,
                                                                        switchStep: activeStep + 1,
                                                                    }
                                                                );
                                                            } else {
                                                                submitForm();
                                                            }
                                                        }}
                                                    >
                                                        {getButtonLabel(isSubmitting)}
                                                    </CustomButton>
                                                )}
                                    </div>
                                </div>
                            </Footer>
                            <Prompt
                                when={dirty && !checkIsDisabled()}
                                message={(location) => `Are you sure you want to go to ${location.pathname}`}
                            />
                        </>
                    )
                }
            </Formik>
            {
                modalData.show && (
                    <CustomModal
                        modalHeader={modalData.modalHeader}
                        open={modalData.show}
                        onClose={handleModalClose}
                        modalAction={modalData.modalActions}
                        modalContent={modalData.modalContent}
                    />
                )
            }
        </>
    )
}