/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import Button from '@mui/material/Button';
import { ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';

interface StyledButtonProps extends ButtonProps {
  actionType?: 'primary' | 'secondary';
  background?: string;
  fontcolor?: string;
}

const getColorByAction = (actionType?: string) => {
  switch (actionType) {
    case 'primary': return '#00B5B1';
    case 'secondary': return '#ffffff';
    default: return 'inherit';
  }
};

const getFontColorByAction = (actionType?: string) => {
  switch (actionType) {
    case 'primary': return '#fff';
    case 'secondary': return '#00B5B1';
    default: return '#fff';
  }
};

const StyledSolidButton = styled(Button, {
  skipSx: true,
})<StyledButtonProps>((props: any) => ({
  border: '2px solid #00B5B1',
  background: props.background,
  color: props.fontcolor,
  borderRadius: '50px',
  textTransform: 'none',
  font: 'normal normal 1rem/38px Poppins-Regular',
  boxShadow: 'none',
  '&:hover': {
    background: '#00A5A2',
    border: '2px solid #00B5B1',
    boxShadow: 'none',
  },
  '&:disabled': {
    background: props.background,
    color: props.fontcolor,
  },
}));

function SolidButton({ actionType, children, ...rest }: StyledButtonProps) {
  return (
    <StyledSolidButton
      data-testid="solid-button"
      variant="contained"
      {...rest}
      background={getColorByAction(actionType)}
      fontcolor={getFontColorByAction(actionType)}
    >
      {children}
    </StyledSolidButton>
  );
}

export default SolidButton;
