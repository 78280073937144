import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  title: {
    display: 'flex',
    padding: '10px 10px 10px 0px',
    fontSize: '14px',
    color: '#0A2756',
    fontFamily: 'Poppins-Medium',
    // width: 'content-fit',
  },
  implementationTitle: {
    color: '#fff',
  },
  astrik: {
    color: '#EF5B2B',
    margin: '0 2px'
  },
  tooltipSpan: {
    marginLeft: '8px',

  },

});

export default useStyles;
