import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  stepperDiv: { padding: 10 },
  stepper: {
    padding: '0!important',
    '& .Mui-disabled .MuiStepLabel-iconContainer': {
      opacity: 0.4,
    },
    '& .MuiStepConnector-line': {
      borderLeftStyle: 'dotted',
      borderLeftWidth: '2px',
      borderColor: '#C6D2DC',
    },
    '& .Mui-completed .MuiStepConnector-line, & .Mui-active .MuiStepConnector-line': {
      borderColor: '#00B5B1',
    },
    '& .MuiStepConnector-root': {
      marginLeft: '15px',
    },
    '& .MuiStepLabel-iconContainer': {
      paddingRight: '11px',
    },
  },
  steperLabel: {
    padding: '0!important',
    '& .MuiStepLabel-label': {
      color: '#0A2756',
      fontFamily: 'Poppins-Regular',
    },
    '& .Mui-disabled .MuiStepLabel-iconContainer': {
      opacity: 0.4,
    },
  },
  stepIcon: {
    border: '1.5px solid #C6D2DC',
    borderRadius: '16px',
    fontFamily: 'Poppins-SemiBold',
    fontSize: '14px',
    height: '30px',
    width: '30px',
    lineHeight: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  completed: {
    border: '1.5px solid #00B5B1',
    background: '#00B5B1',
    color: '#fff',
  },
  active: {
    border: '1.5px solid #00B5B1',
    color: '#00B5B1',
  },
  cursorPointer: {
    cursor: 'pointer',
  }
});

export default useStyles;
