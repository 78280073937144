import React from 'react'
import useStyles from './useStyle';
import { Box } from '@mui/material';

const index = () => {
    const styleClasses = useStyles();
    return (
        <Box className={styleClasses.pageContainer}>
            <Box className={styleClasses.emptyResultContainer}>
                <p>Coming soon! </p>
                <p> This module is pending development and will be available in a future enhancement.</p>
            </Box>
        </Box>
    )
}

export default index
