import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IDelegationSummarry } from './IDelegationSummaryProps'


// Define the initial state using that type
const initialState: IDelegationSummarry = {
    requestType: 0,
}

export const delegationSummarrySlice = createSlice({
    name: 'delegationSummary',
    initialState,
    reducers: {
        addRequestType: (state, action: PayloadAction<number>) => {
            console.log("addRequestType", action.payload)
            state.requestType = action.payload
        },

        // loginSyncUpData: (state, action: PayloadAction<ILoginSyncUpData>) => {
        //     state.loginsyncupdata = action.payload
        // },
        // loginUserData: (state, action: PayloadAction<IUserData>) => {
        //     state.loginsyncupdata = action.payload
        // },
    },
})

// Action creators are generated for each case reducer function
export const { addRequestType } = delegationSummarrySlice.actions

export default delegationSummarrySlice.reducer