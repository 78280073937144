import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import isEqual from "lodash/isEqual";
import SectionHeader from "../../components/section-header";
import FilterMenu from "../../components/filter-menu";
import CustomDatePicker from "../../components/date-range-picker";
import CustomToggleButton from "../../components/button/CustomToggleButton";
import CustomButton from "../../components/button";
import FilterOptions from "../../components/filter-menu/FilterOptions";
import PaginationBlock from "../../components/pagination-block";
import DynamicTable from "../../components/dynamic-table";
import CustomModal from "../../components/custom-modal";
import { ReactComponent as EmptyResultIcon } from "../../assets/icons/no_results_icon.svg";
import { ReactComponent as PencilIconBlack } from "../../assets/icons/pencil_b.svg";
import { ReactComponent as CancelIcon } from "../../assets/icons/cross_b.svg";
import { ReactComponent as ArchiveDownload } from "../../assets/icons/archive_download.svg";
import { ReactComponent as VisibilityIcon } from '../../assets/icons/eye_b.svg';
import { emptyFilters } from "./delegationUtils";
import useDelegationHook from "./useDelegationHook";

function DelegationDashboard() {
  const {
    delegationSummaryData,
    requestStatusFilter,
    teamNameFilter,
    closeFilter,
    isLoading,
    requestIdFilter,
    setFilters,
    styleClasses,
    datepickerProps,
    setDateRangeValue,
    setDateRangeFilter,
    setDatepickerProps,
    setMenuData,
    handleModalOpen,
    setCloseFilter,
    menuData,
    modalData,
    tableColumns,
    modalOpen,
    modalContentHandler,
    modalHeaderHandler,
    handleModalClose,
    modalActionHandler,
    tableParameters,
    setTableParameters,
    filters,
    clearValueHandler,
  } = useDelegationHook();

  const emptyRequestHandler = () => {
    if (!isLoading.tableLoading && delegationSummaryData?.results.length === 0) {
      return (
        <Grid item xs={12} data-testid="empty-delegation-screen">
          <div className={styleClasses.emptyResultContainer}>
            <EmptyResultIcon />
            <span>
              {isEqual(filters, emptyFilters)
                ? "There are no requests created by you."
                : "There are no requests matching to the selected filters."}
            </span>
          </div>
        </Grid>
      );
    }
    return null;
  };


  return (
    <div className={styleClasses.pageContainer} data-testid="delegation-screen">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SectionHeader text="Delegation Summary" />
        </Grid>
        <Grid item xs={12} className={styleClasses.filtersContainer}>
          <div className={styleClasses.filterPanel}>
            <div className={styleClasses.filterIcon}>
              <FilterListIcon />
            </div>
            <div className={styleClasses.filterMenu}>
              <FilterMenu
                filterLabel="Delegation ID"
                handleFilterClose={() => console.log("handleClose")}
                badgeCount={filters.requestId?.length}
              >
                <FilterOptions
                  searchPlaceholder="Search"
                  allowSearch
                  optionsList={requestIdFilter}
                  selectedOptions={filters.requestId}
                  handleOptionSelection={(e, selection) => {
                    setFilters((prevFilters) => {
                      let newRequestIdArray = [...prevFilters.requestId];
                      console.log('newRequestIdArray', newRequestIdArray)
                      if (!selection) {
                        newRequestIdArray = newRequestIdArray.filter(
                          (option) => option !== e.target.value
                        );
                      } else newRequestIdArray.push(e.target.value);
                      return {
                        ...prevFilters,
                        requestId: [...newRequestIdArray],
                        page: 1,
                      };
                    });
                  }}
                  isFilterDataLoading={isLoading.filters.requestId}
                  allowAll
                  selectAllHandler={(isSelected: boolean) => {
                    // console.log(isSelected);
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      requestId: isSelected
                        ? requestIdFilter.map(
                          (obj: { id: string; value: string }) => obj.id
                        )
                        : [],
                    }));
                  }}
                />
              </FilterMenu>
            </div>

            <div className={styleClasses.filterMenu}>
              <FilterMenu
                filterLabel="Status"
                handleFilterClose={() => console.log("handleClose")}
                badgeCount={filters.status?.length}
              >
                <FilterOptions
                  searchPlaceholder="Search by Status"
                  allowSearch
                  optionsList={requestStatusFilter}
                  selectedOptions={filters.status}
                  handleOptionSelection={(e, selection) => {
                    setFilters((prevFilters) => {
                      let newStatusArray = [...prevFilters.status];
                      if (!selection) {
                        newStatusArray = newStatusArray.filter(
                          (option) => option !== Number(e.target.value)
                        );
                      } else newStatusArray.push(Number(e.target.value));
                      return {
                        ...prevFilters,
                        status: [...newStatusArray],
                        page: 1,
                      };
                    });
                  }}
                  isFilterDataLoading={isLoading.filters.status}
                  allowAll
                  selectAllHandler={(isSelected: boolean) => {
                    setFilters((prevFilters: any) => ({
                      ...prevFilters,
                      status: isSelected
                        ? requestStatusFilter.map(
                          (obj: { id: string; value: string }) => obj.id
                        )
                        : [],
                    }));
                  }}
                />
              </FilterMenu>
            </div>

            <div className={styleClasses.filterMenu}>
              <FilterMenu
                filterLabel="Team Name"
                handleFilterClose={() => console.log("handleClose")}
                badgeCount={filters.teamName?.length}
              >
                <FilterOptions
                  searchPlaceholder="Search by Team"
                  allowSearch
                  optionsList={teamNameFilter}
                  selectedOptions={filters.teamName}
                  handleOptionSelection={(e, selection) => {
                    setFilters((prevFilters) => {
                      let newTeamNameArray = [...prevFilters.teamName];
                      if (!selection) {
                        newTeamNameArray = newTeamNameArray.filter(
                          (option) => option !== e.target.value
                        );
                      } else newTeamNameArray.push(e.target.value);
                      return {
                        ...prevFilters,
                        teamName: [...newTeamNameArray],
                        page: 1,
                      };
                    });
                  }}
                  isFilterDataLoading={isLoading.filters.teamName}
                  allowAll
                  selectAllHandler={(isSelected: boolean) => {
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      teamName: isSelected
                        ? teamNameFilter.map(
                          (obj: { id: string; value: string }) => obj.id
                        )
                        : [],
                    }));
                  }}
                />
              </FilterMenu>
            </div>

            <div className={styleClasses.filterMenu}>
              <FilterMenu
                filterLabel="Requested On"
                handleFilterClose={() => {
                  if (closeFilter) {
                    setCloseFilter(false);
                  }
                }}
                // badgeCount={filters.requestDate.endDate && filters.requestDate.startDate
                //   ? differenceInCalendarDays(
                //     filters.requestDate.endDate,
                //     filters.requestDate.startDate,
                //   ) + 1
                //   : 0}
                badgeCount={
                  filters.requestDate.endDate && filters.requestDate.startDate
                    ? " "
                    : 0
                }
                closeFilterExternally={closeFilter}
              >
                <div className={styleClasses.dateRangeToggleContainer}>
                  <CustomToggleButton
                    variant="contained"
                    isSelected={!!datepickerProps.isSingleDate}
                    onClick={() => {
                      if (!datepickerProps.isSingleDate) {
                        setDatepickerProps({
                          isSingleDate: true,
                          dateRange: {
                            startDate: null,
                            endDate: null,
                          },
                        });
                      }
                    }}
                  >
                    Single Date
                  </CustomToggleButton>
                  <CustomToggleButton
                    variant="contained"
                    isSelected={!datepickerProps.isSingleDate}
                    onClick={() => {
                      if (datepickerProps.isSingleDate) {
                        setDatepickerProps({
                          isSingleDate: false,
                          dateRange: {
                            startDate: null,
                            endDate: null,
                          },
                        });
                      }
                    }}
                  >
                    Date Range
                  </CustomToggleButton>
                </div>
                <div className={styleClasses.dateRangeContainer}>
                  <CustomDatePicker
                    isRangePicker={!datepickerProps.isSingleDate}
                    setDateRangeValue={setDateRangeValue}
                    dateRange={datepickerProps.dateRange}
                    disableFuture
                  />
                </div>
                <div className={styleClasses.dateRangeApplyCta}>
                  <CustomButton
                    variant="contained"
                    actionType="secondary"
                    disabled={!datepickerProps.dateRange.endDate}
                    onClick={() => setDateRangeFilter()}
                    isCompact
                    disableMargin
                  >
                    Apply
                  </CustomButton>
                </div>
              </FilterMenu>
            </div>

            <CustomButton
              variant="contained"
              actionType="tertiary"
              onClick={() => {
                clearValueHandler()

              }}
              disableRipple
              disableMargin
              disablePadding
            >
              CLEAR
            </CustomButton>
          </div>

          <div>
            <CustomButton
              variant="contained"
              actionType="primary"
              disableMargin
              onClick={() => {
                handleModalOpen("createRequest");
              }}
            >
              <AddIcon style={{ marginRight: "4px" }} />
              Create New Delegation
            </CustomButton>
          </div>
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: "11px !important" }}>
          <DynamicTable
            rows={delegationSummaryData.results}
            isLoading={isLoading.tableLoading}
            columns={tableColumns}
            pageNumber={tableParameters.page}
            pageSize={delegationSummaryData.pageSize}
          />
        </Grid>
        <Grid item xs={12}>
          <PaginationBlock
            totalRecords={delegationSummaryData?.results.length || 0}
            currentRecordsCount={delegationSummaryData?.results?.length}
            activePage={tableParameters.page}
            handlePageChange={(pageNumber) => {
              setTableParameters((prevParameters) => ({
                ...prevParameters,
                page: pageNumber,
              }));
            }}
            isLoading={isLoading.tableLoading}
            pageSize={delegationSummaryData.pageSize}
          />
        </Grid>
      </Grid>
      {emptyRequestHandler()}
      {menuData.anchorEl && menuData.row && (
        <Menu
          id="menu-popover"
          open={!!menuData.anchorEl}
          anchorEl={menuData.anchorEl}
          onClose={() =>
            setMenuData({
              anchorEl: null,
              row: {
                status: null,
                id: null,
                del_user_detail_list: -1,
                is_request_to_update: false,
                status_id: null,
                admin_action_taken: {
                  admin_deny_comment: null,
                  is_deny: false
                },
                request_to_update_flag: false,
                request_number: null
              },
            })
          }
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          classes={{ root: styleClasses.moreActionMenu }}
          disableAutoFocusItem
        >
          {menuData.row.status === "Completed" && (
            <>
              {/* {menuData.row.request_to_update_flag && <MenuItem
                disabled={menuData.row.is_request_to_update}
                onClick={() => handleModalOpen("update")}
                className={styleClasses.moreActionMenuItem}
              >
                <PencilIconBlack />
                <span className={styleClasses.menuLabel}>
                  Request to Update
                </span>
              </MenuItem>} */}
              {menuData.row.admin_action_taken.is_deny === true &&
                (<MenuItem
                  // disabled={menuData.row.is_request_to_update}
                  onClick={() => handleModalOpen("viewJustification")}
                  className={styleClasses.moreActionMenuItem}
                >
                  <VisibilityIcon />
                  <span className={styleClasses.menuLabel}>
                    View Justification
                  </span>
                </MenuItem>)}
              {/* <MenuItem
                disabled={menuData.row.is_request_to_update}
                onClick={() =>
                  handleModalOpen(
                    menuData.row.del_user_detail_list &&
                      menuData.row.del_user_detail_list > 0
                      ? "archiveError"
                      : "archive"
                  )
                }
                className={styleClasses.moreActionMenuItem}
              >
                <ArchiveDownload />
                <span className={styleClasses.menuLabel}>Archive</span>
              </MenuItem> */}
            </>
          )}
          {(menuData.row.status_id === '4' || '1' || '2' || '5') && (
            <MenuItem
              onClick={() => handleModalOpen("cancel")}
              className={styleClasses.moreActionMenuItem}
              disabled={menuData.row.status === "Completed" ? true : false}
            >
              <CancelIcon />
              <span className={styleClasses.menuLabel}>Cancel Delegation</span>
            </MenuItem>
          )}

        </Menu>
        // status_id
      )}
      {/* modal code started=> */}
      {modalOpen && (
        <CustomModal
          modalHeader={modalHeaderHandler(modalData.type)}
          open={modalOpen}
          onClose={handleModalClose}
          modalAction={modalActionHandler(modalData.type)}
          modalContent={modalContentHandler(modalData.type)}
        />
      )}
      {/* modal code ended=> */}
    </div>
    // </ErrorBoundary >
  );
}
export default DelegationDashboard;
