import {
    useEffect,
    useRef,
    useState,
    useCallback,
} from 'react';
import Grid from '@mui/material/Grid';
import {
    useFormikContext,
} from 'formik';
import FormikControl from '../../../components/formik-conrtols/FormikControl';
import adminSummaryStyles from "../administrative-summary-styles";
import FilterMenu from '../../../components/filter-menu';
import FilterOptions from '../../../components/filter-menu/FilterOptions';
import { IFiltersObj, emptyFilters } from './userManagementUtils'

function EditUserDetails({ data }: any) {
    const styleClasses = adminSummaryStyles();
    const formikCtx: any = useFormikContext();
    const [filters, setFilters] = useState<IFiltersObj>(emptyFilters);
    const roleGroup = [{ id: 'Administrator', value: 'Administrator' }, { id: 'Approver', value: 'Approver' }, { id: 'Implementer', value: 'Implementer' },
    { id: 'Requestor', value: 'Requestor' }, { id: 'Reporter', value: 'Reporter' }];

    useEffect(() => {
        if (data.data) {
            formikCtx.setFieldValue('first_name', data.data.name.split(" ")[0]);
            formikCtx.setFieldValue('last_name', data.data.name.split(" ")[1]);
            formikCtx.setFieldValue('email', data.data.email);
            formikCtx.setFieldValue('user_id', data.data.user_id);
            setFilters((prevFilters: any) => {
                return {
                    ...prevFilters,
                    requestorName: [...data.data.role_group],
                };
            });
            formikCtx.setFieldValue('role_group', data.data.role_group.map((item: []) => item));
        }
    }, []);

    useEffect(() => {
        formikCtx.setFieldValue('role_group', filters.requestorName)
    }, [filters]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item sm={6}>
                    <FormikControl
                        control="textfield"
                        label="First name"
                        name="first_name"
                        isDisabled={true}
                        disabled={true}
                        required
                    />
                </Grid>
                <Grid item sm={6}>
                    <FormikControl
                        control="textfield"
                        label="Last name"
                        name="last_name"
                        isDisabled={true}
                        disabled={true}
                        required
                    />
                </Grid>
                <Grid item sm={6}>
                    <FormikControl
                        control="textfield"
                        label="Email address"
                        name="email"
                        isDisabled={true}
                        disabled={true}
                        required
                    />
                </Grid>
                <Grid item sm={6} className={styleClasses.extraPopup}>
                    <FilterMenu filterLabel="Role group" handleFilterClose={() => console.log("handleClose")}>
                        <FilterOptions
                            searchPlaceholder="Search"
                            optionsList={roleGroup}
                            selectedOptions={filters.requestorName}
                            handleOptionSelection={(e, selection) => {
                                setFilters((prevFilters: any) => {
                                    let newRequestNameArray = [...prevFilters.requestorName];
                                    if (!selection) {
                                        newRequestNameArray = newRequestNameArray.filter(
                                            (option) => option !== e.target.value
                                        );
                                    } else newRequestNameArray.push(e.target.value);
                                    return {
                                        ...prevFilters,
                                        requestorName: [...newRequestNameArray],
                                    };
                                });
                            }}
                            isFilterDataLoading={false}
                        />
                    </FilterMenu>
                </Grid>
            </Grid>
        </>
    );
}
export default EditUserDetails;
