/* eslint-disable react/jsx-props-no-spreading */
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { styled } from '@mui/material/styles';
import isSameDay from 'date-fns/isSameDay';
import isWithinInterval from 'date-fns/isWithinInterval';
import format from 'date-fns/format';
import * as React from 'react';
import calendarStyles from './calendarStyles';

type CustomPickerDayProps = PickersDayProps<Date> & {
  dayIsBetween: boolean;
  isFirstDay: boolean;
  isLastDay: boolean;
  isLastDayDefined: boolean;
};

interface ICustomDatePickerProps {
  isRangePicker: boolean;
  setDateRangeValue(_arg1: { startDate: Date | null, endDate: Date | null }): void;
  dateRange: { startDate: Date | null, endDate: Date | null };
  disablePast?: boolean,
  disableFuture?: boolean,
  shouldDisableDate?: (_date: Date) => boolean,
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop: any) => prop !== 'dayIsBetween'
    && prop !== 'isFirstDay'
    && prop !== 'isLastDay'
    && prop !== 'isLastDayDefined',
})<CustomPickerDayProps>(({
  dayIsBetween,
  isFirstDay,
  isLastDay,
  isLastDayDefined,
}: any) => ({
  ...{
    font: 'normal normal normal 13px/13px NotoSerif-Regular',
    color: '#0A2756 !important',
    height: '32px',
    width: '32px',
    '&:hover, &:focus': {
      backgroundColor: '#E8F0F2',
      borderRadius: '8px',
    },
    '&:disabled': {
      color: '#00000030 !important',
    },
  },
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: '#E8F0F2',
    fontFamily: 'NotoSerif-Bold !important',
    '&:hover, &:focus': {
      backgroundColor: '#E0E6E8 !important',
    },
  }),
  ...(isLastDay && {
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
    backgroundColor: '#99FDD1 !important',
    fontFamily: 'NotoSerif-Bold !important',
    '&:hover, &:focus': {
      backgroundColor: '#99FDD1 !important',
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
    backgroundColor: '#99FDD1 !important',
    fontFamily: 'NotoSerif-Bold !important',
    '&:hover, &:focus': {
      backgroundColor: '#99FDD1 !important',
    },
  }),
  ...(isFirstDay && !isLastDayDefined && {
    borderRadius: '8px',
  }),
})) as React.ComponentType<CustomPickerDayProps>;

const MemoizedArrowIcon = (className: string) => <KeyboardArrowDownIcon className={className} data-testid="datepicker-switchview" />;

export default function CustomDatePicker({
  isRangePicker,
  setDateRangeValue,
  dateRange,
  disablePast,
  disableFuture,
  shouldDisableDate,
}: ICustomDatePickerProps) {
  const renderCustomDateRange = (
    date: any,
    _selectedDates: Array<any>,
    pickersDayProps: PickersDayProps<any>,
  ) => {
    const pickersDayPropsReassign: PickersDayProps<Date> = { ...pickersDayProps };

    const start = dateRange?.startDate != null ? dateRange?.startDate : new Date();
    // eslint-disable-next-line no-nested-ternary

    const getEndDate = () => {
      if (dateRange?.endDate != null) {
        return dateRange?.endDate;
      }
      return dateRange?.startDate ? dateRange?.startDate : new Date();
    };
    const end = getEndDate();
    const dayIsBetween = isWithinInterval(new Date(`${date}`), { start, end });
    const isFirstDay = isSameDay(new Date(`${date}`), start);
    const isLastDay = isSameDay(new Date(`${date}`), end);
    const isLastDayDefined = isRangePicker && !!dateRange?.endDate;

    return (
      <CustomPickersDay
        {...pickersDayPropsReassign}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
        isLastDayDefined={isLastDayDefined}
        data-testid={`${format(date, 'dd MMM, yyyy')}`}
      />
    );
  };

  const handleCalendarViewChange = (view: string) => {
    if (view === 'year') {
      const timeout = setTimeout(() => {
        const yearToScroll = document
          ?.querySelector('.PrivatePickersYear-yearButton.Mui-selected');
        if (yearToScroll && yearToScroll.scrollIntoView) {
          yearToScroll.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        clearTimeout(timeout);
      }, 150);
    }
  };

  return (
    <div data-testid="daterange-picker-wrapper">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <style>
          {calendarStyles}
        </style>
        <StaticDatePicker
          data-testid="daterange-picker"
          displayStaticWrapperAs="desktop"
          label="Date picker"
          value={dateRange.startDate}
          onChange={(newValue: any) => {
            // console.log(newValue, 'date')
            setDateRangeValue(newValue);
          }}
          shouldDisableDate={shouldDisableDate}
          disableFuture={disableFuture}
          disablePast={disablePast}
          renderDay={renderCustomDateRange}
          onViewChange={handleCalendarViewChange}
          renderInput={() => (<div data-testid="daterange-input" />)}
          inputFormat="'Week of' MMM d"
          className="date-picker"
          showDaysOutsideCurrentMonth
          components={{
            SwitchViewIcon: ({ className }: any) => MemoizedArrowIcon(className),
          }}
        />
      </LocalizationProvider>
    </div>
  );
}
