import axios from "../common/axiosInstance";
export const fetchAllUserManagementData = async (
) => {
  const endPoint = '/admin_user_records_view/';
  const { data } = await axios.get(endPoint);
  return data;
};

export const fecthSearchByStatusData = async (postBody: any) => {
  // console.log("postBody", postBody);
  const endPoint = `/search_by_status/?first_name=${postBody.first_name}&last_name=${postBody.last_name}&email=${postBody.email}`;
  const { data } = await axios.get(endPoint, postBody);
  return data;
};
export const postAddNewUser = async (postBody: any) => {
  //console.log("postBody", postBody);
  const endPoint = '/admin_add_user/';
  const { data } = await axios.post(endPoint, postBody);
  //console.log("data@@",data)
  return data;
}
export const postEditUser = async (postBody: any) => {
  const endPoint = '/assign_and_remove_groups/';
  const { data } = await axios.post(endPoint, postBody);
  //console.log("data@@",data)
  return data;
}
export const postDeleteUser = async (postBody: any) => {
  const endPoint = '/admin_delete_user/';
  const { data } = await axios.post(endPoint, postBody);
  //console.log("data@@",data)
  return data;
}

