import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import useStyles from './useStyles';
import { useHistory } from 'react-router-dom';

interface BreadcrumbDetails {
  key: number,
  title: string,
  href: string
}

export default function breadcrumb({ breadcrumbData }:
  { breadcrumbData: BreadcrumbDetails[] }) {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      className={classes.breadcrumb}
      data-testid="breadcrumb"
    >
      {breadcrumbData.map((item, index) => (
        index !== breadcrumbData.length - 1 ? (
          <Link
            data-testid="breadcrumb-link"
            underline="hover"
            key={item.key}
            color="inherit"
            onClick={() => { history.push(item.href); }}
            className={classes.subtitle}
          >
            {item.title}
          </Link>
        )
          : (
            <Typography key={item.key} className={classes.title} data-testid="breadcrumb_SubTitle">{item.title}</Typography>
          )
      ))}
    </Breadcrumbs>
  );
}
