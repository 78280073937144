import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import useStyles from './useStyles';
import { request } from 'http';

interface IVerticalLinearStepper {
  activeStep: number;
  stepsData: { label: string }[];
  setActiveStep: (_step: any) => void;
  requestStatus?: string
}


export default function VerticalLinearStepper(
  { activeStep, stepsData, setActiveStep, requestStatus }: IVerticalLinearStepper,
) {

  const classes = useStyles();

  const stepsinfo = (currentState: any, index: number, classes: any) => {
    if (requestStatus === 'Draft') {
      return (
        <span
          className={`${classes.stepIcon}
         ${currentState.active && classes.active}
          ${currentState.completed && classes.completed}`}
        >
          {index + 1}
        </span>
      )
    } else {
      return (
        <span
          className={`${classes.stepIcon}
          ${classes.completed}`}
        >
          {index + 1}
        </span>
      )
    }
  }

  return (
    <Box className="stepper-div">
      <Stepper activeStep={activeStep} orientation="vertical" className={classes.stepper} data-testid="stepper">
        {stepsData.map((step, index) => (
          <Step className={classes.cursorPointer} data-testid="step" key={step.label} onClick={requestStatus === 'Draft' ? () => null : () => setActiveStep(index)}>
            <StepLabel
              className={classes.steperLabel}
              StepIconComponent={(currentState) => stepsinfo(currentState, index, classes)}
            >
              {step.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
