import axios from '../common/axiosInstance';


export const getAllParrequests = async () => {
    const endpoint = '/get_all_par/';
    const { data } = await axios.get(endpoint);
    return data;
}
export const fetchFilterEndPoints = {

    fetchDelegationStatusFilterEndPoint: '/get_delegation_status/?request_type=2',

};

export const fetchAllFilterRequests = async (endPoint: string) => {
    const { data } = await axios.get(endPoint);
    return data;
};

export const updateStatusOfPar = async (postBody: any) => {
    const endPoint = '/par_update_status/';
    const { data } = await axios.post(endPoint, postBody);
    return data;
};


export const postNewRequestPar = async (postBody: any) => {
    const endPoint = '/create_par/';
    console.log(postBody, 'postbody in axios');
    const { data } = await axios.post(endPoint, postBody);
    return data;
};

// par_date_raised: formatDate(successData.data.par_date_raised)
export const getAllCountryByCityId = async (cityName: string) => {
    let queryString = '';
    if (cityName && cityName !== '') {
        queryString += `?city=${cityName}`;
    }
    const { data } = await axios.get(`get_city_wise_country/${queryString}`);
    return data;
};
export const cancelPar = async (requestId: string) => {
    const endPoint = `/par_cancel_request_data/?par_id=${requestId}`;
    const { data } = await axios.get(endPoint);
    return data;
};

export const getParRequestData = async (requestId: string) => {
    const endPoint = `/par_get_request_data/?par_id=${requestId}`;
    const { data } = await axios.get(endPoint);
    return data;
};

export const getThirdPartyCompany = async () => {
    const endPoint = '/get_all_third_party_company_name/';
    const { data } = await axios.get(endPoint);
    return data;
}

export const getAllOffices = async () => {
    const endpoint = '/get_all_office/';
    const { data } = await axios.get(endpoint);
    return data;
}


export const getAllThirdPartyOffice = async () => {
    const endpoint = '/get_all_third_party_company_name/';
    const { data } = await axios.get(endpoint);
    return data;
}

// start of  edit-review-api 
export const postFieldCommentsPar = async (postBody: any) => {
    const endPoint = '/post_par_fields_comments/';
    const { data } = await axios.post(endPoint, postBody);
    return data;
}

export const fetchAllCommentsReplyPart = async (postBody: any, requestType: string) => {

    const endPoint = `/all_fields_latest_comment_reply/?request_type=${requestType}`;
    const { data } = await axios.post(endPoint, postBody);
    return data;
}
export const postParFieldHistoryData = async (postBody: any) => {
    const endPoint = '/post_par_fields_history/';
    const { data } = await axios.post(endPoint, postBody);
    return data;
};
export const fetchHistoryTableDataPar = async (postBody: any) => {
    const endPoint = '/get_par_fields_history/';
    const { data } = await axios.post(endPoint, postBody);
    return data;
}
// get_par_fields_comments
export const getSectionWiseCommentHistory = async (postBody: any) => {
    const endPoint = '/get_par_fields_comments/'
    const { data } = await axios.post(endPoint, postBody);
    return data;
}
export const postParCommentReply = async (postBody: any) => {
    const endPoint = '/post_par_fields_comments_reply/';
    const { data } = await axios.post(endPoint, postBody);
    return data;
}
// end of edit-review-api

// start of Implementer apis
export const postOnpremAad = async (postBody: any) => {
    const endPoint = '/par_on_premise_implementation/'
    const { data } = await axios.post(endPoint, postBody);
    return data;
}
export const getPostOnpremAad = async (request_id: any) => {
    const endPoint = `/get_par_on_premise_implementer/?par_id=${request_id}`;
    const { data } = await axios.get(endPoint);
    return data;
}
export const postOnpremAd = async (postBody: any) => {
    const endPoint = '/par_on_premise_ad_implementation/'
    const { data } = await axios.post(endPoint, postBody);
    return data;
}
export const getPostOnpremAd = async (request_id: any) => {
    const endPoint = `/get_par_on_premise_ad_implementation/?par_id=${request_id}`;
    const { data } = await axios.get(endPoint);
    return data;
}

export const postCloudAad = async (postBody: any) => {
    const endPoint = '/par_cloud_implementation/'
    const { data } = await axios.post(endPoint, postBody);
    return data;
}
export const getCloudAad = async (request_id: any) => {
    const endPoint = `/get_par_cloud_implementer/?par_id=${request_id}`;
    const { data } = await axios.get(endPoint);
    return data;
}


// end of Implementer apis