import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { phoneInputStyles } from './useStyles';

function PhoneInputField({
  label,
  onChange,
  defaultCountry,
  placeholder,
  value,
  error,
  disabled,
}: {
  label: string,
  onChange: (_value: string) => void,
  defaultCountry: string,
  placeholder: string,
  value: string,
  error?: string,
  disabled?: boolean,
}) {
  const phoneInputClasses = phoneInputStyles();
  return (
    <div data-testid="phone-input-wrapper">
      <PhoneInput
        data-testid="phone-input"
        // defaultErrorMessage={error}
        value={value}
        onChange={onChange}
        specialLabel={label}
        country={defaultCountry}
        countryCodeEditable={false}
        inputProps={{ fullwidth: true, helperText: error }}
        placeholder={placeholder}
        containerClass={phoneInputClasses.container}
        inputClass={`${phoneInputClasses.inputField} ${error && phoneInputClasses.inputError}`}
        dropdownClass={phoneInputClasses.dropdown}
        disabled={disabled}
      />
      {error && (<p className={phoneInputClasses.errorSpan} data-testid="phone-input-error">{error}</p>)}
    </div>
  );
}

export default PhoneInputField;
