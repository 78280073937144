import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    root: {
        "& > .SnackbarContent-root": {
            padding: "20px ",
        },
        "& > *": {
            font: "normal normal normal 14px/21px Poppins-Regular ",
        },
    },
    success: {
        background: "#00FFB4 ",
        color: "#0A2756 ",
    },
    error: {
        background: "#EF5B2B ",
        color: "#FFFFFF ",
    },
    warning: {
        background: "#FFA800 ",
        color: "#FFFFFF ",
    },
    info: {
        background: "#0080FF ",
        color: "#FFFFFF ",
    },
});
export default useStyles;
