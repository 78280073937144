import * as Yup from "yup";
import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Formik,
  Form,
  ArrayHelpers,
  FieldArray,
  FormikProps,
  FormikValues,
  useFormikContext,
  useFormik,
  FormikProvider,
} from "formik";
import { Avatar, Box, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { tableHeadStyle, tableRowStyle } from '../../../components/review-actions/useStyles';
import FormikControl from "../../../components/formik-conrtols/FormikControl";
import CustomButton from "../../../components/button";
import approverManagementStyles from "./approver-management-styles";
import {
  fetchAllStages,
  fetchStageWiseApprover,
  fetchAllApprover,
  postApproverData,
} from "../../../api-services/approver-management";
import EditApproverInfo from "./EditApproverInfo";
import { useSnackbar } from "notistack";
import { IApprovarSummaryData } from "./approvarManagementUtils";

function useApproverManagementHook() {
  const styleClasses = approverManagementStyles();
  const { enqueueSnackbar } = useSnackbar();
  const tableHeadClasses = tableHeadStyle();
  const tableRowClasses = tableRowStyle()
  const formRef = useRef<FormikProps<FormikValues>>(null);
  const isAutoCompleteSelected = useRef(false);
  const [allStageData, setAllStageData] = useState([]);
  const [allApproverData, setAllApproverData] = useState<{ id: number; name: string; email: string }[]
  >([]);

  useEffect(() => {
    fetchAllStagesOnLoad()
  }, []);

  const [expandedPanel, setExpandedPanel] = useState("");
  const handleAccordionChange = (panel: any) => {
    if (expandedPanel !== "panel" + panel) {
      setExpandedPanel("panel" + panel);
    } else {
      setExpandedPanel("");
    }
  };

  const initialValuesModel = {
    stage_name: "",
    primary_approver: "",
    primary_approver_email: "",
    backup_approver_1: "",
    backup_approver_1_email: "",
    backup_approver_2: "",
    backup_approver_2_email: "",
  };
  const validationSchemaModel = Yup.object({
    stage_name: Yup.string().required("Required"),
    primary_approver: Yup.string().required("Required"),
    backup_approver_1: Yup.string(),
    backup_approver_1_email: Yup.string().required("Required"),
    backup_approver_2: Yup.string().required("Required"),
    backup_approver_2_email: Yup.string(),
  });

  const [postformValues, setPostFormValues] = useState<IApprovarSummaryData>({
    stage_name: '',
    primary_approver: '',
    backup_approver_1: '',
    backup_approver_1_email: '',
    backup_approver_2: '',
    backup_approver_2_email: '',
  });
  const [modelFormValues, setModelFormValues] = useState(initialValuesModel);
  const [titleForPopup, setTitleForPopup] = useState<{ data: any }>({ data: null });
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<{
    data: any;
    type: string;
  }>({ data: null, type: "" });
  const [dependencyData, setDependencyData] = useState([])
  const [updateReason, setUpdateReason] = useState("");

  useEffect(() => {
    setModalOpen(modalOpen);
  }, [modalOpen])

  const handleModalClose = () => {
    setModalOpen(false);
    setUpdateReason('')
  };

  const fetchAllStagesOnLoad = () => {
    fetchAllStages()
      .then((response: any) => {
        setAllStageData(response.data);
      })
      .catch((error) => console.log(error));
    fetchAllApprover()
      .then((response: any) => {
        response.data.push({ id: 0, name: "None", email: "NA" });
        setAllApproverData(response.data);
      })
      .catch((error) => console.log(error));
  }

  const OnClickStageName = React.useCallback(() => {
    const initialValues = {
      primary_approver: "",
      primary_approver_email: "",
      backup_approver_1: "",
      backup_approver_1_email: "",
      backup_approver_2: "",
      backup_approver_2_email: "",
    };
    const [formValues, setFormValues] = useState(initialValues);
    let id = expandedPanel.replace("panel", "");
    useEffect(() => {
      fetchStageWiseApprover({
        stage: id,
      })
        .then((res) => {
          let obj: any = {
            primary_approver: res.data.primary.name,
            primary_approver_email: res.data.primary.email,
            backup_approver_1: res.data.secondary1.name == undefined ? 'None' : res.data.secondary1.name,
            backup_approver_1_email: res.data.secondary1.email == undefined ? 'NA' : res.data.secondary1.email,
            backup_approver_2: res.data.secondary2.name == undefined ? 'None' : res.data.secondary2.name,
            backup_approver_2_email: res.data.secondary2.email == undefined ? 'NA' : res.data.secondary2.email,
          };
          setFormValues({ ...obj });
        })
        .catch((err) => {
          console.log(err);
        });
    }, []);

    return (
      <Formik
        initialValues={formValues}
        onSubmit={() => { console.log("submit!"); }}
        enableReinitialize={true}
      >
        <Form >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormikControl
                control="textfield"
                label="Primary approver"
                name="primary_approver"
                disabled={true}
              />
            </Grid>
            <Grid item xs={6}>
              <FormikControl
                control="textfield"
                label="Email address"
                name="primary_approver_email"
                disabled={true}
              />
            </Grid>
            <Grid item xs={6}>
              <FormikControl
                control="textfield"
                label="Backup approver 1"
                name="backup_approver_1"
                disabled={true}
              />
            </Grid>
            <Grid item xs={6}>
              <FormikControl
                control="textfield"
                label="Email address"
                name="backup_approver_1_email"
                disabled={true}
              />
            </Grid>
            <Grid item xs={6}>
              <FormikControl
                control="textfield"
                label="Backup approver 2"
                name="backup_approver_2"
                disabled={true}
              />
            </Grid>
            <Grid item xs={6}>
              <FormikControl
                control="textfield"
                label="Email address"
                name="backup_approver_2_email"
                disabled={true}
              />
            </Grid>
          </Grid>
        </Form>
      </Formik>
    );
  }, [expandedPanel]);

  const handleEditModalOpen = (item: any, Title: string) => {
    setModalOpen(false);
    handleModalOpen("EditApprovar", item, Title)
  }

  const handleModalOpen = (actionType: string, item: any, Title: any) => {
    if (actionType == 'EditApprovar') {
      setTitleForPopup({ data: item })
      let id = expandedPanel.replace("panel", "");
      fetchStageWiseApprover({
        stage: id,
      })
        .then((res) => {
          let obj: any = {
            stage_name: item.value,
            primary_approver: res.data.primary.name,
            primary_approver_email: res.data.primary.email,
            backup_approver_1: res.data.secondary1.name == undefined ? 'None' : res.data.secondary1.name,
            backup_approver_1_email: res.data.secondary1.email == undefined ? 'NA' : res.data.secondary1.email,
            backup_approver_2: res.data.secondary2.name == undefined ? 'None' : res.data.secondary2.name,
            backup_approver_2_email: res.data.secondary2.email == undefined ? 'NA' : res.data.secondary2.email,
            request_type: Title,
            stage: id
          };
          setModelFormValues({ ...obj });
          setModalData({ data: obj, type: actionType });
          setModalOpen(true);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setModalData({ data: item, type: actionType });
      setModalOpen(true);
    }

  };

  const modalHeaderHandler = (type: string) => {
    // console.log("modalData", modalData)
    switch (type) {
      case 'EditApprovar':
        return `Stage ${titleForPopup && titleForPopup?.data.priority} - ${titleForPopup.data.value}`;
      case 'Note':
        return 'Role assignment';
      default: return '';
    }
  };

  const handleSave = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const idValue = (label: string) => {
    const result = allApproverData.find((item: any) => item.name === label);
    return result?.id;
  }

  const onFormSubmit = (values: any, submitProps: any) => {
    setPostFormValues(values)
    setModalOpen(false);
    const postBody = {
      primary_approver: idValue(values.primary_approver),
      secondary_approver1: idValue(values.backup_approver_1) == 0 ? null : idValue(values.backup_approver_1),
      secondary_approver2: idValue(values.backup_approver_2) == 0 ? null : idValue(values.backup_approver_2),
      stage: values.stage,
      stage_name: values.stage_name,
      request_type: values.request_type,
    };
    postApproverData(postBody).then((res) => {
      if (res.data && res.data.length > 0) {
        handleModalOpen("Note", res.data, null);
        if (res.data.length == 2 && res.data[0].secondary_approver1 !== 0 && res.data[1].secondary_approver2 !== 0) {
          setUpdateReason(`You are trying to remove ${res.data[0].secondary_approver1_fname} ${res.data[0].secondary_approver1_lname} and ${res.data[1].secondary_approver2_fname} ${res.data[1].secondary_approver2_lname} from their role. Currently ${res.data[0].secondary_approver1_fname} and ${res.data[1].secondary_approver2_fname} are action owners for the following number of request.`)
        } else if ((res.data.length == 2 && res.data[0].secondary_approver1 !== 0 && res.data[1].secondary_approver2 == 0)) {
          setUpdateReason(`You are trying to remove ${res.data[0].secondary_approver1_fname} ${res.data[0].secondary_approver1_lname} from their role. Currently ${res.data[0].secondary_approver1_fname} is an action owner for the following number of ${res.data[0].secondary_approver1 > 0 ? `requests` : `request`}:`)
        }
        else if ((res.data.length == 2 && res.data[0].secondary_approver1 == 0 && res.data[1].secondary_approver2 !== 0)) {
          setUpdateReason(`You are trying to remove ${res.data[1].secondary_approver2_fname} ${res.data[1].secondary_approver2_lname}  from their role. Currently ${res.data[1].secondary_approver2_fname} is an action owner for the following number of request:`)
        } else if ((res.data.length == 1 && res.data[0].secondary_approver1 !== 0) && (res.data.length == 1 && res.data[0].secondary_approver2 !== 0)) {
          if (res.data[0].secondary_approver1 !== undefined) {
            setUpdateReason(`You are trying to remove ${res.data[0].secondary_approver1_fname} ${res.data[0].secondary_approver1_lname}  from their role. Currently ${res.data[0].secondary_approver1_fname} is an action owner for the following number of request:`)
          } else {
            setUpdateReason(`You are trying to remove ${res.data[0].secondary_approver2_fname} ${res.data[0].secondary_approver2_lname}  from their role. Currently ${res.data[0].secondary_approver2_fname} is an action owner for the following number of request:`)
          }
        }
        else {
          enqueueSnackbar(`Approver ${values.stage_name} for ${values.request_type} has been updated successfully.`, { variant: 'success' });
          setModalOpen(false);
        }
        fetchAllStagesOnLoad();
      } else {
        enqueueSnackbar(`Approver ${values.stage_name} for ${values.request_type} has been updated successfully.`, { variant: 'success' });
        fetchAllStagesOnLoad();
        setModalOpen(false);
      }
      setExpandedPanel('')
    }).catch((error) => {
      console.log("error", error)
    })
    setModalOpen(false);
  };



  const modalContentHandler = (modalData: any, type: any) => {
    switch (type) {
      case "EditApprovar":
        return (
          <Formik
            initialValues={modelFormValues}
            validationSchema={validationSchemaModel}
            onSubmit={onFormSubmit}
            enableReinitialize={true}
            innerRef={formRef}
          >
            <Form>
              <EditApproverInfo data={modelFormValues} approverlist={allApproverData} />
            </Form>
          </Formik>
        )
      case "Note":
        return (
          <div>
            <div className={styleClasses.modalContentDiv}>
              {updateReason}
            </div>
            <Table className={styleClasses.TableForDependency}>
              <TableHead classes={{ root: tableHeadClasses.head }}>
                <TableRow sx={{ '&:first-of-type(th)': { border: 0 } }}>
                  <TableCell classes={{ root: tableHeadClasses.cell }}>Role Group</TableCell>
                  <TableCell classes={{ root: tableHeadClasses.cell }}>Delegation/PAR request count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className={tableRowClasses.cell}>Approver </TableCell>
                  <TableCell className={tableRowClasses.cell}>{
                    (modalData.data && modalData.data.length > 1) ?
                      Number(modalData.data[1].secondary_approver2) + Number(modalData.data[0].secondary_approver1)
                      : modalData.data[0].secondary_approver1 !== 0 && modalData.data[0].secondary_approver1 !== undefined ?
                        modalData.data[0].secondary_approver1 : (modalData.data[0].secondary_approver2
                        )}

                    {/* // Number(modalData.data[1]?.secondary_approver2 == undefined ? 0 : modalData.data[1]?.secondary_approver2) + Number(modalData.data[0]?.secondary_approver1 == undefined ? 0 : modalData.data[0].secondary_approver1) */}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <div className={styleClasses.modalTextDiv}>
              <span className={styleClasses.spanText}>
                {'Note: '}
              </span>
              {modalData.data && modalData.data.length > 1 ? <>
                {modalData.data[0].secondary_approver1 !== 0 && modalData.data[1].secondary_approver2 == 0 ?
                  <>
                    Before {modalData.data[0].secondary_approver1_fname}  can be removed from the Approver, these requests will need to be assigned to an another individual. <br /><br />• Go to the <b>Administrator Summary </b>page <br />• Search for the user in the <b>Action Owner</b> column and reassign.<br /><br />  Once complete, you will be able to remove {modalData.data[0].secondary_approver1_fname} from their role.
                  </>
                  : modalData.data[0].secondary_approver1 == 0 && modalData.data[1].secondary_approver2 !== 0 ?
                    <>
                      Before {modalData.data[1].secondary_approver2_fname} can be removed from the Approver, these requests will need to be assigned to an another individual.<br /> <br />• Go to the  <b>Administrator Summary </b>page <br />• Search for the user in the <b>Action Owner </b> column and reassign. <br /><br />Once complete, you will be able to remove {modalData.data[1].secondary_approver2_fname} from their role.
                    </>
                    :
                    <>
                      Before {modalData.data[0].secondary_approver1_fname} and {modalData.data[1].secondary_approver2_fname} can be removed from their roles, these requests will need to be assigned to other individuals.
                      <br /><br />
                      • Go to the <b>Administrator Summary</b> page <br />
                      • Search for the user in the<b> Action Owner </b> column and reassign
                      .<br /><br /> Once complete, you will be able to remove {modalData.data[0].secondary_approver1_fname} and {modalData.data[1].secondary_approver2_fname} from their roles.
                    </>
                }
              </>
                : (postformValues.backup_approver_1 == 'None' && modalData.data[0].secondary_approver1 !== undefined) ?
                  <>
                    Before {modalData.data[0].secondary_approver1_fname}  can be removed from the Approver, these requests will need to be assigned to an another individual. <br /><br />• Go to the <b>Administrator Summary </b>page <br />• Search for the user in the <b>Action Owner</b> column and reassign.<br /><br />  Once complete, you will be able to remove {modalData.data[0].secondary_approver1_fname} from their role.
                  </>
                  : <>
                    Before {modalData.data[0].secondary_approver2_fname} can be removed from the Approver, these requests will need to be assigned to an another individual.<br /> <br />• Go to the  <b>Administrator Summary </b>page <br />• Search for the user in the <b>Action Owner </b> column and reassign. <br /><br />Once complete, you will be able to remove {modalData.data[0].secondary_approver2_fname} from their role.
                  </>
              }
            </div>
          </div >
        );
      default:
        return null;
    };
  }


  const modalActionHandler = (modalData: any, type: any) => {
    switch (type) {
      case "EditApprovar":
        return (
          <div className={styleClasses.modalActionDiv}>
            <CustomButton
              variant="contained"
              actionType="secondary"
              onClick={handleModalClose}
            >
              Cancel
            </CustomButton>
            <CustomButton
              variant="contained"
              actionType="primary"
              disableMargin
              onClick={handleSave}
            >
              Save
            </CustomButton>
          </div>
        )
      case "Note":
        return (
          <div className={styleClasses.modalActionDiv}>
            <Box className={styleClasses.modalActionDivNew}>
              <CustomButton
                variant="contained"
                actionType="primary"
                disableMargin
                onClick={handleModalClose}
              >
                Ok
              </CustomButton>
            </Box>
          </div>
        );
      default:
        return null;
    };
  };


  return {
    OnClickStageName,
    handleEditModalOpen,
    modalOpen,
    modalHeaderHandler,
    modalContentHandler,
    handleModalClose,
    modalActionHandler,
    modalData,
    allStageData,
    expandedPanel,
    handleAccordionChange,
  };
}
export default useApproverManagementHook;
