


const stringToColor = (nameString: string) => {
    let hash = 0;
    let i;
    for (i = 0; i < nameString.length; i += 1) {
        // eslint-disable-next-line no-bitwise
        hash = nameString.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >= (i * 8)) && 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
};
export const stringAvatar = (name: string) => ({
    sx: {
        bgcolor: stringToColor(name),
        fontSize: '16px',
        fontFamily: 'Poppins-Regular',
    },
    children: `${name && name.split(' ').length > 1
        // eslint-disable-next-line no-unsafe-optional-chaining
        ? name?.split(' ')[0][0] + name?.split(' ')[1][0] : name[0]}`,
});