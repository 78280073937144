/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from 'react';
import axios from "axios";
import { useDropzone } from 'react-dropzone';
import { envConstants } from '../../../../config/env-constants';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/x.svg';
import useStyles from '../../useStyles';
import useStylesDelegation from '../useStylesDelegation';
import { removeUploadDelegation } from '../../../../api-services/delegation-module';
import CustomButton from '../../../button';
import { isEqual } from 'lodash';
import { union } from "lodash";
import { useSnackbar } from 'notistack';

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
  position: 'relative',
};

const img = {
  display: 'block',
  height: 'auto',
  width: 'auto',
  maxWidth: '200px',
  maxHeight: '95px',
};

interface ICustomFileUpload {
  fileAction?: React.ReactNode;
  acceptFileType?: any;
  disabled?: boolean;
  requestId: string;
  getFiles: any;
}

function Signature({ fileAction, acceptFileType, disabled, requestId, getFiles }: ICustomFileUpload) {
  const classes = useStylesDelegation()
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [progress, setProgress] = useState({ started: false, pc: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [msg, setMsg] = useState('');
  const [fileData, setFileData] = useState({
    id: '',
    url: ''
  })
  const { enqueueSnackbar } = useSnackbar();

  const onDrop = useCallback((acceptedFilesArray) => {
    setSelectedFiles(acceptedFilesArray.map((file: any) => Object.assign(file, {
      preview: URL.createObjectURL(file),
    })));
  }, []);

  const authToken = JSON.parse(localStorage.getItem('okta-token-storage') || '');
  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: acceptFileType,
    disabled: disabled,
  });


  // start of file deletion 
  const removeFileHandler = (Id: any, url: any) => {
    const postBody = {
      id: fileData.id !== '' ? fileData.id : Id,
      file: fileData.url !== '' ? fileData.url.replace('https://uks00sta1gpapdev01.blob.core.windows.net/devcontainer01/gpag_dev_asset/', '') : url.replace('https://uks00sta1gpapdev01.blob.core.windows.net/devcontainer01/gpag_dev_asset/', '')
    }
    removeUploadDelegation(postBody).then((response) => {
      setSelectedFiles([]);
      enqueueSnackbar('File removed successfully', { variant: 'success' });
    }).catch(err => err);
  }


  // End of file deletion
  const fileUploadHandler = () => {
    if (!acceptedFiles[0]) {
      setMsg("No file selected");
      return;
    }
    const formData = new FormData();
    // @ts-ignore
    formData.append("file", acceptedFiles[0]);
    formData.append("file_section", 'signature');
    formData.append("request_id", requestId);

    setProgress(prevState => {
      return { ...prevState, started: true }
    })
    setIsLoading(true);
    axios
      .post(`${envConstants.baseUrl}/api/upload/`, formData, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": authToken.idToken.idToken,
        },
      })
      .then((response) => {
        setFileData({ id: response.data.data.id, url: response.data.data.file })
        setIsLoading(false);
        enqueueSnackbar('File uploaded successfully', { variant: 'success' });
      })
      .catch((error) => {
        enqueueSnackbar("Something went wrong, please try again", {
          variant: "error",
        });
      });
  }

  useEffect(() => {
    setSelectedFiles([
      ...union(
        selectedFiles,
        acceptedFiles,
        getFiles.length > 0 &&
        getFiles.map((obj: any) => {
          return {
            extension: obj.extension,
            file: obj.file,
            file_section: obj.file_section,
            // file_type: obj.file_type,
            filename: obj.filename,
            id: obj.id,
          };
        })
      ),
    ]);
  }, []);


  useEffect(() => {
    fileUploadHandler();
  }, [acceptedFiles])

  // @ts-ignore
  const thumbs = selectedFiles.map((file: any) => (
    <>
      <div key={file.name}>
        <div className={classes.InnerThumbnail}>
          <div className={classes.closeIconStyle}>
            {!disabled &&
              <CloseIcon
                onClick={() => removeFileHandler(file.id, file.file ? file.file : file.url)}
              />
            }
          </div>
          <img
            src={file.file || fileData.url}
            width="200px"
            height="200px"
            className={classes.signatureImageContainer}
          />
        </div>
      </div>
    </>
  ));

  // end of preview generation

  const errorMessage = fileRejections[0]?.errors[0]?.message;
  useEffect(() => {
    return () => selectedFiles.forEach((selectedFile: any) => URL.revokeObjectURL(selectedFile.preview));
  }, []);

  return (
    <>
      <div>
        {
          (thumbs.length > 0) && (
            <aside>
              {thumbs}
            </aside>)
        }
        <div {...getRootProps()}>
          {<input {...getInputProps()} />}
          {!(thumbs.length > 0) && (fileAction)}
          {(fileRejections.length > 0)
            ? (
              <span
                style={{
                  color: '#EF5B2B',
                  fontSize: '10px',
                  fontFamily: 'NotoSerif-Regular',
                }}
              >
                {errorMessage}
              </span>
            )
            : null}
        </div>
      </div >
    </>

  );
}
export default Signature;
