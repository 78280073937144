import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { stringAvatar } from '../../../constants/customAvatar';
import { ReactComponent as CommentSvg } from '../../../assets/icons/comment.svg';
import CustomSvgText from '../../../components/cutomsvgtext/custtomSvg';
import { RootState } from '../../../appStore/store';
import { useSelector } from 'react-redux';
import FormikControl from '../../../components/formik-conrtols/FormikControl';
import ReviewCommentsContainer from './commentsContainer';
import { ReactComponent as PostArrowButton } from '../../../assets/icons/post_arrow_button.svg';
import { postFieldCommentsPar, postParCommentReply } from '../../../api-services/par';
import { Avatar, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import useDateTimeHook from '../../../components/date-timehook/useDateTimeHook';
import ChatBox from './chatBox';
import { Box } from '@mui/system';
import { useDispatch } from "react-redux";
import CustomButton from '../../../components/button';
import { editParComments, requestorApproverFreshComments, requestorEditReply, requestorDeleteReply } from '../../../appStore/reducer/submitParApproverComments';
import { postFreshComments, deleteParComments } from '../../../appStore/reducer/submitParApproverComments';
import { useSnackbar } from 'notistack';


interface IRequestorPostData {
    id?: string | number,
    value?: string,
    updated_by?: string,
    user_first_name?: string,
    user_last_name?: string,
    last_updated?: string
}

interface ICommentSubmission {
    sectionName: string,
    subSection: string,
    updateType: string,
    remediation_by?: string,
    data: {
        id: string | number,
        section: string,
        user_first_name: string,
        user_last_name: string,
        sub_section: string,
        update_type: string,
        last_updated: string | Date,
        updated_by: string,
        value: string,
        stage: string,
        requestor_reply?: IRequestorPostData[]
    }[] | any,
    requestorData: {
        id?: string | number,
        value?: string,
        updated_by?: string,
        user_first_name?: string,
        user_last_name?: string,
        last_updated?: string
        comment_id?: string | number,
        section?: string
    }[] | any
}


type editedData = {
    id: any,
    section: any,
    value: any,
    stage: any,
}
const chatBoxstyles = makeStyles({
    avatar: {
        width: '35px !important',
        height: '35px !important',
        color: '#00B5B1',
    },
    contentDiv: {
        fontFamily: 'NotoSerif-Regular',
        fontSize: '14px',
        color: '#0A2756',
        borderRadius: '4px'
    },
    stageText: {
        fontFamily: 'NotoSerif-Italic',
        fontSize: '10px',
        color: '#A7A7A7',
        paddingTop: '11px',
        paddingBottom: '14px',
    },
    actionButton: {
        display: 'flex',
        justifyContent: 'end',
        '& .MuiButton-root': {
            padding: '4px 16px !important',
            fontSize: '12px !important',
        },
        '& .MuiButton-root:nth-of-type(1)': {
            marginRight: '10px !important',
        }
    }
})

const CommentSubmission = ({ sectionName, subSection, updateType, data, remediation_by, requestorData }: ICommentSubmission) => {
    const dispatch = useDispatch();
    const chatStyles = chatBoxstyles();
    const formikCtx: any = useFormikContext();
    const { submitParCommentsData } = useSelector((state: RootState) => state);

    const { loginsyncupdata } = useSelector((state: RootState) => state.loginData);
    const [booleanFlag, setBooleanFlag] = useState(false);
    const [replyToggler, setReplyToggler] = useState(false);
    const [isTextFieldDisabled, setIsTextFieldDisabled] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [fieldNameObject, setFieldNameObject] = useState({
        section: '',
        subSection: '',
        updateType: '',
    })
    const [anchorEl, setAnchorEl] = useState<SVGElement | HTMLDivElement | null>(
        null,
    )

    const [editToggler, setEditToggler] = useState(false);
    const [anchorElEdit, setAnchorElEdit] = useState<SVGElement | HTMLDivElement | null>(
        null,
    )
    const [editPostCall, setEditPostCallData] = useState({
        id: null,
        section: '',
        value: '',
        stage: '',
    });
    const [editPostRequestor, setEditPostRequestor] = useState({
        reply_id: null,
        value: '',
    })

    const status = formikCtx.getFieldProps('status_label');
    const checkIsDisabled = () => {

        return status.value === 'Remediation Requested' || status.value === 'Draft'
    }
    const routeCheck = () => {
        return location.pathname.slice(1, 4).toLocaleLowerCase() === 'par'
    }

    const routeCheckForImplementationSummary = () => {
        return location.pathname.slice(1, 47).toLocaleLowerCase() === 'myimplementationtasks/pendingimplementationpar'
    }
    const routCheckApprovalSummary = () => {
        return location.pathname.slice(1, 35).toLocaleLowerCase() === 'myapprovaltasks/approvalsummarypar'
    }
    const routeCheckForAdminSummary = () => {
        return location.pathname.slice(1, 26).toLocaleLowerCase() === 'myadministrativetasks/par'
    }
    const routeCheckForImplementationSummaryview = () => {
        return location.pathname.slice(1, 47).toLocaleLowerCase() === 'myimplementationtasks/implementationsummarypar'
    }

    const routCheckPendingSummary = () => {
        return location.pathname.slice(1, 35).toLocaleLowerCase() === 'myapprovaltasks/pendingapprovalpar'
    }


    const currentRouteCheck = () => {

        if (routCheckApprovalSummary()) {
            return false;
        } else if (routeCheck()) {
            return false;
        } else if (routeCheckForImplementationSummary()) {
            return false;
        } else if (routeCheckForAdminSummary()) {
            return false;
        } else if (routeCheckForImplementationSummaryview()) {
            return false;
        } else if (routCheckPendingSummary()) {
            return true
        }
    }
    const newArray = [] as any;

    const open = !!anchorEl;
    const openEdit = !!anchorElEdit;
    const freshComment = () => {
        if (data.length > 0 && (data[data.length - 1].current_remediation === formikCtx.values.remediation_count) && (data[data.length - 1].updated_by === loginsyncupdata.email)) {

            return data[data.length - 1];
        } else {
            return null;
        }
    }
    const tempData = freshComment();
    useEffect(() => {
        setFieldNameObject((prevState) => ({
            ...prevState,
            section: sectionName,
            subSection: subSection,
            updateType: updateType,
        }))
    }, [])

    //start of delete requestor reply
    const requestorReplyDeleteHandler = () => {
        const deleteReplyPostBody = {
            comment_id: data[data.length - 1].id,
            reply_id: data[data.length - 1].requestor_reply[0].id || data[data.length - 1].requestor_reply[0].reply_id,
            value: formikCtx.getFieldProps(sectionName).value,
            is_active: false
        }

        if (formikCtx.getFieldProps(sectionName).value !== '') {
            postParCommentReply(deleteReplyPostBody).then((response) => {
                dispatch(requestorDeleteReply({ comment_id: data[data.length - 1].id, section: sectionName }))
                formikCtx.setFieldValue(sectionName, '');
                setReplyToggler(true);
                handleCloseRequestor();
            })
        } else {
            enqueueSnackbar(`Empty comment cannot be posted`, {
                variant: 'warning',
            });
            handleCloseRequestor()
        }
    }
    // end of delete requestor reply

    // start of edit requestor reply
    const requestorReplyEditPostHandler = () => {
        const replyEditBody = {
            comment_id: data[data.length - 1].id,
            reply_id: data[data.length - 1].requestor_reply[0].id || data[data.length - 1].requestor_reply[0].reply_id,
            section: sectionName,
            user_first_name: data[data.length - 1].requestor_reply[0].user_first_name,
            user_last_name: data[data.length - 1].requestor_reply[0].user_last_name,
            updated_by: loginsyncupdata.email,
            value: formikCtx.getFieldProps(sectionName).value,
            is_active: true
        }
        if (formikCtx.getFieldProps(sectionName).value !== '' || formikCtx.getFieldProps(sectionName).value !== undefined) {
            postParCommentReply(replyEditBody).then((response) => {
                const newObj = {
                    ...response.data,
                    section: sectionName,
                    comment_id: data[data.length - 1].id,
                }
                dispatch(requestorEditReply(replyEditBody))
                setReplyToggler(false);
                setAnchorEl(null);
            })
        } else {
            enqueueSnackbar(`Empty comment cannot be posted`, {
                variant: 'warning',
            });
            handleClose();
        }
    }
    // end of Edit requestor reply

    // start of fresh reply for approver comment
    const requestorReplyPostHandler = () => {

        const replyBody = {
            comment_id: data[data.length - 1].id,
            reply_id: null,
            section: sectionName,
            user_first_name: loginsyncupdata.first_name,
            user_last_name: loginsyncupdata.last_name,
            updated_by: loginsyncupdata.email,
            value: formikCtx.getFieldProps(sectionName).value,
            is_active: true
        }
        if (formikCtx.getFieldProps(sectionName).value !== '' || formikCtx.getFieldProps(sectionName).value !== undefined) {
            postParCommentReply(replyBody).then((response) => {

                console.log('secondattempt', response)
                const newObj = {
                    ...response.data,
                    section: sectionName,

                    comment_id: data[data.length - 1].id,
                }
                dispatch(requestorApproverFreshComments(newObj))
                setReplyToggler(true);
                handleCloseRequestor();
            })
        } else {
            enqueueSnackbar(`Empty comment cannot be posted`, {
                variant: 'warning',
            });
            handleCloseRequestor();
        }

    }
    // end fresh reply for approver comment

    // start of approver's edit functionality
    const editPostHandler = () => {
        let selectedData: any;
        submitParCommentsData.map((row) => {
            Object.entries(row).map(([key, value]) => {
                if (key === sectionName) {
                    console.log(value, '1')
                    selectedData = value.find((item: any) => item.updated_by === loginsyncupdata.email)
                }
            })
        })
        const commentpostBody = {
            par_id: formikCtx.values.request_id,
            data: [
                {
                    comment_id: selectedData.id,
                    update_type: 'text update',
                    section: sectionName,
                    user_first_name: loginsyncupdata?.first_name,
                    user_last_name: loginsyncupdata?.last_name,
                    sub_section: '',
                    value: formikCtx.getFieldProps(sectionName).value,
                    updated_by: loginsyncupdata.email,
                    is_active: true
                }
            ]
        }
        if (formikCtx.getFieldProps(sectionName).value !== '' || formikCtx.getFieldProps(sectionName).value !== undefined) {
            postFieldCommentsPar(commentpostBody).then((response: any) => {
                handleClose();
                dispatch(editParComments(response.data[0]))
            })
        } else {
            enqueueSnackbar(`Empty comment cannot be posted`, {
                variant: 'warning',
            });
            handleClose();
        }
    }
    // end of approver's edit functionality


    // start of edit features buttons
    const editContentHandler = () => {

        return (
            <div className={chatStyles.actionButton}>
                <CustomButton
                    variant="outlined"
                    actionType="secondary"
                    disableMargin
                    onClick={handleClose}
                    size='small'
                >
                    Cancel
                </CustomButton>
                <CustomButton
                    variant="contained"
                    actionType="primary"
                    disableMargin
                    onClick={editPostHandler}
                    size='small'
                >
                    Save
                </CustomButton>
            </div>
        )
    }

    // end of edit features buttons

    // start of approver's delete comment function
    const deleteContentHandler = () => {
        let selectedData: any;
        submitParCommentsData.map((row) => {
            Object.entries(row).map(([key, value]) => {
                if (key === sectionName) {
                    selectedData = value.find((item: any) => item.updated_by === loginsyncupdata.email)
                }
            })
        })

        const commentpostBody = {
            par_id: formikCtx.values.request_id,
            data: [
                {
                    comment_id: selectedData.id,
                    update_type: 'text update',
                    section: sectionName,
                    sub_section: '',
                    value: selectedData.value,
                    is_active: false
                }
            ]
        }
        postFieldCommentsPar(commentpostBody).then((response) => {
            dispatch(deleteParComments(response.data[0]))
            formikCtx.setFieldValue(fieldNameObject.section, '')
        }, (error) => {
            console.log(error);
        });
        handleClose();
    }
    // end of approver's delete comment function

    // start of onCLick function for initial icon
    const handleClickForView = (event: React.MouseEvent<SVGElement>) => {
        if (tempData !== null) {
            setIsTextFieldDisabled(true);
            formikCtx.setFieldValue(fieldNameObject.section, tempData.value)
        } else if (routeCheck() && data.length > 0 && data[data.length - 1].requestor_reply.length > 0) {
            setIsTextFieldDisabled(true);
            formikCtx.setFieldValue(fieldNameObject.section, data[data.length - 1].requestor_reply[0].value)
        }

        setAnchorEl(event.currentTarget);
    }
    // end of onCLick function for initial icon


    const handleClick = (event: React.MouseEvent<SVGElement>, sectionName: string, subSection: string, updateType: string) => {

        setAnchorEl(event.currentTarget);
    };

    // start of approver's fresh comment post function
    const postCommentHandler = () => {

        const commentpostBody = {
            par_id: formikCtx.values.request_id,
            data: [
                {
                    comment_id: null,
                    update_type: 'text update',
                    section: sectionName,
                    sub_section: '',
                    updated_by: loginsyncupdata.email,
                    value: formikCtx.getFieldProps(sectionName).value,
                    user_first_name: loginsyncupdata?.first_name,
                    user_last_name: loginsyncupdata?.last_name,
                    is_active: true
                }
            ]
        }

        if (formikCtx.getFieldProps(sectionName).value !== '' || formikCtx.getFieldProps(sectionName).value !== undefined) {
            postFieldCommentsPar(commentpostBody).then((response: any) => {
                handleClose();
                dispatch(postFreshComments(response.data[0]))
            })
        } else {
            enqueueSnackbar(`Empty comment cannot be posted`, {
                variant: 'warning',
            });
            handleClose();
        }

    }
    // end of approver's fresh comment post function

    // start of handleclose for approver's popover
    const handleClose = () => {
        setAnchorEl(null);
        setIsTextFieldDisabled(false);
        setEditToggler(false);
    }
    // end of handleclose for approver's popover
    // start of handleclose for requestor's popover
    const handleCloseRequestor = () => {
        setAnchorEl(null);
        setReplyToggler(false);
        formikCtx.setFieldValue(sectionName, '');
    }
    // end of handleclose for requestor's popover

    // start of edit toggler function for approver
    const editToggleHandler = (event: React.MouseEvent<SVGElement>) => {
        setIsTextFieldDisabled(false);
        setBooleanFlag(false);
        setEditToggler(true);
    }
    // end of edit toggler function for approver

    // start of edit toggler function for requestor

    const editToggleHandlerRequestor = (event: React.MouseEvent<SVGElement>) => {
        setIsTextFieldDisabled(false);
        setReplyToggler(true);
    }

    // end of edit toggler function for requestor

    const replyTogglerHandler = (event: React.MouseEvent<SVGElement>) => {
        setReplyToggler(true);
        setIsTextFieldDisabled(false);

    }

    const editContentHandlerForReply = () => {

        return (
            <div className={chatStyles.actionButton}>
                <CustomButton
                    variant="outlined"
                    actionType="secondary"
                    disableMargin
                    onClick={handleCloseRequestor}
                    size='small'
                >
                    Cancel
                </CustomButton>
                <CustomButton
                    variant="contained"
                    actionType="primary"
                    disableMargin
                    onClick={requestorReplyEditPostHandler}
                    size='small'
                >
                    Save
                </CustomButton>
            </div>
        )
    }


    const replyEditDeletHandler = () => {
        return (
            <div style={{ display: 'flex', fontFamily: 'NotoSerif-Regular', fontSize: '10px', padding: '8px', color: '#A7A7A7' }}>
                <div>
                    {/* {useDateTimeHook(data !== null && data[data.length - 1].requestor_reply[0].last_updated)} */}
                </div>
                {replyToggler ? editContentHandlerForReply() :

                    data.length > 0 && data[data.length - 1].requestor_reply !== null && (remediation_by === data[data.length - 1].updated_by) && <div style={{ paddingLeft: '80px' }}>
                        <button onClick={(e: any) => editToggleHandlerRequestor(e)}
                            style={{
                                color: '#0080FF', fontFamily: 'Poppins-regular', fontSize: '12px',
                                padding: 0,
                                border: 'none',
                                background: 'none',
                                cursor: 'pointer'
                            }}>
                            Edit
                        </button>
                        <button
                            onClick={requestorReplyDeleteHandler}
                            style={{
                                color: '#0080FF', fontFamily: 'Poppins-regular', fontSize: '12px',
                                paddingLeft: '18px',
                                border: 'none',
                                background: 'none',
                                cursor: 'pointer'
                            }}>
                            Delete
                        </button>
                    </div>

                }

            </div >
        )
    }
    const replyPostContentHandler = () => {
        return (
            <>
                <div style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                    {checkIsDisabled() && <div style={{ padding: '8px' }}>
                        <FormikControl
                            control='reviewaction'
                            name={fieldNameObject.section}
                            disabled={isTextFieldDisabled}
                        />
                    </div>}

                    <div style={{ paddingRight: '8px' }}>
                        {!replyToggler && data.length > 0 && !(data[data.length - 1].requestor_reply.length > 0) && <Avatar style={{ color: 'white', background: '#00B5B1' }} {...stringAvatar(`${data[data.length - 1].requestor_reply.length > 0 && data[data.length - 1].requestor_reply[0].user_first_name} ${data[data.length - 1].requestor_reply.length > 0 && data[data.length - 1].requestor_reply[0].user_last_name}`)} className={chatStyles.avatar} />}
                    </div>
                </div>
                {data.length > 0 && (data[data.length - 1].requestor_reply.length > 0) ? (
                    replyEditDeletHandler()
                ) :
                    replyToggler ? (<div className={chatStyles.actionButton}>
                        <CustomButton
                            variant="outlined"
                            actionType="secondary"
                            disableMargin
                            onClick={handleCloseRequestor}
                            size='small'
                        >
                            Cancel
                        </CustomButton>
                        <CustomButton
                            variant="contained"
                            actionType="primary"
                            disableMargin
                            onClick={requestorReplyPostHandler}
                            size='small'
                        >
                            Reply
                        </CustomButton>
                    </div>) : null
                }
            </>
        );
    }

    const requestorModeContentHandler = () => {

        return (
            <>
                {!routeCheck() && <div style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                    <div style={{ display: 'inline-flex', padding: '8px', flexFlow: 'column nowrap', width: '200px', background: '#F4F7F8', borderRadius: '4px' }}>
                        <span className={chatStyles.contentDiv}>
                            {newArray[0]}
                        </span>
                        <span className={chatStyles.stageText}>
                            {`By ${newArray[1]} (${newArray[4]})`}
                        </span>
                    </div>

                    <div style={{ paddingRight: '8px' }}>
                        <Avatar style={{ color: 'white', background: '#00B5B1' }} {...stringAvatar(`${newArray[1]}`)} className={chatStyles.avatar} />
                    </div>
                </div>
                }
                {((data.length > 0 && (data[data.length - 1].requestor_reply.length > 0)) || replyToggler) && replyPostContentHandler()}



                {!replyToggler && data.length > 0 && !(data[data.length - 1].requestor_reply.length > 0) && (remediation_by === data[data.length - 1].updated_by) && checkIsDisabled() && (<div style={{ display: 'flex', fontFamily: 'NotoSerif-Regular', fontSize: '10px', padding: '8px', color: '#A7A7A7' }}>
                    <div>
                        {/* {useDateTimeHook(newArray[2])}  data.length > 0 && data[data.length - 1].requestor_reply !== null  */}
                    </div>
                    <div style={{ paddingLeft: '80px' }}>
                        <button onClick={(e: any) => replyTogglerHandler(e)}
                            style={{
                                color: '#0080FF', fontFamily: 'Poppins-regular', fontSize: '12px',
                                padding: 0,
                                border: 'none',
                                background: 'none',
                                cursor: 'pointer'
                            }}>
                            Reply
                        </button>
                    </div>
                </div>)}
            </>
        )
    }


    console.log('redux data', data)
    return (
        <>

            {data.length > 0 ? (
                <div onClick={(e: any) => handleClickForView(e)}>
                    {data[data.length - 1].requestor_reply !== undefined && data[data.length - 1].requestor_reply.length > 0 ? (
                        <CustomSvgText svgText={stringAvatar(`${data[data.length - 1].requestor_reply[0].user_first_name} ${data[data.length - 1].requestor_reply[0].user_last_name}`).children} />
                    ) :
                        <CustomSvgText svgText={stringAvatar(`${data[data.length - 1].user_first_name} ${data[data.length - 1].user_last_name}`).children} />}
                </div>
            ) :
                currentRouteCheck() && (tempData === null ? <CommentSvg style={{ cursor: 'pointer' }} onClick={(e: any) => handleClick(e, sectionName, subSection, updateType)} /> : !(tempData.updated_by === loginsyncupdata.email) && <CommentSvg style={{ cursor: 'pointer' }} onClick={(e: any) => handleClick(e, sectionName, subSection, updateType)} />)}
            {/* {tempData ? (
                <div onClick={(e: any) => handleClickForView(e)}>
                    {<CustomSvgText svgText={stringAvatar(`${tempData.user_first_name} ${tempData.user_last_name}`).children} />}
                </div>
            ) : booleanFlag ? (<div onClick={(e: any) => handleClickForView(e)}>
                <CustomSvgText svgText={stringAvatar(`${loginsyncupdata?.first_name} ${loginsyncupdata?.last_name}`).children} />
            </div>) :
                (tempData === null ? <CommentSvg style={{ cursor: 'pointer' }} onClick={(e: any) => handleClick(e, sectionName, subSection, updateType)} /> : !(tempData.updated_by === loginsyncupdata.email) && <CommentSvg style={{ cursor: 'pointer' }} onClick={(e: any) => handleClick(e, sectionName, subSection, updateType)} />)} */}

            <ReviewCommentsContainer
                open={open}
                anchorEl={anchorEl}
                handleClose={routeCheck() ? handleCloseRequestor : handleClose}
            >
                <Box style={{ padding: '8px' }}>
                    <ChatBox
                        par_id={formikCtx.values.request_id}
                        section={fieldNameObject.section}
                        sub_section={fieldNameObject.subSection}
                        data={data}
                        remediationCountCheck={formikCtx.values.remediation_count}
                    />
                </Box>
                {
                    routeCheck() && requestorModeContentHandler()
                }
                {

                    <div style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                        {currentRouteCheck() && <div style={{ padding: '8px' }}>
                            <FormikControl
                                control='reviewaction'
                                name={fieldNameObject.section}

                                disabled={isTextFieldDisabled}
                            />
                            {!editToggler && tempData !== null && <span className={chatStyles.stageText}>
                                {`By ${tempData?.user_first_name} ${tempData?.user_last_name} (${tempData.stage})`}
                            </span>}
                        </div>
                        }

                        <div style={{ paddingRight: '8px' }}>
                            {booleanFlag ? <Avatar style={{ color: 'white', background: '#00B5B1' }} {...stringAvatar(`${loginsyncupdata?.first_name} ${loginsyncupdata?.last_name}`)} className={chatStyles.avatar} /> :
                                (tempData === null && currentRouteCheck() && <PostArrowButton style={{ cursor: 'pointer' }} onClick={postCommentHandler} />)}
                        </div>
                    </div>

                }

                {
                    // routeCheck() ? null : newArray.length > 0 ? (
                    // !routCheckApprovalSummary() &&
                    data.length > 0 ? (
                        <div style={{ display: 'flex', fontFamily: 'NotoSerif-Regular', fontSize: '10px', padding: '8px', color: '#A7A7A7', justifyContent: 'end' }}>
                            {editToggler ? null :
                                <div>
                                    {tempData !== null && useDateTimeHook(tempData.last_updated)}
                                </div>}
                            {
                                editToggler ? editContentHandler() : (
                                    currentRouteCheck() && <div style={{ paddingLeft: '80px' }}>
                                        {data[data.length - 1].updated_by === loginsyncupdata.email && (data[data.length - 1].current_remediation === formikCtx.values.remediation_count) &&
                                            <>
                                                <button onClick={(e: any) => editToggleHandler(e)}
                                                    style={{
                                                        color: '#0080FF', fontFamily: 'Poppins-regular', fontSize: '12px',
                                                        padding: 0,
                                                        border: 'none',
                                                        background: 'none',
                                                        cursor: 'pointer'
                                                    }}>
                                                    Edit
                                                </button>
                                                <button
                                                    onClick={deleteContentHandler}
                                                    style={{
                                                        color: '#0080FF', fontFamily: 'Poppins-regular', fontSize: '12px',
                                                        paddingLeft: '18px',
                                                        border: 'none',
                                                        background: 'none',
                                                        cursor: 'pointer'
                                                    }}>
                                                    Delete
                                                </button>
                                            </>}

                                    </div>
                                )
                            }

                        </div>)
                        : booleanFlag ? (
                            // !routCheckApprovalSummary() &&
                            currentRouteCheck() && <div style={{ display: 'flex', fontFamily: 'NotoSerif-Regular', fontSize: '10px', padding: '8px', color: '#A7A7A7' }}>
                                <div>
                                    {/* {tempData.last_updated !== null && useDateTimeHook(tempData.last_updated)} */}
                                </div>
                                <div style={{ paddingLeft: '80px' }}>
                                    <button onClick={(e: any) => editToggleHandler(e)}
                                        style={{
                                            color: '#0080FF', fontFamily: 'Poppins-regular', fontSize: '12px',
                                            padding: 0,
                                            border: 'none',
                                            background: 'none',
                                            cursor: 'pointer'
                                        }}>
                                        Edit
                                    </button>
                                    <button
                                        onClick={deleteContentHandler}
                                        style={{
                                            color: '#0080FF', fontFamily: 'Poppins-regular', fontSize: '12px',
                                            paddingLeft: '18px',
                                            border: 'none',
                                            background: 'none',
                                            cursor: 'pointer'
                                        }}>
                                        Delete
                                    </button>
                                </div>
                            </div>
                        ) : editToggler ? editContentHandler() : null}
            </ReviewCommentsContainer>

        </>
    )
}
export default CommentSubmission;