/* eslint-disable react/require-default-props */
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from "@mui/styles";
import { CheckboxProps, FormControlLabel } from "@mui/material";
import { ReactComponent as CheckBoxChecked } from "../../assets/icons/checkbox_checked.svg";
import { ReactComponent as CheckBoxUnChecked } from "../../assets/icons/checkbox_unchecked.svg";

const styles = makeStyles({
  checkbox: {
    paddingLeft: "4px",
    paddingRight: "4px",
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  formControl: {
    margin: (props: { isCompact?: boolean }) =>
      props.isCompact ? "0px" : "8px 0px",
    display: "flex",
    alignItems: "center",
  },
  formLabel: {
    font: "normal normal normal 13px/13px NotoSerif-Regular",
    letterSpacing: 0,
    color: "#0A2756",
    paddingLeft: "4px",
  },
  errorSpan: {
    textAlign: "left",
    font: "normal normal normal 10px/20px NotoSerif-Regular",
    letterSpacing: "0px",
    color: "#EF5B2B",
    marginTop: "0px",
    marginLeft: "4px",
  },
});

interface IStyledCheckbox extends CheckboxProps {
  name?: string;
  label: string;
  isCompact?: boolean;
  errorMsg?: string;
  disabled?: boolean;
  dataTestid?: string;
}

function Checkboxs({
  checked,
  onChange,
  value,
  className,
  name,
  label,
  isCompact,
  errorMsg,
  disabled,
  dataTestid,
}: IStyledCheckbox) {
  const classes = styles({ isCompact });
  return (
    <>
      <div style={{ display: "flex" }} data-testid="checkbox-div">
        <FormControlLabel
          control={
            <Checkbox
              name={name}
              checked={checked}
              onChange={onChange}
              value={value}
              checkedIcon={<CheckBoxChecked />}
              icon={<CheckBoxUnChecked style={{ color: "#A7A7A7" }} />}
              disableRipple
              className={`${classes.checkbox} ${className}`}
              data-testid={dataTestid || "checkbox"}
              disabled={disabled}
            />
          }
          classes={{
            root: classes.formControl,
            label: classes.formLabel,
          }}
          label={label}
        />
      </div>
      {errorMsg && (
        <p className={classes.errorSpan} data-testid="checkbox-error-msg">
          {errorMsg}
        </p>
      )}
    </>
  );
}

export default Checkboxs;
