import { pink } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";

interface IStyleProps {
  color: string;
  background: string;
}

const statusBadgeStyles = makeStyles({
  statusBadge: {
    font: "normal normal normal 14px/15px NotoSerif-Regular",
    letterSpacing: "-0.06px",
    padding: "4px 8px",
    margin: "5.25px 0px",
    borderRadius: "2px",
    whiteSpace: "nowrap",
    color: (props: IStyleProps) => props.color,
    background: (props: IStyleProps) => props.background,
  },
   active: {
     borderRadius: "2px",
     padding: "4px 8px",
     font: "normal normal normal 14px/15px NotoSerif-Regular",
     whiteSpace: "nowrap",
     letterSpacing: "-0.06px",
     color: "#014645",
     background: "#99FDD1",
  },
  pendingCreation: {
     padding: "4px 8px",
     borderRadius: "2px",
     font: "normal normal normal 14px/15px NotoSerif-Regular",
     whiteSpace: "nowrap",
     letterSpacing: "-0.06px",
     color: "#914B32",
     background: "#F9F7B7",
  },
  inActive: {
    borderRadius: "2px",
    padding: "4px 8px",
    font: "normal normal normal 14px/15px NotoSerif-Regular",
    color:'#FFE2D8',
    background: "#EF5B2B",
  },
});

export default statusBadgeStyles;
