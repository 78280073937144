import { Grid } from "@mui/material";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterOptions from "../../../components/filter-menu/FilterOptions";
import FilterMenu from "../../../components/filter-menu";
import approvalSummaryStyles from "../administrative-summary-styles";
import useAdministrativeSummaryHook from "./useAdministrativeSummaryHook";
import CustomButton from "../../../components/button";
import DynamicTable from "../../../components/dynamic-table";
import { emptyFilters } from "./administrativeSummaryUtils";
import PaginationBlock from "../../../components/pagination-block";
import CustomToggleButton from "../../../components/button/CustomToggleButton";
import CustomDatePicker from "../../../components/date-range-picker";

import { ReactComponent as UserIcon } from "../../../assets/icons/user.svg";
import { ReactComponent as RightTickIcon } from "../../../assets/icons/right-tick.svg";
import { ReactComponent as EyeIcon } from "../../../assets/icons/eye_b.svg";
import CustomModal from "../../../components/custom-modal";
import React, { useState, useRef } from "react";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import FormikControl from "../../../components/formik-conrtols/FormikControl";
import { useHistory } from "react-router-dom";
import { format, isDate } from "date-fns";
import { makeStyles } from "@mui/styles";

import {
  Formik,
  Form,
  ArrayHelpers,
  FieldArray,
  FormikProps,
  FormikValues,
} from "formik";

const useStyles = makeStyles({
  approverText: {
    font: "normal 13px/20px Poppins-Regular",
    color: "#0A2756",
  },
  approverTextResults: {
    font: "normal 14px/19px NotoSerif-Regular",
    color: "#0A2756",
    paddingLeft: "17px",
  },
  astrik: {
    color: "#EF5B2B",
    margin: "0 2px",
  },
  linkText: { textDecoration: "underline", color: "#0080FF" },
});

function AdministrativeSummary() {
  const history = useHistory();
  const classes = useStyles();

  const formatDate = (dateValue: any) =>
    dateValue && isDate(new Date(dateValue))
      ? format(new Date(dateValue), "dd MMM yyyy")
      : "";

  const styleClasses = approvalSummaryStyles();
  const formRef = useRef<FormikProps<FormikValues>>(null);
  const [isAPILoading, setIsAPILoading] = useState(false);

  const [delegatedApprover, setDelegatedApprover] = useState<{
    selected_Approver: string;
    add_reason: string;
  }>();

  const viewDelegation = (id: any) => {
    if (menuData.row.request_type === 'PAR') {

      if (menuData.row.request_purpose === "Connect to / manage a server or computer") {
        // console.log("123")
        history.push(`/myadministrativeTasks/delegation/${id}`);
      } else {
        history.push(`/myadministrativeTasks/sar/${id}`);
      }
    }
  }

  const {
    open,
    filters,
    clearValueHandler,
    administrativeSummaryData,
    tableColumnsForPending,
    rowValue,
    isLoadingPending,
    tableParametersPending,
    setTableParametersPending,
    setFilters,
    requestIdFilter,
    requestorNameFilter,
    requestTypeFilter,
    requestStatusFilter,
    actionOwnerFilter,
    daysPendingFilter,
    setCloseFilter,
    closeFilter,
    datepickerProps,
    setDatepickerProps,
    setDateRangeValue,
    setDateRangeFilter,
    menuData,
    setMenuData,
    handleModalOpen,
    modalOpen,
    modalContentHandler,
    modalHeaderHandler,
    handleModalClose,
    modalActionHandler,
    modalData,
  } = useAdministrativeSummaryHook();
  return (
    <>
      {/* filter started=> */}
      <Grid item xs={12} className={styleClasses.filtersContainer} data-testid="mainContainerGrid" >
        <div className={styleClasses.filterPanel}>
          <div className={styleClasses.filterIcon}>
            <FilterListIcon />
          </div>
          <div className={styleClasses.filterMenu}>
            <FilterMenu
              filterLabel="REQ ID"
              handleFilterClose={() => console.log("handleClose")}
              badgeCount={filters.requestId?.length}
            >
              <FilterOptions
                searchPlaceholder="Search"
                allowSearch
                optionsList={requestIdFilter}
                selectedOptions={filters.requestId}
                handleOptionSelection={(e, selection) => {
                  setFilters((prevFilters) => {
                    let newRequestIdArray = [...prevFilters.requestId];
                    if (!selection) {
                      newRequestIdArray = newRequestIdArray.filter(
                        (option) => option !== e.target.value
                      );
                    } else newRequestIdArray.push(e.target.value);
                    return {
                      ...prevFilters,
                      requestId: [...newRequestIdArray],
                      page: 1,
                    };
                  });
                }}
                isFilterDataLoading={isLoadingPending.filters.requestId}
                allowAll
                selectAllHandler={(isSelected: boolean) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    requestId: isSelected
                      ? requestIdFilter.map(
                        (obj: { id: string; value: string }) => obj.id
                      )
                      : [],
                  }));
                }}
              />
            </FilterMenu>
          </div>
          <div className={styleClasses.filterMenu}>
            <FilterMenu
              filterLabel="REQUESTOR NAME"
              handleFilterClose={() => console.log("handleClose")}
              badgeCount={filters.requestorName?.length}
            >
              <FilterOptions
                searchPlaceholder="Search"
                allowSearch
                optionsList={requestorNameFilter}
                selectedOptions={filters.requestorName}
                handleOptionSelection={(e, selection) => {
                  setFilters((prevFilters) => {
                    let newRequestorNameArray = [...prevFilters.requestorName];
                    if (!selection) {
                      newRequestorNameArray = newRequestorNameArray.filter(
                        (option) => option !== e.target.value
                      );
                    } else {
                      newRequestorNameArray.push(e.target.value);
                    }
                    return {
                      ...prevFilters,
                      requestorName: [...newRequestorNameArray],
                      page: 1,
                    };
                  });
                }}
                isFilterDataLoading={isLoadingPending.filters.requestorName}
                allowAll
                selectAllHandler={(isSelected: boolean) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    requestorName: isSelected
                      ? requestorNameFilter.map(
                        (obj: { id: string; value: string }) => obj.id
                      )
                      : [],
                  }));
                }}
              />
            </FilterMenu>
          </div>
          <div className={styleClasses.filterMenu}>
            <FilterMenu
              filterLabel="REQUEST TYPE"
              handleFilterClose={() => console.log("handleClose")}
              badgeCount={filters.requestType?.length}
            >
              <FilterOptions
                searchPlaceholder="Search"
                optionsList={requestTypeFilter}
                selectedOptions={filters.requestType}
                handleOptionSelection={(e, selection) => {
                  setFilters((prevFilters) => {
                    let newRequestTypeArray = [...prevFilters.requestType];
                    if (!selection) {
                      newRequestTypeArray = newRequestTypeArray.filter(
                        (option) => option !== e.target.value
                      );
                    } else {
                      newRequestTypeArray.push(e.target.value);
                    }
                    return {
                      ...prevFilters,
                      requestType: [...newRequestTypeArray],
                      page: 1,
                    };
                  });
                }}
                isFilterDataLoading={isLoadingPending.filters.requestType}
                allowAll
                selectAllHandler={(isSelected: boolean) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    requestType: isSelected
                      ? requestTypeFilter.map(
                        (obj: { id: string; value: string }) => obj.id
                      )
                      : [],
                  }));
                }}
              />
            </FilterMenu>
          </div>
          <div className={styleClasses.filterMenu}>
            <FilterMenu
              filterLabel="REQUEST STATUS"
              handleFilterClose={() => console.log("handleClose")}
              badgeCount={filters.requestStatus?.length}
            >
              <FilterOptions
                searchPlaceholder="Search by Status"
                allowSearch
                optionsList={requestStatusFilter}
                selectedOptions={filters.requestStatus}
                handleOptionSelection={(e, selection) => {
                  setFilters((prevFilters) => {
                    let newStatusArray = [...prevFilters.requestStatus];
                    if (!selection) {
                      newStatusArray = newStatusArray.filter(
                        (option) => option !== Number(e.target.value)
                      );
                    } else newStatusArray.push(Number(e.target.value));
                    return {
                      ...prevFilters,
                      requestStatus: [...newStatusArray],
                      page: 1,
                    };
                  });
                }}
                isFilterDataLoading={isLoadingPending.filters.requestStatus}
                allowAll
                selectAllHandler={(isSelected: boolean) => {
                  setFilters((prevFilters: any) => ({
                    ...prevFilters,
                    requestStatus: isSelected
                      ? requestStatusFilter.map(
                        (obj: { id: string; value: string }) => obj.id
                      )
                      : [],
                  }));
                }}
              />
            </FilterMenu>
          </div>
          <div className={styleClasses.filterMenu}>
            <FilterMenu
              filterLabel="ACTION OWNER"
              handleFilterClose={() => console.log("handleClose")}
              badgeCount={filters.actionOwner?.length}
            >
              <FilterOptions
                searchPlaceholder="Search"
                optionsList={actionOwnerFilter}
                selectedOptions={filters.actionOwner}
                handleOptionSelection={(e, selection) => {
                  setFilters((prevFilters) => {
                    let newActionOwnerArray = [...prevFilters.actionOwner];
                    if (!selection) {
                      newActionOwnerArray = newActionOwnerArray.filter(
                        (option) => option !== e.target.value
                      );
                    } else newActionOwnerArray.push(e.target.value);
                    return {
                      ...prevFilters,
                      actionOwner: [...newActionOwnerArray],
                      page: 1,
                    };
                  });
                }}
                isFilterDataLoading={isLoadingPending.filters.actionOwner}
                allowAll
                selectAllHandler={(isSelected: boolean) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    actionOwner: isSelected
                      ? actionOwnerFilter.map(
                        (obj: { id: string; value: string }) => obj.id
                      )
                      : [],
                  }));
                }}
              />
            </FilterMenu>
          </div>
          <div className={styleClasses.filterMenu}>
            <FilterMenu
              filterLabel="DAYS PENDING"
              handleFilterClose={() => console.log("handleClose")}
              badgeCount={filters.daysPending?.length}
            >
              <FilterOptions
                searchPlaceholder="Search"
                optionsList={daysPendingFilter}
                selectedOptions={filters.daysPending}
                handleOptionSelection={(e, selection) => {
                  setFilters((prevFilters) => {
                    let newDaysPendingArray = [...prevFilters.daysPending];
                    if (!selection) {
                      newDaysPendingArray = newDaysPendingArray.filter(
                        (option) => option !== e.target.value
                      );
                    } else newDaysPendingArray.push(e.target.value);
                    return {
                      ...prevFilters,
                      daysPending: [...newDaysPendingArray],
                      page: 1,
                    };
                  });
                }}
                isFilterDataLoading={isLoadingPending.filters.daysPending}
                allowAll
                selectAllHandler={(isSelected: boolean) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    daysPending: isSelected
                      ? daysPendingFilter.map(
                        (obj: { id: string; value: string }) => obj.id
                      )
                      : [],
                  }));
                }}
              />
            </FilterMenu>
          </div>
          <div className={styleClasses.filterMenu}>
            <FilterMenu
              filterLabel="Requested On"
              handleFilterClose={() => {
                if (closeFilter) {
                  setCloseFilter(false);
                }
              }}
              // badgeCount={filters.requestDate.endDate && filters.requestDate.startDate
              //   ? differenceInCalendarDays(
              //     filters.requestDate.endDate,
              //     filters.requestDate.startDate,
              //   ) + 1
              //   : 0}
              badgeCount={
                filters.requestedOn.endDate && filters.requestedOn.startDate
                  ? " "
                  : 0
              }
              closeFilterExternally={closeFilter}
            >
              <div className={styleClasses.dateRangeToggleContainer}>
                <CustomToggleButton
                  variant="contained"
                  isSelected={!!datepickerProps.isSingleDate}
                  onClick={() => {
                    if (!datepickerProps.isSingleDate) {
                      setDatepickerProps({
                        isSingleDate: true,
                        dateRange: {
                          startDate: null,
                          endDate: null,
                        },
                      });
                    }
                  }}
                >
                  Single Date
                </CustomToggleButton>
                <CustomToggleButton
                  variant="contained"
                  isSelected={!datepickerProps.isSingleDate}
                  onClick={() => {
                    if (datepickerProps.isSingleDate) {
                      setDatepickerProps({
                        isSingleDate: false,
                        dateRange: {
                          startDate: null,
                          endDate: null,
                        },
                      });
                    }
                  }}
                >
                  Date Range
                </CustomToggleButton>
              </div>
              <div className={styleClasses.dateRangeContainer}>
                <CustomDatePicker
                  isRangePicker={!datepickerProps.isSingleDate}
                  setDateRangeValue={setDateRangeValue}
                  dateRange={datepickerProps.dateRange}
                  disableFuture
                />
              </div>
              <div className={styleClasses.dateRangeApplyCta}>
                <CustomButton
                  variant="contained"
                  actionType="secondary"
                  disabled={!datepickerProps.dateRange.endDate}
                  onClick={() => setDateRangeFilter()}
                  isCompact
                  disableMargin
                >
                  Apply
                </CustomButton>
              </div>
            </FilterMenu>
          </div>
          <CustomButton
            variant="contained"
            actionType="tertiary"
            onClick={() => clearValueHandler()}
            disableRipple
            disableMargin
            disablePadding
          >
            CLEAR
          </CustomButton>
        </div>
      </Grid>
      {/* filter end=> */}
      {/* listing table started=> */}
      <Grid item xs={12}>
        <DynamicTable
          rows={administrativeSummaryData.results}
          isLoading={isLoadingPending.tableLoading}
          columns={tableColumnsForPending}
          collapsible={open}
          rowId={rowValue.request_Id}
          pageNumber={tableParametersPending.page}
          pageSize={administrativeSummaryData.pageSize}
        >
        </DynamicTable>
      </Grid>
      {/* listing table end=> */}
      {/* pagination started=> */}
      <Grid item xs={12} style={{ paddingTop: "21px" }}>
        <PaginationBlock
          totalRecords={administrativeSummaryData?.results.length || 0}
          currentRecordsCount={administrativeSummaryData?.results.length}
          activePage={tableParametersPending.page}
          handlePageChange={(pageNumber) => {
            setTableParametersPending((prevParameters: any) => ({
              ...prevParameters,
              page: pageNumber,
            }));
          }}
          isLoading={isLoadingPending.tableLoading}
          pageSize={administrativeSummaryData.pageSize}
        />
      </Grid>
      {/* pagination end=> */}
      {/* modal code started=> */}
      {menuData.anchorEl && menuData.row && (
        <Menu
          id="menu-popover"
          open={!!menuData.anchorEl}
          anchorEl={menuData.anchorEl}
          onClose={() =>
            setMenuData({
              anchorEl: null,
              row: {
                request_id: null,
                stage_id: null,
                request_type: null,
                request_status: null,
                is_request_to_update: null,
                linked_delegation: null,
                request_stage: null,
                request_to_update_count: 0,
                implementor_type_id: null,
                request_number: null,
                request_purpose: null,
                implementer_type: null
              },
            })
          }
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          classes={{ root: styleClasses.moreActionMenu }}
          disableAutoFocusItem
        >
          {menuData.row.request_status === "Draft" &&
            (
              <MenuItem
                onClick={() => handleModalOpen("changeRequestor")}
                className={styleClasses.moreActionMenuItem}
              >
                <UserIcon />
                <span className={styleClasses.menuLabel}>Change Requestor</span>
              </MenuItem>
            )}
          {menuData.row.request_status === "Completed" &&
            (
              <MenuItem
                onClick={() => handleModalOpen("changeRequestor")}
                className={styleClasses.moreActionMenuItem}
              >
                <UserIcon />
                <span className={styleClasses.menuLabel}>Change Requestor</span>
              </MenuItem>
            )}
          {menuData.row.request_status === "Remediation Requested" &&
            (
              <MenuItem
                onClick={() => handleModalOpen("changeRequestor")}
                className={styleClasses.moreActionMenuItem}
              >
                <UserIcon />
                <span className={styleClasses.menuLabel}>Change Requestor</span>
              </MenuItem>
            )}
          {menuData.row.request_status === "Pending Approval" && (
            <>
              <MenuItem
                onClick={() => handleModalOpen("changeRequestor")}
                className={styleClasses.moreActionMenuItem}
              >
                <UserIcon />
                <span className={styleClasses.menuLabel}>Change Requestor</span>
              </MenuItem>
              <MenuItem
                onClick={() => handleModalOpen("changeApprover")}
                className={styleClasses.moreActionMenuItem}
              >
                <UserIcon />
                <span className={styleClasses.menuLabel}>Change Approver</span>
              </MenuItem>
            </>
          )}
          {
            menuData.row.is_request_to_update && (
              <>
                <MenuItem
                  onClick={() => handleModalOpen("approveDeny")}
                  className={styleClasses.moreActionMenuItem}
                  disabled={menuData.row.is_request_to_update ? false : true}
                >
                  <RightTickIcon style={{ background: 'none' }} />
                  <span className={styleClasses.menuLabel}>Approve / Deny</span>
                </MenuItem>
              </>
            )}
          {menuData.row.request_status ===
            "Approved - Pending Implementation" && (
              <>
                <MenuItem
                  onClick={() => handleModalOpen("changeRequestor")}
                  className={styleClasses.moreActionMenuItem}
                >
                  <UserIcon />
                  <span className={styleClasses.menuLabel}>Change Requestor</span>
                </MenuItem>
                <MenuItem
                  onClick={() => handleModalOpen("changeImplementor")}
                  className={styleClasses.moreActionMenuItem}
                >
                  <UserIcon />
                  <span className={styleClasses.menuLabel}>
                    Change Implementer
                  </span>
                </MenuItem>

              </>
            )}
          {
            menuData.row.linked_delegation && (
              <MenuItem
                onClick={() => viewDelegation(menuData.row.linked_delegation)}
                className={styleClasses.moreActionMenuItem}
              >
                <EyeIcon />
                <span className={styleClasses.menuLabel}>View Delegation</span>
              </MenuItem>
            )
          }
          {
            (menuData?.row?.request_to_update_count > 0) && (
              <MenuItem
                onClick={() => handleModalOpen("viewHistory")}
                className={styleClasses.moreActionMenuItem}
              >
                <EyeIcon />
                <span className={styleClasses.menuLabel}>View History</span>
              </MenuItem>
            )
          }
        </Menu>
      )}
      {modalOpen && (
        <CustomModal
          modalHeader={modalHeaderHandler(modalData.type)}
          open={modalOpen}
          onClose={handleModalClose}
          modalAction={modalActionHandler(modalData.type)}
          modalContent={modalContentHandler(modalData.type)}
          centerButton={modalData.type === 'viewHistory' ? true : false}
        />
      )}
      {/* modal code ended=> */}
    </>
  );
}
export default AdministrativeSummary;
