import React from 'react';

export const pendingApprovalSummaryColumnMapping = {
  request_number: 'request_number',
  requestId: 'requestId',
  requestor_name: 'requestor_name',
  request_type: 'request_type',
  request_stage: 'request_stage',
  last_action: 'last_action',
  requested_on: 'requested_on',
  assigned_on: 'assigned_on',
  actions: 'actions',
};

export function createColumns(
  key: number,
  label: string,
  icon: any,
  id: string,
  isActive: boolean,
  handleClick?: (_e: any) => void,
  style?: React.CSSProperties,
) {
  return {
    key, label, icon, handleClick, id, isActive, style,
  };
}
export function createRowCells(
  columnId: string,
  renderComponent: any,
  id: string,
  columnNumber: number,
  handleClick?: undefined | ((_e: any) => void),
  style?: React.CSSProperties,
  value?: any,
) {
  return {
    columnId, renderComponent, id, handleClick, columnNumber, style, value,
  };
}

export const initialTableParameters = {
  page: 1,
  orderBy: {
    order: 'asc',
    column: '',
  },
};

export interface ITableParameters {
  page: number
  orderBy: {
    order: string,
    column: string,
  },
}

export interface IDateRangeObj {
  startDate: Date | null;
  endDate: Date | null;
}
export interface IFiltersObj {
  requestId: string[],
  requestorName: string[],
  myLastAction: string[],
  requestType: string[],
  // requestedOn: string[],
  // assignedOn: string[],
  requestDate: IDateRangeObj,
}

export interface IPendingApprovalSummaryData {
  request_id: number,
  request_number: string,
  requestor_name: string,
  current_status: string,
  my_last_action: string,
  request_type: string,
  assigned_on: string,
  requested_on: string,
}


export const emptyFilters = {
  requestId: [],
  requestorName: [],
  myLastAction: [],
  requestType: [],
  requestDate: {
    startDate: null,
    endDate: null,
  },
  // requestedOn: [],
  // assignedOn: [],
};


export type LastAction = {
  id: string,
  value: string,
}