import React from 'react';

function useDateTimeHook(dateTime: any) {
    const times = [["second", 1], ["minute", 60], ["hour", 3600], ["day", 86400], ["week", 604800]];

    const currentDate = new Date();
    let sourceDateTime = new Date(dateTime);
    var utcNow = Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), currentDate.getUTCHours(), currentDate.getUTCMinutes(), currentDate.getUTCSeconds());
    var utcSource = Date.UTC(sourceDateTime.getFullYear(), sourceDateTime.getMonth(), sourceDateTime.getDate(), sourceDateTime.getUTCHours(), sourceDateTime.getUTCMinutes(), sourceDateTime.getUTCSeconds());
    var utcRound = Math.round((utcNow - utcSource) / 1000);
    for (var t = 0; t < times.length; t++) {
        if (utcRound < times[t][1]) {
            if (t == 0) {
                return "Just now";
            } else {
                let sourceSec = +times[t - 1][1];
                utcRound = Math.round(utcRound / sourceSec);
                return utcRound + " " + times[t - 1][0] + (utcRound == 1 ? " ago" : "s ago");
            }
        }
    }
    return sourceDateTime.toLocaleString();
}
export default useDateTimeHook;