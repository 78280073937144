import {
  useState,
  useEffect
} from 'react';
import {
  useFormikContext,
} from 'formik';
import Popover from '@mui/material/Popover';
import AddIcon from '@mui/icons-material/Add';
import Grid from '@mui/material/Grid';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FormikControl from '../../../formik-conrtols/FormikControl';
import FieldLabel from '../../../field-label';
import CustomDatePicker from '../../../date-range-picker';
import useDaterangePicker from '../../../date-range-picker/useDaterangePicker';
import IconTextField from '../../../icon-text-field';
import CustomButton from '../../../button';
import FileUploader from '../../../file-uploader/fileUploader';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import Signature from './signature';
import {
  delegationOwnerStyles, parStyles,
} from '../../useStyles';
import { format, isDate } from 'date-fns';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../appStore/store';
import { ownerSignOff, signaturetooltip } from '../tooltip';
import HelperTooltip from '../../../helper-tooltip';
import HistoryTable from '../../../../modules/delegation/comments/historyTable';
import CommentSubmission from '../../../../modules/delegation/comments/commentSubmission';
import { routeCheckDelegation, routeCheckForAdminSummary, routeCheckForApprovalSummary, routeCheckForImplementationSummary, routeCheckForPendingApproval } from './routingConditional';

function DelegationOwner({ isDisabled }: { isDisabled: boolean }) {
  const classes = delegationOwnerStyles();
  const formikCtx: any = useFormikContext();
  const { fieldHistoryDelegationCommentsData } = useSelector((state: RootState) => state);
  const { submitDelegationCommentData } = useSelector((state: RootState) => state);
  const styleClasses = parStyles();
  const remediated_by = formikCtx.values.remediation_by
  const remediationCount = formikCtx.values.remediation_count;

  const checkForEditReviewFlow = () => {
    return routeCheckDelegation() || routeCheckForAdminSummary() || routeCheckForImplementationSummary() || routeCheckForApprovalSummary()
  }
  const checkIsDisabled = () => (formikCtx.values.status_label === 'Draft'
    || formikCtx.values.status_label === 'Remediation Requested');

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const formatDateInside = (dateValue: any) => (dateValue && isDate(new Date(dateValue)) ? format(new Date(dateValue), 'MMM dd, yyyy') : '');
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = !!anchorEl;
  const id = open ? 'simple-popover' : undefined;

  const {
    datepickerProps,
    setDateRangeValue,
  } = useDaterangePicker({ isSingleDate: true });

  const handleDateChange = (e: any) => {

    setDateRangeValue(e);
  };

  useEffect(() => {
    if (checkIsDisabled()) {
      formikCtx.setFieldValue('del_date', format(new Date(), "yyyy-MM-dd"));
    }
  }, []);


  return (
    <Grid container style={{ paddingRight: '8px' }}>
      <Grid item xs={5}>
        <div>
          <FieldLabel Title="Privileged access Delegation - owner sign-off" tooltipMsg={ownerSignOff} required />
        </div>
        <div style={{ color: '#A7A7A7', fontFamily: 'NotoSerif-Regular', fontSize: '10px', marginTop: '-5px', paddingBottom: '10px' }}>
          {/* @ts-ignore */}
          {(fieldHistoryDelegationCommentsData[15].Privileged_access_Delegation_owner_sign_off?.length > 0) && `Updated on ${formatDateInside(fieldHistoryDelegationCommentsData[15].Privileged_access_Delegation_owner_sign_off[fieldHistoryDelegationCommentsData[15].Privileged_access_Delegation_owner_sign_off?.length - 1].last_updated)}`}
        </div>
      </Grid>
      <Grid item xs={5}>
      </Grid>
      <Grid item xs={2}>
        {(checkForEditReviewFlow() && remediationCount > 0) ? (
          <div className={styleClasses.mainSectionDiv}>
            <HistoryTable
              parId={formikCtx.values.request_id}
              section="Privileged_access_Delegation_owner_sign_off"
              subSection=''
              data={fieldHistoryDelegationCommentsData.length > 0 ? fieldHistoryDelegationCommentsData[15].Privileged_access_Delegation_owner_sign_off : null}
            />

            <CommentSubmission
              sectionName='Privileged_access_Delegation_owner_sign_off'
              subSection=''
              updateType='Select update'
              remediation_by={remediated_by}
              data={submitDelegationCommentData.length > 0 ? submitDelegationCommentData[15].Privileged_access_Delegation_owner_sign_off : null}
            />
          </div>
        ) : routeCheckForPendingApproval() && (
          <div className={styleClasses.mainSectionDiv}>
            <HistoryTable
              parId={formikCtx.values.request_id}
              section="Privileged_access_Delegation_owner_sign_off"
              subSection=''
              data={fieldHistoryDelegationCommentsData.length > 0 ? fieldHistoryDelegationCommentsData[15].Privileged_access_Delegation_owner_sign_off : null}
            />

            <CommentSubmission
              sectionName='Privileged_access_Delegation_owner_sign_off'
              subSection=''
              updateType='Select update'
              remediation_by={remediated_by}
              data={submitDelegationCommentData.length > 0 ? submitDelegationCommentData[15].Privileged_access_Delegation_owner_sign_off : null}
            />
          </div>
        )}
      </Grid>
      <Grid container className={classes.containerSignature}>
        <Grid item xs={12}>
          <div className={classes.liHeading}>As a Delegation Owner:</div>

          <ul style={{ paddingLeft: "32px", paddingRight: "8px" }}>

            <li className={classes.liBottomPadding}>

              I acknowledge that I am responsible for the Delegation assignment

              and the rights requested follow the least privilege security

              model.

            </li>

            <li className={classes.liBottomPadding}>

              I acknowledge that I am responsible for the correct membership of this role group and agree to review the membership at the frequency specified above.

            </li>

            <li className={classes.liBottomPadding}>

              I acknowledge that I am responsible for the actions taken by the users who will be enabled by this role.

            </li>

            <li className={classes.liBottomPadding}>

              I acknowledge that only privileged access accounts following the GroupM standard documented here, will be members of this group.

            </li>

          </ul>
        </Grid>
        <Grid item xs={12} className={classes.gridItemUserDetails}>
          <FormikControl
            disabled={isDisabled}
            control="checkbox"
            label="I agree to the above statements and accept this responsibility."
            name="finalCheck"
            errorMsg={formikCtx.errors.finalCheck}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3} className={classes.gridItemUserDetails}>
          <div
            style={{
              fontSize: '12px',
              fontFamily: 'PoppinsRegualr',
              color: '#0A2756',
              paddingBottom: '10px',
            }}
          >Owner Name</div>
          <FormikControl
            control="textfield"
            name="del_signed_by"
            disabled={true}
          />

        </Grid>
        <Grid item xs={10} className={classes.girdStyleAddSignature}>
          <div
            style={{
              fontSize: '12px',
              fontFamily: 'PoppinsRegualr',
              color: '#0A2756',
              paddingBottom: '10px',
              paddingTop: '10px',
            }}
          >{`Signature(optional)`}</div>
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', paddingBottom: '10px' }}>
            <div>
              <Signature
                disabled={isDisabled}
                fileAction={
                  (
                    <div className={classes.signatureDiv}>
                      <span className={classes.signatureSpan}>
                        Add Signature
                      </span>
                    </div>

                  )
                }
                acceptFileType={
                  { 'image/jpeg': ['.jpeg', '.png'] }
                }
                requestId={formikCtx.values.request_id}
                getFiles={formikCtx.values.attachment.length > 0 && formikCtx.values.attachment.filter((obj: any) => obj.file_section !== 'attachment')}
              />
            </div>
            <div style={{ marginLeft: '10px' }}>
              <HelperTooltip tooltipMsg={signaturetooltip}>
                <HelpRoundedIcon />
              </HelperTooltip>
            </div>
          </div>

        </Grid>
        <Grid item xs={2} className={classes.gridCalenderStyle}>
          <IconTextField
            iconEnd={
              (
                <CalendarMonthIcon className={`${anchorEl ? classes.calendarMonthIconStylesActive : classes.calendarMonthIconStyles}`} />
              )
            }
            onClick={() => null}
            value={formikCtx.values.del_date}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <FieldLabel Title="Attachments" />
      </Grid>
      <Grid item xs={12} style={{ paddingBottom: '16px', width: '100%' }}>
        <span className={classes.spanTextStyle}>
          Kindly attach files in either doc, docx, pdf , img or jpg format
        </span>
      </Grid>
      <Grid item xs={12} className={classes.container}>
        <FileUploader
          disabled={isDisabled}
          fileAction={(
            <CustomButton
              variant="contained"
              actionType="primary"
              disableMargin
              disabled={isDisabled}
            >
              <AddIcon />
              <span>Attach File</span>
            </CustomButton>)}
          requestId={formikCtx.values.request_id}
          getFiles={formikCtx.values.attachment.length > 0 && formikCtx.values.attachment.filter((obj: any) => obj.file_section !== 'signature')}
        />
      </Grid>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <div style={{ paddingTop: '32px' }}>
          <CustomDatePicker
            isRangePicker={!datepickerProps.isSingleDate}
            setDateRangeValue={(e) => handleDateChange(e)}
            dateRange={datepickerProps.dateRange}
          />
        </div>
      </Popover>
    </Grid>

  );
}
export default DelegationOwner;
