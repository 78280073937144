import axios from '../common/axiosInstance';

// export const fetchFilterEndPoints = {
//   fetchRequestFilterEndPoint: '/get_requestor_request_ids/?request_type=1', // 1 is delegation, 2 is PAR, 3 is SAR. Check '/get_request_type/' to confirm,
//   fetchDelegationStatusFilterEndPoint: '/get_delegation_status/?request_type=1',
//   fetchTeamNameFilterEndPoint: '/get_team_name/',
// };

export const fetchFilterEndPoints = {
  //fetchRequestFilterEndPoint: '/get_requestor_request_ids/?request_type=1', // 1 is delegation, 2 is PAR, 3 is SAR. Check '/get_request_type/' to confirm,
  fetchDelegationStatusFilterEndPoint: '/get_delegation_status/?request_type=1',
  //fetchTeamNameFilterEndPoint: '/get_team_name/',
};

export const fetchAllFilterRequests = async (endPoint: string) => {
  const { data } = await axios.get(endPoint);
  return data;
};

export const fetchAllDelegations = async (queryStringValue: string) => {
  const endPoint = `/get_all_delegation_sar/`;
  const { data } = await axios.get(endPoint);
  return data;
};

// export const fetchAllFilterRequests = async (endPoint: string) => {
//   const { data } = await axios.get(endPoint);
//   return data;
// };

// export const fetchAllDelegations = async (queryStringValue: string) => {
//   const endPoint = `/get_all_delegation_sar/${queryStringValue}`;
//   const { data } = await axios.get(endPoint);
//   return data;
// };

export const postNewRequest = async (postBody: any) => {
  const endPoint = '/create_delegation_sar/';
  const { data } = await axios.post(endPoint, postBody);
  return data;
};

export const getRequestData = async (requestId: string, Admin?: string) => {
  let endPoint;
  if (Admin == 'Administrator') {
    endPoint = `/get_request_data/?request_id=${requestId}&role=Administrator`;
  } else {
    endPoint = `/get_request_data/?request_id=${requestId}`;
  }

  const { data } = await axios.get(endPoint);
  return data;
};

export const cancelDelegation = async (requestId: string) => {
  const endPoint = `/cancel_request_data/?request_id=${requestId}`;
  const { data } = await axios.get(endPoint);
  return data;
};

export const getAccountTypeValues = async (requestType: string) => {
  const endPoint = `/get_all_account_type/?request_type=${requestType}`;
  const { data } = await axios.get(endPoint);
  return data;
};

export const getAllDomains = async () => {
  const endPoint = '/get_all_domain/';
  const { data } = await axios.get(endPoint);
  return data;
};

export const getAllCompany = async () => {
  const endPoint = '/get_all_company/';
  const { data } = await axios.get(endPoint);
  return data;
};

export const getScopeValuesByGeographicScope = async (scope: string) => {
  // scope can be Market/Regional/Country/City
  const endPoint = `/get_geographical_scope/?geographical_scope=${scope}`;
  const { data } = await axios.get(endPoint);
  return data;
};

export const removeUploadDelegation = async (postBody: any) => {
  const endPoint = `/remove_del_sar_file/`;
  const { data } = await axios.post(endPoint, postBody);
  return data;
};


// for sar_implementer

export const getSarImplementer = async (request_id: string) => {
  const endPoint = `/get_sar_implementer_list/?request_id=${request_id}`;
  const { data } = await axios.get(endPoint);
  return data;
};

export const postSarImplementer = async (postBody: any) => {
  console.log("postBody", postBody)
  const endPoint = `/sar_on_premise_implementation/`;
  const { data } = await axios.post(endPoint, postBody);
  return data;
};

// fileUploadDelegation

export const submitRequestToUpdate = async ({
  justification,
  reqId,
}: {
  justification: string,
  reqId: number,
}) => {
  const payload = {
    request_id: reqId,
    justification_for_update: justification,
  };
  const endPoint = '/request_to_update/';
  const { data } = await axios.post(endPoint, payload);
  return data;
};

export const updateRequestStatus = async ({
  reqId,
  statusId,
}: {
  reqId: number,
  statusId: number,
}) => {
  const payload = {
    request_id: reqId,
    status: statusId,
  };
  const endPoint = '/update_status/';
  const { data } = await axios.post(endPoint, payload);
  return data;
};


// start of file removal delegation implementation
export const removeUploadDelegationImplementation = async (postBody: any) => {
  const endPoint = `/del_on_premise_implementer_file_remove/`;
  const { data } = await axios.post(endPoint, postBody);
  return data;
};
// end of file removal delegation implementation


export default fetchAllDelegations;
