import React from "react";

export const UserManagementSummaryColumnMapping = {
  userId: "user_id",
  requestorName: "name",
  email: "email",
  accountStatus: "account_status",
  oktaAccount: "okta_account",
  roleGroup: "role_group",
  actions: "actions",
};

export function createColumns(
  key: number,
  label: string,
  icon: any,
  id: string,
  isActive: boolean,
  handleClick?: (_e: any) => void,
  style?: React.CSSProperties
) {
  return {
    key,
    label,
    icon,
    handleClick,
    id,
    isActive,
    style,
  };
}

export function createRowCells(
  columnId: string,
  renderComponent: any,
  id: string,
  columnNumber: number,
  handleClick?: undefined | ((_e: any) => void),
  style?: React.CSSProperties
) {
  return {
    columnId,
    renderComponent,
    id,
    handleClick,
    columnNumber,
    style,
  };
}

export const initialTableParameters = {
  page: 1,
  orderBy: {
    order: "asc",
    column: "",
  },
};

export interface ITableParameters {
  page: number;
  orderBy: {
    order: string;
    column: string;
  };
}

export const emptyFilters = {
  requestorName: [],
  accountStatus: [],
  oktaAccount: [],
  roleGroup: [],
};
export interface IDateRangeObj {
  startDate: Date | null;
  endDate: Date | null;
}

export interface IFiltersObj {
  requestorName: string[];
  accountStatus: string[];
  oktaAccount: string[];
  roleGroup: string[];
}

export const cd  = {
  requestorName: [],
  accountStatus: [],
  oktaAccount: [],
  roleGroup: [],
};
export type changeData = {
  id: number,
  name: string,
}

export type filterDataType = {
  id: string,
  value: string,
}

export type dependencyrequestData = {
  label: string,
  value: string,
}

export type dependencyrequestForResquest = {
  requests: string,
  role_group: string,
}

export type groupData = {
  id: number,
}
export interface IUserManagementSummaryData {
  user_id: number;
  account_status: string;
  email: number;
  name: string;
  okta_account: string;
  role_group: [];
}