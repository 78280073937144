import React, { useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

export default function Loader({ openModal }: { openModal: boolean }) {
  //console.log("open", openModal)
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(openModal)
  }, [openModal])
  // const handleClose = () => {
  //   setOpen(false);
  // };
  //   const handleToggle = () => {
  //     setOpen(!open);
  //   };

  return (
    <div>
      {/* <Button onClick={handleToggle}>Show backdrop</Button> */}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      //onClick={handleClose}
      >
        <CircularProgress size="4rem" color="inherit" />
      </Backdrop>
    </div>
  );
}
