import React from 'react';

export const approvalSummaryColumnMapping = {
  request_number: 'request_number',
  requestId: 'requestId',
  requestorName: 'requestor_name',
  requestType: 'request_type',
  assignedOn: 'assigned_on',
  requestedOn: 'requested_on',
  request_stage: 'request_stage',
  last_request_stage: 'last_request_stage',
  myLastAction: 'my_last_action',
  currentStatus: 'current_status',
  actions: 'actions',
};

export function createColumns(
  key: number,
  label: string,
  icon: any,
  id: string,
  isActive: boolean,
  handleClick?: (_e: any) => void,
  style?: React.CSSProperties,
) {
  return {
    key, label, icon, handleClick, id, isActive, style,
  };
}

export function createRowCells(
  columnId: string,
  renderComponent: any,
  id: string,
  columnNumber: number,
  handleClick?: undefined | ((_e: any) => void),
  style?: React.CSSProperties,
) {
  return {
    columnId, renderComponent, id, handleClick, columnNumber, style,
  };
}

export const initialTableParameters = {
  page: 1,
  orderBy: {
    order: 'asc',
    column: '',
  },
};

export interface ITableParameters {
  page: number
  orderBy: {
    order: string,
    column: string,
  },
}
export interface IDateRangeObj {
  startDate: Date | null;
  endDate: Date | null;
}
export interface IFiltersObj {
  requestId: string[],
  requestorName: string[],
  currentStatus: string[],
  myLastAction: string[],
  requestType: string[],
  requestedOn: string[],
  assignedOn: string[]
}

export type IPropsFilter = {
  id: string,
  value: string
}
export const emptyFilters = {
  requestId: [],
  requestorName: [],
  currentStatus: [],
  myLastAction: [],
  requestType: [],
  requestedOn: [],
  assignedOn: [],
};

export interface IFilterApprovalSummaryData {
  request_id: number,
  requestor_name: string,
  current_status: string,
  my_last_action: string,
  request_type: string,
  assigned_on: string,
  requested_on: string,
  request_number: string
}
