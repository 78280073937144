export const accordian1 = 'Please review this Delegation from beginning to end. This will enable full understanding of the requirements for implementation. It is possible to navigate to any part of the Delegation using the menu on the left-hand side.<br/><br/>Comments, screenshots and scripts can be added during the implementation steps that follow.'

export const accordian2 = 'The Delegation has been approved with the below role groups.<br/><br/> Click on each role group to proceed through the required steps to implement the role group permissions.'

export const proposedRoleGroupName = 'This role group name has been proposed based on<br/> the defined naming standards. It can be edited if it<br/> is unsuitable, however, any changes should <br/>adhere to the documented standards.'

export const createRoleGroup1 = 'Paste PowerShell script in to the text box below'
export const createRoleGroup2 = 'Provide target group name and paste PowerShell<br/> script in to the text box below.<br/><br/> If this step is not applicable for this Delegation,<br/> check <b>Not required<b>.'
export const createRoleGroup3 = 'Provide Group Policy Object name and paste <br/>PowerShell script in to the text box below.<br/><br/> If this step is not applicable for this Delegation,<br/> check <b>Not required<b>.'
export const createRoleGroup4 = 'Paste resource names into the text box below, separated by commas.<br/><br/> For example: PSCGRMAPPP01101, LONGRMAPPP01101, HKGAPPD01101<br/><br/> <strong>OR</strong> OU = Computers, OU = MUM, OU = APAC, DC = ad, DC = insidemedia, DC = net<br/><br/> <strong>OR</strong> OU = AppServers, OU = Citrix, OU = Applications, DC = ad, DC = insidemedia, DC = net<br/><br/> The resources are documented in the Role Definition section of the Delegation.<br/>Use the left - hand menu to navigate to this section to retrieve the details.<br/><br/> If this step is not applicable for this Delegation, check Not required.'

export const createRoleGroup5 = 'Make sure you apply all the SRP policies, as applicable, to the Group Policy Editor and link this Group Policy to the OU and the target group.<br/> Set the SRP policy to authenticated users or other, as<br/> applicable, to enable this account to function as expected.<br/><br/> If this step is not applicable for this Delegation, check <b>Not required<b>.'

export const createRoleGroup6 = 'Assign access rights by selecting / deselecting from the list of available options within the Active Directory object, based on the approved requirement in this Delegation. See the Role Definition section for details of what has been approved.<br/><br/> If this step is not applicable for this Delegation, check <b>Not required<b>'

