/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@mui/styles';
import {
  AutocompleteProps,
  CircularProgress,
  TextField,
} from '@mui/material';
import { ReactComponent as DropDown } from '../../assets/icons/DropDownArrow.svg';

const styles = makeStyles({
  notchedOutline: {
    border: '1px solid #A7A7A7',
  },
  input: {
    fontSize: '14px',
    fontFamily: 'NotoSerif-Regular',
    WebkitTextFillColor: '#0A2756',
    '&.Mui-disabled': {
      WebkitTextFillColor: '#0A2756 !important',
    }
  },
  focused: {
    fontSize: '14px',
    fontFamily: 'NotoSerif-Regular',
  },
  label: {
    width: '100%',
    color: '#A7A7A7',
    fontSize: '12px',
    fontFamily: 'NotoSerif-Regular',
    '&.Mui-disabled': {
      color: '#0080FF !important',
    }
  },
  shrink: {
    transform: 'translate(14px, -8px) scale(0.9)',
    color: '#0080FF ',
  },
  error: {
    border: '#EF5B2B !important',
    letterSpacing: '0px',
    color: '#EF5B2B !importnant',
  },
  helperText: {
    font: 'normal normal normal 10px/20px NotoSerif-Regular',
  },
  helperTextError: {
    color: '#EF5B2B !important',
  },
  disable: {
    // '&.Mui-disabled': {
    color: '#0A2756 !important',
    // },
  },
  asterisk: {
    color: '#EF5B2B',
  },
  option: {
    font: 'normal normal normal 13px/16px NotoSerif-Regular',
    letterSpacing: '0px',
    color: '#0A2756',
    '&:hover': {
      backgroundColor: '#EDF4F8',
    },
  },
});

interface IOptionsObject {
  label: string,
  value: string,
}

interface ICustomAutocomplete<T extends IOptionsObject,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  name?: string,
  label: string,
  loading?: boolean,
  error?: string,
  onSelectionChange: (_selectedOption: any, _reason: any) => void;
  handleInputChange?: (_value: any, _reason: any) => void;
  disableClearable?: DisableClearable;
  required?: boolean;
  open?: boolean;
}

function CustomAutocomplete<
  T extends IOptionsObject,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  options,
  value,
  onSelectionChange,
  handleInputChange,
  disableClearable,
  label,
  freeSolo,
  loading,
  onBlur,
  error,
  disabled,
  required,
  open,
}: ICustomAutocomplete<T, Multiple, DisableClearable, FreeSolo>) {
  const styleClasses = styles();
  return (
    <Autocomplete
      open={open}
      options={options}
      popupIcon={<DropDown />}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          label={label}
          fullWidth
          size="small"
          helperText={error}
          error={!!error}
          InputProps={{
            ...params.InputProps,
            classes:
            {
              notchedOutline: styleClasses.notchedOutline,
              input: styleClasses.input,
              focused: styleClasses.focused,
              error: styleClasses.error,
              disabled: styleClasses.disable,
            },
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} data-testid="custom-autocomplete-loader" /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          InputLabelProps={{
            ...params.InputLabelProps,
            classes: {
              root: styleClasses.label,
              shrink: styleClasses.shrink,
              asterisk: styleClasses.asterisk,
            },
          }}
          FormHelperTextProps={{
            classes: {
              root: styleClasses.helperText,
              error: styleClasses.helperTextError,
            },
          }}
        />
      )}
      onChange={(_e, selectedOption, reason) => {
        onSelectionChange(selectedOption, reason);
      }}
      onInputChange={(_e, inputValue, reason) => {
        if (handleInputChange) {
          handleInputChange(inputValue, reason);
        }
      }}
      disableClearable={disableClearable}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
      isOptionEqualToValue={(option) => option.value === value}
      classes={{
        option: styleClasses.option,
        noOptions: styleClasses.option,
      }}
      value={value}
      freeSolo={freeSolo}
      onBlur={onBlur}
      disabled={disabled}
      data-testid="custom-autocomplete"
    />
  );
}

export default CustomAutocomplete;
