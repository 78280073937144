import { createSlice, current } from '@reduxjs/toolkit';
import { IRequestorPostData } from './IParRequestorData'
import type { PayloadAction } from '@reduxjs/toolkit'


export const parRequestorCommentSlice = createSlice({
    name: 'submitParRequestorCommentData',
    initialState: [
        {
            Account_requestor_details: []
        },
        { Account_owner_details: [] },
        { Job_description: [] },
        { Manager_details: [] },
        { Third_Manager_Party_details: [] },
        { ServiceNow_ticket_number: [] },
        { Account_justification: [] },
        { Domain: [] },
    ],

    reducers: {
        requestorFreshComments: (state, action: PayloadAction<IRequestorPostData>) => {
            state.map((row) => {
                Object.entries(row).map(([key, value]) => {
                    if (key === action.payload.section) {
                        value.push(action.payload)
                    }
                })
            })
        },
        // requestorEditComments: (state, action: PayloadAction<IpostData>) => {
        //     state.map((row) => {
        //         Object.entries(row).map(([key, value]) => {
        //             if (key === action.payload.section) {
        //                 value.push(action.payload)
        //             }
        //         })
        //     })
        // },
        // requestorDeleteComments: (state, action: PayloadAction<IpostData>) => {
        //     state.map((row) => {
        //         Object.entries(row).map(([key, value]) => {
        //             if (key === action.payload.section) {
        //                 value.push(action.payload)
        //             }
        //         })
        //     })
        // }


    }
});

export const { requestorFreshComments } = parRequestorCommentSlice.actions;
export default parRequestorCommentSlice.reducer;


