/* eslint-disable react/require-default-props */
import { makeStyles } from '@mui/styles';

interface ISectionProps {
  text: string;
  borderColor?: string;
}

interface IStyleProps {
  borderColor: string | undefined;
}

const styles = makeStyles({
  sectionHeader: ({ borderColor }: IStyleProps) => ({
    display: 'inline-flex',
    fontFamily: 'Poppins-Bold',
    fontSize: '15px',
    position: 'relative',
    color: '#0A2756',
    '&:after': {
      border: '1px solid #FFA800;',
      borderColor: borderColor || '#FFA800',
      width: '3ch !important',
      content: '""',
      height: '0px',
      position: 'absolute',
      bottom: '-8px',
    },
  }),
});

function SectionHeader({ text, borderColor }: ISectionProps) {
  const classes = styles({ borderColor });
  return (
    <div className={classes.sectionHeader} data-testid="section-header">
      {text}
    </div>
  );
}

export default SectionHeader;
