import {
    useEffect,
    useRef,
    useState,
    useCallback,
} from 'react';
import Grid from '@mui/material/Grid';
import {
    useFormikContext,
} from 'formik';
import FormikControl from '../../../components/formik-conrtols/FormikControl';
import adminSummaryStyles from "../administrative-summary-styles";
import { IApprovarData } from './approvarManagementUtils';

function EditApproverInfo({ data, approverlist }: any) {
    const [approvarList, setApprovarList] = useState<IApprovarData[]>([])
    const [approvarList1, setApprovarList1] = useState<IApprovarData[]>([])
    const [approverList2, setApprovarList2] = useState<IApprovarData[]>([])
    const styleClasses = adminSummaryStyles();
    const formikCtx: any = useFormikContext();
    const isAutoCompleteSelected = useRef(false);
    useEffect(() => {
        if (data.data) {
            formikCtx.setFieldValue('stage_name', data.stage_name);
            formikCtx.setFieldValue('primary_approver', data.primary_approver);
            formikCtx.setFieldValue('primary_approver_email', data.primary_approver_email);
            formikCtx.setFieldValue('backup_approver_1', data.backup_approver_1);
            formikCtx.setFieldValue('backup_approver_1_email', data.backup_approver_1_email);
            formikCtx.setFieldValue('backup_approver_2', data.backup_approver_2);
            formikCtx.setFieldValue('backup_approver_2_email', data.backup_approver_2_email);
        }

    }, []);


    useEffect(() => {
        setApprovarList(approverlist && approverlist.filter((approver: any) => approver.name !== formikCtx.values.primary_approver));
    }, [formikCtx.values.primary_approver])

    useEffect(() => {
        setApprovarList1(approverlist && approverlist.filter((approver: any) => approver.name !== formikCtx.values.backup_approver_1));
        if (formikCtx.values.backup_approver_1 == 'None') {
            setApprovarList2(approverlist && approverlist.filter((approver: any) => approver.name !== formikCtx.values.backup_approver_2 && approver.name !== formikCtx.values.primary_approver));
        } else {
            setApprovarList2(approverlist && approverlist.filter((approver: any) => approver.name !== formikCtx.values.backup_approver_2 && approver.name !== formikCtx.values.backup_approver_1 && approver.name !== formikCtx.values.primary_approver));
        }
    }, [formikCtx.values.backup_approver_1])

    useEffect(() => {
        setApprovarList2(approverlist && approverlist.filter((approver: any) => (approver.name !== formikCtx.values.backup_approver_2)));
    }, [formikCtx.values.backup_approver_2])
    return (
        <>
            <Grid container spacing={2} className={styleClasses.editModal}>
                <Grid item xs={6}>
                    <FormikControl
                        control="textfield"
                        label="Stage name"
                        name="stage_name"
                        required
                    />
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                    <FormikControl
                        control="autocomplete"
                        label="Primary approver"
                        name="primary_approver"
                        freeSolo
                        options={
                            approvarList?.length > 0 &&
                            approvarList
                                .filter((approver: any) => approver.id !== 0 && approver.name !== formikCtx.values.primary_approver)
                                .map((item: any) => ({
                                    label: item.name,
                                    value: item.id,
                                }))
                        }
                        handleSelectionChange={(selectedValue: {
                            value: string;
                            label: string;
                        }) => {
                            const selectedApprover = approverlist?.find(
                                (obj: any) => obj.id == selectedValue.value
                            );
                            isAutoCompleteSelected.current = true;
                            formikCtx.setFieldValue(
                                "primary_approver",
                                selectedApprover ? selectedApprover.name : ""
                            );
                            formikCtx.setFieldValue(
                                "primary_approver_email",
                                selectedApprover ? selectedApprover.email : ""
                            );
                        }}
                        required
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormikControl
                        control="textfield"
                        label="Email address"
                        name="primary_approver_email"
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormikControl
                        control="autocomplete"
                        label="Backup approver 1"
                        name="backup_approver_1"
                        freeSolo
                        options={
                            approvarList1?.length > 0 && approvarList1.filter((approver: any) => approver.name !== formikCtx.values.primary_approver).map((item: any) => ({
                                label: item.name,
                                value: item.id,
                            }))
                        }
                        handleSelectionChange={(selectedValue: {
                            value: string;
                            label: string;
                        }) => {
                            const selectedApprover = approvarList?.find(
                                (obj: any) => obj.id == selectedValue.value
                            );
                            isAutoCompleteSelected.current = true;
                            formikCtx.setFieldValue(
                                "backup_approver_1",
                                selectedApprover ? selectedApprover?.name : ""
                            );
                            formikCtx.setFieldValue(
                                "backup_approver_1_email",
                                selectedApprover ? selectedApprover?.email : ""
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormikControl
                        control="textfield"
                        label="Email address"
                        name="backup_approver_1_email"
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormikControl
                        control="autocomplete"
                        label="Backup approver 2"
                        name="backup_approver_2"
                        freeSolo
                        options={
                            approverList2?.length > 0 &&
                            // approverList2.filter((approver: any) => approver.name !== formikCtx.values.primary_approver)
                            approverList2.map((item: any) => ({
                                label: item.name,
                                value: item.id,
                            }))
                        }
                        handleSelectionChange={(selectedValue: {
                            value: string;
                            label: string;
                        }) => {
                            const selectedApprover = approvarList?.find(
                                (obj: any) => obj.id == selectedValue.value
                            );
                            isAutoCompleteSelected.current = true;
                            formikCtx.setFieldValue(
                                "backup_approver_2",
                                selectedApprover ? selectedApprover.name : ""
                            );
                            formikCtx.setFieldValue(
                                "backup_approver_2_email",
                                selectedApprover ? selectedApprover.email : ""
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormikControl
                        control="textfield"
                        label="Email address"
                        name="backup_approver_2_email"
                        disabled={true}
                    />
                </Grid>
            </Grid>
        </>
    );
}
export default EditApproverInfo;
