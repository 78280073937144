import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import FormikControl from '../../../formik-conrtols/FormikControl';
import FieldLabel from '../../../field-label';
import ReviewActions from '../../../review-actions';
import { format, isDate } from 'date-fns';
import { RootState } from '../../../../appStore/store';
import { getAllDomains } from '../../../../api-services/delegation-module';
import { getAllCitiesByCountryId } from '../../../../api-services/common-api';
import { LogonText, PrewindowText, selectDomain, serviceAccountDetails } from '../tooltips';
import { parStyles, sarUseStyles } from '../../useStyles';
import HistoryTable from '../../../../modules/sar/comments/historyTable';
import CommentSubmission from '../../../../modules/sar/comments/commentSubmission';
import { routeCheck, routeCheckForAdminSummary, routeCheckForApprovalSummary, routeCheckForImplementationSummary, routeCheckForPendingApproval } from './routingConditional';
import HelperTooltip from '../../../helper-tooltip';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';

function ServiceAccountDetails({ isDisabled }: { isDisabled: boolean }) {
    const classes = sarUseStyles()
    const [domainOptions] = useState([{ label: 'ad.insidemedia.net (AD)', value: "ad.insidemedia.net" }, { label: 'insidemedia.onmicrosoft.com (Azure)', value: "insidemedia.onmicrosoft.com (Azure)" }, { label: 'Hybrid (AD / Azure)', value: "Hybrid (AD/Azure)" }]);

    // dummy Company code
    const [companyCode] = useState([{ label: 'CYB-01', value: "CYB-01", }, {
        label: 'IBM', value: "IBM",
    }, { label: 'TCS-03', value: "TCS-03" }, { label: 'GROUPM-04', value: 'GROUPM-04' }, { label: 'INFY-05', value: 'INFY-05' }]);

    const formikCtx: any = useFormikContext();
    const styleClasses = parStyles();
    const formatDateInside = (dateValue: any) => (dateValue && isDate(new Date(dateValue)) ? format(new Date(dateValue), 'MMM dd, yyyy') : '');
    const remediated_by = formikCtx.values.remediation_by
    const remediationCount = formikCtx.values.remediation_count;
    const { fieldHistorySarCommentsData } = useSelector((state: RootState) => state);
    const { submitSarCommentData } = useSelector((state: RootState) => state);
    const [cityCode, setCityCode] = useState([]);

    useEffect(() => {
        // if (formikCtx.values.country !== '' && countryList.length) {
        //     const selectedCountry: any = countryList
        //         .find((countryObj: any) => countryObj.country === formikCtx.values.country);
        getAllCitiesByCountryId().then((res) => {
            setCityCode(res.data);
            // console.log("res", res.data)
        }).catch((err) => {
            console.log(err);
        })
        // }
    }, [])

    useEffect(() => {
        let mergeFieldsName = '';
        if (formikCtx.values.sar_domain_location == 'Hybrid (AD/Azure)') {
            mergeFieldsName = `_${formikCtx.values.sar_city_code.substr(0, 3)}${formikCtx.values.sar_company_code.substr(0, 3)}.${formikCtx.values.sar_application_name.substr(0, 12)}@ad.insidemedia.net`
        } else {
            mergeFieldsName = `_${formikCtx.values.sar_city_code.substr(0, 3)}${formikCtx.values.sar_company_code.substr(0, 3)}.${formikCtx.values.sar_application_name.substr(0, 12)}@${formikCtx.values.sar_domain_location.split(' ')[0]}`
        }
        formikCtx.setFieldValue('sar_user_logon_name_upn', mergeFieldsName);
        formikCtx.setFieldValue('sar_pre_windows_2000_username', mergeFieldsName);

        if (formikCtx.values.sar_domain_location === "ad.insidemedia.net") {
            formikCtx.setFieldValue('sar_external_internal_ip_range', 'InternalOnly')
        } else {
            formikCtx.setFieldValue('sar_external_internal_ip_range', 'Static')
        }

    }, [formikCtx.values.sar_domain_location, formikCtx.values.sar_city_code, formikCtx.values.sar_company_code, formikCtx.values.sar_application_name])

    const checkForEditReviewFlow = () => {
        return routeCheck() || routeCheckForAdminSummary() || routeCheckForImplementationSummary() || routeCheckForApprovalSummary()
    }

    return (
        <>
            <Grid container >
                <Grid item xs={10}>
                    <>
                        <FieldLabel Title="Service account details" tooltipMsg={serviceAccountDetails} required />
                    </>
                    <>
                        <div className={styleClasses.updatedByStyle}>
                            {/* @ts-ignore */}
                            {(fieldHistorySarCommentsData[4].Service_account_details?.length > 0) && `Updated on ${formatDateInside(fieldHistorySarCommentsData[4].Service_account_details[fieldHistorySarCommentsData[4].Service_account_details?.length - 1].last_updated)}`}
                        </div>
                    </>
                </Grid>
                <Grid item xs={2}>
                    {(checkForEditReviewFlow() && remediationCount > 0) ? (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="Service_account_details"
                                subSection=''
                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[4].Service_account_details : null}
                            />
                            <CommentSubmission
                                sectionName='Service_account_details'
                                subSection=''
                                updateType='Select update'
                                remediation_by={remediated_by}
                                data={submitSarCommentData.length > 0 ? submitSarCommentData[4].Service_account_details : null}
                            />
                        </div>
                    ) : routeCheckForPendingApproval() && (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="Service_account_details"
                                subSection=''
                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[4].Service_account_details : null}
                            />
                            <CommentSubmission
                                sectionName='Service_account_details'
                                subSection=''
                                updateType='Select update'
                                remediation_by={remediated_by}
                                data={submitSarCommentData.length > 0 ? submitSarCommentData[4].Service_account_details : null}
                            />
                        </div>
                    )}
                </Grid>
            </Grid>
            <Grid container >
                <Grid item xs={3}>
                    <FormikControl
                        control="autocomplete"
                        label="City code"
                        name="sar_city_code"
                        options={
                            cityCode?.map((companyObj: any) => ({
                                label: companyObj.city_code,
                                value: companyObj.city_code,
                            }))
                        }
                        disabled={formikCtx.values.oktaCompany ? true : isDisabled}
                    />
                </Grid>

                <Grid item xs={9}>
                </Grid>
            </Grid>

            <Grid container className={classes.marginTopForDropdown} >
                <Grid item xs={3}>
                    <FormikControl
                        control="autocomplete"
                        label="Company code"
                        name="sar_company_code"
                        options={
                            companyCode?.map((companyObj: any) => ({
                                label: companyObj.label,
                                value: companyObj.value,
                            }))
                        }
                        disabled={formikCtx.values.oktaCompany ? true : isDisabled}
                    />
                </Grid>
                <Grid item xs={9}>

                </Grid>
            </Grid>
            <Grid container className={classes.marginTopForDropdown}>
                <Grid item xs={3}>
                    <FormikControl
                        control="textfield"
                        label="Application / Function name"
                        name="sar_application_name"
                        disabled={isDisabled}
                    />
                </Grid>
                <Grid item xs={9}>

                </Grid>
            </Grid >
            <Grid container paddingTop={'12px'}>
                <Grid item xs={10}>
                    <>
                        <FieldLabel Title="Select domain" tooltipMsg={selectDomain} required />
                    </>
                    <>
                        <div className={styleClasses.updatedByStyle}>
                            {/* @ts-ignore */}
                            {(fieldHistorySarCommentsData[5].Select_domain?.length > 0) && `Updated on ${formatDateInside(fieldHistorySarCommentsData[5].Select_domain[fieldHistorySarCommentsData[5].Select_domain?.length - 1].last_updated)}`}
                        </div>
                    </>
                </Grid>

                <Grid item xs={2}>
                    {(checkForEditReviewFlow() && remediationCount > 0) ? (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="Select_domain"
                                subSection=''
                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[5].Select_domain : null}
                            />
                            <CommentSubmission
                                sectionName='Select_domain'
                                subSection=''
                                updateType='Select update'
                                remediation_by={remediated_by}
                                data={submitSarCommentData.length > 0 ? submitSarCommentData[5].Select_domain : null}
                            />
                        </div>
                    ) : routeCheckForPendingApproval() && (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="Select_domain"
                                subSection=''
                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[5].Select_domain : null}
                            />
                            <CommentSubmission
                                sectionName='Select_domain'
                                subSection=''
                                updateType='Select update'
                                remediation_by={remediated_by}
                                data={submitSarCommentData.length > 0 ? submitSarCommentData[5].Select_domain : null}
                            />
                        </div>
                    )}
                </Grid>
                <Grid item xs={5}>
                    <FormikControl
                        disabled={isDisabled}
                        control="radio"
                        name="sar_domain_location"
                        radioGrouplabels={domainOptions}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            formikCtx.setFieldValue('sar_domain_location', e.target.value);
                        }}
                    />
                </Grid>
                <Grid item xs={5}>

                </Grid>
            </Grid>
            <Grid container paddingTop={'12px'} >
                <Grid item xs={10}>
                    <>
                        <FieldLabel Title="Service account name" />
                    </>
                    <>
                        <div className={styleClasses.updatedByStyle}>
                            {/* @ts-ignore */}
                            {(fieldHistorySarCommentsData[6].Service_account_name?.length > 0) && `Updated on ${formatDateInside(fieldHistorySarCommentsData[6].Service_account_name[fieldHistorySarCommentsData[6].Service_account_name?.length - 1].last_updated)}`}
                        </div>
                    </>
                </Grid>

                <Grid item xs={2}>
                    {(checkForEditReviewFlow() && remediationCount > 0) ? (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="Service_account_name"
                                subSection=''
                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[6].Service_account_name : null}
                            />
                            <CommentSubmission
                                sectionName='Service_account_name'
                                subSection=''
                                updateType='Select update'
                                remediation_by={remediated_by}
                                data={submitSarCommentData.length > 0 ? submitSarCommentData[6].Service_account_name : null}
                            />
                        </div>
                    ) : routeCheckForPendingApproval() && (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="Service_account_name"
                                subSection=''
                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[6].Service_account_name : null}
                            />
                            <CommentSubmission
                                sectionName='Service_account_name'
                                subSection=''
                                updateType='Select update'
                                remediation_by={remediated_by}
                                data={submitSarCommentData.length > 0 ? submitSarCommentData[6].Service_account_name : null}
                            />
                        </div>
                    )}
                </Grid>
            </Grid>
            <Grid container paddingTop={'12px'} >
                <Grid item xs={5}>
                    <FormikControl
                        control="textfield"
                        label="User logon name (UPN)"
                        name="sar_user_logon_name_upn"
                        disabled={isDisabled}
                    />
                </Grid>
                <Grid item sm={2} className={classes.toolTipPosition} >
                    <span className={classes.tooltipSpan}>
                        <HelperTooltip tooltipMsg={LogonText}>
                            <HelpRoundedIcon style={{ color: '#0A2756' }} />
                        </HelperTooltip>
                    </span>
                </Grid>
                <Grid item xs={7}>

                </Grid>
            </Grid >
            <Grid container paddingTop={'12px'} >
                <Grid item xs={5}>
                    <FormikControl
                        control="textfield"
                        label="Pre-windows 2000 user name"
                        name="sar_pre_windows_2000_username"
                        disabled={isDisabled}
                    />
                </Grid>
                <Grid item sm={2} className={classes.toolTipPosition} >
                    <span className={classes.tooltipSpan}>
                        <HelperTooltip tooltipMsg={PrewindowText}>
                            <HelpRoundedIcon style={{ color: '#0A2756' }} />
                        </HelperTooltip>
                    </span>
                </Grid>
                <Grid item xs={7}>

                </Grid>
            </Grid >
        </>
    )
}

export default ServiceAccountDetails;