import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { format, isDate, isWithinInterval } from "date-fns";
import _, { isEmpty, values } from "lodash";
import myImplementationTasksStyles from "../my-Implementation-tasks-styles";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { Grid } from "@mui/material";
import { ReactComponent as Sorting } from "../../../assets/icons/sorting_asc_inactive.svg";
import { ReactComponent as SortingAscending } from "../../../assets/icons/sorting_asc.svg";
import { ReactComponent as SortingDescending } from "../../../assets/icons/sorting_desc.svg";
import useDaterangePicker from "../../../components/date-range-picker/useDaterangePicker";
import { createRowCells } from "./pendingImplementationUtils";
import { Formik, Form, FormikProps, FormikValues } from "formik";
import FormikControl from "../../../components/formik-conrtols/FormikControl";
import CustomButton from "../../../components/button";

import { useSelector, useDispatch } from "react-redux";
import type { RootState } from "../../../appStore/store";

import {
  pendingImplemetationColumnMapping,
  createColumns,
  emptyFilters,
  IFiltersObj,
  initialTableParameters,
  ITableParameters,
  IPendingImplementationData,
  LastAction,
  changeData,
} from "./pendingImplementationUtils";
import {
  fetchMyPendingImplementationData,
  fetchTypeWiseImplementer,
  submitUpdateImpelementor,
  postDelegateToSecondaryImplementerEndPoints,
  fetchImplementerRequestDelegateData,
} from "../../../api-services/pending-implementation";

function usePendingImplementationHook() {
  const history = useHistory();
  const styleClasses = myImplementationTasksStyles();

  const { loginuserdata, loginsyncupdata } = useSelector(
    (state: RootState) => state.loginData
  );
  const [pendingImplementationData, setPendingImplementationData] = useState({
    count: 0,
    results: [],
    pageSize: 10,
  });

  const implementerRequestDelegate = {
    comment: "",
    delegated_by: "",
    delegated_on: "",
  };

  const [implementerRequestDelegateData, setImplementerRequestDelegateData] =
    useState({
      delegated: implementerRequestDelegate,
    });

  const [
    pendingImplementationDataFromApi,
    setPendingImplementationDataFromApi,
  ] = useState([]);
  const [
    tempPendingImplementationDataFromApi,
    setTempPendingImplementationDataFromApi,
  ] = useState([]);

  const [filters, setFilters] = useState<IFiltersObj>(emptyFilters);
  const [closeFilter, setCloseFilter] = useState(false);
  const { datepickerProps, setDatepickerProps, setDateRangeValue } =
    useDaterangePicker({ isSingleDate: true });

  const [assignOnBadge, setAssignOnBadge] = useState(false);
  const [requestOnBadge, setRequestOnBadge] = useState(false);

  const [tableParametersPending, setTableParametersPending] =
    useState<ITableParameters>(initialTableParameters);
  const [requestIdFilter, setRequestIdFilters] = useState([]);
  const [requestorNameFilter, setRequestorNameFilters] = useState<LastAction[]>(
    [{ id: "", value: "" }]
  );
  const [requestTypeFilter, setRequestTypeFilters] = useState<LastAction[]>([
    { id: "", value: "" },
  ]);
  const [requestNameFilter, setRequestNameFilters] = useState<LastAction[]>([
    { id: "", value: "" },
  ]);

  const formRef = useRef<FormikProps<FormikValues>>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [isAPILoading, setIsAPILoading] = useState(false);
  const initialValues = {
    change_implementer: "",
    comment: "",
  };
  const validationSchema = Yup.object({
    change_implementer: Yup.string().required("Required"),
    comment: Yup.string().required("Required"),
  });
  const [changeImplementorsData, setchangeImplementorsData] = useState<
    changeData[]
  >([]);

  const [menuData, setMenuData] = useState({
    anchorEl: null,
    row: {
      request_id: null,
      request_stage_id: null,
      request_type: null,
      request_status: null,
      linked_delegation: null,
      request_stage: null,
      delegate_to_secondary: null,
      implementer_type_id: null,
      request_purpose: null
    },
  });
  const [modalData, setModalData] = useState<{
    data: any;
    type: string;
  }>({ data: null, type: "" });
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isLoadingPending, setIsLoadingPending] = useState({
    tableLoading: false,
    filters: {
      requestId: false,
      requestorName: false,
      currentStatus: false,
      myLastAction: false,
      requestType: false,
      requestName: false,
    },
  });

  const fetchFiltersValues = () => {
    setIsLoadingPending((loadingState) => ({
      ...loadingState,
      filters: {
        ...loadingState.filters,
      },
    }));
  };

  const openDelegation = (id: any, row: any) => {
    setMenuData({
      anchorEl: null,
      row: {
        request_id: null,
        request_stage_id: null,
        request_type: null,
        request_status: null,
        linked_delegation: null,
        request_stage: null,
        delegate_to_secondary: null,
        implementer_type_id: null,
        request_purpose: null
      },
    });

    if (row.request_type === 'Delegation') {
      if (row.request_purpose === "Connect to / manage a server or computer") {
        history.push(`/myImplementationTasks/pendingImplementation/${row.request_id}`);
      } else {
        history.push(`/myImplementationTasks/pendingImplementationSar/${row.request_id}`);
      }
    } else {
      history.push(`/myImplementationTasks/pendingImplementationpar/${row.request_id}`);
    }
    return null;
  };

  useEffect(() => {
    fetchAllPendingImplementation();
    fetchFiltersValues();
    setIsLoadingPending((loadingState) => ({
      ...loadingState,
      tableLoading: true,
    }));
  }, []);

  // FILTERS START
  const fetchFilteredDataWithDebounce = (filtersObject: any) => {
    if (filtersObject?.requestId?.length > 0) {
      setTempPendingImplementationDataFromApi([
        ...pendingImplementationDataFromApi.filter(
          (obj: IPendingImplementationData) =>
            filtersObject?.requestId.find(
              (item: string) => item === obj.request_number
            )
        ),
      ]);
      setTableParametersPending((prevParameters: any) => ({
        ...prevParameters,
        page: Math.ceil(
          filtersObject?.requestId?.length / pendingImplementationData.pageSize
        ),
      }));
    } else if (filtersObject?.requestorName?.length > 0) {
      setTempPendingImplementationDataFromApi([
        ...pendingImplementationDataFromApi.filter(
          (obj: IPendingImplementationData) =>
            filtersObject?.requestorName.find(
              (item: string) => item === obj.requestor_name
            )
        ),
      ]);
      setTableParametersPending((prevParameters: any) => ({
        ...prevParameters,
        page: Math.ceil(
          filtersObject?.requestorName?.length / pendingImplementationData.pageSize
        ),
      }));
    } else if (filtersObject?.requestType?.length > 0) {
      setTempPendingImplementationDataFromApi([
        ...pendingImplementationDataFromApi.filter(
          (obj: IPendingImplementationData) =>
            filtersObject?.requestType.find(
              (item: string) => item == obj.request_type
            )
        ),
      ]);
      setTableParametersPending((prevParameters: any) => ({
        ...prevParameters,
        page: Math.ceil(
          filtersObject?.requestType?.length / pendingImplementationData.pageSize
        ),
      }));
    } else if (filtersObject?.requestName?.length > 0) {
      setTempPendingImplementationDataFromApi([
        ...pendingImplementationDataFromApi.filter(
          (obj: IPendingImplementationData) =>
            filtersObject?.requestName.find(
              (item: string) => item == obj.request_name
            )
        ),
      ]);
      setTableParametersPending((prevParameters: any) => ({
        ...prevParameters,
        page: Math.ceil(
          filtersObject?.requestName?.length / pendingImplementationData.pageSize
        ),
      }));
    } else {
      setTempPendingImplementationDataFromApi(pendingImplementationDataFromApi);
    }
  };

  useEffect(() => {
    fetchFilteredDataWithDebounce(filters);
  }, [filters]);

  const formatDate = (dateValue: any) =>
    dateValue && isDate(new Date(dateValue))
      ? format(new Date(dateValue), "dd MMM yyyy")
      : "";

  const setDateRangeFilterAssignOn = () => {
    setCloseFilter(true);
    setAssignOnBadge(true);
    setTempPendingImplementationDataFromApi([
      ...pendingImplementationDataFromApi.filter(
        (obj: IPendingImplementationData) => {
          if (
            isWithinInterval(new Date(`${obj.assigned_on}`), {
              // @ts-ignore */
              start: datepickerProps.dateRange.startDate,
              // @ts-ignore */
              end: datepickerProps.dateRange.endDate,
            }) ||
            formatDate(obj.assigned_on) ===
            formatDate(datepickerProps.dateRange.endDate)
          )
            return true;
          return false;
        }
      ),
    ]);
  };

  const setDateRangeFilterRequestedOn = () => {
    setCloseFilter(true);
    setRequestOnBadge(true);
    setTempPendingImplementationDataFromApi([
      ...pendingImplementationDataFromApi.filter(
        (obj: IPendingImplementationData) => {
          if (
            isWithinInterval(new Date(`${obj.requested_on}`), {
              // @ts-ignore */
              start: datepickerProps.dateRange.startDate,
              // @ts-ignore */
              end: datepickerProps.dateRange.endDate,
            }) ||
            formatDate(obj.requested_on) ===
            formatDate(datepickerProps.dateRange.endDate)
          )
            return true;
          return false;
        }
      ),
    ]);
  };

  // clear value handler
  const clearValueHandler = () => {
    setAssignOnBadge(false);
    setRequestOnBadge(false);
    setIsLoadingPending((loadingState) => ({
      ...loadingState,
      tableLoading: false,
    }));
    setDatepickerProps({
      isSingleDate: false,
      dateRange: {
        startDate: null,
        endDate: null,
      },
    });
    setFilters(emptyFilters);
    setTempPendingImplementationDataFromApi(pendingImplementationDataFromApi);
  };

  // FILTERS END

  // LISTING TABLE START

  useEffect(() => {
    const rowsArray = tempPendingImplementationDataFromApi.map((row: any) => {
      const cellArray = Object.entries(row)
        .map(([key, value]) => {
          let cellData = null;
          // row.request_type  == 'PAR' && row.delegation_status != 'Completed'
          switch (key) {
            case "request_number":
              cellData = createRowCells(
                pendingImplemetationColumnMapping.request_number,
                `${value}`,
                `${row.request_id}-${value}`,
                1,
                () =>
                  openDelegation(
                    row.linked_delegation ? row.linked_delegation : value, row
                  ),
                row.request_type == "PAR" &&
                  row.delegation_status != "Completed" &&
                  row.linked_delegation != null
                  ? {
                    textDecoration: "underline",
                    color: "#B9C3CE",
                    pointerEvents: "none",
                  }
                  : { textDecoration: "underline", color: "#0080FF" }
              );
              break;
            case "requestor_name":
              cellData = createRowCells(
                pendingImplemetationColumnMapping.requestorName,
                `${value || ""}`,
                `${row.request_id}-${value || ""}`,
                2,
                undefined,
                row.request_type == "PAR" &&
                  row.delegation_status != "Completed" &&
                  row.linked_delegation != null
                  ? {
                    color: "#B9C3CE",
                    pointerEvents: "none",
                  }
                  : {}
              );
              break;
            case "request_type":
              cellData = createRowCells(
                pendingImplemetationColumnMapping.requestType,
                `${value || ""}`,
                `${row.request_id}-${value || ""}`,
                3,
                undefined,
                row.request_type == "PAR" &&
                  row.delegation_status != "Completed" &&
                  row.linked_delegation != null
                  ? {
                    color: "#B9C3CE",
                    pointerEvents: "none",
                  }
                  : {}
              );
              break;
            case "request_name":
              cellData = createRowCells(
                pendingImplemetationColumnMapping.requestName,
                `${value || ""}`,
                `${row.request_id}-${value || ""}`,
                4,
                undefined,
                row.request_type == "PAR" &&
                  row.delegation_status != "Completed" &&
                  row.linked_delegation != null
                  ? {
                    color: "#B9C3CE",
                    pointerEvents: "none",
                  }
                  : {}
              );
              break;
            // case "request_stage":
            //   cellData = createRowCells(
            //     pendingImplemetationColumnMapping.requestStage,
            //     `${value || ""}`,
            //     `${row.request_id}-${value || ""}`,
            //     5
            //   );
            //   break;

            case "requested_on":
              cellData = createRowCells(
                pendingImplemetationColumnMapping.requestedOn,
                `${formatDate(value)}`,
                `${row.request_id}-${value || ""}`,
                5,
                undefined,
                row.request_type == "PAR" &&
                  row.delegation_status != "Completed" &&
                  row.linked_delegation != null
                  ? {
                    color: "#B9C3CE",
                    pointerEvents: "none",
                  }
                  : {}
              );
              break;
            case "assigned_on":
              cellData = createRowCells(
                pendingImplemetationColumnMapping.assignedOn,
                `${formatDate(value)}`,
                `${row.request_id}-${value || ""}`,
                6,
                undefined,
                row.request_type == "PAR" &&
                  row.delegation_status != "Completed" &&
                  row.linked_delegation != null
                  ? {
                    color: "#B9C3CE",
                    pointerEvents: "none",
                  }
                  : {}
              );
              break;
            default:
              break;
          }
          return cellData;
        })
        .filter((obj: any) => obj);
      cellArray.push(
        createRowCells(
          pendingImplemetationColumnMapping.actions,
          <MoreVertIcon
            className={`${row.request_type == "PAR" &&
              row.delegation_status != "Completed" &&
              row.linked_delegation != null
              ? styleClasses.moreIconDisable
              : styleClasses.moreIcon
              } ${!!menuData.anchorEl && styleClasses.moreIconActive}`}
          />,
          `more-actions-${row.id}`,
          7,
          (e) =>
            openMenu(
              e,
              row.request_id,
              row.request_stage_id,
              row.request_type,
              row.request_status,
              row.linked_delegation,
              row.request_stage,
              row.delegate_to_secondary,
              row.implementer_type_id,
              row.request_purpose
            ),
          row.request_type == "PAR" &&
            row.delegation_status != "Completed" &&
            row.linked_delegation != null
            ? {
              color: "#B9C3CE",
              pointerEvents: "none",
              paddingLeft: "35px",
            }
            : { paddingLeft: "35px" }
        )
      );
      return cellArray;
    });
    setPendingImplementationData((prevState: any) => ({
      ...prevState,
      results: [...rowsArray],
    }));
  }, [tempPendingImplementationDataFromApi]);

  const fetchAllPendingImplementation = async () => {
    // @ts-ignore */
    await fetchMyPendingImplementationData()
      .then((res: any) => {
        setPendingImplementationDataFromApi(
          res.data.sort((a: any, b: any) =>
            b.requested_on.localeCompare(a.requested_on)
          )
        );
        setTempPendingImplementationDataFromApi(
          res.data.sort((a: any, b: any) =>
            b.requested_on.localeCompare(a.requested_on)
          )
        );

        // @ts-ignore */
        setRequestIdFilters(
          res.data?.map((requestIdObj: { request_number: string }) => ({
            id: requestIdObj.request_number,
            value: requestIdObj.request_number,
          }))
        );

        // @ts-ignore */
        //For requestorName
        const requestorName = [
          ...new Set(
            res.data?.map(
              ({ requestor_name }: { requestor_name: any }) => requestor_name
            )
          ),
        ].map((e) =>
          res.data?.find(
            ({ requestor_name }: { requestor_name: any }) => requestor_name == e
          )
        );
        // @ts-ignore */
        setRequestorNameFilters(
          requestorName?.map(
            (requestorNameObj: { requestor_name: string }) => ({
              id: requestorNameObj.requestor_name,
              value:
                requestorNameObj.requestor_name === ""
                  ? "(Blanks)"
                  : requestorNameObj.requestor_name,
            })
          )
        );

        // @ts-ignore */
        //For requestType
        const requestType = [
          ...new Set(
            res.data?.map(
              ({ request_type }: { request_type: any }) => request_type
            )
          ),
        ].map((e) =>
          res.data?.find(
            ({ request_type }: { request_type: any }) => request_type == e
          )
        );
        // @ts-ignore */
        setRequestTypeFilters(
          requestType?.map((requestTypeObj: { request_type: string }) => ({
            id: requestTypeObj.request_type,
            value:
              requestTypeObj.request_type === ""
                ? "(Blanks)"
                : requestTypeObj.request_type,
          }))
        );

        // @ts-ignore */
        const requestName = [
          ...new Set(
            res.data?.map(
              ({ request_name }: { request_name: any }) => request_name
            )
          ),
        ].map((e) =>
          res.data?.find(
            ({ request_name }: { request_name: any }) => request_name == e
          )
        );
        // @ts-ignore */
        setRequestNameFilters(
          requestName?.map((requestNameObj: { request_name: string }) => ({
            id: requestNameObj.request_name,
            value:
              requestNameObj.request_name === ""
                ? "(Blanks)"
                : requestNameObj.request_name,
          }))
        );

        setIsLoadingPending((loadingState) => ({
          ...loadingState,
          tableLoading: false,
        }));
      })
      .catch((error: string) => {
        setIsLoadingPending((loadingState) => ({
          ...loadingState,
          tableLoading: false,
        }));
      });
  };

  // for sorting
  useEffect(() => {
    tableParametersPending.orderBy.column !== ""
      ? createPendingImplementation()
      : setTempPendingImplementationDataFromApi(
        pendingImplementationDataFromApi
      );
  }, [
    tableParametersPending.orderBy.column,
    tableParametersPending.orderBy.order,
  ]);

  // For sorting Column Name
  const createPendingImplementation = () => {
    setTempPendingImplementationDataFromApi((prevData) => [
      ...prevData.sort(function (valueOne, valueTwo) {
        if (
          valueOne[tableParametersPending.orderBy.column] <
          valueTwo[tableParametersPending.orderBy.column]
        )
          return tableParametersPending.orderBy.order === "asc" ? -1 : 1;
        if (
          valueOne[tableParametersPending.orderBy.column] >
          valueTwo[tableParametersPending.orderBy.column]
        )
          return tableParametersPending.orderBy.order === "asc" ? 1 : -1;
        return 0;
      }),
    ]);
  };

  const handleSort = (e: any) => {
    setTableParametersPending((prevParameters) => {
      const newOrderBy = { ...prevParameters.orderBy };
      if (e.target.id !== newOrderBy.column) {
        newOrderBy.order = "asc";
        newOrderBy.column = e.target.id;
      } else if (prevParameters.orderBy.order === "desc") {
        newOrderBy.order = "asc";
        newOrderBy.column = "";
      } else {
        newOrderBy.order = "desc";
      }
      return { ...prevParameters, orderBy: { ...newOrderBy } };
    });
  };

  const getSortingIcon = (columnId: string) => {
    if (tableParametersPending.orderBy.column === columnId) {
      return tableParametersPending.orderBy.order === "desc" ? (
        <SortingDescending />
      ) : (
        <SortingAscending />
      );
    }
    return <Sorting />;
  };

  const tableColumnsForPending = [
    createColumns(
      1,
      "REQ ID",
      getSortingIcon(pendingImplemetationColumnMapping.requestId),
      pendingImplemetationColumnMapping.requestId,
      tableParametersPending.orderBy.column ===
      pendingImplemetationColumnMapping.requestId,
      (e) => handleSort(e),
      { width: "75px" }
    ),
    createColumns(
      2,
      "Requestor Name",
      getSortingIcon(pendingImplemetationColumnMapping.requestorName),
      pendingImplemetationColumnMapping.requestorName,
      tableParametersPending.orderBy.column ===
      pendingImplemetationColumnMapping.requestorName,
      (e) => handleSort(e),
      { width: "130px" }
    ),
    createColumns(
      3,
      "Request Type",
      getSortingIcon(pendingImplemetationColumnMapping.requestType),
      pendingImplemetationColumnMapping.requestType,
      tableParametersPending.orderBy.column ===
      pendingImplemetationColumnMapping.requestType,
      (e) => handleSort(e),
      { width: "115px" }
    ),
    createColumns(
      4,
      "Request Name",
      getSortingIcon(pendingImplemetationColumnMapping.requestName),
      pendingImplemetationColumnMapping.requestName,
      tableParametersPending.orderBy.column ===
      pendingImplemetationColumnMapping.requestName,
      (e) => handleSort(e),
      { width: "115px" }
    ),
    // createColumns(
    //   5,
    //   "Stage",
    //   getSortingIcon(pendingImplemetationColumnMapping.requestStage),
    //   pendingImplemetationColumnMapping.requestStage,
    //   tableParametersPending.orderBy.column ===
    //     pendingImplemetationColumnMapping.requestStage,
    //   (e) => handleSort(e),
    //   { width: "115px" }
    // ),

    createColumns(
      5,
      "Requested On",
      getSortingIcon(pendingImplemetationColumnMapping.requestedOn),
      pendingImplemetationColumnMapping.requestedOn,
      tableParametersPending.orderBy.column ===
      pendingImplemetationColumnMapping.requestedOn,
      (e) => handleSort(e),
      { width: "115px" }
    ),
    createColumns(
      6,
      "Assigned On",
      getSortingIcon(pendingImplemetationColumnMapping.assignedOn),
      pendingImplemetationColumnMapping.assignedOn,
      tableParametersPending.orderBy.column ===
      pendingImplemetationColumnMapping.assignedOn,
      (e) => handleSort(e),
      { width: "60px" }
    ),
    createColumns(
      7,
      "Actions",
      null,
      pendingImplemetationColumnMapping.actions,
      false,
      undefined,
      { width: "15px" }
    ),
  ];

  // LISTING TABLE END

  // ACTION MENU POPUP START

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const openMenu = (
    e: any,
    rowId: any,
    stageId: any,
    requestType: any,
    request_status: any,
    linked_delegation: any,
    request_stage: any,
    delegate_to_secondary: any,
    implementer_type_id: any,
    request_purpose: any,
  ) => {
    setMenuData({
      anchorEl: e.target,
      row: {
        request_id: rowId,
        request_stage_id: stageId,
        request_type: requestType,
        request_status: request_status,
        linked_delegation: linked_delegation,
        request_stage: request_stage,
        delegate_to_secondary: delegate_to_secondary,
        implementer_type_id: implementer_type_id,
        request_purpose
      },
    });
  };

  const handleModalOpen = (actionType: string) => {
    if (menuData) {
      setModalData({ data: { ...menuData.row }, type: actionType });

      if (actionType === "changeImplementor") {
        fetchTypeWiseImplementer(menuData.row.implementer_type_id)
          .then((response: any) => {
            const tempArray: changeData[] = [];
            !isEmpty(response.data?.primary) && tempArray.push({ id: response.data?.primary.id, name: `${response.data?.primary.name} (primary implementer)` })
            !isEmpty(response.data?.secondary) && tempArray.push({ id: response.data?.secondary.id, name: `${response.data?.secondary.name} (secondary implementer)` })
            setchangeImplementorsData(tempArray);
          })
          .catch((error) => {
            enqueueSnackbar("Something went wrong! Please try again", {
              variant: "error",
            });
            console.log(error);
          });
        setModalOpen(true);
      } else if (actionType === "info") {
        fetchImplementerRequestDelegateData({
          implementer_type_id: menuData.row.implementer_type_id,
          request_type: menuData.row.request_type,
          request_id: menuData.row.request_id,
        })
          .then((res: any) => {
            setImplementerRequestDelegateData({
              delegated: { ...res.data.delegated },
            });
            setModalOpen(true);
          })
          .catch((err: any) => {
            enqueueSnackbar("Something went wrong! Please try again", {
              variant: "error",
            });
          });
      }

      setMenuData({
        anchorEl: null,
        row: {
          request_id: null,
          request_stage_id: null,
          request_type: null,
          request_status: null,
          linked_delegation: null,
          request_stage: null,
          delegate_to_secondary: null,
          implementer_type_id: null,
          request_purpose: null
        },
      });
    } else {
      setModalData({ data: null, type: actionType });
    }
  };

  const modalActionHandler = (type: string) => {
    switch (type) {
      case "changeImplementor":
        return (
          <div className={styleClasses.modalActionDiv}>
            <CustomButton
              variant="contained"
              actionType="secondary"
              onClick={handleModalClose}
            >
              Cancel
            </CustomButton>
            <CustomButton
              variant="contained"
              actionType="primary"
              disableMargin
              onClick={handleSave}
            >
              Submit
            </CustomButton>
          </div>
        );

      case "info":
        return (
          <div className={styleClasses.modalActionDiv}>
            <CustomButton
              variant="contained"
              actionType="primary"
              onClick={handleModalClose}
              className={styleClasses.btnOk}

            >
              OK
            </CustomButton>
          </div>
        );

      default:
        return null;
    }
  };

  const idValue = (label: string) => {
    const result = changeImplementorsData.find(
      (item: any) => item.name === label
    );
    return result?.id;
  };

  const handleSave = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const onSubmit = (values: any, submitProps: any) => {
    setIsAPILoading(false);

    const requestBodyImplementor = {
      request_id: modalData.data.request_id,
      delegate_user: idValue(values.change_implementer),
      request_type: modalData.data.request_type,
      comment: values.comment,
    };

    submitUpdateImpelementor(
      postDelegateToSecondaryImplementerEndPoints.postUpdatedImplementor,
      requestBodyImplementor
    )
      .then((success: any) => {
        enqueueSnackbar(
          `The request to change the Implementer for ${modalData.data.request_type} #${modalData.data.request_id} has been updated successfully`,
          { variant: "success" }
        );
        setIsLoadingPending((loadingState) => ({
          ...loadingState,
          tableLoading: true,
        }));
        fetchAllPendingImplementation();
      })
      .catch((error: any) => {
        enqueueSnackbar("Something went wrong! Please try again", {
          variant: "error",
        });
        console.log(error);
      });

    submitProps.setSubmitting(false);
    submitProps.resetForm();
    handleModalClose();
  };

  const modalContentHandler = (type: string) => {
    switch (type) {
      case "changeImplementor":
        return (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            validateOnChange={false}
            validateOnBlur={false}
            innerRef={formRef}
          >
            <Form style={{ width: "500px" }}>
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  <FormikControl
                    control="autocomplete"
                    label="Select implementer"
                    name="change_implementer"
                    options={
                      changeImplementorsData.length > 0 &&
                      changeImplementorsData.map((implementer: any) => ({
                        label: implementer.name,
                        value: implementer.name,
                      }))
                    }
                    required
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={12} style={{ paddingTop: "15px" }}>
                <FormikControl
                  control="textfield"
                  label="Add reason for delegation"
                  name="comment"
                  multiline
                  minRows={6}
                  required
                />
              </Grid>
            </Form>
          </Formik>
        );
      case "info":
        return (
          <div style={{ width: "300px" }}>
            <Grid item xs={8} style={{ paddingTop: "11px", maxWidth: "100%" }}>
              <span className={styleClasses.approverText}>Delegated by: </span>
              <span className={styleClasses.approverTextResults}>
                {implementerRequestDelegateData.delegated.delegated_by}
              </span>
            </Grid>
            <Grid item xs={8} style={{ paddingTop: "11px", maxWidth: "100%" }}>
              <span className={styleClasses.approverText}>Delegated on: </span>
              <span className={styleClasses.approverTextResults}>
                {formatDate(
                  implementerRequestDelegateData.delegated.delegated_on
                )}
              </span>
            </Grid>
            <Grid item xs={8} style={{ paddingTop: "11px", maxWidth: "100%" }}>
              <span className={styleClasses.approverText}>Comments: </span>
              <span className={styleClasses.approverTextResults}>
                {implementerRequestDelegateData.delegated.comment}
              </span>
            </Grid>
          </div>
        );
      default:
        return null;
    }
  };

  const modalHeaderHandler = (type: string) => {
    switch (type) {
      case "changeImplementor":
        return "Change implementer";
      case "info":
        return "Delegation information";
      default:
        return "";
    }
  };
  // ACTION MENU POPUP END

  return {
    open,
    styleClasses,
    tableColumnsForPending,
    pendingImplementationData,
    isLoadingPending,
    filters,
    setFilters,
    tableParametersPending,
    setDateRangeFilterRequestedOn,
    setDateRangeFilterAssignOn,
    setTableParametersPending,
    requestIdFilter,
    requestorNameFilter,
    requestTypeFilter,
    requestNameFilter,
    setCloseFilter,
    closeFilter,
    datepickerProps,
    setDatepickerProps,
    setDateRangeValue,
    handleModalOpen,
    modalData,
    menuData,
    setMenuData,
    modalOpen,
    modalHeaderHandler,
    modalContentHandler,
    modalActionHandler,
    handleModalClose,
    clearValueHandler,
    emptyFilters,
    assignOnBadge,
    requestOnBadge
  };
}

export default usePendingImplementationHook;
