import { createSlice } from '@reduxjs/toolkit'
import { IUpdatedFieldDate } from "./IReviewActions";
import type { PayloadAction } from '@reduxjs/toolkit'


interface IReviewActionReducer {
    upadtedFieldHistory: IUpdatedFieldDate[],
    // viewhistoryflag: boolean,
}

const initialState: IReviewActionReducer = {
    upadtedFieldHistory: [],
    //     viewhistoryflag: false,
}


export const updatedHistorySlice = createSlice({
    name: 'updatedHistory',
    initialState,
    reducers: {
        fieldUpdationHistory: (state, action: PayloadAction<IUpdatedFieldDate[]>) => {
            state.upadtedFieldHistory = action.payload;
            // state muttaion logic
            // state.upadtedFieldHistory.push(dummy);
        },
        // viewHistoryFlag: (state, action: PayloadAction<boolean>) => {
        //     state.viewhistoryflag = action.payload;
        // }

    }
});

export const { fieldUpdationHistory } = updatedHistorySlice.actions;
export default updatedHistorySlice.reducer;