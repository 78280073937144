import { useEffect, useRef, useState, useCallback } from 'react';
import {
  ArrayHelpers,
  FieldArray,
  Formik,
  FormikErrors,
  FormikProps,
  FormikValues,
  useFormikContext,
} from 'formik';
import Grid from '@mui/material/Grid';
import { Add, HelpRounded } from '@mui/icons-material';
import { Avatar, useEventCallback } from '@mui/material';
import * as Yup from 'yup';
import isDate from 'date-fns/isDate';
import { useSnackbar } from 'notistack';
import format from 'date-fns/format/index';
import CustomButton from '../../../button';
import FormikControl from '../../../formik-conrtols/FormikControl';
import FieldLabel from '../../../field-label';
import CustomModal from '../../../custom-modal';
import { modalStyles, parStyles, userDefinitionStyles } from '../../useStyles';
import { RoleDefinitionType } from './roleDefinition';
import { getAllDomains } from '../../../../api-services/delegation-module';
import { ReactComponent as DuplicateIcon } from '../../../../assets/icons/DuplicateAction.svg';
import { ReactComponent as DeleteAction } from '../../../../assets/icons/DeleteAction.svg';
import ReviewActions from '../../../review-actions';
import { Chip } from '@mui/material';
import { parFormItself, userDetails } from '../tooltip';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../appStore/store';
import HistoryTable from '../../../../modules/delegation/comments/historyTable';
import CommentSubmission from '../../../../modules/delegation/comments/commentSubmission';
import { emptySchemaCheckForUserDetails } from '../formikContainerUtils';
import { postDelegationFieldHistoryData } from '../../../../api-services/review-delegation';
import { debounce, isEqual } from 'lodash';
import { routeCheckDelegation, routeCheckForAdminSummary, routeCheckForApprovalSummary, routeCheckForImplementationSummary, routeCheckForPendingApproval } from './routingConditional';
import { tmpdir } from 'os';
import HelperTooltip from '../../../helper-tooltip';

const tooltiMsg = 'Kindly add user names, account names, and choose specific domain. You have already defined the roles names and their respective scope details in earlier step.';

type UserDefinitionType = {
  id: string | null,
  user_first_name: string,
  user_last_name: string,
  role_name?: string,
  role_id?: string,
  email: string,
  domain: string,
  admin_account_name: string,
  par_form_fill_access_to_user: boolean,
  is_active: boolean,
  is_deleted: boolean,
  is_implemented?: boolean,
  is_okta_account_exists?: boolean,

};

const emptyUserDefinition = {
  id: null,
  role_name: '',
  user_first_name: '',
  user_last_name: '',
  email: '',
  domain: '',
  admin_account_name: '',
  par_form_fill_access_to_user: false,
  is_active: true,
  is_deleted: false,
};

function UserDetails({ isDisabled }: { isDisabled: boolean }) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = userDefinitionStyles();
  const modalClasses = modalStyles();
  const [userDetailsData, setUserDetailsDats] = useState({
    firstName: '',
    lastname: '',
    email: '',
    domain: ''
  })
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [totalSlots, setTotalSlots] = useState<number>(0);
  const [domainOptions, setDomainOptions] = useState([]);
  const [isSlotLimitReachedFlag, setIsSlotLimitReachedFlag] = useState(false);
  const [userModalValue, setUserModalValue] = useState<UserDefinitionType>({
    ...emptyUserDefinition,
  });
  const formikCtx: any = useFormikContext();
  const formRef = useRef<FormikProps<FormikValues>>(null);
  const [modalAction, setModalAction] = useState<{ index: number, actionType: string }>({
    index: -1,
    actionType: '',
  });
  const { fieldHistoryDelegationCommentsData } = useSelector((state: RootState) => state);
  const { submitDelegationCommentData } = useSelector((state: RootState) => state);
  const styleClasses = parStyles();
  const remediated_by = formikCtx.values.remediation_by
  const remediationCount = formikCtx.values.remediation_count;

  const checkForEditReviewFlow = () => {
    return routeCheckDelegation() || routeCheckForAdminSummary() || routeCheckForImplementationSummary() || routeCheckForApprovalSummary()
  }

  const userAccountName = (firstName: string, lastName: string, domain: string) => {

    const fullName = `${firstName}.${lastName}-s`
    let firstNameData = firstName
    if (fullName.length >= 20) {
      const lengthDiff = fullName.length - 20;
      firstNameData = firstNameData.substring(0, firstName.length - lengthDiff);
    }
    const temp = `${firstNameData}.${lastName}-s@${domain}`
    return temp;
  }

  useEffect(() => {
    let totalCount = 0;
    if (formikCtx.values.del_role_name_list && formikCtx.values.del_role_name_list.length) {
      formikCtx.values.del_role_name_list.forEach((roleObj: RoleDefinitionType) => {
        totalCount += parseInt(roleObj.number_of_slots, 10);
      });
      setTotalSlots(totalCount);
    }
  }, [formikCtx.values.del_role_name_list]);

  useEffect(() => {
    if (formikCtx.errors.del_user_detail_list) {
      enqueueSnackbar(formikCtx.errors.del_user_detail_list, { variant: 'error' });
    }
  }, [formikCtx.errors.del_user_detail_list]);

  useEffect(() => {
    getAllDomains().then((res) => {
      setDomainOptions(res.data.map((domain: { id: number, value: string }) => ({
        label: domain.value,
        value: domain.value,
      })));
    }).catch((err) => {
      console.error(err);
    });
  }, []);

  const stringAvatar = (name: string) => ({
    sx: {
      bgcolor: '#00B5B1',
      fontSize: '13px',
      fontFamily: 'Poppins-Regular',
    },
    children: `${name && name.split(' ').length > 1
      // eslint-disable-next-line no-unsafe-optional-chaining
      ? name?.split(' ')[0][0] + name?.split(' ')[1][0] : name[0]}`,
  });

  const handleClose = () => {
    setModalOpen(false);
    setUserModalValue(emptyUserDefinition);
    // setIsDuplicate(false)
    setIsSlotLimitReachedFlag(false);
  };

  // useEffect(() => {
  // }, [userDetailsData]);

  const handleSave = () => {
    const currentValues = formRef.current?.values
    if (formikCtx.values.remediation_count > 0) {
      // @ts-ignore
      const changedValues = Object.keys(currentValues !== undefined && currentValues).filter(key => formRef.current?.values[key] !== userModalValue[key])
      let finalChangedData: any = [];
      changedValues.length > 0 && changedValues.map((item) => {
        finalChangedData.push(
          {
            update_type: emptySchemaCheckForUserDetails.get(item),
            section: 'User_details',
            sub_section: item,
            // @ts-ignore
            value: userModalValue[item],
            role_name_section: '',
            user_details_section: `${userModalValue.user_first_name + ' ' + userModalValue.user_last_name}`
          }
        )
      })

      const historyPostBody = {
        request_id: formikCtx.values.request_id,
        is_submitted: false,
        data: finalChangedData
      }

      if (finalChangedData.length > 0) {
        postDelegationFieldHistoryData(historyPostBody).then((response) => {
          console.log(response)
        }).catch(error => error);
      }

    }
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleViewUser = (userObject: UserDefinitionType, index: number) => {
    setUserModalValue(userObject);
    setModalAction({
      actionType: 'view',
      index,
    });
    setModalOpen(true);
  };


  const isSlotLimitReached = (selection: { value: string, label: string, slots: string }) => {
    const usersAgainstRole = formikCtx.values.del_user_detail_list.filter((obj: any) => (isEqual(obj.role_name, selection.value) && obj.is_active)).length
    // const filteredUsers = formikCtx.values.del_role_name_list
    // eslint-disable-next-line camelcase
    // .filter((user_obj: UserDefinitionType) => user_obj.role_name === selection.value);
    // const limitFlag = filteredUsers.length === parseInt(selection.slots, 10);
    // setIsSlotLimitReachedFlag(limitFlag);

    return usersAgainstRole == selection.slots;
  };

  const slotLimitReachedForDuplicate = (roleName: any) => {
    const usersAgainstRole = formikCtx.values.del_user_detail_list.filter((obj: any) => (obj.role_name === roleName) && obj.is_active).length
    const slotsAgainstRole = formikCtx.values.del_role_name_list
      .filter((role: RoleDefinitionType) => {
        return role.is_active;
      }).map((role: RoleDefinitionType) => ({
        label: role.role_name,
        value: role.role_name,
        slots: role.number_of_slots,
      })).filter((obj: any) => obj.value === roleName)
    return usersAgainstRole == slotsAgainstRole[0].slots
  }




  useEffect(() => {
    userAccountName(userDetailsData.firstName, userDetailsData.lastname, userDetailsData.domain)
  }, [userDetailsData.domain]);

  const duplicatesCheckfunction = (values: any) => {
    const temp = formikCtx.values.del_user_detail_list.filter((item: any) => item.is_active !== false)

    let booleanCheck = false;
    temp.length > 0 && temp.map((item: any) => {
      if (item.domain === values.domain && item.role_name === values.role_name && item.email === values.email) {
        booleanCheck = true;
        return
      }
    })
    return booleanCheck;
  }

  const modalContentHandler = (arrayHelpers: ArrayHelpers, actionType: string) => {
    switch (actionType) {
      case 'view':
      case 'edit':
      case 'add':
        return (
          <Formik
            validationSchema={Yup.object({
              role_name: Yup.string().required('Please select role name'),
              user_first_name: Yup.string().required('Please provide first name'),
              user_last_name: Yup.string().required('Please provide last name'),
              email: Yup.string().required('Please provide email'),
              domain: Yup.string().required('Please select domain'),
              admin_account_name: Yup.string().required('Please provide admin account name'),
            })}
            onSubmit={(values: FormikValues) => {
              // console.log('index', modalAction.index);
              // if (duplicatesCheckfunction(values)) {
              //   enqueueSnackbar(`Please check for Role name and Domain both can't be duplicated for the same user`, { variant: 'warning' });
              //   return;
              // } else
              if (isSlotLimitReachedFlag) {
                enqueueSnackbar('Slot limit for this role has exceeded. Choose other role', { variant: 'warning' });
                return;
              }
              // else if (isDuplicate && !userDuplicationCheck(values.role_name)) {
              //   arrayHelpers.push({ ...values });
              // }
              // else 
              if (modalAction.index >= 0) {
                arrayHelpers.replace(modalAction.index, values);
              } else {
                arrayHelpers.push({ ...values });
              }
              handleClose();
              setIsDuplicate(false);
            }}
            validateOnChange={false}
            initialValues={userModalValue}
            innerRef={formRef}
          >
            {({
              values,
              setFieldValue,
              setFieldError,
              validateField,
              errors,
            }) => (
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormikControl
                      control="textfield"
                      label="First name"
                      name="user_first_name"
                      disabled={isDisabled}
                      // onChange={(e: any) => {
                      //   temp(e, setFieldValue)
                      //   // debouncedSearch(e, setFieldValue)
                      //   // debounce(() => {
                      //   //   setFieldValue('user_first_name', e.target.value)
                      //   //   // setUserDetailsDats((prevState) => ({
                      //   //   //   ...prevState,
                      //   //   //   firstName: e.target.value
                      //   //   // }))
                      //   //   console.log(values.user_first_name);
                      //   // }, 500)

                      // }}
                      onChange={(e: any) => {
                        setFieldValue('user_first_name', e.target.value);
                        if (userAccountName(e.target.value, values.user_last_name, values.domain)) {

                          setFieldValue('admin_account_name', userAccountName(e.target.value, values.user_last_name, e.target.value))
                        }
                      }}
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikControl
                      control="textfield"
                      label="Last name"
                      name="user_last_name"
                      disabled={isDisabled}
                      onChange={(e: any) => {
                        setFieldValue('user_last_name', e.target.value);
                        if (userAccountName(values.user_first_name, values.user_last_name, values.domain)) {

                          setFieldValue('admin_account_name', userAccountName(e.target.value, values.user_last_name, e.target.value))
                        }
                      }}
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikControl
                      control="textfield"
                      label="Email"
                      name="email"
                      disabled={isDisabled}
                      // onChange={(e: any) => {
                      //   temp2(e, setFieldValue)
                      // }}
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikControl
                      disabled={isDisabled}
                      control="autocomplete"
                      label="Role name"
                      name="role_name"
                      options={formikCtx.values.del_role_name_list
                        .filter((role: RoleDefinitionType) => {
                          return role.is_active;
                        }).map((role: RoleDefinitionType) => ({
                          label: role.role_name,
                          value: role.role_name,
                          slots: role.number_of_slots,
                        }))}
                      handleSelectionChange={(selection: {
                        value: string,
                        label: string,
                        slots: string,
                      }) => {
                        setFieldValue('role_name', selection.value);
                        if (isSlotLimitReached(selection)) {
                          setIsSlotLimitReachedFlag(true);
                          setFieldError('role_name', 'Slot limit for this role has exceeded. Choose other role.');
                          return;
                        }
                        if (errors.role_name === 'Slot limit for this role has exceeded. Choose other role.') {
                          validateField('role_name');
                        }
                      }}
                      required
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <FormikControl
                      disabled={isDisabled}
                      control="autocomplete"
                      label="Domain"
                      name="domain"
                      options={domainOptions}
                      // handleSelectionChange={(e: any) => {
                      //   temp3(e, setFieldValue)
                      // }}
                      handleSelectionChange={(selection: {
                        value: string,
                        label: string,
                        slots: string,
                      }) => {
                        setFieldValue('domain', selection.value);
                        if (userAccountName(values.user_first_name, values.user_last_name, values.domain)) {

                          setFieldValue('admin_account_name', userAccountName(values.user_first_name, values.user_last_name, selection.value))
                        }
                        // if (isSlotLimitReached(selection)) {
                        //   setIsSlotLimitReachedFlag(true);
                        //   setFieldError('role_name', 'Slot limit for this role has exceeded. Choose other role.');
                        //   return;
                        // }
                        // if (errors.role_name === 'Slot limit for this role has exceeded. Choose other role.') {
                        //   validateField('role_name');
                        // }
                      }}
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikControl
                      disabled={isDisabled}
                      control="textfield"
                      label="Admin account name"
                      name="admin_account_name"
                      // onChange={(e: any) => {
                      //   // temp4(e, setFieldValue)
                      //   setFieldValue('admin_account_name', userAccountName(values.user_first_name, values.user_last_name, values.domain))
                      // }}
                      required
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <FormikControl
                      disabled={isDisabled}
                      control="checkbox"
                      label="Do you want the PAR form to be filled by the user itself?"
                      name="par_form_fill_access_to_user"
                      className={classes.checkboxLabel}
                    />
                  </Grid>
                  <Grid item xs={1} className={styleClasses.toolTipPosition}>
                    <span className={styleClasses.tooltipSpan}>
                      <HelperTooltip tooltipMsg={parFormItself}>
                        <HelpRounded style={{ color: '#0A2756' }} />
                      </HelperTooltip>
                    </span>
                  </Grid>
                </Grid>
              </div>
            )}
          </Formik>
        );
      case 'delete':
        return (
          <div>
            <div className={modalClasses.modalContentDiv}>
              Are you sure you want to delete this user?
            </div>
            <div className={modalClasses.modalTextDiv}>
              <span className={modalClasses.spanText}>
                {'Note: '}
              </span>
              Deleting a user is a permanent action and cannot be undone.
            </div>
          </div>
        );
      case 'copy':
        return (
          <div>
            <div className={modalClasses.modalContentDiv}>
              Are you sure you want to duplicate this user detail?
            </div>
            <div className={modalClasses.modalTextDiv}>
              <span className={modalClasses.spanText}>
                {'Note: '}
              </span>
              A user can be duplicated in the event that one individual needs
              to be added to multiple roles or to roles in multiple domains.
              If you duplicate a user you will be required to update
              either the role or domain information before you can submit.
            </div>
          </div>
        );
      case 'emptyslot':
        return (
          <div className={modalClasses.modalContentDiv}>
            In order to add a user, please add a role with atleast one slot first.
          </div>
        );
      case 'slotlimitreached':
        return (
          <div className={modalClasses.modalContentDiv}>
            {`This role has a limit of  slots and is currently full.Please remove a Privileged Access User from a slot before adding another.`}
          </div>
        );
      default: return null;
    }
  };

  const modalActionHandler = (arrayHelpers: ArrayHelpers, actionType: string) => {
    switch (actionType) {
      case 'view':
      case 'edit':
      case 'add':
        return (
          <div className={modalClasses.modalActionDiv}>
            <CustomButton
              variant="contained"
              actionType="secondary"
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </CustomButton>
            <CustomButton
              disabled={isDisabled}
              variant="contained"
              actionType="primary"
              disableMargin
              onClick={() => {
                handleSave();
              }}
            >
              Save
            </CustomButton>
          </div>
        );
      case 'delete':
        return (
          <div className={modalClasses.modalActionDiv}>
            <CustomButton
              variant="contained"
              actionType="secondary"
              onClick={() => {
                const userToDelete = formikCtx.values.del_user_detail_list[modalAction.index];
                arrayHelpers.replace(modalAction.index, {
                  ...userToDelete,
                  is_active: false,
                });
                handleClose();
              }}
            >
              Delete
            </CustomButton>
            <CustomButton
              variant="contained"
              actionType="primary"
              disableMargin
              onClick={() => {
                handleClose();
              }}
            >
              Keep
            </CustomButton>
          </div>
        );
      case 'copy':
        return (
          <div className={modalClasses.modalActionDiv}>
            <CustomButton
              variant="contained"
              actionType="secondary"
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </CustomButton>
            <CustomButton
              variant="contained"
              actionType="primary"
              disableMargin
              onClick={() => {
                if (!slotLimitReachedForDuplicate(formikCtx.values.del_user_detail_list[modalAction.index].role_name)) {
                  setModalAction({
                    actionType: 'add',
                    index: modalAction.index,
                  });
                  setModalAction({
                    actionType: 'add',
                    index: modalAction.index,
                  });
                  arrayHelpers.push({
                    ...formikCtx.values.del_user_detail_list[modalAction.index],
                    id: null,
                  });
                } else {
                  enqueueSnackbar('Slot limit for this role is exceeded', { variant: 'warning' })
                  return;
                }
                handleClose()
                setIsDuplicate(true);
              }}
            >
              Yes, Duplicate
            </CustomButton>
          </div>
        );
      case 'emptyslot':
      case 'slotlimitreached':
        return (
          <div className={modalClasses.modalActionDiv}>
            <CustomButton
              variant="contained"
              actionType="primary"
              disableMargin
              onClick={() => {
                handleClose();
              }}
            >
              OK
            </CustomButton>
          </div>
        );
      default:
        return null;
    }
  };

  const getModalHeader = (actionType: string) => {
    switch (actionType) {
      case 'view':
      case 'edit':
      case 'add':
        return 'Add User Details';
      case 'delete':
        return 'Delete User Details';
      case 'copy':
        return 'Duplicate User Detail';
      case 'slotlimitreached':
      case 'emptyslot':
        return 'Warning';
      default:
        return '';
    }
  };

  const getIsUserThirdPartyLabel = ({
    roleName,
    roleId,
  }: {
    roleName: string | undefined,
    roleId: string | undefined,
  }) => {
    const roleObjectFromList = formikCtx.values?.del_role_name_list?.find(
      (roleObj: RoleDefinitionType) => ((roleName && roleObj.role_name === roleName)
        || (roleId && roleObj.id === roleId)),
    );
    return roleObjectFromList?.is_role_for_thirdparty_user ? '3rd Party User' : '';
  };

  const getRoleNameByRoleId = (roleId: string | undefined) => {
    const roleObjectFromList = formikCtx.values?.del_role_name_list?.find(
      (roleObj: RoleDefinitionType) => roleId && roleObj.id === roleId,
    );
    return roleObjectFromList?.role_name || '';
  };

  const userDuplicationCheck = (roleName: any) => {
    const usersAgainstRole = formikCtx.values.del_user_detail_list.filter((obj: any) => (isEqual(obj.role_name, roleName) && obj.is_active)).length
    const slotsForRole = formikCtx.values.del_role_name_list.filter((obj: any) => (isEqual(obj.role_name, roleName) && obj.is_active))
    return usersAgainstRole == slotsForRole[0].number_of_slots
  }


  return (
    <div>
      <Grid container>
        <Grid item xs={5}>
          <FieldLabel Title="User details" tooltipMsg={userDetails} required />
        </Grid>
        <Grid item xs={5}>
        </Grid>
        <Grid item xs={2}>
          {(checkForEditReviewFlow() && remediationCount > 0) ? (
            <div className={styleClasses.mainSectionDiv}>
              <HistoryTable
                parId={formikCtx.values.request_id}
                section="User_details"
                subSection=''
                data={fieldHistoryDelegationCommentsData.length > 0 ? fieldHistoryDelegationCommentsData[7].User_details : null}
              />

              <CommentSubmission
                sectionName='User_details'
                subSection=''
                updateType='Select update'
                remediation_by={remediated_by}
                data={submitDelegationCommentData.length > 0 ? submitDelegationCommentData[7].User_details : null}
              />
            </div>
          ) : routeCheckForPendingApproval() && (
            <div className={styleClasses.mainSectionDiv}>
              <HistoryTable
                parId={formikCtx.values.request_id}
                section="User_details"
                subSection=''
                data={fieldHistoryDelegationCommentsData.length > 0 ? fieldHistoryDelegationCommentsData[7].User_details : null}
              />

              <CommentSubmission
                sectionName='User_details'
                subSection=''
                updateType='Select update'
                remediation_by={remediated_by}
                data={submitDelegationCommentData.length > 0 ? submitDelegationCommentData[7].User_details : null}
              />
            </div>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <FieldArray
          name="del_user_detail_list"
          render={(arrayHelpers) => (
            <>
              {!isDisabled ? (
                <Grid item xs={4} sm={3} md={3} lg={3}>
                  <div
                    className={classes.addUserTile}
                    onClick={() => {
                      let actionType = 'add';
                      if (totalSlots === 0) actionType = 'emptyslot';
                      // else if (formikCtx.values.del_user_detail_list.length === totalSlots) {
                      //   actionType = 'slotlimitreached';
                      // }
                      setModalAction({
                        actionType,
                        index: -1,
                      });
                      setModalOpen(true);
                    }}
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => null}
                  >
                    <div style={{ display: 'flex' }}>
                      <Add style={{ fontSize: '20px', lineHeight: '20px' }} />
                      Add
                    </div>
                  </div>
                </Grid>
              ) : null}
              {formikCtx.values.del_user_detail_list
                && formikCtx.values.del_user_detail_list.length
                ? formikCtx.values.del_user_detail_list
                  .map((delUserObj: UserDefinitionType, index: number) => (
                    delUserObj.is_active ? (
                      <Grid item xs={4} sm={3} md={3} lg={3} className={classes.userTileWrapper}>
                        <div>
                          <div
                            className={classes.overlay}
                            onClick={delUserObj.is_okta_account_exists ? () => null : () => {
                              handleViewUser(delUserObj, index);
                            }}
                            role="button"
                            tabIndex={-1}
                            onKeyDown={() => null}
                          >
                            <div className={classes.userNameAvtarWrapper}>
                              <Avatar
                                {...stringAvatar(`${delUserObj?.user_first_name} ${delUserObj?.user_last_name} ` || '')}
                                className={classes.avatar}
                              />
                              <div className={classes.userNameWrapper}>
                                <div className={classes.userName}>
                                  {`${delUserObj?.user_first_name} ${delUserObj?.user_last_name} `}
                                </div>
                                <div className={classes.thirdPartyLabel}>
                                  {`${getIsUserThirdPartyLabel({ roleName: delUserObj.role_name, roleId: delUserObj.role_id })} `}
                                </div>
                              </div>
                            </div>
                            <div className={classes.tileTitle}>
                              Role Name:
                            </div>
                            <div className={classes.tileSubTitle}>
                              {delUserObj.role_name || getRoleNameByRoleId(delUserObj.role_id)}
                            </div>
                            <div className={classes.tileTitle}>
                              Account:
                            </div>
                            <div className={classes.tileSubTitle}>
                              {delUserObj.admin_account_name}
                            </div>
                            <div className={classes.tileTitle}>
                              Domain:
                            </div>
                            <div className={classes.tileSubTitle}>
                              {delUserObj.domain}
                            </div>
                            {!delUserObj?.is_okta_account_exists &&
                              <div className={classes.additionPendingDiv}>
                                <span style={{ fontSize: '12px', fontFamily: 'NotoSerif-Regular', color: '#0A2756' }}>Pending Creation</span>
                              </div>}
                          </div>
                        </div>
                        {!isDisabled && (
                          <div className={classes.userTileActionStrip}>
                            <div className={classes.userActionStripAbsolute}>
                              <span className={classes.iconWrapper}>
                                <DuplicateIcon onClick={() => {
                                  if (userDuplicationCheck(delUserObj.role_name)) {
                                    enqueueSnackbar('Slot limit for this role has exceeded. Choose other role', { variant: 'error' });
                                    return;
                                  }
                                  setModalAction({
                                    actionType: 'copy',
                                    index,
                                  });
                                  setModalOpen(true);
                                }}
                                />
                              </span>
                              <span className={classes.iconWrapper}>
                                <DeleteAction
                                  onClick={() => {
                                    setModalAction({
                                      actionType: 'delete',
                                      index,
                                    });
                                    setModalOpen(true);
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                        )}
                      </Grid>
                    ) : null
                  )) : null}
              <CustomModal
                modalHeader={getModalHeader(modalAction.actionType)}
                open={modalOpen}
                onClose={handleClose}
                modalAction={modalActionHandler(arrayHelpers, modalAction.actionType)}
                modalContent={modalContentHandler(arrayHelpers, modalAction.actionType)}
              />
            </>
          )}
        />
      </Grid>
    </div>
  );
}
export default UserDetails;
