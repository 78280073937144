/* eslint-disable react/require-default-props */
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { RadioGroupProps } from '@mui/material';
import useStyles from './useStyles';

interface IStyledRadioGroupsProps extends RadioGroupProps {
  radioGroupHeader?: string,
  radioGrouplabels: { label: string, value: string }[],
  disabled?: boolean,
  errorMsg?: string,
  isInline?: boolean,
  checked?: boolean,
}
function RadioButton({
  onChange,
  name,
  radioGroupHeader,
  radioGrouplabels,
  disabled,
  errorMsg,
  value,
  isInline,
  checked,
}: IStyledRadioGroupsProps) {
  const classes = useStyles({ isInline });
  return (
    <FormControl>
      {radioGroupHeader && (<FormLabel id="demo-controlled-radio-buttons-group" data-testid="form-label">{radioGroupHeader}</FormLabel>)}
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name={name}
        onChange={onChange}
        data-testid="radio-button-group"
        classes={{ root: classes.radioGroupRoot }}
        value={value}
        key={name}
      >
        {radioGrouplabels.map((radioGroupLabel, index) => (
          <div style={{ display: 'flex' }} key={`d_${radioGroupLabel.label}_${index * 1}`}>
            <FormControlLabel
              key={`fcl_${radioGroupLabel.label}_${index * 1}`}
              value={radioGroupLabel.value}
              control={
                (
                  <Radio
                    disableRipple
                    classes={{
                      root: classes.radioRoot,
                      checked: classes.checked,
                      disabled: classes.disabled,
                    }}
                    disabled={disabled}
                    inputProps={{ title: radioGroupLabel.additionalValue || '' }}
                    key={`r_${radioGroupLabel.label}_${index * 1}`}
                    checked={checked}
                  />
                )
              }
              classes={{ label: classes.radioLabel }}
              label={radioGroupLabel.label}
            />
          </div>
        ))}
      </RadioGroup>
      {errorMsg && (<p className={classes.errorSpan} data-testid="radio-field-error">{errorMsg}</p>)}
    </FormControl>
  );
}

export default RadioButton;
