import * as Yup from 'yup';
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import { RootState } from "../../appStore/store";
import approvalSummaryStyles from "./../administrative-summary-styles";
import {
  Formik,
  Form,
  FormikProps,
  FormikValues,
} from 'formik';
import {
  UserManagementSummaryColumnMapping,
  createColumns,
  emptyFilters,
  IFiltersObj,
  initialTableParameters,
  ITableParameters,
  IUserManagementSummaryData,
  filterDataType,
  dependencyrequestData,
  dependencyrequestForResquest,
} from "./userManagementUtils";
import { fetchAllUserManagementData, postAddNewUser, postDeleteUser, postEditUser } from '../../../api-services/user-management';
import { ReactComponent as Sorting } from "../../../assets/icons/sorting_asc_inactive.svg";
import { ReactComponent as SortingAscending } from "../../../assets/icons/sorting_asc.svg";
import { ReactComponent as SortingDescending } from "../../../assets/icons/sorting_desc.svg";
import { ReactComponent as DeleteAction } from '../../../assets/icons/delete.svg';
import { ReactComponent as PencilIconBlack } from "../../../assets/icons/pencil_b.svg";
import { ReactComponent as UsersIcon } from '../../../assets/icons/users_icon.svg';
import { ReactComponent as ImplementerIcon } from "../../../assets/icons/Implementer_icon.svg";
import { ReactComponent as ReporterIcon } from '../../../assets/icons/Reporter_icon.svg';
import { ReactComponent as RequestorIcon } from "../../../assets/icons/Requestor_icon.svg";
import { ReactComponent as ApproverIcon } from "../../../assets/icons/Approver_icon.svg";
import { ReactComponent as AdministratorIcon } from "../../../assets/icons/Administrator_icon.svg";
import useDaterangePicker from "../../../components/date-range-picker/useDaterangePicker";
import { createRowCells } from "./userManagementUtils";
import StatusBadge from "./StatusBadge";
import { useSnackbar } from "notistack";
import CustomButton from "../../../components/button";
import { Avatar, Box, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import AddUserDetails from './addUserInfo'
import EditUserDetails from './EditUserInfo';
import { tableHeadStyle, tableRowStyle } from '../../../components/review-actions/useStyles';
import { useSelector } from 'react-redux';
import { useOktaAuth } from "@okta/okta-react";

function useUserManagementHook() {
  const roleGroup = [{
    id: 'Administrator', value: 'Administrator'
  }, { id: 'Approver', value: 'Approver' }, { id: 'Implementer', value: 'Implementer' },
  { id: 'Requestor', value: 'Requestor' }, { id: 'Reporter', value: 'Reporter' }, { id: 'User', value: 'User' }];

  const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    role_group: '',
    //is_third_party_user: false,
    company: '',
    account_status: '',
    okta_account: '',
    third_party_company: '',
    not_listed_third_party_company: ''
  };

  const initialValuesEdit = {
    first_name: '',
    last_name: '',
    email: '',
    role_group: null,
    user_id: '',
  }

  const validationSchema = Yup.object({
    first_name: Yup.string().required('Please provide firstname'),
    last_name: Yup.string().required('Please provide lastname'),
    email: Yup.string().required('Please provide email'),
    role_group: Yup.string().required('Please select role group'),
    company: Yup.string().required('Please select company'),
    third_party_company: Yup.string()
      .when('company', {
        is: "3rd Party",
        then: Yup.string().required('Please specify 3rd party company'),
        otherwise: Yup.string(),
      }),
    not_listed_third_party_company: Yup.string()
      .when('third_party_company', {
        is: "Not listed",
        then: Yup.string().required('Please specify not listed company name'),
        otherwise: Yup.string(),
      }),
    account_status: Yup.string().required('Please provide account Status'),
    okta_account: Yup.string().required('Please provide okta Account'),
  });

  const validationForEdittSchema = Yup.object({
    first_name: Yup.string().required('Please provide firstname'),
    last_name: Yup.string().required('Please provide lastname'),
    email: Yup.string().required('Please provide email'),
    //role_group: Yup.string().required('Please select Role Group'),
  });

  const history = useHistory();
  const { loginuserdata } = useSelector((state: RootState) => state.loginData)
  const styleClasses = approvalSummaryStyles();
  const { enqueueSnackbar } = useSnackbar();
  const tableHeadClasses = tableHeadStyle();
  const tableRowClasses = tableRowStyle();

  //FILTER START
  const [isLoading, setIsLoading] = useState({
    tableLoading: false,
    filters: {
      requestorName: false,
      accountStatus: false,
      oktaAccount: false,
      roleGroup: false,
    },
  });
  const [filters, setFilters] = useState<IFiltersObj>(emptyFilters);
  const [oktaAccountFilter, setOktaAccountFilter] = useState<filterDataType[]>([{ id: '', value: '' }]);
  const [requestorNameFilter, setRequestorNameFilters] = useState<filterDataType[]>([{ id: '', value: '' }]);
  const [accountTypeFilter, setAccountTypeFilters] = useState<filterDataType[]>([{ id: '', value: '' }]);
  const [roleGroupFilter, setRoleGroupFilter] = useState<filterDataType[]>([{ id: '', value: '' }]);
  const [closeFilter, setCloseFilter] = useState(false);
  const { datepickerProps, setDatepickerProps, setDateRangeValue } =
    useDaterangePicker({ isSingleDate: true });
  //FILTER END

  //LISTING START
  const [tableParametersPending, setTableParametersPending] =
    useState<ITableParameters>(initialTableParameters);

  const [userManagementSummaryData, setUsermanagementSummaryData] =
    useState<any>({
      results: [],
      pageSize: 10,
    });
  const [userMangementDataFromApi, setUserMangementDataFromApi] = useState([]);
  const [tempUserMangementDataFromApi, setTempUserMangementDataFromApi] = useState<IUserManagementSummaryData[]>([]);
  //LISTING END

  const [menuData, setMenuData] = useState({
    anchorEl: null,
    row: {
      request_id: null,
      stage_id: null,
      request_type: null,
      request_status: null,
      is_request_to_update: null,
    },
  });
  const [modalData, setModalData] = useState<{
    data: any;
    type: string;
  }>({ data: null, type: "" });
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [updateReason, setUpdateReason] = useState("");
  const [noteModalOpen, setNoteModalOpen] = useState(false)
  const [isDeleteId, setIsDeleteId] = useState(null);

  const [noteForEditOpen, setNoteForEditOpen] = useState(0)
  const [noteForDeteteOpen, setNoteForDeteteOpen] = useState(0)
  const [dependencyData, setDependencyData] = useState([])
  const formRef = useRef<FormikProps<FormikValues>>(null);
  const [open, setOpen] = useState(false);
  const [rowValue, setRowValue] = useState<{
    request_Id: string;
    stage_Id: string;
  }>({ request_Id: "", stage_Id: "" });

  const [userModalData, setModalUserData] = useState<{
    data: any;
    key: string;
  }>({ data: null, key: "" });

  const [forAdmin, setForAdmin] = useState<{ role_group: any; user_id: any }>({ role_group: '', user_id: '' })
  const { oktaAuth, authState } = useOktaAuth();

  // all filters api calling
  const fetchFiltersValues = () => {
    setIsLoading((loadingState) => ({
      ...loadingState,
      filters: {
        ...loadingState.filters,
        requestorName: true,
        accountStatus: true,
        oktaAccount: true,
        roleGroup: true,
      },
    }));
  }

  // clear value handler
  const clearValueHandler = () => {
    setIsLoading((loadingState) => ({ ...loadingState, tableLoading: false }));
    setFilters(emptyFilters)
    setTempUserMangementDataFromApi(userMangementDataFromApi)
  }
  // FILTER FUNCTIONS END

  // for sorting
  useEffect(() => {
    tableParametersPending.orderBy.column !== '' ? createUserManagementSummary() : setTempUserMangementDataFromApi(userMangementDataFromApi);
  }, [tableParametersPending.orderBy.column, tableParametersPending.orderBy.order]);

  // For sorting Column Name
  const createUserManagementSummary = () => {

    if (tableParametersPending.orderBy.column == 'role_group') {
      setTempUserMangementDataFromApi((prevData) => [...prevData.sort(function (valueOne: any, valueTwo: any) {
        if (valueOne[tableParametersPending.orderBy.column] < valueTwo[tableParametersPending.orderBy.column])
          return tableParametersPending.orderBy.order === 'asc' ? -1 : 1;
        if (valueOne[tableParametersPending.orderBy.column] > valueTwo[tableParametersPending.orderBy.column])
          return tableParametersPending.orderBy.order === 'desc' ? -1 : 1;
        return 0;
      }
      ),
      ]);
    } else {
      setTempUserMangementDataFromApi((prevData) => [...prevData.sort(function (valueOne: any, valueTwo: any) {
        if (valueOne[tableParametersPending.orderBy.column]?.toLowerCase() < valueTwo[tableParametersPending.orderBy.column]?.toLowerCase())
          return tableParametersPending.orderBy.order === 'asc' ? -1 : 1;
        if (valueOne[tableParametersPending.orderBy.column]?.toLowerCase() > valueTwo[tableParametersPending.orderBy.column].toLowerCase())
          return tableParametersPending.orderBy.order === 'desc' ? -1 : 1;
        return 0;
      }
      ),
      ]);
    }
  };

  const handleSort = (e: any) => {
    setTableParametersPending((prevParameters) => {
      const newOrderBy = { ...prevParameters.orderBy };
      if (e.target.id !== newOrderBy.column) {
        newOrderBy.order = "asc";
        newOrderBy.column = e.target.id;
      } else if (prevParameters.orderBy.order === "desc") {
        newOrderBy.order = "asc";
        newOrderBy.column = "";
      } else {
        newOrderBy.order = "desc";
      }
      return { ...prevParameters, orderBy: { ...newOrderBy } };
    });
  };

  const getSortingIcon = (columnId: string) => {
    if (tableParametersPending.orderBy.column === columnId) {
      return tableParametersPending.orderBy.order === "desc" ? (
        <SortingDescending />
      ) : (
        <SortingAscending />
      );
    }
    return <Sorting />;
  };

  const stringAvatar = (name: any) => ({
    sx: {
      bgcolor: '#FFE7DF',
      color: '#0A2756',
      fontSize: "12px",
      fontFamily: 'NotoSerif-Regular',
    },
    children: `${name && name.split(" ").length > 1
      ? // eslint-disable-next-line no-unsafe-optional-chaining
      name?.split(" ")[0][0] + name?.split(" ")[1][0]
      : name[0]
      }`,
  });

  const formateWord = (item: any) => {
    const capitalized = item.toLowerCase();
    let newText = capitalized.charAt(0).toUpperCase() + capitalized.slice(1);
    return newText;
  }

  // Listing api calling
  useEffect(() => {
    const rowsArray = tempUserMangementDataFromApi.map((row: any) => {
      const cellArray = Object.entries(row)
        .map(([key, value]) => {
          let cellData: any = null;
          switch (key) {
            case "name":
              cellData = createRowCells(
                UserManagementSummaryColumnMapping.requestorName,
                <Box className={styleClasses.avatarName}>
                  <Avatar {...stringAvatar(value)} />
                  <span className={styleClasses.avtarNameValue}>{row.name}</span>
                </Box>,
                `${row.request_type?.charAt(0) || ""}${value}`,
                1,
              );
              cellData.value = value;
              break;
            case "email":
              cellData = createRowCells(
                UserManagementSummaryColumnMapping.email,
                `${value || ""}`,
                `${row.request_id}-${value || ""}`,
                2
              );
              break;
            case "account_status":
              cellData = createRowCells(
                UserManagementSummaryColumnMapping.accountStatus,
                <StatusBadge
                  status={formateWord(value)}
                />,
                `${value || ""}`,
                3
              );
              break;
            case "okta_account":
              cellData = createRowCells(
                UserManagementSummaryColumnMapping.oktaAccount,
                `${value || ""}`,
                `${row.request_id}-${value}`,
                4
              );
              break;
            case "role_group":
              cellData = createRowCells(
                UserManagementSummaryColumnMapping.roleGroup,
                <Box>
                  { // @ts-ignore */
                    value.includes("Administrator") && <AdministratorIcon className={styleClasses.BadgesStyle} />}
                  { // @ts-ignore */
                    value.includes("User") && <UsersIcon className={styleClasses.BadgesStyle} />}
                  {// @ts-ignore */
                    value.includes("Requestor") && <RequestorIcon className={styleClasses.BadgesStyle} />}
                  {// @ts-ignore */
                    value.includes("Implementer") && <ImplementerIcon className={styleClasses.BadgesStyle} />}
                  {// @ts-ignore */
                    value.includes("Reporter") && <ReporterIcon className={styleClasses.BadgesStyle} />}
                  {// @ts-ignore */
                    value.includes("Approver") && <ApproverIcon className={styleClasses.BadgesStyle} />}
                </Box>
                ,
                `${row.request_id}-${value || ""}`,
                6
              );
              break;
            default:
              break;
          }
          return cellData;
        })
        .filter((obj: any) => obj);
      cellArray.push(
        createRowCells(
          UserManagementSummaryColumnMapping.actions,
          <Box className={styleClasses.mainIcon}>
            <Box className={styleClasses.mainIcon}>
              <PencilIconBlack
                onClick={() => openEditUserModal(row)}
              />
            </Box>
            <Box className={styleClasses.deleteIcon}>
              <DeleteAction
                onClick={() => handleRemoveModal(row)}
                className={`${styleClasses.moreIcon} ${!!menuData.anchorEl && styleClasses.moreIconActive
                  }`}
              />
            </Box>
          </Box>
          ,
          `more-actions-${row.id}`,
          9,
          (e) => openMenu(
            e,
            row.request_id,
            row.stage_id,
            row.request_type,
            row.request_status,
            row.is_request_to_update),
          { textAlign: "center" }
        ),
      );
      return cellArray;
    });
    setUsermanagementSummaryData((prevState: any) => ({ ...prevState, results: [...rowsArray] }))
  }, [tempUserMangementDataFromApi])


  // set UserManagement Data onload once
  const fetchAllUserManagement = async () => {
    // @ts-ignore
    await fetchAllUserManagementData().then((res: any) => {
      setUserMangementDataFromApi(res.data.sort((a: any, b: any) => b.created_at.localeCompare(a.created_at)));
      setTempUserMangementDataFromApi(res.data.sort((a: any, b: any) => b.created_at.localeCompare(a.created_at)));

      // @ts-ignore
      const requestorName = [...new Set(res.data?.map(({ name }) => name))].map(e => res.data?.find(({ name }) => name == e));
      // @ts-ignore
      setRequestorNameFilters(requestorName?.map((requestTypeObj: { name: string }) => ({
        id: requestTypeObj.name,
        value: requestTypeObj.name === "" ? "(Blanks)" : requestTypeObj.name,
      })))

      // @ts-ignore
      const setAccountTypeFilter = [...new Set(res.data?.map(({ account_status }) => account_status))].map(e => res.data?.find(({ account_status }) => account_status == e));
      // @ts-ignore
      setAccountTypeFilters(setAccountTypeFilter?.map((accountTypeObj: { account_status: string }) => ({
        id: formateWord(accountTypeObj.account_status),
        value: formateWord(accountTypeObj.account_status) === "" ? "(Blanks)" : formateWord(accountTypeObj.account_status),
      })))

      // @ts-ignore
      const setOktaAccount = [...new Set(res.data?.map(({ okta_account }) => okta_account))].map(e => res.data?.find(({ okta_account }) => okta_account == e));
      // @ts-ignore */
      setOktaAccountFilter(setOktaAccount?.map((oktaAccountObj: { okta_account: string }) => ({
        id: oktaAccountObj.okta_account,
        value: oktaAccountObj.okta_account,
      })))

      setRoleGroupFilter(roleGroup)
      setIsLoading((loadingState) => ({
        ...loadingState,
        tableLoading: false,
        filters: {
          requestorName: false,
          accountStatus: false,
          oktaAccount: false,
          roleGroup: false,
        },
      }));
    }).catch((error: string) => {
      setIsLoading((loadingState) => ({
        ...loadingState, tableLoading: false, filters: {
          requestorName: false,
          accountStatus: false,
          oktaAccount: false,
          roleGroup: false,
        },
      }));
    })
  }

  useEffect(() => {
    fetchAllUserManagement();
    fetchFiltersValues();
    setIsLoading((loadingState) => ({ ...loadingState, tableLoading: true }));
  }, []);

  const tableColumnsForPending = [
    createColumns(
      1,
      "Name",
      getSortingIcon(UserManagementSummaryColumnMapping.requestorName),
      UserManagementSummaryColumnMapping.requestorName,
      tableParametersPending.orderBy.column ===
      UserManagementSummaryColumnMapping.requestorName,
      (e) => handleSort(e),
      { width: "110px" }
    ),
    createColumns(
      2,
      "Email Address",
      getSortingIcon(UserManagementSummaryColumnMapping.email),
      UserManagementSummaryColumnMapping.email,
      tableParametersPending.orderBy.column ===
      UserManagementSummaryColumnMapping.email,
      (e) => handleSort(e),
      { width: "115px" }
    ),
    createColumns(
      3,
      "Account Status",
      getSortingIcon(UserManagementSummaryColumnMapping.accountStatus),
      UserManagementSummaryColumnMapping.accountStatus,
      tableParametersPending.orderBy.column ===
      UserManagementSummaryColumnMapping.accountStatus,
      (e) => handleSort(e),
      { width: "150px" }
    ),
    createColumns(
      4,
      "Okta Account",
      getSortingIcon(UserManagementSummaryColumnMapping.oktaAccount),
      UserManagementSummaryColumnMapping.oktaAccount,
      tableParametersPending.orderBy.column ===
      UserManagementSummaryColumnMapping.oktaAccount,
      (e) => handleSort(e),
      { width: "115px" }
    ),
    createColumns(
      5,
      "Role Group",
      '',
      UserManagementSummaryColumnMapping.roleGroup,
      tableParametersPending.orderBy.column ===
      UserManagementSummaryColumnMapping.roleGroup,
      (e) => handleSort(e),
      { width: "120px" }
    ),
    createColumns(
      6,
      "Actions",
      null,
      UserManagementSummaryColumnMapping.actions,
      false,
      undefined,
      { width: "20px" }
    ),
  ];
  // LISTING FUNCATIONS END

  // filtering functionality
  const fetchFilteredDataWithDebounce = (filtersObject: any) => {
    if (filtersObject?.requestorName?.length > 0) {
      setTempUserMangementDataFromApi([...userMangementDataFromApi.filter((obj: IUserManagementSummaryData) => (filtersObject?.requestorName.find((item: any) => item === obj.name))
      )])
    }
    else if (filtersObject?.accountStatus?.length > 0) {
      let accountStatusData = userMangementDataFromApi.filter((obj: IUserManagementSummaryData) => (filtersObject?.accountStatus.find((item: string) => formateWord(item) === formateWord(obj.account_status))))
      setTempUserMangementDataFromApi([...accountStatusData]);

      setTableParametersPending((prevParameters: any) => ({
        ...prevParameters,
        page: Math.ceil(accountStatusData.length / userManagementSummaryData.pageSize),
      }));
    }
    else if (filtersObject?.oktaAccount?.length > 0) {
      let oktaAccountStatus = userMangementDataFromApi.filter((obj: IUserManagementSummaryData) => (filtersObject?.oktaAccount.find((item: string) => item === obj.okta_account)))
      setTempUserMangementDataFromApi([...oktaAccountStatus]);
      setTableParametersPending((prevParameters: any) => ({
        ...prevParameters,
        page: Math.ceil(oktaAccountStatus.length / userManagementSummaryData.pageSize),
      }));
    }
    else if (filtersObject?.roleGroup?.length > 0) {
      const newArray: any = [];
      filtersObject?.roleGroup.map((items: any) => {
        for (var i = 0; i < userMangementDataFromApi.length; i++) {
          // @ts-ignore */
          if (userMangementDataFromApi[i].role_group.includes(items)) { newArray.push(userMangementDataFromApi[i]) }
        }
      });
      setTableParametersPending((prevParameters: any) => ({
        ...prevParameters,
        // @ts-ignore */
        page: Math.ceil([...new Set(newArray)].length / userManagementSummaryData.pageSize),
      }));
      // @ts-ignore */
      setTempUserMangementDataFromApi([...new Set(newArray)])
    }
    else {
      setTempUserMangementDataFromApi(userMangementDataFromApi);
    }
  }

  useEffect(() => {
    fetchFilteredDataWithDebounce(filters);
  }, [filters]);

  // ACTION MENU FUNCATIONS START
  useEffect(() => {
    if (!modalOpen) {
      setModalData({ data: null, type: "" });
    }
  }, [modalOpen]);


  // for Edit User Form
  const openEditUserModal = (row: any) => {
    handleModalOpen("EditUser", row)
    setMenuData({
      anchorEl: null,
      row: {
        request_id: null,
        stage_id: null,
        request_type: null,
        request_status: null,
        is_request_to_update: null,
      },
    });
  };

  // for Delete User Popup
  const handleRemoveModal = (row: any) => {
    if (row.okta_account == 'Requested') {
      setUpdateReason('OKtaAccountRequested')
      handleModalOpen("DeleteUser", row);
      setNoteForDeteteOpen(1)
      setIsDeleteId(row.user_id)
    } else {
      setNoteForDeteteOpen(1)
      setIsDeleteId(row.user_id)
      openDeleteUserModal(row)
    }
  };


  // action menu popup
  const openMenu = (e: any,
    rowId: any,
    stageId: any,
    requestType: any,
    request_status: any,
    is_request_to_update: any,
  ) => {
    setMenuData({
      anchorEl: e.target,
      row:
      {
        request_id: rowId,
        stage_id: stageId,
        request_type: requestType,
        request_status: request_status,
        is_request_to_update: is_request_to_update,
      },
    });
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setNoteModalOpen(false);
    setNoteForEditOpen(0);
    setNoteForDeteteOpen(0)
    setUpdateReason('')
  };

  const handleModalOpen = (actionType: string, row: any) => {
    if (row) {
      setModalData({ data: row, type: actionType });
      setMenuData({
        anchorEl: null,
        row: {
          request_id: null,
          stage_id: null,
          request_type: null,
          request_status: null,
          is_request_to_update: null,
        },
      });
    } else {
      setModalData({ data: null, type: actionType });
    }
    setModalOpen(true);

  };
  const modalHeaderHandler = (type: string) => {
    switch (type) {
      case 'EditUser':
        return noteForEditOpen == 1 ? ((updateReason == 'AdminWantaddsomeoneToAdminGroup') || (updateReason == 'AdminwantsomeoneToRemove') || (updateReason == 'AdminItselfWantToRemove')) ? 'Note' : 'Role assignment' : (noteForEditOpen == 2 ? ' Role assignment' : 'Edit User');
      case 'createUser':
        return noteModalOpen ? 'Note' : 'Add User';
      case 'DeleteUser':
        return noteForDeteteOpen == 1 ? 'Note' : 'Role assignment';
      case 'approveDeny':
        return 'Approve or Deny Request';
      case 'viewHistory':
        return `View history(#${menuData.row.request_id})`
      default: return '';
    }
  };


  const onSubmit = (values: any) => {
    setModalUserData({ data: values, type: '' })
    setForAdmin(values)
    if (values.role_group.includes("Administrator")) {
      setNoteModalOpen(true);
      if (loginuserdata.email == values.email) {
        setUpdateReason("AdminItselfWantToaddToAdminGroup")
      } else {
        setUpdateReason("AdminWantaddsomeoneToAdminGroup")
      }
    } else {
      onFinalSubmit(values)
    }
  }

  const onFinalSubmit = (values: any) => {
    const requestBodyUser = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      company: values.company,
      third_party_company: values.third_party_company,
      not_listed_third_party_company: values.not_listed_third_party_company,
      // is_third_party_user: values.is_third_party_user,
      role_group: values.role_group,
      account_status: values.account_status,
      okta_account: values.okta_account,
    };


    postAddNewUser(requestBodyUser).then((response) => {
      setNoteModalOpen(true);
      if (response.message === 'User already exists with another role') {
        setUpdateReason("UserAlreadyExit")
      } else if (response.message === 'User cannot be added as the limit exceeds') {
        { requestBodyUser.role_group === 'Administrator' ? setUpdateReason("Administrator") : setUpdateReason("Reporter") }
      } else {
        if (requestBodyUser.okta_account === 'Requested') {
          setUpdateReason('Requested')
          //setIsLoading((loadingState) => ({ ...loadingState, tableLoading: true }));
        } else {
          setUpdateReason('OKtaAccountExitaddedUser')
          setModalOpen(false);
          setNoteModalOpen(false);
        }
        setIsLoading((loadingState) => ({ ...loadingState, tableLoading: true }));
        fetchAllUserManagement();
      }
    }).catch((error) => {
      enqueueSnackbar('Something went wrong! Please try again', { variant: 'error' });
      console.log(error.message)
    });
  }

  const onEditSubmit = (values: any) => {
    setForAdmin(values)
    let newData: any = userMangementDataFromApi.filter((item: IUserManagementSummaryData) => item.user_id == values.user_id)
    if (newData[0].role_group.filter(item => !values.role_group.includes(item)).includes("Administrator")) {
      setNoteForEditOpen(1)
      if (loginuserdata.email == values.email) {
        setUpdateReason("AdminItselfWantToRemove")
      } else {
        setUpdateReason("AdminwantsomeoneToRemove")
      }
    } else if (values.role_group.filter(item => !newData[0].role_group.includes(item)).includes("Administrator")) {
      setNoteForEditOpen(1)
      setUpdateReason("AdminWantaddsomeoneToAdminGroup")
    } else {
      finalEditSubmit(values)
    }
  }

  const finalEditSubmit = (values: any) => {
    const requestBodyUser = {
      assign_group: values.role_group || forAdmin.role_group,
      user_id: values.user_id || forAdmin.user_id
    };
    postEditUser(requestBodyUser).then((response) => {
      if (response.message === 'more than 5 Administrator can not be added') {
        setUpdateReason("maxAdmincount")
        setNoteForEditOpen(1)
      } else if (response.message === 'more than 100 Reporter can not be added') {
        setUpdateReason('maxCountRepoters')
        setNoteForEditOpen(1)
      } else if (response.message === 'minimum 2 Administrator required') {
        setUpdateReason('minimumAdmin')
        setNoteForEditOpen(1)
      }
      else if (response.message === 'minimum 2 Reporter required') {
        setUpdateReason('minRepoters')
        setNoteForEditOpen(1)
      } else if (response.message === 'Can not remove User role group') {
        setNoteForEditOpen(1);
        setUpdateReason("UserGroupCannotRemove")
      } else if (response.data.length > 0) {
        if (response.data.some(item => item['text'] === 'Present as a Primary Approver')) {
          setNoteForEditOpen(1)
          setUpdateReason('PrimaryApprover')
        } else if (response.data.some(item => item['text'] === 'Present as a Primary Implemeneter')) {
          setNoteForEditOpen(1)
          setUpdateReason('PrimaryImplementer')
        } else {
          setNoteForEditOpen(2)
          setUpdateReason('')
          setDependencyData(response.data && response.data.map((item: dependencyrequestForResquest) => ({
            value: item.requests,
            label: item.role_group
          })))
        }
      }
      else {
        setModalOpen(false);
        setNoteForEditOpen(0);
        enqueueSnackbar('User details saved successfully.', { variant: 'success' });
        if (updateReason == 'AdminItselfWantToRemove') {
          oktaAuth.tokenManager.clear();
          oktaAuth.signOut();
        } else {
          fetchAllUserManagement()
          setIsLoading((loadingState) => ({ ...loadingState, tableLoading: true }));
        }
      }
    }).catch((error) => {
      enqueueSnackbar('Something went wrong! Please try again', { variant: 'error' });
      console.log(error.message)
    });
  }


  const openDeleteUserModal = (values: any) => {
    const requestBody = { user_id: isDeleteId || values.user_id };
    postDeleteUser(requestBody).then((response) => {
      handleModalOpen("DeleteUser", values || modalData);
      if (response.message === 'User Cannot be deleted') {
        setUpdateReason('UserGroupCannotRemove')
        setNoteForDeteteOpen(1)
      } else if (response.message === 'minimum 2 Reporter required') {
        setUpdateReason('minRepoters')
        setNoteForDeteteOpen(1)
      } else if (response.message === 'minimum 2 Administrator required') {
        setUpdateReason('minimumAdmin')
        setNoteForDeteteOpen(1)
      } else {
        if (response.data.length > 0) {
          if (response.data.some(item => item['text'] === 'Present as a Primary Approver')) {
            setNoteForDeteteOpen(1)
            setUpdateReason('PrimaryApprover')
          } else if (response.data.some(item => item['text'] === 'Present as a Primary Implemeneter')) {
            setNoteForDeteteOpen(1)
            setUpdateReason('PrimaryImplementer')
          } else {
            setNoteForDeteteOpen(2)
            setUpdateReason('')
            setDependencyData(response.data && response.data.map((item: dependencyrequestForResquest) => ({
              value: item.requests,
              label: item.role_group
            })))
          }
        } else {
          setModalOpen(false);
          setNoteForDeteteOpen(0)
          setUpdateReason('')
          enqueueSnackbar('User removed successfully', { variant: 'success' });
          fetchAllUserManagement()
          setIsLoading((loadingState) => ({ ...loadingState, tableLoading: true }));
        }
      }
    }).catch((error) => {
      enqueueSnackbar('Something went wrong! Please try again', { variant: 'error' });
      console.log(error.message)
    });

    setMenuData({
      anchorEl: null,
      row: {
        request_id: null,
        stage_id: null,
        request_type: null,
        request_status: null,
        is_request_to_update: null,
      },
    });
  }

  const handleSave = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const modalContentHandler = (type: string) => {
    // console.log("modalData@@@", modalData.data, dependencyData[0])
    switch (type) {
      case "EditUser":
        if (noteForEditOpen == 1) {
          return (
            <div>
              <div className={styleClasses.modalContentDiv}>
                <div className={styleClasses.modalTextDiv} >
                  <div>
                    {(updateReason == 'AdminItselfWantToRemove') ? (
                      <>
                        You are removing yourself from the <b>Administrator </b>role within <br /> GPAG. Are you sure you wish to do this? <br /><br />
                        <b> Please consider very carefully whether this is the<br /> appropriate course before completing this action.</b><br /><br />
                        If you continue, you will automatically be logged out of the <br />GPAG application and will no longer be able to perform <br />administrative activities in this appliciation.
                      </>
                    ) : (updateReason == 'AdminwantsomeoneToRemove') ? (
                      <>
                        You are removing {modalData.data.name} from the <b>Administrator </b>role within<br /> GPAG. Are you sure you wish to do this? <br /><br />
                        <b> Please consider very carefully whether this is the <br />appropriate course before completing this action.</b><br /><br />
                        If you continue, {modalData.data.name} will automatically be logged out of the<br /> GPAG application and will no longer be able to perform <br />administrative activities in this appliciation.
                      </>
                    ) : (updateReason == 'minimumAdmin') ? (
                      <>
                        You are trying to remove {modalData.data.name} from Administrator role. <br />
                        You cannot remove  {modalData.data.name} from the Administrator role as there <br />has to be a secondary Administrator at all times.  <br /><br />
                        • Go to the <b>Administrator Management </b>page   <br />
                        • Find {modalData.data.name} and reassign Administrator role to another <br />individual <br /><br />
                        Once complete, you will be able to remove {modalData.data.name} from the<br /> Administrator role.
                      </>
                    ) : (updateReason == 'PrimaryImplementer') ? (
                      <>
                        You are trying to remove {modalData.data.name} from implementer role. <br /><br />
                        You cannot remove {modalData.data.name} from the implementer role as there has to be a primary implementer at all times.<br /><br />
                        • Go to the <b>Implementer Management </b> page<br />
                        • Find {modalData.data.name} and reassign implementer role to another individual.<br /><br />
                        Once complete, you will be able to remove {modalData.data.name} from the<br /> implementer role.
                      </>
                    ) : (updateReason == 'minRepoters') ? (
                      <>
                        You are trying to remove {modalData.data.name} from Reporter role.<br /><br />
                        You cannot remove {modalData.data.name} from the Reporter role as there has to <br />be a secondary Reporter at all times.<br />
                      </>
                    ) : (updateReason == 'UserGroupCannotRemove') ? (
                      <>
                        This individual cannot be deleted, since it belongs to "user" role group.<br /> Please ask the request owner to do the needful.<br />
                      </>
                    ) : (updateReason == 'maxAdmincount') ? (
                      <>
                        There should be no more than 5 individuals assigned to the Administrator role within GPAG.<br />
                        In order to amend the current assignment of Administrators, use the Role filter to find the current Administrators and amend the assignment accordingly. <br />
                      </>
                    ) : (updateReason == 'PrimaryApprover') ? (
                      <>
                        You are trying to remove {modalData.data.name} from Approver role.<br /><br />
                        You cannot remove {modalData.data.name} from the Approver role as there has<br /> to be a primary Approver at all times.<br /><br />
                        • Go to the <b>Approver Management </b>page <br />
                        • Find {modalData.data.name} and reassign Approver role to another individual.<br /><br />
                        Once complete, you will be able to remove {modalData.data.name} from the<br /> Approver role. <br />
                      </>
                    ) : (updateReason == 'AdminWantaddsomeoneToAdminGroup') ? (
                      <>
                        <>
                          You are adding {modalData.data.name}  to the Administrator role within <br /> GPAG.<br /> <br />Are you sure you wish to do this?<br /><br />
                          <b>Please consider very carefully whether this is the appropriate <br />course before completing this action.</b>
                        </>
                      </>
                    ) : null
                    }
                  </div>
                </div>
              </div>
            </div >
          );
        } else if (noteForEditOpen == 2) {
          return (
            <div>
              <div className={styleClasses.modalContentDiv}>
                You are trying to remove {modalData.data.name} from the  role.<br /> Currently {modalData.data.name} is either requestor or action owner for the <br />following number of  {dependencyData.length > 1 ? "requests" : "request"}: <br />
              </div>
              <Table className={styleClasses.TableForDependency}>
                <TableHead classes={{ root: tableHeadClasses.head }}>
                  <TableRow sx={{ '&:first-of-type(th)': { border: 0 } }}>
                    <TableCell classes={{ root: tableHeadClasses.cell }}>Role Group</TableCell>
                    <TableCell classes={{ root: tableHeadClasses.cell }}>Delegation/PAR request count</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dependencyData && dependencyData.map((item: dependencyrequestData) =>
                    <TableRow>
                      <TableCell className={tableRowClasses.cell}>{item.label} </TableCell>
                      <TableCell className={tableRowClasses.cell}>{item.value}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              {/* {dependencyData.length > 0 && dependencyData[0]} */}
              <div className={styleClasses.modalTextDiv}>
                Before {modalData.data.name} can be removed from the  role, these <br />requests will need to be assigned to an another individual.<br /> <br />
                • Go to the <b>Administrator Summary </b>page <br />
                • If the user belongs to  <b>Requestor</b> role group search for the user
                in the <b>Requestor Name </b>column and reassign <br />
                • If the user belongs to the <b>Approver, Implementer </b>  or <b>Administrator </b>role, search for the user in the <b>Action
                  Owner</b> column and reassign <br /><br />
                Once complete, you will be able to remove {modalData.data.name} from the GPAG application.
              </div>
            </div>
          );
        }
        else {
          return (
            <Formik
              initialValues={initialValuesEdit}
              validationSchema={validationForEdittSchema}
              onSubmit={onEditSubmit}
              validateOnChange={false}
              validateOnBlur={false}
              innerRef={formRef}
            >
              <Form>
                <EditUserDetails data={modalData} />
              </Form>
            </Formik >
          );
        }
      case "createUser":
        if (noteModalOpen) {
          console.log("modalData.data.first_nameCreate", userModalData, updateReason)
          return (
            <div>
              <div className={styleClasses.modalContentDiv}>
                <div className={styleClasses.modalTextDiv} >
                  {updateReason == 'Requested' ? (
                    <>
                      {userModalData.data.first_name} {userModalData.data.last_name} does not currently have an Okta account. <br />A request for creation of this Okta account has been sent to the<br /> Okta support team.<br /> <br />Once the account has been created: <br /><br />• An email will be sent directly to {userModalData.data.first_name} {userModalData.data.last_name} <br /> • The new account will be added into the {userModalData.data.role_group} role <br />within GPAG < br />• A confirmation email will be sent to you to confirm this.
                    </>
                  ) : (updateReason == 'UserAlreadyExit') ? (
                    <>
                      {userModalData.data.first_name} {userModalData.data.last_name} already exists in the application with the <br /> {userModalData.data.role_group} role.<br /><br />
                      {userModalData.data.first_name} {userModalData.data.last_name} can be added to other role groups in the <b>Role <br />Management </b>module, by filtering on <b>Name </b>and then using<br /> the <b>edit</b> icon to select the appropriate  roles.
                    </>
                  ) : (updateReason == 'Administrator') ? (
                    <>
                      There should be no more than 5 individuals assigned to the Administrator role within GPAG.<br />
                      In order to amend the current assignment of Administrators, use the Role filter to find the current Administrators and amend the assignment accordingly. <br />
                    </>
                  ) : (updateReason == 'Reporter') ? (
                    <>
                      There should be no more than 100 individuals assigned to the Reporter role within GPAG.
                      In order to amend the current assignment of Reporters, use the Role filter to find the current Reporters and amend the assignment accordingly.<br />
                    </>
                  ) : (updateReason == 'AdminItselfWantToaddToAdminGroup') ? (
                    <>
                      You are adding {userModalData.data.first_name} {userModalData.data.last_name} to the Administrator role <br />within GPAG.<br /> <br />Are you sure you wish to do this?<br /><br />
                      <b>Please consider very carefully whether this is the appropriate<br /> course before completing this action.</b>
                    </>
                  ) : (updateReason == 'AdminWantaddsomeoneToAdminGroup') ? (
                    <>
                      You are adding {userModalData.data.first_name} {userModalData.data.last_name} to the Administrator role<br /> within GPAG.<br /> <br />Are you sure you wish to do this?<br /><br />
                      <b>Please consider very carefully whether this is the appropriate <br />course before completing this action.</b>
                    </>
                  ) : (
                    <>
                      {userModalData.data.first_name} {userModalData.data.last_name} has been added successfully to the {userModalData.data.role_group} <br />role. and an email will be sent to them with  <br />instructions on how to login via Okta<br />
                    </>
                  )
                  }
                </div>
              </div>
            </div >
          );
        } else {
          return (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              validateOnChange={false}
              validateOnBlur={false}
              innerRef={formRef}
            >
              <Form>
                <AddUserDetails />
              </Form>
            </Formik>
          );
        }
      case "DeleteUser":
        if (noteForDeteteOpen == 1) {
          return (
            <div>
              <div className={styleClasses.modalContentDiv}>
                <div className={styleClasses.modalTextDiv}>
                  {(updateReason == 'minimumAdmin') ? (
                    <>
                      You are trying to remove {modalData.data.name} from Administrator role. <br />
                      You cannot remove  {modalData.data.name} from the Administrator role as there <br />has to be a secondary Administrator at all times.  <br /><br />
                      • Go to the <b>Administrator Management </b>page   <br />
                      • Find {modalData.data.name} and reassign Administrator role to another <br />individual <br /><br />
                      Once complete, you will be able to remove {modalData.data.name} from the<br /> Administrator role.
                    </>
                  ) : (updateReason == 'PrimaryImplementer') ? (
                    <>
                      You are trying to remove {modalData.data.name} from implementer role. <br /><br />
                      You cannot remove {modalData.data.name} from the implementer role as there has <br />to be a primary implementer at all times.<br /><br />
                      • Go to the <b>Implementer Management </b> page<br />
                      • Find {modalData.data.name} and reassign implementer role to another individual.<br /><br />
                      Once complete, you will be able to remove {modalData.data.name} from the <br />implementer role.
                    </>
                  ) : (updateReason == 'minRepoters') ? (
                    <>
                      You are trying to remove {modalData.data.name} from Reporter role.<br /><br />
                      You cannot remove {modalData.data.name} from the Reporter role as there has to be<br /> a secondary Reporter at all times.<br />
                    </>
                  ) : (updateReason == 'UserGroupCannotRemove') ? (
                    <>
                      This individual cannot be deleted, since it belongs to "user" role group.<br /> Please ask the request owner to do the needful.<br />
                    </>
                  ) : (updateReason == 'maxAdmincount') ? (
                    <>
                      There should be no more than 5 individuals assigned to the Administrator role within GPAG.<br />
                      In order to amend the current assignment of Administrators, use the Role filter to find the current Administrators and amend the assignment accordingly. <br />
                    </>
                  ) : (updateReason == 'PrimaryApprover') ? (
                    <>
                      You are trying to remove {modalData.data.name} from Approver role.<br /><br />
                      You cannot remove {modalData.data.name} from the Approver role as there has<br /> to be a primary Approver at all times.<br /><br />
                      • Go to the <b>Approver Management </b>page <br />
                      • Find {modalData.data.name} and reassign Approver role to another individual.<br /><br />
                      Once complete, you will be able to remove {modalData.data.name} from the<br /> Approver role. <br />
                    </>
                  ) : (updateReason == "OKtaAccountRequested") ? (
                    <>
                      You are trying to remove {modalData.data.name}  from the GPAG application.<br /> The Okta account for {modalData.data.name} was created following a request <br /> from the GPAG application. Removal of the  user from GPAG will <br /> trigger a request to the Okta support team to deactivate this <br />Okta account.<br /><br />
                      On deactivation, the account will be disabled and <b>the user will <br />no longer be able to login to GPAG or any other Okta <br />authenticated applications.<br /><br />
                        Are you sure you want to continue? </b>
                    </>
                  ) : (updateReason == 'UserGroupCannotRemove') ? (
                    <>
                      This individual cannot be deleted, since it belongs to "user" role group. Please ask the request owner to do the needful.<br />
                    </>
                  ) : null
                  }
                </div>
              </div>
            </div>
          );
        }
        else if (noteForDeteteOpen == 2) {
          return (
            <div>
              <div className={styleClasses.modalContentDiv}>
                You are trying to remove {modalData.data.name} from the GPAG application. Currently {modalData.data.name} is either requestor or action owner for the following number of {dependencyData.length > 1 ? "request's" : "request"} :
              </div>
              <Table className={styleClasses.TableForDependency}>
                <TableHead classes={{ root: tableHeadClasses.head }}>
                  <TableRow sx={{ '&:first-of-type(th)': { border: 0 } }}>
                    <TableCell classes={{ root: tableHeadClasses.cell }}>Role Group</TableCell>
                    <TableCell classes={{ root: tableHeadClasses.cell }}>Delegation/PAR request count</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dependencyData && dependencyData.map((item: dependencyrequestData) =>
                    <TableRow>
                      <TableCell className={tableRowClasses.cell}>{item.label} </TableCell>
                      <TableCell className={tableRowClasses.cell}>{item.value}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <div className={styleClasses.modalTextDiv}>
                Before {modalData.data.name} can be removed from the GPAG application, these requests will need to be assigned to an another individual.<br /> <br />
                • Go to the <b>Administrator Summary </b>page <br />
                • If the user belongs to  <b>Requestor</b> role group search for the user <br />
                in the <b>Requestor Name </b>column and reassign <br />
                • If the user belongs to the <b>Approver, Implementer </b>  or
                <b>Administrator </b>role, search for the user in the <b>Action
                  Owner</b> column and reassign <br /><br />
                Once complete, you will be able to remove {modalData.data.name} from the GPAG application.
              </div>
            </div>
          );
        }
        else {
          return null;
          // return (
          //   <div className={styleClasses.modalContentDiv}>
          //     {`Are you sure you want to remove ${modalData.data.name}?`}
          //   </div>
          // );
        }
      default:
        return null;
    }
  };


  const modalActionHandler = (type: string) => {
    //console.log("typeinAction@@@", type, noteForEditOpen, updateReason, userModalData)
    switch (type) {
      case "EditUser":
        if (noteForEditOpen !== 0) {
          if ((updateReason == 'AdminItselfWantToRemove') || (updateReason == 'AdminwantsomeoneToRemove') || (updateReason == "AdminWantaddsomeoneToAdminGroup")) {
            return (
              <div className={styleClasses.modalActionDiv}>
                <>
                  <CustomButton
                    variant="contained"
                    actionType="primary"
                    onClick={handleModalClose}
                  >
                    No
                  </CustomButton>
                  <CustomButton
                    variant="contained"
                    actionType="secondary"
                    disableMargin
                    onClick={() => finalEditSubmit(forAdmin)}
                  >
                    Yes
                  </CustomButton>
                </>
              </div>
            )
          }
          else {
            return (
              <div className={styleClasses.modalActionDiv}>
                <Box className={styleClasses.modalActionDivNew}>
                  <CustomButton
                    variant="contained"
                    actionType="primary"
                    disableMargin
                    onClick={handleModalClose}
                  >
                    Ok
                  </CustomButton>
                </Box>
              </div >
            )
          }
        } else {
          return (
            <div className={styleClasses.modalActionDiv}>
              <CustomButton
                variant="contained"
                actionType="secondary"
                onClick={handleModalClose}
              >
                Cancel
              </CustomButton>
              <CustomButton
                variant="contained"
                actionType="primary"
                disableMargin
                onClick={handleSave}
              >
                Save
              </CustomButton>
            </div>
          );
        }
      case "createUser":
        if (noteModalOpen) {
          if ((updateReason == 'AdminItselfWantToaddToAdminGroup') || (updateReason == 'AdminWantaddsomeoneToAdminGroup')) {
            return (
              <div className={styleClasses.modalActionDiv}>
                <>
                  <CustomButton
                    variant="contained"
                    actionType="secondary"
                    onClick={handleModalClose}
                  >
                    No
                  </CustomButton>
                  <CustomButton
                    variant="contained"
                    actionType="primary"
                    disableMargin
                    onClick={() => onFinalSubmit(userModalData.data)}
                  >
                    Yes
                  </CustomButton>
                </>
              </div >
            )
          } else {
            return (
              <div className={styleClasses.modalActionDiv}>
                <Box className={styleClasses.modalActionDivNew}>
                  <CustomButton
                    variant="contained"
                    actionType="primary"
                    disableMargin
                    onClick={handleModalClose}
                  >
                    Ok
                  </CustomButton>
                </Box>
              </div>
            )
          }
        }
        else {
          return (
            <div className={styleClasses.modalActionDiv}>
              <CustomButton
                variant="contained"
                actionType="secondary"
                onClick={handleModalClose}
              >
                Cancel
              </CustomButton>
              <CustomButton
                variant="contained"
                actionType="primary"
                disableMargin
                onClick={handleSave}
              >
                Add
              </CustomButton>
            </div>
          );
        }
      case "DeleteUser":
        if (noteForDeteteOpen !== 0) {
          if (updateReason == "OKtaAccountRequested") {
            return (
              <div className={styleClasses.modalActionDiv}>
                <CustomButton
                  variant="contained"
                  actionType="primary"
                  onClick={handleModalClose}
                >
                  No
                </CustomButton>
                <CustomButton
                  variant="contained"
                  actionType="secondary"
                  disableMargin
                  onClick={openDeleteUserModal}
                >
                  Yes
                </CustomButton>
              </div>
            );
          } else {
            return (
              <div className={styleClasses.modalActionDiv}>
                <Box className={styleClasses.modalActionDivNew}>
                  <CustomButton
                    variant="contained"
                    actionType="secondary"
                    disableMargin
                    onClick={handleModalClose}
                  >
                    Ok
                  </CustomButton>
                </Box>
              </div>
            );
          }
        } else {
          return null
        }
      // else {
      //   return (
      //     <div className={styleClasses.modalActionDiv}>
      //       <CustomButton
      //         variant="contained"
      //         actionType="secondary"
      //         onClick={openDeleteUserModal}
      //       >
      //         Remove
      //       </CustomButton>
      //       <CustomButton
      //         variant="contained"
      //         actionType="primary"
      //         disableMargin
      //         onClick={handleModalClose}
      //       >
      //         Keep
      //       </CustomButton>
      //     </div>
      //   );
      // }
      default:
        return null;
    }
  }

  // ACTION MENU FUNCATIONS END
  return {
    open,
    styleClasses,
    tableColumnsForPending,
    userManagementSummaryData,
    isLoading,
    filters,
    setFilters,
    tableParametersPending,
    setTableParametersPending,
    oktaAccountFilter,
    requestorNameFilter,
    accountTypeFilter,
    roleGroupFilter,
    setCloseFilter,
    closeFilter,
    rowValue,
    datepickerProps,
    setDatepickerProps,
    setDateRangeValue,
    handleModalOpen,
    modalData,
    menuData,
    setMenuData,
    modalOpen,
    modalContentHandler,
    modalActionHandler,
    handleModalClose,
    modalHeaderHandler,
    clearValueHandler,

    noteForEditOpen,
    noteModalOpen,
    noteForDeteteOpen
  };
}

export default useUserManagementHook;
