import axios from "../common/axiosInstance";

export const fetchAllImplementerTypes = async () => {
  const endPoint = "get_all_implementer_type/";
  const { data } = await axios.get(endPoint);
  return data;
};

export const fetchTypeWiseImplementer = async ({ type_id }: { type_id?: any }) => {
  const endPoint = `/get_type_wise_implementer/?implementer_type=${type_id}`;
  const { data } = await axios.get(endPoint);
  return data;
};

export const fetchAllImplementer = async () => {
  const endPoint = "get_all_implementer/";
  const { data } = await axios.get(endPoint);
  return data;
};

export const postImplementerData = async (postBody:any) => {
  const endPoint = "/change_type_wise_implementer/";
  const { data } = await axios.post(endPoint,postBody);
  return data;
};