import axios from 'axios';
import { envConstants } from '../config/env-constants';

// axios instance for making requests
const axiosInstance = axios.create({
  baseURL: `${envConstants.baseUrl}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
});

// request interceptor for adding token
axiosInstance.interceptors.request.use((config) => {
  const requestConfig = { ...config };
  // add token to request headers
  const authToken = JSON.parse(localStorage.getItem('okta-token-storage') || '');
  if (
    requestConfig
    && requestConfig.headers
    && authToken
    && authToken.idToken
    && authToken.idToken.idToken
  ) {
    requestConfig.headers.Authorization = authToken.idToken.idToken;
  }
  return requestConfig;
});

axiosInstance.interceptors.response.use((response) =>
  response, (error) => {
    console.error('API call error occured', error);
    return error
    // can be done better way like logging to some remote logger.
  }
);

export default axiosInstance;
