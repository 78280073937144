import {
    useEffect,
    useRef,
    useState,
    useCallback,
} from 'react';
import Grid from '@mui/material/Grid';
import {
    useFormikContext,
} from 'formik';
import {
    debounce,
} from 'lodash';
import FormikControl from '../../../components/formik-conrtols/FormikControl';
import { searchUserByQuery } from '../../../api-services/common-api';
import {
    getAllCompany,
} from '../../../api-services/delegation-module';
import { fecthSearchByStatusData } from '../../../api-services/user-management';
import adminSummaryStyles from "../administrative-summary-styles";
import { getThirdPartyCompany } from '../../../api-services/par';

function AddUserDetails() {
    const formikCtx: any = useFormikContext();
    const [searchLoading, setSearchLoading] = useState(false);
    const [openState, setOpenState] = useState(false);
    const [OpenDisabled, setOpenDisabled] = useState(false);
    const [userNameToSearch, setUserNameToSearch] = useState('');
    const [companyList, setCompanyList] = useState([]);
    const [thirdPartyCompany, setThirdPartyCompany] = useState([]);
    const [userNameList, setUserNameList] = useState<{
        first_name: string,
        last_name: string,
        email: string,
        new_homeorg: string
    }[]>([]);

    const isAutoCompleteSelected = useRef(false);

    const roleGroup = [{ id: 'Administrator', value: 'Administrator' }, { id: 'Approver', value: 'Approver' }, { id: 'Implementer', value: 'Implementer' },
    { id: 'Requestor', value: 'Requestor' }, { id: 'Reporter', value: 'Reporter' }];

    // User Fetch Data on Search//
    const searchUserByFirstName = (value: string) => {
        if (value !== '') {
            searchUserByQuery(value, '').then((res) => {
                setOpenState(true)
                setUserNameList([...res]);
            }).catch((err: any) => {
                setUserNameList([]);
            }).finally(() => {
                setSearchLoading(false);
            });
        }
    };

    const debouncedSearch = useCallback(
        debounce((inputValue) => {
            searchUserByFirstName(inputValue);
        }, 1500),
        [],
    );

    useEffect(() => {
        debouncedSearch(userNameToSearch);
    }, [userNameToSearch]);

    const TextFormation = (value: string) => {
        value = value.toLowerCase().replace(/\b[a-z]/g, function (letter) {
            return letter.toUpperCase();
        });
        return value
    }
    useEffect(() => {
        const postBody = {
            first_name: formikCtx.values.first_name,
            last_name: formikCtx.values.last_name,
            email: formikCtx.values.email
        }
        if (postBody.first_name !== '') {
            fecthSearchByStatusData(postBody).then((res) => {
                formikCtx.setFieldValue('account_status', TextFormation(res.data[0].status));
                if (res.data[0].is_exists) {
                    formikCtx.setFieldValue('okta_account', 'Exists');
                    setOpenDisabled(true)
                } else {
                    formikCtx.setFieldValue('okta_account', 'Requested');
                    setOpenDisabled(true)

                }
            });
        }
    }, [formikCtx.values.role_group]);

    useEffect(() => {
        getAllCompany().then((res) => {
            setCompanyList(res.data);
        }).catch((err) => {
        });
        getThirdPartyCompany().then((res) => {
            setThirdPartyCompany(res.data);
        }).catch((err) => {
        })
    }, []);


    return (
        <>
            <Grid container spacing={2}>
                <Grid item sm={6}>
                    <FormikControl
                        control="autocomplete"
                        label="First name"
                        name="first_name"
                        open={openState}
                        options={userNameList?.map((userObj: any) => ({
                            value: `${userObj.email}`,
                            label: `${userObj.first_name} ${userObj.last_name}`,
                        }))}
                        handleInputChange={(inputValue: string, reason: string) => {
                            if (reason === 'input') {
                                setUserNameToSearch(inputValue);
                                setSearchLoading(true);
                                setOpenState(false)
                            }
                            if (reason === 'reset') {
                                formikCtx
                                    .setFieldValue('first_name', inputValue.split(' ')[0]);
                            }
                        }}
                        freeSolo
                        loading={searchLoading}
                        onBlur={() => {
                            if (!isAutoCompleteSelected.current) {
                                formikCtx.setFieldValue('first_name', userNameToSearch);
                            }
                        }}
                        handleSelectionChange={(selectedValue: {
                            value: string,
                            label: string,
                        }) => {
                            const selectedManager = userNameList?.find(
                                (obj: any) => obj.email === selectedValue.value,
                            );
                            setOpenState(false)
                            isAutoCompleteSelected.current = true;
                            formikCtx.setFieldValue('first_name', selectedManager ? selectedManager.first_name : '');
                            formikCtx.setFieldValue('last_name', selectedManager ? selectedManager.last_name : '');
                            formikCtx.setFieldValue('email', selectedManager ? selectedManager.email : '');
                            if (selectedManager && selectedManager.new_homeorg == 'ssow008') {
                                formikCtx.setFieldValue('company', '3rd Party')
                            }
                        }}
                        required
                    />
                </Grid>
                <Grid item sm={6}>
                    <FormikControl
                        control="textfield"
                        label="Last name"
                        name="last_name"
                        required
                    />
                </Grid>
                <Grid item sm={6}>
                    <FormikControl
                        control="textfield"
                        label="Email address"
                        name="email"
                        required
                    />
                </Grid>
                <Grid item sm={6}>
                    <FormikControl
                        control="autocomplete"
                        label="Company"
                        name="company"
                        options={
                            companyList?.map((companyObj: any) => ({
                                label: companyObj.company,
                                value: companyObj.company,
                            }))
                        }
                        required
                    />
                </Grid>
                {formikCtx.values.company == ('3rd Party' || 'Third Party') &&
                    <>
                        <Grid item sm={6}>
                            <FormikControl
                                control="autocomplete"
                                label="3rd party company"
                                name="third_party_company"
                                options={
                                    thirdPartyCompany?.map((companyObj: any) => ({
                                        label: companyObj.third_party_company_name,
                                        value: companyObj.third_party_company_name,
                                    }))
                                }
                            />
                        </Grid>
                        {formikCtx.values.third_party_company !== 'Not listed' && <Grid item sm={6}>
                        </Grid>
                        }
                    </>
                }
                {(formikCtx.values.third_party_company == 'Not listed') && (formikCtx.values.company == ('3rd Party' || 'Third Party')) &&
                    <>
                        <Grid item sm={6}>
                            <FormikControl
                                control="textfield"
                                label="Company name"
                                name="not_listed_third_party_company"
                            />
                        </Grid>

                    </>
                }
                <Grid item sm={6}>
                    <FormikControl
                        control="autocomplete"
                        label="Role group"
                        name="role_group"
                        options={
                            roleGroup?.map((companyObj: any) => ({
                                label: companyObj.value,
                                value: companyObj.value,
                            }))
                        }
                        required
                    />
                </Grid>
                <Grid item sm={6}>
                </Grid>
                <Grid item sm={6}>
                    <FormikControl
                        control="textfield"
                        label="Account status"
                        name="account_status"
                        disabled={OpenDisabled}
                    />
                </Grid>
                <Grid item sm={6}>
                </Grid>
                <Grid item sm={6}>
                    <FormikControl
                        control="textfield"
                        label="Okta account"
                        name="okta_account"
                        disabled={OpenDisabled}
                    />
                </Grid>
            </Grid>
        </>
    );
}
export default AddUserDetails;
