/* eslint-disable react/require-default-props */
import React, { useEffect, useState, useMemo } from 'react';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { buttonStyles, filterStyles } from './make-styles';

interface IFilterMenuProps {
  badgeCount?: number | ' ';
  children?: React.ReactNode;
  filterLabel: string;
  handleFilterClose: () => void;
  closeFilterExternally?: boolean;
}

function FilterMenu({
  badgeCount,
  children,
  filterLabel,
  handleFilterClose,
  closeFilterExternally,
}: IFilterMenuProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(
    null,
  );
  const open = !!anchorEl;
  const filterClasses = filterStyles();
  const buttonClasses = buttonStyles();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    handleFilterClose();
    setAnchorEl(null);
  };

  useEffect(() => {
    if (closeFilterExternally) {
      handleClose();
    }
  }, [closeFilterExternally]);

  const id = open ? 'simple-popover' : undefined;

  const getEndIcon = useMemo(() => {
    if (anchorEl) {
      return <KeyboardArrowUpIcon />;
    }
    if (badgeCount) {
      return <FilterAltIcon />;
    }
    return <KeyboardArrowDownIcon />;
  }, [anchorEl, badgeCount]);

  return (
    <div className={filterClasses.root} data-testid="filtermenu-wrapper">
      <Button
        className={`${buttonClasses.button} ${open && buttonClasses.activeButton}`}
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        endIcon={getEndIcon}
        data-testid="filter-button"
      >
        {filterLabel}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        data-testid="filtermenu-popover"
      >
        {children}
      </Popover>
    </div>
  );
}

export default FilterMenu;
