import { createSlice, current } from '@reduxjs/toolkit'
import { IData } from './IDelegationSubmitData';
import type { PayloadAction } from '@reduxjs/toolkit'
import { IFreshPostedData } from './IDelegationSubmitData';
import { IpostData } from './IDelegationSubmitData';

export const delegationCommentSlice = createSlice({
    name: 'submitDelegationCommentData',
    initialState: [
        {
            Reason_for_Delegation_request: []
        },
        { Justification_for_access: [] },
        { Requestor_details: [] },
        { Team_details: [] },
        { Job_title_and_description: [] },
        { Choose_account_type: [] },
        { Role_definition: [] },
        { User_details: [] },
        { Provide_custom_request: [] },
        { Third_Party_Request_For_Service_in_place: [] },
        { Contract_number_or_Request_For_Service_reference: [] },
        { Request_For_Service_details: [] },
        { Implementation_plan: [] },
        { Access_required_for_Request_For_Service: [] },
        { Third_Party_Request_For_Service_timings: [] },
        { Privileged_access_Delegation_owner_sign_off: [] },
    ],
    reducers: {
        submitDelegationComments: (state, action: PayloadAction<any>) => {
            return [...action.payload]
        },
        postDelegationFreshComments: (state, action: PayloadAction<IpostData>) => {
            console.log(action.payload, 'actionpayload')
            state.map((row) => {
                Object.entries(row).map(([key, value]) => {
                    if (key === action.payload.section) {
                        value.push(action.payload)
                    }
                })
            })
        },
        deleteDelegationComments: (state, action: PayloadAction<IpostData>) => {
            state.map((row) => {
                Object.entries(row).map(([key, value]) => {
                    if (key === action.payload.section) {
                        value.map((item: any, index: number) => {
                            if (item.id === action.payload.id) {
                                value.splice(index, 1)
                            }
                        })
                    }
                })
            })
        },
        editDelegationComments: (state, action: PayloadAction<IpostData>) => {
            state.map((row) => {
                Object.entries(row).map(([key, value]) => {
                    if (key === action.payload.section) {
                        value.map((item: any, index: number) => {
                            if (item.id === action.payload.id) {
                                value.splice(index, 1)
                                value.push(action.payload)
                            }
                        })
                    }
                })
            })
        },
        requestorDelegationApproverFreshComments: (state, action: PayloadAction<IpostData>) => {
            state.map((row) => {
                Object.entries(row).map(([key, value]) => {
                    if (key === action.payload.section) {

                        value.map((item: any, index: number) => {

                            if (item.id === action.payload.comment_id) {
                                item.requestor_reply = [...item.requestor_reply, action.payload]
                            }
                        })
                    }
                })
            })
        },
        requestorDelegationEditReply: (state, action: PayloadAction<IpostData>) => {
            state.map((row) => {
                Object.entries(row).map(([key, value]) => {
                    if (key === action.payload.section) {

                        value.map((item: any, index: number) => {

                            if (item.id === action.payload.comment_id) {
                                item.requestor_reply = [action.payload]
                            }
                        })
                    }
                })
            })
        },
        requestorDelegationDeleteReply: (state, action: PayloadAction<IpostData>) => {

            state.map((row) => {
                Object.entries(row).map(([key, value]) => {
                    if (key === action.payload.section) {

                        value.map((item: any, index: number) => {

                            if (item.id === action.payload.comment_id) {
                                item.requestor_reply = []
                            }
                        })
                    }
                })
            })
        }


    }
});

export const { submitDelegationComments, postDelegationFreshComments, deleteDelegationComments, editDelegationComments, requestorDelegationApproverFreshComments, requestorDelegationEditReply, requestorDelegationDeleteReply } = delegationCommentSlice.actions;
export default delegationCommentSlice.reducer;