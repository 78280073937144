import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import {
    ArrayHelpers,
    FieldArray,
    Formik,
    FormikProps,
    FormikValues,
    useFormikContext,
} from "formik";
import * as Yup from "yup";
import CustomButton from "../../../button";
import FormikControl from "../../../formik-conrtols/FormikControl";
import FieldLabel from "../../../field-label";
import { roleDefinitionStyles, modalStyles, parStyles, sarUseStyles } from "../../useStyles";
import { SpnSection } from '../tooltips';
import { RootState } from '../../../../appStore/store';
import HistoryTable from '../../../../modules/sar/comments/historyTable';
import CommentSubmission from '../../../../modules/sar/comments/commentSubmission';
import { format, isDate } from 'date-fns';
import { routeCheck, routeCheckForAdminSummary, routeCheckForApprovalSummary, routeCheckForImplementationSummary, routeCheckForPendingApproval } from './routingConditional';


export type RoleDefinitionType = {
    id: string | null;
    user_or_computer: string;
    service_type: string;
    port: string;
    service_name: string;
    domain: string;
    // regional_scope_label: string;
    // regional_scope: number | null;
    // market_scope_label: string;
    // market_scope: number | null;
    // city_scope_label: string;
    // city_scope: number | null;
    // country_scope_label: string;
    // country_scope: number | null;
    // is_role_for_thirdparty_user: boolean;
    // does_servicenow_require: boolean;
    is_active: boolean;
    is_deleted: boolean;
};

const emptyRole = {
    id: null,
    user_or_computer: '',
    service_type: '',
    port: '',
    service_name: '',
    domain: '',
    is_active: true,
    is_deleted: false,
};
function SpnAndDelegationDetails({ isDisabled }: { isDisabled: boolean }) {
    const classes = roleDefinitionStyles();
    const styleClasses = parStyles();
    const formikCtx: any = useFormikContext();
    const modalClasses = modalStyles();
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [roleGroupModalValues, setRoleGroupModalValues] =
        useState<RoleDefinitionType>({ ...emptyRole });
    const formRef = useRef<FormikProps<FormikValues>>(null);
    const [modalAction, setModalAction] = useState<{
        index: number;
        actionType: string;
    }>({
        index: -1,
        actionType: "",
    });
    const [totalSlotsUtilized, setTotalSlotsUtilized] = useState<number>(0);
    const [serviceAccountRequest, SetServiceAccountRequest] = useState([{
        label: 'SPN only',
        value: 'SPNonly',
    }, {
        label: 'Delegation only',
        value: 'Delegationonly',
    }, {
        label: 'Both SPN & delegation',
        value: 'BothSPN&delegation',
    }]);

    const remediated_by = formikCtx.values.remediation_by
    const remediationCount = formikCtx.values.remediation_count;
    const { fieldHistorySarCommentsData } = useSelector((state: RootState) => state);
    const { submitSarCommentData } = useSelector((state: RootState) => state);
    const formatDateInside = (dateValue: any) => (dateValue && isDate(new Date(dateValue)) ? format(new Date(dateValue), 'MMM dd, yyyy') : '');

    const checkForEditReviewFlow = () => {
        return routeCheck() || routeCheckForAdminSummary() || routeCheckForImplementationSummary() || routeCheckForApprovalSummary()
    }


    // useEffect(() => {
    //     let totalCount = 0;
    //     if (
    //         formikCtx.values.sar_delegation_list &&
    //         formikCtx.values.sar_delegation_list.length
    //     ) {
    //         formikCtx.values.sar_delegation_list.forEach(
    //             (roleObj: RoleDefinitionType) => {
    //                 if (roleObj.is_active)
    //                     totalCount += parseInt(roleObj.number_of_slots, 10);
    //             }
    //         );
    //         setTotalSlotsUtilized(totalCount);
    //     }
    // }, [formikCtx.values.sar_delegation_list]);

    // useEffect(() => {
    //     if (!modalOpen) {
    //         setModalAction({
    //             index: -1,
    //             actionType: "",
    //         });
    //         setRoleGroupModalValues(emptyRole);
    //     }
    //     if (modalOpen && modalAction.index >= 0) {
    //         const filteredRolesSlots = formikCtx.values.sar_delegation_list.reduce(
    //             (slotSum: number, roleObj: RoleDefinitionType, index: number) => {
    //                 if (index !== modalAction.index) {
    //                     return slotSum * 1 + parseInt(roleObj.number_of_slots, 10);
    //                 }
    //                 return slotSum;
    //             },
    //             0
    //         );
    //         setTotalSlotsUtilized(filteredRolesSlots);
    //     }
    // }, [modalOpen]);

    const handleClose = () => {
        setModalOpen(false);
    };

    const handleSave = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    const handleViewRole = (roleObject: RoleDefinitionType, index: number) => {
        setRoleGroupModalValues(roleObject);
        setModalAction({
            actionType: "view",
            index,
        });
        setModalOpen(true);
    };

    const modalContentHandler = (
        arrayHelpers: ArrayHelpers,
        actionType: string
    ) => {
        switch (actionType) {
            case "view":
            case "edit":
            case "add":
                return (
                    <Formik
                        validationSchema={Yup.object({
                            service_type: Yup.string().required("Please provide Service type"),
                            user_or_computer: Yup.string().required("Please select port"),
                            port: Yup.string().required("Please provide User name/computer Name"),
                            service_name: Yup.string().required("Please provide Service account name"),
                            domain: Yup.string().required("Please provide domain"),
                        })}
                        onSubmit={(values: FormikValues) => {
                            if (modalAction.index >= 0) {
                                arrayHelpers.replace(modalAction.index, values);
                            } else {
                                arrayHelpers.push({ ...values });
                            }
                            handleClose();
                        }}
                        validateOnChange={false}
                        initialValues={roleGroupModalValues}
                        innerRef={formRef}
                    >
                        {({ values, setFieldValue }) => (
                            <div>
                                <Grid container spacing={2}>
                                    <Grid item xs={10}>
                                        <FormikControl
                                            disabled={isDisabled}
                                            control="textfield"
                                            label="Service Type"
                                            name="service_type"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <FormikControl
                                            disabled={isDisabled}
                                            control="textfield"
                                            label="User name/Computer name"
                                            name="user_or_computer"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <FormikControl
                                            disabled={isDisabled}
                                            control="textfield"
                                            label="Port"
                                            name="port"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <FormikControl
                                            disabled={isDisabled}
                                            control="textfield"
                                            label="Service account Name"
                                            name="service_name"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <FormikControl
                                            disabled={isDisabled}
                                            control="textfield"
                                            label="Domain"
                                            name="domain"
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                    </Formik>
                );
            case "delete":
                return (
                    <div>
                        <div className={modalClasses.modalContentDiv}>
                            Are you sure you want to delete this Role Definition and all its
                            data?
                        </div>
                        <div className={modalClasses.modalTextDiv}>
                            <span className={modalClasses.spanText}>{"Note: "}</span>A
                            cancelled Role Definition will be permanently deleted and cannot
                            be retrieved.
                        </div>
                    </div>
                );
            case "slotlimitreached":
                return (
                    <div className={modalClasses.modalContentDiv}>
                        The sum of total Privileged Access Users within the Delegation can
                        not exceed 50.
                    </div>
                );
            default:
                return null;
        }
    };

    const modalActionHandler = (
        arrayHelpers: ArrayHelpers,
        actionType: string
    ) => {
        switch (actionType) {
            case "view":
            case "edit":
            case "add":
                return (
                    <div className={modalClasses.modalActionDiv}>
                        <CustomButton
                            variant="contained"
                            actionType="secondary"
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            Cancel
                        </CustomButton>
                        <CustomButton
                            variant="contained"
                            actionType="primary"
                            disableMargin
                            disabled={isDisabled}
                            onClick={() => {
                                handleSave();
                            }}
                        >
                            Save
                        </CustomButton>
                    </div>
                );
            case "delete":
                return (
                    <div className={modalClasses.modalActionDiv}>
                        <CustomButton
                            variant="contained"
                            actionType="secondary"
                            onClick={() => {
                                const roleToDelete =
                                    formikCtx.values.sar_delegation_list[modalAction.index];
                                arrayHelpers.replace(modalAction.index, {
                                    ...roleToDelete,
                                    is_active: false,
                                });
                                handleClose();
                            }}
                        >
                            Delete
                        </CustomButton>
                        <CustomButton
                            variant="contained"
                            actionType="primary"
                            disableMargin
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            Keep
                        </CustomButton>
                    </div>
                );
            case "slotlimitreached":
                return (
                    <div className={modalClasses.modalActionDiv}>
                        <CustomButton
                            variant="contained"
                            actionType="primary"
                            disableMargin
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            OK
                        </CustomButton>
                    </div>
                );
            default:
                return null;
        }
    };

    const getModalHeader = (actionType: string) => {
        switch (actionType) {
            case "view":
            case "edit":
            case "add":
                return "Role Definition";
            case "delete":
                return "Delete Role Definition";
            case "slotlimitreached":
                return "Warning";
            default:
                return "";
        }
    };


    const SpnOnly = () => {
        return (
            <>
                <Grid container paddingTop={'12px'} >
                    <Grid item xs={10}>
                        <div>
                            <FieldLabel Title="Provide SPN list below:" required />
                        </div>
                        <>
                            <div className={styleClasses.updatedByStyle}>
                                {/* @ts-ignore */}
                                {(fieldHistorySarCommentsData[18].SPN_list?.length > 0) && `Updated on ${formatDateInside(fieldHistorySarCommentsData[18].SPN_list[fieldHistorySarCommentsData[18].SPN_list?.length - 1].last_updated)}`}
                            </div>
                        </>
                    </Grid>
                    <Grid item xs={2}>
                        {(checkForEditReviewFlow() && remediationCount > 0) ? (
                            <div className={styleClasses.mainSectionDiv}>
                                <HistoryTable
                                    parId={formikCtx.values.request_id}
                                    section="SPN_list"
                                    subSection=''
                                    data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[18].SPN_list : null}
                                />
                                <CommentSubmission
                                    sectionName='SPN_list'
                                    subSection=''
                                    updateType='Select update'
                                    remediation_by={remediated_by}
                                    data={submitSarCommentData.length > 0 ? submitSarCommentData[18].SPN_list : null}
                                />
                            </div>
                        ) : routeCheckForPendingApproval() && (
                            <div className={styleClasses.mainSectionDiv}>
                                <HistoryTable
                                    parId={formikCtx.values.request_id}
                                    section="SPN_list"
                                    subSection=''
                                    data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[18].SPN_list : null}
                                />
                                <CommentSubmission
                                    sectionName='SPN_list'
                                    subSection=''
                                    updateType='Select update'
                                    remediation_by={remediated_by}
                                    data={submitSarCommentData.length > 0 ? submitSarCommentData[18].SPN_list : null}
                                />
                            </div>
                        )}
                    </Grid>
                </Grid >
                <Grid container rowSpacing={3} columnSpacing={10} >
                    <Grid item xs={12}>
                        <FormikControl
                            control="textfield"
                            name="sar_spn_list"
                            //label="Please server hostnames"
                            disabled={isDisabled}
                            placeholder="Provide SPN list here"
                            multiline
                            minRows={5}
                        />
                    </Grid>
                </Grid>
            </>
        )
    }

    const DelegationRequestOnly = () => {
        return (
            <>
                <Grid container paddingTop={'12px'} >
                    <Grid item xs={10}>
                        <div>
                            <FieldLabel Title="Provide Delegation details below:" required />
                        </div>
                        <>
                            <div className={styleClasses.updatedByStyle}>
                                {/* @ts-ignore */}
                                {(fieldHistorySarCommentsData[34].required_delegtion?.length > 0) && `Updated on ${formatDateInside(fieldHistorySarCommentsData[34].required_delegtion[fieldHistorySarCommentsData[34].required_delegtion?.length - 1].last_updated)}`}
                            </div>
                        </>
                    </Grid>
                    <Grid item xs={2}>
                        {(checkForEditReviewFlow() && remediationCount > 0) ? (
                            <div className={styleClasses.mainSectionDiv}>
                                <HistoryTable
                                    parId={formikCtx.values.request_id}
                                    section="required_delegtion"
                                    subSection=''
                                    data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[34].required_delegtion : null}
                                />
                                <CommentSubmission
                                    sectionName='required_delegtion'
                                    subSection=''
                                    updateType='Select update'
                                    remediation_by={remediated_by}
                                    data={submitSarCommentData.length > 0 ? submitSarCommentData[34].required_delegtion : null}
                                />
                            </div>
                        ) : routeCheckForPendingApproval() && (
                            <div className={styleClasses.mainSectionDiv}>
                                <HistoryTable
                                    parId={formikCtx.values.request_id}
                                    section="required_delegtion"
                                    subSection=''
                                    data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[34].required_delegtion : null}
                                />
                                <CommentSubmission
                                    sectionName='required_delegtion'
                                    subSection=''
                                    updateType='Select update'
                                    remediation_by={remediated_by}
                                    data={submitSarCommentData.length > 0 ? submitSarCommentData[34].required_delegtion : null}
                                />
                            </div>
                        )}
                    </Grid>
                    <Grid container rowSpacing={3} columnSpacing={10} >
                        <Grid item xs={12}>
                            <FormikControl
                                control="textfield"
                                name="sar_delegation_details"
                                disabled={isDisabled}
                                placeholder="Provide Delegation details below"
                                multiline
                                minRows={5}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={10}>
                        <FieldLabel Title="Choose from the below required delegations:" required />
                    </Grid>
                    <Grid item xs={12} className={classes.checkBox}>
                        <FormikControl
                            control="checkbox"
                            label="Trust this user for delegation to any service (Kerberos only)"
                            name="sar_is_any_service_kerberos_only"
                            required
                            disabled={isDisabled}
                        //className={classes.modelCheckboxLabel}
                        />
                        <FormikControl
                            control="checkbox"
                            label="Trust this user for delegation to specified service only (Kerberos only)"
                            name="sar_is_specified_service_kerberos_only"
                            required
                            disabled={isDisabled}
                        //className={classes.modelCheckboxLabel}
                        />
                        <FormikControl
                            control="checkbox"
                            label="Trust this user for delegation to specified service only (Protocol only)"
                            name="sar_is_specified_service_any_protocol"
                            required
                            disabled={isDisabled}
                        //className={classes.modelCheckboxLabel}
                        />
                    </Grid>
                </Grid>
                <Grid container paddingTop={'12px'} >
                    <Grid item xs={10}>
                        <div>
                            <FieldLabel Title="Delegation reason (optional)" />
                        </div>
                        <>
                            <div className={styleClasses.updatedByStyle}>
                                {/* @ts-ignore */}
                                {(fieldHistorySarCommentsData[20].Delegation_reason?.length > 0) && `Updated on ${formatDateInside(fieldHistorySarCommentsData[20].Delegation_reason[fieldHistorySarCommentsData[20].Delegation_reason?.length - 1].last_updated)}`}
                            </div>
                        </>
                    </Grid>

                    <Grid item xs={2}>
                        {(checkForEditReviewFlow() && remediationCount > 0) ? (
                            <div className={styleClasses.mainSectionDiv}>
                                <HistoryTable
                                    parId={formikCtx.values.request_id}
                                    section="Delegation_reason"
                                    subSection=''
                                    data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[20].Delegation_reason : null}
                                />
                                <CommentSubmission
                                    sectionName='Delegation_reason'
                                    subSection=''
                                    updateType='Select update'
                                    remediation_by={remediated_by}
                                    data={submitSarCommentData.length > 0 ? submitSarCommentData[20].Delegation_reason : null}
                                />
                            </div>
                        ) : routeCheckForPendingApproval() && (
                            <div className={styleClasses.mainSectionDiv}>
                                <HistoryTable
                                    parId={formikCtx.values.request_id}
                                    section="Delegation_reason"
                                    subSection=''
                                    data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[20].Delegation_reason : null}
                                />
                                <CommentSubmission
                                    sectionName='Delegation_reason'
                                    subSection=''
                                    updateType='Select update'
                                    remediation_by={remediated_by}
                                    data={submitSarCommentData.length > 0 ? submitSarCommentData[20].Delegation_reason : null}
                                />
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <FormikControl
                            control="textfield"
                            name="sar_delegation_reason"
                            // label="Please server hostnames"
                            disabled={isDisabled}
                            placeholder="Provide Delegation reason"
                            multiline
                            minRows={5}
                        />
                    </Grid>
                </Grid >
                {/* <Grid container paddingTop={'12px'} >
                    <Grid item xs={10}>
                        <FieldLabel Title="Delegation list (optional)" />
                    </Grid>
                    <Grid item xs={2}>

                    </Grid>
                    <Grid container spacing={2}>
                        <FieldArray
                            name="sar_delegation_list"
                            render={(arrayHelpers) => (
                                <>
                                    {!isDisabled && (
                                        <Grid item xs={4} sm={3} md={3} lg={3}>
                                            <div
                                                className={classes.addRoleTile}
                                                onClick={() => {
                                                    setModalAction({
                                                        actionType:
                                                            totalSlotsUtilized >= 50 ? "slotlimitreached" : "add",
                                                        index: -1,
                                                    });
                                                    setModalOpen(true);
                                                }}
                                                role="presentation"
                                                onKeyDown={() => null}
                                            >
                                                <div style={{ display: "flex" }}>
                                                    <Add style={{ fontSize: "20px", lineHeight: "20px" }} />
                                                    Add
                                                </div>
                                            </div>
                                        </Grid>
                                    )}
                                    {formikCtx.values.sar_delegation_list &&
                                        formikCtx.values.sar_delegation_list.length
                                        ? formikCtx.values.sar_delegation_list.map(
                                            (del_role_obj: RoleDefinitionType, index: number) =>
                                                del_role_obj.is_active ? (
                                                    <Grid
                                                        item
                                                        xs={4}
                                                        sm={3}
                                                        md={3}
                                                        lg={3}
                                                        className={classes.roleTileWrapper}
                                                    >
                                                        <div
                                                            className={classes.roleTile}
                                                            onClick={() => {
                                                                handleViewRole(del_role_obj, index);
                                                            }}
                                                            role="presentation"
                                                        >
                                                            <div className={classes.tileHeader}>
                                                                Service type:
                                                            </div>
                                                            <div className={classes.tileValue}>
                                                                {del_role_obj.service_type || ""}
                                                            </div>
                                                            <div className={classes.tileHeader}>User name/computer name: </div>
                                                            <div className={classes.tileValue}>
                                                                {del_role_obj.user_or_computer || ""}
                                                            </div>
                                                            <div className={classes.tileHeader}>Port: </div>
                                                            <div className={classes.tileValue}>
                                                                {del_role_obj.port || ""}
                                                            </div>
                                                            <div className={classes.tileHeader}>Domain: </div>
                                                            <div className={classes.tileValue}>
                                                                {del_role_obj.domain || ""}
                                                            </div>
                                                        </div>
                                                        {!isDisabled && (
                                                            <div className={classes.roleActionStrip}>
                                                                <div className={classes.roleActionStripAbsolute}>
                                                                    <span className={classes.iconWrapper}>
                                                                        <DeleteAction
                                                                            onClick={() => {
                                                                                setModalAction({
                                                                                    actionType: "delete",
                                                                                    index,
                                                                                });
                                                                                setModalOpen(true);
                                                                            }}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Grid>
                                                ) : null
                                        )
                                        : null}
                                    <CustomModal
                                        modalHeader={getModalHeader(modalAction.actionType)}
                                        open={modalOpen}
                                        onClose={handleClose}
                                        modalAction={modalActionHandler(
                                            arrayHelpers,
                                            modalAction.actionType
                                        )}
                                        modalContent={modalContentHandler(
                                            arrayHelpers,
                                            modalAction.actionType
                                        )}
                                    />
                                </>
                            )}
                        />
                    </Grid>
                </Grid > */}
            </>
        )
    }
    return (
        <>
            <Grid container paddingTop={'12px'} >
                <Grid item xs={10}>
                    <div>
                        <FieldLabel Title="Please choose below if service account request require SPN (Service Principle Name) and or Delegation?" tooltipMsg={SpnSection} required />
                    </div>
                    <>
                        <div className={styleClasses.updatedByStyle}>
                            {/* @ts-ignore */}
                            {(fieldHistorySarCommentsData[17].Service_account_request_spn_dele?.length > 0) && `Updated on ${formatDateInside(fieldHistorySarCommentsData[17].Service_account_request_spn_dele[fieldHistorySarCommentsData[17].Service_account_request_spn_dele?.length - 1].last_updated)}`}
                        </div>
                    </>
                </Grid>
                <Grid item xs={2}>
                    {(checkForEditReviewFlow() && remediationCount > 0) ? (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="Service_account_request_spn_dele"
                                subSection=''
                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[17].Service_account_request_spn_dele : null}
                            />
                            <CommentSubmission
                                sectionName='Service_account_request_spn_dele'
                                subSection=''
                                updateType='Select update'
                                remediation_by={remediated_by}
                                data={submitSarCommentData.length > 0 ? submitSarCommentData[17].Service_account_request_spn_dele : null}
                            />
                        </div>
                    ) : routeCheckForPendingApproval() && (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="Service_account_request_spn_dele"
                                subSection=''
                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[17].Service_account_request_spn_dele : null}
                            />
                            <CommentSubmission
                                sectionName='Service_account_request_spn_dele'
                                subSection=''
                                updateType='Select update'
                                remediation_by={remediated_by}
                                data={submitSarCommentData.length > 0 ? submitSarCommentData[17].Service_account_request_spn_dele : null}
                            />
                        </div>
                    )}
                </Grid>
                <Grid item xs={10}>
                    <FormikControl
                        disabled={isDisabled}
                        control="radio"
                        name="sar_spn_and_or_delegation"
                        radioGrouplabels={serviceAccountRequest}
                    />
                </Grid>
            </Grid >

            {/*  for Textfeild Only for Selection on SPNOnly resquest from Above radio button */}
            {
                formikCtx.values.sar_spn_and_or_delegation == 'SPNonly' ?
                    SpnOnly() : (formikCtx.values.sar_spn_and_or_delegation == 'Delegationonly' ?
                        DelegationRequestOnly() : [SpnOnly(),
                        DelegationRequestOnly()])
            }
        </>
    )
}

export default SpnAndDelegationDetails;