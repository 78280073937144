/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from "react";
import { FormikValues, FormikHelpers } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { uniqWith, isEqual } from "lodash";
import format from "date-fns/format/index";
import CustomButton from "../../button";
import CustomModal from "../../custom-modal";
import modalStyles from "../../custom-modal/modalStyles";
import { postNewRequest } from "../../../api-services/delegation-module";
import DelegationReason from "./formsSteps/reason";
import RequestorAndManagerDetails from "./formsSteps/requestorAndManager";
import JobTitleExplanation from "./formsSteps/jobTitleExplanation";
import AccountType from "./formsSteps/accountType";
import RoleDefinition from "./formsSteps/roleDefinition";
import CustomRequest from "./formsSteps/customRequests";
import UserDetails from "./formsSteps/userDetails";
import DelegationOwner from "./formsSteps/delegationOwnerSignOff";
import Implementation from "./formsSteps/implementation";
import delegationBody from "../../../constants/delegationBody";
import { FormikStep } from "./formikStep";
import { RootState } from "../../../appStore/store";
import { useSelector } from "react-redux";
import { IFormikContainerProps, IModalState, emptySchemaCheck, fieldsOfSection, staticDiffernceData, fieldsOfSectionForSubsection } from "./formikContainerUtils";
import { FormikStepper } from "./formikStepper";
import { postDelegationFieldHistoryData } from "../../../api-services/review-delegation";
import { routeCheckForAdminSummary, routeCheckForApprovalSummary, routeCheckForImplementationSummary, routeCheckForPendingImplementation } from './formsSteps/routingConditional';

const initialValuesData = {
  ...delegationBody,
  request_type: 1,

};

// Formik container code start
function FormikContainer({ ...props }: IFormikContainerProps) {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { loginuserdata, loginsyncupdata } = useSelector(
    (state: RootState) => state.loginData
  );




  useEffect(() => {
    if (loginuserdata.given_name) {
      initialValuesData.first_name = loginuserdata.given_name;
    }
    if (loginuserdata.family_name) {
      initialValuesData.last_name = loginuserdata.family_name;
    }
    if (loginuserdata.email) {
      initialValuesData.email = loginuserdata.email;
    }
  }, []);

  const [modalData, setModalData] = useState<IModalState>({
    show: false,
    modalHeader: "",
    modalContent: null,
    modalActions: null,
  });
  const centerButton = false;
  const modalClasses = modalStyles({ centerButton });

  // const checkIsDisabled = () =>
  //   !(
  //     props.data.status_label === "Draft" ||
  //     props.data.status_label === "Remediation Requested"
  //   );
  const checkIsDisabled = () => !(props.data.status_label === 'Draft' || props.data.status_label !== 'Pending Approval') || routeCheckForApprovalSummary() || props.data.status_label === 'Rejected' || props.data.status_label === 'Completed' || props.data.status_label === 'Rejected' || props.data.status_label === "Approved - Pending Implementation" || routeCheckForAdminSummary();

  const checkIsDisabledImplementation = () => {
    if ((routeCheckForImplementationSummary() && props.data.is_implemented) ||
      (routeCheckForAdminSummary() && props.data.is_implemented) ||
      (routeCheckForApprovalSummary() && props.data.is_implemented) ||
      (routeCheckForImplementationSummary() && props.data.is_implemented)
    ) {
      return true;
    } else return false;
  };

  const historyDataOfForm = JSON.parse(localStorage.getItem('historyDataOfMainSchemaDelegation') || '');
  return (
    <>
      <FormikStepper
        initialValues={{
          ...initialValuesData,
          ...props.data,
          del_other: props.data?.del_other || "",
          event: "update",
          request_id: props.requestId,
          finalCheck:
            props.data.status_label !== "Draft" &&
            props.data.status_label !== "Remediation Requested",
          // del_date: format(new Date(), "yyyy-MM-dd"),
          del_signed_by: props.data?.del_signed_by
            ? props.data?.del_signed_by
            : `${props.data?.first_name} ${props.data?.last_name}`,
        }}
        onSubmit={async (
          values: FormikValues,
          formikHelpers: FormikHelpers<FormikValues>
        ) => {

          if (props.data.remediation_count > 0) {
            const changedValues = Object.keys(values).filter(key => values[key] !== historyDataOfForm[key])
            const newArray = changedValues.filter(v => !staticDiffernceData.includes(v));
            let finalChangedData: any = [];
            newArray.length > 0 && newArray.map((item) => {
              finalChangedData.push(
                {
                  update_type: emptySchemaCheck.get(item),
                  section: fieldsOfSection.get(item),
                  sub_section: fieldsOfSectionForSubsection.get(item),
                  value: historyDataOfForm[item],
                  role_name_section: '',
                  user_details_section: ''
                }
              )
            })

            const historyPostBody = {
              request_id: props.requestId,
              is_submitted: false,
              data: finalChangedData.filter((item) => item.section !== undefined && item.sub_section !== undefined)
            }

            if (finalChangedData.length > 0) {
              postDelegationFieldHistoryData(historyPostBody).then((response) => {
                console.log(response)
              }).catch(error => error);
            }

          }
          formikHelpers.setSubmitting(true);
          await postNewRequest({ ...values, status: "4" })
            .then((res) => {
              if (res.status) {
                formikHelpers.resetForm({ values: res.data });
                // <Loader openModal={false} />
                setModalData({
                  show: true,
                  modalHeader: 'Success',
                  modalContent: (
                    <div>
                      <div className={modalClasses.modalContentDiv}>
                        <div>
                          {values.remediation_count === 0 && values.request_to_update_count === 0 ? `Your Delegation request has been created successfully` : `Your Delegation request has been updated and re-submitted successfully`}
                        </div>
                        <div>
                          Your Delegation is {res.data.request_number}
                        </div>
                      </div>
                      <div className={modalClasses.modalTextDiv}>
                        <span className={modalClasses.spanText}>
                          {'Note: '}
                        </span>
                        You will shortly receive an email confirmation with a link to your Delegation. You can use this link to return to the GPAG application to review the progress of your request. Please note that while in the review and approvals stages you will not be able to edit your request.<br /> <br />  You may be contacted for further information by the approvers during their review. Your prompt response to any queries will allow your request to be dealt with as quickly as possible.
                      </div>
                    </div>
                  ),
                  modalActions: (
                    <div className={modalClasses.modalActionDiv}>
                      <CustomButton
                        variant="contained"
                        actionType="primary"
                        disableMargin
                        onClick={() => history.push('/delegation')}
                      >
                        Ok
                      </CustomButton>
                    </div>
                  ),
                });
              } else {
                console.error(res);
              }
            })
            .catch((err) => {
              console.log(err);
              enqueueSnackbar("Something went wrong, please try again", {
                variant: "error",
              });
            })
            .finally(() => {
              formikHelpers.setSubmitting(false);
            });
        }}
        requestId={props.requestId}
        requestData={props.data}
      >
        <FormikStep
          validationSchema={Yup.object({
            onSave: Yup.boolean(),
            del_reason_for_raising_new_request: Yup.string().required(
              "Please select reason for delegation request"
            ),
            del_other: Yup.string().when("del_reason_for_raising_new_request", {
              is: "Other",
              then: Yup.string().required("Please specify reason for Delegation request"),
              otherwise: Yup.string().nullable(),
            }),
            del_justification_for_access: Yup.string().required(
              "Please provide justification"
            ),
          })}
        >
          <DelegationReason isDisabled={checkIsDisabled()} />
        </FormikStep>
        <FormikStep
          validationSchema={Yup.object({
            onSave: Yup.boolean(),
            first_name: Yup.string().required("Please provide first name"),
            last_name: Yup.string().required("Please provide last name"),
            email: Yup.string().required("Please provide email"),
            phone: Yup.string()
              .required("Please provide phone number")
              .test("len", "Please provide valid phone number", (val) => {
                if (val && val.length >= 7) return true;
                return false;
              }),
            company: Yup.string().required("Please provide your company name"),
            team_name: Yup.string().required("Please provide team name"),
            access_review_period: Yup.string().required(
              "Please provide period of access review"
            ),
            manager_first_name: Yup.string().required(
              `Please provide manager's first name`
            ),
            manager_last_name: Yup.string().required(
              "Please provide manager's last name"
            ),
            manager_phone: Yup.string()
              .required("Please provide manager's phone number")
              .test("len", "Please provide valid phone number", (val) => {
                if (val && val.length >= 7) return true;
                return false;
              }),
            manager_email: Yup.string().required("Please provide manager's email"),
            city: Yup.string().required("Please provide city"),
            country: Yup.string().required("Please provide country"),
          })}
        >
          <RequestorAndManagerDetails isDisabled={checkIsDisabled()} />
        </FormikStep>
        <FormikStep
          validationSchema={Yup.object({
            onSave: Yup.boolean(),
            del_job_title: Yup.string().required("Please provide job title"),
            del_job_explanation: Yup.string().required(
              "Please provide job description"
            ),
          })}
        >
          <JobTitleExplanation isDisabled={checkIsDisabled()} />
        </FormikStep>
        <FormikStep
          validationSchema={Yup.object({
            del_delegation_account_type_id: Yup.string()
              .required("Please select the account type")
              .typeError("Please select the account type"),
          })}
        >
          <AccountType isDisabled={checkIsDisabled()} />
        </FormikStep>
        <FormikStep validationSchema={Yup.object({})}>
          <RoleDefinition isDisabled={checkIsDisabled()} />
        </FormikStep>
        <FormikStep
          validationSchema={Yup.object({})}
        >
          <UserDetails isDisabled={checkIsDisabled()} />
        </FormikStep>
        <FormikStep
          validationSchema={Yup.object({
            del_custom_request: Yup.string(),
            del_is_thirdparty_request_service: Yup.bool(),
            del_short_term_long_term_contract_number: Yup.number()
              .when(
                "del_is_thirdparty_request_service",
                {
                  is: true,
                  then: Yup.number().typeError("Please enter number value only").required("Please provide contract number"),
                  otherwise: Yup.number(),
                }
              ),
            del_short_term_long_term_contract_details: Yup.string().when(
              "del_is_thirdparty_request_service",
              {
                is: true,
                then: Yup.string().required("Please provide service details"),
                otherwise: Yup.string(),
              }
            ),
            del_short_term_long_term_implementation_plan: Yup.string().when(
              "del_is_thirdparty_request_service",
              {
                is: true,
                then: Yup.string().required(
                  "Please provide implementation plan"
                ),
                otherwise: Yup.string(),
              }
            ),
            del_short_term_long_term_access_request: Yup.string().when(
              "del_is_thirdparty_request_service",
              {
                is: true,
                then: Yup.string().required(
                  "Please provide access required for service "
                ),
                otherwise: Yup.string(),
              }
            ),
          })}
        >
          <CustomRequest isDisabled={checkIsDisabled()} />
        </FormikStep>
        <FormikStep
          validationSchema={Yup.object({
            finalCheck: Yup.boolean().oneOf(
              [true],
              "Please agree to the guidelines"
            ),
          })}
        >
          <DelegationOwner isDisabled={checkIsDisabled()} />
        </FormikStep>
        {(checkIsDisabledImplementation() || routeCheckForPendingImplementation()) &&
          (<FormikStep validationSchema={Yup.object({
            review_comments_first_step: Yup.string().required('please fill up the '),
            mark_step_firststep: Yup.boolean().oneOf(
              [true],
              "Please mark this step complete"
            ),
            mark_step_secondstep: Yup.boolean().oneOf(
              [true],
              "Please mark this step complete"
            ),

          })}>
            <Implementation isDisabled={checkIsDisabledImplementation()} />
          </FormikStep>)}

      </FormikStepper>
      <CustomModal
        open={modalData.show}
        modalHeader={modalData.modalHeader}
        modalContent={modalData.modalContent}
        modalAction={modalData.modalActions}
      />
    </>
  );
}
export default FormikContainer;

// formik container code end
