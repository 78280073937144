import { makeStyles } from '@mui/styles';

const progressStyles = makeStyles({
  stage: {
    // padding: '4px',
    width: '15px',
    height: '15px',
    background: '#00B5B1',
    borderRadius: '30px',
    margin: '0px 10px',
    textAlign: 'center',
    display: 'inline-flex',
    justifyContent: 'center',
    position: 'relative',
    color: '#fff',
    font: 'normal normal normal 8px/13px Poppins-Regular',
    letterSpacing: '-0.04px',
    alignItems: 'center',
    '&::before': {
      content: '""',
      width: '10px',
      border: '1px solid #00B5B1',
      margin: 'auto',
      left: '-19px',
      position: 'absolute',
      top: '6px',
    },
    '&::after': {
      content: '""',
      width: '10px',
      border: '1px solid #00B5B1',
      margin: 'auto',
      right: '-19px',
      position: 'absolute',
      top: '6px',
    },
    '&:first-child': {
      marginLeft: '0px',
      '&::before': {
        display: 'none',
      },
    },
    '&:last-child': {
      marginRight: '0px',
      '&::after': {
        display: 'none',
      },
    },
  },
  pendingStage: {
    background: '#D9D9D9',
    '&::before': {
      borderColor: '#D9D9D9',
    },
    '&::after': {
      borderColor: '#D9D9D9',
    },
  },
  currentStage: {
    background: '#D9D9D9',
    '&::after': {
      borderColor: '#D9D9D9',
    },
  },
});

function StatusProgress({
  stagesArray,
  currentStage,
}: {
  stagesArray:
  {
    index: number,
    label: string,
  }[],
  currentStage: any,
}) {
  const progessClasses = progressStyles();
  return (
    <div style={{ display: 'flex' }} data-testid="status-progress">
      {stagesArray.map((stage: { index: number, label: string }) => (
        <span
          className={`${progessClasses.stage}
          ${stage.index > parseInt(currentStage) && progessClasses.pendingStage}
          ${stage.index === parseInt(currentStage) && progessClasses.currentStage}`}
          key={stage.index}
        >
          {stage.label}
        </span>
      ))}
    </div>
  );
}
export default StatusProgress;
