
import {
  FormikValues,
} from 'formik';

export interface IFormikContainerProps {
  activeStep: number;
  setActiveStep: (_step: any) => void;
  requestId: string;
  data: any;
  path: any;
}

export interface FormikStepProps
  extends Pick<FormikValues, 'children' | 'validationSchema'> {
}
export interface IModalState {
  show: boolean,
  modalHeader: string,
  modalContent: React.ReactElement | null,
  modalActions: React.ReactElement | null,
  rejectStatus?: boolean,
  remediationStatus?: boolean,
  approveStatus?: boolean
}

export const stepsData = [
  { label: 'SAR Reason' },
  { label: 'Requestor & Team Details' },
  { label: 'Service Account Details' },
  { label: 'Account Type' },
  { label: 'Server Information' },
  { label: 'Service Account Premissions' },
  { label: 'SPN & Delegation Details' },
  { label: 'Password Security & Policy' },
];

export const stepsImplemtaionDataSar = [
  { label: 'SAR Reason' },
  { label: 'Requestor & Team Details' },
  { label: 'Service Account Details' },
  { label: 'Account Type' },
  { label: 'Server Information' },
  { label: 'Service Account Premissions' },
  { label: 'SPN & Delegation Details' },
  { label: 'Password Security & Policy' },
  { label: 'Implementation' },
];

export const emptySchemaCheck = new Map([
  ['sar_function_of_sar', 'Text update'],
  ['sar_justification_of_sar', 'Text update'],
  ['first_name', 'Text update'],
  ['last_name', 'Text update'],
  ['email', 'Text update'],
  ['phone', 'Text update'],
  ['company', 'Text update'],
  ['city', 'Text update'],
  ['country', 'Text update'],
  ['sar_department', 'Text update'],
  ['sar_office', 'Text update'],
  ['sar_third_party_company', 'Text update'],
  ['sar_not_listed_third_party_company', 'Text update'],
  ['manager_first_name', 'Text update'],
  ['manager_last_name', 'Text update'],
  ['manager_phone', 'Text update'],
  ['manager_email', 'Text update'],
  ['team_name', 'Text update'],
  ['sar_point_of_escalation', 'Text update'],
  ['sar_third_party_manager_first_name', 'Text update'],
  ['sar_third_party_manager_last_name', 'Text update'],
  ['sar_third_party_manager_email', 'Text update'],
  ['sar_third_party_manager_phone', 'Text update'],
  ['sar_city_code', 'Selection'],
  ['sar_company_code', 'Selection'],
  ['sar_application_name', 'Selection'],
  ['sar_domain_location', 'Selection'],
  ['sar_user_logon_name_upn', 'Text update'],
  ['sar_pre_windows_2000_username', 'Text update'],
  ['sar_is_complex_service_account', 'Selection'],
  ['sar_does_require_interactive_logon', 'Selection'],
  ['sar_justification_for_interactive_logon', 'Selection'],
  ['sar_logon_as_service', 'Selection'],
  ['sar_logon_as_batch_job', 'Selection'],
  ['sar_function_other', 'Text update'],
  ['sar_justification', 'Text update'],
  ['sar_is_sar_servers_usage', 'Selection'],
  ['sar_servers_hostname', 'Text update'],
  ['sar_server_tier', 'Selection'],
  ['sar_user_rights_and_privileges', 'Text update'],
  ['sar_group_membership', ' Text update'],
  ['sar_external_internal_ip_range', ' Selection'],
  ['sar_external_internal_ip_details', 'Text update'],
  ['sar_spn_and_or_delegation', 'Selection'],
  ['sar_spn_list', 'Text update'],
  ['sar_delegation_details', 'Text update'],
  ['sar_is_any_service_kerberos_only', 'Selection'],
  ['sar_is_specified_service_kerberos_only', 'Selection'],
  ['sar_is_specified_service_any_protocol', 'Selection'],
  ['sar_delegation_reason', 'Text update'],
  ['sar_password_change_procedure', 'Text update'],
  ['sar_security_of_password', 'Text update'],
  ['sar_password_reset_timing', 'Selection'],
  ['sar_reason_for_password_expiry_policy', 'Text update'],
  ['sar_business_impact', 'Text update'],
  ['sar_risk_exposure', 'Selection'],
  ['sar_risk_of_sar_non_password_expiring', 'Text update'],
  ['sar_risk_if_sar_compromised', 'Text update']
])

export const fieldsOfSection = new Map([
  ['sar_function_of_sar', 'Function'],
  ['sar_justification_of_sar', 'Justification'],
  ['first_name', 'Requestor_details'],
  ['last_name', 'Requestor_details'],
  ['email', 'Requestor_details'],
  ['phone', 'Requestor_details'],
  ['company', 'Requestor_details'],
  ['city', 'Requestor_details'],
  ['country', 'Requestor_details'],
  ['sar_department', 'Requestor_details'],
  ['sar_office', 'Requestor_details'],
  ['sar_third_party_company', 'Requestor_details'],
  ['sar_not_listed_third_party_company', 'Requestor_details'],
  ['manager_first_name', 'Team_details'],
  ['manager_last_name', 'Team_details'],
  ['manager_phone', 'Team_details'],
  ['manager_email', 'Team_details'],
  ['team_name', 'Team_details'],
  ['sar_point_of_escalation', 'Team_details'],
  ['sar_third_party_manager_first_name', 'Team_details'],
  ['sar_third_party_manager_last_name', 'Team_details'],
  ['sar_third_party_manager_email', 'Team_details'],
  ['sar_third_party_manager_phone', 'Team_details'],
  ['sar_city_code', 'Service_account_details'],
  ['sar_company_code', 'Service_account_details'],
  ['sar_application_name', 'Service_account_details'],
  ['sar_domain_location', 'Select_domain'],
  ['sar_user_logon_name_upn', 'Service_account_name'],
  ['sar_pre_windows_2000_username', 'Service_account_name'],
  ['sar_is_complex_service_account', 'Choose_account_type'],
  ['sar_does_require_interactive_logon', 'Require_interactive_logon'],
  ['sar_justification_for_interactive_logon', 'Require_interactive_logon'],
  ['sar_logon_as_service', 'Choose_service_account_function'],
  ['sar_logon_as_batch_job', 'Choose_service_account_function'],
  ['sar_function_other', 'Choose_service_account_function'],
  ['sar_justification', 'Account_function_justification'],
  ['sar_is_sar_servers_usage', 'Is_single_server'],
  ['sar_servers_hostname', 'Server_hostnames'],
  ['sar_server_tier', 'Choose_server_tier'],
  ['sar_user_rights_and_privileges', 'User_rights_and_privileges'],
  ['sar_group_membership', ' Group_membership'],
  ['sar_external_internal_ip_range', ' External_And_Internal_IP_Details'],
  ['sar_external_internal_ip_details', ' External_And_Internal_IP_Details'],
  ['sar_spn_and_or_delegation', 'Service_account_request_spn_dele'],
  ['sar_spn_list', 'SPN_list'],
  ['sar_delegation_details', 'required_delegtion'],
  ['sar_is_any_service_kerberos_only', 'required_delegtion'],
  ['sar_is_specified_service_kerberos_only', 'required_delegtion'],
  ['sar_is_specified_service_any_protocol', 'required_delegtion'],
  ['sar_delegation_reason', 'Delegation_reason'],
  ['sar_password_change_procedure', ' Password_change_procedures'],
  ['sar_security_of_password', 'Security_of_passwords'],
  ['sar_password_reset_timing', 'Password_will_be_changed'],
  ['sar_reason_for_password_expiry_policy', 'Default_password_expiry_policy'],
  ['sar_business_impact', 'Business_impact'],
  ['sar_risk_exposure', 'Risk_exposure'],
  ['sar_risk_of_sar_non_password_expiring', 'Risk_of_the_service_account'],
  ['sar_risk_if_sar_compromised', 'Account_was_compromised'],
])

export const fieldsOfSectionForSubsection = new Map([
  ['sar_function_of_sar', ''],
  ['sar_justification_of_sar', ''],
  ['first_name', 'First Name'],
  ['last_name', 'Last Name'],
  ['email', 'Email'],
  ['phone', 'Phone'],
  ['company', 'Company'],
  ['city', 'City'],
  ['country', 'Country'],
  ['sar_department', 'Department'],
  ['sar_office', 'Office'],
  ['sar_third_party_company', '3rd party company'],
  ['sar_not_listed_third_party_company', 'Company name'],
  ['manager_first_name', 'Manager first name'],
  ['manager_last_name', 'Manager last name'],
  ['manager_phone', 'Manager Phone'],
  ['manager_email', 'Manager email'],
  ['team_name', 'Team Name'],
  ['sar_point_of_escalation', 'Point of escalation'],
  ['sar_third_party_manager_first_name', '3rd Party manager first name'],
  ['sar_third_party_manager_last_name', '3rd Party manager last name'],
  ['sar_third_party_manager_email', '3rd Party manager email'],
  ['sar_third_party_manager_phone', '3rd Party manager phone'],
  ['sar_city_code', 'City Code'],
  ['sar_company_code', 'Company code'],
  ['sar_application_name', 'Application name'],
  ['sar_domain_location', ''],
  ['sar_user_logon_name_upn', 'User login name (UPN)'],
  ['sar_pre_windows_2000_username', 'Pre-windows 2000 user name'],
  ['sar_is_complex_service_account', 'Choose account type'],
  ['sar_does_require_interactive_logon', ''],
  ['sar_justification_for_interactive_logon', ''],
  ['sar_logon_as_service', 'logon as service'],
  ['sar_logon_as_batch_job', 'logon as batch job'],
  ['sar_function_other', 'Additional function'],
  ['sar_justification', ''],
  ['sar_is_sar_servers_usage', ''],
  ['sar_servers_hostname', ''],
  ['sar_server_tier', ''],
  ['sar_user_rights_and_privileges', ''],
  ['sar_group_membership', ''],
  ['sar_external_internal_ip_range', ''],
  ['sar_external_internal_ip_details', ''],
  ['sar_spn_and_or_delegation', ''],
  ['sar_spn_list', ''],
  ['sar_delegation_details', ''],
  ['sar_is_any_service_kerberos_only', ''],
  ['sar_is_specified_service_kerberos_only', ''],
  ['sar_is_specified_service_any_protocol', ''],
  ['sar_delegation_reason', ''],
  ['sar_password_change_procedure', ' '],
  ['sar_security_of_password', ''],
  ['sar_password_reset_timing', ''],
  ['sar_reason_for_password_expiry_policy', ''],
  ['sar_business_impact', ''],
  ['sar_risk_exposure', ''],
  ['sar_risk_of_sar_non_password_expiring', ''],
  ['sar_risk_if_sar_compromised', ''],
])

export const staticDiffernceData = [
  "request_id",
  "event",
  "action_owner",
  "request_type",
  "access_review_period",
  "completion_date",
  "dataverse",
  "completion_date",
  "is_implemented",
  "is_request_to_update",
  "finalCheck",
  "review_comments_first_step",
  "mark_step_secondstep",
  "oktaCompany",
  "oktaPhone",
  "oktaCity",
  "oktaCountry",
  "oktaJobTitle",
  "oktaDepartment",
  "del_reason_for_raising_new_request",
  "del_other",
  "del_please_enter_role_name",
  "del_job_title",
  "del_job_explanation",
  "del_justification_for_access",
  "del_delegation_account_type_id",
  "del_role_name_list",
  "del_user_detail_list",
  "del_custom_request",
  "del_short_term_long_term_contract_number",
  "del_short_term_long_term_contract_details",
  "del_short_term_long_term_implementation_plan",
  "del_short_term_long_term_access_request",
  "del_short_term_long_term_access_request",
  "del_start_date",
  "del_end_date",
  "del_date",
  "del_is_thirdparty_request_service",
  "not_listed_third_party_company",
  "third_party_company",
  "sar_delegation_list",
  "sar_is_default_password_expiry_policy",
  "Justification",
  'sar_server_tier',
  "attachment",
  //  for implentation task list
  "sar_on_premise_id",
  "is_step_one_completed",
  "proposed_role_group_name",
  "is_step_two_completed",
  "proposed_delegation_role_group_name",
  "proposed_target_group_name",
  "proposed_gpo_name",
  "is_delegation_gpo_target_completed",
  "is_delegation_info_completed",
  "complete_manage_by_section",
  "complete_other_section",
  "is_step_three_completed",
  "is_service_account_require_access",
  "review_delegation_form_to_determine_scope",
  "is_step_four_completed",
  "is_target_group_name_completed",
  "is_target_group_delegation_info_completed",
  "is_target_group_complete_other_section",
  "is_target_group_complete_manage_by_section",
  "is_step_five_completed",
  "copy_target_group_name",
  "add_members_to_target_group",
  "is_step_six_completed",
  "is_group_policy_gpo_name_completed",
  "is_group_policy_role_group_completed",
  "is_select_the_delegation",
  "is_step_seven_completed",
  "is_assignment_to_gpo_completed",
  "is_step_eight_completed",
  "is_implemented",
  "mark_step_firststep"
]

export interface IFormikContextPar {
  del_request_id: string,
  status: string,
  request_type: string,
  request_purpose: string,
  event: string,
  next_priority: null,
  request_id: string,
  par_requestor_first_name: string,
  par_requestor_last_name: string,
  par_requestor_email: string,
  par_date_raised: string,
  par_first_name: string,
  par_last_name: string,
  par_department: string,
  par_company: string,
  par_city: string,
  par_email: string,
  par_telephone: string,
  par_job_title: string,
  par_job_title_explanation: string,
  par_internal_manager_name: string,
  par_is_third_party_office: string,
  par_is_third_party_company: string,
  par_third_party_manager_first_name: string,
  par_third_party_manager_last_name: string,
  par_third_party_manager_telephone: string,
  par_third_party_manager_email: string,
  par_point_of_escalation_in_third_party: string,
  par_servicenow_ticket_number: string,
  par_domain: string,
  par_account_justification: string,
  par_account_name: string,
  par_user_logon_name: string,
  par_pre_windows_2000_username: string,
  delegation_id: null
}