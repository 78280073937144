/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import Button from '@mui/material/Button';
import { ButtonProps } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface StyledButtonProps extends ButtonProps {
  actionType: 'primary' | 'secondary' | 'tertiary' | '';
  disablePadding?: boolean;
  disableMargin?: boolean;
  isCompact?: boolean;
  isAllCaps?: boolean;
  disabled?: boolean;
}

const buttonStyles = makeStyles({
  root: {
    borderRadius: '25px !important',
    boxShadow: 'none !important',
    fontFamily: 'Poppins-Semibold !important',
    textTransform: (props: StyledButtonProps) => (props.isAllCaps ? 'uppercase' : 'none'),
    fontSize: (props: StyledButtonProps) => (props.isCompact ? '12px !important' : '14px !important'),
    margin: (props: StyledButtonProps) => (props.disableMargin ? '0px !important' : '0px 8px !important'),
    padding: (props: StyledButtonProps) => (props.disablePadding ? '0px !important' : '4px 16px !important'),
    backgroundColor: (props: StyledButtonProps) => (
      props.actionType === 'primary'
        ? '#00B5B1 !important'
        : '#fff !important'
    ),
    border: (props: StyledButtonProps) => (
      props.actionType === 'primary' || props.actionType === 'secondary'
        ? '2px solid #00B5B1 !important'
        : 'none'
    ),
    color: (props: StyledButtonProps) => (
      props.actionType === 'primary'
        ? '#fff !important'
        : '#00B5B1 !important'
    ),
    '&:hover': {
      backgroundColor: (props: StyledButtonProps) => {
        switch (props.actionType) {
          case 'tertiary': return '#fff !important';
          case 'secondary': return '#00B5B1 !important';
          case 'primary': return '#00A5A2 !important';
          default: return '';
        }
      },
      color: (props: StyledButtonProps) => {
        switch (props.actionType) {
          case 'tertiary': return '#FFA800 !important';
          case 'secondary': return '#fff !important';
          default: return 'inherit';
        }
      },
      borderColor: (props: StyledButtonProps) => {
        if (props.actionType === 'primary') return '#00A5A2 !important';
        return 'inherit';
      },
    },
    '&:disabled': {
      background: (props: StyledButtonProps) => {
        switch (props.actionType) {
          case 'tertiary':
          case 'secondary': return '#fff !important';
          case 'primary': return '#D9D9D9 !important';
          default: return '';
        }
      },
      borderRadius: '23px !important',
      opacity: '0.98 !important',
      borderColor: '#D9D9D9 !important',
      color: (props: StyledButtonProps) => {
        switch (props.actionType) {
          case 'tertiary':
          case 'secondary': return '#d9d9d9 !important';
          case 'primary': return '#fff !important';
          default: return '';
        }
      },
    },

  },
});

function CustomButton(
  {
    actionType,
    disablePadding,
    disableMargin,
    isCompact,
    isAllCaps,
    children,
    disabled,
    ...rest
  }: StyledButtonProps,
) {
  const styleProps = {
    actionType,
    disableMargin,
    disablePadding,
    isCompact,
    isAllCaps,
  };
  const classes = buttonStyles(styleProps);
  return (
    <Button
      data-testid="custom-button"
      variant="contained"
      className={classes.root}
      {...rest}
      style={{ textTransform: 'none' }}
      disabled={disabled}
    >
      {children}
    </Button>
  );
}

export default CustomButton;
