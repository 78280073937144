import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import Grid from "@mui/material/Grid";
import FormikControl from "../../../formik-conrtols/FormikControl";
import FieldLabel from "../../../field-label";
import { parImplementationStyles } from "../../useStyles";
import Typography from "@mui/material/Typography";
import { Padding } from "@mui/icons-material";
import { useSelector } from 'react-redux';
import { RootState } from '../../../../appStore/store';
import { isEmpty } from 'lodash';
// import { getPostOnpremAad } from '../../../../api-services/par';


function AccountCreation({ isDisabled }: { isDisabled: boolean }) {
  const formikCtx: any = useFormikContext();
  const classes = parImplementationStyles();
  const { aadImplementationOnpremList } = useSelector((state: RootState) => state);
  useEffect(() => {
    if (!isEmpty(aadImplementationOnpremList[0])) {
      formikCtx.setFieldValue('provide_service_number_aad_onPrem', aadImplementationOnpremList[0].provide_service_number)
      formikCtx.setFieldValue('mark_step_aad_onPrem', aadImplementationOnpremList[0].is_step_complete)
    }
  }, [])

  return (
    <>
      <Grid container spacing={2} className={classes.gridContainer}>
        <Grid item xs={5}>
          <FieldLabel Title="Implementation task list for account set up" />
        </Grid>
        <div style={{ paddingLeft: '12px' }}>
          <Grid container className={classes.containerBorder}>
            <div style={{ paddingLeft: '32px', paddingRight: '8px' }}>
              <Grid item xs={12} className={classes.paddingT}>
                <Typography className={classes.title}>
                  <div>ServiceNow Details</div>
                  <span className={classes.astrik}>*</span>
                </Typography>
              </Grid>
              {!(formikCtx.values.par_domain === 'Hybrid (AD/Azure)') && <Grid item xs={12} className={classes.paddingT}>
                <Typography className={classes.title}>
                  <div>
                    Raise a ticket in ServiceNow for <strong>Creation of an Azure Active Directory Account.</strong>This ticket should be assigned to resolver group IBM-SW-Messaging O365. The request must include the Privileged Access Request ID in the ticket.
                  </div>
                </Typography>
              </Grid>}
              <Grid item xs={12} className={classes.paddingT}>
                <Typography className={classes.title}>
                  <div>
                    Raise a ticket in ServiceNow for <strong>Assign permisssions / licence to an Azure Active Directory Account.</strong> This ticket should be assigned to resolver group <strong>IBM-SW-Messaging O365.</strong> The request must include the Privileged Access Request ID in the ticket.
                  </div>
                </Typography>
              </Grid>

              <Grid item xs={4} className={classes.paddingT}>
                <Typography className={classes.serviceTitle}>
                  <div>Provide ServiceNow ticket number</div>
                </Typography>
                <div className={classes.fieldTopPadding}>
                  <FormikControl
                    control="textfield"
                    name="provide_service_number_aad_onPrem"
                    label="ServiceNow INC number"
                    disabled={isDisabled}
                  />
                  <span className={classes.subTitle}>Eg; INC323567888</span>

                </div>
              </Grid>

              <Grid item xs={12} className={classes.markStep}>
                <FormikControl
                  control="checkbox"
                  label="Mark this step complete"
                  name="mark_step_aad_onPrem"
                  required
                  className={classes.checkboxLabel}
                  disabled={isDisabled}
                />
              </Grid>
            </div>
          </Grid>
        </div>
      </Grid >
    </>
  );
}

export default AccountCreation;
