import { FormikValues } from "formik";

export interface IFormikContainerProps {
  activeStep: number;
  setActiveStep: (_step: any) => void;
  requestId: string;
  data: any;
  path: any;
}

export interface FormikStepProps
  extends Pick<FormikValues, "children" | "validationSchema"> { }

export interface IModalState {
  show: boolean;
  modalHeader: string;
  modalContent: React.ReactElement | null;
  modalActions: React.ReactElement | null;
}

export const stepsData = [
  { label: "Delegation Reason" },
  { label: "Requestor & Team Details" },
  { label: "Requestor Job Details" },
  { label: "Account Type" },
  { label: "Role Definition" },
  { label: "User Details" },
  { label: "Custom & 3rd Party Request" },
  { label: "Delegation Owner Sign-off" },
];

export const emptySchemaCheck = new Map([
  ['del_reason_for_raising_new_request', 'Select update'],
  ['del_other', 'Text update'],
  ['del_justification_for_access', 'Text update'],
  ['team_name', 'Selection'],
  ['manager_first_name', 'Text update'],
  ['manager_last_name', 'Text update'],
  ['manager_email', 'Text update'],
  ['manager_phone', 'Text update'],
  ['country', 'Selection update'],
  ['city', 'Selection'],
  ['del_job_title', 'Text update'],
  ['del_job_explanation', 'Text update'],
  ['del_delegation_account_type_id', 'Select update'],
  ['role_name', 'Text update'],
  ['does_servicenow_require', 'Text update'],
  ['del_custom_request', 'Text update'],
  ['del_is_thirdparty_request_service', 'Text update'],
  ['del_short_term_long_term_contract_number', 'Selection'],
  ['del_short_term_long_term_implementation_plan', 'Text update'],
  ['del_short_term_long_term_access_request', 'Selection'],
])

export const fieldsOfSection = new Map([
  ['del_reason_for_raising_new_request', 'Reason_for_Delegation_request'],
  ['del_other', 'Reason_for_Delegation_request'],
  ['del_justification_for_access', 'Justification_for_access'],
  ['team_name', 'Team_details'],
  ['manager_first_name', 'Team_details'],
  ['manager_last_name', 'Team_details'],
  ['manager_email', 'Team_details'],
  ['manager_phone', 'Team_details'],
  ['country', 'Team_details'],
  ['city', 'Team_details'],
  ['del_job_title', 'Job_title_and_description'],
  ['del_job_explanation', 'Job_title_and_description'],
  ['del_delegation_account_type_id', 'Choose_account_type'],
  ['role_name', 'Role_definition'],
  ['scope', 'Select update'],
  ['geographical_scope', 'Role_definition'],
  ['regional_scope_label', 'Role_definition'],
  ['market_scope_label', 'Role_definition'],
  ['city_scope_label', 'Role_definition'],
  ['country_scope_label', 'Role_definition'],
  ['justification', 'Role_definition'],
  ['number_of_slots', 'Role_definition'],
  ['justification_for_slots', 'Role_definition'],
  ['is_role_for_thirdparty_user', 'Selection'],
  ['user_first_name', ' User_details'],
  ['user_last_name', ' User_details'],
  ['email', ' User_details'],
  ['role_name', ' User_details'],
  ['admin_account_name', ' User_details'],
  ['domain', ' User_details'],
  ['par_form_fill_access_to_user', ' User_details'],
  ['does_servicenow_require', 'Role_definition'],
  ['del_custom_request', 'Provide_custom_request'],
  ['del_is_thirdparty_request_service', ' Third_Party_Request_For_Service_in_place'],
  ['del_short_term_long_term_contract_number', 'Contract_number_or_Request_For_Service_reference'],
  ['del_short_term_long_term_implementation_plan', 'Implementation_plan'],
  ['del_short_term_long_term_access_request', 'Selection'],
])

export const fieldsOfSectionForSubsection = new Map([
  ['del_reason_for_raising_new_request', ''],
  ['del_other', ''],
  ['del_justification_for_access', ''],
  ['team_name', 'Team Name'],
  ['manager_first_name', 'Manager first name'],
  ['manager_last_name', 'Manager last name'],
  ['manager_email', 'Manager email'],
  ['manager_phone', 'Manager phone'],
  ['country', 'Country'],
  ['city', 'City'],
  ['del_job_title', 'Job title'],
  ['del_job_explanation', 'Job description'],
  ['del_delegation_account_type_id', 'Choose account type'],
  ['role_name', 'Role name'],
  ['scope', 'Scope'],
  ['geographical_scope', 'Geographical scope'],
  ['regional_scope_label', 'Regional scope'],
  ['market_scope_label', 'Market scope'],
  ['city_scope_label', 'City scope'],
  ['country_scope_label', 'country scope'],
  ['justification', 'Justification'],
  ['number_of_slots', 'Number of slots'],
  ['justification_for_slots', 'Justification for slots'],
  ['is_role_for_thirdparty_user', 'Is role for thirdparty user'],
  ['user_first_name', 'User first name'],
  ['user_last_name', 'User last name'],
  ['email', ' email'],
  ['role_name', ' Role name'],
  ['admin_account_name', ' Admin account name'],
  ['domain', ' Domain'],
  ['par_form_fill_access_to_user', 'Par form fill access to user'],
  ['does_servicenow_require', 'Service now require'],
  ['del_custom_request', 'Custom request'],
  ['del_is_thirdparty_request_service', 'Third party request service'],
  ['del_short_term_long_term_contract_number', 'Short term long term contract number'],
  ['del_short_term_long_term_implementation_plan', 'Short term long term implementation plan'],
  ['del_short_term_long_term_access_request', 'Short term long term access request'],
])

export const staticDiffernceData = [
  "attachment",
  "company",
  "del_date",
  "sar_delegation_details",
  "request_id",
  "event",
  "request_type",
  "not_listed_third_party_company",
  "third_party_company",
  "del_role_name_list",
  "del_user_detail_list",
  "sar_function_of_sar",
  "sar_justification_of_sar",
  "sar_city_code",
  "sar_company_code",
  "sar_application_name",
  "sar_user_logon_name_upn",
  "sar_pre_windows_2000_username",
  "sar_is_complex_service_account",
  "sar_domain_location",
  "sar_logon_as_service",
  "sar_logon_as_batch_job",
  "sar_function_other",
  "sar_justification",
  "sar_is_sar_servers_usage",
  "sar_servers_hostname",
  "sar_server_tier",
  "sar_user_rights_and_privileges",
  "sar_group_membership",
  "sar_external_internal_ip_range",
  "sar_external_internal_ip_details",
  "sar_spn_and_or_delegation",
  "sar_spn_list",
  "sar_is_any_service_kerberos_only",
  "sar_is_specified_service_kerberos_only",
  "sar_is_specified_service_any_protocol",
  "sar_delegation_reason",
  "sar_delegation_list",
  "sar_password_change_procedure",
  "sar_security_of_password",
  "sar_does_require_interactive_logon",
  "sar_justification_for_interactive_logon",
  "sar_password_reset_timing",
  "sar_is_default_password_expiry_policy",
  "sar_reason_for_password_expiry_policy",
  "sar_business_impact",
  "sar_risk_exposure",
  "sar_risk_of_sar_non_password_expiring",
  "sar_risk_if_sar_compromised",
  "sar_department",
  "sar_office",
  "sar_third_party_company",
  "sar_not_listed_third_party_company",
  "sar_third_party_manager_first_name",
  "sar_third_party_manager_last_name",
  "sar_third_party_manager_email",
  "sar_third_party_manager_phone",
  "sar_point_of_escalation",
  "finalCheck",
  "review_comments_first_step",
  "mark_step_secondstep",
  "dataverse",
   //  for implentation task list
  "sar_on_premise_id",
  "is_step_one_completed",
  "proposed_role_group_name",
  "is_step_two_completed",
  "proposed_delegation_role_group_name",
  "proposed_target_group_name",
  "proposed_gpo_name",
  "is_delegation_gpo_target_completed",
  "is_delegation_info_completed",
  "complete_manage_by_section",
  "complete_other_section",
  "is_step_three_completed",
  "is_service_account_require_access",
  "review_delegation_form_to_determine_scope",
  "is_step_four_completed",
  "is_target_group_name_completed",
  "is_target_group_delegation_info_completed",
  "is_target_group_complete_other_section",
  "is_target_group_complete_manage_by_section",
  "is_step_five_completed",
  "copy_target_group_name",
  "add_members_to_target_group",
  "is_step_six_completed",
  "is_group_policy_gpo_name_completed",
  "is_group_policy_role_group_completed",
  "is_select_the_delegation",
  "is_step_seven_completed",
  "is_assignment_to_gpo_completed",
  "is_step_eight_completed",
  "is_implemented",
  "mark_step_firststep",
]

export const emptySchemaCheckForRoleDefinition = new Map([
  ['role_name', 'Text Update'],
  ['scope', 'Select update'],
  ['geographical_scope', 'Text update'],
  ['regional_scope_label', 'Text update'],
  ['regional_scope', 'Text update'],
  ['market_scope_label', 'Text update'],
  ['market_scope', 'Text update'],
  ['city_scope_label', 'Text update'],
  ['city_scope', 'Text update'],
  ['country_scope_label', 'Text update'],
  ['country_scope', 'Text update'],
  ['justification', 'Text update'],
  ['number_of_slots', 'Text update'],
  ['justification_for_slots', 'Text update'],
  ['is_role_for_thirdparty_user', 'Select update'],
  ['does_servicenow_require', 'Select update']
])
// export const fieldsOfSectionOfRoleDefinition = new Map([
//   ['scope', 'Role_definition'],
//   ['geographical_scope', 'Role_definition'],
//   ['regional_scope_label', 'Role_definition'],
//   ['market_scope_label', 'Role_definition'],
//   ['city_scope_label', 'Role_definition'],
//   ['country_scope_label', 'Role_definition'],
//   ['justification', 'Role_definition'],
//   ['number_of_slots', 'Role_definition'],
//   ['justification_for_slots', 'Role_definition'],
//   ['is_role_for_thirdparty_user', 'Role_definition'],
//   ['does_servicenow_require', 'Role_definition']
// ])

export const emptySchemaCheckForUserDetails = new Map([
  ['user_first_name', 'Text update'],
  ['user_last_name', 'Text update'],
  ['email', 'Text update'],
  ['role_name', 'Select update'],
  ['admin_account_name', 'Text update'],
  ['domain', 'Select update'],
  ['par_form_fill_access_to_user', 'Select update'],
])
// export const fieldsOfSectionOfUserDetails = new Map([
//   ['user_first_name', 'User_details'],
//   ['user_last_name', 'User_details'],
//   ['email', 'User_details'],
//   ['role_name', 'User_details'],
//   ['admin_account_name', 'User_details'],
//   ['domain', 'User_details'],
//   ['par_form_fill_access_to_user', 'User_details'],
// ])