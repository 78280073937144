
import React from 'react'
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import Skeleton from "@mui/material/Skeleton";
import { Box, Collapse } from "@mui/material";
import Loader from '../loader';

const tableHeadStyle = makeStyles({
  head: {
    background: "#DFEFFF",
  },
  cell: {
    color: "#0A2756 !important",
    fontFamily: 'Poppins-Medium !important',
    fontSize: '14px',
    letterSpacing: "0px !important",
    padding: "12px 16px !important",
  },
  activeHeadCell: {
    color: "#0A2756 !important",
  },
  pointer: {
    cursor: "pointer",
  },
  span: {
    display: "flex",
    alignItems: "center",
    pointerEvents: "none",
  },
});

const tableRowStyle = makeStyles({
  cell: {
    fontFamily: 'NotoSerif-Regular !important',
    fontSize: '14px',
    letterSpacing: "0px !important",
    color: "#0A2756",
    height: "40px",
  },
  // DisableRow: {
  //   font: "normal normal normal 14px/19px NotoSerif-Regular !important",
  //   letterSpacing: "0px !important",
  //   color: "#B9C3CE",
  //   height: "40px",
  //   pointerEvents: 'none'
  //   // borderBottom: "none",
  // },
});

const tableLoaderStyle = makeStyles({
  container: {
    height: 'calc(100vh - 20vh) !important',
    width: "100% !important",
    display: "flex !important",
    flexWrap: "wrap",
  },
  skeleton: {
    flex: "0 0 100% !important",
    transform: "none !important",
    backgroundColor: "#EDF4F8 !important",
  },
});

// interface IInputPropsDynamicTbale {
//   collapsible?: boolean;
// }

interface IColumns {
  key: number;
  label: string;
  icon?: any;
  handleClick?: (_e: any) => void;
  id: string;
  isActive: boolean;
  style?: React.CSSProperties;
}

export default function DynamicTable({
  rows,
  isLoading,
  columns,
  children,
  collapsible,
  rowId,
  pageNumber,
  pageSize,
}: {
  rows: any;
  isLoading: boolean;
  columns: IColumns[];
  children?: any;
  collapsible?: boolean;
  rowId?: any;
  pageNumber: number;
  pageSize: number;
}) {

  const tableRowStart = (pageNumber - 1) * pageSize;
  const tableRowEnd = tableRowStart + pageSize;
  const updatedData = rows.slice(tableRowStart, tableRowEnd);

  const tableHeadClasses = tableHeadStyle();
  const tableRowClasses = tableRowStyle();
  const tableLoaderClasses = tableLoaderStyle();
  return (
    <TableContainer
      data-testid="tableContainerElement"
      style={{
        overflow: 'auto'
      }}
    >

      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead classes={{ root: tableHeadClasses.head }}>
          <TableRow sx={{ "&:first-of-type(th)": { border: 0 } }}>
            {columns.map((column: IColumns) => (
              <TableCell
                key={column.key}
                onClick={column.handleClick}
                id={column.id}
                style={column.style}
                classes={{
                  root: `${tableHeadClasses.cell} ${column.handleClick ? tableHeadClasses.pointer : ""
                    } ${column.isActive ? tableHeadClasses.activeHeadCell : ""}`,
                }}
                data-testid="tableCell"
              >
                <span className={tableHeadClasses.span}>
                  {column.label}
                  &nbsp;
                  {column.icon || ""}
                </span>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {updatedData.length || isLoading ? (
          // <div style=>
          <TableBody key="table-body" data-testid="tableBody" >
            {isLoading && (
              <TableRow data-testid="tableRow">
                <TableCell colSpan={columns.length} sx={{ padding: "6px 0px", }}>
                  <div className={tableLoaderClasses.container}>
                    <Box >
                      <Loader openModal={true} />
                    </Box>
                    {/* <div
                      animation="wave"
                      width="100%"
                      className={tableLoaderClasses.skeleton}
                    /> */}

                  </div>

                </TableCell>
              </TableRow>
            )}
            {!isLoading &&
              updatedData?.map((row: any, index: string) => (
                <>
                  <TableRow
                    // key={row.name}
                    key={index}
                    // sx={{ '&:first-child td, &:first-child th': { borderTop: 0 } }}
                    data-testid="rowCount"

                  >
                    {row
                      ?.sort(
                        // eslint-disable-next-line max-len
                        (cellObj1: any, cellObj2: any) =>
                          cellObj1.columnNumber - cellObj2.columnNumber
                      )
                      ?.map((cellObj: any) => (
                        <TableCell
                          id={cellObj.id}
                          onClick={cellObj.handleClick}
                          style={{
                            ...cellObj.style,
                            cursor: cellObj.handleClick ? "pointer" : "default",
                          }}
                          key={`${cellObj.id}${cellObj.columnNumber}`}
                          className={
                            tableRowClasses.cell
                          }
                          data-testid="sortCell"
                        >
                          <div>{cellObj.renderComponent}</div>
                        </TableCell>
                      ))}
                  </TableRow>
                  {collapsible && (
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={columns.length}
                      >
                        <Collapse
                          in={rowId === row[1].value}
                          timeout="auto"
                          unmountOnExit
                        >
                          {console.log("123", row, children)}
                          {children}
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  )}
                </>
              ))}
          </TableBody>
          // </div>
        ) : null}
      </Table>
    </TableContainer >
  );
}
