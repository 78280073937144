import { makeStyles } from '@mui/styles';

const parSummaryStyles = makeStyles({
    pageContainer: {
        padding: '16px 20px 20px',
    },
    filtersContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    filterPanel: {
        display: 'flex',
        alignItems: 'center',
    },
    filterIcon: {
        padding: '16px',
        display: 'inline-flex',
    },
    filterMenu: {
        margin: '0px 6px',
        display: 'inline-flex',
    },
    stickySearchContainer: {
        position: 'sticky',
        top: '0',
        padding: '12px 12px 4px 12px',
        zIndex: '2',
        background: '#fff',
    },
    stickySearch: {
        padding: '0px 8px',
        border: '1px solid grey',
        borderRadius: '25px',
        margin: '0',
    },
    filterOptionsList: {
        margin: '12px 16px',
        maxHeight: '250px',
        overflowY: 'scroll',
    },
    dateRangeToggleContainer: {
        margin: '12px 16px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    dateRangeContainer: {
        margin: '0px 8px',
    },
    dateRangeApplyCta: {
        margin: '4px 16px 12px',
        display: 'flex',
        justifyContent: 'end',
    },
    emptyResultContainer: {
        color: '#0A2756',
        width: '100%',
        height: '425px',
        border: '1px dashed #A7A7A7',
        font: 'normal normal normal 14px/19px NotoSerif-Regular',
        letterSpacing: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    paginatedResultsText: {
        font: 'normal normal normal 13px/20px Poppins-Regular',
        letterSpacing: '0px',
        color: '#0A2756',
        display: 'inline-block',
        lineHeight: '26px',
    },
    paginationRoot: {
        display: 'inline-block',
        float: 'right',
    },
    paginationText: {
        color: '#0A2756 !important',
        font: 'normal normal normal 13px/20px Poppins-Regular !important',
        borderRadius: '3px !important',
        margin: '0px 2px !important',
        '&:hover': {
            background: '#EEF2F3 !important',
        },
    },
    paginationSelected: {
        background: '#fff !important',
        border: '1px solid #FFA800 !important',
    },
    modalContentDiv: {
        marginBottom: '12px',
        color: '#0A2756',
        font: 'normal normal normal 14px/21px Poppins-Medium',
    },
    modalTextDiv: {
        maxWidth: '52ch',
        color: '#0A2756',
        font: 'normal normal normal 14px/22px NotoSerif-Regular',
    },
    spanText: {
        fontFamily: 'NotoSerif-Bold',
    },
    updateModalContent: {
        marginBottom: '8px',
        // maxWidth: '85ch',
        color: '#0A2756',
        font: 'normal normal normal 14px/18px Poppins-Medium',
    },
    updateModalNote: {
        marginBottom: '16px',
        maxWidth: '86ch',
        font: 'normal normal normal 14px/22px NotoSerif-Regular',
        color: '#0A2756',
    },
    modalActionDiv: {
        display: 'flex',
        justifyContent: 'right',
    },
    textFieldRoot: {
        width: '100%',
        color: '#A7A7A7',
    },
    textFieldInput: {
        padding: '12px !important',
        font: 'normal normal normal 12px/17px NotoSerif-Regular !important',
        '&>fieldset>legend': {
            fontSize: '1em',
        },
        '&>textarea:first-child': {
            minHeight: '26px !important',
        },
        '&>textarea': {
            color: '#0A2756 !important',
            fontSize: '14px !important',
        },
        '&.Mui-focused > fieldset': {
            border: '1px solid #0080FF !important',
        },
    },
    textFieldLabel: {
        width: '100%',
        font: 'normal normal normal 12px/17px NotoSerif-Regular !important',
        transform: 'translate(12px, 8px) scale(1) !important',
    },
    textFieldLabelShrink: {
        transform: 'translate(14px, -8px) scale(1) !important',
        color: '#0080FF !important',
    },
    pencilIcon: {
        '&:hover > path': {
            fill: '#FFA800 !important',
        },
    },
    moreIcon: {
        '& > path': {
            fill: '#00FFB4 !important',
        },
        '&:hover > path': {
            fill: '#FFA800 !important',
        },
    },
    moreIconActive: {
        fill: '#FFA800 !important',
    },
    moreActionMenu: {
        padding: '8px',
    },
    moreActionMenuItem: {
        font: 'normal normal normal 13px/14px NotoSerif-Regular !important',
        letterSpacing: '0px !important',
        color: '#0A2756 !important',
        '&:hover, &:active, &:focus': {
            background: '#EDF4F8 !important',
        },
    },
    menuLabel: {
        marginLeft: '8px',
    },
});

export default parSummaryStyles;