import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { debounce, isEmpty, update } from 'lodash';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import FormikControl from '../../../formik-conrtols/FormikControl';
import { useDispatch } from 'react-redux';
import FieldLabel from '../../../field-label';
import ReviewActions from '../../../review-actions';
import { format, isDate } from 'date-fns';
import { RootState } from '../../../../appStore/store';
import { historyDate } from '../../../../constants/updatedField';
import { getAllCompany } from '../../../../api-services/delegation-module';
import { chatHistoryData } from '../../../../appStore/reducer/chatHistoryReducer';
import { ReactComponent as PostArrowButton } from '../../../../assets/icons/post_arrow_button.svg';
import {
    getAllCountries,
    getAllCitiesByCountryId,
    searchUserByQueryDetails,
} from '../../../../api-services/common-api';
import { getThirdPartyCompany } from '../../../../api-services/par';
import CommentSubmission from '../../../../modules/par/comments/commentSubmission';
import HistoryTable from '../../../../modules/par/comments/historyTable';
import { parStyles } from '../../useStyles';
import { fetchfieldHistoryForLastUpdate } from '../../../../api-services/review-delegation';
import { fieldUpdationHistory } from '../../../../appStore/reducer/reviewActionReducer';
import HelperTooltip from '../../../helper-tooltip';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';



const accountRequestorTooltip = 'This should either be the person who requested this account, as part of a Delegation, (in which case it will be pre-populated from the Delegation Requestor name) or the person requesting the PAR directly, i.e. for a Test Account (in which case it will be pre-populated with the details of the logged in user from Okta data).'
const accountOwnerDetailsTooltip = `This should be the details of the person who will be using the Privileged Access account. <br /> <b>Note:</b>  This must be an internal WPP named individual. If this request is from a 3rd Party company, this should be the details of the WPP primary contact.`
const jobDescribtiontooltip = `Please detail the function of your role and why <br /> it requires you to have privileged access`

function RequestorAndOwner({ isDisabled }: { isDisabled: boolean }) {
    const dispatch = useDispatch();
    const styleClasses = parStyles();
    const formikCtx: any = useFormikContext();
    const [openState, setOpenState] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [countryList, setCountryList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [thirdPartyCompany, setThirdPartyCompany] = useState([]);
    const [accountOwnerToSearch, setaccountOwnerToSearch] = useState('');
    const [booleanFlag, setBooleanFlag] = useState(false);
    const formatDateInside = (dateValue: any) => (dateValue && isDate(new Date(dateValue)) ? format(new Date(dateValue), 'MMM dd, yyyy') : '');

    const { loginuserdata, loginsyncupdata } = useSelector((state: RootState) => state.loginData);
    const { updatedFieldHistoryData } = useSelector((state: RootState) => state);

    const { submitParCommentsData } = useSelector((state: RootState) => state);
    const { submitParRequestorCommentsData } = useSelector((state: RootState) => state);
    const remediationCount = formikCtx.values.remediation_count;
    const [accountOwnerList, setaccountOwnerList] = useState<{
        first_name: string,
        last_name: string,
        email: string,
        country: string,
        city: string,
        phone: string | number,
        department: string,
        company: string,
        title: string,
    }[]>([]);
    const isAutoCompleteSelected = useRef(false);

    const updatedArray: any = []

    const remediated_by = formikCtx.values.remediation_by
    const searchUserByFirstName = (value: string) => {
        if (value && value !== '') {
            searchUserByQueryDetails(value, '').then((res) => {
                setOpenState(true)
                setaccountOwnerList([...res]);
            }).catch((err: any) => {
                console.log(err);
                setaccountOwnerList([]);
            }).finally(() => {
                setSearchLoading(false);
            });
        }
    };
    const routeCheck = () => {
        return location.pathname.slice(1, 4).toLocaleLowerCase() === 'par'
    }
    const routCheckApprovalSummary = () => {
        return location.pathname.slice(1, 35).toLocaleLowerCase() === 'myapprovaltasks/approvalsummarypar'
    }
    const debouncedSearch = useCallback(
        debounce((inputValue) => {
            searchUserByFirstName(inputValue);
        }, 1500),
        [],
    );
    useEffect(() => {
        debouncedSearch(accountOwnerToSearch);
    }, [accountOwnerToSearch])

    useEffect(() => {
        if (formikCtx.values.par_country !== '' && countryList.length) {
            const selectedCountry: any = countryList
                .find((countryObj: any) => countryObj.country === formikCtx.values.par_country);
            getAllCitiesByCountryId(selectedCountry?.id).then((res) => {
                setCityList(res.data);
            }).catch((err) => {
                console.log(err);
            })
        }
    }, [formikCtx.values.par_country])

    useEffect(() => {
        getAllCountries().then((res) => {
            setCountryList(res.data);
        }).catch((err) => {
            console.log(err);
        });

        getAllCompany().then((res) => {
            setCompanyList(res.data);
        }).catch((err) => {
            console.log(err);
        });
        getThirdPartyCompany().then((res) => {
            setThirdPartyCompany(res.data);
        }).catch((err) => {
            console.log(err);
        })
        fetchfieldHistoryForLastUpdate({
            request_id: parseInt(formikCtx.values.request_id),
            request_type: "PAR",
        })
            .then((response) => {

                dispatch(fieldUpdationHistory(response.data));
            })
            .catch((error) => {
                console.log(error);
            });

    }, [])
    const IsCompanyThirdPartyAndNotlisted = () => {
        return (
            <>
                <Grid item xs={3}>
                    <FormikControl
                        control="autocomplete"
                        label="3rd party company"
                        name="par_is_third_party_company"
                        options={
                            thirdPartyCompany?.map((companyObj: any) => ({
                                label: companyObj.third_party_company_name,
                                value: companyObj.third_party_company_name,
                            }))
                        }
                        disabled={isDisabled}
                    />
                </Grid>
                <Grid item sm={6}>

                </Grid>
                <Grid item xs={3}>
                    <FormikControl
                        control="textfield"
                        label="Company name"
                        name="par_not_listed_third_party_company"
                        disabled={isDisabled}
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormikControl
                        control="textfield"
                        label="Department"
                        name="par_department"
                        disabled={formikCtx.values.oktaDepartment ? true : isDisabled}
                    />
                </Grid>
                <Grid item sm={6}>

                </Grid>
                <Grid item sm={3}>
                    <FormikControl
                        control="autocomplete"
                        label="Country"
                        name="par_country"
                        options={countryList?.map((countryObj: any) => ({
                            label: countryObj.country,
                            value: countryObj.country,
                        }))}
                        disabled={formikCtx.values.oktaCountry ? true : isDisabled}
                    />
                </Grid>
                <Grid item sm={3}>
                    <FormikControl
                        control="autocomplete"
                        label="City"
                        name="par_city"
                        options={cityList?.map((city: any) => ({ label: city.city, value: city.city }))}
                        disabled={formikCtx.values.oktaCity ? true : isDisabled}
                    />
                </Grid>
                <Grid item sm={6}>

                </Grid>
                <Grid item sm={3}>
                    <FormikControl
                        control="textfield"
                        label="Job title"
                        name="par_job_title"
                        disabled={formikCtx.values.oktaJobTitle ? true : isDisabled}
                    />
                </Grid>

            </>
        )
    }


    const IsCompanyThirdPartyAndListed = () => {
        return (
            <>
                <Grid item xs={3}>
                    <FormikControl
                        control="autocomplete"
                        label="3rd party company"
                        name="par_is_third_party_company"
                        options={
                            thirdPartyCompany?.map((companyObj: any) => ({
                                label: companyObj.third_party_company_name,
                                value: companyObj.third_party_company_name,
                            }))
                        }
                        disabled={isDisabled}
                    />
                </Grid>
                <Grid item sm={6}>

                </Grid>
                <Grid item xs={3}>
                    <FormikControl
                        control="textfield"
                        label="Department"
                        name="par_department"
                        disabled={formikCtx.values.oktaDepartment ? true : isDisabled}
                    />
                </Grid>

                <Grid item sm={3}>
                    <FormikControl
                        control="autocomplete"
                        label="Country"
                        name="par_country"
                        options={countryList?.map((countryObj: any) => ({
                            label: countryObj.country,
                            value: countryObj.country,
                        }))}
                        disabled={formikCtx.values.oktaCountry ? true : isDisabled}
                    />
                </Grid>
                <Grid item sm={6}>

                </Grid>
                <Grid item sm={3}>
                    <FormikControl
                        control="autocomplete"
                        label="City"
                        name="par_city"
                        options={cityList?.map((city: any) => ({ label: city.city, value: city.city }))}
                        disabled={formikCtx.values.oktaCity ? true : isDisabled}
                    />
                </Grid>

                <Grid item sm={3}>
                    <FormikControl
                        control="textfield"
                        label="Job title"
                        name="par_job_title"
                        disabled={formikCtx.values.oktaJobTitle ? true : isDisabled}
                    />
                </Grid>

            </>
        )
    }
    const IsNormalCompany = () => {
        return (
            <>
                <Grid item xs={3}>
                    <FormikControl
                        control="textfield"
                        label="Department"
                        name="par_department"
                        disabled={formikCtx.values.oktaDepartment ? true : isDisabled}
                    />
                </Grid>
                <Grid item sm={6}>
                </Grid>
                <Grid item sm={3}>
                    <FormikControl
                        control="autocomplete"
                        label="Country"
                        name="par_country"
                        options={countryList?.map((countryObj: any) => ({
                            label: countryObj.country,
                            value: countryObj.country,
                        }))}
                        disabled={formikCtx.values.oktaCountry ? true : isDisabled}
                    />
                </Grid>

                <Grid item sm={3}>
                    <FormikControl
                        control="autocomplete"
                        label="City"
                        name="par_city"
                        options={cityList?.map((city: any) => ({ label: city.city, value: city.city }))}
                        disabled={formikCtx.values.oktaCity ? true : isDisabled}
                    />
                </Grid>
                <Grid item sm={6}>
                </Grid>
                <Grid item sm={3}>
                    <FormikControl
                        control="textfield"
                        label="Job title"
                        name="par_job_title"
                        disabled={formikCtx.values.oktaJobTitle ? true : isDisabled}
                    />
                </Grid>

            </>
        )
    }
    return (
        <>
            <Grid container >
                <Grid item xs={5}>
                    <div>
                        <FieldLabel Title="Account requestor details" tooltipMsg={accountRequestorTooltip} required />
                    </div>

                </Grid>
                <Grid item xs={5}>

                </Grid>

                <Grid item xs={2}>
                </Grid>
                <Grid item xs={12}>
                    <div style={{ color: '#A7A7A7', fontFamily: 'NotoSerif-Regular', fontSize: '10px', marginTop: '-5px', paddingBottom: '12px' }}>
                    </div>
                </Grid>
            </Grid>
            <Grid container rowSpacing={3} columnSpacing={10}>
                <Grid item xs={3}>
                    <FormikControl
                        control="textfield"
                        label="First name"
                        name="par_requestor_first_name"
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormikControl
                        control="textfield"
                        label="Last name"
                        name="par_requestor_last_name"
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={6}>

                </Grid>
                <Grid item xs={3}>
                    <FormikControl
                        control="textfield"
                        label="Email address"
                        name="par_requestor_email"
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormikControl
                        control="textfield"
                        label="Date raised"
                        name="par_date_raised"
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={1}>
                    {/* <span className={classes.tooltipSpan}>
                        <HelperTooltip tooltipMsg={`This should be the approval date of the Delegation or today's date if for a Test account`}>
                            <HelpRoundedIcon style={{ color: '#0A2756' }} />
                        </HelperTooltip>
                    </span> */}
                </Grid>
                <Grid item xs={5}></Grid>
            </Grid>
            <Grid container paddingTop={'12px'} >
                <Grid item xs={5}>
                    <div>
                        <FieldLabel Title="Account owner details" tooltipMsg={accountOwnerDetailsTooltip} required />
                    </div>
                    <div style={{ color: '#A7A7A7', fontFamily: 'NotoSerif-Regular', fontSize: '10px', marginTop: '-5px', paddingBottom: '10px' }}>
                        {/* @ts-ignore */}
                        {(updatedFieldHistoryData[1].Account_owner_details?.length > 0) && `Updated on ${formatDateInside(updatedFieldHistoryData[1].Account_owner_details[updatedFieldHistoryData[1].Account_owner_details?.length - 1].last_updated)}`}
                    </div>
                </Grid>
                <Grid item xs={5}>

                </Grid>
                <Grid item xs={2}>

                    {routeCheck() ? remediationCount > 0 ? (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="Account_owner_details"
                                subSection='Department'
                                data={updatedFieldHistoryData.length > 0 ? updatedFieldHistoryData[1].Account_owner_details : null}
                            />

                            <CommentSubmission
                                sectionName='Account_owner_details'
                                subSection=''
                                updateType='text update'
                                remediation_by={remediated_by}
                                data={submitParCommentsData.length > 0 ? submitParCommentsData[1].Account_owner_details : null}
                                requestorData={submitParRequestorCommentsData.length > 0 ? submitParRequestorCommentsData[1].Account_owner_details : null}
                            />
                        </div>
                    ) : null : (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="Account_owner_details"
                                subSection='Department'
                                data={updatedFieldHistoryData.length > 0 ? updatedFieldHistoryData[1].Account_owner_details : null}
                            />

                            <CommentSubmission
                                sectionName='Account_owner_details'
                                subSection=''
                                remediation_by={remediated_by}
                                updateType='text update'
                                requestorData={submitParRequestorCommentsData.length > 0 ? submitParRequestorCommentsData[1].Account_owner_details : null}
                                data={submitParCommentsData.length > 0 ? submitParCommentsData[1].Account_owner_details : null}
                            />
                        </div>
                    )}

                </Grid>
            </Grid>
            <Grid container rowSpacing={3} columnSpacing={10}>
                <Grid item xs={3}>
                    {/* <FormikControl
                        control="textfield"
                        label="First name"
                        name="par_first_name"
                    // disabled
                    /> */}
                    <FormikControl
                        control='autocomplete'
                        label='First name'
                        name='par_first_name'
                        open={openState}
                        options={
                            accountOwnerList?.map((userObj: any) => ({
                                value: `${userObj.email}`,
                                label: `${userObj.first_name} ${userObj.last_name}`,
                            }))
                        }
                        handleInputChange={(inputValue: string, reason: string) => {
                            if (reason === 'input') {
                                setaccountOwnerToSearch(inputValue);
                                setSearchLoading(true);
                                setOpenState(false);
                            }
                            if (reason === 'reset') {
                                formikCtx.setFieldValue('par_first_name', inputValue.split(' ')[0]);
                            }
                        }}
                        freeSolo
                        loading={searchLoading}
                        onBlur={() => {
                            if (!isAutoCompleteSelected.current) {
                                formikCtx.setFieldValue('par_first_name', accountOwnerToSearch)
                            }
                        }}
                        handleSelectionChange={(selectedValue: {
                            value: string,
                            label: string,
                        }) => {
                            const selectedAccountOwner = accountOwnerList?.find(
                                (obj: any) => obj.email === selectedValue.value
                            );
                            setOpenState(false);
                            isAutoCompleteSelected.current = true;
                            formikCtx.setFieldValue('par_first_name', selectedAccountOwner ? selectedAccountOwner.first_name : '');
                            formikCtx.setFieldValue('par_last_name', selectedAccountOwner ? selectedAccountOwner.last_name : '');
                            formikCtx.setFieldValue('par_email', selectedAccountOwner ? selectedAccountOwner.email : '');
                            formikCtx.setFieldValue('par_department', selectedAccountOwner ? selectedAccountOwner.department : '');
                            formikCtx.setFieldValue('par_company', selectedAccountOwner ? selectedAccountOwner.company : '');
                            formikCtx.setFieldValue('par_country', selectedAccountOwner ? selectedAccountOwner.country : '');
                            formikCtx.setFieldValue('par_city', selectedAccountOwner ? selectedAccountOwner.city : '');
                            formikCtx.setFieldValue('par_phone', selectedAccountOwner ? selectedAccountOwner.phone : '');
                            formikCtx.setFieldValue('par_job_title', selectedAccountOwner ? selectedAccountOwner.title : '');
                        }}
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormikControl
                        control="textfield"
                        label="Last name"
                        name="par_last_name"
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={6}>

                </Grid>
                <Grid item sm={3}>
                    <FormikControl
                        control="textfield"
                        label="Email address"
                        name="par_email"
                        // disabled={formikCtx.values.par_email !== (null || "") ? true : isDisabled}
                        disabled={true}
                    />
                </Grid>
                <Grid item sm={3}>
                    <FormikControl
                        control="phone"
                        label="Phone"
                        name="par_phone"
                        defaultCountry="us"
                        disabled={formikCtx.values.oktaPhone ? true : isDisabled}
                    />
                </Grid>
                <Grid item sm={6}>
                </Grid>

                <Grid item sm={3}>
                    <FormikControl
                        control="autocomplete"
                        label="Company"
                        name="par_company"
                        options={
                            companyList?.map((companyObj: any) => ({
                                label: companyObj.company,
                                value: companyObj.company,
                            }))
                        }
                        disabled={formikCtx.values.oktaCompany ? true : isDisabled}
                    />
                </Grid>
                {(formikCtx.values.par_company == ('3rd Party' || 'Third Party') && formikCtx.values.par_is_third_party_company === 'Not listed') ?
                    IsCompanyThirdPartyAndNotlisted() : (formikCtx.values.par_company === ('3rd Party' || 'Third Party')) ? IsCompanyThirdPartyAndListed() : IsNormalCompany()
                }
                <Grid item sm={7}>
                </Grid>

                {/* </Grid> */}
                <Grid item sm={11}>
                    <FormikControl
                        disabled={isDisabled}
                        control="textfield"
                        label="Job description"
                        name="par_job_title_explanation"
                        multiline
                        minRows={5}
                    />
                </Grid>
                <Grid item xs={1}>
                    {routeCheck() ? remediationCount > 0 ? (<div className={styleClasses.mainSectionDiv}>
                        <HistoryTable
                            parId={formikCtx.values.request_id}
                            section="Job_description"
                            subSection=''
                            data={updatedFieldHistoryData.length > 0 ? updatedFieldHistoryData[2].Job_description : null}
                        />
                        <CommentSubmission
                            sectionName='Job_description'
                            subSection=''
                            remediation_by={remediated_by}
                            updateType='text update'
                            data={submitParCommentsData.length > 0 ? submitParCommentsData[2].Job_description : null}
                            requestorData={submitParRequestorCommentsData.length > 0 ? submitParRequestorCommentsData[2].Job_description : null}
                        />
                    </div>) : null
                        : (
                            <div className={styleClasses.mainSectionDiv}>
                                {/* <HistoryTable
                                    parId={formikCtx.values.request_id}
                                    section="Job description"
                                    subSection=''
                                /> */}

                                <CommentSubmission
                                    sectionName='Job_description'
                                    subSection=''
                                    remediation_by={remediated_by}
                                    updateType='text update'
                                    data={submitParCommentsData.length > 0 ? submitParCommentsData[2].Job_description : null}
                                    requestorData={submitParRequestorCommentsData.length > 0 ? submitParRequestorCommentsData[2].Job_description : null}
                                />
                            </div>)}
                </Grid>
            </Grid>
        </>
    )
};

export default RequestorAndOwner;