import React, { useState, useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import FormikControl from '../../../formik-conrtols/FormikControl';
import FieldLabel from '../../../field-label';
import { format, isDate } from 'date-fns';
import { ChooseServerTier, hostnameTootip, singleServer } from '../tooltips';
import FilterOptions from '../../../filter-menu/FilterOptions';
import { parStyles, sarUseStyles } from '../../useStyles';
import { RootState } from '../../../../appStore/store';
import HistoryTable from '../../../../modules/sar/comments/historyTable';
import CommentSubmission from '../../../../modules/sar/comments/commentSubmission';
import { routeCheck, routeCheckForAdminSummary, routeCheckForApprovalSummary, routeCheckForImplementationSummary, routeCheckForPendingApproval } from './routingConditional';

function ServerInformation({ isDisabled }: { isDisabled: boolean }) {
    const classes = sarUseStyles()
    const styleClasses = parStyles();
    const formikCtx: any = useFormikContext();
    const remediated_by = formikCtx.values.remediation_by
    const remediationCount = formikCtx.values.remediation_count;
    const { fieldHistorySarCommentsData } = useSelector((state: RootState) => state);
    const { submitSarCommentData } = useSelector((state: RootState) => state);
    const formatDateInside = (dateValue: any) => (dateValue && isDate(new Date(dateValue)) ? format(new Date(dateValue), 'MMM dd, yyyy') : '');

    const checkForEditReviewFlow = () => {
        return routeCheck() || routeCheckForAdminSummary() || routeCheckForImplementationSummary() || routeCheckForApprovalSummary()
    }
    const [filters, setFilters] = useState({ serverTier: [] });
    const [serverTierOptionsData] = useState([{ id: "PROD", value: 'PROD' }, { id: 'UAT', value: 'UAT' }, { id: 'TEST', value: 'TEST' }, { id: 'DEV', value: 'DEV' }]);


    const handleOptionSelection = (e: any, selection: any) => {
        setFilters((prevFilters: any) => {
            let newRequestNameArray = [...prevFilters.serverTier];
            if (!selection) {
                newRequestNameArray = newRequestNameArray.filter(
                    (option) => option !== e.target.value
                );
            } else newRequestNameArray.push(e.target.value);
            return {
                ...prevFilters,
                serverTier: [...newRequestNameArray]
            };
        });
    }

    useEffect(() => {
        formikCtx.setFieldValue('sar_server_tier', filters.serverTier)
    }, [filters]);

    useEffect(() => {
        formikCtx.values.sar_server_tier &&
            setFilters((prevFilters: any) => {
                return {
                    ...prevFilters,
                    serverTier: [...formikCtx.values.sar_server_tier],
                };
            })
    }, [])
    return (
        <>
            <Grid container paddingTop={'12px'} >
                <Grid item xs={10}>
                    <>
                        <FieldLabel Title="Is this service account to be used only on a single server?" tooltipMsg={singleServer} required />
                    </>
                    <>
                        <div className={styleClasses.updatedByStyle}>
                            {/* @ts-ignore */}
                            {(fieldHistorySarCommentsData[11].Is_single_server?.length > 0) && `Updated on ${formatDateInside(fieldHistorySarCommentsData[11].Is_single_server[fieldHistorySarCommentsData[11].Is_single_server?.length - 1].last_updated)}`}
                        </div>
                    </>
                </Grid>
                <Grid item xs={2}>
                    {(checkForEditReviewFlow() && remediationCount > 0) ? (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="Is_single_server"
                                subSection=''
                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[11].Is_single_server : null}
                            />
                            <CommentSubmission
                                sectionName='Is_single_server'
                                subSection=''
                                updateType='Select update'
                                remediation_by={remediated_by}
                                data={submitSarCommentData.length > 0 ? submitSarCommentData[11].Is_single_server : null}
                            />
                        </div>
                    ) : routeCheckForPendingApproval() && (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="Is_single_server"
                                subSection=''
                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[11].Is_single_server : null}
                            />
                            <CommentSubmission
                                sectionName='Is_single_server'
                                subSection=''
                                updateType='Select update'
                                remediation_by={remediated_by}
                                data={submitSarCommentData.length > 0 ? submitSarCommentData[11].Is_single_server : null}
                            />
                        </div>
                    )}
                </Grid>
                <Grid item xs={3}>
                    <FormikControl
                        disabled={isDisabled}
                        control="radio"
                        name="sar_is_sar_servers_usage"
                        radioGrouplabels={[{
                            label: 'Yes',
                            value: true,
                        }, {
                            label: 'No',
                            value: false,
                        }]}
                        isInline
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            formikCtx.setFieldValue('sar_is_sar_servers_usage', e.target.value === 'true');
                        }}
                    />
                </Grid>
                <Grid item xs={9}>

                </Grid>
            </Grid>
            <Grid container paddingTop={'12px'} >
                <Grid item xs={10}>
                    <>
                        <FieldLabel Title="Please list the exact server hostnames of the systems the service account will run on" tooltipMsg={hostnameTootip} required />
                    </>
                    <>
                        <div className={styleClasses.updatedByStyle}>
                            {/* @ts-ignore */}
                            {(fieldHistorySarCommentsData[12].Is_single_server?.length > 0) && `Updated on ${formatDateInside(fieldHistorySarCommentsData[12].Is_single_server[fieldHistorySarCommentsData[12].Is_single_server?.length - 1].last_updated)}`}
                        </div>
                    </>
                </Grid>
                <Grid item xs={2}>
                    {(checkForEditReviewFlow() && remediationCount > 0) ? (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="Server_hostnames"
                                subSection=''
                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[12].Server_hostnames : null}
                            />
                            <CommentSubmission
                                sectionName='Server_hostnames'
                                subSection=''
                                updateType=''
                                remediation_by={remediated_by}
                                data={submitSarCommentData.length > 0 ? submitSarCommentData[12].Server_hostnames : null}
                            />
                        </div>
                    ) : routeCheckForPendingApproval() && (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="Server_hostnames"
                                subSection=''
                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[12].Server_hostnames : null}
                            />
                            <CommentSubmission
                                sectionName='Server_hostnames'
                                subSection=''
                                updateType='Select update'
                                remediation_by={remediated_by}
                                data={submitSarCommentData.length > 0 ? submitSarCommentData[12].Server_hostnames : null}
                            />
                        </div>
                    )}
                </Grid>
            </Grid >
            <Grid container rowSpacing={3} columnSpacing={10} >
                <Grid item xs={12}>
                    <FormikControl
                        control="textfield"
                        name="sar_servers_hostname"
                        //label="Please server hostnames"
                        disabled={isDisabled}
                        placeholder="Provide server hostnames"
                        multiline
                        minRows={5}
                    />
                </Grid>

            </Grid>
            <Grid container paddingTop={'12px'} >
                <Grid item xs={10}>
                    <>
                        <FieldLabel Title="Choose server tier" tooltipMsg={ChooseServerTier} required />
                    </>
                    <>
                        <div className={styleClasses.updatedByStyle}>
                            {/* @ts-ignore */}
                            {(fieldHistorySarCommentsData[13].Choose_server_tier?.length > 0) && `Updated on ${formatDateInside(fieldHistorySarCommentsData[13].Choose_server_tier[fieldHistorySarCommentsData[13].Choose_server_tier?.length - 1].last_updated)}`}
                        </div>
                    </>
                </Grid>
                <Grid item xs={2}>
                    {(checkForEditReviewFlow() && remediationCount > 0) ? (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="Choose_server_tier"
                                subSection=''
                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[13].Choose_server_tier : null}
                            />
                            <CommentSubmission
                                sectionName='Choose_server_tier'
                                subSection=''
                                updateType=''
                                remediation_by={remediated_by}
                                data={submitSarCommentData.length > 0 ? submitSarCommentData[13].Choose_server_tier : null}
                            />
                        </div>
                    ) : routeCheckForPendingApproval() && (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="Choose_server_tier"
                                subSection=''
                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[13].Choose_server_tier : null}
                            />
                            <CommentSubmission
                                sectionName='Choose_server_tier'
                                subSection=''
                                updateType='Select update'
                                remediation_by={remediated_by}
                                data={submitSarCommentData.length > 0 ? submitSarCommentData[13].Choose_server_tier : null}
                            />
                        </div>
                    )}
                </Grid>
            </Grid>
            <Grid container rowSpacing={3} columnSpacing={10}>
                <Grid item xs={12} className={classes.checkBox}>
                    <FilterOptions
                        searchPlaceholder="Search"
                        optionsList={serverTierOptionsData}
                        selectedOptions={filters.serverTier}
                        handleOptionSelection={handleOptionSelection}
                        disabled={isDisabled}
                        isFilterDataLoading={false}
                    />
                </Grid>
            </Grid >
        </>
    )
}

export default ServerInformation;