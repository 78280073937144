import { useEffect } from "react";
import { RootState } from "../appStore/store";
import { useSelector } from "react-redux";
import { LoginCallback, SecureRoute, useOktaAuth } from "@okta/okta-react";
import { Route, useHistory, useLocation, Redirect } from "react-router-dom";
import DelegationDashboard from "../modules/delegation";
import FormPage from "../modules/formPage";
import ApprovalSummary from "../modules/approvalSummary";
import myAdministrativeTasks from "../modules/myAdministrativeTasks";
import myImplementationTasks from "../modules/myImplementationTasks";
import LandingPage from "../modules/landing-page";
import LoginScreen from "../modules/login";
import ErrorCallbackComponent from "../modules/login-callback-error";
import parDashboard from "../modules/par";
import FormPagePar from "../modules/formPagePar";
import FormPageSar from "../modules/formPageSar";
import Loader from "../components/loader";
import faq from '../modules/faq'
import attestaion from '../modules/attestation'
import insights from '../modules/insights'
import surveilence from "../modules/surveillance"
// eslint-disable-next-line max-len
const errorCallbackComponent = (propsParam: any) => (
  <ErrorCallbackComponent error={propsParam.error} />
);

const Routes = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const { loginuserdata, loginsyncupdata, loading } = useSelector(
    (state: RootState) => state.loginData
  );
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (authState && !authState.isAuthenticated) {
      history.push("/login");
    } else {
      oktaAuth.getUser().then((info) => {
        history.push("/home");
      });
    }
  }, [authState, oktaAuth]);

  if (loading) {
    return (
      <div style={{ padding: "0px 32px" }}>
        <Loader openModal={true} />
      </div>
    );
  }

  return (
    <div>
      <div
        style={{
          marginTop: location.pathname !== "/login" ? "110px" : "0px",
        }}
      >
        <Route
          path="/"
          exact
          render={() => {
            if (authState) {
              if (authState.isAuthenticated) {
                return <Redirect to="/home" />;
              } else {
                return <Redirect to="/login" />;
              }
            }
          }}
        />
        <SecureRoute path="/home" exact component={LandingPage} />
        <SecureRoute path="/callback" exact component={LandingPage} />
        <SecureRoute path="/delegation" exact component={DelegationDashboard} />
        <SecureRoute path="/par" exact component={parDashboard} />
        <SecureRoute path="/delegation/:requestId" exact component={FormPage} />
        <SecureRoute path="/par/:requestId" exact component={FormPagePar} />
        <SecureRoute path="/delegation/sar/:requestId" exact component={FormPageSar} />
        <SecureRoute path="/faq" exact component={faq} />
        <SecureRoute path="/attestaion" exact component={attestaion} />
        <SecureRoute path="/reporting" exact component={insights} />
        <SecureRoute path="/surveillance" exact component={surveilence} />
        {/* Start Of Routes for MyApproal Task Module */}
        <SecureRoute
          path="/myApprovalTasks"
          exact
          component={ApprovalSummary}
        >
        </SecureRoute>
        <SecureRoute
          path="/myApprovalTasks/pendingApproval/:requestId"
          exact
          component={FormPage}
        />
        <SecureRoute
          path="/myApprovalTasks/pendingApprovalPar/:requestId"
          exact
          component={FormPagePar}
        />
        <SecureRoute
          path="/myApprovalTasks/approvalSummary/:requestId"
          exact
          component={FormPage}
        />
        <SecureRoute
          path="/myApprovalTasks/approvalSummaryPar/:requestId"
          exact
          component={FormPagePar}
        />
        {/* End Of Routes for MyApproal Task Module*/}
        <SecureRoute
          path="/myadministrativeTasks"
          exact
          component={myAdministrativeTasks}
        />
        <SecureRoute
          path="/myadministrativeTasks/delegation/:requestId"
          exact
          component={FormPage}
        />

        {/* For Edit Review SAR */}
        <SecureRoute
          path="/myadministrativeTasks/sar/:requestId"
          exact
          component={FormPageSar}
        />
        <SecureRoute
          path="/myApprovalTasks/approvalSummarySar/:requestId"
          exact
          component={FormPageSar}
        />
        <SecureRoute
          path="/myApprovalTasks/pendingApprovalSar/:requestId"
          exact
          component={FormPageSar}
        />
        <SecureRoute
          path="/myImplementationTasks/pendingImplementationSar/:requestId"
          exact
          component={FormPageSar}
        />
        <SecureRoute
          path="/myImplementationTasks/implementationSummarySar/:requestId"
          exact
          component={FormPageSar}
        />
        {/* End of  Edit/Review */}




        <SecureRoute
          path="/myadministrativeTasks/par/:requestId"
          exact
          component={FormPagePar}
        />
        <SecureRoute
          path="/myImplementationTasks"
          exact
          component={myImplementationTasks}
        />
        <SecureRoute
          path="/myImplementationTasks/pendingImplementation/:requestId"
          exact
          component={FormPage}
        />
        <SecureRoute
          path="/myImplementationTasks/implementationSummary/:requestId"
          exact
          component={FormPage}
        />
        <SecureRoute
          path="/myImplementationTasks/pendingImplementationpar/:requestId"
          exact
          component={FormPagePar}
        />
        <SecureRoute
          path="/myImplementationTasks/implementationSummarypar/:requestId"
          exact
          component={FormPagePar}
        />
        <Route path="/login" render={() => <LoginScreen />} />
        <Route path="/callback" component={LoginCallback} />
        {/* <Route
                    path="/callback"
                    render={() => (
                        <LoginCallback
                            errorComponent={(props) => errorCallbackComponent(props)}
                            loadingElement={
                                <div
                                    style={{
                                        height: "calc(100vh - 110px)",
                                        width: "100vw",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        color: "#0A2756",
                                    }}
                                >
                                    <CircularProgress color="inherit" />
                                </div>
                            }
                        />
                    )
                    }
                /> */}
      </div>
    </div>
  );
};

export default Routes;
