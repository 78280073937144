import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ICompanyData, ICountryData, ILoginSyncUpData, IOfficeData, IThirdPartyCompanyName, IUserData } from './ILoginProps'

export interface IDelegationSummarry {
    loginsyncupdata: ILoginSyncUpData,
    loginuserdata: IUserData,
    loading: Boolean,
    countryData: ICountryData[],
    companydata: ICompanyData[],
    thirdPartyCompanyName: IThirdPartyCompanyName[],
    officeData: IOfficeData[]
}

// Define the initial state using that type
const initialState: IDelegationSummarry = {
    loginsyncupdata: { first_name: '', last_name: '', email: '', user_id: '', groups: [], message: '', status: false },
    loginuserdata: { sub: '', name: '', locale: '', email: '', preferred_username: '', given_name: '', family_name: '', zoneinfo: '', updated_at: 0, email_verified: false },
    loading: false,
    countryData: { id: null, country: '', country_code: '' },
    companydata: { id: null, company: '' },
    thirdPartyCompanyName: { id: null, third_party_company_name: '' },
    officeData: { id: null, office: '' }
}

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        initialLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        loginSyncUpData: (state, action: PayloadAction<ILoginSyncUpData>) => {
            state.loginsyncupdata = action.payload
        },
        loginUserData: (state, action: PayloadAction<IUserData>) => {
            state.loginuserdata = action.payload
        },
        dispatchCountryData: (state, action: PayloadAction<ICountryData[]>) => {
            state.countryData = action.payload
        },
        dispatchCompanyData: (state, action: PayloadAction<ICompanyData[]>) => {
            state.companydata = action.payload
        },
        dispatchThirdCompanyData: (state, action: PayloadAction<IThirdPartyCompanyName[]>) => {
            state.thirdPartyCompanyName = action.payload
        },
        dispatchOfficeData: (state, action: PayloadAction<IOfficeData[]>) => {
            state.officeData = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { loginSyncUpData, loginUserData, initialLoading, dispatchCountryData, dispatchCompanyData, dispatchThirdCompanyData, dispatchOfficeData } = loginSlice.actions

export default loginSlice.reducer