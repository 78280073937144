import React, { useState, useEffect } from 'react';
import useDateTimeHook from '../../../components/date-timehook/useDateTimeHook';
import { Avatar, TextField } from '@mui/material';
import { chatBoxStyles } from './useStyles';
import { stringAvatar } from '../../../constants/customAvatar';
import { getSectionWiseCommentHistory } from '../../../api-services/par';
import { useSelector } from 'react-redux';
import { RootState } from '../../../appStore/store';

interface IRequestorPostData {
    id?: string | number,
    value?: string,
    updated_by?: string,
    user_first_name?: string,
    user_last_name?: string,
    last_updated?: string,
}

interface IChatHistoryData {
    par_id: string | number,
    section: string,
    sub_section: string,
    remediationCountCheck?: string | number,
    data: {
        id: string | number,
        section: string,
        user_first_name: string,
        user_last_name: string,
        sub_section: string,
        update_type: string,
        last_updated: string | Date,
        updated_by: string,
        value: string,
        stage: string,
        requestor_reply?: IRequestorPostData[]
    }[] | any,
}
function ChatBox({ data, remediationCountCheck }: IChatHistoryData) {

    const styleClasses = chatBoxStyles();
    const { loginsyncupdata } = useSelector((state: RootState) => state.loginData);
    const routeCheck = () => {
        return location.pathname.slice(1, 5).toLocaleLowerCase() === 'dele'
    }

    const routCheckApprovalSummary = () => {
        return location.pathname.slice(1, 35).toLocaleLowerCase() === 'myapprovaltasks/approvalsummarysar'
    }

    return (
        <>
            {(data && data.length > 0) && data.map((viewHistory: any) => {
                return <div>
                    {(routeCheck() || routCheckApprovalSummary() || (viewHistory.current_remediation !== remediationCountCheck || viewHistory.updated_by !== loginsyncupdata.email)) && < div style={{ display: 'flex', alignItems: 'flex-start', width: 'content-fit', paddingBottom: '10px' }}>
                        <div>
                            <div style={{ display: 'inline-flex', flexFlow: 'column nowrap', width: '200px', background: '#F4F7F8', padding: '7px 11px 0px 13px', borderRadius: '4px' }}>
                                <span className={styleClasses.contentDiv}>
                                    {viewHistory.value}
                                </span>
                                <span className={styleClasses.stageText}>
                                    {`By ${viewHistory.user_first_name} ${viewHistory.user_last_name} (${viewHistory.stage})`}
                                </span>
                            </div>
                            <div style={{ fontFamily: 'NotoSerif-Regular', fontSize: '10px', color: '#A7A7A7' }}>
                                {useDateTimeHook(viewHistory.last_updated)}
                            </div>
                        </div>
                        <div style={{ padding: '0px 4px 4px 10px' }}>
                            <Avatar style={{ color: 'white', background: '#00B5B1' }} {...stringAvatar(`${viewHistory.user_first_name} ${viewHistory.user_last_name}` || '')} className={styleClasses.avatar} />
                        </div>
                    </div>
                    }
                    {
                        viewHistory.requestor_reply !== undefined && viewHistory.requestor_reply.length > 0 && (viewHistory.requestor_reply.updated_by !== loginsyncupdata.email) && viewHistory.requestor_reply.map((item: any) => (
                            <div style={{ display: 'flex', alignItems: 'flex-start', width: 'content-fit', paddingBottom: '10px' }}>
                                <div>
                                    <div style={{ display: 'inline-flex', flexFlow: 'column nowrap', width: '200px', background: '#F4F7F8', padding: '7px 11px 0px 13px', borderRadius: '4px' }}>
                                        <span className={styleClasses.contentDiv}>
                                            {item.value}
                                        </span>
                                        <span className={styleClasses.stageText}>
                                            {`By ${item.user_first_name} ${item.user_last_name}`}
                                        </span>
                                    </div>
                                    <div style={{ fontFamily: 'NotoSerif-Regular', fontSize: '10px', color: '#A7A7A7' }}>
                                        {useDateTimeHook(item.last_updated)}
                                    </div>
                                </div>
                                <div style={{ padding: '0px 4px 4px 10px' }}>
                                    <Avatar style={{ color: 'white', background: '#00B5B1' }} {...stringAvatar(`${item.user_first_name} ${item.user_last_name}` || '')} className={styleClasses.avatar} />
                                </div>
                            </div>

                        ))
                    }
                </div >
            })}


        </>
    );
}

export default ChatBox;