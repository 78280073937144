import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    pageContainer: {
        padding: '16px 20px 20px',
    },
    emptyResultContainer: {
        color: '#0A2756',
        width: '100%',
        height: 'calc(100vh - 20vh) !important',
        border: '1px dashed #A7A7A7',
        font: 'normal normal normal 14px/19px NotoSerif-Regular',
        letterSpacing: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        "& p": {
            fontSize: '15px',
            fontFamily: 'Poppins-Bold',
        }
    },
})

export default useStyles;