import axios from '../common/axiosInstance';

// export const fetchFilterEndPointsPendingSummary = {
//   fetchDelegationRequestType: '/get_request_type/',
//   fetchRequestFilterEndPoint: '/get_approver_request_ids/',
//   fetchDelegationRequestorNameEndPoint: '/get_all_requestor/',
//   fetchTeamNameFilterEndPoint: '/get_team_name/',
//   createDelegationSAR: '/get_delegation_status/',
// };
export const fetchFilterEndPointsApprovalSummary = {
  fetchRequestIds: '/get_approver_summary_ids/',
  fetchDelegationRequestorNameEndPoint: '/get_all_requestor/',
};

// export const fetchAllFilterRequests = async (endPoint: string) => {
//   const { data } = await axios.get(endPoint);
//   return data;
// };


export const fetchAllApprovalSummaryData = async (queryStringValue: string) => {
  const endPoint = `/my_approval_summary/`;
  const { data } = await axios.get(endPoint);
  return data;
};


export const fetchAllPendingApprovalsData = async (queryStringValue: string) => {
  const endPoint = `/my_pending_approvals/`;
  const { data } = await axios.get(endPoint);
  return data;
};

export const fetchCollapsibleFormData = async (
  { requestId, stageId, requestType }:
    { requestId?: string, stageId?: string, requestType?: string },
) => {
  let requestTypeId;
  if (requestType === 'del' || requestType === 'sar') {
    requestTypeId = '1';
  } else if (requestType === 'par') {
    requestTypeId = '2';
  }
  const endPoint = `/request_delegate_from/?request_type=${requestTypeId}&stage=${stageId}&request_id=${requestId}`;
  const { data } = await axios.get(endPoint);
  return data;
};


export const postDelegatedToSecondary = async (postBody: any) => {
  const endPoint = '/delegate_to_secondary/';
  const { data } = await axios.post(endPoint, postBody);
  return data;
};
export const postDelegatedToSecondaryPar = async (postBody: any) => {
  const endPoint = '/par_delegate_to_secondary/';
  const { data } = await axios.post(endPoint, postBody);
  return data;
};
export const postRemediationStatus = async (postBody: any) => {
  const endPoint = '/update_status/';
  const { data } = await axios.post(endPoint, postBody);
  return data;
};
