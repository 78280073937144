/* eslint-disable react/jsx-props-no-spreading */
import { Field } from 'formik';
import RadioButton from '../radio-button/index';
import TextFields from '../input';
import Checkboxs from '../checkbox/index';
import CustomAutocomplete from '../auto-complete';
import PhoneInputField from '../phone-input';
import CommentSection from '../../modules/par/comments/commentSection';

function FormikControl(props: any) {
  const {
    control,
    name,
    disabled,
    isInline,
    isCompact,
    label,
    defaultCountry,
    handleInputChange,
    handleSelectionChange,
    freeSolo,
    onBlur,
    onChange,
    implementationFieldCheck,
    value,
    checked,
    ...rest
  } = props;


  return (
    <Field name={name}>
      {({ field, meta, form }: { field: any, meta: any, form: any }) => {
        switch (control) {
          case 'reviewaction':
            return (
              <CommentSection
                {...rest}
                {...field}
                {...meta}

                disabled={disabled}
                isInline={isInline}
                errorMsg={meta.error}
              />
            );
          case 'radio':
            return (
              <RadioButton
                {...rest}
                {...field}
                {...meta}
                disabled={disabled}
                isInline={isInline}
                errorMsg={meta.touched && meta.error ? meta.error : null}
                checked={checked}
                onChange={(e) => {
                  if (onChange) onChange(e);
                  else field.onChange(e);
                }}
              />
            );
          case 'textfield':
            return (
              <TextFields
                {...rest}
                {...field}
                {...meta}
                disabled={disabled}
                label={label}
                errorMsg={meta.touched && meta.error ? meta.error : null}
                onBlur={(e) => {
                  if (onBlur) onBlur(e);
                  else field.onBlur(e);
                }}
                onChange={(e) => {
                  if (onChange) {
                    onChange(e);
                  } else {
                    field.onChange(e);
                  }
                }}
                implementationFieldCheck={implementationFieldCheck}
              />
            );
          case 'checkbox':
            return (
              <Checkboxs
                {...rest}
                {...field}
                {...meta}
                disabled={disabled}
                isCompact={isCompact}
                label={label}
                checked={field.value}
              // onChange={onChange}
              />
            );
          case 'autocomplete':
            return (
              <CustomAutocomplete
                {...rest}
                {...field}
                {...meta}
                onSelectionChange={(selectedValue, reason) => {
                  if (handleSelectionChange) {
                    handleSelectionChange(selectedValue, reason);
                  } else {
                    form.setFieldValue(name, selectedValue.value);
                  }
                }}
                disableClearable
                label={label}
                handleInputChange={handleInputChange}
                freeSolo={freeSolo}
                onBlur={onBlur}
                disabled={disabled}
              />
            );
          case 'phone':
            return (
              <PhoneInputField
                {...rest}
                {...field}
                {...meta}
                onChange={(phoneValue) => {
                  form.setFieldValue(name, phoneValue);
                }}
                label={label}
                defaultCountry={defaultCountry}
                disabled={disabled}
              />
            );
          default: return (<div data-testid="empty-div" />);
        }
      }}
    </Field>
  );
}

export default FormikControl;
