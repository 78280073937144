import axios from "../common/axiosInstance";

export const postUpdatedionEndPointsAdminstrativeSummary = {
  postUpdatedRequestor: '/change_requestor/',
  postUpdatedApprover: '/change_approver/',
  postUpdatedImplementor: 'change_implementer/',
}


export const postAllUpdatedRoles = async (endPoint: string, postBody: any) => {
  const { data } = await axios.post(endPoint, postBody);
  return data;
}

export const fetchAllFilterRequests = async (endPoint: string) => {
  const { data } = await axios.get(endPoint);
  return data;
};

export const fetchAllAdministrativeSummaryData = async (
) => {
  const endPoint = '/admin_summary/';
  const { data } = await axios.get(endPoint);
  return data;
};

export const fetchAllAdminStatus = async () => {
  const endPoint = '/get_delegation_status/';
  const { data } = await axios.get(endPoint);
  return data;
}

export const fetchAllRequestorsAdmin = async () => {
  const endPoint = '/get_all_requestor/';
  const { data } = await axios.get(endPoint);
  return data;
}
export const fetchJustificationForDeny = async (request_id: any) => {
  const endPoint = `/get_request_to_update_justification/?request_id=${request_id}`;
  const { data } = await axios.get(endPoint);
  return data;
}
export const fetchAllApproversAdmin = async (stage_id: any) => {
  const endPoint = `/get_stage_wise_approver/?stage=${stage_id}`;
  const { data } = await axios.get(endPoint);
  return data;
}
export const fetchAllImplementorsAdmin = async (implementor_type_id: any) => {
  const endPoint = `get_type_wise_implementer/?implementer_type=${implementor_type_id}`;
  const { data } = await axios.get(endPoint);
  return data;
}
export const postAdminJustifcationAllowOrDeny = async (postBody: any) => {
  const endpoint = '/get_request_to_update_justification/'
  const { data } = await axios.get(endpoint, postBody);
  return data;
}

export const postAdminDeny = async (postBody: any) => {
  const endpoint = '/admin_action_on_request_to_update/'
  const { data } = await axios.post(endpoint, postBody);
  return data;
}
export const fetchAllUpdateHistory = async (requestId: any) => {
  const endPoint = `/get_request_to_update_history/?request_id=${requestId}`;
  const { data } = await axios.get(endPoint);
  return data;
}

export const updateHistory = async (requestId: any) => {
  const endPoint = `/get_request_to_update_history/?request_id=${requestId}`;
  const { data } = await axios.get(endPoint);
  return data;
}