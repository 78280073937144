// For SAR Reason

export const functionTooltip = 'Please explain the reason for raising this <br/>request and detail what the automation <br/> will be used to achieve'
export const justificationTooltip = `Please explain what activities need to be <br/> completed that require this automation <br/> and why automation is required`

// Request and Team Details//
// export const requestorDetailTooltip = 'This should either be the person who requested this account, as part of a Delegation, (in which case it will be pre-populated from the Delegation Requestor name) or the person requesting the PAR directly, i.e. for a Test Account (in which case it will be pre-populated with the details of the logged in user from Okta data).'
export const pointOfEscalationToolTip = 'This should be the email address of the account manager from <b>3rd Party </b> company who manages the relationship with WPP'
export const teamDetails = 'Provide details for the team requiring <br/> the privileged access. This should include full <br/> details for the manager of the team.'

//ServiceAccountDetails//
export const serviceAccountDetails = "A service account shouldn't be used for multiple <br/> applications, across cities.In this case, separate <br/> requests should be logged. <br/> User logon names should not exceed 20 characters."
export const selectDomain = 'Select the domain for <br> which access is required'

//account Type//
export const chooseAccountType = "Please choose either standard <br/> or complex account"
export const ChooseServiceAccountFunction = 'Select the basic service account function. Also advise if there are any additional functions the account will perform, e.g. the service accounts run a specific process. The service account is also used to read data in a file share.'
export const interactiveLogin = "Though interactive logon service <br/> accounts are bad practice, select <br/> 'yes' if its required"
export const justificaionForInteractiveDesign = 'Interactive logon service accounts will need high justification due to the risk these types of accounts present. Any exception will be reviewed on a quarterly basis by the regional and local Business Partners.<br/>For example, the software vendor system requirement, require them to logon ad hoc to sign on to make specific changes as per documentation.'
export const accountjustificationTooltip = 'Provide justification for the account function selected, e.g. "this service account is used to run xyz service on abcserver as per software system requirements, see attached document". <br/> <b>Note:</b> Supporting documentation can be uploaded at the end of this form, prior to submission.'

//Service Information//
export const singleServer = 'This will determine whether an AD Managed <br/> Service Account (MSA) will be appropriate for the <br/> requirement. Managed Service Accounts do not <br/> require a password and are automatically <br/> managed by AD.'
export const hostnameTootip = 'This should be the fully qualified domain <br/>   name of the server, <br/>   i.e. NYCGRMAPPP01101.ad.insidemedia.net'
export const ChooseServerTier = 'Service accounts should be environment <br/>  and role specific.<br/> Production service accounts should not be <br/>  used in any other environment, e.g.<br/> DEV, TEST, UAT.'

// Service Account Permisssions//
export const userRights =
    'Each service account must identify the minimum amount of user rights necessary to function on each system, <br/> e.g. access to  shared area on a server,  \\servername\share$ as read only / read write / full control. Further information on this principle is available in the FAQs.'

'Each service account must identify the minimum amount of user rights necessary to function on each system, e.g. access to shared area on a server, \\servername share$  as read only / read write / full control.Further information on this principle is available in the FAQs.'

export const groupMembership = "If it is known that the service account require  membership of an AD group, details of this membership/s should be explicitly recorded here, e.g. required access to  ad\\groupname or servername\\groupname. <br/> This may be defined by software system requirements and supporting documentation can be uploaded at the  end of this form, prior to submission."
export const externalInternalIpDetails = 'If the service account will authenticate against Azure AD the external IP address information for the infrastructure where the account will be running, should be captured here. <br/> If your service account does not authenticate to  <br/> Azure AD please select Internal below.'

//spnAndDelegations//
export const SpnSection = "SPN is Service Principal Name. This is <br/> required when a SQL account on a server <br/>needs to be connected to the service <br/>account. It allows communication of data <br/> between database and the application."

// For PaswordAndSecuritypolicy //
export const passChangeProcedures = 'Please explain in detail any procedures that must occur to manage password changes,explain how it should be done, using what tools, on which exact systems, if the password needs to be updated in the registry or configuration file using a hashing procedure, please explain the procedure.<br/><br/>For example:  <br/>• We input the service account password into the software directly <br/>• We update the following configuration files (list of locations)  <br/>• The password is entered in services and they are encrypted / hashed in the following <br/> way (provide list / see doc / method)'
export const securityOfPassword = "The password should never be listed in clear text in any configuration file or registry. Please verify that you have ensured that the passwords are saved in a secured method.<br/><br/>For example:<br/>• Service is input directly into application and is saved in the software <br/>providers' application in a secure manner <br/>• We have used asp net_set reg to encrypt into the registry or it has been hashed <br/> in some other way."
export const passwordChanaged = "The GroupM domain policy is that all user account passwords expire every 60 days.<br />Azure AD accounts only have the option for 60 days.<br/> On - premise accounts have additional options for quarterly, semi - annually, annually.<br/>If you require the password not to be reset in the standard window listed above, <br/> please detail how often will the password be changed and how you will change it.<br/><br/> <b>Note:</b> The maximum password reset cadence is yearly. "
export const expiryPolicy = 'Briefly explain the reason behind not <br/> choosing default password policy'
export const businessImpact = 'Briefly explain what the impact will be if the <br/> password for the service account is forced to <br/> expire at the default period of every 60 days'
export const riskExposureTooltip = 'Please select the risk exposure that the service account will run on'
export const defaultPasswordExpiryPolicy = 'Detail the risk associated with not <br/> choosing default password policy'
export const accountCompromised = 'Detail what services or data would potentially be <br/> at risk if this service account was compromised'
export const LogonText = "This is the proposed user logon name for the Service Account. This should be validated to ensure it is correct. If it is, check the box below to continue.If the proposed user logon name is incorrect, this should be manually edited and this will then be saved."
export const PrewindowText = "This is the proposed Pre-Windows 2000 user name for the Service Account. This should be validated to ensure it is correct. If it is, check the box below to continue.If the proposed Pre - Windows 2000 user logon name is incorrect, this should be manually edited and this will then be saved."