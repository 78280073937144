//export const routeCheck = () => {
//     return location.pathname.slice(1, 4).toLocaleLowerCase() === 'del';
// }
export const routeCheckForPendingApproval = () => {
    return location.pathname.slice(1, 35).toLocaleLowerCase() === 'myApprovalTasks/pendingApprovalPar'
}

// export const routeCheckForImplementationSummary = () => {
//     return location.pathname.slice(1, 44).toLocaleLowerCase() === 'myimplementationtasks/implementationsummary'
// }

export const routeCheckForAdminSummary = () => {
    return location.pathname.slice(1, 26).toLocaleLowerCase() === 'myadministrativetasks/par'
}
export const routeCheckForPar = () => {
    return location.pathname.slice(1, 4).toLocaleLowerCase() === 'par'
}

// export const routeCheckForPendingImplementation = () => {
//     return location.pathname.slice(1, 44).toLocaleLowerCase() === 'myimplementationtasks/pendingimplementation'
// }

export const routeCheckForImplementationSummarypar = () => {
    return location.pathname.slice(1, 47).toLocaleLowerCase() === 'myimplementationtasks/implementationsummarypar'
}

export const routeCheckForImplementationSummary = () => {
    return location.pathname.slice(1, 47).toLocaleLowerCase() === 'myimplementationtasks/pendingimplementationpar'
}

export const routCheckApprovalSummary = () => {
    return location.pathname.slice(1, 35).toLocaleLowerCase() === 'myapprovaltasks/approvalsummarypar'
}

export const routeCheck = () => {
    return location.pathname.slice(1, 35).toLocaleLowerCase() === 'myapprovaltasks/pendingapprovalpar'
}