import React from 'react';
interface ISvgProps {
    svgText: string,
}

function CustomSvgText({ svgText }: ISvgProps) {
    return (
        <div style={{
            cursor: 'pointer'
        }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="26.035" height="26" viewBox="0 0 26.035 26">
                <g id="Group_8793" data-name="Group 8793" transform="translate(-1233.001 -182)">
                    <path id="_696009861560146810" data-name="696009861560146810" d="M15.008,2a13,13,0,0,0-13,13,12.856,12.856,0,0,0,2.938,8.229l-2.6,2.6A1.268,1.268,0,0,0,3.308,28h11.7a13,13,0,1,0,0-26Z" transform="translate(1231.029 180)" fill="#00b5b1" />
                    <text id="WS" transform="translate(1247 199)" fill="#fff" fontSize="11" fontFamily="Poppins-Medium, Poppins" fontWeight="500" letterSpacing="0.035em"><tspan x="-9" y="0">{svgText}</tspan></text>
                </g>
            </svg>
        </div>

    );
};
export default CustomSvgText;