import { makeStyles } from '@mui/styles';

export const badgeStyles = makeStyles({
  badge: {
    background: '#EF5B2B',
    right: '20px !important',
    color: '#fff',
    font: 'normal normal normal 9px/12px Poppins-Regular !important',
    letterSpacing: 0,
    padding: '0px !important',
  },
});

export const buttonStyles = makeStyles({
  button: {
    borderRadius: '25px !important',
    boxShadow: 'none !important',
    backgroundColor: '#fff !important',
    border: '2px solid #00B5B1 !important',
    color: '#00B5B1 !important',
    font: '14px Poppins-Medium !important',
    padding: '4px 16px',
    '&:hover': {
      background: '#00A5A2 !important',
      // border: '2px solid #00B5B1',
      color: '#fff !important',
      boxShadow: 'none',
    },
    cursor: 'pointer',

  },
  activeButton: {
    backgroundColor: '#00B5B1 !important',
    color: '#fff !important',
  },
});

export const filterStyles = makeStyles({
  root: {
    display: 'inline-block',
  },
});

export const filterPopoverStyles = makeStyles({
  stickySearchContainer: {
    position: 'sticky',
    top: '0',
    padding: '12px 8px 4px 8px',
    zIndex: '2',
    background: '#fff',
  },
  stickySearch: {
    margin: '0 !important',
    // width: 'auto',
    font: 'normal normal normal 12px/18px Poppins-Regular !important',
    letterSpacing: 0,
    color: '#A7A7A7',
    '& > input': {
      padding: '8px 0px 8px 16px',
      border: '1px solid #B9C4CE',
      borderRadius: '25px',
      '&:active, &:focus': {
        borderColor: '#0080FF',
      },
    },
  },
  filterOptionsList: {
    margin: '0px 8px 8px',
    paddingRight: '8px',
    maxHeight: '250px',
    overflowY: 'scroll',
  },
  noRecordsLabel: {
    font: 'normal normal normal 12px/16px Poppins-Regular',
    color: '#A4ADB7',
  },
  formControl: {
    // margin: 'unset !important',
    margin: '12px 0px !important',
    display: 'flex !important',
    alignItems: 'center',
  },
  formLabel: {
    font: 'normal normal normal 13px/13px NotoSerif-Regular !important',
    letterSpacing: 0,
    color: '#0A2756',
  },
  checkbox: {
    paddingLeft: '4px !important',
    paddingRight: '4px !important',
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
  },
  skeletonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: '4px 0px',
    minWidth: '180px',
    width: '100%',
  },
  skeletonCheckbox: {
    width: '10%',
    height: '16px',
    transform: 'none',
    bgcolor: '#EDF4F8',

  },
  skeletonLabel: {
    width: '80%',
    height: '16px',
    transform: 'none',
    bgcolor: '#EDF4F8',
  },
});
