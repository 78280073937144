import { createSlice, current } from '@reduxjs/toolkit'
import { IData } from './IParSubmitData';
import type { PayloadAction } from '@reduxjs/toolkit'
import { IFreshPostedData } from './IParSubmitData';
import { IpostData } from './IParSubmitData';

export const parCommentSlice = createSlice({
    name: 'submitParCommentData',
    initialState: [
        {
            Account_requestor_details: []
        },
        { Account_owner_details: [] },
        { Job_description: [] },
        { Manager_details: [] },
        { Third_Manager_Party_details: [] },
        { ServiceNow_ticket_number: [] },
        { Account_justification: [] },
        { Domain: [] },
    ],
    reducers: {
        submitParComments: (state, action: PayloadAction<any>) => {
            return [...action.payload]
        },
        postFreshComments: (state, action: PayloadAction<IpostData>) => {
            state.map((row) => {
                Object.entries(row).map(([key, value]) => {
                    if (key === action.payload.section) {
                        value.push(action.payload)
                    }
                })
            })
        },
        deleteParComments: (state, action: PayloadAction<IpostData>) => {
            state.map((row) => {
                Object.entries(row).map(([key, value]) => {
                    if (key === action.payload.section) {
                        value.map((item: any, index: number) => {
                            if (item.id === action.payload.id) {
                                value.splice(index, 1)
                            }
                        })
                    }
                })
            })
        },
        editParComments: (state, action: PayloadAction<IpostData>) => {
            state.map((row) => {
                Object.entries(row).map(([key, value]) => {
                    if (key === action.payload.section) {
                        value.map((item: any, index: number) => {
                            if (item.id === action.payload.id) {
                                value.splice(index, 1)
                                value.push(action.payload)
                            }
                        })
                    }
                })
            })
        },
        requestorApproverFreshComments: (state, action: PayloadAction<IpostData>) => {
            state.map((row) => {
                Object.entries(row).map(([key, value]) => {
                    if (key === action.payload.section) {

                        value.map((item: any, index: number) => {

                            if (item.id === action.payload.comment_id) {
                                item.requestor_reply = [...item.requestor_reply, action.payload]
                            }
                        })
                    }
                })
            })


        },
        requestorEditReply: (state, action: PayloadAction<IpostData>) => {
            state.map((row) => {
                Object.entries(row).map(([key, value]) => {
                    if (key === action.payload.section) {

                        value.map((item: any, index: number) => {

                            if (item.id === action.payload.comment_id) {
                                item.requestor_reply = [action.payload]
                            }
                        })
                    }
                })
            })
        },
        requestorDeleteReply: (state, action: PayloadAction<IpostData>) => {

            state.map((row) => {
                Object.entries(row).map(([key, value]) => {
                    if (key === action.payload.section) {

                        value.map((item: any, index: number) => {

                            if (item.id === action.payload.comment_id) {
                                item.requestor_reply = []
                            }
                        })
                    }
                })
            })
        }
    }
});

export const { submitParComments, postFreshComments, deleteParComments, editParComments, requestorApproverFreshComments, requestorEditReply, requestorDeleteReply } = parCommentSlice.actions;
export default parCommentSlice.reducer;