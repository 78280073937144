import { styled } from '@mui/material/styles';
import { useOktaAuth } from '@okta/okta-react';
import LoginLockIcon from '../../assets/images/login-lock-icon.svg';
import LoginBg from '../../assets/images/login_bg.jpeg';
import Brandlogo from '../../components/brandlogo';
import FullScreenBg from '../../components/fullscreen-bg';
import StyledSolidButton from '../../components/button/SolidButton';
import { useHistory } from 'react-router-dom';

const BrandLogoDiv = styled('div')({
  position: 'absolute',
});

const LoadingPanel = styled('div')({
  background: 'white',
  borderRadius: '8px',
  display: 'flex',
  margin: 'auto',
  padding: '32px',
  minWidth: '230px',
  width: '18.75vw',
  maxWidth: '300px',
  flexDirection: 'column',
});

interface Error {
  name: string;
  message: string;
  stack?: string;
}
interface ComponentProps {
  error: Error;
}

const Image = styled('img')({
  width: 'auto',
  marginRight: '8px',
  display: 'inline-flex',
  alignSelf: 'center',
});

function ErrorCallbackComponent({ error }: ComponentProps) {
  const { oktaAuth } = useOktaAuth();
  const history = useHistory()

  const redirectSignout = async () => {
    await oktaAuth.signOut({ clearTokensAfterRedirect: true, postLogoutRedirectUri: '/' });
    history.push('/')
  };

  return (
    <div data-testid="login-errror-screen">
      <FullScreenBg bgimage={LoginBg}>
        <BrandLogoDiv>
          <Brandlogo />
        </BrandLogoDiv>
        <LoadingPanel>
          <div style={{ display: 'flex', marginBottom: '32px' }}>
            <Image src={LoginLockIcon} />
            <div style={{
              fontFamily: 'Poppins-SemiBold',
              fontSize: '1.25rem',
              display: 'inline-flex',
              textAlign: 'center',
              color: '#0A2756',
            }}
            >
              Privileged Access Governance
            </div>
          </div>
          <span style={{ fontSize: '1rem' }}>
            Error:
            {error?.message}
          </span>
          <StyledSolidButton
            actionType="primary"
            onClick={redirectSignout}
            data-testid="back-button"
          >
            Go back
          </StyledSolidButton>
        </LoadingPanel>
      </FullScreenBg>
    </div>
  );
}

export default ErrorCallbackComponent;
