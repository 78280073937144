import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import isEqual from "lodash/isEqual";
import SectionHeader from "../../components/section-header";
import FilterMenu from "../../components/filter-menu";
import CustomDatePicker from "../../components/date-range-picker";
import CustomToggleButton from "../../components/button/CustomToggleButton";
import CustomButton from "../../components/button";
import FilterOptions from "../../components/filter-menu/FilterOptions";
import PaginationBlock from "../../components/pagination-block";
import DynamicTable from "../../components/dynamic-table";
import CustomModal from "../../components/custom-modal";
import useParHook from "./useParHook";
import parSummaryStyles from "./par-summary-styles";
import { ReactComponent as EmptyResultIcon } from "../../assets/icons/no_results_icon.svg";
import { ReactComponent as CancelIcon } from "../../assets/icons/cross_b.svg";

function parDashboard() {
    const {
        handleSort,
        getSortingIcon,
        requestStatusFilter,
        requestIdFilter,
        setCloseFilter,
        closeFilter,
        setDateRangeFilter,
        clearValueHandler,
        tableColumns,
        handleModalClose,
        datepickerProps,
        setDatepickerProps,
        setDateRangeValue,
        modalData,
        menuData,
        setMenuData,
        modalOpen,
        parSummaryData,
        isLoading,
        tableParameters,
        setTableParameters,
        filters,
        setFilters,
        modalActionHandler,
        modalHeaderHandler,
        modalContentHandler,
        emptyFilters,
        handleModalOpen,
    } = useParHook();
    const styleClasses = parSummaryStyles();

    const emptyRequestHandler = () => {
        if (!isLoading.tableLoading && parSummaryData?.results.length === 0) {
            return (
                <Grid item xs={12} data-testid="empty-delegation-screen">
                    <div className={styleClasses.emptyResultContainer}>
                        <EmptyResultIcon />
                        <span>
                            {isEqual(filters, emptyFilters)
                                ? "There are no requests created by you."
                                : "There are no requests matching to the selected filters."}
                        </span>
                    </div>
                </Grid>
            );
        }
        return null;
    };

    return (
        <div className={styleClasses.pageContainer} data-testid="delegation-screen">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <SectionHeader text="PAR Summary" />
                </Grid>
                <Grid item xs={12} className={styleClasses.filtersContainer}>
                    <div className={styleClasses.filterPanel}>
                        <div className={styleClasses.filterIcon}>
                            <FilterListIcon />
                        </div>
                        <div className={styleClasses.filterMenu}>
                            <FilterMenu
                                filterLabel="PAR ID"
                                handleFilterClose={() => console.log("handleClose")}
                                badgeCount={filters.requestId?.length}
                            >
                                <FilterOptions
                                    searchPlaceholder="Search"
                                    allowSearch
                                    optionsList={requestIdFilter}
                                    selectedOptions={filters.requestId}
                                    handleOptionSelection={(e, selection) => {

                                        setFilters((prevFilters) => {
                                            let newRequestIdArray = [...prevFilters.requestId];
                                            console.log('newRequestIdArray', newRequestIdArray)
                                            if (!selection) {
                                                newRequestIdArray = newRequestIdArray.filter(
                                                    (option) => option !== (e.target.value)
                                                );
                                            } else newRequestIdArray.push(e.target.value);
                                            return {
                                                ...prevFilters,
                                                requestId: [...newRequestIdArray],
                                                page: 1,
                                            };
                                        });
                                    }}
                                    isFilterDataLoading={isLoading.filters.requestId}
                                    allowAll
                                    selectAllHandler={(isSelected: boolean) => {

                                        setFilters((prevFilters) => ({
                                            ...prevFilters,
                                            requestId: isSelected
                                                ? requestIdFilter.map(
                                                    (obj: { id: string; value: string }) => obj.id
                                                )
                                                : [],
                                        }));
                                    }}
                                />
                            </FilterMenu>
                        </div>
                        <div className={styleClasses.filterMenu}>
                            <FilterMenu
                                filterLabel="Status"
                                handleFilterClose={() => console.log("handleClose")}
                                badgeCount={filters.status?.length}
                            >
                                <FilterOptions
                                    searchPlaceholder="Search by Status"
                                    allowSearch
                                    // @ts-ignore
                                    optionsList={requestStatusFilter}
                                    selectedOptions={filters.status}
                                    handleOptionSelection={(e, selection) => {
                                        setFilters((prevFilters) => {
                                            let newStatusArray = [...prevFilters.status];
                                            if (!selection) {
                                                newStatusArray = newStatusArray.filter(
                                                    (option) => option !== Number(e.target.value)
                                                );
                                            } else newStatusArray.push(Number(e.target.value));
                                            return {
                                                ...prevFilters,
                                                status: [...newStatusArray],
                                                page: 1,
                                            };
                                        });
                                    }}
                                    isFilterDataLoading={isLoading.filters.status}
                                    allowAll
                                    selectAllHandler={(isSelected: boolean) => {
                                        setFilters((prevFilters: any) => ({
                                            ...prevFilters,
                                            status: isSelected
                                                ? requestStatusFilter.map(
                                                    (obj: { id: number; value: string }) => obj.id
                                                )
                                                : [],
                                        }));
                                    }}
                                />
                            </FilterMenu>
                        </div>
                        <div className={styleClasses.filterMenu}>
                            <FilterMenu
                                filterLabel="Requested On"
                                handleFilterClose={() => {
                                    if (closeFilter) {
                                        setCloseFilter(false);
                                    }
                                }}
                                badgeCount={
                                    filters.requesteOn.endDate && filters.requesteOn.startDate
                                        ? " "
                                        : 0
                                }
                                closeFilterExternally={closeFilter}
                            >
                                <div className={styleClasses.dateRangeToggleContainer}>
                                    <CustomToggleButton
                                        variant="contained"
                                        isSelected={!!datepickerProps.isSingleDate}
                                        onClick={() => {
                                            if (!datepickerProps.isSingleDate) {
                                                setDatepickerProps({
                                                    isSingleDate: true,
                                                    dateRange: {
                                                        startDate: null,
                                                        endDate: null,
                                                    },
                                                });
                                            }
                                        }}
                                    >
                                        Single Date
                                    </CustomToggleButton>
                                    <CustomToggleButton
                                        variant="contained"
                                        isSelected={!datepickerProps.isSingleDate}
                                        onClick={() => {
                                            if (datepickerProps.isSingleDate) {
                                                setDatepickerProps({
                                                    isSingleDate: false,
                                                    dateRange: {
                                                        startDate: null,
                                                        endDate: null,
                                                    },
                                                });
                                            }
                                        }}
                                    >
                                        Date Range
                                    </CustomToggleButton>
                                </div>
                                <div className={styleClasses.dateRangeContainer}>
                                    <CustomDatePicker
                                        isRangePicker={!datepickerProps.isSingleDate}
                                        setDateRangeValue={setDateRangeValue}
                                        dateRange={datepickerProps.dateRange}
                                        disableFuture
                                    />
                                </div>
                                <div className={styleClasses.dateRangeApplyCta}>
                                    <CustomButton
                                        variant="contained"
                                        actionType="secondary"
                                        disabled={!datepickerProps.dateRange.endDate}
                                        onClick={() => setDateRangeFilter()}
                                        isCompact
                                        disableMargin
                                    >
                                        Apply
                                    </CustomButton>
                                </div>
                            </FilterMenu>
                        </div>
                        <CustomButton
                            variant="contained"
                            actionType="tertiary"
                            onClick={() => clearValueHandler()}
                            disableRipple
                            disableMargin
                            disablePadding
                        >
                            CLEAR
                        </CustomButton>
                    </div>
                </Grid >
            </Grid >
            {/* Dynamic Table Code */}
            < Grid item xs={12} >
                <DynamicTable
                    rows={parSummaryData.results}
                    isLoading={isLoading.tableLoading}
                    columns={tableColumns}
                    collapsible={false}
                    pageNumber={tableParameters.page}
                    pageSize={parSummaryData.pageSize}
                >
                </DynamicTable>
            </Grid >
            {/* End of Dynamic Table */}
            {/* pagination started=> */}
            <Grid item xs={12} style={{ paddingTop: "21px" }}>
                <PaginationBlock
                    totalRecords={parSummaryData?.results.length || 0}
                    currentRecordsCount={parSummaryData?.results.length}
                    activePage={tableParameters.page}
                    handlePageChange={(pageNumber) => {

                        setTableParameters((prevParameters: any) => ({
                            ...prevParameters,
                            page: pageNumber,
                        }));
                    }}
                    isLoading={isLoading.tableLoading}
                    pageSize={parSummaryData.pageSize}
                />
            </Grid>
            {/* pagination end=> */}
            {emptyRequestHandler()}


            {menuData.anchorEl && menuData.row && (
                <Menu
                    id="menu-popover"
                    open={!!menuData.anchorEl}
                    anchorEl={menuData.anchorEl}
                    onClose={() =>
                        setMenuData({
                            anchorEl: null,
                            row: {
                                status: null,
                                id: null,
                                delegation_id: null,
                            },
                        })
                    }
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    classes={{ root: styleClasses.moreActionMenu }}
                    disableAutoFocusItem
                >
                    {(menuData.row.status === 'Remediation Requested' || menuData.row.status === 'Pending Approval' ||
                        menuData.row.status === 'Rejected'
                    ) && (
                            <>
                                <MenuItem
                                    // disabled={menuData.row.is_request_to_update}
                                    onClick={() => handleModalOpen("revoke")}
                                    className={styleClasses.moreActionMenuItem}
                                >
                                    <CancelIcon />
                                    <span className={styleClasses.menuLabel}>
                                        Request to revoke
                                    </span>
                                </MenuItem>
                            </>
                        )}
                </Menu>
            )}
            {/* modal code started=> */}
            {modalOpen && (
                <CustomModal
                    modalHeader={modalHeaderHandler(modalData.type)}
                    open={modalOpen}
                    onClose={handleModalClose}
                    modalAction={modalActionHandler(modalData.type)}
                    modalContent={modalContentHandler(modalData.type)}
                />
            )}
            {/* modal code ended=> */}
        </div >
    )
}

export default parDashboard;