/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import Button from '@mui/material/Button';
import { ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';

interface StyledToggleButtonProps extends ButtonProps {
  isSelected: boolean;
}

const StyledToggleButton = styled(Button, {
  skipSx: true,
  shouldForwardProp: (prop: any) => prop !== 'isSelected',
})<StyledToggleButtonProps>(({ isSelected }: { isSelected: boolean }) => ({
  backgroundColor: isSelected ? '#99FDD1' : '#fff',
  color: isSelected ? '#0A2756' : '#00B5B1',
  border: isSelected ? '2px solid #99FDD1' : '2px solid #00B5B1',
  borderRadius: '20px',
  boxShadow: 'none',
  font: 'normal normal normal 12px/18px Poppins-Medium',
  letterSpacing: '0px',
  textTransform: 'unset',
  '&:hover': {
    backgroundColor: isSelected ? '#99FDD1' : '#fff',
    border: isSelected ? '2px solid #99FDD1' : '2px solid #00B5B1',
    boxShadow: 'none',
  },
  '&:disabled': {
    opacity: '0.75',
    backgroundColor: isSelected ? '#99FDD1' : '#fff',
  },
}));

function CustomToggleButton({ isSelected, children, ...rest }: StyledToggleButtonProps) {
  return (
    <StyledToggleButton
      data-testid="custom-toggle-button"
      variant="contained"
      isSelected={isSelected}
      {...rest}
    >
      {children}
    </StyledToggleButton>
  );
}

export default CustomToggleButton;
