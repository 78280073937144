export const routeCheckDelegation = () => {
    return location.pathname.slice(1, 4).toLocaleLowerCase() === 'del';
}



export const routeCheckForPendingApproval = () => {
    return location.pathname.slice(1, 32).toLocaleLowerCase() === 'myapprovaltasks/pendingapproval'
}


export const routeCheckForPendingImplementation = () => {
    return location.pathname.slice(1, 44).toLocaleLowerCase() === 'myimplementationtasks/pendingimplementation'
}

export const routeCheck = () => {
    return location.pathname.slice(1, 32).toLocaleLowerCase() === 'myapprovaltasks/pendingapproval'
}

export const routeCheckForImplementationSummary = () => {
    return location.pathname.slice(1, 44).toLocaleLowerCase() === 'myimplementationtasks/implementationsummary'
}

export const routeCheckForAdminSummary = () => {
    return location.pathname.slice(1, 33).toLocaleLowerCase() === 'myadministrativetasks/delegation'
}

export const routeCheckForApprovalSummary = () => {
    return location.pathname.slice(1, 32).toLocaleLowerCase() === 'myapprovaltasks/approvalsummary'
}
export const routeCheckForImplementationSummaryview = () => {
    return location.pathname.slice(1, 44).toLocaleLowerCase() === 'myimplementationtasks/implementationsummary'
}