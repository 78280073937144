import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import Tab from '@mui/material/Tab';
import isEqual from 'lodash/isEqual';
import { ReactComponent as EmptyResultIcon } from '../../assets/icons/no_results_icon.svg';
import { emptyFilters } from './approvalSummary/approvalSummaryUtils';
import approvalSummaryStyles from './approval-summary-styles';
import PendingSummaryPanel from './pendingAprroverSummary/pendingApproverSummaryPanel';
import ApprovalSummaryPanel from './approvalSummary/arrovalSummaryPanel';
// import Outlet from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  tabPanel: {
    padding: '0px 0px 0px 10px',
    width: '100%',
  },
  customLabelColor: {
    '& button': {
      fontFamily: 'Poppins-Medium !important',
      color: '#0A2756 !important',
      textTransform: 'unset',
      '&.MuiTab-root.Mui-selected': {
        color: '#0080FF !important',
        fontFamily: 'Poppins-Medium !important',
        textDecoration: 'underline',
      },
    },
  },
}));

function ApprovalSummary() {
  const styleClasses = approvalSummaryStyles();
  const classes = useStyles();
  const [value, setValue] = useState('1');
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <div className={styleClasses.pageContainer} data-testid="approvalsummary-screen">
      <Grid container spacing={2}>
        <TabContext value={value}>
          <TabList
            onChange={handleChange}
            className={classes.customLabelColor}
          >
            <Tab
              disableTouchRipple
              disableFocusRipple
              disableRipple
              label={<span>Pending Approvals</span>}
              value="1"
            />
            <Tab
              disableTouchRipple
              disableFocusRipple
              disableRipple
              label={<span>Approval Summary</span>}
              value="2"
            />
          </TabList>

          <TabPanel value="1" classes={{ root: classes.tabPanel }}>
            <PendingSummaryPanel />
          </TabPanel>
          <TabPanel value="2" classes={{ root: classes.tabPanel }}>
            <ApprovalSummaryPanel />
          </TabPanel>
        </TabContext>
      </Grid>
    </div>
  );
}
export default ApprovalSummary;
