import React from 'react';

export interface IDateRangeObj {
    startDate: Date | null;
    endDate: Date | null;
}
export interface IFiltersObj {
    requestId: string[],
    status: number[],
    requesteOn: IDateRangeObj,
}

export interface ITableParameters {
    page: number
    orderBy: {
        order: string,
        column: string,
    },
}

export const emptyFilters = {
    requestId: [],
    status: [],
    requesteOn: {
        startDate: null,
        endDate: null,
    },
};

export const initialTableParameters = {
    page: 1,
    orderBy: {
        order: 'asc',
        column: '',
    },
};

export function createColumns(
    key: number,
    label: string,
    icon: any,
    id: string,
    isActive: boolean,
    handleClick?: (_e: any) => void,
    style?: React.CSSProperties,
) {
    return {
        key, label, icon, handleClick, id, isActive, style,
    };
}

export function createRowCells(
    columnId: string,
    renderComponent: any,
    id: string,
    handleClick: undefined | ((_e: any) => void),
    columnNumber: number,
    style?: React.CSSProperties,
) {
    return {
        columnId, renderComponent, id, handleClick, columnNumber, style,
    };
}

export const parColumnMapping = {
    id: 'id',
    request_number: 'request_number',
    assignedTo: 'assign_to',
    status: 'status',
    requestedOn: 'request_date',
    lastUpdated: 'updated_at',
    completionDate: 'completion_date',
    actions: 'actions'
};



export interface IParSummaryData {
    assign_to: string,
    completion_date: string,
    created_at: string,
    delegation_id: string,
    id: number,
    is_active: boolean,
    remediation_count: number,
    request_date: string,
    request_type: string,
    request_type_id: string,
    stage: string,
    stage_id: string,
    status: string,
    status_id: string,
    updated_at: string,
    request_number: string
}
