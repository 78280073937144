import { useEffect, useState } from 'react';
import { Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import FormikContainerPar from '../../components/formik-containers/formikContainerPar/FormikContainerPar';
import BreadCrumbs from '../../components/breadcrumb';
// import { submitParComments } from '../../../../appStore/reducer/submitParApproverComments';
// import { editParComments, deleteParComments } from '../../../../appStore/reducer/submitParApproverComments';
import { submitParComments } from '../../appStore/reducer/submitParApproverComments';
import { updatedFieldHistoryPar } from '../../appStore/reducer/fieldHistoryReducer';

// import { fetchAllCommentsReplyPart, fetchfieldHistoryForLastUpdate } from '../../api-services/review-delegation';
import {
    fetchAllCommentsReply,
    fetchfieldHistoryForLastUpdate,
} from "../../api-services/review-delegation";
import { fetchAllCommentsReplyPart, getCloudAad, getPostOnpremAad, getPostOnpremAd } from '../../api-services/par';
import { useDispatch, useSelector } from 'react-redux';
import { fieldUpdationHistory } from '../../appStore/reducer/reviewActionReducer';
import { chatHistoryData } from '../../appStore/reducer/chatHistoryReducer';
import { getParRequestData, getAllCountryByCityId } from '../../api-services/par';
import { RootState } from '../../appStore/store';
import { format, isDate } from 'date-fns';
import { searchUserByQueryDetails } from '../../api-services/common-api';
import Loader from '../../components/loader';
import { aadImplementationOnpremTasklist } from '../../appStore/reducer/aadImplementationOnprem';
import { cloudImplementationOnpremTasklist } from '../../appStore/reducer/cloudImplementation';
import adImplementationOnprem, { adImplementationOnpremTasklist } from '../../appStore/reducer/adImplementationOnprem';
import CustomModal from '../../components/custom-modal';
import CustomButton from '../../components/button';
import approvalSummaryStyles from '../approvalSummary/approval-summary-styles';
import { routeCheckForAdminSummary } from '../../components/formik-containers/formikContainerDelegation/formsSteps/routingConditional';

const styles = makeStyles({
    bodyMainDiv: {
        display: 'flex',
    },
    topDiv: {
        padding: '16px',
    },
});


function FormPagePar() {
    const { loginuserdata, loginsyncupdata } = useSelector((state: RootState) => state.loginData)
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [accountOwnerList, setaccountOwnerList] = useState<{
        first_name: string,
        last_name: string,
        email: string,
        country: string,
        city: string,
        phone: string | number,
        department: string,
        company: string,
        title: string,
    }[]>([]);


    const { requestId } = useParams<{ requestId: string }>();
    const styleClasses = styles();
    const styleClassess = approvalSummaryStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [activeStep, setActiveStep] = useState(0);
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [countryList, setCountryList] = useState('');
    const formatDate = (dateValue: any) => (dateValue && isDate(new Date(dateValue)) ? format(new Date(dateValue), 'dd MMM yyyy') : '');

    let okatValidationCheckObject = {
        oktaCompany: false,
        oktaPhone: false,
        oktaCity: false,
        oktaCountry: false,
        oktaJobTitle: false,
        oktaDepartment: false,
    }


    const serachCountryByCity = (cityName: string) => {
        getAllCountryByCityId(cityName).then((res) => {
            setCountryList(res.data.country);
        }).catch((err) => {
            console.log(err);
        })
    }
    const routeCheckForImplementationSummarypar = () => {
        return location.pathname.slice(1, 47).toLocaleLowerCase() === 'myimplementationtasks/implementationsummarypar'
    }
    const routCheckApprovalSummary = () => {
        return location.pathname.slice(1, 35).toLocaleLowerCase() === 'myapprovaltasks/approvalsummarypar'
    }
    const routeCheckForAdminSummary = () => {
        return location.pathname.slice(1, 26).toLocaleLowerCase() === 'myadministrativetasks/par'
    }
    const getImplementationTasklistApis = (domain: string, implementer: string, requestId: any, delRequestId: any) => {
        if ((routeCheckForImplementationSummarypar() || routCheckApprovalSummary() || routeCheckForAdminSummary())) {
            getPostOnpremAd(requestId).then((res) => {
                dispatch(adImplementationOnpremTasklist(res.data))
            }).catch(error => error);
            getPostOnpremAad(requestId).then((res) => {
                dispatch(aadImplementationOnpremTasklist(res.data))
            }).catch(error => error);
            getCloudAad(requestId).then((res) => {
                dispatch(cloudImplementationOnpremTasklist(res.data))
            }).catch(error => error);

        } else if ((domain === 'insidemedia.onmicrosoft.com') && implementer === 'On-premise implementation engineer') {
            getPostOnpremAad(requestId).then((res) => {
                dispatch(aadImplementationOnpremTasklist(res.data))
            }).catch(error => error);

        } else if ((domain !== 'insidemedia.onmicrosoft.com') && implementer === 'On-premise implementation engineer') {
            getPostOnpremAd(requestId).then((res) => {
                dispatch(adImplementationOnpremTasklist(res.data))
            }).catch(error => error);
        } else if (implementer === 'Cloud implementation engineer' && (domain === 'insidemedia.onmicrosoft.com')) {
            getPostOnpremAad(requestId).then((res) => {
                dispatch(aadImplementationOnpremTasklist(res.data))
            }).catch(error => error);
            getCloudAad(requestId).then((res) => {
                dispatch(cloudImplementationOnpremTasklist(res.data))
            }).catch(error => error);
        }
    }


    useEffect(() => {
        if (!requestId) {
            history.push('/par');
        }
        getParRequestData(requestId)
            .then((successData) => {
                if (successData.message === 'okta account is not created for given user') {
                    enqueueSnackbar('Okta account is not created for given user', { variant: 'error' });
                    if (routeCheckForAdminSummary()) {
                        history.push(`/myadministrativeTasks/`)
                    } else {
                        history.push('/par');
                    }
                    return;
                    // setModalOpen(true);
                }
                dispatch(submitParComments(successData.data[1].comments_history))
                dispatch(updatedFieldHistoryPar(successData.data[2].fields_history))
                getImplementationTasklistApis(successData.data[0].par_form_data.par_domain == 'insidemedia.onmicrosoft.com (Azure)' ? successData.data[0].par_form_data.par_domain.replace(' (Azure)', '') : successData.data[0].par_form_data.par_domain,
                    successData.data[0].par_form_data.implementer_type,
                    requestId,
                    successData.data[0].par_form_data.del_request_id)
                localStorage.setItem('historyDataOfMainSchema', JSON.stringify(successData.data[0].par_form_data))
                if (successData.data[0].par_form_data.del_request_id === null) {
                    if (successData.data[0].par_form_data.dataverse && successData.data[0].par_form_data.dataverse.length > 0) {
                        okatValidationCheckObject.oktaCity = successData.data[0].par_form_data.dataverse.value[0].new_city !== null ? true : false;
                        okatValidationCheckObject.oktaCompany = successData.data[0].par_form_data.dataverse.value[0].new_company !== null ? true : false;
                        okatValidationCheckObject.oktaCountry = successData.data[0].par_form_data.dataverse.value[0].new_country !== null ? true : false;
                        okatValidationCheckObject.oktaDepartment = successData.data[0].par_form_data.dataverse.value[0].new_department !== null ? true : false;
                        okatValidationCheckObject.oktaJobTitle = successData.data[0].par_form_data.dataverse.value[0].new_title !== null ? true : false;
                        okatValidationCheckObject.oktaPhone = successData.data[0].par_form_data.dataverse.value[0].new_primaryphone !== null ? true : false;
                        if (successData.data[0].par_form_data.dataverse.value[0].new_country === null) {
                            successData.data[0].par_form_data.dataverse.value[0].new_city && serachCountryByCity(successData.data[0].par_form_data.dataverse.value[0].new_city);
                        } else if (successData.data[0].par_form_data.par_country === null) {
                            (successData.data[0].par_form_data.par_city !== '') && serachCountryByCity(successData.data[0].par_form_data.par_city);
                        }
                        successData.data[0].par_form_data.status_label === 'Draft' && setData({
                            ...successData.data[0].par_form_data,
                            ...okatValidationCheckObject,
                            par_date_raised: formatDate(successData.data[0].par_form_data.par_date_raised),
                            par_first_name: successData.data[0].par_form_data.par_requestor_first_name,
                            par_last_name: successData.data[0].par_form_data.par_requestor_last_name,
                            par_email: successData.data[0].par_form_data.par_requestor_email,
                            // par_email: (successData.data[0].par_form_data.dataverse.length > 0) ? successData.data[0].par_form_data.dataverse.value[0].new_primaryemail : '',
                            par_department: okatValidationCheckObject.oktaDepartment ? successData.data[0].par_form_data.dataverse.value[0].new_department : successData.data[0].par_form_data.par_department,
                            par_company: okatValidationCheckObject.oktaCompany ? successData.data[0].par_form_data.dataverse.value[0].new_company : successData.data[0].par_form_data.par_company,
                            par_country: okatValidationCheckObject.oktaCountry ? successData.data[0].par_form_data.dataverse.value[0].new_country : successData.data[0].par_form_data.par_country,
                            par_city: okatValidationCheckObject.oktaCity ? successData.data[0].par_form_data.dataverse.value[0].new_city : successData.data[0].par_form_data.par_city,
                            par_phone: okatValidationCheckObject.oktaPhone ? successData.data[0].par_form_data.dataverse.value[0].new_primaryphone : successData.data[0].par_form_data.par_phone,
                            par_job_title: okatValidationCheckObject.oktaJobTitle ? successData.data[0].par_form_data.dataverse.value[0].new_title : successData.data[0].par_form_data.par_job_title,
                            par_domain: successData.data[0].par_form_data.par_domain == 'insidemedia.onmicrosoft.com (Azure)' ? successData.data[0].par_form_data.par_domain.replace(' (Azure)', '') : successData.data[0].par_form_data.par_domain
                        })
                    } else {
                        (setData({
                            ...successData.data[0].par_form_data,
                            par_first_name: successData.data[0].par_form_data.par_requestor_first_name,
                            par_last_name: successData.data[0].par_form_data.par_requestor_last_name,
                            par_email: successData.data[0].par_form_data.par_requestor_email,
                            ...okatValidationCheckObject,
                            par_date_raised: formatDate(successData.data[0].par_form_data.par_date_raised),
                            par_domain: successData.data[0].par_form_data.par_domain == 'insidemedia.onmicrosoft.com (Azure)' ? successData.data[0].par_form_data.par_domain.replace(' (Azure)', '') : successData.data[0].par_form_data.par_domain
                        }))
                    }
                } else if (successData.data[0].par_form_data.del_request_id !== null) {
                    if (successData.data[0].par_form_data.dataverse && successData.data[0].par_form_data.dataverse.length > 0) {
                        okatValidationCheckObject.oktaCity = successData.data[0].par_form_data.dataverse.value[0].new_city !== null ? true : false;
                        okatValidationCheckObject.oktaCompany = successData.data[0].par_form_data.dataverse.value[0].new_company !== null ? true : false;
                        okatValidationCheckObject.oktaCountry = successData.data[0].par_form_data.dataverse.value[0].new_country !== null ? true : false;
                        okatValidationCheckObject.oktaDepartment = successData.data[0].par_form_data.dataverse.value[0].new_department !== null ? true : false;
                        okatValidationCheckObject.oktaJobTitle = successData.data[0].par_form_data.dataverse.value[0].new_title !== null ? true : false;
                        okatValidationCheckObject.oktaPhone = successData.data[0].par_form_data.dataverse.value[0].new_primaryphone !== null ? true : false;
                        if (successData.data[0].par_form_data.dataverse.value[0].new_country === null) {
                            successData.data[0].par_form_data.dataverse.value[0].new_city && serachCountryByCity(successData.data[0].par_form_data.dataverse.value[0].new_city);
                        } else if (successData.data[0].par_form_data.par_country === null) {
                            (successData.data[0].par_form_data.par_city !== '') && serachCountryByCity(successData.data[0].par_form_data.par_city);
                        }
                        successData.data[0].par_form_data.status_label === 'Draft' && setData({
                            ...successData.data[0].par_form_data,
                            ...okatValidationCheckObject,
                            par_date_raised: formatDate(successData.data[0].par_form_data.par_date_raised),
                            // par_is_third_party_company: successData.data[0].par_form_data.admin_company,
                            // par_first_name: successData.data.par_requestor_first_name,
                            // par_last_name: successData.data.par_requestor_last_name,
                            // par_email: successData.data.par_requestor_email,
                            // par_email: (successData.data.dataverse.length > 0) ? successData.data.dataverse.value[0].new_primaryemail : '',
                            par_department: okatValidationCheckObject.oktaDepartment ? successData.data[0].par_form_data.dataverse.value[0].new_department : successData.data[0].par_form_data.par_department,
                            par_company: okatValidationCheckObject.oktaCompany ? successData.data[0].par_form_data.dataverse.value[0].new_company : successData.data[0].par_form_data.par_company,
                            par_country: okatValidationCheckObject.oktaCountry ? successData.data[0].par_form_data.dataverse.value[0].new_country : successData.data[0].par_form_data.par_country,
                            par_city: okatValidationCheckObject.oktaCity ? successData.data[0].par_form_data.dataverse.value[0].new_city : successData.data[0].par_form_data.par_city,
                            par_phone: okatValidationCheckObject.oktaPhone ? successData.data[0].par_form_data.dataverse.value[0].new_primaryphone : successData.data[0].par_form_data.par_phone,
                            par_job_title: okatValidationCheckObject.oktaJobTitle ? successData.data[0].par_form_data.dataverse.value[0].new_title : successData.data[0].par_form_data.par_job_title,
                            par_domain: successData.data[0].par_form_data.par_domain == 'insidemedia.onmicrosoft.com (Azure)' ? successData.data[0].par_form_data.par_domain.replace(' (Azure)', '') : successData.data[0].par_form_data.par_domain

                        })
                    } else {
                        (setData({
                            ...successData.data[0].par_form_data,
                            ...okatValidationCheckObject,
                            par_date_raised: formatDate(successData.data[0].par_form_data.par_date_raised),
                            par_is_third_party_company: successData.data[0].par_form_data.admin_company,
                            par_domain: successData.data[0].par_form_data.par_domain == 'insidemedia.onmicrosoft.com (Azure)' ? successData.data[0].par_form_data.par_domain.replace(' (Azure)', '') : successData.data[0].par_form_data.par_domain
                        }))
                    }
                }

                // (
                //     setData({
                //         ...successData.data,
                //         par_requestor_first_name: loginsyncupdata.first_name,
                //         par_requestor_last_name: loginsyncupdata.last_name,
                //         par_requestor_email: loginsyncupdata.email,
                //         par_date_raised: formatDate(successData.data.par_date_raised),
                //         par_first_name: loginsyncupdata.first_name,
                //         par_last_name: loginsyncupdata.last_name,
                //         par_email: loginsyncupdata.email,
                //         par_department: selectedAccountOwner?.department ? selectedAccountOwner?.department : '',
                //         par_company: selectedAccountOwner?.company ? selectedAccountOwner?.company : '',
                //         par_country: selectedAccountOwner?.country ? selectedAccountOwner?.country : '',
                //         par_city: selectedAccountOwner?.city ? selectedAccountOwner?.city : '',
                //         par_phone: selectedAccountOwner?.phone ? selectedAccountOwner?.phone : '',
                //         par_job_title: selectedAccountOwner?.title ? selectedAccountOwner?.title : '',
                //     })
                // ) : setData({
                //     ...successData.data,
                //     par_date_raised: formatDate(successData.data.par_date_raised),
                // })
                // const parData = {
                //     ...successData.data,
                //     par_requestor_first_name: loginsyncupdata.first_name,
                //     par_requestor_last_name: loginsyncupdata.last_name,
                //     par_requestor_email: loginsyncupdata.email,
                //     par_date_raised: formatDate(successData.data.par_date_raised)

                // }
                // setData(parData);
                setIsLoading(false);
            }).catch((err) => {
                console.error(err);
                setIsLoading(false);
                enqueueSnackbar('Something went wrong while fetching request data.', { variant: 'error' });
                setTimeout(() => history.push('/par'));
            });
    }, []);


    if (isLoading) {
        return (
            <div style={{ padding: '0px 32px' }}>
                <Loader openModal={true} />
            </div>
        );
    }


    const BreadCrumbHandler = (route: string) => {
        const newRoute = route.slice(1, 5).toLocaleLowerCase();

        switch (newRoute) {
            case 'par/':
                return (
                    <BreadCrumbs
                        breadcrumbData={[
                            {
                                key: 1,
                                title: 'PAR',
                                href: '/par',
                            }, {
                                key: 2,
                                title: `PAR ${data?.request_number}`,
                                href: '',
                            },
                        ]}
                    />
                )
            case 'myap':
                return (
                    <BreadCrumbs
                        breadcrumbData={[
                            {
                                key: 1,
                                title: 'My Approval Tasks',
                                href: '/myApprovalTasks',
                            }, {
                                key: 2,
                                title: `PAR ${data?.request_number}`,
                                href: '',
                            },
                        ]}
                    />
                )
            case 'myad':
                return (
                    <BreadCrumbs
                        breadcrumbData={[
                            {
                                key: 1,
                                title: 'My Administrative Task',
                                href: '/myadministrativeTasks',
                            }, {
                                key: 2,
                                title: `PAR ${data?.request_number}`,
                                href: '',
                            },
                        ]}
                    />
                );
            case "myim":
                return (
                    <BreadCrumbs
                        breadcrumbData={[
                            {
                                key: 1,
                                title: "My Implementation Task",
                                href: "/myImplementationTasks",
                            },
                            {
                                key: 2,
                                title: `${data?.request_number}`,
                                href: "",
                            },
                        ]}
                    />
                );
            default: return null;

        }


    };
    // const handleModalClose = () => {
    //     setModalOpen(false);
    //     history.push('/par');
    // }

    return (
        <>
            <div className={styleClasses.topDiv}>
                <div style={{ paddingLeft: '7px' }}>
                    {BreadCrumbHandler(location.pathname)}
                </div>
                <div className={styleClasses.bodyMainDiv}>
                    <FormikContainerPar
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        data={data}
                        requestId={requestId}
                        path={location.pathname}
                    />
                </div>
            </div>
            {/* <CustomModal
                modalHeader={'Note'}
                open={modalOpen}
                onClose={handleModalClose}
                modalAction={
                    <div className={styleClassess.modalActionDiv}>
                        <CustomButton
                            variant="contained"
                            actionType="primary"
                            disableMargin
                            onClick={() => handleModalClose()}
                        >
                            Ok
                        </CustomButton>
                    </div>}
                modalContent={
                    <div>
                        <div className={styleClassess.modalContentDiv}>
                            Okta account is not created for given user
                        </div>
                    </div>
                }
            /> */}
        </>
    );
}
export default FormPagePar;
