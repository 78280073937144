import React, { useRef, useState, useEffect } from 'react'
import CustomModal from '../custom-modal';
// import IdleTimer from 'react-idle-timer'
import CountdownTimer from './CountdownTimer'
import { useIdleTimer } from "react-idle-timer";
import { formikContainerStyles } from './useStyles';
import CustomButton from '../button';
import { useOktaAuth } from "@okta/okta-react";

// Modal.setAppElement('#root')
const IdleTimerContainer = (props) => {
    const { oktaAuth, authState } = useOktaAuth();
    const styleClasses = formikContainerStyles();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    //   let [isTime,setTime] = useState('10');
    let isAUth = props.onAuth;
    const idleTimerRef = useRef(null);
    const sessionTimeoutRef = useRef(null);
    const tracker = () => {
        window.addEventListener("mousemove", resetTimer, false);
        window.addEventListener("mousedown", resetTimer, false);
        window.addEventListener("keypress", resetTimer, false);
        window.addEventListener("DOMMouseScroll", resetTimer, false);
        window.addEventListener("touchmove", resetTimer, false);
        window.addEventListener("MSPointerMove", resetTimer, false);
    }

    const onIdle = () => {

        setModalIsOpen(true)
        sessionTimeoutRef.current = setTimeout(logOut, 120000)
    }
    const resetTimer = () => {
        clearTimeout(sessionTimeoutRef.current)
        // setModalIsOpen(false);
    }
    const stayActive = () => {

        setModalIsOpen(false);
        clearTimeout(sessionTimeoutRef.current)

    }

    const idleTimer = useIdleTimer({
        crossTab: true,
        ref: idleTimerRef,
        timeout: 1680 * 1000,
        onIdle: onIdle,
    });
    tracker();

    const logOut = async () => {
        oktaAuth.tokenManager.clear();
        await oktaAuth.signOut();
        //   {
        //   revokeAccessToken: true,
        //   revokeRefreshToken: true,
        //   clearTokensBeforeRedirect: true,
        //   postLogoutRedirectUri: "/",
        // });
        handleClose();
        clearTimeout(sessionTimeoutRef.current)
    };

    const handleClose = () => {
        setModalIsOpen(false);
    }
    return (
        <div>
            {/* {
                isLoggedIn ? <h2>Hello shantanu</h2> : <h2>Hello Guest</h2>
            } */}
            <CustomModal
                open={modalIsOpen}
                onClose={handleClose}
                modalHeader={`You've been idle for a while!`}
                modalContent={
                    <div>
                        <div className={styleClasses.modalContentDiv}>
                            You have been inactive for 28 minutes and you will be logged out in
                            <CountdownTimer />
                        </div>
                    </div>

                }
                modalAction={
                    <div className={styleClasses.modalActionDiv}>
                        <CustomButton
                            variant="contained"
                            actionType="secondary"
                            onClick={logOut}
                        >
                            Log me out
                        </CustomButton>
                        <CustomButton
                            variant="contained"
                            actionType="primary"
                            disableMargin
                            onClick={stayActive}
                        >
                            Stay logged in
                        </CustomButton>
                    </div>
                }
            >

            </CustomModal>
            <div idleTimer={idleTimer} ></div>
            {/* <IdleTimer ref={idleTimerRef}
                timeout={50 * 1000}
                onIdle={onIdle}
            > </IdleTimer> */}
        </div>
    )
}
export default IdleTimerContainer;