import { createSlice, current } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export const parUpdatedFieldHistorySlice = createSlice({
    name: 'fieldHistoryParCommentsData',
    initialState: [
        { Account_requestor_details: [] },
        { Account_owner_details: [] },
        { Job_description: [] },
        { Manager_details: [] },
        { Third_Manager_Party_details: [] },
        { ServiceNow_ticket_number: [] },
        { Account_justification: [] },
        { Domain: [] }
    ],

    reducers: {
        updatedFieldHistoryPar: (state, action: PayloadAction<any>) => {
            return [...action.payload]
        },
    }
});

export const { updatedFieldHistoryPar } = parUpdatedFieldHistorySlice.actions;
export default parUpdatedFieldHistorySlice.reducer;