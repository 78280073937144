import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import FormikControl from '../../../formik-conrtols/FormikControl';
import FieldLabel from '../../../field-label';
import { externalInternalIpDetails, groupMembership, userRights } from '../tooltips';
import { RootState } from '../../../../appStore/store';
import HistoryTable from '../../../../modules/sar/comments/historyTable';
import CommentSubmission from '../../../../modules/sar/comments/commentSubmission';
import { parStyles, sarUseStyles } from '../../useStyles';
import { format, isDate } from 'date-fns';
import { routeCheck, routeCheckForAdminSummary, routeCheckForApprovalSummary, routeCheckForImplementationSummary, routeCheckForPendingApproval } from './routingConditional';

function ServiceAccountPremissions({ isDisabled }: { isDisabled: boolean }) {
    const classes = sarUseStyles()
    const styleClasses = parStyles();
    const formikCtx: any = useFormikContext();
    const remediated_by = formikCtx.values.remediation_by
    const remediationCount = formikCtx.values.remediation_count;
    const { fieldHistorySarCommentsData } = useSelector((state: RootState) => state);
    const { submitSarCommentData } = useSelector((state: RootState) => state);
    const formatDateInside = (dateValue: any) => (dateValue && isDate(new Date(dateValue)) ? format(new Date(dateValue), 'MMM dd, yyyy') : '');

    const checkForEditReviewFlow = () => {
        return routeCheck() || routeCheckForAdminSummary() || routeCheckForImplementationSummary() || routeCheckForApprovalSummary()
    }

    const [externalInternalIp, SetExternalInternalIp] = useState([{ label: 'Static', value: 'Static' }, { label: 'Dynamic', value: 'Dynamic' }, {
        label: 'Internal only', value: 'InternalOnly',
    }]);

    //For showing external & internal ip details dyanamically
    useEffect(() => {
        if (formikCtx.values.sar_domain_location === "ad.insidemedia.net") {
            SetExternalInternalIp(externalInternalIp.filter((item) => item.value === "InternalOnly"))
        } else {
            SetExternalInternalIp(externalInternalIp.filter((item) => item.value !== "InternalOnly"))
        }
    }, [formikCtx.values.sar_domain_location])

    return (
        <>

            <Grid container paddingTop={'12px'} >
                <Grid item xs={10}>
                    <>
                        <FieldLabel Title="User rights and privileges" tooltipMsg={userRights} required />
                    </>
                    <>
                        <div className={styleClasses.updatedByStyle}>
                            {/* @ts-ignore */}
                            {(fieldHistorySarCommentsData[14].User_rights_and_privileges?.length > 0) && `Updated on ${formatDateInside(fieldHistorySarCommentsData[14].User_rights_and_privileges[fieldHistorySarCommentsData[14].User_rights_and_privileges?.length - 1].last_updated)}`}
                        </div>
                    </>
                </Grid>

                <Grid item xs={2}>
                    {(checkForEditReviewFlow() && remediationCount > 0) ? (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="User_rights_and_privileges"
                                subSection=''
                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[14].User_rights_and_privileges : null}
                            />
                            <CommentSubmission
                                sectionName='User_rights_and_privileges'
                                subSection=''
                                updateType=''
                                remediation_by={remediated_by}
                                data={submitSarCommentData.length > 0 ? submitSarCommentData[14].User_rights_and_privileges : null}
                            />
                        </div>
                    ) : routeCheckForPendingApproval() && (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="User_rights_and_privileges"
                                subSection=''
                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[14].User_rights_and_privileges : null}
                            />
                            <CommentSubmission
                                sectionName='User_rights_and_privileges'
                                subSection=''
                                updateType='Select update'
                                remediation_by={remediated_by}
                                data={submitSarCommentData.length > 0 ? submitSarCommentData[14].User_rights_and_privileges : null}
                            />
                        </div>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <FormikControl
                        control="textfield"
                        name="sar_user_rights_and_privileges"
                        disabled={isDisabled}
                        placeholder="Provide user rights & privileges"
                        multiline
                        minRows={5}
                    />
                </Grid>
            </Grid >
            <Grid container paddingTop={'12px'} >
                <Grid item xs={10}>
                    <>
                        <FieldLabel Title="Group membership" tooltipMsg={groupMembership} required />
                    </>
                    <>
                        <div className={styleClasses.updatedByStyle}>
                            {/* @ts-ignore */}
                            {(fieldHistorySarCommentsData[32].Group_membership?.length > 0) && `Updated on ${formatDateInside(fieldHistorySarCommentsData[32].Group_membership[fieldHistorySarCommentsData[32].Group_membership?.length - 1].last_updated)}`}
                        </div>
                    </>
                </Grid>
                <Grid item xs={2}>
                    {(checkForEditReviewFlow() && remediationCount > 0) ? (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="Group_membership"
                                subSection=''
                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[32].Group_membership : null}
                            />
                            <CommentSubmission
                                sectionName='Group_membership'
                                subSection=''
                                updateType='Select update'
                                remediation_by={remediated_by}
                                data={submitSarCommentData.length > 0 ? submitSarCommentData[32].Group_membership : null}
                            />
                        </div>
                    ) : routeCheckForPendingApproval() && (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="Group_membership"
                                subSection=''
                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[32].Group_membership : null}
                            />
                            <CommentSubmission
                                sectionName='Group_membership'
                                subSection=''
                                updateType='Select update'
                                remediation_by={remediated_by}
                                data={submitSarCommentData.length > 0 ? submitSarCommentData[32].Group_membership : null}
                            />
                        </div>
                    )}
                </Grid>
            </Grid >
            <Grid container rowSpacing={3} columnSpacing={10} >
                <Grid item xs={12}>
                    <FormikControl
                        control="textfield"
                        name="sar_group_membership"
                        disabled={isDisabled}
                        placeholder="Provide group membership"
                        multiline
                        minRows={5}
                    />
                </Grid>

            </Grid>
            <Grid container paddingTop={'12px'} >
                <Grid item xs={10}>
                    <>
                        <FieldLabel Title="External and internal IP details" tooltipMsg={externalInternalIpDetails} required />
                    </>
                    <>
                        <div className={styleClasses.updatedByStyle}>
                            {/* @ts-ignore */}
                            {(fieldHistorySarCommentsData[16].External_And_Internal_IP_Details?.length > 0) && `Updated on ${formatDateInside(fieldHistorySarCommentsData[16].External_And_Internal_IP_Details[fieldHistorySarCommentsData[16].External_And_Internal_IP_Details?.length - 1].last_updated)}`}
                        </div>
                    </>
                </Grid>
                <Grid item xs={2}>
                    {(checkForEditReviewFlow() && remediationCount > 0) ? (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="External_And_Internal_IP_Details"
                                subSection=''
                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[16].External_And_Internal_IP_Details : null}
                            />
                            <CommentSubmission
                                sectionName='External_And_Internal_IP_Details'
                                subSection=''
                                updateType='Select update'
                                remediation_by={remediated_by}
                                data={submitSarCommentData.length > 0 ? submitSarCommentData[16].External_And_Internal_IP_Details : null}
                            />
                        </div>
                    ) : routeCheckForPendingApproval() && (
                        <div className={styleClasses.mainSectionDiv}>
                            <HistoryTable
                                parId={formikCtx.values.request_id}
                                section="External_And_Internal_IP_Details"
                                subSection=''
                                data={fieldHistorySarCommentsData.length > 0 ? fieldHistorySarCommentsData[16].External_And_Internal_IP_Details : null}
                            />
                            <CommentSubmission
                                sectionName='External_And_Internal_IP_Details'
                                subSection=''
                                updateType='Select update'
                                remediation_by={remediated_by}
                                data={submitSarCommentData.length > 0 ? submitSarCommentData[16].External_And_Internal_IP_Details : null}
                            />
                        </div>
                    )}
                </Grid>
                <Grid item xs={4}>
                    <FormikControl
                        disabled={isDisabled}
                        control="radio"
                        name="sar_external_internal_ip_range"
                        radioGrouplabels={externalInternalIp}
                    />
                </Grid>
            </Grid >
            {
                formikCtx.values.sar_domain_location !== 'ad.insidemedia.net' &&
                < Grid container paddingTop={'12px'} >
                    <Grid item xs={12}>
                        <FieldLabel Title={formikCtx.values.sar_external_internal_ip_range == 'Static' ? "Specify the static external IP address/es or IP ranges where the service account will be run from" : "Please specify the external IP or the range of IP addresses or the URL or website if this needs to be whitelisted"} required />
                    </Grid>
                    <Grid item xs={12}>
                        <FormikControl
                            control="textfield"
                            name="sar_external_internal_ip_details"
                            disabled={isDisabled}
                            multiline
                            minRows={5}
                        />
                    </Grid>
                </Grid >
            }
        </>
    )
}

export default ServiceAccountPremissions;