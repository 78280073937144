import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  Formik,
  Form,
  ArrayHelpers,
  FieldArray,
  FormikProps,
  FormikValues,
} from "formik";
import * as Yup from "yup";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useSnackbar } from "notistack";
import { differenceInCalendarDays, format, isDate } from "date-fns";
import FilterOptions from "../../../components/filter-menu/FilterOptions";
import FilterMenu from "../../../components/filter-menu";
import approvalSummaryStyles from "../approval-summary-styles";
import usePendingApprovalSummaryHook from "./usePendingApprovalSummaryHook";
import FormikControl from "../../../components/formik-conrtols/FormikControl";
import CustomButton from "../../../components/button";
import DynamicTable from "../../../components/dynamic-table";
import { emptyFilters } from "./pendingSummaryUtils";
import PaginationBlock from "../../../components/pagination-block";
import CustomModal from "../../../components/custom-modal";
import { postDelegatedToSecondary } from "../../../api-services/approval-summary";
import CustomDatePicker from "../../../components/date-range-picker";
import CustomToggleButton from "../../../components/button/CustomToggleButton";
import { ReactComponent as EmptyResultIcon } from "../../../assets/icons/no_results_icon.svg";
import { ReactComponent as ProfileIcon } from "../../../assets/icons/users.svg";
import { ReactComponent as VisibilityIcon } from "../../../assets/icons/eye_b.svg";
import { ReactComponent as InformationIcon } from "../../../assets/icons/info_9024.svg";
import { isEqual } from "lodash";

const useStyles = makeStyles({
  approverText: {
    font: "normal 13px/20px Poppins-Regular",
    color: "#0A2756",
  },
  approverTextResults: {
    font: "normal 14px/19px NotoSerif-Regular",
    color: "#0A2756",
    paddingLeft: "17px",
  },
  astrik: {
    color: "#EF5B2B",
    margin: "0 2px",
  },
  linkText: { textDecoration: "underline", color: "#0080FF" },
});

function PendingSummaryPanel() {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [isAPILoading, setIsAPILoading] = useState(false);
  const formRef = useRef<FormikProps<FormikValues>>(null);
  const formatDate = (dateValue: any) =>
    dateValue && isDate(new Date(dateValue))
      ? format(new Date(dateValue), "dd MMM yyyy")
      : "";
  const [delegatedApprover, setDelegatedApprover] = useState<{
    selected_Approver: string;
    add_reason: string;
  }>();


  const styleClasses = approvalSummaryStyles();
  const {
    open,
    subApproverList,
    filters,
    pendingSummaryData,
    tableColumnsForPending,
    isLoadingPending,
    tableParametersPending,
    setTableParametersPending,
    setFilters,
    requestIdFilter,
    requestorNameFilter,
    myLastActionFilter,
    requestTypeFilter,
    setCloseFilter,
    modalHeaderHandler,
    closeFilter,
    datepickerProps,
    setDatepickerProps,
    setDateRangeValue,
    setDateRangeFilter,
    menuData,
    setMenuData,
    modalData,
    modalOpen,
    handleModalOpen,
    handleModalClose,
    clearValueHandler,
    collapsibleElementHandler,
    modalActionHandler,
    modalContentHandler,
  } = usePendingApprovalSummaryHook();




  const openLinkedDelegation = (id: any) => {
    if (modalData.data.request_purpose === 'PAR') {
      // console.log("123")
      if (modalData.data.request_purpose === "Connect to / manage a server or computer") {
        history.push(`/myApprovalTasks/pendingApproval/${id}`);
      } else {
        history.push(`/myApprovalTasks/pendingApprovalSar/${id}`);
      }
    }
  };
  const emptyRequestHandler = () => {
    if (
      !isLoadingPending.tableLoading &&
      pendingSummaryData?.results.length === 0
    ) {
      return (
        <Grid item xs={12} data-testid="empty-delegation-screen">
          <div className={styleClasses.emptyResultContainer}>
            <EmptyResultIcon />
            <span>
              {isEqual(filters, emptyFilters)
                ? "There are no requests created by you."
                : "There are no requests matching to the selected filters."}
            </span>
          </div>
        </Grid>
      );
    }
    return null;
  };

  return (
    <>
      <Grid item xs={12} className={styleClasses.filtersContainer}>
        <div className={styleClasses.filterPanel}>
          <div className={styleClasses.filterIcon}>
            <FilterListIcon />
          </div>
          <div className={styleClasses.filterMenu}>
            <FilterMenu
              filterLabel="REQ ID"
              handleFilterClose={() => console.log("handleClose")}
              badgeCount={filters.requestId?.length}
            >
              <FilterOptions
                searchPlaceholder="Search"
                allowSearch
                optionsList={requestIdFilter}
                selectedOptions={filters.requestId}
                handleOptionSelection={(e, selection) => {
                  setFilters((prevFilters) => {
                    let newRequestIdArray = [...prevFilters.requestId];
                    if (!selection) {
                      newRequestIdArray = newRequestIdArray.filter(
                        (option) => option !== e.target.value
                      );
                    } else newRequestIdArray.push(e.target.value);
                    return {
                      ...prevFilters,
                      requestId: [...newRequestIdArray],
                      page: 1,
                    };
                  });
                }}
                isFilterDataLoading={isLoadingPending.filters.requestId}
                allowAll
                selectAllHandler={(isSelected: boolean) => {
                  // console.log(isSelected);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    requestId: isSelected
                      ? requestIdFilter.map(
                        (obj: { id: string; value: string }) => obj.id
                      )
                      : [],
                  }));
                }}
              />
            </FilterMenu>
          </div>
          <div className={styleClasses.filterMenu}>
            <FilterMenu
              filterLabel="REQUESTOR NAME"
              handleFilterClose={() => console.log("handleClose")}
              badgeCount={filters.requestorName?.length}
            >
              <FilterOptions
                searchPlaceholder="Search"
                allowSearch
                optionsList={requestorNameFilter}
                selectedOptions={filters.requestorName}
                handleOptionSelection={(e, selection) => {
                  setFilters((prevFilters) => {
                    let newRequestorNameArray = [...prevFilters.requestorName];
                    console.log(selection, "name");
                    if (!selection) {
                      newRequestorNameArray = newRequestorNameArray.filter(
                        (option) => option !== e.target.value
                      );
                    } else newRequestorNameArray.push(e.target.value);
                    return {
                      ...prevFilters,
                      requestorName: [...newRequestorNameArray],
                      page: 1,
                    };
                  });
                }}
                isFilterDataLoading={isLoadingPending.filters.requestorName}
                allowAll
                selectAllHandler={(isSelected: boolean) => {
                  // console.log(isSelected);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    requestorName: isSelected
                      ? requestorNameFilter.map(
                        (obj: { id: string; value: string }) => obj.id
                      )
                      : [],
                  }));
                }}
              />
            </FilterMenu>
          </div>
          <div className={styleClasses.filterMenu}>
            <FilterMenu
              filterLabel="REQUEST TYPE"
              handleFilterClose={() => console.log("handleClose")}
              badgeCount={filters.requestType?.length}
            >
              <FilterOptions
                searchPlaceholder="Search"
                allowSearch
                optionsList={requestTypeFilter}
                selectedOptions={filters.requestType}
                handleOptionSelection={(e, selection) => {
                  setFilters((prevFilters) => {
                    let newRequestorNameArray = [...prevFilters.requestType];

                    if (!selection) {
                      newRequestorNameArray = newRequestorNameArray.filter(
                        (option) => option !== e.target.value
                      );
                    } else newRequestorNameArray.push(e.target.value);
                    return {
                      ...prevFilters,
                      requestType: [...newRequestorNameArray],
                      page: 1,
                    };
                  });
                }}
                isFilterDataLoading={isLoadingPending.filters.requestType}
                allowAll
                selectAllHandler={(isSelected: boolean) => {
                  // console.log(isSelected);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    requestType: isSelected
                      ? requestTypeFilter.map(
                        (obj: { id: string; value: string }) => obj.id
                      )
                      : [],
                  }));
                }}
              />
            </FilterMenu>
          </div>
          <div className={styleClasses.filterMenu}>
            <FilterMenu
              filterLabel="MY LAST ACTION "
              handleFilterClose={() => console.log("handleClose")}
              badgeCount={filters.myLastAction?.length}
            >
              <FilterOptions
                searchPlaceholder="Search"
                allowSearch
                optionsList={myLastActionFilter}
                selectedOptions={filters.myLastAction}
                handleOptionSelection={(e, selection) => {
                  setFilters((prevFilters: any) => {
                    let newRequestTypeArray = [...prevFilters.myLastAction];
                    console.log(newRequestTypeArray);
                    if (!selection) {
                      newRequestTypeArray = newRequestTypeArray.filter(
                        (option) => option !== e.target.value
                      );
                    } else newRequestTypeArray.push(e.target.value);
                    return {
                      ...prevFilters,
                      myLastAction: [...newRequestTypeArray],
                      page: 1,
                    };
                  });
                }}
                isFilterDataLoading={isLoadingPending.filters.myLastAction}
                allowAll
                selectAllHandler={(isSelected: boolean) => {

                  setFilters((prevFilters: any) => ({
                    ...prevFilters,
                    myLastAction: isSelected
                      ? myLastActionFilter.map(
                        (obj: { id: string; value: string }) => obj.value
                      )
                      : [],
                  }));
                }}
              />
            </FilterMenu>
          </div>
          <div className={styleClasses.filterMenu}>
            <FilterMenu
              filterLabel="Requested On"
              handleFilterClose={() => {
                if (closeFilter) {
                  setCloseFilter(false);
                }
              }}

              badgeCount={filters.requestDate.endDate && filters.requestDate.startDate
                ? ' '
                : 0}
              closeFilterExternally={closeFilter}
            >
              <div className={styleClasses.dateRangeToggleContainer}>
                <CustomToggleButton
                  variant="contained"
                  isSelected={!!datepickerProps.isSingleDate}
                  onClick={() => {
                    if (!datepickerProps.isSingleDate) {
                      setDatepickerProps({
                        isSingleDate: true,
                        dateRange: {
                          startDate: null,
                          endDate: null,
                        },
                      });
                    }
                  }}
                >
                  Single Date
                </CustomToggleButton>
                <CustomToggleButton
                  variant="contained"
                  isSelected={!datepickerProps.isSingleDate}
                  onClick={() => {
                    if (datepickerProps.isSingleDate) {
                      setDatepickerProps({
                        isSingleDate: false,
                        dateRange: {
                          startDate: null,
                          endDate: null,
                        },
                      });
                    }
                  }}
                >
                  Date Range
                </CustomToggleButton>
              </div>
              <div className={styleClasses.dateRangeContainer}>
                <CustomDatePicker
                  isRangePicker={!datepickerProps.isSingleDate}
                  setDateRangeValue={setDateRangeValue}
                  dateRange={datepickerProps.dateRange}
                  disableFuture
                />
              </div>
              <div className={styleClasses.dateRangeApplyCta}>
                <CustomButton
                  variant="contained"
                  actionType="secondary"
                  disabled={!datepickerProps.dateRange.endDate}
                  onClick={() => setDateRangeFilter()}
                  isCompact
                  disableMargin
                >
                  Apply
                </CustomButton>
              </div>
            </FilterMenu>
          </div>
          <div className={styleClasses.filterMenu}>
            <FilterMenu
              filterLabel="Assigned On"
              handleFilterClose={() => {
                if (closeFilter) {
                  setCloseFilter(false);
                }
                console.log("handleClose");
              }}
              closeFilterExternally={closeFilter}
            >
              <div className={styleClasses.dateRangeToggleContainer}>
                <CustomToggleButton
                  variant="contained"
                  isSelected={!!datepickerProps.isSingleDate}
                  onClick={() => {
                    if (!datepickerProps.isSingleDate) {
                      setDatepickerProps({
                        isSingleDate: true,
                        dateRange: {
                          startDate: null,
                          endDate: null,
                        },
                      });
                    }
                  }}
                >
                  Single Date
                </CustomToggleButton>
                <CustomToggleButton
                  variant="contained"
                  isSelected={!datepickerProps.isSingleDate}
                  onClick={() => {
                    if (datepickerProps.isSingleDate) {
                      setDatepickerProps({
                        isSingleDate: false,
                        dateRange: {
                          startDate: null,
                          endDate: null,
                        },
                      });
                    }
                  }}
                >
                  Date Range
                </CustomToggleButton>
              </div>
              <div className={styleClasses.dateRangeContainer}>
                <CustomDatePicker
                  isRangePicker={!datepickerProps.isSingleDate}
                  setDateRangeValue={setDateRangeValue}
                  dateRange={datepickerProps.dateRange}
                  disableFuture
                />
              </div>
              <div className={styleClasses.dateRangeApplyCta}>
                <CustomButton
                  variant="contained"
                  actionType="secondary"
                  disabled={!datepickerProps.dateRange.endDate}
                  onClick={() => setDateRangeFilter()}
                  isCompact
                  disableMargin
                >
                  Apply
                </CustomButton>
              </div>
            </FilterMenu>
          </div>
          <CustomButton
            variant="contained"
            actionType="tertiary"
            onClick={() => {
              clearValueHandler();
            }}
            disableRipple
            disableMargin
            disablePadding
          >
            CLEAR
          </CustomButton>
        </div>
      </Grid>
      <Grid item xs={12}>
        <DynamicTable
          rows={pendingSummaryData.results}
          isLoading={isLoadingPending.tableLoading}
          columns={tableColumnsForPending}
          collapsible={open}
          rowId={menuData.row.rowId}
          pageNumber={tableParametersPending.page}
          pageSize={pendingSummaryData.pageSize}
        >
          {/* {collapsibleElementHandler()} */}
        </DynamicTable>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: "21px" }}>
        <PaginationBlock
          totalRecords={pendingSummaryData?.results.length || 0}
          currentRecordsCount={pendingSummaryData?.results?.length}
          activePage={tableParametersPending.page}
          handlePageChange={(pageNumber) => {
            setTableParametersPending((prevParameters: any) => ({
              ...prevParameters,
              page: pageNumber,
            }));
          }}
          isLoading={isLoadingPending.tableLoading}
          pageSize={pendingSummaryData.pageSize}
        />
      </Grid>
      {emptyRequestHandler()}
      {menuData.anchorEl && menuData.row && (
        <Menu
          id="menu-popover"
          open={!!menuData.anchorEl}
          anchorEl={menuData.anchorEl}
          onClose={() =>
            setMenuData({
              anchorEl: null,
              row: {
                rowId: "",
                stageId: "",
                requestType: "",
                linkedDelegationId: null,
                delegateToSecondary: null,
              },
              completeRow: null
            })
          }
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          classes={{ root: styleClasses.moreActionMenu }}
          disableAutoFocusItem
        >

          {
            menuData.completeRow.linked_delegation !== null && (
              <MenuItem
                onClick={() =>
                  openLinkedDelegation(menuData.completeRow.linked_delegation)
                }
                className={styleClasses.moreActionMenuItem}
              >
                <VisibilityIcon />
                <span className={styleClasses.menuLabel}>
                  View Delegation
                </span>
              </MenuItem>
            )
          }
          {menuData.completeRow.delegate_to_secondary.possible_delegates.length === 0 &&
            (
              <MenuItem
                onClick={() => handleModalOpen("info")}
                className={styleClasses.moreActionMenuItem}
              >
                <InformationIcon />
                <span className={styleClasses.menuLabel}>Delegation Info</span>
              </MenuItem>
            )
          }
          {
            menuData.completeRow.delegate_to_secondary.possible_delegates.length > 0 && (
              <MenuItem
                onClick={() => handleModalOpen("change")}
                className={styleClasses.moreActionMenuItem}
              >
                <ProfileIcon />
                <span className={styleClasses.menuLabel}>
                  Change Approver
                </span>
              </MenuItem>
            )
          }

          {/* 
          {menuData.row.delegateToSecondary ? (
            menuData?.row?.linkedDelegationId ? (
              <>
                <MenuItem
                  onClick={() =>
                    openLinkedDelegation(menuData.row.linkedDelegationId)
                  }
                  className={styleClasses.moreActionMenuItem}
                >
                  <VisibilityIcon />
                  <span className={styleClasses.menuLabel}>
                    View Delegation
                  </span>
                </MenuItem>

                <MenuItem
                  onClick={() => handleModalOpen("change")}
                  className={styleClasses.moreActionMenuItem}
                >
                  <ProfileIcon />
                  <span className={styleClasses.menuLabel}>
                    Change Approver
                  </span>
                </MenuItem>
              </>
            ) : (
              <MenuItem
                onClick={() => handleModalOpen("change")}
                className={styleClasses.moreActionMenuItem}
              >
                <ProfileIcon />
                <span className={styleClasses.menuLabel}>Change Approver</span>
              </MenuItem>
            )
          ) : menuData?.row?.linkedDelegationId ? (
            <>
              <MenuItem
                onClick={() =>
                  openLinkedDelegation(menuData.row.linkedDelegationId)
                }
                className={styleClasses.moreActionMenuItem}
              >
                <VisibilityIcon />
                <span className={styleClasses.menuLabel}>View Delegation</span>
              </MenuItem>
              <MenuItem
                onClick={() => handleModalOpen("info")}
                className={styleClasses.moreActionMenuItem}
              >
                <InformationIcon />
                <span className={styleClasses.menuLabel}>Delegation Info</span>
              </MenuItem>
            </>
          ) : (
            <MenuItem
              onClick={() => handleModalOpen("info")}
              className={styleClasses.moreActionMenuItem}
            >
              <InformationIcon />
              <span className={styleClasses.menuLabel}>Delegation Info</span>
            </MenuItem>
          )} */}
        </Menu>
      )}
      {modalOpen && (
        <CustomModal
          modalHeader={modalHeaderHandler(modalData.type)}
          open={modalOpen}
          onClose={handleModalClose}
          modalAction={modalActionHandler(modalData.type)}
          modalContent={modalContentHandler(modalData.type)}
        />
      )}
    </>
  );
}
export default PendingSummaryPanel;
