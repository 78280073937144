import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import { union } from "lodash";
import { useDropzone } from "react-dropzone";
import { ReactComponent as PdfIcon } from "../../assets/icons/Pdf.svg";
import { ReactComponent as ImageIcon } from "../../assets/icons/noun-picture-1218877.svg";
import { ReactComponent as WordIcon } from "../../assets/icons/Word.svg";
import { ReactComponent as ExcelIcon } from "../../assets/icons/excel.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/x.svg";
import { ReactComponent as UnknownIcon } from "../../assets/icons/unknown_file_icon.svg";
import useStyles from "./useStyles";
import { envConstants } from "../../config/env-constants";
import { removeUploadDelegation, removeUploadDelegationImplementation } from "../../api-services/delegation-module";
import GetAppIcon from "@mui/icons-material/GetApp";
import { useSnackbar } from 'notistack';

interface ICustomFileUpload {
  fileAction?: React.ReactNode;
  acceptFileType?: any;
  disabled?: boolean;
  requestId: string;
  getFiles: any;
  step_id?: any;
  file_section?: any;
  step_one_id?: any;
  role_id?: any;
}

const iconPicker = (fileType: any) => {
  switch (fileType.toLowerCase()) {
    case "word":
    case "docx":
    case "txt":
      return <WordIcon />;
    case "xlsx":
      return <ExcelIcon />;
    case "pdf":
      return <PdfIcon />;
    case "png":
    case "jpg":
    case "img":
    case "jpeg":
      return <ImageIcon />;
    default:
      return <UnknownIcon />;
  }
};

function FileUploader({
  fileAction,
  acceptFileType,
  disabled,
  requestId,
  getFiles,
  step_id,
  file_section,
  step_one_id,
  role_id,
}: ICustomFileUpload) {
  // console.log('getFiles81232323', getFiles);
  const classes = useStyles();
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [removeFileFlag, setRemoveFileFlag] = useState(false);
  const [progress, setProgress] = useState({ started: false, pc: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [fileData, setFileData] = useState<any[]>([]);
  const authToken = JSON.parse(
    localStorage.getItem("okta-token-storage") || ""
  );

  const onDrop = useCallback((acceptedFilesArray) => {
    acceptedFilesArray.forEach((file: any) => {
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        console.log(file);
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const extensionHnadler = (fileName: string) => {
    return fileName.split(".").pop();
  };

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
  } = useDropzone({
    onDrop,
    maxFiles: 4,
    accept: acceptFileType,
    disabled: disabled,
  });

  // start of route check for implementation
  const routeCheckForPendingImplementation = () => {
    return location.pathname.slice(1, 44).toLocaleLowerCase() === 'myimplementationtasks/pendingimplementation'
  }
  const routeCheckForAdminSummary = () => {
    return location.pathname.slice(1, 33).toLocaleLowerCase() === 'myadministrativetasks/delegation'
  }

  const routeCheckForApprovalSummary = () => {
    return location.pathname.slice(1, 32).toLocaleLowerCase() === 'myapprovaltasks/approvalsummary'
  }
  // end of route check for implementation

  // @ts-ignore

  // start of file download

  const downloadHandler = (fileUrl: any, fileName: string) => {
    var a = document.createElement("a");
    a.href = fileUrl.file;
    a.setAttribute("download", fileName);
    a.click();
  };

  // end of file download
  // start of file deletion
  const removeFileHandler = (Id: any, url: any, fileName: any) => {
    if (url !== undefined) {
      if (routeCheckForPendingImplementation()) {
        const tempUrl = url.replaceAll('%20', ' ');
        const postBody = {
          file: tempUrl.replace(
            "https://uks00sta1gpapdev01.blob.core.windows.net/devcontainer01/gpag_dev_asset/",
            ""
          ),
          filename: fileName,
          step_id: step_id,
          file_section: file_section,
        };

        removeUploadDelegationImplementation(postBody)
          .then((response) => {
            setSelectedFiles((prevSate) => [
              ...prevSate.filter((value) => {
                return value.id !== Id || value.filename !== fileName;
              }),
            ]);
            enqueueSnackbar('File removed successfully', { variant: 'success' });
            sessionStorage.setItem(file_section, JSON.stringify(selectedFiles.length));
          })
          .catch((err) => err);
        setRemoveFileFlag(true);
      } else {
        const tempUrl = url.replaceAll('%20', ' ');
        const postBody = {
          id: Id,
          file: tempUrl.replace(
            "https://uks00sta1gpapdev01.blob.core.windows.net/devcontainer01/gpag_dev_asset/",
            ""
          ),
        };

        removeUploadDelegation(postBody)
          .then((response) => {
            setSelectedFiles((prevSate) => [
              ...prevSate.filter((value) => {
                return value.id !== Id;
              }),
            ]);
            enqueueSnackbar('File removed successfully', { variant: 'success' });
          })
          .catch((err) => err);
        setRemoveFileFlag(true);
      }


    }
  };

  // End of file deletion
  const fileUploadHandler = () => {
    if (!acceptedFiles[0]) {
      // enqueueSnackbar('No file selected', { variant: 'success' });
      return;
    }
    if (routeCheckForPendingImplementation()) {
      for (let i = 0; i < acceptedFiles.length; i++) {
        const formData = new FormData();
        formData.append("file", acceptedFiles[i]);
        formData.append("file_section", file_section);
        formData.append("request_id", requestId);
        formData.append("step_one_id", step_one_id);
        formData.append("step_id", step_id);
        formData.append("role_id", role_id);
        setProgress((prevState) => {
          return { ...prevState, started: true };
        });
        setIsLoading(true);
        axios
          .post(`${envConstants.baseUrl}/api/del_on_premise_implementer_file_upload/`, formData, {
            headers: {
              "Content-Type": "application/json",
              Authorization: authToken.idToken.idToken,
            },
          })
          .then((response) => {
            if (response && response.data.message == "Invalid file format") {
              enqueueSnackbar('Invalid file format uploaded', { variant: 'warning' });
            } else {
              setSelectedFiles((prevState: any) => [...prevState, response.data.data])
              enqueueSnackbar('File uploaded successfully', { variant: 'success' });
              setIsLoading(false);
              sessionStorage.setItem(file_section, JSON.stringify(selectedFiles.length));
            }
          })
          .catch((error) => {
            enqueueSnackbar("Something went wrong, please try again", {
              variant: "error",
            });
          });
      }
    } else {
      for (let i = 0; i < acceptedFiles.length; i++) {
        const formData = new FormData();
        formData.append("file", acceptedFiles[i]);
        formData.append("file_section", "attachment");
        formData.append("request_id", requestId);
        setProgress((prevState) => {
          return { ...prevState, started: true };
        });
        setIsLoading(true);
        axios
          .post(`${envConstants.baseUrl}/api/upload/`, formData, {
            headers: {
              "Content-Type": "application/json",
              Authorization: authToken.idToken.idToken,
            },
          })
          .then((response) => {
            if (response && response.data.message == "Invalid file format") {
              enqueueSnackbar('Invalid file format uploaded', { variant: 'warning' });
            } else {
              setSelectedFiles((prevState: any) => [...prevState, response.data.data]

              )
              enqueueSnackbar('File uploaded successfully', { variant: 'success' });
              setIsLoading(false);
            }
          })
          .catch((error) => {
            enqueueSnackbar("Something went wrong, please try again", {
              variant: "error",
            });
          });
      }
    }
  };

  useEffect(() => {
    fileUploadHandler();
  }, [acceptedFiles]);

  useEffect(() => {

    if (routeCheckForPendingImplementation() || routeCheckForAdminSummary() || routeCheckForApprovalSummary()) {
      // console.log('checki1', routeCheckForPendingImplementation())
      setSelectedFiles([
        ...union(
          selectedFiles,
          acceptedFiles,
          getFiles.length > 0 &&
          getFiles.map((obj: any) => {
            return {
              extension: obj.extension,
              file: obj.file,
              file_section: obj.file_section,
              // file_type: obj.file_type,
              filename: obj.filename,
            };
          })
        ),
      ]);
      sessionStorage.setItem(file_section, JSON.stringify(selectedFiles.length));
    }
  }, [getFiles]);



  useEffect(() => {
    if (!routeCheckForPendingImplementation()) {
      // console.log('checki2', !routeCheckForPendingImplementation())
      setSelectedFiles([
        ...union(
          selectedFiles,
          acceptedFiles,
          getFiles.length > 0 &&
          getFiles.map((obj: any) => {
            return {
              extension: obj.extension,
              file: obj.file,
              file_section: obj.file_section,
              // file_type: obj.file_type,
              filename: obj.filename,
              id: obj.id,
            };
          })
        ),
      ]);
    }
  }, []);




  return (
    <Grid container>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {fileAction}
      </div>
      {selectedFiles.length > 0 &&
        selectedFiles.map((fileObj: any) => (
          <>
            <Grid item xs={3} md={3} sm={3} className={classes.container}>
              <div className={classes.topDivfileContainer}>
                <>
                  <div className={classes.divFileIcon}>
                    {iconPicker(extensionHnadler(fileObj.extension))}
                  </div>
                  <div className={classes.divFileName}>
                    {fileObj.name || fileObj.filename}
                  </div>
                  {!disabled &&
                    <CloseIcon
                      className={classes.closeIconStyle}
                      onClick={() => removeFileHandler(fileObj.id, fileObj.file ? fileObj.file : fileObj.url, fileObj.file ? fileObj.filename : fileObj.filename)}
                    />
                  }
                  <GetAppIcon
                    style={{ color: "#00B5B1", cursor: "pointer", fontSize: '15px', marginLeft: '8px' }}
                    onClick={() => downloadHandler(fileObj, "fileName")}
                  />
                </>
              </div>
            </Grid>
          </>
        ))}
    </Grid>
  );
}

export default FileUploader;
