import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  appBarHeader: {
    background: "#0A2756 0% 0% no-repeat padding-box !important",
    boxShadow: "none !important",
  },
  indicator: {
    bgcolor: "orange",
    height: "2px",
  },
  tab: {
    "& a": {
      color: "#fff",
      textTransform: "capitalize",
      textDecoration: "none",
      padding: "12px 1.5px",
      minWidth: "auto",
      margin: "0px 12px",
      fontSize: "16px",
      fontFamily: "Poppins-Medium",
      "&:active, &:hover, &:focus": {
        color: "#00FFB4",
        textDecoration: "none",
        fontFamily: "Poppins-Medium !important",
        // padding: '12px 0',
      },
      "&:first-child": {
        marginLeft: "0",
        paddingLeft: "0",
        paddingRight: "3px",
      },
      "& > span:after": {
        width: "100% !important",
        left: "0 !important",
      },
    },
  },
  inputContainer: {
    // margin: '8px auto',
  },
  notchedOutline: {
    "&.css-bll5gk-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall":
      {
        border: "2px solid #00B5B1 !important",
        borderRadius: "0px 25px 25px 0px",
        maxHeight: "1.9em",
      },
  },
  input: {
    font: "normal normal normal 12px/17px NotoSerif-Regular ",
    maxHeight: "1em !important",
    color: "#0A2756",
    "&:active, &:focus": {
      borderColor: "#0080FF",
    },
  },
  focused: {
    font: "14px NotoSerif-Regular",
    border: "#0080FF",
  },
  label: {
    width: "100%",
    color: "#A7A7A7",
    font: "normal normal normal 12px/17px NotoSerif-Regular ",
    // transform: 'translate(12px, 8px) scale(1) ',
    "&::last-letter": {
      color: "red",
    },
  },
  shrink: {
    transform: "translate(12px, -8px) scale(0.9)",
    color: "#0080FF ",
  },
  error: {
    border: "#EF5B2B !important",
    letterSpacing: "0px",
    color: "#EF5B2B !importnant",
  },
  helperText: {
    font: "normal normal normal 10px/20px NotoSerif-Regular",
  },
  helperTextError: {
    color: "#EF5B2B !important",
  },
  disable: {
    color: "blue",
  },
  asterisk: {
    color: "#EF5B2B",
  },
  stickySearch: {
    margin: "0 !important",
    // width: 'auto',
    font: "normal normal normal 12px/18px Poppins-Regular !important",
    letterSpacing: 0,
    borderRadius: "0px 25px 25px 0px",
    "&:active, &:focus": {
      borderColor: "#0080FF",
    },
    color: "#A7A7A7",
    "& > input": {
      padding: "8px 0px 8px 16px",
      height: "0.9em",
      border: "2px solid #00B5B1 !important",
    },
  },
  button: {
    borderRadius: "25px 0px 0px 25px !important",
    boxShadow: "none !important",
    backgroundColor: "#00B5B1 !important",
    border: "1px solid #00B5B1 !important",
    color: "#fff !important",
    font: "14px Poppins-Medium !important",
    padding: "4px 16px",
  },
  activeButton: {
    backgroundColor: "#00B5B1 !important",
    color: "#fff !important",
  },
  notificationIconButton: {
    padding: "8px 16px 8px 12px !important",
  },
  notificationicon: {
    "& span": {
      minWidth: "0px!important",
      height: "12px !important",
      top: "4px !important",
      right: "4px !important",
      bottom: "4px !important",
      background: "red !important",
      fontSize: "0.5rem!important",
    },
  },
  inputsearch: {
    borderBottom: "1px solid #fff",
    color: "#a7a7a7 !important",
    cursor: "pointer",
    font: "normal normal normal 14px/19px NotoSerif-Regular !important",
    width: "0%",
    display: "block",
    float: "right",
    transition: "width .5s",
  },
  inputsearchActive: {
    width: "100%",
  },
  search: {
    position: "absolute",
    right: "0",
    cursor: "pointer",
  },
  headermain: {
    padding: "20px 20px 0 ",
  },
  divider: {
    background: "#fff",
    opacity: "0.3",
  },
  divdivder: {
    padding: "4px 16px 8px",
  },
  pagdiv: {
    fontFamily: "Poppins-Medium",
    fontSize: "20px",
    color: "#fff",
    padding: "0",
  },
  serachdiv: {
    // display: 'flex',
    paddingTop: "8px",
    alignItems: "center",
    marginRight: "16px",
    position: "relative",
    "&:hover > div": {
      borderBottom: "2px solid #00FFB4",
    },
    "&:hover > svg": {
      color: "#00FFB4",
    },
    display: "flex",
  },
  toplinksdiv: {
    flexGrow: 1,
    display: "flex",
  },
  link: {
    textDecoration: "none",
  },
  avatar: {
    width: "35px !important",
    height: "35px !important",
    background: "#00B5B1",
    color: "#0A2756",
    fontFamily: "Poppins-Medium",
  },
  span: {
    margin: "0 7px 0 10px",
    font: "normal normal normal 12px/18px Poppins-Regular",
    color: "#F2F2F2",
    // fontFamily: 'Poppins-Medium',
  },
  arrowkeyspan: {
    cursor: "pointer",
  },
});

export default useStyles;
