import React, { useState, useCallback, useEffect } from 'react';
import { useFormikContext } from 'formik';
import Grid from '@mui/material/Grid';
import Popover from '@mui/material/Popover';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import parseISO from 'date-fns/parseISO';
import addDays from 'date-fns/addDays';
import FormikControl from '../../../formik-conrtols/FormikControl';
import FieldLabel from '../../../field-label';
import CustomDatePicker from '../../../date-range-picker';
import useDaterangePicker from '../../../date-range-picker/useDaterangePicker';
import IconTextField from '../../../icon-text-field';
import { customRequestStyles, parStyles } from '../../useStyles';
import { format, isDate } from 'date-fns';
import { accessForRequest, contractNumber, implementationPlan, provideCustomRequest, serviceDetails, thirdPartyRequestTimings } from '../tooltip';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../appStore/store';
import HistoryTable from '../../../../modules/delegation/comments/historyTable';
import CommentSubmission from '../../../../modules/delegation/comments/commentSubmission';
import { routeCheckDelegation, routeCheckForAdminSummary, routeCheckForApprovalSummary, routeCheckForImplementationSummary, routeCheckForPendingApproval } from './routingConditional';

const thirdPartyRequest = `Please provide the reference number for your Request For
                     Service with the 3rd Party and all the Request For Service details.

                     We need to understand:
                     - the details of the work requested by GroupM from the 3rd Party
                     - how this is planned to be implemented, in detail
                     - What servers, databases or applications you will be working on
                     - what access will be required
                     The greater detail you can provide the better able we will be to quickly assess and respond on your request.`;

function CustomRequest({ isDisabled }: { isDisabled: boolean }) {
  const classes = customRequestStyles();
  const formikCtx: any = useFormikContext();
  const [anchorEl, setAnchorEl] = useState<{
    anchorElStartDate: HTMLElement | null,
    anchorElEndDate: HTMLElement | null,
  }>({
    anchorElStartDate: null,
    anchorElEndDate: null,
  });


  const formatDateInside = (dateValue: any) => (dateValue && isDate(new Date(dateValue)) ? format(new Date(dateValue), 'MMM dd, yyyy') : '');
  const { fieldHistoryDelegationCommentsData } = useSelector((state: RootState) => state);
  const { submitDelegationCommentData } = useSelector((state: RootState) => state);
  const styleClasses = parStyles();
  const remediated_by = formikCtx.values.remediation_by
  const remediationCount = formikCtx.values.remediation_count;

  const checkForEditReviewFlow = () => {
    return routeCheckDelegation() || routeCheckForAdminSummary() || routeCheckForImplementationSummary() || routeCheckForApprovalSummary()
  }
  const handleClick = (event: React.MouseEvent<HTMLElement>, anchorElField: string) => {
    if (anchorElField === 'startdate') {
      setAnchorEl((prevState) => (
        { ...prevState, anchorElStartDate: event.currentTarget }
      ));
    } else if (anchorElField === 'enddate') {
      setAnchorEl((prevState) => (
        { ...prevState, anchorElEndDate: event.currentTarget }
      ));
    }
  };
  const handleClose = () => {
    setAnchorEl({
      anchorElStartDate: null,
      anchorElEndDate: null,
    });
  };
  const {
    datepickerProps,
    setDateRangeValue,
  } = useDaterangePicker({ isSingleDate: true });

  const getDisabledEndDate = useCallback(
    (date) => !!isAfter(parseISO(formikCtx.values.del_start_date), date),
    [formikCtx.values.del_start_date],
  );

  const handleDateChange = (e: any, dateField: string) => {
    if (dateField === 'startdate') {
      formikCtx.setFieldValue('del_start_date', format(e, 'yyyy-MM-dd'));
      formikCtx.setFieldValue('del_end_date', format(addDays(e, 1), 'yyyy-MM-dd'));
      setDateRangeValue(e);
    } else if (dateField === 'enddate') {
      formikCtx.setFieldValue('del_end_date', format(e, 'yyyy-MM-dd'));
      setDateRangeValue(e);
    }
  };

  return (
    <Grid container style={{ paddingRight: '8px' }}>
      <Grid item xs={5}>
        <div>
          <FieldLabel Title="Provide custom request" tooltipMsg={provideCustomRequest} />
        </div>
        <div style={{ color: '#A7A7A7', fontFamily: 'NotoSerif-Regular', fontSize: '10px', marginTop: '-5px', paddingBottom: '10px' }}>
          {/* @ts-ignore */}
          {(fieldHistoryDelegationCommentsData[8].Provide_custom_request?.length > 0) && `Updated on ${formatDateInside(fieldHistoryDelegationCommentsData[8].Provide_custom_request[fieldHistoryDelegationCommentsData[8].Provide_custom_request?.length - 1].last_updated)}`}
        </div>
      </Grid>
      <Grid item xs={5}>
      </Grid>
      <Grid item xs={2}>
        {(checkForEditReviewFlow() && remediationCount > 0) ? (
          <div className={styleClasses.mainSectionDiv}>
            <HistoryTable
              parId={formikCtx.values.request_id}
              section="Provide_custom_request"
              subSection=''
              data={fieldHistoryDelegationCommentsData.length > 0 ? fieldHistoryDelegationCommentsData[8].Provide_custom_request : null}
            />

            <CommentSubmission
              sectionName='Provide_custom_request'
              subSection=''
              updateType='Select update'
              remediation_by={remediated_by}
              data={submitDelegationCommentData.length > 0 ? submitDelegationCommentData[8].Provide_custom_request : null}
            />
          </div>
        ) : routeCheckForPendingApproval() && (
          <div className={styleClasses.mainSectionDiv}>
            <HistoryTable
              parId={formikCtx.values.request_id}
              section="Provide_custom_request"
              subSection=''
              data={fieldHistoryDelegationCommentsData.length > 0 ? fieldHistoryDelegationCommentsData[8].Provide_custom_request : null}
            />

            <CommentSubmission
              sectionName='Provide_custom_request'
              subSection=''
              updateType='Select update'
              remediation_by={remediated_by}
              data={submitDelegationCommentData.length > 0 ? submitDelegationCommentData[8].Provide_custom_request : null}
            />
          </div>
        )}
      </Grid>
      <Grid item xs={12}>
        <FormikControl
          control="textfield"
          label="Custom request details (Optional)"
          name="del_custom_request"
          multiline
          minRows={5}
          disabled={isDisabled}
        />
      </Grid>
      <Grid item xs={5}>
        <div>
          <FieldLabel Title="Do you have a 3rd Party Request For Service (RFS) in place?" tooltipMsg={thirdPartyRequest} required />
        </div>
        <div style={{ color: '#A7A7A7', fontFamily: 'NotoSerif-Regular', fontSize: '10px', marginTop: '-5px', paddingBottom: '10px' }}>
          {/* @ts-ignore */}
          {(fieldHistoryDelegationCommentsData[9].Third_Party_Request_For_Service_in_place?.length > 0) && `Updated on ${formatDateInside(fieldHistoryDelegationCommentsData[9].Third_Party_Request_For_Service_in_place[fieldHistoryDelegationCommentsData[9].Third_Party_Request_For_Service_in_place?.length - 1].last_updated)}`}
        </div>
      </Grid>
      <Grid item xs={5}>
      </Grid>
      <Grid item xs={2}>
        {(checkForEditReviewFlow() && remediationCount > 0) ? (
          <div className={styleClasses.mainSectionDiv}>
            <HistoryTable
              parId={formikCtx.values.request_id}
              section="Third_Party_Request_For_Service_in_place"
              subSection=''
              data={fieldHistoryDelegationCommentsData.length > 0 ? fieldHistoryDelegationCommentsData[9].Third_Party_Request_For_Service_in_place : null}
            />

            <CommentSubmission
              sectionName='Third_Party_Request_For_Service_in_place'
              subSection=''
              updateType='Select update'
              remediation_by={remediated_by}
              data={submitDelegationCommentData.length > 0 ? submitDelegationCommentData[9].Third_Party_Request_For_Service_in_place : null}
            />
          </div>
        ) : routeCheckForPendingApproval() && (
          <div className={styleClasses.mainSectionDiv}>
            <HistoryTable
              parId={formikCtx.values.request_id}
              section="Third_Party_Request_For_Service_in_place"
              subSection=''
              data={fieldHistoryDelegationCommentsData.length > 0 ? fieldHistoryDelegationCommentsData[9].Third_Party_Request_For_Service_in_place : null}
            />

            <CommentSubmission
              sectionName='Third_Party_Request_For_Service_in_place'
              subSection=''
              updateType='Select update'
              remediation_by={remediated_by}
              data={submitDelegationCommentData.length > 0 ? submitDelegationCommentData[9].Third_Party_Request_For_Service_in_place : null}
            />
          </div>
        )}
      </Grid>
      <Grid item xs={12}>
        <FormikControl
          disabled={isDisabled}
          control="radio"
          name="del_is_thirdparty_request_service"
          radioGrouplabels={[{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]}
          isInline
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            formikCtx.setFieldValue('del_is_thirdparty_request_service', e.target.value === 'true');
          }}
        />
      </Grid>
      {formikCtx.values.del_is_thirdparty_request_service && (
        <>
          <Grid item xs={12}>
            <div className={classes.customTextDiv}>
              Please provide 3rd party Request For
              Service reference number and details of the request
            </div>
          </Grid>
          <Grid item xs={5}>
            <div>
              <FieldLabel Title="Contract number or Request For Service (RFS) reference" tooltipMsg={contractNumber} required />
            </div>
            <div style={{ color: '#A7A7A7', fontFamily: 'NotoSerif-Regular', fontSize: '10px', marginTop: '-5px', paddingBottom: '10px' }}>
              {/* @ts-ignore */}
              {(fieldHistoryDelegationCommentsData[10].Contract_number_or_Request_For_Service_reference?.length > 0) && `Updated on ${formatDateInside(fieldHistoryDelegationCommentsData[10].Contract_number_or_Request_For_Service_reference[fieldHistoryDelegationCommentsData[10].Contract_number_or_Request_For_Service_reference?.length - 1].last_updated)}`}
            </div>
          </Grid>
          <Grid item xs={5}>
          </Grid>
          <Grid item xs={2}>
            {(checkForEditReviewFlow() && remediationCount > 0) ? (
              <div className={styleClasses.mainSectionDiv}>
                <HistoryTable
                  parId={formikCtx.values.request_id}
                  section="Contract_number_or_Request_For_Service_reference"
                  subSection=''
                  data={fieldHistoryDelegationCommentsData.length > 0 ? fieldHistoryDelegationCommentsData[10].Contract_number_or_Request_For_Service_reference : null}
                />

                <CommentSubmission
                  sectionName='Contract_number_or_Request_For_Service_reference'
                  subSection=''
                  updateType='Select update'
                  remediation_by={remediated_by}
                  data={submitDelegationCommentData.length > 0 ? submitDelegationCommentData[10].Contract_number_or_Request_For_Service_reference : null}
                />
              </div>
            ) : routeCheckForPendingApproval() && (
              <div className={styleClasses.mainSectionDiv}>
                <HistoryTable
                  parId={formikCtx.values.request_id}
                  section="Contract_number_or_Request_For_Service_reference"
                  subSection=''
                  data={fieldHistoryDelegationCommentsData.length > 0 ? fieldHistoryDelegationCommentsData[10].Contract_number_or_Request_For_Service_reference : null}
                />

                <CommentSubmission
                  sectionName='Contract_number_or_Request_For_Service_reference'
                  subSection=''
                  updateType='Select update'
                  remediation_by={remediated_by}
                  data={submitDelegationCommentData.length > 0 ? submitDelegationCommentData[10].Contract_number_or_Request_For_Service_reference : null}
                />
              </div>
            )}
          </Grid>
          <Grid item xs={4}>
            <FormikControl
              disabled={isDisabled}
              control="textfield"
              label="Contract number"
              name="del_short_term_long_term_contract_number"
            />
          </Grid>
          <Grid item xs={8}>

          </Grid>
          <Grid item xs={5}>
            <div>
              <FieldLabel Title="Request For Service details" tooltipMsg={serviceDetails} required />
            </div>
            <div style={{ color: '#A7A7A7', fontFamily: 'NotoSerif-Regular', fontSize: '10px', marginTop: '-5px', paddingBottom: '10px' }}>
              {/* @ts-ignore */}
              {(fieldHistoryDelegationCommentsData[11].Request_For_Service_details?.length > 0) && `Updated on ${formatDateInside(fieldHistoryDelegationCommentsData[11].Request_For_Service_details[fieldHistoryDelegationCommentsData[11].Request_For_Service_details?.length - 1].last_updated)}`}
            </div>
          </Grid>
          <Grid item xs={5}>
          </Grid>
          <Grid item xs={2}>
            {(checkForEditReviewFlow() && remediationCount > 0) ? (
              <div className={styleClasses.mainSectionDiv}>
                <HistoryTable
                  parId={formikCtx.values.request_id}
                  section="Request_For_Service_details"
                  subSection=''
                  data={fieldHistoryDelegationCommentsData.length > 0 ? fieldHistoryDelegationCommentsData[11].Request_For_Service_details : null}
                />
                <CommentSubmission
                  sectionName='Request_For_Service_details'
                  subSection=''
                  updateType='Select update'
                  remediation_by={remediated_by}
                  data={submitDelegationCommentData.length > 0 ? submitDelegationCommentData[11].Request_For_Service_details : null}
                />
              </div>
            ) : routeCheckForPendingApproval() && (
              <div className={styleClasses.mainSectionDiv}>
                <HistoryTable
                  parId={formikCtx.values.request_id}
                  section="Request_For_Service_details"
                  subSection=''
                  data={fieldHistoryDelegationCommentsData.length > 0 ? fieldHistoryDelegationCommentsData[11].Request_For_Service_details : null}
                />

                <CommentSubmission
                  sectionName='Request_For_Service_details'
                  subSection=''
                  updateType='Select update'
                  remediation_by={remediated_by}
                  data={submitDelegationCommentData.length > 0 ? submitDelegationCommentData[11].Request_For_Service_details : null}
                />
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <FormikControl
              disabled={isDisabled}
              control="textfield"
              label="Service details"
              name="del_short_term_long_term_contract_details"
              multiline
              minRows={5}
            />
          </Grid>
          <Grid item xs={5}>
            <div>
              <FieldLabel Title="Implementation plan" tooltipMsg={implementationPlan} required />
            </div>
            <div style={{ color: '#A7A7A7', fontFamily: 'NotoSerif-Regular', fontSize: '10px', marginTop: '-5px', paddingBottom: '10px' }}>
              {/* @ts-ignore */}
              {(fieldHistoryDelegationCommentsData[12].Implementation_plan?.length > 0) && `Updated on ${formatDateInside(fieldHistoryDelegationCommentsData[12].Implementation_plan[fieldHistoryDelegationCommentsData[12].Implementation_plan?.length - 1].last_updated)}`}
            </div>
          </Grid>
          <Grid item xs={5}>
          </Grid>
          <Grid item xs={2}>
            {(checkForEditReviewFlow() && remediationCount > 0) ? (
              <div className={styleClasses.mainSectionDiv}>
                <HistoryTable
                  parId={formikCtx.values.request_id}
                  section="Implementation_plan"
                  subSection=''
                  data={fieldHistoryDelegationCommentsData.length > 0 ? fieldHistoryDelegationCommentsData[12].Implementation_plan : null}
                />

                <CommentSubmission
                  sectionName='Implementation_plan'
                  subSection=''
                  updateType='Select update'
                  remediation_by={remediated_by}
                  data={submitDelegationCommentData.length > 0 ? submitDelegationCommentData[12].Implementation_plan : null}
                />
              </div>
            ) : routeCheckForPendingApproval() && (
              <div className={styleClasses.mainSectionDiv}>
                <HistoryTable
                  parId={formikCtx.values.request_id}
                  section="Implementation_plan"
                  subSection=''
                  data={fieldHistoryDelegationCommentsData.length > 0 ? fieldHistoryDelegationCommentsData[12].Implementation_plan : null}
                />

                <CommentSubmission
                  sectionName='Implementation_plan'
                  subSection=''
                  updateType='Select update'
                  remediation_by={remediated_by}
                  data={submitDelegationCommentData.length > 0 ? submitDelegationCommentData[12].Implementation_plan : null}
                />
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <FormikControl
              disabled={isDisabled}
              control="textfield"
              label="Implementation plan"
              name="del_short_term_long_term_implementation_plan"
              multiline
              minRows={5}
            />
          </Grid>
          <Grid item xs={5}>
            <div>
              <FieldLabel Title="Access required for Request For Service" tooltipMsg={accessForRequest} required />
            </div>
            <div style={{ color: '#A7A7A7', fontFamily: 'NotoSerif-Regular', fontSize: '10px', marginTop: '-5px', paddingBottom: '10px' }}>
              {/* @ts-ignore */}
              {(fieldHistoryDelegationCommentsData[13].Access_required_for_Request_For_Service?.length > 0) && `Updated on ${formatDateInside(fieldHistoryDelegationCommentsData[13].Access_required_for_Request_For_Service[fieldHistoryDelegationCommentsData[13].Access_required_for_Request_For_Service?.length - 1].last_updated)}`}
            </div>
          </Grid>
          <Grid item xs={5}>
          </Grid>
          <Grid item xs={2}>
            {(checkForEditReviewFlow() && remediationCount > 0) ? (
              <div className={styleClasses.mainSectionDiv}>
                <HistoryTable
                  parId={formikCtx.values.request_id}
                  section="Access_required_for_Request_For_Service"
                  subSection=''
                  data={fieldHistoryDelegationCommentsData.length > 0 ? fieldHistoryDelegationCommentsData[13].Access_required_for_Request_For_Service : null}
                />

                <CommentSubmission
                  sectionName='Access_required_for_Request_For_Service'
                  subSection=''
                  updateType='Select update'
                  remediation_by={remediated_by}
                  data={submitDelegationCommentData.length > 0 ? submitDelegationCommentData[13].Access_required_for_Request_For_Service : null}
                />
              </div>
            ) : routeCheckForPendingApproval() && (
              <div className={styleClasses.mainSectionDiv}>
                <HistoryTable
                  parId={formikCtx.values.request_id}
                  section="Access_required_for_Request_For_Service"
                  subSection=''
                  data={fieldHistoryDelegationCommentsData.length > 0 ? fieldHistoryDelegationCommentsData[13].Access_required_for_Request_For_Service : null}
                />

                <CommentSubmission
                  sectionName='Access_required_for_Request_For_Service'
                  subSection=''
                  updateType='Select update'
                  remediation_by={remediated_by}
                  data={submitDelegationCommentData.length > 0 ? submitDelegationCommentData[13].Access_required_for_Request_For_Service : null}
                />
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <FormikControl
              disabled={isDisabled}
              control="textfield"
              label="Access Required For Request For Service"
              name="del_short_term_long_term_access_request"
              multiline
              minRows={5}
            />
          </Grid>
          <Grid item xs={5}>
            <div>
              <FieldLabel Title="3rd Party Request For Service timings" tooltipMsg={thirdPartyRequestTimings} required />
            </div>
            <div style={{ color: '#A7A7A7', fontFamily: 'NotoSerif-Regular', fontSize: '10px', marginTop: '-5px', paddingBottom: '10px' }}>
              {/* @ts-ignore */}
              {(fieldHistoryDelegationCommentsData[14].Third_Party_Request_For_Service_timings?.length > 0) && `Updated on ${formatDateInside(fieldHistoryDelegationCommentsData[14].Third_Party_Request_For_Service_timings[fieldHistoryDelegationCommentsData[14].Third_Party_Request_For_Service_timings?.length - 1].last_updated)}`}
            </div>
          </Grid>
          <Grid item xs={5}>
          </Grid>
          <Grid item xs={2}>
            {(checkForEditReviewFlow() && remediationCount > 0) ? (
              <div className={styleClasses.mainSectionDiv}>
                <HistoryTable
                  parId={formikCtx.values.request_id}
                  section="Third_Party_Request_For_Service_timings"
                  subSection=''
                  data={fieldHistoryDelegationCommentsData.length > 0 ? fieldHistoryDelegationCommentsData[14].Third_Party_Request_For_Service_timings : null}
                />

                <CommentSubmission
                  sectionName='Third_Party_Request_For_Service_timings'
                  subSection=''
                  updateType='Select update'
                  remediation_by={remediated_by}
                  data={submitDelegationCommentData.length > 0 ? submitDelegationCommentData[14].Third_Party_Request_For_Service_timings : null}
                />
              </div>
            ) : routeCheckForPendingApproval() && (
              <div className={styleClasses.mainSectionDiv}>
                <HistoryTable
                  parId={formikCtx.values.request_id}
                  section="Third_Party_Request_For_Service_timings"
                  subSection=''
                  data={fieldHistoryDelegationCommentsData.length > 0 ? fieldHistoryDelegationCommentsData[14].Third_Party_Request_For_Service_timings : null}
                />

                <CommentSubmission
                  sectionName='Third_Party_Request_For_Service_timings'
                  subSection=''
                  updateType='Select update'
                  remediation_by={remediated_by}
                  data={submitDelegationCommentData.length > 0 ? submitDelegationCommentData[14].Third_Party_Request_For_Service_timings : null}
                />
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <div className={classes.customRequestTopDateDiv}>
              <div className={classes.customRequestDateDivflex}>
                <span className={classes.customRequestDateSpan}>Start date</span>
                <IconTextField
                  iconEnd={
                    (
                      <CalendarMonthIcon
                        className={`${anchorEl.anchorElStartDate ? classes.calendarMonthIconStylesActive : classes.calendarMonthIconStyles}`}
                      />
                    )
                  }
                  onClick={(e: any) => {
                    if (!isDisabled) { handleClick(e, 'startdate'); }
                  }}
                  value={formikCtx.values.del_start_date}
                />

              </div>
              <div className={classes.customRequestDateDivflexEnd}>
                <span className={classes.customRequestDateSpan}>End date</span>
                <IconTextField
                  iconEnd={
                    (
                      <CalendarMonthIcon
                        className={`${anchorEl.anchorElEndDate ? classes.calendarMonthIconStylesActive : classes.calendarMonthIconStyles}`}
                      />
                    )
                  }
                  onClick={(e: any) => { if (!isDisabled) { handleClick(e, 'enddate'); } }}
                  value={formikCtx.values.del_end_date}
                />
              </div>
            </div>
          </Grid>
          <Popover
            id="endDatePopover"
            open={!!anchorEl.anchorElStartDate}
            anchorEl={anchorEl.anchorElStartDate}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <div className={classes.datePickerDiv}>
              <CustomDatePicker
                isRangePicker={!datepickerProps.isSingleDate}
                setDateRangeValue={(e) => handleDateChange(e, 'startdate')}
                dateRange={datepickerProps.dateRange}
                shouldDisableDate={(date: Date) => !!isBefore(date, addDays(new Date(), 7))}
              />
            </div>
          </Popover>

          <Popover
            id="startDatePopover"
            open={!!anchorEl.anchorElEndDate}
            anchorEl={anchorEl.anchorElEndDate}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <div style={{ paddingTop: '32px' }}>
              <CustomDatePicker
                isRangePicker={!datepickerProps.isSingleDate}
                setDateRangeValue={(e) => handleDateChange(e, 'enddate')}
                dateRange={datepickerProps.dateRange}
                shouldDisableDate={(date: Date) => getDisabledEndDate(date)}
              />
            </div>
          </Popover>
        </>
      )}
    </Grid>
  );
}
export default CustomRequest;
