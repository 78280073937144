import { createSlice, current } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export const sarUpdatedFieldHistorySlice = createSlice({
    name: 'fieldHistoryDelegationCommentsData',
    initialState: [
        { Function: []},
        { Justification: [] },
        { Requestor_details: [] },
        { Team_details: [] },
        { Service_account_details:[] },
        { Select_domain:[] },
        { Service_account_name:[] },
        { Choose_account_type:[] },
        { Choose_service_account_function:[] },
        { Account_function_justification:[] },
        { Require_interactive_logon:[] },
        { Justification_for_interactive_logon: [] },
        { Additional_function: [] },
        { Is_single_server:[] },
        { Server_hostnames:[] },
        { Choose_server_tier: [] },
        { User_rights_and_privileges:[] },
        { Group_membership:[] },
        { External_And_Internal_IP_Details:[] },
        { External_internal_ip_ranges: [] },
        { Service_account_request_spn_dele: [] },
        { SPN_list:[]},
        { required_delegtion: [] },
        { Delegation_list: [] },
        { Delegation_reason:[]},
        { Password_change_procedures: [] },
        { Security_of_passwords: [] },
        { Password_will_be_changed: [] },
        { Default_password_expiry_policy: [] },
        { Business_impact: [] },
        { Risk_exposure: [] },
        { Risk_of_the_service_account: [] },
        { Account_was_compromised: [] },
        { Attachments:[]}

    ],
    reducers: {
        updatedFieldHistorySar: (state, action: PayloadAction<any>) => {
            return [...action.payload]
        },
    }
});

export const { updatedFieldHistorySar } = sarUpdatedFieldHistorySlice.actions;
export default sarUpdatedFieldHistorySlice.reducer;


                       

  






