import axios from '../common/axiosInstance';

export const fetchMasterDataEndpoints = {
  domains: '/get_all_domain/',
  requestType: '/get_request_type/',
  approverStages: '/get_all_stages/',
  country: '/get_all_country/',
};

export const fetchMasterData = async (endPoint: string) => {
  const { data } = await axios.get(endPoint);
  return data;
};
// search_by_account_info
export const searchUserByQueryDetails = async (firstName: string, lastName: string) => {
  let queryString = '?';
  if (firstName && firstName !== '') {
    queryString += `first_name=${firstName}`;
  }
  if (lastName && lastName !== '') {
    queryString += `last_name=${lastName}`;
  }
  const res = await axios.get(`/search_by_account_info/${queryString}`);

  let userData: any = [];

  if (res?.data?.data?.value && res?.data.data.value.length) {
    userData = res.data.data.value.map((oktaUserObj: any) => ({
      email: oktaUserObj.new_primaryemail,
      first_name: oktaUserObj.new_firstname,
      last_name: oktaUserObj.new_lastname,
      country: oktaUserObj.new_country,
      city: oktaUserObj.new_city,
      department: oktaUserObj.new_department,
      phone: oktaUserObj.new_primaryphone,
      company: oktaUserObj.new_company,
      title: oktaUserObj.new_title,
    }));
  }
  if (res?.data?.data && res?.data.data.length) {
    userData = res.data.data.map((userObj: any) => ({
      email: userObj.new_primaryemail,
      first_name: userObj.new_firstname,
      last_name: userObj.new_lastname,
      country: userObj.new_country,
      city: userObj.new_city,
      department: userObj.new_department,
      phone: userObj.new_primaryphone,
      // manager_email: userObj.manager_email,
      // manager_first_name: userObj.manager_first_name,
      // manager_last_name: userObj.manager_last_name,
    }));
  }
  return userData;
};

export const searchUserByQuery = async (firstName: string, lastName: string) => {
  let queryString = '?';
  if (firstName && firstName !== '') {
    queryString += `first_name=${firstName}`;
  }
  if (lastName && lastName !== '') {
    queryString += `last_name=${lastName}`;
  }
  const res = await axios.get(`/search_by_name/${queryString}`);
  let userData = [];
  if (res?.data?.data && res?.data.data.length) {
    userData = res.data.data.map((userObj: any) => ({

      email: userObj.email,
      first_name: userObj.first_name,
      last_name: userObj.last_name,
      manager_email: userObj.manager_email,
      manager_first_name: userObj.manager_first_name,
      manager_last_name: userObj.manager_last_name,
    }));
  } else if (res?.data?.data?.value && res?.data.data.value.length) {
    userData = res.data.data.value.map((oktaUserObj: any) => ({
      email: oktaUserObj.new_primaryemail,
      first_name: oktaUserObj.new_firstname,
      last_name: oktaUserObj.new_lastname,
      new_homeorg: oktaUserObj.new_homeorg,
      manager_email: oktaUserObj.new_manager,
      manager_first_name: oktaUserObj.new_manager,
      manager_last_name: oktaUserObj.new_manager,
    }));
  }
  return userData;
};

export const getAllCitiesByCountryId = async (id: number | undefined) => {
  let queryString = '';
  if (id && id >= 0) {
    queryString += `?country=${id}`;
  }
  const { data } = await axios.get(`get_all_city/${queryString}`);
  return data;
};

export const getAllCountries = async () => {
  const { data } = await axios.get('/get_all_country/');
  return data;
};

export const getLoginSyncUpCall = async () => {
  const { data } = await axios.get('/loginsyncup/');
  return data;
};
