import React from "react";

export const AdministrativeSummaryColumnMapping = {
  request_number: 'request_number',
  requestId: "requested_id",
  requestorName: "requestor_name",
  requestType: "request_type",
  requestStatus: "request_status",
  actionOwner: "action_owner",
  daysPending: "days_pending",
  requestedOn: "requested_on",
  actions: "actions",
};

export function createColumns(
  key: number,
  label: string,
  icon: any,
  id: string,
  isActive: boolean,
  handleClick?: (_e: any) => void,
  style?: React.CSSProperties
) {
  return {
    key,
    label,
    icon,
    handleClick,
    id,
    isActive,
    style,
  };
}

export function createRowCells(
  columnId: string,
  renderComponent: any,
  id: string,
  columnNumber: number,
  handleClick?: undefined | ((_e: any) => void),
  style?: React.CSSProperties
) {
  return {
    columnId,
    renderComponent,
    id,
    handleClick,
    columnNumber,
    style,
  };
}

export const initialTableParameters = {
  page: 1,
  orderBy: {
    order: "asc",
    column: "",
  },
};

export interface ITableParameters {
  page: number;
  orderBy: {
    order: string;
    column: string;
  };
}
export interface IDateRangeObj {
  startDate: Date | null;
  endDate: Date | null;
}

export interface IFiltersObj {
  requestId: string[];
  requestorName: string[];
  requestType: string[];
  requestStatus: number[];
  actionOwner: string[];
  daysPending: string[];
  requestedOn: IDateRangeObj;
}

export const emptyFilters = {
  requestId: [],
  requestorName: [],
  requestType: [],
  requestStatus: [],
  actionOwner: [],
  daysPending: [],
  requestedOn: {
    startDate: null,
    endDate: null,
  },
};
export type changeData = {
  id: number,
  name: string,
  stage?: string,
  email?: string,
}

export type filterDataType = {
  id: string,
  value: string,
}
export interface IAdministrativeSummaryData {
  request_number: string,
  action_owner: string;
  created_at: string;
  days_pending: number;
  is_request_to_update: boolean;
  remediation_by: string;
  remediation_count: number;
  remediation_stage: string | number;
  request_id: string;
  request_stage: string | number;
  request_status: string;
  request_to_update_count: number;
  request_type: string;
  status_id: string;
  requested_on: string;
  requestor_name: string;
  stage_id: number | string;
}
export const columnArrayForHistoryTable = [
  "Last Update Requested",
  "Requestor Name",
  "Justification By Requestor",
  "Action Taken",
  "Justification By Admin",
]