import React, { useState } from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Tab from "@mui/material/Tab";
import administrativeSummaryStyles from "./administrative-summary-styles";
import AdministrativeSummary from "./admin-summary/AdministrativeSummary";
import UserManagement from "./userManagement/UserManagement";
import ApproverManagement from "./approverManagement/ApproverManagement";
import ImplementerManagement from "./implementerManagement/implementerManagement";

const useStyles = makeStyles((theme) => ({
  tabPanel: {
    padding: "0px 0px 0px 10px",
    width: "100%",
  },
  customLabelColor: {
    "& button": {
      fontFamily: "Poppins-SemiBold !important",
      color: "#0A2756 !important",
      textTransform: "unset",
      "&.MuiTab-root.Mui-selected": {
        color: "#0080FF !important",
        fontFamily: "Poppins-Medium !important",
        textDecoration: "underline",
      },
    },
  },
}));

function myAdministrativeTasks() {
  const styleClasses = administrativeSummaryStyles();
  const classes = useStyles();
  const [value, setValue] = useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div
      className={styleClasses.pageContainer}
      data-testid="approvalsummary-screen"
    >
      <Grid container spacing={2}>
        <TabContext value={value}>
          <TabList onChange={handleChange} className={classes.customLabelColor}>
            <Tab
              disableTouchRipple
              disableFocusRipple
              disableRipple
              label={<span>Administrator Summary</span>}
              value="1"
            />
            <Tab
              disableTouchRipple
              disableFocusRipple
              disableRipple
              label={<span>Role Management</span>}
              value="2"
            />
            <Tab
              disableTouchRipple
              disableFocusRipple
              disableRipple
              label={<span>Approver Management</span>}
              value="3"
            />
            <Tab
              disableTouchRipple
              disableFocusRipple
              disableRipple
              label={<span>Implementer Management</span>}
              value="4"
            />
          </TabList>

          <TabPanel value="1" classes={{ root: classes.tabPanel }}>
            <AdministrativeSummary />
          </TabPanel>
          <TabPanel value="2" classes={{ root: classes.tabPanel }}>
            <UserManagement />
          </TabPanel>
          <TabPanel value="3" classes={{ root: classes.tabPanel }}>
            <ApproverManagement />
          </TabPanel>
          <TabPanel value="4" classes={{ root: classes.tabPanel }}>
            <ImplementerManagement />
          </TabPanel>
        </TabContext>
      </Grid>
    </div>
  );
}
export default myAdministrativeTasks;
