import React, { Children, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  FormikValues,
  FormikHelpers,
} from 'formik';

import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { IFormikContainerProps } from './formikContainerUtils';
import { parBody } from '../../../constants/parBody';
import { RootState } from '../../../appStore/store';
import { IModalState } from './formikContainerUtils';
import modalStyles from '../../custom-modal/modalStyles';
import { FormikStepper } from './formikStepper';
import CustomButton from '../../button';
import { postNewRequestPar } from '../../../api-services/par';
import { FormikStep } from './formikStep';
import RequestorAndOwner from './formSteps/requestorAndOwner';
import ManagerDetails from './formSteps/managerDetails';
import AccountJustification from './formSteps/accountJustification';
import { format, isDate } from 'date-fns';
import CustomModal from '../../custom-modal';
import {
  useHistory,
  Prompt,
  useLocation,
} from 'react-router-dom';
import { postParFieldHistoryData } from '../../../api-services/par';
import AccountCreation from "./formSteps/accountCreation";
import CloudImplementation from "./formSteps/cloudImplementation";
import AccountCreationAD from "./formSteps/accountCreationAD";
import PamImplementation from "./formSteps/pamImplementation";
import { emptySchemaCheck, fieldsOfSection } from './formikContainerUtils';
import { routeCheckForAdminSummary, routeCheckForImplementationSummarypar } from './formSteps/routingConditional';



const initialValuesData = {
  ...parBody,
}

function FormikContainerPar({
  ...props
}: IFormikContainerProps) {
  const history = useHistory();
  const location = useLocation();
  const formatDate = (dateValue: any) => (dateValue && isDate(new Date(dateValue)) ? format(new Date(dateValue), 'yyyy-MM-dd') : '');
  const { enqueueSnackbar } = useSnackbar();
  const { loginuserdata, loginsyncupdata } = useSelector((state: RootState) => state.loginData);
  const { cloudImplementationList } = useSelector((state: RootState) => state);
  const routeCheck = () => {
    return location.pathname.slice(1, 35).toLocaleLowerCase() === 'myapprovaltasks/pendingapprovalpar'
  }

  const [modalData, setModalData] = useState<IModalState>({
    show: false,
    modalHeader: '',
    modalContent: null,
    modalActions: null,
  });
  const centerButton = false;
  const modalClasses = modalStyles({ centerButton });

  const routCheckApprovalSummary = () => {
    return location.pathname.slice(1, 35).toLocaleLowerCase() === 'myapprovaltasks/approvalsummarypar'
  }

  const routeCheckForImplementationSummary = () => {
    return location.pathname.slice(1, 47).toLocaleLowerCase() === 'myimplementationtasks/pendingimplementationpar'
  }

  const implementationCheckForLinkedPar = () => {

    if ((props.data.request_purpose !== ('Connect to / manage a server or computer' || 'Test a service / product as a part of project')) && props.data.par_domain === 'ad.insidemedia.net' && (props.data.status_label === 'Completed' || props.data.implementer_type === 'On-premise implementation engineer')) {
      return (
        <FormikStep validationSchema={{}}>
          <AccountCreationAD isDisabled={checkIsDisabled()} />
        </FormikStep>
      )
    } else if (props.data.par_domain === 'Hybrid (AD/Azure)' && props.data.implementer_type === 'On-premise implementation engineer' && !props.data.is_ad_task_list_completed) {
      return (

        <FormikStep validationSchema={{}}>
          <AccountCreationAD isDisabled={checkIsDisabled()} />
        </FormikStep>
      )
    } else if (props.data.par_domain === 'Hybrid (AD/Azure)' && props.data.implementer_type === 'On-premise implementation engineer' && props.data.is_ad_task_list_completed) {
      return (
        <FormikStep validationSchema={{
          provide_service_number_aad_onPrem: Yup.string().required('Please provide serviceNow ticket number'),
          // mark_step_aad_onPrem: Yup.string().when('provide_service_number_aad_onPrem', {
          //   is: (val: string) => val === '',
          //   then: Yup.string().required('Please provide serviceNow ticket number'),
          //   otherwise: Yup.string(),
          // }),
        }}>
          <AccountCreation isDisabled={(routeCheckForImplementationSummary() && props.data.implementer_type === 'Cloud implementation engineer') ? true : false} />
        </FormikStep>
      )
    }
    else if ((props.data.par_domain === 'insidemedia.onmicrosoft.com') && (props.data.implementer_type === 'On-premise implementation engineer')) {
      return (
        <FormikStep validationSchema={{
          provide_service_number_aad_onPrem: Yup.string().required('Please provide serviceNow ticket number'),
          // mark_step_aad_onPrem: Yup.string().when('provide_service_number_aad_onPrem', {
          //   is: (val: string) => val === '',
          //   then: Yup.string().required('Please provide serviceNow ticket number'),
          //   otherwise: Yup.string(),
          // }),
        }}>
          <AccountCreation isDisabled={(routeCheckForImplementationSummary() && props.data.implementer_type === 'Cloud implementation engineer') ? true : false} />
        </FormikStep>
      )
    } else if (((props.data.par_domain !== 'insidemedia.onmicrosoft.com') && ((props.data.implementer_type ? props.data.status_label === 'Completed' : false) || props.data.implementer_type === 'On-premise implementation engineer'))) {

      return (
        <FormikStep validationSchema={{}}>
          <AccountCreationAD isDisabled={checkIsDisabled()} />
        </FormikStep>
      )
    }
  }

  const checkIsDisabledImplementation = () => {
    if ((routeCheckForImplementationSummary() && (props.data.status_label === 'Completed' || props.data.is_implemented)) ||
      (routeCheckForAdminSummary() && (props.data.status_label === 'Completed' || props.data.is_implemented)) ||
      (routCheckApprovalSummary() && (props.data.status_label === 'Completed' || props.data.is_implemented)) ||
      (routeCheckForImplementationSummarypar() && (props.data.status_label === 'Completed' || props.data.is_implemented))
    ) {
      return true;
    } else return false;
  };

  const checkIsDisabled = () => {
    if (routeCheckForAdminSummary()) {
      return true
    } else if (routeCheckForImplementationSummarypar()) {
      return true
    } else if (routeCheckForImplementationSummary()) {
      return false;
    }
    else if (props.data.status_label === 'Draft'
      || props.data.status_label === 'Remediation Requested') {
      return false;
    } else {
      return true
    }
  }
  const historyDataOfForm = JSON.parse(localStorage.getItem('historyDataOfMainSchema') || '');
  return (
    <>
      <FormikStepper
        initialValues={
          {
            ...initialValuesData,
            ...props.data,
            request_id: props.requestId,

            // par_job_title_explanation: 'shantanu'
          }
        }
        onSubmit={async (values: FormikValues, formikHelpers: FormikHelpers<FormikValues>) => {

          if (props.data.remediation_count > 0) {
            const changedValues = Object.keys(values).filter(key => values[key] !== historyDataOfForm[key])
            const staticDifferenceData = ['description', 'Account_owner_details', 'Third_Manager_Party_details', 'Manager_details', 'ServiceNow_ticket_number', 'Domain', 'dataverse', 'oktaCompany', 'oktaPhone', 'oktaCity', 'oktaCountry', 'oktaJobTitle', 'oktaDepartment', 'request_type', 'event', 'request_id', 'par_date_raised', 'par_internal_manager_name', 'Job_description']


            const newArray = changedValues.filter(v => !staticDifferenceData.includes(v))
            let finalChangedData: any = [];
            newArray.length > 0 && newArray.map((item) => {
              finalChangedData.push(
                {
                  update_type: emptySchemaCheck.get(item),
                  section: fieldsOfSection.get(item),
                  sub_section: item,
                  value: historyDataOfForm[item],
                }
              )

            })

            const historyPostBody = {
              par_id: props.requestId,
              is_submitted: false,
              data: finalChangedData
            }

            if (finalChangedData.length > 0) {
              postParFieldHistoryData(historyPostBody).then((response) => {
                console.log(response)
              }).catch(error => error);
            }

          }


          if (routeCheck() || routCheckApprovalSummary()) {
          } else {
            formikHelpers.setSubmitting(true);
            await postNewRequestPar({ ...values, event: props.requestId !== '' ? 'update' : 'create', status: '4', request_id: props.requestId, par_date_raised: formatDate(values.par_date_raised) }).then((res) => {
              if (res.status) {
                formikHelpers.resetForm({ values: res.data });
                setModalData({
                  show: true,
                  modalHeader: 'Success',
                  modalContent: (
                    <div>
                      <div className={modalClasses.modalContentDiv}>
                        <div>
                          {values.remediation_count === 0 ? `Your PAR request has been created successfully` : `Your PAR request has been updated and re-submitted successfully`}
                        </div>
                        <div>
                          Your PAR is {res.data.request_number}
                        </div>
                      </div>
                      <div className={modalClasses.modalTextDiv}>
                        <span className={modalClasses.spanText}>
                          {'Note: '}
                        </span>
                        You will shortly receive an email confirmation with a link to your PAR. You can use this link to return to the GPAG application to review the progress of your request. Please note that while in the review and approvals stages you will not be able to edit your request.<br /> <br />  You may be contacted for further information by the approvers during their review. Your prompt response to any queries will allow your request to be dealt with as quickly as possible.
                        <br />
                        Please note that while in the review and approvals stages you will not be able to edit your request.
                        You may be contacted for further information by the approvers during their review. Your prompt response to any queries will allow your request to be dealt with as quickly as possible.
                      </div>
                    </div>
                  ),
                  modalActions: (
                    <div className={modalClasses.modalActionDiv}>
                      <CustomButton
                        variant="contained"
                        actionType="primary"
                        disableMargin
                        onClick={() => history.push('/par')}
                      >
                        Ok
                      </CustomButton>
                    </div>
                  ),
                });
              } else {
                console.error(res);
              }
            }).catch((err) => {
              console.log(err);
              enqueueSnackbar('Something went wrong, please try again', { variant: 'error' });
            }).finally(() => {
              formikHelpers.setSubmitting(false);
            });
          }

        }}
        requestId={props.requestId}
        requestData={props.data}
      >
        <FormikStep
          validationSchema={Yup.object({
            onSave: Yup.boolean(),
            par_requestor_first_name: Yup.string().required('Please enter first name of requestor'),
            par_requestor_last_name: Yup.string().required('Please enter last name of requestor'),
            par_requestor_email: Yup.string().required('Please provide action owner email'),
            // par_is_third_party_company: Yup.string().required('Please provide 3rd party company'),
            par_date_raised: Yup.string().required('Please provide the date for par'),
            par_first_name: Yup.string().nullable().required('Please enter first name of action owner'),
            par_last_name: Yup.string().nullable().required('Please enter last name of action owner '),
            par_department: Yup.string().nullable().required('Please provide department of action owner'),
            par_company: Yup.string().nullable().required('Please select your company name from given options'),
            par_city: Yup.string().nullable().required('Please select city from given options'),
            par_email: Yup.string().nullable().required('Please provide action owner email'),
            par_job_title: Yup.string().nullable().required('Please provide a valid job title'),
            par_job_title_explanation: Yup.string().nullable().required('Please provide a valid description for job title'),
            par_phone: Yup.string().nullable().required('Please provide phone number')
              .test('len', 'Please provide a valid phone number', (val) => {
                if (val && val.length >= 7) return true;
                return false;
              }),
            par_country: Yup.string().nullable().required('Please provide country'),
            // Account_requestor_details: Yup.string().required('please enter your comment'),
            // Job_description: Yup.string().required('please enter your comment'),
            // Account_owner_details: Yup.string().required('please enter your comment'),

          })}
        >
          <RequestorAndOwner isDisabled={checkIsDisabled()} />
        </FormikStep>
        <FormikStep
          validationSchema={Yup.object({
            onSave: Yup.boolean(),
            par_internal_manager_first_name: Yup.string().when('par_company', {
              is: (val: string) => val !== ('3rd Party' || 'Third Party'),
              then: Yup.string().nullable().required(`Please enter manager's first name`),
              otherwise: Yup.string().nullable(),
            }),
            par_internal_manager_last_name: Yup.string().when('par_company', {
              is: (val: string) => val !== ('3rd Party' || 'Third Party'),
              then: Yup.string().nullable().required(`Please enter manager's last name`),
              otherwise: Yup.string().nullable(),
            }),
            par_internal_manager_email: Yup.string().when('par_company', {
              is: (val: string) => val !== ('3rd Party' || 'Third Party'),
              then: Yup.string().nullable().required(`Please enter manager's email`).nullable(),
              otherwise: Yup.string().nullable(),
            }),
            par_internal_manager_phone: Yup.string().when('par_company', {
              is: (val: string) => val !== ('3rd Party' || 'Third Party'),
              then: Yup.string().nullable().required(`Please provide manager's phone number`)
                .test('len', 'Please provide a valid phone number', (val) => {
                  if (val && val.length >= 7) return true;
                  return false
                }),
              otherwise: Yup.string().nullable(),
            }),
            par_is_third_party_office: Yup.string().nullable().required('Please enter 3rd party office name'),
            par_third_party_manager_first_name: Yup.string().when('par_company', {
              is: (val: string) => val === ('3rd Party' || 'Third Party'),
              then: Yup.string().nullable().required(`Please enter 3rd party manager's first name`),
              otherwise: Yup.string().nullable(),
            }),
            par_third_party_manager_last_name: Yup.string().when('par_company', {
              is: (val: string) => val === ('3rd Party' || 'Third Party'),
              then: Yup.string().nullable().required(`Please enter 3rd party manager's last name`),
              otherwise: Yup.string().nullable(),
            }),
            par_third_party_manager_phone: Yup.string()
              .when('par_company', {
                is: (val: string) => val === ('3rd Party' || 'Third Party'),
                then: Yup.string().nullable().required('Please provide phone number')
                  .test('len', 'Please provide a valid phone number', (val) => {
                    if (val && val.length >= 7) return true;
                    return false
                  }),
                otherwise: Yup.string().nullable(),
              }),
            par_third_party_manager_email: Yup.string().when('par_company', {
              is: (val: string) => val === ('3rd Party' || 'Third Party'),
              then: Yup.string().nullable().required(`Please provide email`),
              otherwise: Yup.string().nullable(),
            }),
            par_point_of_escalation_in_third_party: Yup.string()
              .when("par_company", {
                is: (val: string) => val === ('3rd Party' || 'Third Party'),
                then: Yup.string().nullable().required("Please provide a valid email address").nullable()
              }),
          })
          }
        >
          <ManagerDetails isDisabled={checkIsDisabled()} />
        </FormikStep>
        <FormikStep
          validationSchema={Yup.object({
            onSave: Yup.boolean(),
            par_servicenow_ticket_number: Yup.string().nullable(),
            par_domain: Yup.string().required('Please select a domain').nullable(),
            par_account_justification: Yup.string().required('Please provide account justification').nullable(),
          })}
        >
          <AccountJustification isDisabled={checkIsDisabled()} />
        </FormikStep>

        {(routeCheckForAdminSummary() || routCheckApprovalSummary() || routeCheckForImplementationSummarypar()) && (props.data.request_purpose !== 'Test a service / product as a part of project') && (props.data.request_purpose !== 'Connect to / manage a server or computer') && (props.data.status_label === 'Completed') && (props.data.par_domain === 'insidemedia.onmicrosoft.com') &&
          <>
            <FormikStep validationSchema={{
              provide_service_number_aad_onPrem: Yup.string().required('Please provide serviceNow ticket number'),
              // mark_step_aad_onPrem: Yup.string().when('provide_service_number_aad_onPrem', {
              //   is: (val: string) => val === '',
              //   then: Yup.string().required('Please provide serviceNow ticket number'),
              //   otherwise: Yup.string(),
              // }),
            }}>
              <AccountCreation isDisabled={(routeCheckForImplementationSummary() && props.data.implementer_type === 'Cloud implementation engineer') ? true : false} />
            </FormikStep>
          </>
        }
        {(routeCheckForAdminSummary() || routCheckApprovalSummary() || routeCheckForImplementationSummarypar()) && (props.data.request_purpose !== 'Test a service / product as a part of project') && (props.data.request_purpose !== 'Connect to / manage a server or computer') && (props.data.status_label === 'Completed') && (props.data.par_domain === 'insidemedia.onmicrosoft.com') &&
          <>
            <FormikStep validationSchema={{}}>
              <CloudImplementation isDisabled={checkIsDisabled()} />
            </FormikStep>
          </>
        }

        {/* ad sar */}
        {(routeCheckForAdminSummary() || routCheckApprovalSummary() || routeCheckForImplementationSummarypar()) && (props.data.status_label === 'Completed') && (props.data.par_domain == 'ad.insidemedia.net(AD)') &&
          <>
            <FormikStep validationSchema={{}}>
              <AccountCreationAD isDisabled={true} />
            </FormikStep>
          </>
        }
        {/* onmicrosoft.com */}
        {(routeCheckForAdminSummary() || routCheckApprovalSummary() || routeCheckForImplementationSummarypar()) && (props.data.status_label === 'Approved - Pending Implementation') && (props.data.par_domain === 'insidemedia.onmicrosoft.com') &&
          <>
            <FormikStep validationSchema={{
              provide_service_number_aad_onPrem: Yup.string().required('Please provide serviceNow ticket number'),
              // mark_step_aad_onPrem: Yup.string().when('provide_service_number_aad_onPrem', {
              //   is: (val: string) => val === '',
              //   then: Yup.string().required('Please provide serviceNow ticket number'),
              //   otherwise: Yup.string(),
              // }),
            }}>
              <AccountCreation isDisabled={(routeCheckForImplementationSummary() && props.data.implementer_type === 'Cloud implementation engineer') ? true : false} />
            </FormikStep>
          </>
        }

        {/* hybrid sar */}
        {(routeCheckForAdminSummary() || routCheckApprovalSummary() || routeCheckForImplementationSummarypar()) && (props.data.status_label === 'Approved - Pending Implementation') && (props.data.par_domain === 'Hybrid (AD/Azure)') && props.data.is_ad_task_list_completed &&
          <>
            <FormikStep validationSchema={{}}>
              <AccountCreationAD isDisabled={true} />
            </FormikStep>
          </>
        }
        {(routeCheckForAdminSummary() || routCheckApprovalSummary() || routeCheckForImplementationSummarypar()) && (props.data.status_label === 'Approved - Pending Implementation') && (props.data.par_domain === 'Hybrid (AD/Azure)') && props.data.is_ad_task_list_completed && props.data.implementer_type === 'Cloud implementation engineer' &&
          <>
            <FormikStep validationSchema={{
              provide_service_number_aad_onPrem: Yup.string().required('Please provide serviceNow ticket number'),
              // mark_step_aad_onPrem: Yup.string().when('provide_service_number_aad_onPrem', {
              //   is: (val: string) => val === '',
              //   then: Yup.string().required('Please provide serviceNow ticket number'),
              //   otherwise: Yup.string(),
              // }),
            }}>
              <AccountCreation isDisabled={(routeCheckForImplementationSummary() && props.data.implementer_type === 'Cloud implementation engineer') ? true : false} />
            </FormikStep>
          </>
        }


        {(routeCheckForAdminSummary() || routCheckApprovalSummary() || routeCheckForImplementationSummarypar()) && (props.data.status_label === 'Completed') && (props.data.par_domain === 'Hybrid (AD/Azure)') &&
          <>
            <FormikStep validationSchema={{}}>
              <AccountCreationAD isDisabled={true} />
            </FormikStep>
          </>
        }
        {(routeCheckForAdminSummary() || routCheckApprovalSummary() || routeCheckForImplementationSummarypar()) && (props.data.status_label === 'Completed') && (props.data.par_domain === 'Hybrid (AD/Azure)') &&
          <>
            <FormikStep validationSchema={{
              provide_service_number_aad_onPrem: Yup.string().required('Please provide serviceNow ticket number'),
              // mark_step_aad_onPrem: Yup.string().when('provide_service_number_aad_onPrem', {
              //   is: (val: string) => val === '',
              //   then: Yup.string().required('Please provide serviceNow ticket number'),
              //   otherwise: Yup.string(),
              // }),
            }}>
              <AccountCreation isDisabled={(routeCheckForImplementationSummary() && props.data.implementer_type === 'Cloud implementation engineer') ? true : false} />
            </FormikStep>
          </>
        }
        {(routeCheckForAdminSummary() || routCheckApprovalSummary() || routeCheckForImplementationSummarypar()) && (props.data.status_label === 'Completed') && (props.data.par_domain === 'Hybrid (AD/Azure)') &&
          <>
            <FormikStep validationSchema={{}}>
              <CloudImplementation isDisabled={checkIsDisabled()} />
            </FormikStep>
          </>
        }
        {((checkIsDisabledImplementation() || routeCheckForImplementationSummary()) && (props.data.implementer_type === 'On-premise implementation engineer') && (props.data.par_domain === 'Hybrid (AD/Azure)')) && (props.data.is_ad_task_list_completed) && (
          <>
            <FormikStep validationSchema={{}}>
              <AccountCreationAD isDisabled={true} />
            </FormikStep>
          </>
        )}
        {((checkIsDisabledImplementation() || routeCheckForImplementationSummary()) && (props.data.implementer_type === 'Cloud implementation engineer') && (props.data.par_domain === 'Hybrid (AD/Azure)')) && (
          <>
            <FormikStep validationSchema={{}}>
              <AccountCreationAD isDisabled={true} />
            </FormikStep>
          </>
        )}
        {((checkIsDisabledImplementation() || routeCheckForImplementationSummary()) && (props.data.implementer_type === 'Cloud implementation engineer') && (props.data.par_domain === 'Hybrid (AD/Azure)')) && (
          <>
            <FormikStep validationSchema={{
              provide_service_number_aad_onPrem: Yup.string().required('Please provide serviceNow ticket number'),
              // mark_step_aad_onPrem: Yup.string().when('provide_service_number_aad_onPrem', {
              //   is: (val: string) => val === '',
              //   then: Yup.string().required('Please provide serviceNow ticket number'),
              //   otherwise: Yup.string(),
              // }),
            }}>
              <AccountCreation isDisabled={(routeCheckForImplementationSummary() && props.data.implementer_type === 'Cloud implementation engineer') ? true : false} />
            </FormikStep>
          </>
        )}

        {((checkIsDisabledImplementation() || routeCheckForImplementationSummary()) && (props.data.implementer_type === 'Cloud implementation engineer') && (props.data.par_domain === 'Hybrid (AD/Azure)')) && (
          <>
            <FormikStep validationSchema={{}}>
              <CloudImplementation isDisabled={checkIsDisabled()} />
            </FormikStep>
          </>
        )}
        {((checkIsDisabledImplementation() || routeCheckForImplementationSummary()) && (props.data.implementer_type === 'Cloud implementation engineer') && (props.data.par_domain === 'insidemedia.onmicrosoft.com')) && (
          <>
            <FormikStep validationSchema={{
              provide_service_number_aad_onPrem: Yup.string().required('Please provide serviceNow ticket number'),
              // mark_step_aad_onPrem: Yup.string().when('provide_service_number_aad_onPrem', {
              //   is: (val: string) => val === '',
              //   then: Yup.string().required('Please provide serviceNow ticket number'),
              //   otherwise: Yup.string(),
              // }),
            }}>
              <AccountCreation isDisabled={(routeCheckForImplementationSummary() && props.data.implementer_type === 'Cloud implementation engineer') ? true : false} />
            </FormikStep>
          </>
        )}

        {/* test account */}
        {
          ((routeCheckForAdminSummary() || routCheckApprovalSummary() || routeCheckForImplementationSummarypar()) && (props.data.request_purpose === 'Test a service / product as a part of project') && (props.data.status_label === 'Approved - Pending Implementation') && (props.data.par_domain === 'insidemedia.onmicrosoft.com') && (
            <>
              <FormikStep validationSchema={{
                provide_service_number_aad_onPrem: Yup.string().required('Please provide serviceNow ticket number'),
                // mark_step_aad_onPrem: Yup.string().when('provide_service_number_aad_onPrem', {
                //   is: (val: string) => val === '',
                //   then: Yup.string().required('Please provide serviceNow ticket number'),
                //   otherwise: Yup.string(),
                // }),
              }}>
                <AccountCreation isDisabled={(routeCheckForImplementationSummary() && props.data.implementer_type === 'Cloud implementation engineer') ? true : false} />
              </FormikStep>
            </>
          ))
        }
        {
          ((routeCheckForAdminSummary() || routCheckApprovalSummary() || routeCheckForImplementationSummarypar()) && (props.data.request_purpose === 'Test a service / product as a part of project') && (props.data.status_label === 'Completed') && (props.data.par_domain === 'insidemedia.onmicrosoft.com') && (
            <>
              <FormikStep validationSchema={{
                provide_service_number_aad_onPrem: Yup.string().required('Please provide serviceNow ticket number'),
                // mark_step_aad_onPrem: Yup.string().when('provide_service_number_aad_onPrem', {
                //   is: (val: string) => val === '',
                //   then: Yup.string().required('Please provide serviceNow ticket number'),
                //   otherwise: Yup.string(),
                // }),
              }}>
                <AccountCreation isDisabled={(routeCheckForImplementationSummary() && props.data.implementer_type === 'Cloud implementation engineer') ? true : false} />
              </FormikStep>
            </>
          ))
        }
        {
          ((routeCheckForAdminSummary() || routCheckApprovalSummary() || routeCheckForImplementationSummarypar()) && (props.data.request_purpose === 'Test a service / product as a part of project') && (props.data.status_label === 'Completed') && (props.data.par_domain === 'insidemedia.onmicrosoft.com') && (
            <>
              <FormikStep validationSchema={{}}>
                <CloudImplementation isDisabled={checkIsDisabled()} />
              </FormikStep>
            </>
          ))
        }
        {/* Admin account */}
        {
          ((routeCheckForAdminSummary() || routCheckApprovalSummary() || routeCheckForImplementationSummarypar()) && (props.data.request_purpose === 'Connect to / manage a server or computer') && (props.data.status_label === 'Approved - Pending Implementation') && (props.data.par_domain === 'insidemedia.onmicrosoft.com') && (
            <>
              <FormikStep validationSchema={{
                provide_service_number_aad_onPrem: Yup.string().required('Please provide serviceNow ticket number'),
                // mark_step_aad_onPrem: Yup.string().when('provide_service_number_aad_onPrem', {
                //   is: (val: string) => val === '',
                //   then: Yup.string().required('Please provide serviceNow ticket number'),
                //   otherwise: Yup.string(),
                // }),
              }}>
                <AccountCreation isDisabled={(routeCheckForImplementationSummary() && props.data.implementer_type === 'Cloud implementation engineer') ? true : false} />
              </FormikStep>
            </>
          ))
        }
        {(routeCheckForAdminSummary() || routCheckApprovalSummary() || routeCheckForImplementationSummarypar()) && (props.data.request_purpose === 'Connect to / manage a server or computer') && (props.data.status_label === 'Completed') && (props.data.par_domain === 'insidemedia.onmicrosoft.com') &&
          <>
            <FormikStep validationSchema={{
              provide_service_number_aad_onPrem: Yup.string().required('Please provide serviceNow ticket number'),
              // mark_step_aad_onPrem: Yup.string().when('provide_service_number_aad_onPrem', {
              //   is: (val: string) => val === '',
              //   then: Yup.string().required('Please provide serviceNow ticket number'),
              //   otherwise: Yup.string(),
              // }),
            }}>
              <AccountCreation isDisabled={true} />
            </FormikStep>
          </>
        }
        {(routeCheckForAdminSummary() || routCheckApprovalSummary() || routeCheckForImplementationSummarypar()) && (props.data.request_purpose === 'Connect to / manage a server or computer') && (props.data.status_label === 'Completed') && (props.data.par_domain === 'insidemedia.onmicrosoft.com') &&
          <>
            <FormikStep validationSchema={{}}>
              <CloudImplementation isDisabled={true} />
            </FormikStep>
          </>
        }
        {(routeCheckForAdminSummary() || routCheckApprovalSummary() || routeCheckForImplementationSummarypar()) && (props.data.request_purpose === 'Connect to / manage a server or computer') && (props.data.status_label === 'Completed') && (props.data.par_domain !== 'insidemedia.onmicrosoft.com') &&
          <>
            <FormikStep validationSchema={{}}>
              <AccountCreationAD isDisabled={true} />
            </FormikStep>
          </>
        }
        {
          ((checkIsDisabledImplementation() || routeCheckForImplementationSummary()) && (props.data.implementer_type === 'Cloud implementation engineer') && (props.data.par_domain === 'insidemedia.onmicrosoft.com')) && (
            <>
              <FormikStep validationSchema={{}}>
                <CloudImplementation isDisabled={checkIsDisabled()} />
              </FormikStep>
            </>
          )
        }
        {(checkIsDisabledImplementation() || routeCheckForImplementationSummary()) && implementationCheckForLinkedPar()}
      </FormikStepper >
      <CustomModal
        open={modalData.show}
        modalHeader={modalData.modalHeader}
        modalContent={modalData.modalContent}
        modalAction={modalData.modalActions}
      />
    </>
  )
}

export default FormikContainerPar;







