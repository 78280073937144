import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  tooltipIcon: {
    '& .MuiSvgIcon-root': {
      width: '18px',
      height: '18px',
    },
    cursor: 'pointer',

  },

  tooltip: {
    color: '#0A2756 !important',
    background: '#FFEDCA !important',
    borderRadius: 4,
    padding: '12px',
    fontFamily: 'NotoSerif-Regular',
    fontSize: '12px',
    marginLeft: '6px!important',
    //background: 'red',
  },
  arrow: {
    color: '#FFEDCA ',
  },

});

export default useStyles;
