import axios from "../common/axiosInstance";

export const fetchAllStages = async () => {
  const endPoint = "get_all_stages/";
  const { data } = await axios.get(endPoint);
  return data;
};

export const fetchStageWiseApprover = async ({ stage }: { stage?: any }) => {
  const endPoint = `/get_stage_wise_approver/?stage=${stage}`;
  const { data } = await axios.get(endPoint);
  return data;
};

export const fetchAllApprover = async () => {
  const endPoint = "get_all_approver/";
  const { data } = await axios.get(endPoint);
  return data;
};

export const postApproverData = async (postBody:any) => {
  const endPoint = "/change_stage_wise_approver/";
  const { data } = await axios.post(endPoint,postBody);
  return data;
};