import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IScommented } from './IScommented'

const initialState: IScommented = {
    isCommented: false,
}

export const isCommentedSummarrySlice = createSlice({
    name: 'iscommented',
    initialState,
    reducers: {
        isCommentPosted: (state, action: PayloadAction<boolean>) => {
            console.log('redux ', action.payload);
            state.isCommented = action.payload
        },
    },
});

// Action creators are generated for each case reducer function
export const { isCommentPosted } = isCommentedSummarrySlice.actions

export default isCommentedSummarrySlice.reducer