

export const reasonForDelegationRequest = "Define the reason for  raising <br /> this Delegation request"

// Justification For Access

export const justificationForAccess = "Explain what activities <br /> require privileged access"

// Please provide details for the team requiring the Delegation. This should include full details for the Manager of the team

export const teamDetails = "Details for the team requiring the <br /> Delegation. This  should include full <br /> details for the manager of the team."

// Requestor Job Details

export const jobTitleDescription = "Provide a brief explanation of your role <br />  and responsibilities. This will inform the <br /> approvers reviewing your request of your <br /> suitability for this access."

//  Account Type

export const chooseAccountType = "Please choose an account type <br /> from the list below"

// Role Definition

export const roleDefinition = "<b>Delegation owner</b>: Each Delegation form is owned by a single manager who is responsible for the team.<br /><br /> <b>Role name</b>: Each Delegation corresponds to the set of rights necessary for the entire team and it may be <br /> necessary to divide the team into specific roles. The manager must define the team role(s) and the rights <br /> assigned to those role(s). The role will have a specific set of rights. <br /><br /> For example, the wintel team, has multiple sub-teams within it. Each of those sub-teams has a specific set of responsibilities. To execute their responsibilities, they need to have rights assigned to them. <br /> Each sub team will require a role. An administrative account may only be a member of one team, for example, if a manager defines you as part of his team and you are granted a role such as NTAM-Wintel L1, you cannot <br /> also be in the EMEA-SQL L1 role at the same time, reporting to another manager. <br /><br /> <b>Scope</b>: Once a role is defined, it may have one or many scopes. A scope in GroupM is one of two things.<br /> Usually this is the geographical limit of the role, such as global, regional, sub-regional or local. <br /><br /> - In the case of sub-regional, a set of cities must be defined for the scope. <br /> - In the case of local the specific city must be defined. <br /><br /> At times the scope may be defined based upon the characteristics of the server, such as all SQL servers,<br /> or all backup targets. Lastly these two types of scopes may be combined, such as all Servers that must be  backed up in a city, i.e. BUE-Backup <br /><br /> <b>Justification</b>: The access requested below must be technically justified. Explain why the team’s role cannot be executed with any lesser rights than requested."

// Role Name

export const roleNameTooltip = "<b>Role name</b>: Each Delegation corresponds to the set of rights necessary for <br /> the entire team and it may be necessary to divide the team into specific <br /> roles. The manager must define the team role(s) and the rights assigned to <br /> those role(s). The role will have a specific set of rights. <br /><br />For example, the Wintel team, has multiple sub-teams within it. Each of <br /> those sub-teams has a specific set of responsibilities. To execute their <br /> responsibilities, they need to have rights assigned to them. Each sub-team <br /> will require a role. An individual may only be a member of one team, for <br /> example, if a manager defines you as part of their team and you are granted <br /> a role such as NTAM-Wintel L1, you cannot also be in the EMEA-SQL L1 role <br /> at the same time, reporting to another manager.<br /><br />The Role name should represent the role per the above description."

// Geographical scope or functional scope


export const scopeTooltip = "<b>Scope</b>: Once a role is defined, it may have one or many scopes. A scope in GroupM is one of two things. Usually this is the geographical limit of the role, such as global, regional, sub-regional or local.<br /><br /> In the case of sub-regional, a set of cities must be defined for the scope.<br /><br /> In the case of local the specific city must be defined. At times the scope may be defined based upon the characteristics of the server, such as all SQL servers, or all backup targets.<br /><br />Lastly these two types of scopes may be combined, such as all servers that must be backed up in a city, i.e. BUE-Backup. If you have a combined requirement, please select Geographical and the appropriate location scope  and add in your Functional scope requirements into the justification box. "
// Slots required
export const roleSlotsTooltip = "The number of users associated with a Delegation should be no more than 50 <br /> and ideally, significantly less. The number of users in a given privilege <br /> role should be kept to the minimum possible. If more than 50 privileged <br />access users are required across the roles within the Delegation, the team <br /> will need to be split into sub-teams and roles and a Delegation request <br /> submitted for each sub-team."


// User Details


export const userDetails = "Provide the user name, account name, and choose a specific domain for each individual <br /> who requires this role"

// Do you want the PAR form to be filled by the user itself?

export const parFormItself = "Ordinarily, it is expected that an individual<br /> would complete their own PAR form.<br /> In the event that the individual has not yet<br /> started in the role, and to allow an expedited <br />start, it is permitted for the requestor of the <br /> Delegation to complete the PAR form on <br />behalf of the individual. "

// Custom & 3rd Party Request

export const provideCustomRequest = "In the event that the previous fields have not <br /> allowed the ability to specify the full details of <br /> access requirements please use custom request in <br />this section to detail any additional information"

// Third Party Request

export const thirdPartyRequest = "Please provide the reference number for your RFS with the 3rd Party <br /> and all the RFS details.<br /><br />This is necessary for the approvers to understand:<br /><br />• The details of the work requested by GroupM from the 3rd Party<br />• How this is planned to be implemented, in detail<br />• What servers, databases or applications will be accessed<br />• What access will be required <br /><br /> The greater detail that is provided the better able the approvers will <br /> be to quickly assess and respond to the request"

// Contract Number To Request For Service Reference

export const contractNumber = "Please provide the reference number <br /> for the RFS with the 3rd Party"

// Request For Service Details

export const serviceDetails = "Please provide the objective of <br /> the RFS and expected outcomes"

// Implementation Plan

export const implementationPlan = "Provide as much detail as possible around what <br />is planned, when it is planned and detail on the <br />steps to test and implement. <br /><br />The greater detail provided the better able the<br /> approvers will be to quickly assess and respond <br /> on the request."

//  Access Required For Request For Service

export const accessForRequest = "Describe the access permissions required in order <br /> to complete this project. The least privileges <br /> necessary to complete the activity should be <br /> requested. The access requested must be technically <br /> justified. Explain why the activity cannot be <br /> executed with any lesser rights than requested."


// 3rd Party Request For Service Timings

export const thirdPartyRequestTimings = "Advise the time period for which this access is <br /> required. Access will only be granted during <br /> those times and afterwards the access will be <br /> revoked and the account will be deleted."


// Privileged Access Delegation - Owner Sign-Off

export const ownerSignOff = 'Please ensure you have read all the guidelines carefully and acknowledge this by checking "I agree to the above statements and accept this responsibility".<br /><br />Please enter today&#39;s date and then add attachments, if required, to support your request.'
export const signaturetooltip = 'Upload of a signature file is optional. If you have one, you may upload it. <br/> <br/> If no signature file is uploaded, checking the above box is accepted as acknowledgement of and agreement to the statements above.'
// Attachments (optional)

export const addSignature = "Upload of a signature file is optional. If you have one,<br /> you may upload it.<br /> If no signature file is uploaded, checking the above box <br /> is accepted as acknowledgement of and agreement to <br /> the statements above. "