/* eslint-disable react/require-default-props */
import statusBadgeStyles from './status-badge-styles';
import StatusProgress from '../../components/status-progress';
import HelperTooltip from '../../components/helper-tooltip';

const getStatusBackground = (status: string) => {
  switch (status.toLowerCase()) {
    case 'draft':
      return '#DBEDFF';
    case 'rejected':
      return '#FFE2D8';
    case 'approved - pending implementation':
      return '#D4FFEC';
    case 'remediation requested':
      return '#F9F7B7';
    case 'archived':
      return '#EFE8FC';
    case 'completed':
      return '#9AFFFB';
    case 'pending approval':
    default:
      return '#FFF';
  }
};

const getStatusFontColor = (status: string) => {
  switch (status.toLowerCase()) {
    case 'draft':
      return '#0080FF';
    case 'rejected':
      return '#EF5B2B';
    case 'approved - pending implementation':
      return '#008E8B';
    case 'remediation requested':
      return '#8B7B1E';
    case 'pending approval':
      return '#028886';
    case 'archived':
      return '#79698E';
    case 'completed':
      return '#007573';
    default:
      return 'default';
  }
};

const delegationStages = [
  { label: 'L1', index: 1 },
  { label: 'L2', index: 2 },
  { label: 'L3', index: 3 },
  { label: 'L4', index: 4 },
];
const sarStages = [
  { label: 'L1', index: 1 },
  { label: 'L2', index: 2 },
  { label: 'L3', index: 3 },
  { label: 'L4', index: 4 },
  { label: 'L5', index: 5 },
  { label: 'L6', index: 6 },
];

function StatusBadge(
  {
    status,
    requestPurpose,
    stage,
    remediationCount,
    isUpdateRequested,
    isRequestToUpdateCount,
    stageId,
    superScript,
  }:
    {
      status: any,
      requestPurpose: string,
      stage?: string,
      remediationCount?: string,
      isUpdateRequested?: boolean,
      stageId?: string,
      superScript?: string,
      isRequestToUpdateCount: number,
    },
) {

  const statusClasses = statusBadgeStyles(
    {
      background: getStatusBackground(status),
      color: getStatusFontColor(status),
    },
  );

  const getStagesArray = () => {
    if (requestPurpose === 'Connect to / manage a server or computer') return delegationStages;
    if (requestPurpose?.toLowerCase() === 'Automate actions via application' || 'Send and receive an email via application' || 'Sending automated notifications') return sarStages;
    if (requestPurpose?.toLowerCase() === 'par') return sarStages;
    return [];
  };

  switch (status.toLowerCase()) {
    case 'draft':
    case 'approved - pending implementation':
    case 'rejected':
    case 'archived':
    case 'completed':
      return (
        // <HelperTooltip tooltipMsg={superScript ? `Update cycle - ${superScript}` : ''}>
        <span className={statusClasses.statusBadge} data-testid="status-badge">
          {`${status} ${isUpdateRequested && status.toLowerCase() === 'completed' ? `(Update Requested - ${isRequestToUpdateCount + 1})` : ''}`}
          {superScript ? <span className={statusClasses.superScript}>{superScript}</span> : null}
        </span>
        // </HelperTooltip>
      );
    case 'remediation requested':
      return (
        // <HelperTooltip tooltipMsg={superScript ? `Update cycle - ${superScript}` : ''}>
        <span className={statusClasses.statusBadge} data-testid="status-badge">
          {`${status} (${remediationCount})`}
          {superScript ? <span className={statusClasses.superScript}>{superScript}</span> : null}
        </span>
        // </HelperTooltip>
      );
    case 'pending approval':
      return (
        // <HelperTooltip tooltipMsg={superScript ? `Update cycle - ${superScript}` : ''}>
        <div data-testid="status-badge" className={statusClasses.pendingApprovalContainer}>
          <div
            className={`${statusClasses.statusBadge} ${statusClasses.pendingApproval}`}
          >
            {`${status} (${stage || ''})`}
            {superScript
              ? (
                <span className={statusClasses.superScript}>
                  {superScript}
                </span>
              ) : null}
          </div>
          <StatusProgress
            stagesArray={getStagesArray()}
            currentStage={stageId}
          />
        </div>
        // </HelperTooltip>
      );
    default:
      return (
        <HelperTooltip tooltipMsg={superScript ? `Update cycle - ${superScript}` : ''}>
          <span data-testid="status-badge">
            {status}
            {superScript
              ? (
                <span className={statusClasses.superScript}>
                  {superScript}
                </span>
              ) : null}
          </span>
        </HelperTooltip>
      );
  }
}

export default StatusBadge;
