import React, { useState } from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Tab from "@mui/material/Tab";
import myImplementationTasksStyles from "./my-Implementation-tasks-styles";
import PendingImplementation from "./PendingImplementation/PendingImplementation";
import ImplementationSummary from "./ImplementationSummary/ImplementationSummary";

const useStyles = makeStyles((theme) => ({
  tabPanel: {
    padding: "0px 0px 0px 10px",
    width: "100%",
  },
  customLabelColor: {
    "& button": {
      fontFamily: "Poppins-SemiBold !important",
      color: "#0A2756 !important",
      textTransform: "unset",
      "&.MuiTab-root.Mui-selected": {
        color: "#0080FF !important",
        fontFamily: "Poppins-Medium !important",
        textDecoration: "underline",
      },
    },
  },
  pageContainer: {
    padding: "16px 20px 20px",
  },
}));

function myImplementationTasks() {
  const styleClasses = myImplementationTasksStyles();
  const classes = useStyles();
  const [value, setValue] = useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div className={classes.pageContainer} data-testid="approvalsummary-screen">
      <Grid container spacing={2}>
        <TabContext value={value}>
          <TabList onChange={handleChange} className={classes.customLabelColor}>
            <Tab
              disableTouchRipple
              disableFocusRipple
              disableRipple
              label={<span>Pending Implementations</span>}
              value="1"
            />
            <Tab
              disableTouchRipple
              disableFocusRipple
              disableRipple
              label={<span>Implementation Summary</span>}
              value="2"
            />
          </TabList>

          <TabPanel value="1" classes={{ root: classes.tabPanel }}>
            <PendingImplementation />
          </TabPanel>
          <TabPanel value="2" classes={{ root: classes.tabPanel }}>
            <ImplementationSummary />
          </TabPanel>
        </TabContext>
      </Grid>
    </div>
  );
}
export default myImplementationTasks;
