import ReactDOM from 'react-dom';
import CustomButton from '../button';
import CustomModal from '../custom-modal';

const UserConfirmation = (
  {
    message,
    callback,
  }:
    {
      message: string,
      callback: (_ok: boolean) => void,
    },
) => {
  const container = document.createElement('div');
  container.setAttribute('custom-confirmation-navigation', '');
  document.body.appendChild(container);
  const closeModal = (callbackState: boolean) => {
    ReactDOM.unmountComponentAtNode(container);
    document.body.removeChild(container);
    callback(callbackState);
  };

  ReactDOM.render(
    <CustomModal
      modalHeader="Confirmation"
      open
      onClose={() => closeModal(false)}
      modalAction={(
        <div style={{ display: 'flex', justifyContent: 'right' }}>
          <CustomButton variant="contained" actionType="secondary" onClick={() => closeModal(false)}>
            No, Stay here
          </CustomButton>
          <CustomButton variant="contained" actionType="primary" disableMargin onClick={() => closeModal(true)}>
            Yes, Exit
          </CustomButton>
        </div>
      )}
      modalContent={(
        <div>
          <div
            style={{
              marginBottom: '12px',
              color: '#0A2756',
              font: 'normal normal normal 14px/21px Poppins-Medium',
            }}
          >
            <div>
              It looks like you might have some unsaved changes. Are you sure you want to leave? Please click the Save button to save your changes so you can return later.
            </div>
            <div>
              Are you sure you want to exit?
            </div>
          </div>
        </div>
      )}
    />,
    container,
  );
};
export default UserConfirmation;
