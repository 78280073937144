/* eslint-disable react/require-default-props */
import React from 'react';
import { styled } from '@mui/material/styles';
import LoginBackground from '../../assets/images/login_bg.jpeg';

export interface IFullScreenBgProps {
  children?: React.ReactNode | string;
  bgimage?: string;
}

interface StyleProps {
  bgimage?: string;
}

const StyledDiv = styled('div')(
  (props: StyleProps) => ({
    background: `no-repeat center url('${props.bgimage || LoginBackground}')`,
    minHeight: '100vh',
    width: '100vw',
    display: 'flex',
    backgroundSize: 'cover',
  }),
);

function FullScreenBg({ bgimage, children }: IFullScreenBgProps) {
  return (<StyledDiv bgimage={bgimage} data-testid="fullscreen-bg">{children}</StyledDiv>);
}

export default FullScreenBg;
