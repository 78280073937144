/* eslint-disable react/require-default-props */
import statusBadgeStyles from "./status-badge-styles";
import StatusProgress from "../../../components/status-progress";
import HelperTooltip from "../../../components/helper-tooltip";
import { Superscript } from "@mui/icons-material";

const getStatusBackground = (status: string) => {
  switch (status.toLowerCase()) {
    case "draft":
      return "#DBEDFF";
    case "rejected":
      return "#FFE2D8";
    case "approved - pending implementation":
      return "#D4FFEC";
    case "revoked":
      return "#EAEEF2";
    case "remediation requested":
      return "#F9F7B7";
    case "archived":
      return "#EFE8FC";
    case "completed":
      return "#9AFFFB";
    case "pending approval":
    default:
      return "#FFF";
  }
};

const getStatusFontColor = (status: string) => {
  switch (status.toLowerCase()) {
    case "draft":
      return "#0080FF";
    case "rejected":
      return "#EF5B2B";
    case "approved - pending implementation":
      return "#008E8B";
    case "remediation requested":
      return "#8B7B1E";
    case "pending approval":
      return "#028886";
    case "archived":
      return "#79698E";
    case "completed":
      return "#007573";
    case "revoked":
      return "#595959";
    default:
      return "default";
  }
};

const delegationStages = [
  { label: "L1", index: 1 },
  { label: "L2", index: 2 },
  { label: "L3", index: 3 },
  { label: "L4", index: 4 },
];
const sarStages = [
  { label: "L1", index: 1 },
  { label: "L2", index: 2 },
  { label: "L3", index: 3 },
  { label: "L4", index: 4 },
  { label: "L5", index: 5 },
  { label: "L6", index: 6 },
  //{ label: "L7", index: 7 },
];

function StatusBadge({
  status,
  requestType,
  requestStage,
  remediationStage,
  remediationBy,
  stageId,
  remediationCount,
  isUpdateRequested,
  superScript,
  isRequestToUpdateCount,
  requestStagePriority,
  requestPurpose,
}: {
  status: any;
  requestType: string;
  requestStage?: string;
  remediationStage?: string;
  remediationCount?: string;
  remediationBy?: string;
  isUpdateRequested?: boolean;
  stageId: string;
  superScript?: string;
  isRequestToUpdateCount: number;
  requestStagePriority: number;
  requestPurpose: string;
}) {
  const statusClasses = statusBadgeStyles({
    background: getStatusBackground(status),
    color: getStatusFontColor(status),
  });
  const getStagesArray = () => {
    if (
      requestType?.toLowerCase() === "delegation" &&
      requestPurpose == "Connect to / manage a server or computer"
    ) {
      return delegationStages;
    } else {
      return sarStages;
    }

    // if (requestType?.toLowerCase() === "sar") return sarStages;
    // if (requestType?.toLowerCase() === "par") return sarStages;
    // return [];
  };
  const superScriptAdd = superScript ? Number(superScript + 1) : "";
  switch (status.toLowerCase()) {
    case "draft":
    case "approved - pending implementation":
    case "rejected":
    case "archived":
    case "revoked":
    case "completed":
      return (
        // <HelperTooltip
        //   tooltipMsg={superScript ? `Update cycle - ${superScript}` : ""}
        // >
        <span
          className={`${isUpdateRequested && status.toLowerCase() === "completed"
            ? statusClasses.isUpdateRequested
            : statusClasses.statusBadge
            }`}
          data-testid="status-badge"
        >
          {`${status} ${isUpdateRequested && status.toLowerCase() === "completed"
            ? `(Update Requested - ${isRequestToUpdateCount + 1})`
            : ""
            }`}
          {superScript ? (
            <span className={statusClasses.superScript}>{superScript}</span>
          ) : null}
        </span>
        // </HelperTooltip>
      );
    case "remediation requested":
      return (
        // <HelperTooltip
        //   tooltipMsg={superScript ? `Update cycle - ${superScript}` : ""}
        // >
        <div className={statusClasses.remediationMain}>
          <span
            className={`${statusClasses.statusBadge} ${statusClasses.remediationStage}`}
            data-testid="status-badge"
          >
            {`${status} (${remediationCount}) (${remediationStage})`}

            {superScript ? (
              <span className={statusClasses.superScript}>{superScript}</span>
            ) : null}
          </span>
          <span
            className={`${statusClasses.statusBadge} ${statusClasses.remediationBy}`}
          >
            By {remediationBy}
          </span>
        </div>
        // </HelperTooltip>
      );
    case "pending approval":
      return (
        // <HelperTooltip
        //   tooltipMsg={superScript ? `Update cycle - ${superScript}` : ""}
        // >
        <div
          data-testid="status-badge"
          className={statusClasses.pendingApprovalContainer}
        >
          <div
            className={`${statusClasses.statusBadge} ${statusClasses.pendingApproval}`}
          >
            {`${status} (${requestStage || ""})`}
            {superScript ? (
              <span className={statusClasses.superScript}>{superScript}</span>
            ) : null}
          </div>
          <StatusProgress
            stagesArray={getStagesArray()}
            currentStage={requestStagePriority}
          />
        </div>
        // </HelperTooltip>
      );
    default:
      return (
        // <HelperTooltip
        //   tooltipMsg={superScript ? `Update cycle - ${superScript}` : ""}
        // >
        <span data-testid="status-badge">
          {status}
          {superScript ? (
            <span className={statusClasses.superScript}>{superScript}</span>
          ) : null}
        </span>
        // </HelperTooltip>
      );
  }
}

export default StatusBadge;
