import { useState } from 'react';
import isBefore from 'date-fns/isBefore';

interface IDateRangeObj {
  startDate: Date | null;
  endDate: Date | null;
}

const useDaterangePicker = ({ isSingleDate }: { isSingleDate: boolean }) => {
  const [datepickerProps, setDatepickerProps] = useState<{
    isSingleDate: boolean,
    dateRange: IDateRangeObj,
  }>({
    isSingleDate,
    dateRange: {
      startDate: null,
      endDate: null,
    },
  });

  const setDateRangeValue = (selectedValue: any) => {
    if (!datepickerProps.isSingleDate) {
      setDatepickerProps((prevVal) => {
        const newVal = { ...prevVal.dateRange };
        if (!prevVal.dateRange.startDate || prevVal.dateRange.endDate) {
          newVal.startDate = selectedValue;
          newVal.endDate = null;
        } else if (prevVal.dateRange.startDate) {
          if (isBefore(selectedValue, prevVal.dateRange.startDate)) {
            newVal.startDate = selectedValue;
            newVal.endDate = prevVal.dateRange.startDate;
          } else newVal.endDate = selectedValue;
        }
        return {
          ...prevVal, dateRange: { ...newVal },
        };
      });
    } else {
      setDatepickerProps((prevVal) => ({
        ...prevVal,
        dateRange: {
          startDate: selectedValue,
          endDate: selectedValue,
        },
      }));
    }
  };
  return { datepickerProps, setDatepickerProps, setDateRangeValue };
};

export default useDaterangePicker;
