import React from 'react';

export interface IDateRangeObj {
  startDate: Date | null;
  endDate: Date | null;
}
export interface IFiltersObj {
  requestId: string[],
  status: number[],
  teamName: string[],
  requestDate: IDateRangeObj,
}

export interface ITableParameters {
  page: number
  orderBy: {
    order: string,
    column: string,
  },
}

export const emptyFilters = {
  requestId: [],
  status: [],
  teamName: [],
  requestDate: {
    startDate: null,
    endDate: null,
  },
};

export const initialTableParameters = {
  page: 1,
  orderBy: {
    order: 'asc',
    column: '',
  },
};

export function createColumns(
  key: number,
  label: string,
  icon: any,
  id: string,
  isActive: boolean,
  handleClick?: (_e: any) => void,
  style?: React.CSSProperties,
) {
  return {
    key, label, icon, handleClick, id, isActive, style,
  };
}

export function createRowCells(
  columnId: string,
  renderComponent: any,
  id: string,
  handleClick: undefined | ((_e: any) => void),
  columnNumber: number,
  style?: React.CSSProperties,
) {
  return {
    columnId, renderComponent, id, handleClick, columnNumber, style,
  };
}

export const delegationColumnMapping = {
  editIcon: 'editIcon',
  id: 'id',
  request_number: 'request_number',
  delegationId: 'id',
  status: 'status',
  teamName: 'team_name',
  requestDate: 'request_date',
  lastUpdated: 'updated_at',
  completionDate: 'completion_date',
  actions: 'actions',
};


export interface IDelegationSummaryData {
  id: number,
  completion_date: string
  created_at: string,
  del_user_detail_list: number,
  is_active: boolean,
  is_request_to_update: boolean,
  remediation_count: number,
  request_date: string,
  request_to_update_count: number,
  request_type_id: string,
  stage: string,
  stage_id: string,
  status: string,
  status_id: string,
  team_name: string,
  updated_at: string,
  user: string,
  request_number: string,
}
