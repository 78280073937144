import { makeStyles } from "@mui/styles";

const myImplementationTasksStyles = makeStyles({
  pageContainer: {
    padding: "16px 20px 20px",
  },
  filtersContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  filterPanel: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "5px",
    "& .MuiButton-root:last-child": {
      fontFamily: 'Poppins-Medium!important',
      //background: 'red'
    }
  },
  filterIcon: {
    padding: "16px",
    display: "inline-flex",
  },
  filterMenu: {
    margin: "0px 6px",
    display: "inline-flex",
  },
  stickySearchContainer: {
    position: "sticky",
    top: "0",
    padding: "12px 12px 4px 12px",
    zIndex: "2",
    background: "#fff",
  },
  stickySearch: {
    padding: "0px 8px",
    border: "1px solid grey",
    borderRadius: "25px",
    margin: "0",
  },
  filterOptionsList: {
    margin: "12px 16px",
    maxHeight: "250px",
    overflowY: "scroll",
  },
  dateRangeToggleContainer: {
    margin: "12px 16px",
    display: "flex",
    justifyContent: "space-between",
  },
  dateRangeContainer: {
    margin: "0px 8px",
  },
  dateRangeApplyCta: {
    margin: "4px 16px 12px",
    display: "flex",
    justifyContent: "end",
  },
  emptyResultContainer: {
    color: "#0A2756",
    width: "100%",
    height: "425px",
    border: "1px dashed #A7A7A7",
    font: "normal normal normal 14px/19px NotoSerif-Regular",
    letterSpacing: "0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  paginatedResultsText: {
    font: "normal normal normal 13px/20px Poppins-Regular",
    letterSpacing: "0px",
    color: "#0A2756",
    display: "inline-block",
    lineHeight: "26px",
  },
  paginationRoot: {
    display: "inline-block",
    float: "right",
  },
  paginationText: {
    color: "#0A2756 !important",
    font: "normal normal normal 13px/20px Poppins-Regular !important",
    borderRadius: "3px !important",
    margin: "0px 2px !important",
    "&:hover": {
      background: "#EEF2F3 !important",
    },
  },
  paginationSelected: {
    background: "#fff !important",
    border: "1px solid #FFA800 !important",
  },
  modalContentDiv: {
    marginBottom: "12px",
    color: "#0A2756",
    font: "normal normal normal 14px/21px Poppins-Medium",
  },
  modalTextDiv: {
    maxWidth: "52ch",
    color: "#0A2756",
    font: "normal normal normal 14px/22px NotoSerif-Regular",
  },
  spanText: {
    fontFamily: "NotoSerif-Bold",
  },
  updateModalContent: {
    marginBottom: "8px",
    // maxWidth: '85ch',
    color: "#0A2756",
    font: "normal normal normal 14px/18px Poppins-Medium",
  },
  updateModalNote: {
    marginBottom: "16px",
    maxWidth: "86ch",
    font: "normal normal normal 14px/22px NotoSerif-Regular",
    color: "#0A2756",
  },
  btnOk:{
    font: "normal normal normal 14px/21px Poppins-Medium !important",
    fontSize:"14px !important",
    fontWeight:"500 !important",
    margin: "0px 8px !important",
    padding: "4px 16px !important",
    backgroundColor: "rgb(0, 181, 177) !important",
    border: "2px solid rgb(0, 181, 177) !important",
    color: "rgb(255, 255, 255) !important",
    boxShadow: "none !important",
    borderRadius: "25px !important",
    lineHeight: "1.75 !important",
    letterSpacing: "0.02857em !important",
  },

  modalActionDiv: {
    display: "flex",
    justifyContent: "right",
  },
  textFieldRoot: {
    width: "100%",
    border: "1px solid #A7A7A7",
    color: "#A7A7A7",
  },
  textFieldInput: {
    padding: "12px !important",
    font: "normal normal normal 12px/17px NotoSerif-Regular !important",
    "&>fieldset>legend": {
      fontSize: "1em",
    },
    "&>textarea:first-child": {
      minHeight: "26px !important",
    },
    "&>textarea": {
      color: "#0A2756 !important",
      fontSize: "14px !important",
    },
    "&.Mui-focused > fieldset": {
      border: "1px solid #0080FF !important",
    },
  },
  textFieldLabel: {
    width: "100%",
    font: "normal normal normal 12px/17px NotoSerif-Regular !important",
    transform: "translate(12px, 8px) scale(1) !important",
  },
  textFieldLabelShrink: {
    transform: "translate(14px, -8px) scale(1) !important",
    color: "#0080FF !important",
  },
  // textField: {

  // },
  // textFieldPlaceholder: {
  //   font: 'normal normal normal 12px/17px NotoSerif-Regular !important',
  //   color: '#A7A7A7 !important',
  //   // transform: 'translate(14px, -9px) scale(1) !important',
  // },
  // table cell
  pencilIcon: {
    "&:hover > path": {
      fill: "#FFA800 !important",
    },
  },
  moreIcon: {
    "& > path": {
      fill: "#00B5B1 !important",
    },
    "&:hover > path": {
      fill: "#FFA800 !important",
    },
  },
  moreIconDisable: {
    "& > path": {
      fill: "#B9C3CE !important",
    },
  },
  moreIconActive: {
    fill: "#FFA800 !important",
  },
  moreActionMenu: {
    padding: "8px",
  },
  moreActionMenuItem: {
    font: "normal normal normal 13px/14px NotoSerif-Regular !important",
    letterSpacing: "0px !important",
    color: "#0A2756 !important",
    "&:hover, &:active, &:focus": {
      background: "#EDF4F8 !important",
    },
  },
  menuLabel: {
    marginLeft: "8px",
  },
  approverText: {
    font: "normal 13px/20px Poppins-Regular",
    color: "#0A2756",
    fontSize:"14px",
  },
  approverTextResults: {
    font: "normal 14px/19px NotoSerif-Regular",
    color: "#0A2756",
  },
  astrik: {
    color: "#EF5B2B",
    margin: "0 2px",
  },
  linkText: { textDecoration: "underline", color: "#0080FF" },
});
export default myImplementationTasksStyles;
